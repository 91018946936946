import ReviewActionButtons from '@/pages/orderReview/OrderReviewDetails/ReviewActionsHeader/ReviewActionButtons';
import Order from '@/pages/orderReview/models/Order';
import { Drawer, IconButton, Stack } from '@mui/material';
import { Separator } from '@treyd-io/treyd-ui/components/Separator';
import MenuIcon from '@treyd-io/treyd-ui/icons/MenuIcon';
import { useState } from 'react';
import { SupplierInvoiceButton } from './SupplierInvoiceButton';

interface ReviewActionsHeaderMenuProps {
  order: Order;
  actionsDisabled: boolean;
  goBack: () => void;
  areAllSectionsApproved: boolean;
  dealDuplicates?: Order[];
}

export const ReviewActionsHeaderMenu = (
  props: ReviewActionsHeaderMenuProps
) => {
  const {
    order,
    actionsDisabled,
    goBack,
    areAllSectionsApproved,
    dealDuplicates,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setMenuOpen(true)}
        color="primary"
        size="large">
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}>
        <Stack spacing={2} sx={{ padding: '1rem' }}>
          <SupplierInvoiceButton />
          <Separator />
          <ReviewActionButtons
            actionsDisabled={actionsDisabled}
            order={order}
            goBack={goBack}
            areAllSectionsApproved={areAllSectionsApproved}
            dealDuplicates={dealDuplicates}
          />
        </Stack>
      </Drawer>
    </>
  );
};
