import {
  GetCompanyCreditDecisionsQueryVariables,
  getCompanyCreditDecisionsQuery,
} from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetCompanyCreditDecisions = (
  variables: GetCompanyCreditDecisionsQueryVariables
) =>
  useQuery(getCompanyCreditDecisionsQuery, {
    variables,
    skip: !variables?.orgNumber || !variables?.country,
  });
