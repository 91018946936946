import { TypedDocumentNode, gql } from '@apollo/client';
import {
  Lager_Core_Company,
  Lager_Core_Credituser,
  Lager_Risk_Creditdecision,
  Lager_Risk_Creditdecisionapproval,
  Lager_Risk_Creditdecisionapprover,
} from '__generated__/graphql';

export type CoreCompanyStatus =
  | 'draft'
  | 'approved'
  | 'rejected'
  | 'published_active'
  | 'published_inactive'
  | 'published_expired'
  | 'opendecision'
  | 'review_notstarted'
  | 'review_unassigned'
  | 'review_inreview'
  | 'review_sentback'
  | 'underwriting_notstarted'
  | 'underwriting_unassigned'
  | 'underwriting_sentback'
  | 'underwriting_inanalysis'
  | 'underwriting_cancelled';

type CreditDecisionStatus =
  | 'published'
  | 'approved'
  | 'cancelled'
  | 'expired'
  | 'outdated'
  | 'open'
  | 'refused';

type Approver = Pick<
  Lager_Risk_Creditdecisionapprover,
  'first_name' | 'last_name'
>;

export type Assignee = Pick<Lager_Core_Credituser, 'first_name' | 'last_name'>;
type Approval = Pick<
  Lager_Risk_Creditdecisionapproval,
  'risk_creditdecisionapprover'
> & {
  risk_creditdecisionapprover: Approver;
};

export type CompanyCreditDecision = Pick<
  Lager_Risk_Creditdecision,
  | 'approved_limit'
  | 'approved_limit_currency'
  | 'approved_limit_comments'
  | 'approved_rating_comments'
  | 'published_at'
  | 'created'
  | 'expiration_date'
  | 'approved_rating'
  | 'status'
  | 'approvals'
> & {
  approvals: Approval[];
  status: CreditDecisionStatus;
};

type GetCompanyCreditDecisionsQueryType = {
  lager_core_company: (Pick<
    Lager_Core_Company,
    | 'status'
    | 'latest_credit_decision'
    | 'latest_published_credit_decision'
    | 'assignee'
  > & {
    status: CoreCompanyStatus;
    latest_credit_decision: CompanyCreditDecision;
    latest_published_credit_decision: CompanyCreditDecision;
    assignee: Assignee;
  })[];
};

export type GetCompanyCreditDecisionsQueryVariables = {
  orgNumber: string;
  country: string;
};

export const getCompanyCreditDecisionsQuery = gql`
  query getCompanyCreditDecisions($orgNumber: String!, $country: String!) {
    lager_core_company(
      where: { orgnr: { _eq: $orgNumber }, country: { _eq: $country } }
    ) {
      status
      assignee {
        first_name
        last_name
      }
      latest_published_credit_decision {
        status
        approved_limit
        approved_limit_currency
        approved_limit_comments
        approved_rating_comments
        published_at
        created
        expiration_date
        approved_rating
        approvals {
          risk_creditdecisionapprover {
            first_name
            last_name
          }
        }
      }
      latest_credit_decision {
        status
        approved_limit
        approved_limit_currency
        approved_limit_comments
        approved_rating_comments
        published_at
        created
        expiration_date
        approved_rating
        approvals {
          risk_creditdecisionapprover {
            first_name
            last_name
          }
        }
      }
    }
  }
` as TypedDocumentNode<
  GetCompanyCreditDecisionsQueryType,
  GetCompanyCreditDecisionsQueryVariables
>;
