import { CompanyAppliedDiscounts } from '@/types';
import { IconButton, Stack, Typography } from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import EditIcon from '@treyd-io/treyd-ui/icons/EditIcon';
import { Can } from 'context';
import { toNumber } from 'lodash';
import { useToggle } from 'react-use';
import { DiscountDialog } from './DiscountDialog';

export const PricingSection = ({
  companyAppliedDiscounts,
  companyId,
  onUpdate,
}: {
  companyAppliedDiscounts?: CompanyAppliedDiscounts;
  companyId: number;
  onUpdate: () => void;
}) => {
  const { esg_discount, manual_discount, size_discount, base_price } =
    companyAppliedDiscounts || {};
  const manualDiscount = toNumber(manual_discount);
  const esgDiscount = toNumber(esg_discount);
  const sizeDiscount = toNumber(size_discount);
  const basePrice = toNumber(base_price);
  const isAdditionalCharges = manualDiscount >= -1 && manualDiscount <= 0;
  const isManualDiscount = manualDiscount >= 0 && manualDiscount <= 0.5;

  const [isAdditionalChargesDialogOpen, toggleAdditionalChargesDialogOpen] =
    useToggle(false);

  const [isDiscountDialogOpen, toggleDiscountDialogOpen] = useToggle(false);

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="h5" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.primary">
          It shows the amount of pricing options available to the merchant.
        </Typography>
      </Stack>
      <Stack
        border={1}
        borderColor="other.outlined_border"
        borderRadius={4}
        padding={3}>
        <Row spacing={{ xs: 4, sm: 8 }}>
          <Col xs={6} sm="auto">
            <TwoLines label={'Base price'} content={`${basePrice}%`} />
          </Col>
          <Col xs={6} sm="auto">
            <TwoLines
              label={
                <Stack direction="row" alignItems="center" gap={0.25}>
                  <Typography variant="body2" color="text.secondary">
                    Manual discount
                  </Typography>
                  <Can I="edit" an="merchant_fees">
                    <IconButton
                      color="info"
                      size="small"
                      sx={{ padding: 0 }}
                      onClick={toggleDiscountDialogOpen}>
                      <EditIcon />
                    </IconButton>
                  </Can>
                  <DiscountDialog
                    open={isDiscountDialogOpen}
                    onClose={() => {
                      toggleDiscountDialogOpen();
                      onUpdate();
                    }}
                    value={isManualDiscount ? Math.abs(manualDiscount) : 0}
                    type="discount"
                    companyId={companyId}
                  />
                </Stack>
              }
              content={`${isManualDiscount ? Math.abs(manualDiscount) : 0}%`}
            />
          </Col>
          <Col xs={6} sm="auto">
            <TwoLines
              label={
                <Stack direction="row" alignItems="center" gap={0.25}>
                  <Typography variant="body2" color="text.secondary">
                    Manual additional charges
                  </Typography>
                  <Can I="edit" an="merchant_fees">
                    <IconButton
                      color="info"
                      size="small"
                      sx={{ padding: 0 }}
                      onClick={toggleAdditionalChargesDialogOpen}>
                      <EditIcon />
                    </IconButton>
                  </Can>
                  <DiscountDialog
                    open={isAdditionalChargesDialogOpen}
                    onClose={() => {
                      toggleAdditionalChargesDialogOpen();
                      onUpdate();
                    }}
                    value={isAdditionalCharges ? Math.abs(manualDiscount) : 0}
                    type="charges"
                    companyId={companyId}
                  />
                </Stack>
              }
              content={`${isAdditionalCharges ? Math.abs(manualDiscount) : 0}%`}
            />
          </Col>
          <Col xs={6} sm="auto">
            <TwoLines label={'ESG'} content={`${esgDiscount}%`} />
          </Col>
          <Col xs={6} sm="auto">
            <TwoLines label={'Size discount'} content={`${sizeDiscount}%`} />
          </Col>
        </Row>
      </Stack>
    </Stack>
  );
};
