import { gql, TypedDocumentNode } from '@apollo/client';
import { Orders_Order_Totals_In_Sek_Aggregate } from '__generated__/graphql';

type getMerchantOrdersStatsQueryTypes = {
  average_latest_orders: Pick<
    Orders_Order_Totals_In_Sek_Aggregate,
    'aggregate'
  >;
  first_order_amount: Pick<Orders_Order_Totals_In_Sek_Aggregate, 'aggregate'>;
  orders_count_last_month: Pick<
    Orders_Order_Totals_In_Sek_Aggregate,
    'aggregate'
  >;
  orders_count_last_year: Pick<
    Orders_Order_Totals_In_Sek_Aggregate,
    'aggregate'
  >;
};

export const getMerchantOrdersStatsQuery = gql`
  query getMerchantOrdersStats(
    $importer_id: bigint
    $last_month: date
    $last_year: date
  ) {
    average_latest_orders: orders_order_totals_in_sek_aggregate(
      order_by: {
        order: { active_payout: { actual_payment_date: desc_nulls_last } }
      }
      where: { order: { importer_id: { _eq: $importer_id } } }
      limit: 5
    ) {
      aggregate {
        avg {
          total_amount_excl_vat_no_discount_in_sek
        }
      }
    }

    first_order_amount: orders_order_totals_in_sek_aggregate(
      order_by: {
        order: { active_payout: { actual_payment_date: desc_nulls_last } }
      }
      where: { order: { importer_id: { _eq: $importer_id } } }
      limit: 1
    ) {
      aggregate {
        avg {
          total_amount_excl_vat_no_discount_in_sek
        }
      }
    }

    orders_count_last_month: orders_order_totals_in_sek_aggregate(
      where: {
        order: {
          importer_id: { _eq: $importer_id }
          active_payout: { actual_payment_date: { _gte: $last_month } }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    orders_count_last_year: orders_order_totals_in_sek_aggregate(
      where: {
        order: {
          importer_id: { _eq: $importer_id }
          active_payout: { actual_payment_date: { _gte: $last_year } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
` as TypedDocumentNode<
  getMerchantOrdersStatsQueryTypes,
  { importer_id: number; last_month: string; last_year: string }
>;
