import { OnboardingStepStatus } from '@/types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import CheckmarkIcon from '@treyd-io/treyd-ui/icons/CheckmarkIcon';
import ChevronDownIcon from '@treyd-io/treyd-ui/icons/ChevronDownIcon';
import { ReactNode } from 'react';

interface AccordionProps {
  details: ReactNode;
  title: string;
  status: OnboardingStepStatus;
  index: number;
  onChange: () => void;
  expanded: boolean;
}

export const StepAccordion = (props: AccordionProps) => (
  <Accordion
    sx={{ backgroundColor: 'transparent' }}
    onChange={props.onChange}
    expanded={props.expanded}>
    <AccordionSummary expandIcon={<ChevronDownIcon fontSize="large" />}>
      <Typography variant="subtitle1" color="text.primary">
        <StepTitle
          index={props.index}
          status={props.status}
          title={props.title}
        />
      </Typography>
    </AccordionSummary>
    <AccordionDetails
      sx={{
        paddingInline: 8,
        paddingBlockStart: 1.5,
        paddingBlockEnd: 5,
      }}>
      <Box maxWidth="39rem">
        {typeof props.details === 'string' ? (
          <Typography variant="body1" color="text.secondary">
            {props.details}
          </Typography>
        ) : (
          props.details
        )}
      </Box>
    </AccordionDetails>
  </Accordion>
);

interface StepTitleProps {
  title: string;
  status: 'not_started' | 'on_going' | 'completed';
  index: number;
}

const StepTitle = (props: StepTitleProps) => {
  switch (props.status) {
    case 'on_going':
      return (
        <Box display="flex" alignItems="center">
          <Badge
            component={Box}
            width={24}
            height={24}
            badgeContent={props.index}
            color="primary"
            componentsProps={{
              badge: {
                style: {
                  transform: 'none',
                  height: '100%',
                  width: '100%',
                  borderRadius: '50%',
                },
              },
            }}
          />
          <Typography
            variant="subtitle1"
            color="text.primary"
            marginLeft={3}
            marginRight={3}>
            {props.title}
          </Typography>
          <Chip label="Ongoing" color="primary" variant="filled" size="small" />
        </Box>
      );
    case 'completed':
      return (
        <Box display="flex" alignItems="center">
          <Badge
            component={Box}
            width={24}
            height={24}
            badgeContent={<CheckmarkIcon fontSize="small" />}
            color="success"
            componentsProps={{
              badge: {
                style: {
                  transform: 'none',
                  height: '100%',
                  width: '100%',
                  borderRadius: '50%',
                },
              },
            }}
          />
          <Typography variant="body1" color="success.dark" marginLeft={3}>
            {props.title}
          </Typography>
        </Box>
      );
    default:
    case 'not_started':
      return (
        <Box display="flex" alignItems="center">
          <Badge
            component={Box}
            width={24}
            height={24}
            badgeContent={props.index}
            color="secondary"
            componentsProps={{
              badge: {
                style: {
                  transform: 'none',
                  height: '100%',
                  width: '100%',
                  borderRadius: '50%',
                },
              },
            }}
          />
          <Typography variant="body1" color="text.primary" marginLeft={3}>
            {props.title}
          </Typography>
        </Box>
      );
  }
};
