import { Button, Stack, Typography } from '@mui/material';
import EditIcon from '@treyd-io/treyd-ui/icons/EditIcon';
import { useToggle } from 'react-use';
import { FinancialDocumentsDialog } from './FinancialDocumentsDialog';

interface FinancialDocumentsSectionProps {
  country: string;
  orgNo: string;
}
export const FinancialDocumentsSection = (
  props: FinancialDocumentsSectionProps
) => {
  const { country, orgNo } = props;
  const [isFinancialDocumentsDialogOpen, toggleFinancialDocumentsDialog] =
    useToggle(false);
  return (
    <Stack
      gap={1}
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between">
      <Stack gap={1}>
        <Typography variant="h5">Financial documents</Typography>
        <Typography variant="body1" color="text.secondary">
          Balance sheet, Profit and loss statements, Bank statement, and other
          supporting files
        </Typography>
      </Stack>
      <Button
        startIcon={<EditIcon />}
        variant="outlined"
        color="secondary"
        onClick={toggleFinancialDocumentsDialog}>
        Edit
      </Button>
      <FinancialDocumentsDialog
        country={country}
        orgNo={orgNo}
        open={isFinancialDocumentsDialogOpen}
        onClose={toggleFinancialDocumentsDialog}
      />
    </Stack>
  );
};
