import { useSupplierInvoice } from '@/hooks';
import { Button, ButtonProps, useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';
import FileTextTwoIcon from '@treyd-io/treyd-ui/icons/FileTextTwoIcon';

export const SupplierInvoiceButton = (props: ButtonProps) => {
  const { isSupplierInvoiceOpen, toggleSupplierInvoice, invoiceUrl } =
    useSupplierInvoice();

  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('md'));

  if (invoiceUrl) {
    if (isSmallScreens) {
      return (
        <Button
          size="large"
          href={invoiceUrl}
          target="_blank"
          color="secondary"
          variant="outlined"
          startIcon={<FileTextTwoIcon />}>
          Supplier invoice
        </Button>
      );
    }

    return (
      <Button
        size="large"
        variant={isSupplierInvoiceOpen ? 'contained' : 'outlined'}
        color={isSupplierInvoiceOpen ? 'primary' : 'secondary'}
        onClick={toggleSupplierInvoice}
        startIcon={<FileTextTwoIcon />}
        {...props}>
        Supplier invoice
      </Button>
    );
  }

  return (
    <Button
      disabled
      variant="text"
      size="large"
      startIcon={<CloseIcon />}
      {...props}>
      No invoice attached
    </Button>
  );
};
