import {
  useGetTreydUsers,
  useSearchHubspotCompanies,
  useUpdateCompany,
  useUpdateCompanyHubSpotId,
} from '@/hooks';
import useDebouncedEffect from '@/hooks/useDebouncedEffect';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import SearchIcon from '@treyd-io/treyd-ui/icons/SearchIcon';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { map, replace, toString } from 'lodash';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

type FormValues = {
  hubspotId: string;
  salesRepresentativeId: number | null;
};
interface HubspotActionsDialogProps {
  merchantId: number;
  hubspotId: string;
  salesRepresentativeId: number | null;
  open: boolean;
  onClose: () => void;
  onSaveSuccess: () => void;
}

export const HubspotActionsDialog = (props: HubspotActionsDialogProps) => {
  const {
    merchantId,
    hubspotId,
    salesRepresentativeId,
    open,
    onClose,
    onSaveSuccess,
  } = props;
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('md'));

  const [searchTerm, setSearchTerm] = useState('');
  const {
    mutate: searchHubspotCompany,
    data: searchHubspotCompaniesData,
    isPending: isSearchHubspotCompanyLoading,
  } = useSearchHubspotCompanies();
  const {
    mutateAsync: updateCompanyHubspotId,
    isPending: isUpdateCompanyHubspotIdLoading,
  } = useUpdateCompanyHubSpotId();

  const { data: treydUsers, loading: isTreydUsersLoading } = useGetTreydUsers();
  const users = treydUsers?.lager_treyd_users || [];

  const showNotificationMessage = useToastNotification();
  const [
    updateCompanySalesRepresentativeId,
    { loading: isUpdatingCompanySalesRepresentativeId },
  ] = useUpdateCompany();

  const hubspotCompaniesOptions = map(
    searchHubspotCompaniesData?.data,
    (searchResult) => ({
      id: toString(searchResult.id),
      name: `${searchResult.name} (${searchResult.id})`,
    })
  );

  useDebouncedEffect(
    () => {
      if (searchTerm) searchHubspotCompany(searchTerm);
    },
    [searchTerm],
    500
  );

  useEffectOnce(() => {
    if (hubspotId) searchHubspotCompany(hubspotId);
  });

  const handleFormSubmit = async (values: FormValues) => {
    const newHubspotId = values.hubspotId;
    const newSalesRepresentativeId = values.salesRepresentativeId;
    try {
      if (hubspotId !== newHubspotId)
        await updateCompanyHubspotId({
          companyId: merchantId,
          hubspotVid: newHubspotId ? newHubspotId : null,
        });

      if (salesRepresentativeId !== newSalesRepresentativeId)
        await updateCompanySalesRepresentativeId({
          variables: {
            id: merchantId,
            company: {
              sales_representative_id: newSalesRepresentativeId,
            },
          },
        });
      onSaveSuccess();
    } catch (e) {
      const errMessage = toString(
        (e as AxiosError<{ error: string }>)?.response?.data?.error
      );
      const formattedErrorMessage = replace(
        replace(errMessage, 'hubspot_vid', 'Hubspot ID'),
        /hubspot_vid(?!.*hubspot_vid)/,
        `Hubspot ID : ${newHubspotId}`
      );
      showNotificationMessage({
        title: formattedErrorMessage || 'Failed to update Hubspot connection',
        type: 'error',
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isSmallScreens}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="h5" noWrap color="text.primary">
          Hubspot actions
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          hubspotId: toString(hubspotId),
          salesRepresentativeId: salesRepresentativeId,
        }}
        enableReinitialize
        validateOnMount
        onSubmit={handleFormSubmit}>
        {(formik) => (
          <Stack
            component="form"
            display="contents"
            onSubmit={formik.handleSubmit}>
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                paddingBlockEnd: 3,
              }}>
              <Typography variant="body1">Link merchant to Hubspot.</Typography>
              <Autocomplete
                loading={isSearchHubspotCompanyLoading}
                options={hubspotCompaniesOptions}
                noOptionsText="No Hubspot companies found"
                value={
                  hubspotCompaniesOptions.find(
                    (item) => item.id === formik.values.hubspotId
                  ) || null
                }
                onChange={(_, value) => {
                  formik.setFieldValue(
                    'hubspotId',
                    value && value.id ? value.id : ''
                  );
                }}
                fullWidth
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="hubspotId"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    fullWidth
                    placeholder="Search for a Hubspot company.."
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: null,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={
                      formik.touched.hubspotId &&
                      Boolean(formik.errors.hubspotId)
                    }
                    helperText={
                      formik.touched.hubspotId && formik.errors.hubspotId
                    }
                  />
                )}
              />
              <Autocomplete
                options={users}
                value={
                  users.find(
                    (user) => user.id === formik.values.salesRepresentativeId
                  ) || null
                }
                onChange={(_, value) => {
                  formik.setFieldValue(
                    'salesRepresentativeId',
                    value && value.id ? value.id : ''
                  );
                }}
                fullWidth
                loading={isTreydUsersLoading}
                getOptionLabel={(option) => option.name || option.email || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="salesRepresentativeId"
                    fullWidth
                    label={'Sales representative'}
                    error={
                      formik.touched.salesRepresentativeId &&
                      Boolean(formik.errors.salesRepresentativeId)
                    }
                    helperText={
                      formik.touched.salesRepresentativeId &&
                      formik.errors.salesRepresentativeId
                    }
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                type="button"
                onClick={onClose}
                variant="outlined"
                color="secondary">
                Cancel
              </Button>
              <LoadingButton
                loading={
                  isUpdatingCompanySalesRepresentativeId ||
                  isUpdateCompanyHubspotIdLoading
                }
                size="large"
                type="submit"
                disabled={!formik.dirty || isTreydUsersLoading}
                variant="contained">
                Connect
              </LoadingButton>
            </DialogActions>
          </Stack>
        )}
      </Formik>
    </Dialog>
  );
};
