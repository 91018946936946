import { getMerchantCustomerErpInvoicesQuery } from '@/schemas/getMerchantCustomersErpInvoicesQuery';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetMerchantCustomerErpInvoices = ({
  queryOptions,
  skip,
  companyId,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
  companyId: number;
}) =>
  useQuery(getMerchantCustomerErpInvoicesQuery(queryOptions), {
    variables: {
      companyId,
    },
    skip,
  });
