import { createContext } from 'react';
// @ts-ignore
import { _LTracker } from 'loggly-jslogger';

export const trackingLogs = ['production'].includes(
  process.env.REACT_APP_ENV as string
)
  ? _LTracker
  : [];

trackingLogs.push({
  logglyKey: process.env['REACT_APP_LOGGLY_TOKEN'],
  sendConsoleErrors: false,
  tag: 'ops-app-logging',
});

export const LoggingContext = createContext(trackingLogs);
