import { getCreditFees } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetCompanyFees = (companyId: number) =>
  useQuery({
    queryKey: [`company-fees`, companyId],
    queryFn: () => getCreditFees(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
    select: (data) => data.data,
  });
