import { Header } from '@/components';
import { FormButton } from '@/components/styled-components/FormStyles';

import {
  DetailsForm,
  GeneralFormikCheckBox,
  GeneralFormikInput,
} from '@/pages/dev/components';
import { updateInvocationMutation } from '@/schemas';

import { useMutation } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';
import { Formik } from 'formik';
import { startCase } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

export const EventDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    user_id,
    org_no,
    event: { invocations, data },
  } = location.state;
  const [UpdateInvocation] = useMutation(updateInvocationMutation);
  const showNotificationMessage = useToastNotification();

  const initialValues = (id: number) => {
    const i = invocations?.filter((invocation: any) => invocation.id === id)[0];
    return {
      ack: i.ack,
      retries: i.retries,
    };
  };

  const renderData = (data: any) => {
    if (data === null) return null;
    var jsonPretty = JSON.stringify(data, null, '\t');
    return (
      <>
        <pre>{jsonPretty}</pre>
      </>
    );
  };

  const goBack = () => {
    navigate('/dev#events', { state: { user_id, org_no } });
  };

  return (
    <>
      <Header>
        <Button onClick={goBack} startIcon={<ArrowLeftIcon />}>
          Go back
        </Button>
      </Header>
      {!!invocations.length && (
        <Typography variant="h4">{'Event Details'}</Typography>
      )}
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        {invocations?.map((invocation: any) => (
          <Formik
            key={invocation.id}
            initialValues={initialValues(invocation.id)}
            onSubmit={(eventData, { setSubmitting }) => {
              UpdateInvocation({
                variables: { invocation_id: invocation.id, data: eventData },
              })
                .then((res) => {
                  if (res.errors) {
                    console.log('err ==> ', res.errors);
                    showNotificationMessage({
                      title: 'Something went wrong',
                      type: 'error',
                    });
                  } else {
                    showNotificationMessage({
                      title: 'Changes saved successfully',
                      type: 'success',
                    });
                  }
                  setSubmitting(false);
                })
                .catch((err) => {
                  console.log('err ==> ', err);
                  showNotificationMessage({
                    title: 'Something went wrong',
                    type: 'error',
                  });
                  setSubmitting(false);
                });
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DetailsForm sx={{ width: '20rem' }}>
                <Typography>
                  {invocation.id}: {startCase(invocation?.handler?.name)}
                </Typography>
                <GeneralFormikCheckBox name="ack">Ack</GeneralFormikCheckBox>
                <GeneralFormikInput
                  label="Retries"
                  name="retries"
                  type="number"
                  inputProps={{ min: 0, max: 3 }}
                />
                <FormButton variant="outlined" type="submit">
                  Save Changes
                </FormButton>
              </DetailsForm>
            </Box>
          </Formik>
        ))}
      </Box>
      {data && <Typography variant="h4">{'Event Data'}</Typography>}
      {renderData(data)}
    </>
  );
};
