import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';

export const NoKycPlaceholder = ({
  isKYCRevoked,
}: {
  isKYCRevoked: boolean;
}) => {
  return (
    <TableHasNoRowsPlaceholder
      title={'No KYC'}
      subtitle={
        isKYCRevoked ? 'KYC has been revoked' : 'KYC has not been submitted yet'
      }
      containerProps={{
        border: 1,
        borderColor: 'other.outlined_border',
        borderRadius: 4,
      }}
      contentBoxProps={{
        gap: 4,
      }}
    />
  );
};
