import { BANK_FORM_FIELDS } from '@/constants/bank';

import { useGetCurrencies } from '@/hooks';
import { usePaymentsRequirements } from '@/hooks/usePaymentsRequirements';
import { BankFormService } from '@/services/BankFormService';
import { BankDialogFormData, CountryItem } from '@/types/bank';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { COUNTRIES_OPTIONS } from '@treyd-io/core/constants/country';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { useFormikContext } from 'formik';
import { isEmpty, isUndefined, keys, map } from 'lodash';
import { useEffect, useMemo } from 'react';
import { BankFields } from './BankFields';

export const BankFormBody = ({
  entityCountry,
  setValidationSchema,
}: {
  entityCountry: string;
  setValidationSchema: React.Dispatch<
    React.SetStateAction<NonNullable<unknown>>
  >;
}) => {
  const currencies = useGetCurrencies();
  const { values, errors, setFieldValue } =
    useFormikContext<BankDialogFormData>();

  const { data, loading } = usePaymentsRequirements({
    bankCountry: values.bank_country,
    currency: values.currency,
    beneficiaryCountry: entityCountry,
  });

  const beneficiaryRequirements = data?.banking?.awx_beneficiary_requirements;

  const BankForm = useMemo(
    () =>
      beneficiaryRequirements &&
      new BankFormService({
        beneficiaryRequirements,
        currency: values.currency,
        country: values.bank_country,
      }),
    [beneficiaryRequirements, values.currency, values.bank_country]
  );

  const paymentTypes = BankForm?.getPaymentTypes();
  const bankFields = BankForm?.getBankFields(values.payment_type);

  const isCountryNotSupported =
    isEmpty(paymentTypes) && !isUndefined(paymentTypes);
  const countryError = isCountryNotSupported
    ? 'Country not supported'
    : errors.bank_country;

  const bankFieldsKeys = keys(bankFields) as BANK_FORM_FIELDS[];
  // We set the combination_id whenever the payment_type changes
  useMemo(() => {
    const combinationId = paymentTypes?.find(
      (paymentType) => paymentType.label === values.payment_type
    )?.combinationId;
    setFieldValue(BANK_FORM_FIELDS.combinationId, combinationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.payment_type]);

  useEffect(() => {
    const validationSchema =
      BankForm?.getValidationSchema(bankFieldsKeys) || {};
    return setValidationSchema(validationSchema);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleReset = () => {
    map(bankFieldsKeys, (field) => setFieldValue(field, ''));
  };

  const handleCountryChange = (item: CountryItem | null) => {
    const selectedBankCountry = item && item.value ? item.value : '';

    setFieldValue(BANK_FORM_FIELDS.bankCountry, selectedBankCountry);
    setFieldValue(BANK_FORM_FIELDS.paymentType, '');
    handleReset();
  };

  return (
    <Row>
      <Col xs={12} sm={4}>
        <FormControl fullWidth key="currency">
          <InputLabel id="currency">{'Currency'}</InputLabel>
          <Select
            fullWidth
            labelId="currency"
            name="currency"
            label="Currency"
            onChange={(e) => {
              setFieldValue(BANK_FORM_FIELDS.currency, e.target.value);
              setFieldValue(BANK_FORM_FIELDS.paymentType, '');
              handleReset();
            }}
            value={values.currency || ''}>
            {currencies?.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
      <Col xs={12} sm={8}>
        <Autocomplete
          options={COUNTRIES_OPTIONS}
          value={
            COUNTRIES_OPTIONS.find(
              (item) => item.value === values.bank_country
            ) || null
          }
          fullWidth
          getOptionLabel={(option) => option.title || ''}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          onChange={(_e, item) => handleCountryChange(item)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              name="bank_country"
              label={'Bank country'}
              error={Boolean(errors.bank_country) || isCountryNotSupported}
              helperText={countryError}
            />
          )}
        />
      </Col>
      <Col xs={12} sm>
        <FormControl fullWidth key="payment_type">
          <InputLabel id="payment_type">{'Payment type'}</InputLabel>
          <Select
            fullWidth
            labelId="payment_type"
            name="payment_type"
            label="Payment type"
            disabled={loading || isCountryNotSupported}
            onChange={async (e) => {
              setFieldValue(BANK_FORM_FIELDS.paymentType, e.target.value);
              handleReset();
            }}
            value={values.payment_type || ''}>
            {map(paymentTypes, (paymentType) => (
              <MenuItem key={paymentType.label} value={paymentType.label}>
                {paymentType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
      {bankFields && <BankFields bankFields={bankFields} />}
    </Row>
  );
};
