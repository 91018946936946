import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { ROUTES } from '@/constants';
import {
  Alert,
  alertClasses,
  AlertTitle,
  Box,
  GridProps,
  IconButton,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import ArrowUpRightIcon from '@treyd-io/treyd-ui/icons/ArrowUpRightIcon';
import BuildingStoreIcon from '@treyd-io/treyd-ui/icons/BuildingStoreIcon';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import CloseCircleIcon from '@treyd-io/treyd-ui/icons/CloseCircleIcon';
import TruckIcon from '@treyd-io/treyd-ui/icons/TruckIcon';
import { format, formatDistanceToNow } from 'date-fns';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { CONTRACTOR_TYPE, HeaderProps, OrderContractorProps } from './types';

const MetaPart = ({
  title,
  subtitle,
  colProps,
}: {
  title: string;
  subtitle?: ReactNode;
  colProps?: GridProps;
}) => (
  <Col xs={6} md={4} {...colProps}>
    <Stack gap={0.5}>
      <Typography color="text.secondary" variant="body2">
        {title}
      </Typography>
      {typeof subtitle === 'string' ? (
        <Typography
          color="text.primary"
          variant="body1"
          sx={{ fontVariantNumeric: 'tabular-nums' }}>
          {subtitle || '-'}
        </Typography>
      ) : (
        subtitle
      )}
    </Stack>
  </Col>
);

export const OrderContractor = (props: OrderContractorProps) => {
  const isMerchant = props.type === CONTRACTOR_TYPE.MERCHANT;
  return (
    <Stack gap={3}>
      <Header
        merchantId={props.merchantId}
        supplierId={props.supplierId}
        name={props.name}
        type={props.type}
        isApproved={props.isApproved}
        date={props.date}
        merchantUid={props.merchantUid}
      />

      {!isMerchant && (
        <Box>
          {props.supplierId ? (
            <Row spacing={{ xs: 2, md: 3 }}>
              {props.date ? (
                <MetaPart
                  title="Screened"
                  subtitle={`${formatDistanceToNow(props.date, {
                    includeSeconds: true,
                  })} ago (${format(props.date, 'yyyy-MM-dd')})`}
                />
              ) : (
                <MetaPart
                  title="Screened"
                  subtitle={
                    <Typography variant="body1" color="error.main">
                      Unscreened
                    </Typography>
                  }
                />
              )}
              <MetaPart
                title="Treyd payments to supplier"
                subtitle={
                  <MuiLink
                    component={Link}
                    to={`${ROUTES.suppliers}/${props.supplierId}#orders`}>
                    {props.ordersCount}↗
                  </MuiLink>
                }
              />
              {Boolean(props.supplierNote) ? (
                <Col xs={12}>
                  <NoteAlert note={props.supplierNote} />
                </Col>
              ) : (
                <MetaPart
                  colProps={{ xs: 12, md: 12 }}
                  title="Note"
                  subtitle={
                    <>
                      <Typography variant="body1">
                        No note to display.{' '}
                      </Typography>
                      <MuiLink
                        component={Link}
                        to={`${ROUTES.suppliers}/${props.supplierId}#notes`}>
                        Add new note ↗
                      </MuiLink>
                    </>
                  }
                />
              )}
            </Row>
          ) : (
            <Typography color="text.secondary" variant="body1">
              No supplier selected
            </Typography>
          )}
        </Box>
      )}
      {isMerchant && (
        <Row spacing={{ xs: 2, md: 3 }}>
          <MetaPart
            title="Treyd rating"
            subtitle={
              <Typography variant="body1" color="success.main">
                {props.rating || 0}/10
              </Typography>
            }
          />
          <MetaPart title="Available credit" subtitle={props.remainingCredit} />
          {props.date && (
            <MetaPart
              title={'Onboarded'}
              subtitle={`${formatDistanceToNow(props.date, {
                includeSeconds: true,
              })} ago (${format(props.date, 'yyyy-MM-dd')})`}
            />
          )}
          {Boolean(props.merchantNote) ? (
            <Col xs={12}>
              <NoteAlert note={props.merchantNote} />
            </Col>
          ) : (
            <MetaPart
              colProps={{ xs: 12, md: 12 }}
              title="Note"
              subtitle={
                <>
                  <Typography variant="body1">No note to display. </Typography>
                  <MuiLink
                    component={Link}
                    to={`${ROUTES.merchants}/${props.merchantId}?actions=notes`}>
                    Add new note ↗
                  </MuiLink>
                </>
              }
            />
          )}
        </Row>
      )}
    </Stack>
  );
};

const Header = (props: HeaderProps) => {
  const isMerchant = props.type === CONTRACTOR_TYPE.MERCHANT;
  if (isMerchant) {
    return (
      <Stack gap={1.5}>
        <Stack direction="row" alignItems="center" gap={1}>
          <BuildingStoreIcon fontSize="medium" />
          <Typography variant="subtitle1">{props.type}</Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Typography variant="h5">{props.name}</Typography>
          <IconButton
            color="primary"
            size="small"
            href={`${ROUTES.merchants}/${props.merchantId}`}
            LinkComponent={(props) => <Link to={props.href} {...props} />}>
            <ArrowUpRightIcon />
          </IconButton>
        </Stack>
      </Stack>
    );
  }

  const STATUS = props.isApproved
    ? 'success'
    : props.date
    ? 'error'
    : 'primary';

  const ICON = {
    success: <CheckCircleIcon color="success" />,
    error: <CloseCircleIcon color="error" />,
    primary: <TruckIcon />,
  };

  return (
    <Stack gap={1.5}>
      <Stack direction="row" alignItems="center" gap={1}>
        {ICON[STATUS]}
        <Typography variant="subtitle1" color={`${STATUS}.main`}>
          {props.type}
        </Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Typography variant="h5" color={`${STATUS}.main`}>
          {props.name}
        </Typography>
        <IconButton
          color={STATUS}
          size="small"
          href={`${ROUTES.suppliers}/${props.supplierId}`}
          LinkComponent={(props) => <Link to={props.href} {...props} />}>
          <ArrowUpRightIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

const NoteAlert = ({ note }: { note?: string }) => {
  return (
    <Alert
      severity="info"
      sx={{
        maxWidth: 720,
        [`& .${alertClasses.message}`]: {
          paddingBlockEnd: 1.5,
        },
      }}>
      <AlertTitle>Note</AlertTitle>
      <Typography color="text.primary" variant="body2" component={'pre'}>
        {note}
      </Typography>
    </Alert>
  );
};
