import { Orders_Bank_Accounts } from '@/__generated__/graphql';
import { FIELD_TITLE, IDENTIFYING_CODES } from '@/constants';
import { CompanyBankAccount } from '@/schemas/getCompanyBankAccounts';
import { BankDialogFormData, BankIdentifyingCode } from '@/types';
import { getCountryName } from '@treyd-io/core/utils/country';
import {
  isArray,
  isEmpty,
  map,
  pickBy,
  reduce,
  startCase,
  toLower,
  toString,
  upperCase,
} from 'lodash';

const LABELS: Record<string, string> = {
  bank_account_holder_name: 'Beneficiary name',
  bank_country: 'Bank country',
  bic_swift: 'BIC/SWIFT',
  account_number: 'Account number',
  clearing_number: 'Clearing no.',
  bank_code: 'Bank code',
  cnaps_code: 'CNAPS code',
  routing_code: 'Routing code',
  acct_number: 'Account number',
  aba_code: 'ABA',
  clabe_code: 'CLABE code',
  bsb_code: 'BSB code',
  plusgiro_number: 'PlusGiro code',
  bankgiro_number: 'BankGiro number',
  institution_number: 'Institution no.',
  ifsc_code: 'IFSC code',
  currency: 'Currency',
  bankgiro: 'Bankgiro',
  swift: 'SWIFT',
  iban: 'IBAN',
};

const createBankInfoMapper = (account: Partial<Orders_Bank_Accounts>) => {
  const excludedKeys = [
    '__typename',
    'id',
    'combination_id',
    'is_valid',
    'created_at',
    'payment_type',
    'error_msgs',
    'type',
    'updated_at',
    'remittance_text',
    'compliance',
    'awx_bene_val_errors',
  ];

  if (toLower(account.currency) !== 'sek')
    excludedKeys.push(...['bankgiro', 'plusgiro']);

  const viewableKeys = pickBy(account, (_, key) => {
    return !excludedKeys.includes(key);
  });

  const bankInfoMapper = reduce(
    viewableKeys,
    (acc: Record<string, string>, _, key) => {
      const label = LABELS[key] || startCase(key);
      acc[key] = label;
      return acc;
    },
    {}
  );

  return bankInfoMapper;
};

export const getBankInfo = <T extends Record<string, any>>(
  BankAccountInfo: T
) => {
  const bankInfoMapper = createBankInfoMapper(BankAccountInfo);
  const bankInfo = Object.entries(bankInfoMapper).map(([key, label]) => {
    if (key === 'currency')
      return {
        label,
        value: upperCase(BankAccountInfo[key]),
      };

    if (key.includes('country'))
      return {
        label,
        value: getCountryName(toString(BankAccountInfo[key])),
      };

    return {
      label,
      value: BankAccountInfo[key],
    };
  });

  return bankInfo;
};

export const getBankAccountFields = (bankAccount: CompanyBankAccount) => {
  return [
    {
      label: LABELS['bank_account_holder_name'],
      value: toString(bankAccount?.bank_account_holder_name),
    },
    {
      label: LABELS['acct_number'],
      value: toString(bankAccount?.acct_number || bankAccount.iban),
    },
    {
      label: LABELS['bankgiro_number'],
      value: toString(bankAccount?.bankgiro_number),
    },
    {
      label: LABELS['plusgiro_number'],
      value: toString(bankAccount?.plusgiro_number),
    },
    {
      label: LABELS['iban'],
      value: toString(bankAccount?.acct_number)
        ? toString(bankAccount?.iban)
        : '',
    },
    {
      label: LABELS['bank_name'],
      value: toString(bankAccount?.bank_name),
    },
    {
      label: LABELS['bank_code'],
      value: toString(bankAccount?.bank_code),
    },
    {
      label: LABELS['swift_code'],
      value: toString(bankAccount?.bic_swift),
    },
    {
      label: LABELS['bank_address'],
      value: toString(bankAccount?.bank_address),
    },
    {
      label: LABELS['bsb_code'],
      value: toString(bankAccount?.bsb_code),
    },
    {
      label: LABELS['aba_code'],
      value: toString(bankAccount?.aba),
    },
    {
      label: LABELS['clabe_code'],
      value: toString(bankAccount?.clabe),
    },
    {
      label: LABELS['cnaps_code'],
      value: toString(bankAccount?.cnaps),
    },
    {
      label: LABELS['ifsc_code'],
      value: toString(bankAccount?.ifsc),
    },
    {
      label: LABELS['institution_number'],
      value: toString(bankAccount?.institution_no),
    },
  ];
};
export const getBankIdentifyingCode = (
  bankFormData: BankDialogFormData
): BankIdentifyingCode =>
  reduce(
    IDENTIFYING_CODES,
    (result, code) => {
      const value = bankFormData?.[code];
      if (value && isEmpty(result)) {
        return {
          identifierType: code,
          identifierValue: value,
          bankCountry: bankFormData?.bank_country,
          accountNumber: bankFormData?.iban || bankFormData?.acct_number || '',
        };
      }
      return result;
    },
    {} as BankIdentifyingCode
  );

export const formatErrorMessage = (key: string, error?: string) => {
  if (!error) {
    return '';
  }
  if (isArray(error)) {
    const message = map(error, (err: string) => {
      return err?.split(':')[1];
    });
    return message?.join(', ')?.replace(`${key}`, FIELD_TITLE[key]);
  }
  return error;
};
