import { INVOICE_DETAILS_TABS } from '@/constants';
import { useGetInvoiceById } from '@/hooks';
import { CircularProgress, Stack, Tab, Tabs } from '@mui/material';
import { toNumber } from 'lodash';
import { ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvoiceDetailsHeader } from './InvoiceDetailsHeader';
import { InvoiceMainDetails } from './InvoiceMainDetails';
import { InvoicePreview } from './InvoicePreview';
import { InvoiceRepaymentBreakdown } from './InvoiceRepaymentBreakdown';
import { Transactions } from './Transactions';
// eslint-disable-next-line @typescript-eslint/no-redeclare
interface Tab {
  label: ReactNode;
  icon?: string | React.ReactElement;
}

export const InvoiceDetailsPage = () => {
  const params = useParams<{ id: string }>();
  const id = params.id;
  const [activeTab, setActiveTab] = useState<INVOICE_DETAILS_TABS>(
    INVOICE_DETAILS_TABS.DETAILS
  );

  const { data: invoice, isFetching } = useGetInvoiceById(toNumber(id));

  const TABS: Tab[] = [
    {
      label: 'Details',
    },
    {
      label: 'Preview',
    },
    {
      label: 'Transactions',
    },
  ];

  if (isFetching) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    invoice && (
      <Stack direction="column" minHeight="100%">
        <InvoiceDetailsHeader
          invoiceNumber={invoice?.invoice_number}
          status={invoice?.ops_status}
        />
        <Tabs
          value={activeTab}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            marginBottom: 3,
            marginTop: 1,
          }}>
          {TABS.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              onClick={() => setActiveTab(index)}
            />
          ))}
        </Tabs>
        {activeTab === INVOICE_DETAILS_TABS.DETAILS && (
          <>
            <InvoiceMainDetails invoice={invoice} />
            <InvoiceRepaymentBreakdown invoice={invoice} />
          </>
        )}
        {activeTab === INVOICE_DETAILS_TABS.PREVIEW && (
          <InvoicePreview invoiceUrl={invoice?.pdf_url} />
        )}
        {activeTab === INVOICE_DETAILS_TABS.TRANSACTIONS && (
          <Transactions invoiceNumber={invoice?.invoice_number} />
        )}
      </Stack>
    )
  );
};
