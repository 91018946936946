import { Button, styled, Typography } from '@mui/material';
import UserCircleIcon from '@treyd-io/treyd-ui/icons/UserCircleIcon';

export const GettingStarted = styled('div')(({ theme }) => ({
  marginBlockEnd: theme.spacing(15),
  [theme.breakpoints.down('sm')]: {
    marginBlockEnd: theme.spacing(3),
  },
}));

interface StartReviewProps {
  onClickReview: () => void;
}
const StartReview = ({ onClickReview }: StartReviewProps) => (
  <GettingStarted>
    <Typography sx={{ marginBlockEnd: '0.5rem' }} variant="h4">
      Get started
    </Typography>
    <Typography sx={{ marginBlockEnd: '1rem' }} variant="body1">
      Starting the review process will assign the order to yourself.
    </Typography>
    <Button
      variant="contained"
      startIcon={<UserCircleIcon />}
      onClick={onClickReview}
      sx={{ position: 'inherit' }}
      size="large"
      style={{ boxShadow: 'none' }}>
      Start reviewing
    </Button>
  </GettingStarted>
);

export default StartReview;
