import { InvoiceOpsStatusChip } from '@/components';
import { INVOICE_OPS_STATUS } from '@/constants';
import { useBrowsingHistory } from '@/hooks/useBrowsingHistory';
import { Button, Stack, Typography } from '@mui/material';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';
export const InvoiceDetailsHeader = ({
  invoiceNumber,
  status,
}: {
  invoiceNumber: string;
  status: INVOICE_OPS_STATUS;
}) => {
  const { goBack } = useBrowsingHistory();
  return (
    <Stack gap={2} width="100%" alignItems="start">
      <Button
        variant="text"
        color="info"
        startIcon={<ArrowLeftIcon />}
        onClick={goBack}>
        Back
      </Button>
      <Stack direction="row" gap={2} alignItems={'center'}>
        <Typography variant={'h4'}>Invoice #{invoiceNumber}</Typography>
        <InvoiceOpsStatusChip status={status} />
      </Stack>
    </Stack>
  );
};
