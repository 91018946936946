import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ChevronLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="ChevronLeftIcon"
      {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M13.25 8.75L9.75 12L13.25 15.25"
      />
    </SvgIcon>
  );
}
