import { editDealReview } from '@/api';
import { useCallback, useEffect, useState } from 'react';

export const useOrderReview = (
  orderId: number,
  activeOrderReview: any
): {
  orderReviewData: OrderReviewData;
  checkSection: (section_name: SectionName, value: boolean) => void;
} => {
  const [orderReviewData, setOrderReviewData] = useState(
    activeOrderReview?.data
  );
  useEffect(() => {
    setOrderReviewData(activeOrderReview?.data);
  }, [activeOrderReview]);

  const checkSection = useCallback(
    (section_name: SectionName, value: boolean) => {
      const newSectionValue = {
        ...orderReviewData?.[section_name],
        checked: value,
      };
      editDealReview(orderId, {
        ...orderReviewData,
        [section_name]: newSectionValue,
      });
      setOrderReviewData({
        ...orderReviewData,
        [section_name]: newSectionValue,
      });
    },
    [orderReviewData, orderId]
  );

  return { orderReviewData, checkSection };
};

export type SectionName = 'invoice' | 'amounts';

export type OrderReviewData = {
  [index in SectionName]: {
    checked: boolean;
  };
};
