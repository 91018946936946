import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ArrowLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="ArrowLeftIcon"
      {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.25 6.75L4.75 12L10.25 17.25"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.25 12H5"
      />
    </SvgIcon>
  );
}
