export const FINANCIAL_FILES_TAG = 'financial_info';
export namespace PROFIT_AND_LOSS_DOCUMENTS_TAGS {
  export const LAST_FISCAL_YEAR = 'financial_info_profit_last_fiscal_year';
  export const PROFIT_INTERIM_REPORT = 'financial_info_profit_interim_report';
  export const NET_SALES = 'financial_info_profit_net_sales';
}

export namespace BALANCE_SHEET_DOCUMENTS_TAGS {
  export const BALANCE_SHEET = 'financial_info_balance_sheet';
}

export namespace SUPPORTING_FILES_DOCUMENTS_TAGS {
  export const OTHER_SUPPORTING_FILES = 'financial_info_other_supporting_files';
}

export namespace BANK_STATEMENT_DOCUMENTS_TAGS {
  export const LAST_THREE_MONTHS =
    'financial_info_bank_statements_last_three_months';
}

export const PERSONAL_LIABILITY_TEMPLATE_ID = 26;
export const GUARANTEE_UNDERTAKING_TEMPLATE_ID = 33;
export const STATEMENT_OF_ASSETS_LIABILITIES_TEMPLATE_ID = 30;
export const CHARGE_TEMPLATE_ID = 31;
