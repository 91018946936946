import { TypedDocumentNode, gql } from '@apollo/client';

export type UpsertAttachmentMutationVariables = {
  dataurl: string | ArrayBuffer | null;
  filename: string;
  orgnr: string;
  tag?: string;
  description?: string;
  country: string;
};

export type UpsertAttachmentMutationType = {
  django: {
    upsert_attachment: {
      attachment: {
        description: string;
        id: number;
        name: string;
        tag: string;
        url: string;
      };
    };
  };
};

export const upsertAttachmentMutation = gql`
  mutation upsertAttachment(
    $dataurl: String!
    $filename: String!
    $orgnr: String!
    $id: Int = null
    $tag: String = ""
    $description: String = ""
    $country: String!
  ) {
    django {
      upsert_attachment(
        dataurl: $dataurl
        filename: $filename
        orgnr: $orgnr
        id: $id
        tag: $tag
        description: $description
        country: $country
      ) {
        attachment {
          description
          id
          name
          tag
          url
        }
      }
    }
  }
` as TypedDocumentNode<
  UpsertAttachmentMutationType,
  UpsertAttachmentMutationVariables
>;
