import { gql, TypedDocumentNode, useQuery } from '@apollo/client';
import { Orders_Financiers } from '__generated__/graphql';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';

type GetFinanciersQuery = {
  orders_financiers: Array<Pick<Orders_Financiers, 'id' | 'name'>>;
};
const FinanciersSchema = gql`
  query getFinanciers {
    orders_financiers {
      id
      name
    }
  }
` as TypedDocumentNode<GetFinanciersQuery, {}>;

export const useFinanciersFilter = () => {
  const { data, loading } = useQuery(FinanciersSchema);
  const financiers = data?.orders_financiers;
  const availableFinanciersNames =
    financiers?.map((financier: any) => financier.name) || [];
  const getFinancierId = useCallback(
    (financierName: string) => {
      const financier = financiers?.find((f: any) => f.name === financierName);
      return financier?.id;
    },
    [financiers]
  );

  const formatFilters = useCallback(
    (filters: any) => {
      const formattedFilters = cloneDeep(filters);
      if (filters.financier__name) {
        formattedFilters.financier__id = formattedFilters.financier__name.map(
          (financier__name: string) => getFinancierId(financier__name)
        );
        delete formattedFilters.financier__name;
      }
      return formattedFilters;
    },
    [getFinancierId]
  );
  return { availableFinanciersNames, formatFilters, loading };
};
