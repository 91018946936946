import { TypedDocumentNode, gql } from '@apollo/client';

export type deleteAttachmentMutationType = {
  django: {
    delete_attachment: {
      deleted: boolean;
    };
  };
};

export type deleteAttachmentMutationVariables = {
  id: number;
};

export const deleteAttachmentMutation = gql`
  mutation deleteAttachment($id: Int!) {
    django {
      delete_attachment(id: $id) {
        deleted
      }
    }
  }
` as TypedDocumentNode<
  deleteAttachmentMutationType,
  deleteAttachmentMutationVariables
>;
