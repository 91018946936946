import { AppAbility, PermissionGroup } from '@/types';
import { useAuth } from 'auth';
import useUserPermissionsGroups from 'auth/permissions/schema';
import { AbilityContext } from 'context';
import { ReactNode } from 'react';

interface AbilityProviderProps {
  children: ReactNode | string;
  defineAbility: (userGroups?: PermissionGroup[]) => AppAbility;
}

export const AbilityProvider = ({
  children,
  defineAbility,
}: AbilityProviderProps) => {
  const { user } = useAuth();
  const { data, loading } = useUserPermissionsGroups({ user_id: user?.id });
  if (loading) return null;

  if (!defineAbility) {
    throw new Error('defineAbility is required');
  }

  const ability = defineAbility(data?.lager_treyd_users?.[0]?.user_groups);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
