import jsDownloadFile from 'js-file-download';
import { last, split } from 'lodash';
import qs from 'query-string';
import { FILE_EXTENSIONS } from '../constants/regex';

export const getFilenameFromUrl = (url: string) => {
  const path = url || '';
  const segments = split(path, '?')[0];
  const parts = split(segments, '/');
  const lastPart = last(parts) || '';
  return lastPart;
};

export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = String(reader.result).replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const downloadFile = async (url: string, title = 'file.pdf') => {
  const parsedUrl = qs.parseUrl(url);
  const newTitle = title.match(FILE_EXTENSIONS) ? title : title + '.pdf';

  if (parsedUrl.query.disposition) {
    parsedUrl.query.disposition = 'attachment';
    const fileUrl = qs.stringifyUrl(parsedUrl);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.setAttribute('download', newTitle);
    a.click();
    return;
  }
  const response = await fetch(url);
  const blobFile = await response.blob();
  jsDownloadFile(blobFile, newTitle);
};

export const fileToUrl = (blob: unknown) => {
  const pdfFile = new File([blob as BlobPart], 'aml.pdf', {
    type: 'application/pdf',
  });
  return URL.createObjectURL(pdfFile);
};
