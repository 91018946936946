import { gql } from '@apollo/client';

export const updateUserAuthProfile = gql`
  mutation updateUserAuthProfile(
    $profileId: bigint!
    $userData: lager_treyd_auth_profile_set_input!
  ) {
    update_lager_treyd_auth_profile_by_pk(
      pk_columns: { id: $profileId }
      _set: $userData
    ) {
      identity_verified
    }
  }
`;
