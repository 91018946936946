import { deleteCommentById } from '@/api';
import { CommentCard } from '@/components';
import { DecisionCard } from '@/pages/orderReview/OrderReviewDetails/ActivityLog/DecisionCard';
import GeneralUpdatesCard from '@/pages/orderReview/OrderReviewDetails/ActivityLog/GeneralUpdatesCard';
import { formatDate, newISODate } from '@treyd-io/core/utils/date';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import UserCircleCheckIcon from '@treyd-io/treyd-ui/icons/UserCircleCheckIcon';

const ActivityLogCard = ({
  log,
  refetch,
  can_delete,
}: {
  log: any;
  can_delete: boolean;
  refetch: () => {};
}) => {
  const getDecisionCardStatus = (message: string, user?: any) => {
    let severity: 'success' | 'error' | 'warning';
    let title = '';
    if (message === 'approve') {
      severity = 'success';
      title = `Approved by ${user?.first_name} ${user?.last_name}`;
    } else if (message === 'reject') {
      severity = 'error';
      title = `Rejected by ${user?.first_name} ${user?.last_name}`;
    } else {
      severity = 'warning';
      title = `Sent back by ${user?.first_name} ${user?.last_name}`;
    }
    return { severity, title };
  };
  const showNotificationMessage = useToastNotification();

  const deleteComment = async (id: number) => {
    try {
      await deleteCommentById(id);
      refetch();
    } catch (err) {
      showNotificationMessage({
        title: 'Something went wrong.',
        type: 'error',
      });
    }
  };

  return (
    <>
      {log.type === 'comment' && (
        <CommentCard
          comment={log.message}
          date={formatDate(new Date(log.created), 'daytime')}
          userName={`${log.user.first_name} ${log.user.last_name}`}
          onDelete={can_delete ? () => deleteComment(log.id) : undefined}
        />
      )}
      {log.type === 'review' && !log.message && (
        <GeneralUpdatesCard
          title={`Assigned to ${log.user.first_name} ${log.user.last_name}`}
          date={newISODate(log.created)}
          icon={<UserCircleCheckIcon />}
        />
      )}
      {log.type === 'review' && log.message && (
        <DecisionCard
          title={getDecisionCardStatus(log.message, log.user).title}
          comment={log.comment}
          reason={log?.reason}
          color={getDecisionCardStatus(log.message).severity}
          date={newISODate(log.created)}
        />
      )}
      {log.type === 'log' && log.message && (
        <GeneralUpdatesCard
          title={log.message}
          date={newISODate(log.created)}
        />
      )}
    </>
  );
};
export default ActivityLogCard;
