import { Orders_Vouchers_App_Voucher } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

export type OrderVoucher = Pick<
  Orders_Vouchers_App_Voucher,
  'slug' | 'value' | 'currency'
>;
export type GetOrderVouchersQueryVariables = {
  orderId: number;
};
export type GetOrderVouchersType = {
  orders_vouchers_app_voucher: Array<OrderVoucher>;
};

export const getOrderVouchersQuery = gql`
  query getOrderVouchers($orderId: bigint!) {
    orders_vouchers_app_voucher(
      where: {
        _and: { order_id: { _eq: $orderId }, status: { _eq: "applied" } }
      }
    ) {
      slug
      value
      currency
    }
  }
` as TypedDocumentNode<GetOrderVouchersType, GetOrderVouchersQueryVariables>;
