import NavTabsBar, { TabType } from '@/components/NavTabsBar';
import { Stack, Typography } from '@mui/material';
import { EmailsPage } from './Tabs/emails/EmailsPage';
import { NotificationsPage } from './Tabs/notifications/NotificationsPage';

export const NotificationsAndEmails = () => {
  const tabs: TabType[] = [
    {
      id: 'notifications',
      label: 'Notifications',
      content: <NotificationsPage />,
    },
    {
      id: 'emails',
      label: 'Emails',
      content: <EmailsPage />,
    },
  ];

  return (
    <Stack flexGrow={1} gap={1} height="100%">
      <Stack>
        <Typography variant="h4">Notifications & Emails</Typography>
      </Stack>
      <Stack flexGrow={1} height="100%">
        <NavTabsBar tabs={tabs} defaultTabId={'notifications'} />
      </Stack>
    </Stack>
  );
};
