import OrderContractors from '@/pages/orderReview/OrderReviewDetails/OrderContractors';
import { OrderMetaDataSection } from '@/pages/orderReview/OrderReviewDetails/OrderMetaDataSection';
import { Divider, Stack } from '@mui/material';

export const OrderReviewInformationTab = (props: any) => {
  const { deal } = props;
  return (
    <Stack gap={5}>
      <OrderMetaDataSection order={deal} />
      <Divider />
      <OrderContractors deal={deal} />
    </Stack>
  );
};
