import { PAGE_SIZE } from '@/constants';
import {
  TableFilterFields,
  TableSettings,
  TableSettingsReducerAction,
  TableSettingsState,
} from '@/types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { QueryFilterField } from '@treyd-io/core/types/hasura';
import { isEmpty, toString } from 'lodash';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { createPath, useLocation, useNavigate } from 'react-router-dom';

export const useTableSettings = <T extends TableFilterFields>(
  tableId: string,
  initialArgs?: Partial<TableSettings<T>>
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultState: TableSettingsState<T> = {
    filterFields: [],
    sortModel: initialArgs?.sortModel || [{ field: 'id', sort: 'desc' }],
    paginationModel: { pageSize: PAGE_SIZE, page: 0 },
  };

  const reducer = (
    state: TableSettingsState<T>,
    action: TableSettingsReducerAction<T>
  ): TableSettingsState<T> => {
    switch (action.type) {
      case 'SET_FILTER_FIELDS':
        return { ...state, filterFields: action.payload };
      case 'SET_SORT_MODEL':
        return { ...state, sortModel: action.payload };
      case 'SET_PAGINATION_MODEL':
        return { ...state, paginationModel: action.payload };
      case 'RESET_TABLE':
        return defaultState;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...initialArgs,
  });

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (queryParams[tableId]) {
      try {
        const persistedState: TableSettingsState<T> = JSON.parse(
          atob(toString(queryParams[tableId]))
        );
        dispatch({
          type: 'SET_FILTER_FIELDS',
          payload: persistedState.filterFields,
        });
        dispatch({ type: 'SET_SORT_MODEL', payload: persistedState.sortModel });
        dispatch({
          type: 'SET_PAGINATION_MODEL',
          payload: persistedState.paginationModel,
        });
      } catch (error) {
        console.error('Error parsing persisted state:', error);
      }
    }
  }, [location.search, tableId]);

  const updateQueryParams = useCallback(
    (newState: Partial<TableSettingsState<T>>) => {
      const queryParams = {
        ...queryString.parse(location.search),
        [tableId]: btoa(JSON.stringify(newState)),
      };

      if (isEmpty(newState)) delete queryParams[tableId];
      navigate(
        createPath({
          pathname: location.pathname,
          search: queryString.stringify(queryParams),
          hash: location.hash,
        })
      );
    },
    [location, tableId, navigate]
  );

  const dispatchers = useMemo(
    () => ({
      setFilterFields: (filters: QueryFilterField<T>[]) => {
        updateQueryParams({ ...state, filterFields: filters });
        dispatch({ type: 'SET_FILTER_FIELDS', payload: filters });
      },
      setSortModel: (sortModel: GridSortModel) => {
        updateQueryParams({ ...state, sortModel });
        dispatch({ type: 'SET_SORT_MODEL', payload: sortModel });
      },
      setPaginationModel: (paginationModel: GridPaginationModel) => {
        updateQueryParams({ ...state, paginationModel });
        dispatch({ type: 'SET_PAGINATION_MODEL', payload: paginationModel });
      },
      resetTable: () => {
        updateQueryParams({});
        dispatch({ type: 'RESET_TABLE' });
      },
    }),
    [state, updateQueryParams]
  );

  return {
    ...state,
    ...dispatchers,
  };
};
