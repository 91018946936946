import { ORDER_REVIEW_VERDICT } from '@/constants';
import Order from '@/pages/orderReview/models/Order';
import {
  ActiveOrderReview,
  AutoOrderReviewResponse,
  OrderComplianceAutoReviewResponse,
  OrderEventPayload,
} from '@/types';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { get, save } from '@treyd-io/core/utils/request';
import {
  ACTIVE_ORDER_REVIEW,
  ADD_COMMENT,
  AUTO_ORDER_COMPLIANCE_REVIEW,
  AUTO_ORDER_REVIEW,
  CURRENCIES_URL,
  DEAL_DUPLICATES,
  DELETE_COMMENT,
  DOCUMENTS_URL,
  EDIT_ORDER_REVIEW,
  NOTIFY_NORDEA_TAG,
  ORDERS_URL,
  ORDER_EVENTS_URL,
  ORDER_SM_CAN,
  SELF_ASSIGN_ORDER,
  SUBMIT_ORDER_COMPLIANCE_REVIEW,
  SUBMIT_ORDER_FUNDING_REVIEW,
  SUBMIT_ORDER_REVIEW,
} from 'constants/api-endpoints';

export const getCurrencies = () => {
  return get<string[]>(CURRENCIES_URL);
};

interface DealResponse {
  deal: Order;
  errors: any;
}

interface OrderReview {
  verdict: ORDER_REVIEW_VERDICT;
  comment?: string;
  reason?: string;
}

interface OrderFundingReview {
  verdict: ORDER_REVIEW_VERDICT;
  comment?: string;
  reason?: string;
  data?: {
    financier_id: number;
  };
}

const ORDER_URL = (order_id: number): string => `${ORDERS_URL}/${order_id}/`;
type GetDealDuplicatesResponse = Order[];

interface CanDoEventOnOrderResponse {
  status: boolean;
  errors: {
    compliance: string[];
    transition: string[];
  };
}

export const updateOrderInOrderReview = (
  order_id: number,
  updated_data: any,
  request_options: any = { noToast: true }
) =>
  save<DealResponse>(
    ORDER_URL(order_id),
    updated_data,
    REQUEST_METHOD.PUT,
    request_options
  );

export const addComment = async ({
  orderId,
  comment,
}: {
  orderId: number;
  comment: string;
}) => save(ADD_COMMENT(orderId), { comment }, REQUEST_METHOD.POST);

export const deleteCommentById = async (commentId: number): Promise<any> => {
  await save(DELETE_COMMENT(commentId), {}, REQUEST_METHOD.DELETE, {});
  return null;
};

export const assignSelf = (deal_id: number) =>
  save(SELF_ASSIGN_ORDER(deal_id), {}, REQUEST_METHOD.POST, { noToast: true });

export const getDealDuplicates = async (deal_id: number) => {
  const resp = await get<GetDealDuplicatesResponse>(DEAL_DUPLICATES(deal_id));
  return resp.data;
};

export const submitOrderReview = async ({
  orderId,
  data,
}: {
  orderId: number;
  data: OrderReview;
}) =>
  await save(SUBMIT_ORDER_REVIEW(orderId), data, REQUEST_METHOD.PUT, {
    noToast: true,
  });

export const submitOrderComplianceReview = async ({
  orderId,
  data,
}: {
  orderId: number;
  data: OrderReview;
}) =>
  await save(
    SUBMIT_ORDER_COMPLIANCE_REVIEW(orderId),
    data,
    REQUEST_METHOD.PUT,
    {
      noToast: true,
    }
  );

export const submitOrderFundingReview = async ({
  orderId,
  fundingReviewData,
}: {
  orderId: number;
  fundingReviewData: OrderFundingReview;
}) =>
  await save(
    SUBMIT_ORDER_FUNDING_REVIEW(orderId),
    fundingReviewData,
    REQUEST_METHOD.PUT,
    {
      noToast: true,
    }
  );

export const autoDealComplianceReview = (orderId: number) =>
  get<OrderComplianceAutoReviewResponse>(AUTO_ORDER_COMPLIANCE_REVIEW(orderId));
export const editDealReview = async (deal_id: number, data: any) =>
  await save(EDIT_ORDER_REVIEW(deal_id), { data }, REQUEST_METHOD.PUT, {
    noToast: true,
  });

export const getOrderLoanAgreements = (orderId: number) =>
  get(DOCUMENTS_URL('orders', orderId));

export const getOrderOpsAutoReview = async (orderId: number) => {
  const resp = await get<AutoOrderReviewResponse>(AUTO_ORDER_REVIEW(orderId));
  return resp.data;
};

export const startEvent = ({ id, eventName }: OrderEventPayload) =>
  save(ORDER_EVENTS_URL(id, eventName), {}, REQUEST_METHOD.POST);

export const canDoEventOnOrder = ({ id, eventName }: OrderEventPayload) =>
  save<CanDoEventOnOrderResponse>(
    ORDER_SM_CAN(id, eventName),
    {},
    REQUEST_METHOD.POST
  );

export const getActiveOrderReview = (orderId: number) =>
  get<ActiveOrderReview>(ACTIVE_ORDER_REVIEW(orderId));

export const notifyNordeaTag = (orderId: number) =>
  save(NOTIFY_NORDEA_TAG(orderId), {}, REQUEST_METHOD.POST);
