import {
  useExporter,
  useGetOrderLoanAgreements,
  useGetRemainingLimit,
  useMerchant,
  useOrderById,
  useOrderInvoiceOcr,
} from '@/hooks';
import { useGetActiveOrderReview } from '@/hooks/useGetActiveOrderReview';
import { getPublishedCreditDecisionData } from '@/schemas';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { toNumber, toString } from 'lodash';
import { useParams } from 'react-router-dom';

const OrderReviewDetailsWrapper = ({ render }: { render: any }) => {
  const { id } = useParams();
  const { data: order, isLoading, refetch } = useOrderById(Number(id));
  const {
    data: orderLoanAgreementsData,
    isPending: isLoadingOrderLoanAgreements,
  } = useGetOrderLoanAgreements(toNumber(id));
  const { states } = useMerchant(toString(order?.importer_id));
  const { data: exporterData } = useExporter(toNumber(order?.exporter_id), {
    include_bank_data: false,
  });
  const { data: publishedCreditDecisionData, loading: creditDecisionLoading } =
    useQuery(getPublishedCreditDecisionData, {
      variables: { orgnr: states?.orgNo, country: states.country },
    });
  const { data: remainingLimitData, isPending: isRemainingLimitLoading } =
    useGetRemainingLimit(Number(order?.importer_id));
  const deal = {
    ...order,
    importer: {
      name: states.name,
    },
    exporter: {
      ...order?.exporter,
      country: exporterData?.country,
      full_address: exporterData?.full_address,
    },
    invoice_ref: order?.supplier_invoice?.invoice_ref,
    due_date: order?.supplier_invoice?.due_date,
    value: order?.supplier_invoice?.amount,
    currency: order?.supplier_invoice.currency,
    treyd_payment_to_supplier: order?.loan_amount,
  };
  const {
    data: activeOrderReview,
    isPending: reviewerLoading,
    refetch: refetchReviewer,
  } = useGetActiveOrderReview(Number(id));
  const {
    data: ocrData,
    loading: ocrLoading,
    handleOcrFieldUpdate,
  } = useOrderInvoiceOcr(Number(id));
  const loading =
    isLoading ||
    reviewerLoading ||
    ocrLoading ||
    creditDecisionLoading ||
    isLoadingOrderLoanAgreements;
  const reviewerName = `${activeOrderReview?.reviewer?.first_name} ${activeOrderReview?.reviewer?.last_name}`;
  const reviewerId = activeOrderReview?.reviewer_id;

  const { isMerchantFirstOrder } = states;

  if (isLoading || reviewerLoading || ocrLoading || isRemainingLimitLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }
  return render({
    id,
    deal,
    orderLoanAgreementsData,
    loading,
    fetchOrder: refetch,
    activeOrderReview,
    reviewerName,
    reviewerId,
    refetchReviewer,
    ocrData,
    handleOcrFieldUpdate,
    publishedCreditDecisionData,
    remainingLimitData,
    isMerchantFirstOrder,
  });
};
export default OrderReviewDetailsWrapper;
