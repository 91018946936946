import { TypedDocumentNode, gql } from '@apollo/client';
import { Orders_Notification_Center_Notification } from '__generated__/graphql';

type GetUpdatesByOrderIdVariables = {
  orderId: number;
};

type GetUpdatesByOrderIdType = {
  orders_notification_center_notification: Orders_Notification_Center_Notification[];
};

export const getUpdatesByOrderIdQuery = gql`
  query overviewUpdatesQuery($orderId: bigint!) {
    orders_notification_center_notification(
      where: {
        resource_id: { _eq: $orderId }
        resource_type: { _eq: "order" }
        notification_center_notificationgroup: {
          notification_template: {
            header: { _neq: "" }
            _or: [
              { action_url: { _eq: "" } }
              { action_url: { _is_null: true } }
            ]
          }
        }
      }
    ) {
      created
      notification_center_notificationgroup {
        notification_template {
          header
          body
        }
      }
    }
  }
` as TypedDocumentNode<GetUpdatesByOrderIdType, GetUpdatesByOrderIdVariables>;
