import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface TwoLinesProps {
  label: ReactNode;
  content: ReactNode;
  labelProps?: TypographyProps;
  contentProps?: TypographyProps;
  containerProps?: StackProps;
}

export const TwoLines = (props: TwoLinesProps) => {
  return (
    <Stack gap={0.5} {...props.containerProps}>
      {typeof props.label === 'string' ? (
        <Typography
          variant="body2"
          color="text.secondary"
          {...props.labelProps}>
          {props.label}
        </Typography>
      ) : (
        props.label
      )}
      {typeof props.content === 'string' ? (
        <Typography
          variant="body1"
          color="text.primary"
          noWrap
          title={props.content}
          {...props.contentProps}>
          {props.content}
        </Typography>
      ) : (
        props.content
      )}
    </Stack>
  );
};
