import Order from '@/pages/orderReview/models/Order';
import {
  Alert,
  AlertTitle,
  Stack,
  Typography,
  alertClasses,
} from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency, formatDecimal } from '@treyd-io/core/utils/number';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { toNumber, toString } from 'lodash';

const MetaPart = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string | number;
}) => {
  return (
    <Col xs={6} md={4}>
      <Stack gap={0.5}>
        <Typography color="text.secondary" variant="body2">
          {title}
        </Typography>
        <Typography
          color="text.primary"
          variant="body1"
          sx={{ fontVariantNumeric: 'tabular-nums' }}>
          {subtitle || '-'}
        </Typography>
      </Stack>
    </Col>
  );
};

export const OrderMetaDataSection = ({ order }: { order: Order }) => {
  const orderAdditionalNotes = toString(order?.additional_notes).trim();
  return (
    <Stack gap={3}>
      <Typography variant="h5">Payout</Typography>
      <Row spacing={{ xs: 2, md: 3 }}>
        <MetaPart
          title="Payout amount"
          subtitle={formatCurrency(
            toNumber(order.payouts[0]?.amount),
            toString(order.payouts[0]?.currency)
          )}
        />
        <MetaPart
          title="Expected payment date"
          subtitle={
            order.payouts?.[0]?.expected_payment_date
              ? formatDate(
                  new Date(order.payouts[0]?.expected_payment_date),
                  'date'
                )
              : undefined
          }
        />
        <MetaPart
          title="FX rate"
          subtitle={formatDecimal(
            toNumber(order.payouts[0]?.fx_rate_to_invoicing_currency)
          )}
        />
        <MetaPart
          title="Repayment amount"
          subtitle={formatCurrency(
            toNumber(order.total_repayment_amount_in_invoicing_currency),
            order.invoicing_currency
          )}
        />
        <MetaPart
          title="Expected repayment date"
          subtitle={
            order.payouts?.[0]?.expected_repayment_date
              ? `${formatDate(
                  new Date(order.payouts[0]?.expected_repayment_date),
                  'date'
                )} (${
                  order?.payouts?.[0]?.payment_term?.tenor_in_months
                } months)`
              : undefined
          }
        />
        <MetaPart
          title="Internal order reference"
          subtitle={order.supplier_invoice.order_ref}
        />
        {Boolean(orderAdditionalNotes) && (
          <Col xs={12}>
            <Alert
              severity="info"
              sx={{
                maxWidth: 720,
                [`& .${alertClasses.message}`]: {
                  paddingBlockEnd: 1.5,
                },
              }}>
              <AlertTitle>Additional note from merchant</AlertTitle>
              <Typography
                color="text.primary"
                variant="body2"
                component={'pre'}>
                {orderAdditionalNotes}
              </Typography>
            </Alert>
          </Col>
        )}
      </Row>
    </Stack>
  );
};
