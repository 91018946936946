/* eslint-disable @typescript-eslint/no-use-before-define */
import { TimelineItem } from '@/pages/merchants/MerchantsDetails/Tabs/GeneralInfo/TimelineItem';
import { Codes, LimitStatus, LimitStatusSegmentArgs, Segment } from '@/types';
import {
  Alert,
  AlertTitle,
  Link as MuiLink,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { intersection, isEmpty, map, reduce } from 'lodash';
import { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LimitStatusService } from '../LimitStatusService';

export const ComplianceSegmentMapper = (
  segment: LimitStatus.Segments['compliance']
): Segment.Values => {
  const {
    KYC_SUBMITTED: kycSubmitted,
    KYC_EXPIRING_SOON: kycExpiringSoon,
    COMPLIANCE_DECISION_APPROVED: complianceDecisionApproved,
    KYC_REVOKED: kycRevoked,
  } = reduce(
    segment.uiCodes,
    (
      acc: Partial<Record<Codes.Compliance, LimitStatusSegmentArgs.Compliance>>,
      uiCode
    ) => {
      acc[uiCode.code] = uiCode.kargs;
      return acc;
    },
    {}
  );

  const WARNING_CREDIT_CODES: Codes.Compliance[] = ['KYC_EXPIRING_SOON'];

  const POINTS_COMPONENTS: Record<Codes.Compliance, ReactNode> = {
    COMPLIANCE_NOT_STARTED: <BulletPoints.ComplianceNotStarted />,
    COMPLIANCE_DECISION_STARTED: <BulletPoints.ComplianceDecisionStarted />,
    UBO_NOT_INVITED: <BulletPoints.UboNotInvited />,
    KYC_NOT_SUBMITTED: <BulletPoints.KycNotSubmitted />,
    KYC_SUBMITTED: (
      <BulletPoints.KycSubmitted
        submittedAt={kycSubmitted?.submitted_at}
        submittedBy={kycSubmitted?.submitted_by}
      />
    ),
    KYC_EXPIRING_SOON: (
      <BulletPoints.KycExpiringSoon
        expirationDate={kycExpiringSoon?.expiration_date}
      />
    ),
    COMPLIANCE_DECISION_APPROVED: (
      <BulletPoints.ComplianceDecisionApproved
        approvedAt={complianceDecisionApproved?.approved_at}
      />
    ),
    COMPLIANCE_DECISION_INACTIVE: <BulletPoints.ComplianceDecisionInactive />,
    KYC_REVOKED: <BulletPoints.KycRevoked revokedAt={kycRevoked?.revoked_at} />,
    COMPLIANCE_DECISION_DECLINED: <BulletPoints.ComplianceDecisionDeclined />,
    AT_LEAST_ONE_OTHER_USER_NEEDS_TO_BE_INVITED: (
      <BulletPoints.OneMoreUserNeedsToBeInvited />
    ),
    SIGNATORY_NOT_INVITED: <BulletPoints.SignatoryNotInvited />,
    ONE_OTHER_UBO_OR_SIGNATORY_NEEDS_TO_BE_INVITED: (
      <BulletPoints.OneOtherUboOrSignatoryNeedsToBeInvited />
    ),
  };

  const ALERTS_COMPONENTS: Partial<Record<Codes.Compliance, ReactNode>> = {
    KYC_EXPIRING_SOON: (
      <Alerts.UpcomingKYCRenewal
        expirationDate={kycExpiringSoon?.expiration_date}
      />
    ),
  };

  const points = map(
    segment.uiCodes,
    (uiCode) => POINTS_COMPONENTS[uiCode.code]
  );

  const alerts = map(
    segment.uiCodes,
    (uiCode) => ALERTS_COMPONENTS[uiCode.code]
  );

  const icon = ((
    segment: LimitStatus.Segments['compliance']
  ): ReactElement<SvgIconProps> => {
    const codes = segment.uiCodes.map((uiCode) => uiCode.code);
    const status = segment.status;

    if (!isEmpty(intersection(codes, WARNING_CREDIT_CODES)))
      return LimitStatusService.ICON_COMPONENTS.WARNING;

    return LimitStatusService.ICON_COMPONENTS[status];
  })(segment);

  return {
    icon,
    points,
    alerts,
  };
};

namespace BulletPoints {
  export const ComplianceNotStarted = () => (
    <TimelineItem>
      <Typography variant="body1">
        Compliance decision has not started yet.
      </Typography>
    </TimelineItem>
  );

  export const ComplianceDecisionStarted = () => (
    <TimelineItem>
      <Typography variant="body1">Compliance decision has started.</Typography>
    </TimelineItem>
  );

  export const UboNotInvited = () => (
    <TimelineItem>
      <Typography variant="body1">No UBO user has been invited yet.</Typography>
    </TimelineItem>
  );

  export const KycNotSubmitted = () => (
    <TimelineItem>
      <Typography variant="body1">KYC is not submitted.</Typography>
    </TimelineItem>
  );

  export const KycSubmitted = ({
    submittedAt,
    submittedBy,
  }: {
    submittedAt: string | undefined;
    submittedBy: string | undefined;
  }) =>
    submittedAt &&
    (submittedBy ? (
      <TimelineItem>
        <Typography variant="body1">
          KYC is submitted on{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(submittedAt), 'longdate')}{' '}
          </Typography>
          by{' '}
          <Typography display={'inline'} variant="subtitle1">
            {submittedBy}
          </Typography>
          .{' '}
          <MuiLink component={Link} to={'#kyc'}>
            View KYC ↗︎
          </MuiLink>
        </Typography>
      </TimelineItem>
    ) : (
      <TimelineItem>
        <Typography variant="body1">
          KYC is submitted on{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(submittedAt), 'longdate')}
          </Typography>
          .{' '}
          <MuiLink component={Link} to={'#kyc'}>
            View KYC ↗︎
          </MuiLink>
        </Typography>
      </TimelineItem>
    ));

  export const KycExpiringSoon = ({
    expirationDate,
  }: {
    expirationDate: string | undefined;
  }) =>
    expirationDate && (
      <TimelineItem>
        <Typography variant="body1">
          KYC is expected to expire on{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(expirationDate), 'longdate')}
          </Typography>
          .
        </Typography>
      </TimelineItem>
    );

  export const ComplianceDecisionApproved = ({
    approvedAt,
  }: {
    approvedAt: string | undefined;
  }) =>
    approvedAt ? (
      <TimelineItem>
        <Typography variant="body1">
          Compliance decision is{' '}
          <Typography display={'inline'} variant="subtitle1">
            approved
          </Typography>{' '}
          on{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(approvedAt), 'longdate')}
          </Typography>
          .{' '}
          <MuiLink component={Link} to={'#compliance'}>
            View compliance decision ↗︎
          </MuiLink>
        </Typography>
      </TimelineItem>
    ) : (
      <TimelineItem>
        <Typography variant="body1">
          Compliance decision is{' '}
          <Typography display={'inline'} variant="subtitle1">
            approved
          </Typography>
          .{' '}
          <MuiLink component={Link} to={'#compliance'}>
            View compliance decision ↗︎
          </MuiLink>
        </Typography>
      </TimelineItem>
    );

  export const ComplianceDecisionInactive = () => (
    <TimelineItem>
      <Typography variant="body1">Compliance decision is inactive.</Typography>
    </TimelineItem>
  );

  export const KycRevoked = ({
    revokedAt,
  }: {
    revokedAt: string | undefined;
  }) =>
    revokedAt && (
      <TimelineItem>
        <Typography variant="body1">
          KYC is revoked at{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(revokedAt), 'longdate')}
          </Typography>
          .
        </Typography>
      </TimelineItem>
    );

  export const ComplianceDecisionDeclined = () => (
    <TimelineItem>
      <Typography variant="body1">
        Merchant has been declined due to unacceptable AML.
      </Typography>
    </TimelineItem>
  );

  export const OneMoreUserNeedsToBeInvited = () => (
    <TimelineItem>
      <Typography variant="body1">
        One more user is required to signup and verify.
      </Typography>
    </TimelineItem>
  );

  export const SignatoryNotInvited = () => (
    <TimelineItem>
      <Typography variant="body1">
        No signatory has been invited yet.
      </Typography>
    </TimelineItem>
  );

  export const OneOtherUboOrSignatoryNeedsToBeInvited = () => (
    <TimelineItem>
      <Typography variant="body1">
        One other UBO or signatory needs to be invited.
      </Typography>
    </TimelineItem>
  );
}

namespace Alerts {
  export const UpcomingKYCRenewal = ({
    expirationDate,
  }: {
    expirationDate: string | undefined;
  }) =>
    expirationDate ? (
      <TimelineItem alert>
        <Alert
          severity="warning"
          variant="standard"
          sx={{ width: '100%', marginTop: 3 }}>
          <AlertTitle>
            Upcoming KYC renewal on{' '}
            {formatDate(new Date(expirationDate), 'longdate')}
          </AlertTitle>
        </Alert>
      </TimelineItem>
    ) : (
      <TimelineItem alert>
        <Alert
          severity="warning"
          variant="standard"
          sx={{ width: '100%', marginTop: 3 }}>
          <AlertTitle>Upcoming KYC renewal</AlertTitle>
        </Alert>
      </TimelineItem>
    );
}
