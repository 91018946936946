import { CompanyStatus, LimitStatusSegmentArgs, Routes } from '@/types';
import { mapValues } from 'lodash';
import { ROUTES } from './routes';

export enum KYC_STATUS {
  DRAFT = 'draft',
  PREVIEW = 'preview',
  EDITABLE = 'editable',
}

const MERCHANTS_ROOT = ROUTES.merchants;

export const MERCHANTS_ROUTES: Routes = Object.freeze({
  root: '',
  ':id': `:id`,
});

export const MERCHANTS_PATHS = mapValues(
  MERCHANTS_ROUTES,
  (route) => `${MERCHANTS_ROOT}/${route}`
);

export const STATUS_ERROR_MESSAGES: Partial<Record<CompanyStatus, string>> = {
  FUNDING_NOT_SECURED: 'Funding is not secured.',
  BLOCKED_OVERDUE: 'Merchant has overdue invoices.',
  LIMIT_EXPIRED: 'Expired credit decision.',
  BLOCKED_KYC: 'KYC is not submitted.',
  CREDIT_INACTIVE: 'Credit decision is inactive.',
  BLOCKED_BUSINESS_PERSONS: 'No UBO user has been invited yet.',
};

export const COMPANY_LIMIT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  WILL_EXPIRE: 'WILL_EXPIRE',
  NO_CREDIT_DECISION: 'NO_CREDIT_DECISION',
  INDICATIVE: 'INDICATIVE',
};

export const COMPANY_STATUS = {
  ACTIVE: 'ACTIVE',
  BLOCKED_OVERDUE: 'BLOCKED_OVERDUE',
  BLOCKED_KYC: 'BLOCKED_KYC',
  FUNDING_NOT_SECURED: 'FUNDING_NOT_SECURED',
  LIMIT_EXPIRED: 'LIMIT_EXPIRED',
  CREDIT_INACTIVE: 'CREDIT_INACTIVE',
  BLOCKED_BUSINESS_PERSONS: 'BLOCKED_BUSINESS_PERSONS',
  NO_COMPLIANCE_DECISION: 'NO_COMPLIANCE_DECISION',
  REVIEW_BLOCKED: 'REVIEW_BLOCKED',
  CREDIT_INDICATIVE: 'CREDIT_INDICATIVE',
  KYC_WILL_EXPIRE: 'KYC_WILL_EXPIRE',
};

export enum CREDITS_REASONS {
  EARLY_PAYMENT_CREDIT = 'early_payment_credit',
  EARLY_PAYMENT_CREDIT_CANCELLED = 'early_payment_credit_cancelled',
  CREDIT_VOUCHER = 'credit_voucher',
  CREDIT_VOUCHER_CANCELLED = 'credit_voucher_cancelled',
  FAILED_PAYMENT = 'failed_payment',
  REFUND = 'refund',
  BONUS = 'bonus',
}

export enum CompanyIsActiveLabel {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DELETED = 'Deleted',
}

export enum MERCHANT_LIMIT_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export const companyStatusFilterOptions = [
  {
    label: CompanyIsActiveLabel.ACTIVE,
    value: true,
  },
  {
    label: CompanyIsActiveLabel.INACTIVE,
    value: false,
  },
];

export enum COMMERCIAL_STATUS {
  'TREYDING_CUSTOMER' = 'TREYDING_CUSTOMER',
  'PROACTIVE_OPPORTUNITY' = 'PROACTIVE_OPPORTUNITY',
  'CUSTOMER_AT_RISK' = 'CUSTOMER_AT_RISK',
  'CHURNED_NO_UTILIZATION' = 'CHURNED_NO_UTILIZATION',
  'UP_FOR_KYC_RENEWAL' = 'UP_FOR_KYC_RENEWAL',
  'UP_FOR_LIMIT_RENEWAL_NEED_DATA' = 'UP_FOR_LIMIT_RENEWAL_NEED_DATA',
  'UP_FOR_RENEWAL_DATA_AVAILABLE' = 'UP_FOR_RENEWAL_DATA_AVAILABLE',
  'CHURNED_LIMIT_EXPIRED' = 'CHURNED_LIMIT_EXPIRED',
  'DEACTIVATED_RETAIN' = 'DEACTIVATED_RETAIN',
  'RESURRECTION_OPPORTUNITY' = 'RESURRECTION_OPPORTUNITY',
  'OVERDUE' = 'OVERDUE',
  'DEACTIVATED_AVOID' = 'DEACTIVATED_AVOID',
  'DELETED' = 'DELETED',
  'REJECTED' = 'REJECTED',
  'COMPLIANCE_PENDING' = 'COMPLIANCE_PENDING',
}

export enum ONBOARDING_STATUS {
  'DISCOVERY' = 'DISCOVERY',
  'UNDERWRITING' = 'UNDERWRITING',
  'INDICATIVE_LIMIT_APPROVED' = 'INDICATIVE_LIMIT_APPROVED',
  'READY_TO_TREYD' = 'READY_TO_TREYD',
  'CLOSED_WON' = 'CLOSED_WON',
  'CLOSED_LOST' = 'CLOSED_LOST',
}

export const MERCHANT_COMBINED_STATUS = {
  ...COMMERCIAL_STATUS,
  ...ONBOARDING_STATUS,
};

export const COMMERCIAL_STATUS_DISPLAYED_VALUE: Record<
  COMMERCIAL_STATUS,
  string
> = {
  [COMMERCIAL_STATUS.TREYDING_CUSTOMER]: 'Treyding Customer',
  [COMMERCIAL_STATUS.PROACTIVE_OPPORTUNITY]: 'Proactive Opportunity',
  [COMMERCIAL_STATUS.CUSTOMER_AT_RISK]: 'Customer at Risk',
  [COMMERCIAL_STATUS.CHURNED_NO_UTILIZATION]: 'Churned, No Utilization',
  [COMMERCIAL_STATUS.UP_FOR_KYC_RENEWAL]: 'KYC Renewal',
  [COMMERCIAL_STATUS.UP_FOR_LIMIT_RENEWAL_NEED_DATA]:
    'Limit Renewal, Need Data',
  [COMMERCIAL_STATUS.UP_FOR_RENEWAL_DATA_AVAILABLE]:
    'Limit Renewal, Data Available',
  [COMMERCIAL_STATUS.CHURNED_LIMIT_EXPIRED]: 'Churned, Limit Expired',
  [COMMERCIAL_STATUS.DEACTIVATED_RETAIN]: 'Deactivated Retain',
  [COMMERCIAL_STATUS.RESURRECTION_OPPORTUNITY]: 'Resurrection Opportunity',
  [COMMERCIAL_STATUS.DELETED]: 'Deleted',
  [COMMERCIAL_STATUS.REJECTED]: 'Soft Rejected',
  [COMMERCIAL_STATUS.OVERDUE]: 'Overdue',
  [COMMERCIAL_STATUS.DEACTIVATED_AVOID]: 'Deactivated, Avoid',
  [COMMERCIAL_STATUS.COMPLIANCE_PENDING]: 'Compliance, Pending',
};

export const ONBOARDING_STATUS_DISPLAYED_VALUE: Record<
  ONBOARDING_STATUS,
  string
> = {
  [ONBOARDING_STATUS.DISCOVERY]: 'Discovery',
  [ONBOARDING_STATUS.UNDERWRITING]: 'Underwriting',
  [ONBOARDING_STATUS.INDICATIVE_LIMIT_APPROVED]: 'Indicative Limit Approved',
  [ONBOARDING_STATUS.READY_TO_TREYD]: 'Ready to Treyd',
  [ONBOARDING_STATUS.CLOSED_WON]: 'Closed Won',
  [ONBOARDING_STATUS.CLOSED_LOST]: 'Closed Lost',
};

export const MERCHANT_COMBINED_STATUS_DISPLAYED_VALUE = {
  ...COMMERCIAL_STATUS_DISPLAYED_VALUE,
  ...ONBOARDING_STATUS_DISPLAYED_VALUE,
};

export enum MERCHANT_SEGMENT {
  'WHITE_GLOVE' = 'white_glove',
  'NORMAL' = 'normal',
}

export const MERCHANT_SEGMENTS_DISPLAYED_VALUE = {
  [MERCHANT_SEGMENT.WHITE_GLOVE]: 'White glove',
  [MERCHANT_SEGMENT.NORMAL]: 'Normal',
};

const CREDIT_SUB_STATUS = LimitStatusSegmentArgs.CREDIT_SUB_STATUS;

export const CREDIT_SUB_STATUS_TITLE: Record<
  LimitStatusSegmentArgs.CREDIT_SUB_STATUS,
  string
> = {
  [CREDIT_SUB_STATUS.REVIEW_IN_REVIEW]: 'Review - In Review',
  [CREDIT_SUB_STATUS.REVIEW_PENDING]: 'Review - Pending',
  [CREDIT_SUB_STATUS.REVIEW_NOT_STARTED]: 'Review - Not Started',
  [CREDIT_SUB_STATUS.REVIEW_SENT_BACK]: 'Review - Sent Back',
  [CREDIT_SUB_STATUS.REVIEW_BLOCKED]: 'Review - Blocked',
  [CREDIT_SUB_STATUS.UNDERWRITING_UNASSIGNED]: 'Underwriting - Unassigned',
  [CREDIT_SUB_STATUS.UNDERWRITING_NOT_STARTED]: 'Underwriting - Not Started',
  [CREDIT_SUB_STATUS.UNDERWRITING_IN_ANALYSIS]: 'Underwriting - In Analysis',
  [CREDIT_SUB_STATUS.UNDERWRITING_SENT_BACK]: 'Underwriting - Sent Back',
  [CREDIT_SUB_STATUS.UNDERWRITING_CANCELLED]: 'Underwriting - Cancelled',
  [CREDIT_SUB_STATUS.UNDERWRITING_BLOCKED]: 'Underwriting - Blocked',
  [CREDIT_SUB_STATUS.OPEN_DECISION]: 'Open Decision',
  [CREDIT_SUB_STATUS.APPROVED]: 'Approved',
};
