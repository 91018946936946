import { ComponentLoader } from '@/components/ComponentLoader';
import NavTabsBar from '@/components/NavTabsBar';

import { useGetMerchantCustomerErpInvoicesCount } from '@/hooks/useGetMerchantCustomerErpInvoicesCount';
import { useGetMerchantSuppliersErpInvoicesCount } from '@/hooks/useGetMerchantSuppliersErpInvoicesCount';
import { Box, Stack, Typography } from '@mui/material';
import { CustomerErpInvoices } from './CustomerErpInvoices';
import { SuppliersErpInvoices } from './SuppliersErpInvoices';

export const ErpInvoices = () => {
  const {
    data: suppliersInvoicesCountResponse,
    loading: isSuppliersInvoicesCountLoading,
  } = useGetMerchantSuppliersErpInvoicesCount();
  const {
    data: customerInvoicesCountResponse,
    loading: isCustomersInvoicesCountLoading,
  } = useGetMerchantCustomerErpInvoicesCount();

  if (isSuppliersInvoicesCountLoading || isCustomersInvoicesCountLoading) {
    return <ComponentLoader />;
  }

  const suppliersInvoicesCount =
    suppliersInvoicesCountResponse?.unified_supplier_invoice_aggregate
      ?.aggregate?.count;
  const customerInvoicesCount =
    customerInvoicesCountResponse?.unified_customer_invoice_aggregate?.aggregate
      ?.count;

  const tabs = [
    {
      id: 'supplier-erp-invoices',
      label: 'Supplier Invoices',
      chipLabel: suppliersInvoicesCount,
      content: <SuppliersErpInvoices />,
    },
    {
      id: 'customer-erp-invoices',
      label: 'Customer Invoices',
      chipLabel: customerInvoicesCount,
      content: <CustomerErpInvoices />,
    },
  ];

  return (
    <Stack flexGrow={1} height="100%">
      <Box sx={{ marginBlockEnd: 1 }}>
        <Typography variant="h4">ERP Invoices</Typography>
      </Box>
      <NavTabsBar tabs={tabs} defaultTabId={'supplier-erp-invoices'} />
    </Stack>
  );
};
