import Order from '@/pages/orderReview/models/Order';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { isNumber, map, some, toUpper } from 'lodash';

interface Props {
  order?: Order;
  title?: string;
}

export const RepaymentBreakdownSection = ({ title, order }: Props) => {
  const theme = useTheme();
  const invoicingCurrency = toUpper(order?.invoicing_currency);
  const lineItems = order?.payouts?.[0]?.line_items;

  const isDifferentCurrency = some(
    lineItems,
    (row) => row?.currency && toUpper(row?.currency) !== invoicingCurrency
  );

  return (
    <Stack direction="column" gap={3}>
      {title && <Typography variant="h6">{title}</Typography>}
      <TableContainer
        sx={{
          border: `${theme.spacing(0.125)} solid`,
          borderRadius: theme.spacing(1),
          borderColor: theme.palette.divider,
        }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Item
                </Typography>
              </TableCell>
              {isDifferentCurrency && (
                <>
                  <TableCell align="right">
                    <Typography variant="body2" color="text.secondary">
                      Local amount{' '}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" color="text.secondary">
                      FX
                    </Typography>
                  </TableCell>
                </>
              )}
              <TableCell align="right">
                <Typography variant="body2" color="text.secondary">
                  VAT
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="text.secondary">
                  Discount
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="text.secondary">
                  Total
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(
              lineItems,
              (row) =>
                isNumber(row.unit_price) && (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                    {isDifferentCurrency && (
                      <>
                        <TableCell align="right">
                          {row.currency &&
                            formatCurrency(row.unit_price, row.currency)}
                        </TableCell>
                        <TableCell align="right">
                          {row?.fx_rate_to_invoicing_currency &&
                            Number(row.fx_rate_to_invoicing_currency).toFixed(
                              1
                            )}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="right">
                      {isNumber(row.vat) &&
                        row.currency &&
                        formatCurrency(row.vat, row.currency)}
                    </TableCell>
                    <TableCell align="right">
                      {isNumber(row.discount) &&
                        row.currency &&
                        formatCurrency(row.discount || 0, row.currency)}
                    </TableCell>
                    <TableCell align="right">
                      {isNumber(
                        row.total_repayment_amount_in_invoicing_currency
                      ) &&
                        invoicingCurrency &&
                        formatCurrency(
                          row.total_repayment_amount_in_invoicing_currency || 0,
                          invoicingCurrency
                        )}
                    </TableCell>
                  </TableRow>
                )
            )}
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  fontWeight: 550,
                  fontSize: '0.88rem',
                  borderBottom: 0,
                },
              }}>
              <TableCell component="th" scope="row">
                Repayment invoice total
              </TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
              {isDifferentCurrency && (
                <>
                  <TableCell align="right" />
                  <TableCell align="right" />
                </>
              )}
              <TableCell align="right">
                {invoicingCurrency &&
                  formatCurrency(
                    order?.payouts[0]
                      ?.total_repayment_amount_in_invoicing_currency || 0,
                    invoicingCurrency
                  )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
