import { Box, Typography } from '@mui/material';

export const InvoicePreview = ({
  invoiceUrl,
}: {
  invoiceUrl?: string | null;
}) => {
  return invoiceUrl ? (
    <Box sx={{ height: '100vh' }}>
      <iframe
        src={invoiceUrl}
        title="Invoice Preview"
        style={{ width: '100%', height: '100%' }}
      />
    </Box>
  ) : (
    <Typography display={'flex'} justifyContent={'center'}>
      No invoice found
    </Typography>
  );
};
