import { MAPPED_ORDER_STATUS, MERCHANT_SEGMENT, OrderType } from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { GridSortItem } from '@mui/x-data-grid';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Orders as Orders,
  Orders_Companies,
  Orders_Financiers,
  Orders_Invoice,
  Orders_Order_Payouts,
  Orders_Orders_Aggregate,
  Orders_Orders_App_Aiapprovalrecommendation,
  Orders_Orders_App_Complianceapprovalrecommendation,
} from '__generated__/graphql';
import { isEmpty, toNumber } from 'lodash';

export type Order = Pick<
  Orders,
  | 'id'
  | 'modified'
  | 'created'
  | 'compliance_approval_recommendation'
  | 'order_invoice'
> & {
  importer: Pick<
    Orders_Companies,
    'id' | 'name' | 'country' | 'commercial_statuses'
  > & {
    segment: MERCHANT_SEGMENT;
    first_paid_order: { id: number } | null;
  };
  financier: Pick<Orders_Financiers, 'id' | 'name'>;
  order_invoice: Pick<
    Orders_Invoice,
    'ocr_data' | 'id' | 'ops_approval_recommendation'
  > & {
    ops_approval_recommendation: Pick<
      Orders_Orders_App_Aiapprovalrecommendation,
      'is_auto_approval'
    >;
  };
  active_payout: Pick<
    Orders_Order_Payouts,
    'expected_payment_date' | 'amount' | 'currency'
  >;
  compliance_approval_recommendation: Pick<
    Orders_Orders_App_Complianceapprovalrecommendation,
    'is_auto_approval'
  >;
};

type GetAwaitingApprovalDealsQuery = {
  orders_orders: Array<Order>;
  orders_orders_aggregate: Pick<Orders_Orders_Aggregate, 'aggregate'>;
};
export const getAwaitingApprovalOrdersInOrderReviewQuery = (
  offset: number,
  limit: number,
  searchTerm = '',
  sorting = {},
  filters = {}
) => {
  const awaitingApprovalOrdersFilters: Filter[] = [
    {
      type: 'search',
      name: 'id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(searchTerm)
          ? null
          : toNumber(searchTerm),
    },
    {
      type: 'search',
      name: 'importer__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'financier__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'filter',
      name: 'mapped_status',
      comparator: FilterComparator.IN,
      comparisonValue: [MAPPED_ORDER_STATUS.AWAITING_APPROVAL],
    },
    {
      type: 'filter',
      name: 'active_order_review',
      comparator: FilterComparator.NOT_EXIST,
    },
    {
      type: 'filter',
      name: 'type',
      comparator: FilterComparator.EQUAL,
      comparisonValue: OrderType.PAYABLE,
    },
  ];

  const { queryParams, whereClause } = getGqlQueryParams({
    limit,
    offset,
    sorting: sorting as GridSortItem,
    filters: awaitingApprovalOrdersFilters,
  });
  return gql`
  query AwaitingSignaturesDeals {
      orders_orders ${queryParams} {
				id
        created
        modified
        active_payout {
          amount
          currency
          expected_payment_date
        }
        financier {
          id
          name
        }
        importer {
          id
          name
          country
					segment
          commercial_statuses {
            commercial_status
          }
          first_paid_order: orders(where: {_and: {payouts: {status: {_eq: "paid"}}}}, limit: 1, order_by: {active_payout: {actual_payment_date: asc}}) {
              id
            }
        }
        order_invoice{
          ocr_data
          ops_approval_recommendation {
            is_auto_approval
          }
        }
        compliance_approval_recommendation{
          is_auto_approval
        }
      }
  
      orders_orders_aggregate (where: ${whereClause}) {
        aggregate {
          count
        }
      }
    }
  ` as TypedDocumentNode<GetAwaitingApprovalDealsQuery, {}>;
};
