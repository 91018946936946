import { gql, TypedDocumentNode } from '@apollo/client';

export type RevokeKycMutationType = {
  django: {
    revoke_kyc_mutation: {
      revoked: boolean;
    };
  };
};

export type RevokeKycMutationVariables = {
  orgNo: string;
  revoked_by: number;
  country: string;
};

export const revokeKycMutation = gql`
  mutation revokeKycMutation(
    $orgNo: String!
    $revoked_by: Int!
    $country: String!
  ) {
    django {
      revoke_kyc_mutation(
        orgnr: $orgNo
        revoked_by: $revoked_by
        country: $country
      ) {
        revoked
      }
    }
  }
` as TypedDocumentNode<RevokeKycMutationType, RevokeKycMutationVariables>;
