import { SearchField } from '@/components';
import { useGetCompanyUsersEmails } from '@/hooks/useGetCompanyUsersEmails';
import useSearchTerm from '@/hooks/useSearchTerm';
import { EmailsTableFiltersFields } from '@/types';
import { Stack } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QueryFilterField,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { isEmpty, isNaN, map, toNumber, toString } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmailsTable } from './EmailsTable';
import { TableFilters } from './TableFilters';

export const Emails = () => {
  const { id } = useParams();
  const companyId =
    isNaN(toNumber(id)) || isEmpty(id) ? undefined : toNumber(id);
  const { data } = useGetCompanyUsersEmails(companyId);
  const companyUsers =
    map(data?.lager_treyd_users, (user) => user.email as string) || [];
  const { searchTerm, setSearchTerm } = useSearchTerm('emailsSearchTerm');
  const [filterFields, setFilterFields] = useState<
    QueryFilterField<EmailsTableFiltersFields>[]
  >([]);

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'recipient',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
    {
      type: 'search',
      name: 'id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(toString(searchTerm).trim())
          ? null
          : toNumber(searchTerm),
    },
    {
      type: 'search',
      name: 'template__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
  ];

  const filters: Filter[] = [
    ...searchFields,
    ...filterFields,
    {
      type: 'filter',
      name: 'recipient',
      comparator: FilterComparator.IN,
      comparisonValue: companyUsers,
    },
  ];

  return (
    <Stack flexGrow={1} gap={3} height="100%">
      <Row>
        <Col xs={12} sm>
          <SearchField
            placeholder={'Search by template, recipient, or email #'}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Col>
        <Col xs={12} sm="auto">
          <TableFilters
            onSubmit={setFilterFields}
            onReset={() => setFilterFields([])}
            filters={filterFields}
          />
        </Col>
      </Row>
      <EmailsTable filters={filters} />
    </Stack>
  );
};
