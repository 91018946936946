import NavTabsBar, { TabType } from '@/components/NavTabsBar';
import { Stack, Typography } from '@mui/material';
import { Credits } from './Tabs/Credits';
import { Vouchers } from './Tabs/Vouchers';

export const CreditsAndVouchers = () => {
  const tabs: TabType[] = [
    {
      id: 'credits',
      label: 'Credits',
      content: <Credits />,
    },
    {
      id: 'vouchers',
      label: 'Vouchers',
      content: <Vouchers />,
    },
  ];

  return (
    <Stack flexGrow={1} gap={1} height="100%">
      <Stack>
        <Typography variant="h4">Credits & Vouchers</Typography>
      </Stack>
      <Stack flexGrow={1} height="100%">
        <NavTabsBar tabs={tabs} defaultTabId={'credits'} />
      </Stack>
    </Stack>
  );
};
