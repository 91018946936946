import { useDebouncedState } from '@treyd-io/core/hooks/useDebouncedState';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';

const useSearchTerm = (key = 'searchTerm') => {
  const searchTermQueryParam = useSearchParam(key);
  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useDebouncedState(
    searchTermQueryParam || '',
    500
  );

  const setSearchTermQueryParam = (value: string) => {
    setSearchTerm(value);
    const params = new URLSearchParams(location.search);
    params.set(key, value);
    navigate({
      pathname: location.pathname,
      search: params.toString(),
      hash: location.hash,
    });
  };

  return { searchTerm, setSearchTerm: setSearchTermQueryParam };
};

export default useSearchTerm;
