import { SearchField } from '@/components';
import { ComponentLoader } from '@/components/ComponentLoader';
import { ExportMerchantsButton } from '@/components/Merchants/ExportMerchantsButton';
import { useGetAllFinanciers, useGetTableSettings } from '@/hooks';
import useSearchTerm from '@/hooks/useSearchTerm';
import { MerchantTableFiltersFields } from '@/types';
import { Button, Stack, Typography } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import PlusIcon from '@treyd-io/treyd-ui/icons/PlusIcon';
import { toString } from 'lodash';
import { useToggle } from 'react-use';
import { AddCompanyDialog } from './AddCompanyDialog';
import { MerchantsTable } from './MerchantsTable';
import { TableFilters } from './TableFilters';

export const MerchantsPage = () => {
  const { searchTerm, setSearchTerm } = useSearchTerm('merchantsSearchTerm');
  const [open, toggle] = useToggle(false);
  const {
    merchantsTableSettings: { filterFields, setFilterFields, resetTable },
  } = useGetTableSettings();

  const { data, loading: isAllFinanciersLoading } = useGetAllFinanciers();
  const allFinanciers = data?.orders_financiers;

  const searchFields: QuerySearchField<MerchantTableFiltersFields>[] = [
    {
      type: 'search',
      name: 'org_no',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
  ];

  const filters: Filter<MerchantTableFiltersFields>[] = [
    ...searchFields,
    ...(filterFields || []),
  ];

  if (isAllFinanciersLoading) return <ComponentLoader />;

  return (
    <Stack flexGrow={1} gap={5} height="100%">
      <Stack flexGrow={1} gap={3} height="100%">
        <Row>
          <Col xs="auto">
            <Typography variant="h4">Merchants</Typography>
          </Col>
          <Col xs={12} sm>
            <SearchField
              placeholder={'Search by merchant name, or org #'}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Col>
          <Col xs={12} sm="auto">
            <TableFilters
              onSubmit={setFilterFields}
              onReset={resetTable}
              allFinanciers={allFinanciers}
              filters={filterFields}
            />
          </Col>
          <Col xs={12} sm="auto">
            <ExportMerchantsButton filters={filters} />
          </Col>
          <Col xs={12} sm="auto">
            <Button
              startIcon={<PlusIcon />}
              variant="contained"
              color="primary"
              fullWidth
              onClick={toggle}
              size="large">
              Add merchant
            </Button>
          </Col>
        </Row>
        <MerchantsTable filters={filters} />
      </Stack>
      <AddCompanyDialog open={open} onClose={toggle} />
    </Stack>
  );
};
