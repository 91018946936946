import { Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import GenericTableView from '@/components/GenericTableView';
import { DEFAULT_GRID_COL_DEF } from '@/constants';
import { getTreydUsers } from '@/schemas';

//TODO: pass columns as props
const columns: GridColDef[] = [
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'id',
    headerName: 'User #',
    width: 100,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'member_id',
    headerName: 'Member ID',
    type: 'number',
    width: 100,
    renderCell: (params: any) => (params.value ? params.value : 'N/A'),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'company_id',
    headerName: 'Company ID',
    type: 'number',
    width: 100,
    renderCell: (params: any) => (params.value ? params.value : 'N/A'),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'name',
    headerName: 'Name',
    type: 'string',
    width: 200,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'first_name',
    headerName: 'First Name',
    type: 'string',
    width: 200,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'last_name',
    headerName: 'Last Name',
    type: 'string',
    width: 200,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'email',
    headerName: 'Email Address',
    type: 'email',
    width: 250,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'identity_verified',
    headerName: 'Identity Verified',
    type: 'string',
    width: 150,
    renderCell: (params: any) => (
      <div>{params.row.identity_verified ? 'Yes' : 'No'}</div>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'can_sign_alone',
    headerName: 'Can Sign Alone',
    type: 'string',
    width: 150,
    renderCell: (params: any) => (
      <div>{params.row.can_sign_alone ? 'Yes' : 'No'}</div>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'is_ubo',
    headerName: 'Is UBO',
    type: 'string',
    width: 150,
    renderCell: (params: any) => <div>{params.row.is_ubo ? 'Yes' : 'No'}</div>,
  },
];

export const TreydUsersTable = () => (
  <Stack flexGrow={1} height={'100%'}>
    <Typography
      sx={(theme) => ({ marginBlockEnd: theme.spacing(1) })}
      variant="h5">
      Treyd Users
    </Typography>
    <GenericTableView
      searchPlaceholder="Search for user email, name, or company ID"
      getGQLSchema={getTreydUsers}
      columns={columns}
      resourceName="lager_treyd_users"
    />
  </Stack>
);
