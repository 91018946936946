import { ComponentLoader } from '@/components/ComponentLoader';
import { useAggregateInvoicesByCurrencies } from '@/hooks';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { invoicingCurrencies } from '@treyd-io/core/constants/country';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { filter, find, isEmpty, map, reduce } from 'lodash';

type CurrencyRow = {
  currency: string;
  count: number;
  amount: number;
  paidAmount: number;
  remainingAmount: number;
};

export const CurrenciesTable = ({ filters }: { filters?: Filter[] }) => {
  const currenciesFilter = find(filters, { name: 'invoiced_currency' });
  const currencies =
    (currenciesFilter?.comparisonValue as string[]) || invoicingCurrencies;

  const { data, loading: isAggregateInvoicesByCurrenciesLoading } =
    useAggregateInvoicesByCurrencies({
      queryOptions: {
        filters: filter(
          filters,
          (filter) => filter.name !== 'invoiced_currency'
        ),
      },
      currencies,
    });

  const rows = reduce(
    data,
    (currencies: CurrencyRow[], currencyAggregate, currency) => {
      if (currencyAggregate.aggregate?.count) {
        currencies.push({
          currency,
          count: currencyAggregate.aggregate?.count,
          amount: currencyAggregate.aggregate?.sum?.invoiced_amount,
          paidAmount: currencyAggregate.aggregate?.sum?.paid_amount,
          remainingAmount: currencyAggregate.aggregate?.sum?.remaining_amount,
        });
      }
      return currencies;
    },
    []
  );

  if (isAggregateInvoicesByCurrenciesLoading) return <ComponentLoader />;

  if (isEmpty(rows)) return;

  return (
    <Stack gap={2}>
      <Typography variant="h6">Amounts by currencies</Typography>
      <TableContainer
        sx={{
          border: 1,
          borderRadius: 2,
          borderColor: 'divider',
        }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Currency
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Number of invoices
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Amount
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Paid amount
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="text.secondary">
                  Remaining amount
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(rows, (row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: index % 2 !== 0 ? 'grey.100' : '',
                }}>
                <TableCell component="th" scope="row">
                  {row.currency}
                </TableCell>
                <TableCell sx={{ fontVariantNumeric: 'tabular-nums' }}>
                  {row.count}
                </TableCell>
                <TableCell sx={{ fontVariantNumeric: 'tabular-nums' }}>
                  {formatCurrency(row.amount, row.currency)}
                </TableCell>
                <TableCell sx={{ fontVariantNumeric: 'tabular-nums' }}>
                  {formatCurrency(row.paidAmount, row.currency)}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontVariantNumeric: 'tabular-nums' }}>
                  {formatCurrency(row.remainingAmount, row.currency)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
