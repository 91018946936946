import { gql, TypedDocumentNode } from '@apollo/client';

type AddCompanyMutationVariablesType = {
  orgNo: string;
  country: string;
  hubspotId?: string;
};

type AddCompanyMutationType = {
  django: {
    insert_company: {
      company: {
        id: number;
      };
    };
  };
};

export const addCompanyMutation = gql`
  mutation insertCompany(
    $orgNo: String!
    $country: String!
    $hubspotId: String
  ) {
    django {
      insert_company(
        country: $country
        org_no: $orgNo
        hubspot_id: $hubspotId
      ) {
        company {
          id
        }
      }
    }
  }
` as TypedDocumentNode<AddCompanyMutationType, AddCompanyMutationVariablesType>;
