export namespace Onfido {
  enum REPORT_RESULT {
    CLEAR = 'clear',
    CONSIDER = 'consider',
  }
  export enum REPORT_NAME {
    DOCUMENT = 'document',
    FACIAL_SIMILARITY_PHOTO = 'facial_similarity_photo',
  }
  enum CHECK_STATUS {
    'IN_PROGRESS' = 'in_progress',
    'COMPLETE' = 'complete',
  }
  type BreakDownItem = {
    result: null | REPORT_RESULT;
    properties?: Record<string, any>;
    breakdown?: BreakDown;
  };
  type BreakDown = Record<string, BreakDownItem>;

  type Document = {
    id: string;
  };

  type DocumentNumber = {
    type: string;
    value: string;
  };

  type Properties = Record<string, string | DocumentNumber[]>;

  export interface Report {
    id: string;
    href: string;
    name: REPORT_NAME;
    result: REPORT_RESULT;
    status: CHECK_STATUS;
    check_id: string;
    breakdown: BreakDown;
    documents: Document[];
    created_at: string;
    properties: Properties;
    sub_result: REPORT_RESULT;
  }
}
