import { useDeleteSupplierAttachment } from '@/hooks';
import { SupplierCompliance } from '@/types';
import { formatDate } from '@treyd-io/core/utils/date';
import { openURL } from '@treyd-io/core/utils/openUrl';
import { last, split, toNumber } from 'lodash';
import { AttachmentCard } from './AttachmentCard';
export const ComplianceAttachment = ({
  attachment,
  onRemoveComplete,
  isDraftScreening,
}: {
  attachment: SupplierCompliance.Attachment;
  onRemoveComplete: () => void;
  isDraftScreening: boolean;
}) => {
  const [deleteSupplierAttachment] = useDeleteSupplierAttachment();
  const name = last(split(attachment.name, 'supplier_screening-'));
  const creationDate = formatDate(new Date(attachment.created), 'datetime');
  const handlePreviewClick = () => openURL(attachment.url, '_blank');

  const handleRemoveClick = () => {
    deleteSupplierAttachment({
      variables: {
        attachmentId: toNumber(attachment.id),
      },
      onCompleted: onRemoveComplete,
    });
  };

  return (
    <AttachmentCard
      title={name}
      description={creationDate}
      onPreviewClick={handlePreviewClick}
      onRemoveClick={isDraftScreening ? handleRemoveClick : undefined}
    />
  );
};
