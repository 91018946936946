export const PLATFORM_HOST = process.env.REACT_APP_PLATFORM_HOST;
const FINANCIER_PLATFORM_HOST = process.env.REACT_APP_FINANCIER_PLATFORM_HOST;
export const getSupplierPlatformLink = (
  merchant_id: Number | string,
  supplier_id: Number | string
) =>
  `${FINANCIER_PLATFORM_HOST}/companies/${merchant_id}/suppliers/${supplier_id}`;

export const IMPERSONATE_WITH_EMAIL_LINK = (email = '', companyId = '') =>
  `${PLATFORM_HOST}/company?tab=impersonate&email=${email}&companyId=${companyId}`;
