import { DocumentCard, DocumentCardType } from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { isEmpty, map } from 'lodash';

export const DocumentsTab = ({
  documents,
}: {
  documents: DocumentCardType[];
}) => {
  return (
    <Row spacing={3}>
      {isEmpty(documents) ? (
        <Col xs={12}>
          <NoAttachmentsPlaceholder />
        </Col>
      ) : (
        map(documents, (document) => (
          <Col xs={12} sm={6} lg={4} key={document.title}>
            <DocumentCard {...document} />
          </Col>
        ))
      )}
    </Row>
  );
};

const NoAttachmentsPlaceholder = () => {
  return (
    <TableHasNoRowsPlaceholder
      title={'Nothing here yet'}
      subtitle={'Documents will be displayed here.'}
      iconProps={{
        style: { fill: 'none' },
        sx: {
          color: 'info.main',
          width: 54,
          height: 60,
        },
        viewBox: '0 0 95 108',
      }}
      containerProps={{
        border: 1,
        borderColor: 'other.outlined_border',
        borderRadius: 4,
      }}
      contentBoxProps={{
        gap: 4,
      }}
    />
  );
};
