import { CompanyData } from '@/types';
import { TypedDocumentNode, gql } from '@apollo/client';

export const getAllCompanyUsersById: TypedDocumentNode<CompanyData, {}> = gql`
  query getAllCompanyUsersById($company_id: bigint!) {
    lager_treyd_users(
      where: {
        company_id: { _eq: $company_id }
        _and: { is_active: { _eq: true } }
      }
    ) {
      id
      user_id
      name
      email
      phone_code
      phone_no
      identity_verified
      invitation_accepted_at
      can_sign_alone
      last_login
      date_joined
      phone_confirmed_at
      is_ubo
      member {
        id
        core_signatories {
          can_sign_alone
        }
      }
      onfido_applicant {
        onfido_check(order_by: { created: desc_nulls_last }, limit: 1) {
          reports_payload
        }
      }
    }
  }
`;
