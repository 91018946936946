import { TrackingEvents } from '@/schemas';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import ChevronDownIcon from '@treyd-io/treyd-ui/icons/ChevronDownIcon';
import HelpCircleIcon from '@treyd-io/treyd-ui/icons/HelpCircleIcon';
import { isEmpty, last, map, reduce, toNumber, toString } from 'lodash';

export const TrackingDetails = ({ events }: { events?: TrackingEvents[] }) => {
  if (isEmpty(events)) {
    return (
      <Accordion
        disabled
        sx={{
          '&.Mui-disabled': {
            backgroundColor: 'background.default',
          },
          borderRadius: 2,
        }}>
        <AccordionSummary expandIcon={<ChevronDownIcon fontSize="large" />}>
          No tracking details available
        </AccordionSummary>
      </Accordion>
    );
  }
  return (
    <Accordion
      sx={{
        borderRadius: 2,
        backgroundColor: 'background.default',
      }}>
      <AccordionSummary expandIcon={<ChevronDownIcon fontSize="large" />}>
        <Typography variant="body1">View tracking details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              paddingInline: { xs: 0, md: 2 },
            },
            padding: 0,
            marginTop: 0,
          }}>
          {map(events, (event) => {
            return (
              <TimelineItem sx={{ marginBottom: 3 }}>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" />
                  {last(events)?.last_update_time !==
                    event.last_update_time && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Box
                    width={'100%'}
                    display={'flex'}
                    paddingX={1}
                    justifyContent={'space-between'}>
                    <Row direction={'row'} gap={0}>
                      <Col xs={12} md>
                        <Stack gap={1}>
                          <Stack>
                            <Typography variant={'overline'}>
                              ON{' '}
                              {formatDate(
                                new Date(event?.last_update_time),
                                'longdatetime'
                              )}
                            </Typography>
                            <Typography
                              variant={'body1'}
                              color={'text.primary'}>
                              {event.from_bank_name ?? event.from}
                            </Typography>
                          </Stack>
                          <Stack>
                            <Stack gap={{ xs: 1, md: 0.5 }}>
                              <Row spacing={1}>
                                <Col xs={12} md={'auto'}>
                                  <Typography
                                    variant="body2"
                                    width={64}
                                    color="textSecondary">
                                    Address
                                  </Typography>
                                </Col>
                                <Col xs={12} md>
                                  <Typography
                                    variant="body2"
                                    color="text.primary">
                                    {event.from_bank_address}{' '}
                                  </Typography>
                                </Col>
                              </Row>
                              <Row spacing={1}>
                                <Col xs={12} md={'auto'}>
                                  <Typography
                                    variant="body2"
                                    width={64}
                                    color="textSecondary">
                                    To
                                  </Typography>
                                </Col>
                                <Col xs={12} md>
                                  <Typography
                                    variant="body2"
                                    color="text.primary">
                                    {event.to_bank_name ?? event.to}{' '}
                                  </Typography>
                                </Col>
                              </Row>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Col>
                      <Col xs={12} md={'auto'}>
                        <Box
                          display={'flex'}
                          gap={0.25}
                          alignItems={{ sm: 'start', md: 'end' }}
                          flexDirection={'column'}>
                          <Typography variant={'body1'}>
                            {' '}
                            {formatCurrency(
                              toNumber(
                                event?.confirmed_amount?.amount ??
                                  event?.instructed_amount?.amount
                              ),
                              toString(
                                event?.confirmed_amount?.currency ??
                                  event?.instructed_amount?.currency
                              )
                            )}
                          </Typography>
                          {!isEmpty(event?.charge_amount) && (
                            <Box
                              display={'flex'}
                              gap={0.25}
                              justifyContent={{ sm: 'start', md: 'end' }}
                              alignItems={'center'}>
                              <Typography
                                variant="caption"
                                color={'textSecondary'}>
                                {formatCurrency(
                                  reduce(
                                    event.charge_amount,
                                    (acc, value) =>
                                      acc + toNumber(value.amount),
                                    0
                                  ),
                                  event?.charge_amount[0]?.currency
                                )}{' '}
                                Charges
                              </Typography>
                              <Tooltip
                                title={
                                  event?.charge_type === 'shared'
                                    ? 'SHA'
                                    : 'OURS'
                                }
                                arrow>
                                <Box display="flex" alignItems="center">
                                  <HelpCircleIcon color="disabled" />
                                </Box>
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      </Col>
                    </Row>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </AccordionDetails>
    </Accordion>
  );
};
