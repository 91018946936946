import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import CheckTwoIcon from '@treyd-io/treyd-ui/icons/CheckTwoIcon';
import ChevronDownIcon from '@treyd-io/treyd-ui/icons/ChevronDownIcon';

import { OcrConfidenceStatus } from '@/types';
import { toString } from 'lodash';
import { ReactNode } from 'react';
import { useToggle } from 'react-use';

interface Row {
  keyCell: ReactNode;
  valueCell: ReactNode;
  confidenceStatus?: OcrConfidenceStatus;
  isReviewed?: boolean;
  ocrKeyPath?: string;
  ocrSectionPath?: string;
}
interface OrderInformationTableProps {
  title: string;
  rows: Row[];
  isOpen: boolean;
  headerActions?: ReactNode;
  onOcrFieldReviewChecked: (
    ocrSectionPath: string,
    ocrKeyPath: string,
    isChecked: boolean
  ) => void;
  editable: boolean;
}

const statusColors = {
  success: {
    color: 'inherit',
    backgroundColor: 'inherit',
  },
  warning: {
    color: 'warning.main',
    backgroundColor: 'warning.50',
  },
};
export const OrderInformationTable = (props: OrderInformationTableProps) => {
  const { isOpen, rows, title, editable, headerActions } = props;

  const [isExpanded, toggleExpand] = useToggle(isOpen);

  return (
    <Stack border={1} borderColor="other.outlined_border" borderRadius={4}>
      <Accordion
        expanded={isExpanded}
        sx={{ background: 'transparent', border: 'none' }}>
        <AccordionSummary
          tabIndex={-1}
          expandIcon={
            <IconButton size="small" sx={{ padding: 0 }} onClick={toggleExpand}>
              <ChevronDownIcon
                fontSize="large"
                color="primary"
                pointerEvents="auto"
              />
            </IconButton>
          }
          sx={{ paddingRight: 1, pointerEvents: 'none' }}>
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h6">{title}</Typography>
            <Stack sx={{ pointerEvents: 'auto' }}>{headerActions}</Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
            width: '100%',
            borderTop: 1,
            borderColor: 'other.outlined_border',
          }}>
          <Table>
            <TableBody>
              {rows.map((row, idx) => {
                const color =
                  row?.confidenceStatus &&
                  statusColors[row?.confidenceStatus]?.color;
                const backgroundColor =
                  row?.confidenceStatus &&
                  statusColors[row?.confidenceStatus]?.backgroundColor;
                return (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': {
                        borderBottom: 0,
                      },
                      width: '100%',
                      backgroundColor: backgroundColor,
                    }}
                    key={idx}>
                    <TableCell width={210}>
                      <Box
                        sx={{
                          '& .MuiTypography-root:nth-of-type(2)': {
                            color: color,
                          },
                        }}>
                        {row.keyCell}
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{
                          color: color,
                        }}>
                        {row.valueCell}
                      </Box>
                    </TableCell>
                    <TableCell padding="checkbox">
                      {row?.isReviewed ? (
                        <CheckTwoIcon
                          sx={{ color: 'success.main', margin: 1 }}
                        />
                      ) : (
                        row.ocrSectionPath &&
                        row.ocrKeyPath && (
                          <Checkbox
                            color="success"
                            onChange={(_, isReviewed) =>
                              props.onOcrFieldReviewChecked(
                                toString(row.ocrSectionPath),
                                toString(row.ocrKeyPath),
                                isReviewed
                              )
                            }
                            disabled={!editable}
                          />
                        )
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
