import { MAPPED_ORDER_STATUS, ORDER_EVENT } from '@/constants';
import {
  useCanDoEventOnOrder,
  useExporter,
  useGetSimilarBankAccounts,
} from '@/hooks';

import {
  getComplianceDecisionQuery,
  getMerchantDetailsQuery,
  getMerchantOrdersStatsQuery,
} from '@/schemas';
import {
  OrderComplianceAutoReviewError,
  OrderComplianceAutoReviewResponse,
} from '@/types';
import { useQuery } from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatCurrency } from '@treyd-io/core/utils/number';
import AlertIcon from '@treyd-io/treyd-ui/icons/AlertIcon';
import { BANK_ACCOUNT_TYPE } from 'constants/bank';
import { ROUTES } from 'constants/routes';
import { sub } from 'date-fns';
import { isEmpty, map, omit, startCase, toNumber, toString } from 'lodash';

import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import Order from '@/pages/orderReview/models/Order';
import { useMemo } from 'react';

import { getBankInfo } from '@/utils/bank';
import { SectionHeader } from './SectionHeader';
import { SectionTable } from './SectionTable';
import { SimilarBankAccounts } from './SimilarBankAccounts';

interface ResultRow {
  label: string;
  value: string | number;
  moreDetails?: React.ReactNode;
}

const SIMILAR_BANK_ACCOUNT_LABEL = 'Bank account is linked to many suppliers';

const Compliance = ({
  order,
  orderComplianceAutoReviewData,
  orderComplianceAutoReviewError,
}: {
  order: Order;
  orderComplianceAutoReviewData?: OrderComplianceAutoReviewResponse;
  orderComplianceAutoReviewError: OrderComplianceAutoReviewError | null;
}) => {
  const { data: supplier, loading: loadingSupplierData } = useExporter(
    toNumber(order?.exporter_id),
    {
      include_bank_data: true,
    }
  );

  const bankAccount = supplier?.bank_accounts?.find(
    (bankAccount) => bankAccount.id === order?.bank_account_id
  );
  const { data: similarBankAccounts } = useGetSimilarBankAccounts(
    bankAccount?.id
  );
  const bankInfo = bankAccount && getBankInfo(bankAccount);
  const title = bankAccount?.payment_type
    ? BANK_ACCOUNT_TYPE[
        bankAccount?.payment_type as keyof typeof BANK_ACCOUNT_TYPE
      ]
    : '';

  const { data: merchantData, loading: loadingMerchantData } = useQuery(
    getMerchantDetailsQuery,
    { variables: { merchant_id: order?.importer_id } }
  );
  const merchant = merchantData?.orders_companies_by_pk;
  const merchantKycData = merchant?.kyc?.data;

  const {
    data: merchantComplianceDecision,
    loading: loadingMerchantComplianceDecision,
  } = useQuery(getComplianceDecisionQuery, {
    variables: { country: merchant?.country, orgnr: merchant?.org_no },
    skip: !merchant?.country || !merchant?.org_no,
  });
  const riskLevel =
    merchantComplianceDecision?.lager_risk_compliancedecision?.[0]
      ?.final_risk_level;

  const today = useMemo(() => new Date(), []);
  const last_year = sub(today, { years: 1 }).toISOString();
  const last_month = sub(today, { months: 1 }).toISOString();

  const {
    data: merchantOrdersStatsData,
    loading: loadingMerchantOrdersStatsData,
  } = useQuery(getMerchantOrdersStatsQuery, {
    variables: { importer_id: order?.importer_id, last_month, last_year },
  });
  const averageLatestOrders =
    merchantOrdersStatsData?.average_latest_orders?.aggregate?.avg
      ?.total_amount_excl_vat_no_discount_in_sek;
  const firstOrderAmount =
    merchantOrdersStatsData?.first_order_amount?.aggregate?.avg
      ?.total_amount_excl_vat_no_discount_in_sek;
  const ordersCountLastMonth =
    merchantOrdersStatsData?.orders_count_last_month?.aggregate?.count;
  const ordersCountLastYear =
    merchantOrdersStatsData?.orders_count_last_year?.aggregate?.count;

  const { data: canDoEventOnOrderData } = useCanDoEventOnOrder(
    {
      id: order?.id,
      eventName: ORDER_EVENT.COMPLIANCE_APPROVE,
    },
    order?.mapped_status === MAPPED_ORDER_STATUS.AWAITING_COMPLIANCE_APPROVAL
  );

  const canDoComplianceActionErrorMessages =
    canDoEventOnOrderData?.errors?.compliance;

  const isLoading =
    loadingMerchantData ||
    loadingSupplierData ||
    loadingMerchantComplianceDecision ||
    loadingMerchantOrdersStatsData;

  if (isLoading)
    return (
      <Box
        width={'100%'}
        height="100%"
        display={'flex'}
        alignItems="center"
        justifyContent={'center'}>
        <CircularProgress size={40} />
      </Box>
    );

  const results = orderComplianceAutoReviewData?.results;
  const canBeAutoApproved = orderComplianceAutoReviewData?.is_auto_approval;
  const merchantResult = omit(results?.customer, 'First order');
  const isMerchantApproved = !Boolean(merchantResult?.['Review required']);
  const supplierResult = results?.supplier;
  const isSupplierApproved = !Boolean(supplierResult?.['Review required']);
  const amlResult = results?.aml;
  const isAmlApproved = !Boolean(amlResult?.['Review required']);

  const supplierResultRows: ResultRow[] = [
    {
      label: 'Country',
      value: getCountryName(toString(supplier?.country)),
    },
    {
      label: 'Address',
      value: toString(supplier?.full_address),
    },
  ];

  if (supplierResult) {
    const isBankLinkedToManySuppliers =
      supplierResult?.[SIMILAR_BANK_ACCOUNT_LABEL] !== 0 &&
      !isEmpty(similarBankAccounts);

    supplierResultRows.push({
      label: SIMILAR_BANK_ACCOUNT_LABEL,
      value: isBankLinkedToManySuppliers ? 'Yes' : 'No',
      moreDetails: isBankLinkedToManySuppliers ? (
        <SimilarBankAccounts similarBankAccounts={similarBankAccounts} />
      ) : null,
    });
  }

  return (
    <Stack gap={5}>
      <Stack gap={3}>
        {canDoComplianceActionErrorMessages && (
          <Alert color="error" icon={<AlertIcon />}>
            <AlertTitle>
              Compliance approval can't be performed on this order due to:
            </AlertTitle>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              {map(canDoComplianceActionErrorMessages, (errorMessage) => (
                <ListItem sx={{ display: 'list-item', py: 0, px: 1 }}>
                  {errorMessage}
                </ListItem>
              ))}
            </List>
          </Alert>
        )}
        {canBeAutoApproved ? (
          <Alert color="success">
            <AlertTitle>Order can be auto approved.</AlertTitle>
          </Alert>
        ) : (
          <Alert color="error" icon={<AlertIcon />}>
            <AlertTitle>Order cannot be approved automatically.</AlertTitle>
            {orderComplianceAutoReviewError?.response?.data.error && (
              <List sx={{ listStyleType: 'disc', pl: 2 }}>
                <ListItem sx={{ display: 'list-item', py: 0, px: 1 }}>
                  {orderComplianceAutoReviewError?.response?.data.error}
                </ListItem>
              </List>
            )}
          </Alert>
        )}
        <SectionHeader
          title="Merchant"
          subtitle={toString(merchant?.name)}
          href={`${ROUTES.merchants}/${order?.importer_id}`}
          isApproved={isMerchantApproved}
        />
        <SectionTable
          rows={[
            {
              label: 'AML risk level',
              value: riskLevel || 'N/A',
            },
            {
              label: 'KYC average order size',
              value: merchantKycData?.size_per_order
                ? startCase(merchantKycData?.size_per_order)
                : 'N/A',
            },
            {
              label: 'KYC number of orders per year',
              value: merchantKycData?.yearly_number_of_orders
                ? startCase(merchantKycData?.yearly_number_of_orders)
                : 'N/A',
            },

            {
              label: 'First order',
              value: formatCurrency(toNumber(firstOrderAmount), 'SEK') || 0,
            },
            {
              label: 'Order count in last month',
              value: ordersCountLastMonth || 0,
            },
            {
              label: 'Order count in last year',
              value: ordersCountLastYear || 0,
            },
            {
              label: 'Average amount in last 5 orders',
              value: formatCurrency(toNumber(averageLatestOrders), 'SEK') || 0,
            },
            ...map(omit(merchantResult, 'Review required'), (value, label) => ({
              label,
              value: value === 0 ? 'No' : 'Yes',
            })),
          ]}
        />
      </Stack>
      <Divider />
      <Stack gap={3}>
        <SectionHeader
          title="Supplier"
          subtitle={toString(supplier?.name)}
          href={`${ROUTES.suppliers}/${order?.exporter_id}`}
          isApproved={isSupplierApproved}
        />
        <Stack
          gap={2}
          border={1}
          borderRadius={4}
          borderColor="other.outlined_border"
          padding={3}>
          <Typography variant="h6" color="text.primary">
            {title}
          </Typography>
          <Stack gap={1}>
            {map(
              bankInfo,
              (row) =>
                row.value && (
                  <Row spacing={{ xs: 2, md: 6 }}>
                    <Col xs={12} sm="auto">
                      <Typography
                        width={200}
                        textAlign={'start'}
                        variant="body1"
                        color="text.secondary">
                        {row.label}
                      </Typography>
                    </Col>
                    <Col xs={12} sm>
                      <Typography variant="body1" color="text.primary">
                        {row.value}
                      </Typography>
                    </Col>
                  </Row>
                )
            )}
          </Stack>
        </Stack>
        <SectionTable
          rows={[
            ...supplierResultRows,
            ...map(
              omit(
                supplierResult,
                'Review required',
                SIMILAR_BANK_ACCOUNT_LABEL
              ),
              (value, label) => ({
                label,
                value: value === 0 ? 'No' : 'Yes',
              })
            ),
          ]}
        />
      </Stack>
      {amlResult && (
        <>
          <Divider />
          <Stack gap={3}>
            <SectionHeader
              title="Auto review results"
              subtitle="AML"
              isApproved={isAmlApproved}
            />
            <SectionTable
              rows={map(omit(amlResult, 'Review required'), (value, label) => ({
                label,
                value,
              }))}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Compliance;
