import { transactionsTypesDisplayedValue } from '@/constants';
import { Transaction } from '@/schemas';
import { CSVBuilder } from '@treyd-io/core/services/CsvService';
import { formatDate } from '@treyd-io/core/utils/date';
import { downloadCsvFile } from '@treyd-io/core/utils/downloadCSV';
import { isEmpty, map, toString } from 'lodash';
export class TransactionsCsvService {
  private csvBuilder: CSVBuilder;

  constructor() {
    this.csvBuilder = new CSVBuilder();
  }

  private buildHeaders(): void {
    this.csvBuilder.setHeaders([
      ' ',
      'Invoice #',
      'Order #',
      'Merchant',
      'Payment date',
      'Amount',
      'Currency',
      'Transaction type',
    ]);
  }

  private formatTransactionRow(
    transaction: Transaction,
    index: number
  ): (string | number | Date)[] {
    return map(
      [
        index + 1,
        toString(transaction?.invoices?.[0]?.invoice_number),
        toString(transaction?.invoices?.[0]?.order_id),
        toString(transaction?.invoices?.[0]?.order?.importer?.name),
        transaction?.payment_date
          ? formatDate(new Date(transaction?.payment_date), 'date')
          : '',
        toString(transaction?.amount),
        toString(transaction?.amount_currency),
        transactionsTypesDisplayedValue[transaction?.type] || '',
      ],
      (value) => `"${value}"`
    );
  }

  private buildRows(transactions: Transaction[]): void {
    const formattedRows = map(transactions, (transaction, index) =>
      this.formatTransactionRow(transaction, index)
    );
    this.csvBuilder.addRow(map(formattedRows, (row) => row.join(',')));
  }

  exportToCsv(transactions: Transaction[]) {
    if (isEmpty(transactions)) return false;

    this.buildHeaders();
    this.buildRows(transactions);

    const csvData = this.csvBuilder.build();
    downloadCsvFile({
      data: csvData,
      fileName: 'Transactions.csv',
    });
    return true;
  }
}
