import {
  Orders_Companies,
  Unified_Supplier_Invoice,
} from '@/__generated__/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';

export type MerchantSupplierErpInvoice = Pick<
  Unified_Supplier_Invoice,
  | 'uuid'
  | 'invoice_reference'
  | 'issue_date'
  | 'due_date'
  | 'invoice_status'
  | 'total_amount'
  | 'currency'
  | 'balance'
  | 'supplier_name'
>;

type GetMerchantSuppliersErpInvoicesQueryType = {
  orders_companies_by_pk: Pick<Orders_Companies, 'name'> & {
    core_company: {
      erp_connections: Array<{
        integration?: string;
        erp_invoices?: {
          unified_supplier_invoices?: MerchantSupplierErpInvoice[];
          unified_supplier_invoices_aggregate?: {
            aggregate?: {
              count: number;
            };
          };
        };
      }>;
    };
  };
};

export const getMerchantSuppliersErpInvoicesQuery = (
  queryOptions?: QueryOptions
) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);
  return gql`
	query getMerchantSuppliersErpInvoicesQuery($companyId: bigint!) {
		orders_companies_by_pk(id: $companyId) {
			name
			core_company {
				erp_connections(where: {is_active: {_eq: true}}) {
				integration
					erp_invoices {
						unified_supplier_invoices ${queryParams} {
							uuid
							invoice_reference
							issue_date
							due_date
							invoice_status
							total_amount
							currency
							balance
							supplier_name
						}
						unified_supplier_invoices_aggregate ${
              whereClause ? `(where: ${whereClause})` : ''
            } {
							aggregate {
								count
							}
						}
					}
				}
			}
		}
}
  ` as TypedDocumentNode<
    GetMerchantSuppliersErpInvoicesQueryType,
    { companyId: number }
  >;
};
