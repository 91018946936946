import { styled, Typography } from '@mui/material';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import CheckIcon from '@treyd-io/treyd-ui/icons/CheckIcon';
import ForbidTwoIcon from '@treyd-io/treyd-ui/icons/ForbidTwoIcon';

interface AiStatusProps {
  hasOcrData: boolean;
  reviewedRowsCount: number;
  unreviewedRowsCount: number;
  unreviewedMismathcedRowsCount: number;
  isAllRowsReviewed: boolean;
}

const View = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
}));

export const AiStatus = (props: AiStatusProps) => {
  if (props.reviewedRowsCount === 0) {
    return (
      <View>
        <ForbidTwoIcon sx={{ color: 'text.secondary' }} />
        <Typography variant="subtitle1" color="text.secondary">
          {props.hasOcrData ? 'Unverified' : 'No Invoice AI'}
        </Typography>
        {props.unreviewedMismathcedRowsCount > 0 && (
          <Typography color="warning.main">
            , {props.unreviewedMismathcedRowsCount} mismatch
          </Typography>
        )}
      </View>
    );
  }

  if (props.isAllRowsReviewed) {
    return (
      <View>
        <CheckCircleIcon color="success" />
        <Typography
          sx={(theme) => ({
            color: theme.palette.success.main,
            marginInlineStart: theme.spacing(0.5),
          })}
          variant="subtitle1">
          Verified
        </Typography>
      </View>
    );
  }

  return (
    <View>
      <CheckIcon color="success" />
      <Typography
        sx={(theme) => ({
          marginInlineStart: theme.spacing(0.5),
          marginInlineEnd: theme.spacing(0.5),
        })}
        color="success.main"
        variant="subtitle1">
        {props.reviewedRowsCount} Verified,
      </Typography>
      {props.unreviewedMismathcedRowsCount > 0 && (
        <Typography color="warning.main">
          {props.unreviewedMismathcedRowsCount} mismatch
        </Typography>
      )}
      {props.unreviewedMismathcedRowsCount > 0 &&
        props.unreviewedRowsCount > 0 && (
          <Typography
            color="text.secondary"
            sx={(theme) => ({
              marginInlineEnd: theme.spacing(0.5),
            })}>
            , and
          </Typography>
        )}
      {props.unreviewedRowsCount > 0 && (
        <Typography color="text.secondary">
          {props.unreviewedRowsCount} unchecked
        </Typography>
      )}
    </View>
  );
};
