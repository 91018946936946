import { Button, IconButton, Stack } from '@mui/material';
import { useTimer } from '@treyd-io/core/hooks/useTimer';
import DownloadIcon from '@treyd-io/treyd-ui/icons/DownloadIcon';
import FileSearchIcon from '@treyd-io/treyd-ui/icons/FileSearchIcon';
import MailIcon from '@treyd-io/treyd-ui/icons/MailIcon';

export const DocumentActions = ({
  onResendClick,
  onViewClick,
  onDownloadClick,
}: {
  onResendClick?: () => void;
  onViewClick?: () => void;
  onDownloadClick?: () => void;
}) => {
  const { counter, startTimer } = useTimer(0, 30);

  const isIconButtonsLayout = Boolean(
    onViewClick && onDownloadClick && onResendClick
  );

  if (isIconButtonsLayout) {
    return (
      <Stack gap={1} direction="row" alignItems="center">
        {onResendClick && (
          <Button
            startIcon={<MailIcon />}
            color="secondary"
            variant="outlined"
            disabled={!!counter}
            size="small"
            onClick={() => {
              onResendClick();
              startTimer();
            }}>
            resend {Boolean(counter) && `${counter}`}
          </Button>
        )}
        <IconButton color="secondary" onClick={onViewClick}>
          <FileSearchIcon />
        </IconButton>
        <IconButton color="info" onClick={onDownloadClick}>
          <DownloadIcon />
        </IconButton>
      </Stack>
    );
  }

  return (
    <Stack gap={1} direction="row" alignItems="center">
      {onResendClick && (
        <Button
          startIcon={<MailIcon />}
          color="secondary"
          variant="outlined"
          size="small"
          disabled={!!counter}
          onClick={() => {
            onResendClick();
            startTimer();
          }}>
          resend {Boolean(counter) && `${counter}`}
        </Button>
      )}
      {onViewClick && (
        <Button
          startIcon={<FileSearchIcon />}
          color="secondary"
          variant="outlined"
          size="small"
          onClick={onViewClick}>
          view
        </Button>
      )}
      {onDownloadClick && (
        <Button
          startIcon={<DownloadIcon />}
          color="info"
          variant="outlined"
          size="small"
          onClick={onDownloadClick}>
          download
        </Button>
      )}
    </Stack>
  );
};
