import { Assignee, CoreCompanyStatus } from '@/schemas';
import { Typography } from '@mui/material';
import { includes, toString } from 'lodash';

const withoutCreditDecisionStatuses: Partial<
  Record<CoreCompanyStatus, string>
> = {
  approved: 'Approved - Not published',
  rejected: 'Rejected',
  opendecision: 'Open decision',
  review_notstarted: 'Not started',
  review_unassigned: 'Not started',
  review_inreview: 'In review',
  review_sentback: 'In review',
  underwriting_notstarted: 'Underwriting',
  underwriting_unassigned: 'Underwriting',
  underwriting_sentback: 'Underwriting',
  underwriting_inanalysis: 'Underwriting',
  underwriting_cancelled: 'Underwriting',
};

export const WithoutCrediDecisionStatus = ({
  coreCompanyStatus,
  assignee,
}: {
  coreCompanyStatus?: CoreCompanyStatus;
  assignee?: Assignee;
}) => {
  if (!coreCompanyStatus || !withoutCreditDecisionStatuses[coreCompanyStatus])
    return;

  const showAssignee = includes<CoreCompanyStatus>(
    [
      'review_inreview',
      'review_sentback',
      'underwriting_sentback',
      'underwriting_inanalysis',
    ],
    coreCompanyStatus
  );

  const assigneeFirstName = toString(assignee?.first_name).trim();
  const assigneeLastName = toString(assignee?.last_name).trim();

  return (
    <Typography variant="body2" color="text.primary">
      Credit decision - {withoutCreditDecisionStatuses[coreCompanyStatus]}{' '}
      {showAssignee && `by ${assigneeFirstName} ${assigneeLastName}`}
    </Typography>
  );
};
