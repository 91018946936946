import { styled } from '@mui/material/styles';

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  top: 0,
  width: '100%',
  paddingBlockEnd: theme.spacing(2),
  position: 'sticky',
  backdropFilter: 'blur(0.625rem)',
  zIndex: 2,
}));
