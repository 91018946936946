import { useState } from 'react';

export const usePagination = (
  initPage = 0,
  initPageSize = 10
): { page: number; size: number; setSize: any; setPage: any } => {
  const [size, setSize] = useState(initPageSize);
  const [page, setPage] = useState(initPage);
  return { size, setSize, page, setPage };
};
