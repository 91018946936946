import { Box, BoxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import AlertIcon from '@treyd-io/treyd-ui/icons/AlertIcon';
import { SentryContext } from 'context';
import { useContext } from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  textAlign: 'center',
}) as typeof Box;

export default function ErrorPage(props: BoxProps) {
  const error = useRouteError();
  const { captureException } = useContext(SentryContext);
  return (
    <ErrorRoot {...props}>
      <AlertIcon sx={{ fontSize: 75 }} />
      <Typography variant="h2" marginBottom={3}>
        Oops!
      </Typography>
      <Typography variant="body1">
        {getErrorMessage(error, captureException)}
      </Typography>
    </ErrorRoot>
  );
}

const getErrorMessage = (
  error: any,
  captureException: (exception: any) => string
) => {
  if (error?.status === 404) {
    captureException(new Error('404'));
    return 'The requested page was not found.';
  } else return 'Sorry, an unexpected error has occurred.';
};
