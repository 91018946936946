import { TableSorting } from '@/types';
import { DocumentNode, useQuery } from '@apollo/client';

export type GQLSchemaGetter = (
  offset: number,
  limit: number,
  searchTerm: string,
  sorting: TableSorting,
  filters: any
) => DocumentNode;
interface GenericRowFormatter<T> {
  (arg: T): any;
}

interface QueryParams {
  offset: number;
  limit: number;
  searchTerm: string;
  sorting: TableSorting;
  filters?: any;
}

const useGQLDataLoader = <T>(
  getGQLSchema: GQLSchemaGetter,
  resourceName: string,
  queryParams: QueryParams,
  formatRows: GenericRowFormatter<T> | undefined
): { rows: any[]; total_count: number; loading: boolean } => {
  const { offset, limit, searchTerm, sorting, filters } = queryParams;
  const gql_schema = getGQLSchema(offset, limit, searchTerm, sorting, filters);
  const { data, loading } = useQuery(gql_schema);
  const total_count =
    data?.[`${resourceName}_aggregate`]?.aggregate?.count || 0;
  const rows =
    data?.[resourceName]?.map((d: T) => (formatRows ? formatRows(d) : d)) || [];
  return {
    rows,
    total_count,
    loading,
  };
};

export default useGQLDataLoader;
