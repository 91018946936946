import { ROUTES } from '@/constants';
import { Stack, Tooltip, Typography } from '@mui/material';
import BellCheckIcon from '@treyd-io/treyd-ui/icons/BellCheckIcon';
import { Link } from 'react-router-dom';

export const SupplierNameLink = ({
  id,
  name,
  isMonitored,
}: {
  id: string;
  name: string;
  isMonitored: boolean;
}) => {
  if (isMonitored) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          maxWidth={150}
          noWrap
          component={Link}
          to={`${ROUTES.suppliers}/${id}`}>
          {name}
        </Typography>
        <Tooltip title={'Monitoring'} arrow placement="top">
          <Stack>
            <BellCheckIcon color="info" />
          </Stack>
        </Tooltip>
      </Stack>
    );
  }
  return (
    <Typography noWrap component={Link} to={`${ROUTES.suppliers}/${id}`}>
      {name}
    </Typography>
  );
};
