import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';
import HelpCircleIcon from '@treyd-io/treyd-ui/icons/HelpCircleIcon';
import InformationIcon from '@treyd-io/treyd-ui/icons/InformationIcon';
import { useToggle } from 'react-use';

export const TrackingGuideDialog = () => {
  const [open, toggleOpen] = useToggle(false);
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isSmallScreens ? (
        <IconButton onClick={toggleOpen} color="info">
          <HelpCircleIcon />
        </IconButton>
      ) : (
        <Button
          startIcon={<HelpCircleIcon />}
          color="info"
          onClick={toggleOpen}>
          Payment tracking guide
        </Button>
      )}

      <Dialog open={open} onClose={toggleOpen}>
        <DialogTitle sx={{ paddingTop: 3 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h5">Payment tracking guide</Typography>
            <IconButton onClick={toggleOpen}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 3 }}>
          <Stack gap={2}>
            <Stack direction={'column'} gap={0.5}>
              <Typography variant={'subtitle1'}>
                Why is my payment still "Payment initiated"?
              </Typography>
              <Typography variant={'body1'}>
                This status indicates that we're awaiting confirmation from the
                beneficiary bank. The delay could be due to the payment not
                being received or the beneficiary bank could have received the
                payment but not reporting back.
              </Typography>
            </Stack>
            <Stack direction={'column'} gap={0.5}>
              <Typography variant={'subtitle1'}>
                Are all payments reported?
              </Typography>
              <Typography variant={'body1'}>
                Payments are equipped with global payment innovation (GPI).
                However, not all banks globally support GPI, leading to
                occasional incomplete tracking for some transactions.
              </Typography>
            </Stack>
            <Stack direction={'column'} gap={0.5}>
              <Typography variant={'subtitle1'}>
                Why can't I track payments with specific methods?
              </Typography>
              <Typography variant={'body1'}>
                Certain payment methods, tailored for efficiency and specific
                purposes, lack real-time tracking support. Methods such as
                Bankgiro, SEPA or sort codes do not offer tracking, impeding
                real-time progress monitoring for these transactions.
              </Typography>
            </Stack>
            <Stack direction={'column'} gap={0.5}>
              <Typography variant={'subtitle1'}>
                Why is my payment pending compliance checks?
              </Typography>
              <Typography variant={'body1'}>
                Compliance issues flagged by a bank in the payment chain require
                resolution before the payment can proceed.
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ marginY: 3 }} />
          <Stack direction={'row'} gap={1}>
            <InformationIcon color="action" />
            <Typography variant={'body2'} color={'textSecondary'}>
              For transactions with untraceable methods, consider reaching out
              directly to the recipient or the relevant financial institution
              for timely updates.
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
