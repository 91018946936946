import { gql, TypedDocumentNode } from '@apollo/client';
import { MutationInvoice_Ocr_Update_DataArgs } from '__generated__/graphql';

type UpdateOcrField = { django: MutationInvoice_Ocr_Update_DataArgs };

type MutationVariables = {
  section_name: string;
  field: string;
  ocr_id: string;
  updated_data: any;
};

export const updateOcrFieldMutation = gql`
  mutation updateOcrField(
    $ocr_id: String!
    $section_name: String!
    $field: String!
    $updated_data: JSONString!
  ) {
    django {
      invoice_ocr_update_data(
        invoice_id: $ocr_id
        section_name: $section_name
        field: $field
        updated_data: $updated_data
      ) {
        ok
      }
    }
  }
` as TypedDocumentNode<UpdateOcrField, MutationVariables>;
