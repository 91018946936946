import { getOrdersQuery } from '@/schemas/getOrdersQuery';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetOrders = ({
  queryOptions,
  skip,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(getOrdersQuery(queryOptions), {
    skip,
  });
