import { Orders_Orders_App_Companyfeatureflag } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

export type GetFeatureFlagByIdQueryType = {
  orders_orders_app_companyfeatureflag_by_pk: Pick<
    Orders_Orders_App_Companyfeatureflag,
    'enabled' | 'id' | 'name'
  >;
};

export type GetFeatureFlagByIdVariablesType = {
  id: number;
};

export const getFeatureFlagByIdQuery = gql`
  query getFeatureFlags($id: bigint!) {
    orders_orders_app_companyfeatureflag_by_pk(id: $id) {
      id
      name
      enabled
    }
  }
` as TypedDocumentNode<
  GetFeatureFlagByIdQueryType,
  GetFeatureFlagByIdVariablesType
>;
