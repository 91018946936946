import { ReviewActionsHeaderList } from '@/pages/orderReview/OrderReviewDetails/ReviewActionsHeader/ReviewActionsHeaderList';
import { ReviewActionsHeaderMenu } from '@/pages/orderReview/OrderReviewDetails/ReviewActionsHeader/ReviewActionsHeaderMenu';
import Order from '@/pages/orderReview/models/Order';
import { IconButton, Stack, Theme, useMediaQuery } from '@mui/material';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';

interface ReviewActionsHeaderProps {
  deal: Order;
  goBack: () => void;
  actionsDisabled: boolean;
  areAllSectionsApproved: boolean;
  dealDuplicates?: Order[];
}

const ReviewActionsHeader = ({
  deal,
  goBack,
  actionsDisabled,
  areAllSectionsApproved,
  dealDuplicates,
}: ReviewActionsHeaderProps) => {
  const isSmallScreens = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <Stack direction="row" alignItems="center">
      <>
        {isSmallScreens ? (
          <ReviewActionsHeaderMenu
            order={deal}
            actionsDisabled={actionsDisabled}
            goBack={goBack}
            areAllSectionsApproved={areAllSectionsApproved}
            dealDuplicates={dealDuplicates}
          />
        ) : (
          <ReviewActionsHeaderList
            order={deal}
            actionsDisabled={actionsDisabled}
            goBack={goBack}
            areAllSectionsApproved={areAllSectionsApproved}
            dealDuplicates={dealDuplicates}
          />
        )}
      </>
      <IconButton
        onClick={goBack}
        sx={{ marginInlineStart: 2, border: 1, borderColor: 'secondary.dark' }}
        size="large"
        color="primary">
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export default ReviewActionsHeader;
