import { IconButton, Stack, Typography } from '@mui/material';
import ArrowUpRightIcon from '@treyd-io/treyd-ui/icons/ArrowUpRightIcon';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import ForbidIcon from '@treyd-io/treyd-ui/icons/ForbidIcon';
import { Link } from 'react-router-dom';

interface SectionHeaderProps {
  title: string;
  subtitle: string;
  href?: string;
  isApproved?: boolean;
}

export const SectionHeader = (props: SectionHeaderProps) => {
  const { title, subtitle, href, isApproved } = props;

  const status = isApproved ? 'success' : 'error';

  const icon = {
    success: <CheckCircleIcon color="success" />,
    error: <ForbidIcon color="error" />,
  };

  return (
    <Stack gap={1.5}>
      <Stack direction="row" alignItems="center" gap={1}>
        {icon[status]}
        <Typography variant="subtitle1" color={`${status}.main`}>
          {title}
        </Typography>
      </Stack>
      <Stack direction="row" gap={1}>
        <Typography variant="h5" color={`${status}.main`}>
          {subtitle}
        </Typography>
        {href && (
          <IconButton
            color={status}
            size="small"
            href={href}
            LinkComponent={(props) => <Link to={props.href} {...props} />}>
            <ArrowUpRightIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};
