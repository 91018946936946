import { gql, TypedDocumentNode } from '@apollo/client';
import { FilterComparator, QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import { map } from 'lodash';

type InvoiceByCurrencyAggregation = {
  aggregate: {
    count: number;
    sum: {
      invoiced_amount: number;
      paid_amount: number;
      remaining_amount: number;
    };
  };
};

type AggregateInvoicesByCurrenciesQueryType = Record<
  string,
  InvoiceByCurrencyAggregation
>;

export const aggregateInvoicesByCurrenciesQuery = (
  currencies?: string[],
  queryOptions?: QueryOptions
) => {
  const currenciesTemplates = map(currencies, (currency) =>
    getCurrencyTemplate(currency, queryOptions)
  ).join('\n');

  return gql`
    query aggregateInvoicesByCurrencies {
     ${currenciesTemplates}
    }
  ` as TypedDocumentNode<AggregateInvoicesByCurrenciesQueryType, {}>;
};

const getCurrencyTemplate = (currency: string, queryOptions?: QueryOptions) => {
  const { queryParams } = getGqlQueryParams({
    ...queryOptions,
    filters: [
      ...(queryOptions?.filters || []),
      {
        type: 'filter',
        name: 'invoiced_currency',
        comparator: FilterComparator.EQUAL,
        comparisonValue: currency,
      },
    ],
  });

  return `
    ${currency}:orders_order_invoices_aggregate ${queryParams} {
      aggregate {
        count
        sum {
          invoiced_amount
          paid_amount
          remaining_amount
        }
      }
    }
    `;
};
