import { Filter } from '@treyd-io/core/types/hasura';
import { find, reduce } from 'lodash';

type FiltersMappers<Filters> = Record<keyof Filters, (value: any) => any>;

export function getFiltersInitialValues<Filters>(
  filters?: Filter<keyof Filters>[],
  filtersMappers?: FiltersMappers<Filters>
): Filters {
  return reduce(
    filtersMappers,
    (acc, mapper, key) => {
      const filter = find(filters, (filter) => filter.key === key);
      const comparisonValue = filter?.comparisonValue;
      acc[key as keyof Filters] = mapper(comparisonValue);
      return acc;
    },
    {} as Filters
  );
}
