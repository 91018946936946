import { ROUTES } from '@/constants';
import SupplierDetails from '@/pages/suppliers/SupplierDetails';
import { RouteObject } from 'react-router-dom';
import { SuppliersPage } from './SuppliersPage';

export const SUPPLIERS: RouteObject = {
  path: `${ROUTES.suppliers}`,
  children: [
    {
      index: true,
      element: <SuppliersPage />,
    },
    {
      path: ':id',
      element: <SupplierDetails />,
    },
  ],
};
