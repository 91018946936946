import codes from 'country-calling-code';
import countries from 'i18n-iso-countries';
import en_countries from 'i18n-iso-countries/langs/en.json';
import { lowerCase, map, mapKeys, toLower } from 'lodash';
import { SignMethods } from '../types/auth';
import { SIGN_METHOD } from './auth';

countries.registerLocale(en_countries);

export const COUNTRIES_ENUM: Readonly<string[]> = map(
  countries.getNames('en'),
  (_value, key) => key.toLowerCase()
);
export const COUNTRIES_NAMES_ENUM: Readonly<string[]> = map(
  countries.getNames('en'),
  (value) => value
);

export const COUNTRIES_OPTIONS: Readonly<{ title: string; value: string }[]> =
  COUNTRIES_NAMES_ENUM.map((countryName, idx) => ({
    title: countryName,
    value: lowerCase(COUNTRIES_ENUM[idx]),
  }));

export const COUNTRY_CODE_TO_NAME = mapKeys(countries.getNames('en'), (_, k) =>
  toLower(k)
);

export const NORDIC_COUNTRIES: Readonly<string[]> = ['se', 'no', 'fi', 'dk'];
export const BANKID_COUNTRIES: Readonly<string[]> = ['se', 'no', 'fi'];
export const REGULATED_COUNTRIES: Readonly<string[]> = ['se', 'no', 'fi', 'dk'];
export const ERP_COUNTRIES: Readonly<string[]> = ['se', 'gb', 'no', 'ie', 'dk'];
export const NON_REGULATED_COUNTRIES: Readonly<string[]> = ['gb', 'ie'];

export const COUNTRY_SIGN_METHODS: Readonly<SignMethods> = {
  se: [SIGN_METHOD.BANKID_SE, SIGN_METHOD.OTP],
  no: [SIGN_METHOD.BANKID_NO, SIGN_METHOD.OTP],
  fi: [SIGN_METHOD.BANKID_FI, SIGN_METHOD.OTP],
  dk: [SIGN_METHOD.OTP],
  gb: [SIGN_METHOD.OTP],
  ie: [SIGN_METHOD.OTP],
  default: [SIGN_METHOD.OTP],
};

export const COUNTRY_CODES: Readonly<{ title: string; value: string }[]> =
  codes.map((code) => ({
    title: `+${code.countryCodes[0]} (${code.country})`,
    value: code.countryCodes[0],
  }));

export const COUNTRIES = [
  { title: 'Sweden', value: 'se', currency: 'SEK' },
  { title: 'Norway', value: 'no', currency: 'NOK' },
  { title: 'Denmark', value: 'dk', currency: 'DKK' },
  { title: 'Finland', value: 'fi', currency: 'EUR' },
  { title: 'United Kingdom', value: 'gb', currency: 'GBP' },
  { title: 'Ireland', value: 'ie', currency: 'EUR' },
];

export const invoicingCurrencies = ['SEK', 'DKK', 'NOK', 'GBP', 'USD', 'EUR'];
