import { resendDocumentEmails } from '@/api';
import { Documents } from '@/types';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

export const useResendDocumentEmails = () =>
  useMutation<AxiosResponse, AxiosError, Documents.ResendDocumentEmailsPayload>(
    {
      mutationKey: ['resend-document-emails'],
      mutationFn: resendDocumentEmails,
    }
  );
