import {
  GetCompanyCreditDecisionHistoryQueryVariables,
  getCompanyCreditDecisionHistoryQuery,
} from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetCompanyCreditDecisionHistory = (
  variables: GetCompanyCreditDecisionHistoryQueryVariables
) =>
  useQuery(getCompanyCreditDecisionHistoryQuery, {
    variables,
    skip: !variables?.orgNumber || !variables?.country,
  });
