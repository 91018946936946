import { Box, Button, styled } from '@mui/material';

export const Form = styled('form')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  border: `1px solid ${theme.palette.grey['400']}`,
  borderRadius: theme.spacing(1),
  marginBlock: theme.spacing(3),
  padding: theme.spacing(2),
}));

export const FormButton = styled(Button)(({ theme }) => ({
  paddingBlock: theme.spacing(0.75),
  paddingInline: theme.spacing(2),
  borderRadius: theme.spacing(1),
  fontWeight: 400,
  fontSize: theme.spacing(1.75),
  lineHeight: theme.spacing(3),
}));

export const FormGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  marginBlock: theme.spacing(3),
  padding: theme.spacing(2),
}));
