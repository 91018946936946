import { Orders_Companies, Orders_Financiers } from '@/__generated__/graphql';
import { gql } from '@apollo/client';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';

export type Merchant = Pick<Orders_Companies, 'id' | 'name' | 'country'> & {
  financier: Pick<Orders_Financiers, 'name'>;
};

export const getNotificationsMerchantsList = (
  filters = {
    financier__name: '',
    country: '',
  }
) => {
  let NotificationsMerchantsFilters: Filter[] = [];

  if (filters.financier__name) {
    NotificationsMerchantsFilters.push({
      type: 'filter',
      name: 'financier__name',
      comparator: FilterComparator.IN,
      comparisonValue: filters.financier__name,
    });
  }
  if (filters.country) {
    NotificationsMerchantsFilters.push({
      type: 'filter',
      name: 'country',
      comparator: FilterComparator.IN,
      comparisonValue: filters.country,
    });
  }
  const { queryParams } = getGqlQueryParams({
    offset: 0,
    limit: undefined,
    sorting: undefined,
    filters: NotificationsMerchantsFilters,
  });

  return gql`
query getMerchants {
  orders_companies${queryParams} {
    id
		name
    country
    financier {
      name
    }
  }
}`;
};
