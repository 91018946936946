import { gql } from '@apollo/client';

export const updateUserProfileMutation = gql`
  mutation updateUser(
    $company_user_id: bigint!
    $userData: lager_treyd_auth_companyuser_set_input!
  ) {
    update_lager_treyd_auth_companyuser_by_pk(
      pk_columns: { id: $company_user_id }
      _set: $userData
    ) {
      user_id
      can_sign_alone
      is_ubo
    }
  }
`;
