import { GridSortItem } from '@mui/x-data-grid';
import {
  compact,
  entries,
  filter,
  isArray,
  isEmpty,
  isNil,
  isNull,
  join,
  map,
  omitBy,
  set,
  toString,
} from 'lodash';
import { Filter, FilterComparator, QueryOptions } from '../types/hasura';

export const getGqlQueryParams = (options?: QueryOptions | null) => {
  if (isNil(options))
    return {
      whereClause: '',
      queryParams: '',
      orderBy: '{}',
      limit: 0,
      offset: 0,
    };
  const { offset, limit, filters, sorting } = options;
  const whereQuery = getWhereQuery(filters);
  const where = whereQuery && `{${whereQuery}}`;
  const orderBy = getSortingParams(sorting);
  const result = getSanitizedGqlQueryParams(offset, limit, orderBy, where);

  return {
    whereClause: where || '',
    queryParams: result ? `(${result})` : '',
    orderBy: orderBy || '{}',
    limit: limit || 0,
    offset: offset || 0,
  };
};

const getSanitizedGqlQueryParams = (
  offset?: number,
  limit?: number,
  orderBy?: string | null,
  where?: string | null
) => {
  const sanitizedQueryParts = omitBy(
    {
      offset,
      limit,
      where,
      order_by: orderBy,
    },
    isNil
  );
  return `${entries(sanitizedQueryParts)
    .map(([key, value]) => `${key}:${value}`)
    .join(', ')}`;
};

const getSortingParams = (sorting?: GridSortItem | null) => {
  if (isNil(sorting)) return null;

  return JSON.stringify(
    set({}, sorting.field.replaceAll('__', '.'), `${sorting.sort}_nulls_last`)
  ).replaceAll('"', '');
};

const getFilterParamsList = (filters?: Filter[]) => {
  if (isEmpty(filters)) return null;

  return map(filters, (field) => formatField(field));
};

const getWhereQuery = (filters?: Filter[]) => {
  const searchFields = filter(
    filters,
    (filter) =>
      filter.type === 'search' &&
      Boolean(sanitizeSearchTerm(toString(filter.comparisonValue)))
  );
  const searchParams = getFilterParamsList(searchFields);
  const whereSearchParams = getWherePart(searchParams, 'or');

  const filterFields = filter(filters, (filter) => filter.type === 'filter');
  const filterParams = getFilterParamsList(filterFields);
  const whereFilterParams = getWherePart(filterParams, 'and');

  if (isNull(whereSearchParams) && isNull(whereFilterParams)) return null;
  return join(compact([whereSearchParams, whereFilterParams]), ', ');
};

const getWherePart = (
  filterParamsList: string[] | null,
  operator: 'and' | 'or' | 'not'
) => {
  if (isNull(filterParamsList)) return null;

  return !isNull(filterParamsList)
    ? `_${operator}:[${filterParamsList.join(',')}]`
    : '';
};

const formatField = (field: Filter) => {
  const VALUE_FORMATS: {
    [key in FilterComparator]?: string | object;
  } = {
    [FilterComparator.LIKE]: `%{{comparison_value}}%`,
    [FilterComparator.NOT_LIKE]: `%{{comparison_value}}%`,
    [FilterComparator.CASE_INSENSITIVE_LIKE]: `%{{comparison_value}}%`,
    [FilterComparator.NOT_CASE_INSENSITIVE_LIKE]: `%{{comparison_value}}%`,
  };

  const name = field.name.replaceAll('__', '.');

  let fieldPath = '';
  let value = undefined;

  if (field.comparator === FilterComparator.NOT_EXIST) {
    const nameParts = name.split('.');
    const lastPart = nameParts.pop() || '';
    nameParts.push('_not');
    nameParts.push(lastPart);
    fieldPath = nameParts.join('.');
    value = {};
    return JSON.stringify(set({}, fieldPath, value)).replaceAll('"', '');
  }

  if (field.comparator === FilterComparator.EXIST) {
    fieldPath = name;
    value = {};
    return JSON.stringify(set({}, fieldPath, value)).replaceAll('"', '');
  }

  if (VALUE_FORMATS[field.comparator]) {
    fieldPath = name;
    value = {
      [field.comparator]: VALUE_FORMATS[field.comparator],
    };
    return JSON.stringify(set({}, fieldPath, value))
      .replaceAll('"', '')
      .replace('%{{comparison_value}}%', `"%${field.comparisonValue}%"`);
  }

  if (typeof field.comparisonValue === 'string') {
    fieldPath = name;
    value = {
      [field.comparator]: '{{comparison_value}}',
    };
    return JSON.stringify(set({}, fieldPath, value))
      .replaceAll('"', '')
      .replace('{{comparison_value}}', `"${field.comparisonValue}"`);
  }

  if (isArray(field.comparisonValue)) {
    fieldPath = name;
    value = {
      [field.comparator]: map(field.comparisonValue, (item) => `{{${item}}}`),
    };
    let valueString = JSON.stringify(set({}, fieldPath, value)).replaceAll(
      '"',
      ''
    );
    for (const item of field.comparisonValue) {
      valueString = valueString.replace(`{{${item}}}`, `"${item}"`);
    }
    return valueString;
  }
  fieldPath = name;
  value = {
    [field.comparator]: field.comparisonValue,
  };
  return JSON.stringify(set({}, fieldPath, value)).replaceAll('"', '');
};

const sanitizeSearchTerm = (searchTerm: string) =>
  searchTerm
    .trim()
    .replaceAll('\\"', '')
    .replaceAll('"', '')
    .replaceAll('\\', '\\\\');
