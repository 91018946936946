import { useGetCompanyCreditDecisionHistory } from '@/hooks';
import {
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { isEmpty, isNumber, map, startCase } from 'lodash';
import { EmptySection } from './EmptySection';

export const CreditDecisionHistorySection = ({
  orgNumber,
  country,
}: {
  orgNumber: string;
  country: string;
}) => {
  const { data: creditDecisionHistoryData, loading } =
    useGetCompanyCreditDecisionHistory({
      orgNumber,
      country,
    });

  const creditDecisionHistory =
    creditDecisionHistoryData?.lager_core_company?.[0]?.creditdecisions;

  if (loading)
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );

  return (
    <Stack gap={2}>
      <Typography variant="h5" color="text.primary">
        Credit decision history
      </Typography>
      {isEmpty(creditDecisionHistory) ? (
        <EmptySection
          title="No decision history"
          subtitle="The previous decisions will appear here."
        />
      ) : (
        <TableContainer
          elevation={0}
          component={Paper}
          sx={{
            backgroundColor: 'transparent',
            border: 1,
            borderColor: 'other.outlined_border',
            borderRadius: 2,
          }}>
          <Table sx={{ minWidth: 300 }} size="small">
            <TableHead
              sx={{
                backgroundColor: 'grey.100',
                [`.${tableCellClasses.root}`]: {
                  color: 'text.secondary',
                },
              }}>
              <TableRow>
                <TableCell>Created at</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Expiration date</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Limit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(creditDecisionHistory, (row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.created && formatDate(new Date(row.created), 'date')}
                  </TableCell>
                  <TableCell>{startCase(row.status)}</TableCell>
                  <TableCell>
                    {row.published_at &&
                      formatDate(new Date(row.published_at), 'date')}
                  </TableCell>
                  <TableCell>
                    {row.expiration_date &&
                      formatDate(new Date(row.expiration_date), 'date')}
                  </TableCell>
                  <TableCell>
                    {isNumber(row.approved_rating) &&
                      `${row.approved_rating}/10`}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(
                      row.approved_limit,
                      row.approved_limit_currency
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};
