import { Orders_Orders_App_Companyfeatureflag } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

export type UpdateFeatureFlagMutationType = Pick<
  Orders_Orders_App_Companyfeatureflag,
  'id' | 'name' | 'enabled'
>;

export type UpdateFeatureFlagVariablesType = {
  id: number;
  data: {
    enabled: boolean;
  };
};

export const updateFeatureFlagMutation = gql`
  mutation updateFeatureFlag(
    $id: bigint!
    $data: orders_orders_app_companyfeatureflag_set_input!
  ) {
    update_orders_orders_app_companyfeatureflag_by_pk(
      pk_columns: { id: $id }
      _set: $data
    ) {
      id
      name
      enabled
    }
  }
` as TypedDocumentNode<
  UpdateFeatureFlagMutationType,
  UpdateFeatureFlagVariablesType
>;
