'use client';

import { Stack, Typography } from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { UploadButton } from '@treyd-io/treyd-ui/components/Upload/UploadButton';
import { Attachment } from '@treyd-io/treyd-ui/types/file';
import { ReactNode } from 'react';

interface UploadCardProps {
  title: string;
  subtitle: ReactNode;
  files: Attachment[];
  isLoading?: boolean;
  uploadFile: (file: File) => void;
  onDelete?: (id?: number) => void;
  single?: boolean;
}

export const UploadCard = (props: UploadCardProps) => {
  const { title, subtitle, files, isLoading, single, uploadFile, onDelete } =
    props;

  return (
    <Stack
      border={1}
      borderColor={'other.outlined_border'}
      borderRadius={3}
      padding={2.5}>
      <Row justifyContent="space-between">
        <Col maxWidth={{ md: 640 }}>
          <Stack gap={0.5}>
            {typeof title === 'string' ? (
              <Typography variant="h6">{title}</Typography>
            ) : (
              title
            )}
            {typeof subtitle === 'string' ? (
              <Typography variant="body1" color={'text.secondary'}>
                {subtitle}
              </Typography>
            ) : (
              subtitle
            )}
          </Stack>
        </Col>
        <Col xs={12} md="auto">
          <UploadButton
            text={'Upload'}
            isError={false}
            title={title}
            subtitle={subtitle}
            files={files}
            uploadFile={uploadFile}
            isLoading={isLoading}
            onDelete={onDelete}
            single={single}
          />
        </Col>
      </Row>
    </Stack>
  );
};
