import { isDevelopment } from './api';

export const PLATFORM_VERSION_KEY = 'platform_version_id';
export const AUTH_TOKEN_KEY = 'authKey';
export const REFRESH_KEY = 'refresh_key';
export const LOCALE = 'locale';
export const AUTH_LAST_REFRESHED_AT = 'authLastRefreshedAt';
export const REFRESH_ACCESS_TOKEN_FREQ = 1000 * 60 * 1; // 1 minutes;
export const MAX_TIME_OF_INACTIVITY = 60 * 5; // 5 minutes;
export const PROTOCOL = isDevelopment
  ? { http: 'http', ws: 'ws' }
  : { http: 'https', ws: 'wss' };
export const TREYD_WEBSITE_URL = 'https://treyd.io';
