import { FormButton } from '@/components/styled-components/FormStyles';
import {
  DetailsForm,
  GeneralFormikCheckBox,
  GeneralFormikInput,
} from '@/pages/dev/components';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { useToggle } from 'react-use';

export interface FormValues {
  name: string;
  enabled: boolean;
}

export const InsertFeatureFlagDialog = ({
  insertFeatureFlagHandler,
}: {
  insertFeatureFlagHandler: (name: string, enabled: boolean) => Promise<void>;
}) => {
  const [open, toggle] = useToggle(false);
  const initialValues: FormValues = {
    name: '',
    enabled: false,
  };

  return (
    <Box>
      <Button variant="outlined" color="secondary" onClick={toggle}>
        Add Feature Flag
      </Button>
      <Dialog open={open} onClose={toggle}>
        <DialogTitle>
          <Typography variant="h5">Add Feature Flag</Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              insertFeatureFlagHandler(values.name, values.enabled).then(() => {
                setSubmitting(false);
                toggle();
              });
            }}>
            <DetailsForm>
              <GeneralFormikInput
                label="Name"
                name="name"
                type="string"
                placeholder="Feature flag name"
              />
              <GeneralFormikCheckBox name="enabled">
                Enabled
              </GeneralFormikCheckBox>
              <FormButton variant="outlined" type="submit">
                Submit
              </FormButton>
            </DetailsForm>
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
