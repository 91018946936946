import { KycData } from '@/types';
import { Box, Stack, Typography } from '@mui/material';
import { getCountryName } from '@treyd-io/core/utils/country';
import { getAddress } from '@treyd-io/core/utils/organization';
import { formatPersonalNumber } from '@treyd-io/core/utils/personalNumber';
import { toString } from 'lodash';
import { PreviewKycHead } from './PreviewKycHead';
import { SectionTable } from './PreviewKycSectionTable';

interface PreviewKycThirdVersionProps {
  submittedKycData: KycData;
  onRevokeKycClick: () => void;
  onEditKycClick: () => void;
  submittedAt?: string;
  submittedBy?: string;
  isEditable?: boolean;
  isPreview?: boolean;
}

export const PreviewKycThirdVersion = (props: PreviewKycThirdVersionProps) => {
  const {
    submittedKycData,
    submittedAt,
    submittedBy,
    isPreview,
    isEditable,
    onRevokeKycClick,
    onEditKycClick,
  } = props;

  const { company_info, company_signers, owners, ...company_operations } =
    submittedKycData;
  const importedProducts = company_operations?.imported_products;

  return (
    <Box marginBottom={6}>
      <Stack gap={4}>
        <PreviewKycHead
          onRevokeClick={isPreview || isEditable ? onRevokeKycClick : undefined}
          onEditClick={isPreview ? onEditKycClick : undefined}
          submittedAt={submittedAt}
          submittedBy={submittedBy}
          version={3}
        />
        <Box>
          <Box>
            <Typography variant="subtitle1" color="text.primary">
              Company information
            </Typography>

            <Box marginTop={2}>
              <SectionTable
                rows={[
                  { key: 'Org #', value: company_info?.org_no },
                  {
                    key: 'Phone number',
                    value: company_info?.phone_number,
                  },
                  {
                    key: 'Address',
                    value: getAddress({
                      firstLineAddress: toString(company_info?.address),
                      zipCode: toString(company_info?.zip_code),
                      city: toString(company_info?.city),
                      country: getCountryName(toString(company_info?.country)),
                    }),
                  },
                ]}
                title={company_info?.name}
              />
            </Box>
          </Box>
          <Box marginTop={3}>
            <Typography variant="subtitle1" color="text.primary">
              Authorized company signers
            </Typography>
            {company_signers?.map((signer) => (
              <Box marginTop={2} key={signer?.personal_no}>
                <SectionTable
                  rows={[
                    {
                      key: 'Email',
                      value: signer?.email,
                    },
                    {
                      key: 'Personal number / Date of birth',
                      value: formatPersonalNumber(signer?.personal_no),
                    },
                    {
                      key: 'Address',
                      value: signer?.address,
                    },
                    {
                      key: 'Zip code',
                      value: signer?.zip_code,
                    },
                    {
                      key: 'City',
                      value: signer?.city,
                    },
                    {
                      key: 'Country of residence',
                      value: getCountryName(signer?.country_of_residence),
                    },
                    {
                      key: 'Citizen in',
                      value: signer?.citizenship
                        ?.map((country) => getCountryName(country))
                        ?.join(', '),
                    },
                  ]}
                  title={signer?.name}
                />
              </Box>
            ))}
          </Box>
          <Box marginTop={3}>
            <Typography variant="subtitle1" color="text.primary">
              Ultimate beneficial owners
            </Typography>
            {owners?.map((owner) => (
              <Box marginTop={2} key={owner?.personal_no}>
                <SectionTable
                  rows={[
                    {
                      key: 'Email',
                      value: owner?.email,
                    },
                    {
                      key: 'Personal number / Date of birth',
                      value: formatPersonalNumber(owner?.personal_no),
                    },
                    {
                      key: 'Address',
                      value: owner?.address,
                    },
                    {
                      key: 'Zip code',
                      value: owner?.zip_code,
                    },
                    {
                      key: 'City',
                      value: owner?.city,
                    },
                    {
                      key: 'Country of residence',
                      value: getCountryName(owner?.country_of_residence),
                    },
                    {
                      key: 'Tax liable in',
                      value: owner?.tax_liable_country
                        ?.map((country) => getCountryName(country))
                        ?.join(', '),
                    },
                    {
                      key: 'Citizen in',
                      value: owner?.citizenship
                        ?.map((country) => getCountryName(country))
                        ?.join(', '),
                    },
                    { key: 'PEP', value: owner?.pep },
                  ]}
                  title={owner?.name}
                />
              </Box>
            ))}
          </Box>
          <Box marginTop={3}>
            <Typography variant="subtitle1" color="text.primary">
              Products
            </Typography>
            {importedProducts?.map((product) => (
              <Box marginTop={2} key={product?.type}>
                <SectionTable
                  rows={[
                    {
                      key: 'From country',
                      value: getCountryName(product?.country),
                    },
                  ]}
                  title={product?.type}
                />
              </Box>
            ))}
          </Box>
          <Box marginTop={3}>
            <Typography variant="subtitle1" color="text.primary">
              Additional information
            </Typography>
            <Box marginTop={2}>
              <SectionTable
                rows={[
                  {
                    key: 'Number of orders per year',
                    value: company_operations?.yearly_number_of_orders,
                  },
                  {
                    key: 'Average order size',
                    value: company_operations?.size_per_order,
                  },
                  {
                    key: 'Ownership structure',
                    value: company_operations?.ownership_structure,
                  },
                  {
                    key: 'Other forms of financing',
                    value: company_operations?.forms_of_financing,
                  },
                  {
                    key: 'Monthly revenue',
                    value: `${company_operations?.monthly_revenue} ${company_operations?.monthly_revenue_currency}`,
                  },
                  {
                    key: 'Handles cash',
                    value: company_operations?.company_handle_cash
                      ? 'Yes'
                      : 'No',
                  },
                  {
                    key: 'Industries category',
                    value: company_operations?.industry_category,
                  },
                  {
                    key: 'Industries category details',
                    value: company_operations?.industry_category_details,
                  },
                  {
                    key: 'Industries',
                    value: company_operations?.business_industry?.join(', '),
                  },
                  {
                    key: 'Ownership in supplier',
                    value: company_operations?.supplier_ownership
                      ? 'Yes'
                      : 'No',
                  },
                  {
                    key: 'Link to website/social media',
                    value: company_operations?.online_service,
                  },
                ]}
              />
            </Box>
          </Box>
          {Boolean(company_operations?.customer_sales_optional?.length) && (
            <Box marginTop={3}>
              <Typography variant="subtitle1" color="text.primary">
                Customers over 20% of sales
              </Typography>

              {company_operations?.customer_sales_optional?.map((customer) => (
                <Box marginTop={2} key={customer?.customer_sales_name}>
                  <SectionTable
                    rows={[
                      {
                        key: 'Percentage',
                        value: customer?.customer_sales_percentage,
                      },
                    ]}
                    title={customer?.customer_sales_name}
                  />
                </Box>
              ))}
            </Box>
          )}
          {Boolean(company_operations?.supplier_ownership_optional?.length) && (
            <Box marginTop={3}>
              <Typography variant="subtitle1" color="text.primary">
                Suppliers with ownership in
              </Typography>
              {company_operations?.supplier_ownership_optional?.map(
                (supplier) => (
                  <Box marginTop={2} key={supplier?.supplier_ownership_name}>
                    <SectionTable
                      rows={[
                        {
                          key: 'Percentage',
                          value: supplier?.supplier_ownership_percentage,
                        },
                      ]}
                      title={supplier?.supplier_ownership_name}
                    />
                  </Box>
                )
              )}
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
