import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  title?: string;
  rows: { key: string; value: ReactNode }[];
}

export const SectionTable = (props: Props) => {
  const isAllRowsEmpty = props.rows?.every((row) => !row?.value);
  if (isAllRowsEmpty) return null;

  return (
    <TableContainer
      component={Paper}
      sx={{
        border: 1,
        borderColor: 'other.outlined_border',
        borderRadius: 2,
        boxShadow: 'none',
        backgroundColor: 'background.default',
      }}>
      <Table size="small">
        {props.title && (
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" color="text.primary">
                  {props.title}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {props.rows?.map(
            (row) =>
              row.value && (
                <TableRow
                  key={row.key}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}>
                  <TableCell component="th" scope="row" width={280}>
                    <Typography variant="body2" color="text.secondary">
                      {row.key}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {typeof row.value === 'string' ? (
                      <Typography variant="body2" color="text.primary">
                        {row.value}
                      </Typography>
                    ) : (
                      row.value
                    )}
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
