export enum SIGNUP_METHOD {
  GOOGLE = 'Google',
  EMAIL_AND_PASSWORD = 'Email_And_Password',
}
export enum SIGN_METHOD {
  'BANKID_SE' = 'bankid_se',
  'BANKID_NO' = 'bankid_no',
  'BANKID_FI' = 'bankid_fi',
  'OTP' = 'otp',
}

export enum SCRIVE_TYPE {
  'SIGN' = 'sign',
  'AUTH' = 'auth',
}
