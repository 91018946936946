import { Stack, SvgIconProps, Typography } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import CloseCircleIcon from '@treyd-io/treyd-ui/icons/CloseCircleIcon';

export type DecisionCardColor = 'success' | 'warning' | 'error';

interface DecisionCardProps {
  color: DecisionCardColor;
  title: string;
  reason?: string;
  comment: string;
  date: Date;
}

export const DecisionCard = (props: DecisionCardProps) => {
  return (
    <Stack
      gap={1.5}
      bgcolor={`${props.color}.190p`}
      padding={2}
      borderRadius={2}>
      <Stack direction="row" gap={1} alignItems="center">
        <CardIcon color={props.color} />
        <Typography variant="subtitle2" color="text.primary">
          {props.title}
        </Typography>
      </Stack>
      <Stack gap={0.5}>
        {props.reason && (
          <Typography variant="subtitle1" color="text.primary">
            {props.reason}
          </Typography>
        )}
        <Typography variant="body1" color="text.primary">
          {props.comment}
        </Typography>
      </Stack>
      <Typography variant="caption" color="text.secondary">
        {formatDate(props.date, 'daytime')}
      </Typography>
    </Stack>
  );
};

const CardIcon = (
  props: Omit<SvgIconProps, 'color'> & { color: DecisionCardColor }
) => {
  const ICONS = {
    success: <CheckCircleIcon {...props} />,
    warning: <ArrowLeftIcon {...props} />,
    error: <CloseCircleIcon {...props} />,
  };
  return ICONS[props.color];
};
