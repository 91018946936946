import { Box, Typography } from '@mui/material';

export const NoDocumentsPlaceholder = () => {
  return (
    <Box
      padding={3}
      borderRadius={2}
      border={1}
      borderColor="grey.300"
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}>
        <Typography variant="subtitle1">No POA</Typography>
        <Typography variant="body1" color={'text.secondary'}>
          POA requests will be displayed here.
        </Typography>
      </Box>
    </Box>
  );
};
