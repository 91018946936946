import {
  MAPPED_ORDER_STATUS,
  ORDER_MAPPED_STATUS_DISPLAYED_VALUE,
} from '@/constants';
import { Chip, ChipProps } from '@mui/material';

interface Props extends ChipProps {
  status: MAPPED_ORDER_STATUS;
}
const chipProps: {
  [key in MAPPED_ORDER_STATUS]?: ChipProps;
} = {
  [MAPPED_ORDER_STATUS.DRAFT]: {
    label: ORDER_MAPPED_STATUS_DISPLAYED_VALUE[MAPPED_ORDER_STATUS.DRAFT],
    color: 'default',
    variant: 'outlined',
  },
  [MAPPED_ORDER_STATUS.DISCARDED]: {
    label: ORDER_MAPPED_STATUS_DISPLAYED_VALUE[MAPPED_ORDER_STATUS.DISCARDED],
    color: 'error',
    variant: 'outlined',
  },
  [MAPPED_ORDER_STATUS.PREVIEW]: {
    label: ORDER_MAPPED_STATUS_DISPLAYED_VALUE[MAPPED_ORDER_STATUS.PREVIEW],
    color: 'default',
    variant: 'outlined',
  },
  [MAPPED_ORDER_STATUS.AWAITING_APPROVAL]: {
    label:
      ORDER_MAPPED_STATUS_DISPLAYED_VALUE[
        MAPPED_ORDER_STATUS.AWAITING_APPROVAL
      ],
    color: 'default',
    variant: 'filled',
  },
  [MAPPED_ORDER_STATUS.AWAITING_COMPLIANCE_APPROVAL]: {
    label:
      ORDER_MAPPED_STATUS_DISPLAYED_VALUE[
        MAPPED_ORDER_STATUS.AWAITING_COMPLIANCE_APPROVAL
      ],
    color: 'default',
    variant: 'filled',
  },
  [MAPPED_ORDER_STATUS.AWAITING_FUNDING_APPROVAL]: {
    label:
      ORDER_MAPPED_STATUS_DISPLAYED_VALUE[
        MAPPED_ORDER_STATUS.AWAITING_FUNDING_APPROVAL
      ],
    color: 'default',
    variant: 'filled',
  },
  [MAPPED_ORDER_STATUS.READY_FOR_PAYMENT]: {
    label:
      ORDER_MAPPED_STATUS_DISPLAYED_VALUE[
        MAPPED_ORDER_STATUS.READY_FOR_PAYMENT
      ],
    color: 'default',
    variant: 'filled',
  },
  [MAPPED_ORDER_STATUS.PAYMENT_PROCESSING]: {
    label:
      ORDER_MAPPED_STATUS_DISPLAYED_VALUE[
        MAPPED_ORDER_STATUS.PAYMENT_PROCESSING
      ],
    color: 'default',
    variant: 'filled',
  },
  [MAPPED_ORDER_STATUS.PAID]: {
    label: ORDER_MAPPED_STATUS_DISPLAYED_VALUE[MAPPED_ORDER_STATUS.PAID],
    color: 'default',
    variant: 'filled',
  },
  [MAPPED_ORDER_STATUS.INVOICE_OPEN]: {
    label:
      ORDER_MAPPED_STATUS_DISPLAYED_VALUE[MAPPED_ORDER_STATUS.INVOICE_OPEN],
    color: 'info',
    variant: 'standard',
  },
  [MAPPED_ORDER_STATUS.INVOICE_OVERDUE]: {
    label:
      ORDER_MAPPED_STATUS_DISPLAYED_VALUE[MAPPED_ORDER_STATUS.INVOICE_OVERDUE],
    color: 'error',
    variant: 'standard',
  },
  [MAPPED_ORDER_STATUS.INVOICE_COLLECTION]: {
    label:
      ORDER_MAPPED_STATUS_DISPLAYED_VALUE[
        MAPPED_ORDER_STATUS.INVOICE_COLLECTION
      ],
    color: 'error',
    variant: 'standard',
  },
  [MAPPED_ORDER_STATUS.CLOSED]: {
    label: ORDER_MAPPED_STATUS_DISPLAYED_VALUE[MAPPED_ORDER_STATUS.CLOSED],
    color: 'default',
    variant: 'outlined',
  },
  [MAPPED_ORDER_STATUS.REJECTED]: {
    label: ORDER_MAPPED_STATUS_DISPLAYED_VALUE[MAPPED_ORDER_STATUS.REJECTED],
    color: 'error',
    variant: 'outlined',
  },
  [MAPPED_ORDER_STATUS.CANCELED]: {
    label: ORDER_MAPPED_STATUS_DISPLAYED_VALUE[MAPPED_ORDER_STATUS.CANCELED],
    color: 'error',
    variant: 'outlined',
  },
};

export const OrderStatusChip = (props: Props) => {
  const { status, ...rest } = props;

  if (!chipProps[status]) return null;

  return <Chip {...chipProps[status]} {...rest} />;
};
