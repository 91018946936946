import { useGridApiContext } from '@mui/x-data-grid';
import { forwardRef, useImperativeHandle } from 'react';

export const DownloadToolbar = (props: any, ref: any) => {
  const apiRef = useGridApiContext();
  useImperativeHandle(ref, () => ({
    exportDataAsCsv: apiRef.current.exportDataAsCsv,
  }));

  return null;
};

export default forwardRef(DownloadToolbar);
