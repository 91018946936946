import { BankAccountInfoCard } from '@/components/Bank/BankAccountInfoCard';
import { DeactivateBankAccountDialog } from '@/components/Bank/DeactivateBankAccountDialog';
import { ComponentLoader } from '@/components/ComponentLoader';
import { useDeactivateMerchantBankAccount } from '@/hooks';
import { useGetCompanyBankAccounts } from '@/hooks/useGetCompanyBankAccounts';
import { getBankTypeLabel } from '@/pages/suppliers/SupplierDetails/Tabs/BankInfoTab/PaymentTypes';
import { getBankAccountFields } from '@/utils/bank';
import { Box, Stack, Typography } from '@mui/material';
import { find, isEmpty, map } from 'lodash';
import { useState } from 'react';
import { useToggle } from 'react-use';

export const BankAccountsTab = ({ companyId }: { companyId: number }) => {
  const {
    data,
    refetch: refetchCompanyBankAccounts,
    loading,
  } = useGetCompanyBankAccounts(companyId);

  const [deactivateMerchantBankAccount] = useDeactivateMerchantBankAccount();

  const bankAccounts = data?.orders_companies_by_pk.bank_accounts;
  const [selectedBankAccountId, setSelectedBankAccountId] = useState();
  const [isDeactivationDialogOpen, toggleDeactivationDialogOpen] =
    useToggle(false);

  const accountType = getBankTypeLabel(
    find(
      bankAccounts,
      (bankAccount) => bankAccount?.id === selectedBankAccountId
    )?.payment_type || ''
  );

  if (isEmpty(bankAccounts)) {
    return (
      <Stack gap={2}>
        <Typography variant={'h5'}>Bank accounts</Typography>
        <Typography variant={'body1'} color={'textSecondary'}>
          This company has no bank accounts.
        </Typography>
      </Stack>
    );
  }
  if (loading) {
    return <ComponentLoader />;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBlock: 2,
        }}>
        <Typography variant="h5">Bank accounts</Typography>
      </Box>
      <Stack gap={2}>
        {map(bankAccounts, (bankAccount) => (
          <BankAccountInfoCard
            key={bankAccount.id}
            bankAccountId={bankAccount.id}
            bankAccountCompliance={bankAccount.compliance}
            onBankAccountReviewSubmit={refetchCompanyBankAccounts}
            title={getBankTypeLabel(bankAccount.payment_type || '')}
            accountInfo={getBankAccountFields(bankAccount)}
            onDeactivateClick={() => {
              setSelectedBankAccountId(bankAccount.id);
              toggleDeactivationDialogOpen();
            }}
            isActive={!Boolean(bankAccount.deactivated_at)}
            awx_errors={bankAccount.awx_bene_val_errors}
          />
        ))}
      </Stack>
      {selectedBankAccountId && (
        <DeactivateBankAccountDialog
          isOpen={isDeactivationDialogOpen}
          accountType={accountType}
          onClose={toggleDeactivationDialogOpen}
          onDeactivateClick={() => {
            toggleDeactivationDialogOpen();
            deactivateMerchantBankAccount({
              variables: {
                companyId,
                bankAccountId: selectedBankAccountId,
              },
              onCompleted: () => {
                refetchCompanyBankAccounts();
              },
            });
          }}
        />
      )}
    </Box>
  );
};
