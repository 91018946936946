import { ROUTES } from '@/constants';
import { RouteObject } from 'react-router-dom';
import { InvoiceDetailsPage } from './InvoiceDetailsPage';
import { InvoicesPage } from './InvoicesPage';
export const INVOICES: RouteObject = {
  path: `${ROUTES.invoices}`,
  children: [
    {
      index: true,
      element: <InvoicesPage />,
    },
    {
      path: `${ROUTES.invoices}/:id`,
      element: <InvoiceDetailsPage />,
    },
  ],
};
