import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
import UploadIcon from '../../icons/UploadIcon';
import { Uploader, UploaderProps } from './Uploader';

interface UploadButtonProps extends Omit<UploaderProps, 'open'> {
  isError: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  single?: boolean;
  text: string;
  filename?: string;
  acceptsImages?: boolean;
}

export const UploadButton = (props: UploadButtonProps) => {
  const {
    isError,
    title,
    text,
    subtitle,
    isLoading,
    files,
    filename,
    single = false,
    fullWidth = true,
    acceptsImages = false,
  } = props;
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const isIdle = isEmpty(files) && !isError;

  const uploadButtonColor = () => {
    if (!isEmpty(files)) return 'success';
    if (isError) return 'error';
    return 'secondary';
  };

  const onCancel = () => {
    setUploadModalOpen(false);
  };
  return (
    <>
      <Button
        startIcon={!isEmpty(files) ? <CheckmarkIcon /> : <UploadIcon />}
        variant="outlined"
        size="large"
        component="label"
        color={uploadButtonColor()}
        fullWidth={fullWidth}
        onClick={() => setUploadModalOpen(true)}>
        {isIdle && text}
        {!isEmpty(files) && `${files?.length} ${' file uploaded'}`}
        {isError && 'Error uploading file'}
      </Button>
      <Uploader
        open={uploadModalOpen}
        files={files}
        uploadFile={props.uploadFile}
        onDelete={props?.onDelete}
        onCancel={onCancel}
        title={title}
        subtitle={subtitle}
        isLoading={isLoading}
        single={single}
        filename={filename}
        acceptsImages={acceptsImages}
      />
    </>
  );
};
