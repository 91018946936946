import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ChevronRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="ChevronRightIcon"
      {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.75 8.75L14.25 12L10.75 15.25"
      />
    </SvgIcon>
  );
}
