import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface MenuItemProps {
  isDrawerOpen: boolean;
  isSelected: boolean;
  title: string;
  icon: ReactNode;
  color?: string;
  opacity?: number;
  href?: string;
  onClick?: () => void;
}

export const MenuItem = (props: MenuItemProps) => {
  return (
    <Tooltip
      title={props.isDrawerOpen ? '' : props.title}
      placement="right"
      arrow>
      <ListItem
        disablePadding
        sx={{
          display: 'block',
          marginBlock: 0.25,
        }}
        tabIndex={-1}>
        <ListItemButton
          href={props.href || ''}
          LinkComponent={(props) => <Link to={props.href} {...props} />}
          sx={{
            minHeight: 48,
            flexDirection: 'column',
            p: 0,
            paddingInline: 1.5,
            borderRadius: 2,
          }}
          selected={props.isSelected}
          onClick={props.onClick}
          tabIndex={0}>
          <Box
            display="flex"
            justifyContent={props.isDrawerOpen ? 'initial' : 'center'}
            alignSelf="start"
            alignItems="center"
            paddingY={1.5}
            width="100%">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.isDrawerOpen ? 1.5 : 0,
                justifyContent: 'center',
              }}>
              {props.icon}
            </ListItemIcon>
            {props.isDrawerOpen && (
              <ListItemText
                sx={{
                  margin: 0,
                }}
                primaryTypographyProps={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                primary={
                  <Typography
                    variant="body1"
                    overflow="clip"
                    flexGrow={1}
                    color={props.color}
                    display={'inline'}>
                    {props.title}
                  </Typography>
                }
              />
            )}
          </Box>
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};
