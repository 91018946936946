import {
  ComputedInvoiceStatus,
  INVOICE_OPS_STATUS,
  InvoiceStatusMapper,
} from '@/constants';
import { useGetCreditAmountByInvoice } from '@/hooks/useGetCreditAmountByInvoice';
import { Invoice } from '@/types';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency, negativeToZero } from '@treyd-io/core/utils/number';
import { toNumber } from 'lodash';
import { useMemo } from 'react';

export const InvoiceAlert = ({
  invoice,
  invoicingCurrency,
}: {
  invoice: Invoice;
  invoicingCurrency: string;
}) => {
  const { data: creditsData } = useGetCreditAmountByInvoice(
    toNumber(invoice?.invoicing_system_reference)
  );
  const credits = negativeToZero(
    creditsData?.orders_vouchers_app_credit_aggregate?.aggregate?.sum?.amount
  );
  const isPartialInvoice =
    invoice?.remaining_amount !== 0 && invoice?.paid_amount !== 0;
  const isPaid =
    invoice.status === INVOICE_OPS_STATUS.CLOSED &&
    invoice?.invoice_type !== 'credit' &&
    !invoice?.credited;

  const formattedCredits = formatCurrency(credits, invoicingCurrency);

  const alertStatus = useMemo((): ComputedInvoiceStatus => {
    if (isPartialInvoice) {
      return ComputedInvoiceStatus.PARTIAL;
    }
    if (isPaid) {
      return ComputedInvoiceStatus.PAID;
    }
    return ComputedInvoiceStatus.NO_INVOICE;
  }, [isPartialInvoice, isPaid]);

  const AlertValues = () => {
    const closedDate = invoice?.closed_date
      ? formatDate(new Date(invoice?.closed_date), 'longdate')
      : '';
    const alertValues: InvoiceStatusMapper = {
      [ComputedInvoiceStatus.PARTIAL]: {
        title: 'This invoice was partially paid',
        subtitle: `Repayment has earned the merchant ${formattedCredits} as a credit. They should pay the remaining amount below before the due date to avoid overdue interest.`,
        severity: 'info',
      },
      [ComputedInvoiceStatus.PAID]: {
        title: `Repayment on ${closedDate}, has earned the merchant ${formattedCredits} as credits.`,
        severity: 'success',
      },
      [ComputedInvoiceStatus.NO_INVOICE]: null,
    };
    return alertValues[alertStatus];
  };

  if (!credits || alertStatus === ComputedInvoiceStatus.NO_INVOICE) return null;

  return (
    <Alert severity={AlertValues()?.severity || 'info'}>
      <AlertTitle>{AlertValues()?.title}</AlertTitle>
      <Stack gap={0.5}>
        <Typography variant="body2">{AlertValues()?.subtitle}</Typography>
      </Stack>
    </Alert>
  );
};
