import { getCurrencies } from '@/api';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const useGetCurrencies = () => {
  const [currencies, setCurrencies] = useState<string[]>([]);
  const getCurrenciesData = async () => {
    try {
      const { data } = await getCurrencies();
      setCurrencies(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffectOnce(() => {
    getCurrenciesData();
  });
  return currencies;
};
