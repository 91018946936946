import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ExportIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="ExportIcon"
      {...props}>
      <path
        d="M6.75 19.25H17.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.25V4.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.75 9.25L12 4.75L16.25 9.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
