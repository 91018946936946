import { TextField } from '@mui/material';
import { useField } from 'formik';

export const GeneralFormikInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      <TextField
        label={label}
        error={meta.touched && meta.error}
        helperText={meta.error}
        className="text-input"
        {...field}
        {...props}
        onChange={(e) => {
          const { value } = e.target;
          field.onChange({
            target: {
              value: value.trim(),
              name: field.name,
            },
          });
        }}
      />
    </>
  );
};
