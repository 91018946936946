enum WalletAccountStatus {
  ACTIVE = 'ACTIVE',
}

export enum WalletAccountAccountType {
  CASH = 'cash',
  CREDIT = 'credit',
}

export interface WalletAccount {
  id: number;
  created: string;
  modified: string;
  account_id: string;
  account_identifier: string;
  account_type: WalletAccountAccountType;
  status: WalletAccountStatus;
  entity: number;
}

export interface WalletEntity {
  id: 1;
  accounts: WalletAccount[];
  created: string;
  modified: string;
  entity_id: string;
  name: string;
  org_no: string;
  country: string;
}
