import { INVOICE_OPS_STATUS } from '@/constants';
import { Invoice } from '@/schemas';
import { CSVBuilder } from '@treyd-io/core/services/CsvService';
import { formatDate } from '@treyd-io/core/utils/date';
import { downloadCsvFile } from '@treyd-io/core/utils/downloadCSV';
import { isEmpty, map, startCase, toString } from 'lodash';
import { InvoiceService } from './Invoice';

export class InvoicesCsvService {
  private csvBuilder: CSVBuilder;

  constructor() {
    this.csvBuilder = new CSVBuilder();
  }

  private buildHeaders(): void {
    this.csvBuilder.setHeaders([
      ' ',
      'Invoice #',
      'Order #',
      'Status',
      'Merchant',
      'Invoice date',
      'Due date',
      'Closed date',
      'Days till due date',
      'Amount',
      'Paid amount',
      'Remaining amount',
      'Currency',
      'Financier',
      'System',
      'Type',
    ]);
  }

  private formatInvoiceRow(
    invoice: Invoice,
    index: number
  ): (string | number | Date)[] {
    const closedDate = InvoiceService.getInvoiceClosedDate(
      invoice.closed_date,
      invoice.closed_date_overwrite
    );
    return map(
      [
        index + 1,
        toString(invoice?.invoice_number),
        toString(invoice?.order_id),
        startCase(invoice?.ops_status || ''),
        invoice?.order?.importer?.name || '',
        invoice?.invoice_date
          ? formatDate(new Date(invoice?.invoice_date), 'date')
          : '',
        invoice?.due_date
          ? formatDate(new Date(invoice?.due_date), 'date')
          : '',
        closedDate ? formatDate(new Date(closedDate), 'date') : '',
        this.formatOverdueDays(
          invoice?.overdue_days,
          invoice?.ops_status as INVOICE_OPS_STATUS
        ),
        toString(invoice?.invoiced_amount),
        toString(invoice?.paid_amount),
        toString(invoice?.remaining_amount),
        toString(invoice?.invoiced_currency),
        toString(invoice?.financier?.name),
        InvoiceService.isOldInvoicingSystem(invoice?.invoicing_system)
          ? 'Old'
          : toString(invoice?.invoicing_system),
        startCase(toString(invoice?.invoice_type)),
      ],
      (value) => `"${value}"`
    );
  }

  private buildRows(invoices: Invoice[]): void {
    const formattedRows = map(invoices, (invoice, index) =>
      this.formatInvoiceRow(invoice, index)
    );
    this.csvBuilder.addRow(map(formattedRows, (row) => row.join(',')));
  }

  private formatOverdueDays = (days: number, status: INVOICE_OPS_STATUS) => {
    const isOverDue = InvoiceService.doesInvoicePassDueDate(days, status);
    const numberOfDays = Math.abs(days);
    const daysPostfix = numberOfDays === 1 ? 'day' : 'days';
    if (isOverDue) return `${numberOfDays} ${daysPostfix} overdue`;
    if (isOverDue === false) return `${numberOfDays} ${daysPostfix} remaining`;
    return '';
  };

  exportToCsv(invoices: Invoice[]) {
    if (isEmpty(invoices)) return false;

    this.buildHeaders();
    this.buildRows(invoices);

    const csvData = this.csvBuilder.build();
    downloadCsvFile({
      data: csvData,
      fileName: 'Invoices.csv',
    });
    return true;
  }
}
