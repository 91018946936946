import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useState } from 'react';

type AddNoteDialogProps = {
  open: boolean;
  toggle: () => void;
  onClick: (value: string) => void;
  title?: string;
  note: string;
};
export const AddNoteDialog = (props: AddNoteDialogProps) => {
  const { open, toggle, onClick, note, title } = props;
  const [newNote, setNewNote] = useState('');
  const [disabled, setDisabled] = useState(true);

  const onClickSave = () => {
    setDisabled(true);
    onClick(newNote);
  };

  return (
    <Dialog fullWidth open={open} onClose={toggle}>
      <DialogTitle>{title ? title : 'Edit order review note'}</DialogTitle>
      <DialogContent>
        <TextField
          inputMode="text"
          onChange={(e) => {
            if (e.target.value !== note) {
              setDisabled(false);
            }
            setNewNote(e.target.value);
          }}
          label="Add a note"
          defaultValue={note}
          multiline
          rows={4}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
        <Button disabled={disabled} onClick={onClickSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
