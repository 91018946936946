import { Header } from '@/components';
import { FormButton } from '@/components/styled-components/FormStyles';
import { useGetFeatureFlagById, useUpdateFeatureFlag } from '@/hooks/';
import { DetailsForm, GeneralFormikCheckBox } from '@/pages/dev/components';
import { Box, Button, Typography } from '@mui/material';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';
import { Formik } from 'formik';
import { startCase } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

export const FeatureFlagsDetails = () => {
  const { feature_flag_id } = useParams();
  const navigate = useNavigate();

  const { data } = useGetFeatureFlagById(Number(feature_flag_id));
  const [updateFeatureFlag] = useUpdateFeatureFlag();
  const featureFlag = data?.orders_orders_app_companyfeatureflag_by_pk;

  const initialValues = {
    enabled: featureFlag?.enabled || false,
  };

  const updateFeatureFlagHandler = async (id: number, enabled: boolean) => {
    await updateFeatureFlag({
      variables: {
        id,
        data: {
          enabled,
        },
      },
    });
  };

  const goBack = () => {
    navigate('/dev#featureFlags');
  };

  return (
    <>
      <Header>
        <Button onClick={goBack} startIcon={<ArrowLeftIcon />}>
          Go back
        </Button>
      </Header>
      <Typography variant="h4">
        {startCase(data?.orders_orders_app_companyfeatureflag_by_pk.name)}
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          updateFeatureFlagHandler(Number(feature_flag_id), values.enabled);
          setSubmitting(false);
          goBack();
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DetailsForm>
            <GeneralFormikCheckBox name="enabled">
              Enabled
            </GeneralFormikCheckBox>
            <FormButton variant="outlined" type="submit">
              Save Changes
            </FormButton>
          </DetailsForm>
        </Box>
      </Formik>
    </>
  );
};
