import {
  addSupplierAttachmentMutation,
  getSupplierAttachmentsQuery,
} from '@/schemas';
import { useMutation } from '@apollo/client';

export const useAddSupplierAttachment = (supplierId: number) =>
  useMutation(addSupplierAttachmentMutation, {
    refetchQueries: [
      {
        query: getSupplierAttachmentsQuery,
        variables: { supplierId: supplierId },
      },
    ],
  });
