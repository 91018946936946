import { CreditableType, MERCHANT_SEGMENT } from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Companies,
  Orders_Voucher_Credits_Invoices,
} from '__generated__/graphql';

export type Credit = Pick<
  Orders_Voucher_Credits_Invoices,
  | 'id'
  | 'created'
  | 'amount'
  | 'reason'
  | 'invoice_number'
  | 'order_invoice_id'
  | 'slug'
> & {
  company: Pick<Orders_Companies, 'invoicing_currency' | 'name' | 'id'> & {
    segment: MERCHANT_SEGMENT;
  };
  creditable_type?: CreditableType;
};

type GetCreditsQueryTypes = {
  orders_voucher_credits_invoices: Credit[];
  orders_voucher_credits_invoices_aggregate: { aggregate: { count: number } };
};

export const getCreditsQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getCredits{
    orders_voucher_credits_invoices ${queryParams} {
      id
      created
      creditable_type
      invoice_number
      order_invoice_id
      slug
      amount
      reason
      company {
        id
        name
        invoicing_currency
				segment
      }
	}
	orders_voucher_credits_invoices_aggregate ${
    whereClause ? `(where: ${whereClause})` : ''
  } {
		aggregate {
			count
		}
	}
	
  }
  ` as TypedDocumentNode<GetCreditsQueryTypes>;
};
