import { getDealDuplicates } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetOrderDuplicates = (dealId: number) =>
  useQuery({
    queryKey: ['dealDuplicates', dealId],
    queryFn: () => getDealDuplicates(dealId),
    retry: 1,
    enabled: !!dealId,
  });
