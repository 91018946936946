import { Stack } from '@mui/material';
import { CreditsSection } from './CreditsSection';
import { VouchersSection } from './VouchersSection';

interface CreditsAndVouchersProps {
  companyId: number;
  invoicingCurrency: string;
}

export const CreditsAndVouchers = (props: CreditsAndVouchersProps) => {
  const { companyId, invoicingCurrency } = props;
  return (
    <Stack gap={7}>
      <CreditsSection
        companyId={companyId}
        invoicingCurrency={invoicingCurrency}
      />
      <VouchersSection companyId={companyId} />
    </Stack>
  );
};
