import {
  Orders_Companies_Fees,
  Orders_Companies_Final_Pricing_Log,
} from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';

export type Fee = Pick<
  Orders_Companies_Final_Pricing_Log,
  'id' | 'appliedDiscounts' | 'archived_at' | 'final_pricing' | 'created_at'
> & {
  companies_fee: {
    max_tenor_in_months: Pick<Orders_Companies_Fees, 'max_tenor_in_months'>;
  };
};

export type GetFeesQueryType = {
  orders_companies_final_pricing_log: Fee[];
  orders_companies_fees_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export const getFeesQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getFees {
    orders_companies_final_pricing_log ${queryParams} {
      final_pricing
      id
      created_at
      archived_at
			appliedDiscounts
      companies_fee {
      max_tenor_in_months
      }
    }
    orders_companies_fees_aggregate ${
      whereClause && `(where: ${whereClause})`
    } {
      aggregate {
        count
      }
    }
  }
` as TypedDocumentNode<GetFeesQueryType>;
};
