import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ClockIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="ClockIcon"
      {...props}>
      <circle
        cx="12"
        cy="12"
        r="7.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path stroke="currentColor" strokeWidth="1.5" d="M12 8V12L14 14" />
    </SvgIcon>
  );
}
