import { ROUTES } from '@/constants';
import { RouteObject } from 'react-router-dom';
import { CreditsAndVouchers } from './CreditsAndVouchersPage';

export const CREDITS_AND_VOUCHERS: RouteObject = {
  path: `${ROUTES.creditsAndVouchers}`,
  children: [
    {
      index: true,
      element: <CreditsAndVouchers />,
    },
  ],
};
