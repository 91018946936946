import { getActiveCompanyAgreementConsentVersionNumberQuery } from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetActiveCompanyAgreementConsentVersionNumber = (
  companyId: number
) =>
  useQuery(getActiveCompanyAgreementConsentVersionNumberQuery, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });
