import { gql, TypedDocumentNode } from '@apollo/client';

export type GetCompanyAttachmentsQueryVariables = {
  orgnr: string;
  country: string;
};

export type GetCompanyAttachmentsQueryType = {
  django: {
    core_company: {
      attachments: {
        id: string;
        name: string;
        url: string;
        tag: string;
        description: string;
        expiration_date: string;
      }[];
    };
  };
};

export const getCompanyAttachmentsQuery = gql`
  query getCompanyAttachments($orgnr: String!, $country: String!) {
    django {
      core_company(orgnr: $orgnr, country: $country) {
        attachments {
          id
          name
          url
          tag
          description
          expiration_date
        }
      }
    }
  }
` as TypedDocumentNode<
  GetCompanyAttachmentsQueryType,
  GetCompanyAttachmentsQueryVariables
>;
