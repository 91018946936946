import { Button, Stack, TextField, Typography } from '@mui/material';
import MessageIcon from '@treyd-io/treyd-ui/icons/MessageIcon';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

interface CommentFormProps {
  onSubmit: (values: { comment: string }) => void;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
}

const VALidaTION_SCHEMA = Yup.object().shape({
  comment: Yup.string().required('Required'),
});

const CommentForm = ({ onSubmit, onCancel }: CommentFormProps) => {
  return (
    <>
      <Formik
        initialValues={{
          comment: '',
        }}
        validationSchema={VALidaTION_SCHEMA}
        validateOnMount
        onSubmit={onSubmit}>
        {(formik) => {
          return (
            <Form>
              <Stack
                gap={1.5}
                border={1}
                borderColor="other.outlined_border"
                borderRadius={2}
                padding={2}>
                <Stack direction="row" gap={0.5} alignItems="center">
                  <MessageIcon sx={{ color: 'grey.700' }} />
                  <Typography variant="subtitle2">New comment</Typography>
                </Stack>
                <TextField
                  multiline
                  rows={2}
                  placeholder="Type a comment..."
                  fullWidth
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    variant="contained"
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="submit">
                    Post
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onCancel}>
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CommentForm;
