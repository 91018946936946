import {
  Orders_Companies,
  Orders_Notification_Center_Notification,
} from '@/__generated__/graphql';
import { MERCHANT_SEGMENT } from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';

export type NotificationType = Pick<
  Orders_Notification_Center_Notification,
  'id' | 'dismissed_at' | 'received_at' | 'notification_group_id'
>;

export type NotificationGroupMerchant = Pick<
  Orders_Companies,
  'id' | 'name' | 'country'
> & {
  notifications: NotificationType[];
  segment: MERCHANT_SEGMENT;
};

type GetNotificationGroupMerchantsQueryType = {
  orders_companies: NotificationGroupMerchant[];
  orders_companies_aggregate: { aggregate: { count: number } };
};

export const getNotificationGroupMerchantsQuery = (
  notificationGroupId: number,
  queryOptions?: QueryOptions
) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`

query getNotificationGroupMerchants {
    orders_companies ${queryParams} {
      id
      name
      country
			segment
      notifications(
        where: { notification_group_id: { _eq: ${notificationGroupId} } }
      ) {
        id
        dismissed_at
        received_at
        notification_group_id
      }
    }
    orders_companies_aggregate ${whereClause ? `(where: ${whereClause})` : ''} {
		aggregate {
			count
		}
	}
  }
  ` as TypedDocumentNode<GetNotificationGroupMerchantsQueryType>;
};
