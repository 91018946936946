import { PREVIEW_AML_PDF } from '@/constants';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { save } from '@treyd-io/core/utils/request';

export const getAMLReport = (org_no: string, country: string) =>
  save(
    PREVIEW_AML_PDF,
    {
      orgnr: org_no,
      country,
    },
    REQUEST_METHOD.POST,
    { responseType: 'blob', noToast: true }
  );
