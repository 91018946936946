import { IconButtonProps, useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';
import CloseIcon from '../icons/CloseIcon';
import ResponsiveButton from './ResponsiveButton';

const DialogCloseButton = (props: IconButtonProps) => {
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ResponsiveButton
      aria-label="close"
      iconButtonProps={{
        children: <CloseIcon />,
        ...props,
      }}
      variant={isSmallScreens ? 'outlined' : 'text'}
      color="secondary"
    />
  );
};
export default DialogCloseButton;
