import { MerchantKyc } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';

type QueryVariables = {
  companyId: number;
};

export type GetMerchantKycQueryType = {
  orders_companies_by_pk: {
    kyc: MerchantKyc;
  };
};

export const getMerchantKycQuery = gql`
  query getMerchantKycQuery($companyId: bigint!) {
    orders_companies_by_pk(id: $companyId) {
      kyc
    }
  }
` as TypedDocumentNode<GetMerchantKycQueryType, QueryVariables>;
