import { LoggingEntry } from '@/types';
import { useAuth } from 'auth';
import { LoggingContext } from 'context';
import { useContext } from 'react';

export const useLogglyLoggingService = () => {
  const { user } = useAuth();
  const trackingLogs: LoggingEntry[] = useContext(LoggingContext);
  const log = (entry: Omit<LoggingEntry, 'userId'>) => {
    trackingLogs.push({ userId: user.id, type: 'INFO', ...entry });
  };
  return log;
};
