import {
  Alert,
  AlertColor,
  AlertTitle,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import React from 'react';
import AlertIcon from '../icons/AlertIcon';
import CheckCircleIcon from '../icons/CheckCircleIcon';
import InformationIcon from '../icons/InformationIcon';
import WarningTriangleIcon from '../icons/WarningTriangleIcon';

export interface ToastProps {
  isOpen: boolean;
  type: AlertColor;
  title?: string;
  message?: string;
  duration: number;
  onClose: () => void;
  position?: 'absolute';
  placement?: SnackbarOrigin;
}

const ICONS = {
  success: <CheckCircleIcon />,
  info: <InformationIcon />,
  warning: <WarningTriangleIcon />,
  error: <AlertIcon />,
};

export const Toast = (props: ToastProps) => (
  <Snackbar
    open={props.isOpen}
    autoHideDuration={props.duration}
    onClose={props.onClose}
    anchorOrigin={
      props.placement || { vertical: 'bottom', horizontal: 'right' }
    }
    sx={{
      position: props.position || 'fixed',
    }}>
    <Alert
      severity={props.type}
      variant="filled"
      iconMapping={ICONS}
      sx={{
        width: 320,
      }}>
      {props.title && <AlertTitle>{props.title}</AlertTitle>}
      {props.message}
    </Alert>
  </Snackbar>
);
