import { SupplierCompliance } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';

type GetSupplierAttachmentsQueryType = {
  django: {
    supplier_attachments: SupplierCompliance.Attachment[];
  };
};

type GetSupplierAttachmentsQueryVariablesType = {
  supplierId: number;
};

export const getSupplierAttachmentsQuery = gql`
  query getSupplierAttachmentsQuery($supplierId: Int!) {
    django {
      supplier_attachments(supplier_id: $supplierId) {
        url
        name
        tag
        id
        description
        created
      }
    }
  }
` as TypedDocumentNode<
  GetSupplierAttachmentsQueryType,
  GetSupplierAttachmentsQueryVariablesType
>;
