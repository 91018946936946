import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function FileErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="FileErrorIcon"
      {...props}>
      <path
        fill="currentColor"
        d="M17.25 9.25V10a.75.75 0 0 0 .53-1.28l-.53.53Zm-4.5-4.5.53-.53a.75.75 0 0 0-.53-.22v.75ZM9.25 20a.75.75 0 0 0 0-1.5V20Zm8.53-11.28-4.5-4.5-1.06 1.06 4.5 4.5 1.06-1.06ZM12.75 4h-6v1.5h6V4ZM4 6.75v10.5h1.5V6.75H4ZM6.75 20h2.5v-1.5h-2.5V20ZM12 4.75v3.5h1.5v-3.5H12ZM13.75 10h3.5V8.5h-3.5V10ZM12 8.25c0 .966.784 1.75 1.75 1.75V8.5a.25.25 0 0 1-.25-.25H12Zm-8 9A2.75 2.75 0 0 0 6.75 20v-1.5c-.69 0-1.25-.56-1.25-1.25H4ZM6.75 4A2.75 2.75 0 0 0 4 6.75h1.5c0-.69.56-1.25 1.25-1.25V4Zm8.75 10.5a2 2 0 0 1 2 2H19a3.5 3.5 0 0 0-3.5-3.5v1.5Zm0 4a2 2 0 0 1-2-2H12a3.5 3.5 0 0 0 3.5 3.5v-1.5Zm0-5.5a3.49 3.49 0 0 0-2.402.955l1.03 1.09A1.99 1.99 0 0 1 15.5 14.5V13Zm-2.402.955A3.492 3.492 0 0 0 12 16.5h1.5c0-.573.24-1.089.627-1.455l-1.03-1.09ZM15.5 20a3.492 3.492 0 0 0 2.545-1.098l-1.09-1.03a1.992 1.992 0 0 1-1.455.628V20Zm2.545-1.098A3.49 3.49 0 0 0 19 16.5h-1.5a1.99 1.99 0 0 1-.545 1.373l1.09 1.03Zm-4.963-3.872 3.888 3.888 1.06-1.06-3.887-3.888-1.06 1.06Z"
      />
    </SvgIcon>
  );
}
