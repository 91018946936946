import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ClickPulseIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="ClickPulseIcon"
      {...props}>
      <path
        fill="currentColor"
        d="m11.75 10.75.372-.652a.75.75 0 0 0-1.1.834l.728-.182Zm2.125 8.5-.728.182a.75.75 0 0 0 1.398.156l-.67-.338Zm1.625-3.222-.193-.725a.75.75 0 0 0-.477.387l.67.338Zm3.75-1 .193.724a.75.75 0 0 0 .179-1.376l-.372.652ZM6.895 6.895l.53.53-.53-.53Zm9.825.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM6.364 17.78a.75.75 0 0 0 1.061-1.06l-1.06 1.06Zm2.673-8.743.53.53-.53-.53Zm5.54.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-6.07 6.07a.75.75 0 0 0 1.06-1.06l-1.06 1.06Zm2.515-4.705 2.125 8.5 1.456-.364-2.125-8.5-1.456.364Zm3.523 8.656 1.625-3.223-1.34-.675-1.625 3.222 1.34.676Zm1.148-2.835 3.75-1-.386-1.45-3.75 1 .386 1.45Zm3.929-2.377-7.5-4.277-.744 1.303 7.5 4.277.744-1.303ZM7.425 7.425a6.572 6.572 0 0 1 9.295 0l1.06-1.06a8.072 8.072 0 0 0-11.416 0l1.061 1.06Zm0 9.295a6.572 6.572 0 0 1 0-9.295l-1.06-1.06a8.072 8.072 0 0 0 0 11.415l1.06-1.06Zm2.142-7.153a3.542 3.542 0 0 1 5.01 0l1.06-1.06a5.042 5.042 0 0 0-7.13 0l1.06 1.06Zm0 5.01a3.542 3.542 0 0 1 0-5.01l-1.06-1.06a5.042 5.042 0 0 0 0 7.13l1.06-1.06Z"
      />
    </SvgIcon>
  );
}
