import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { CREDITS_REASONS, ROUTES } from '@/constants';
import { useGetCompanyCredits, useGetCompanyCreditsAmount } from '@/hooks';
import { CompanyCredits } from '@/types';
import { Box, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency, negativeToZero } from '@treyd-io/core/utils/number';
import { toNumber } from 'lodash';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

interface CreditsSectionProps {
  invoicingCurrency: string;
  companyId: number;
}

export const CreditsSection = (props: CreditsSectionProps) => {
  const { invoicingCurrency, companyId } = props;

  const [page, setPage] = useState<number>(0);

  const { data, isFetching: companyCreditsLoading } = useGetCompanyCredits({
    companyId,
    page: page + 1,
    limit: 10,
  });
  const { data: creditsAmountData, isFetching: companyCreditsAmountLoading } =
    useGetCompanyCreditsAmount(companyId);
  const totalCredits = creditsAmountData?.available_credits || 0;

  const rowCount = data?.count || 0;
  const companyCredits = data?.results || [];

  const getCreditReason = (credit: CompanyCredits) =>
    ({
      [CREDITS_REASONS.EARLY_PAYMENT_CREDIT]: (
        <Typography variant="body2">
          Early repayment on invoice
          <Link to={`${ROUTES.invoices}/${credit?.invoice?.id}`}>
            #{credit?.invoice?.number}
          </Link>
        </Typography>
      ),
      [CREDITS_REASONS.REFUND]: (
        <Typography variant="body2">
          Refund on invoice
          <Link to={`${ROUTES.invoices}/${credit?.invoice?.id}`}>
            #{credit?.invoice?.number}
          </Link>
        </Typography>
      ),
      [CREDITS_REASONS.EARLY_PAYMENT_CREDIT_CANCELLED]:
        'Early repayment cancelled on invoice',
      [CREDITS_REASONS.CREDIT_VOUCHER]: `Voucher #${credit?.voucher_slug} applied`,
      [CREDITS_REASONS.CREDIT_VOUCHER_CANCELLED]: `Voucher #${credit?.voucher_slug} cancelled`,
      [CREDITS_REASONS.FAILED_PAYMENT]: (
        <Typography variant="body2">
          Failed payment on order
          <Link to={`${ROUTES.orders}/${credit?.creditable_id}`}>
            #{credit?.creditable_id}
          </Link>
        </Typography>
      ),
      [CREDITS_REASONS.BONUS]: 'Complimentary credit',
    }[credit?.reason]);

  const columns = useMemo(
    (): GridColDef<CompanyCredits>[] => [
      {
        field: 'created',
        headerName: 'Date',
        flex: 1,
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
      {
        field: 'reason',
        headerName: 'Reason',
        renderCell: (params) => getCreditReason(params?.row) || '-',
        flex: 2,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        headerAlign: 'right',
        align: 'right',
        renderCell: ({ value }) =>
          formatCurrency(toNumber(value), invoicingCurrency),
        flex: 2,
      },
    ],
    [invoicingCurrency]
  );

  const noCreditsProps = !rowCount && {
    columnHeaders: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  const isLoading = companyCreditsLoading || companyCreditsAmountLoading;

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={1}>
          <Typography variant="h6">Credits</Typography>
          <Typography variant="body2" color="textSecondary">
            Earned credits will be applied automatically as fees discounts on
            upcoming orders.
          </Typography>
        </Stack>
        <Stack direction="row" gap={1} alignSelf={'end'}>
          <Typography variant="body2">Total credits</Typography>
          <Typography variant="subtitle2">
            {formatCurrency(
              negativeToZero(toNumber(totalCredits)),
              invoicingCurrency
            )}
          </Typography>
        </Stack>
      </Stack>
      <Box height={400} width="100%">
        <DataGrid
          rows={companyCredits || []}
          paginationModel={{
            page,
            pageSize: 10,
          }}
          columns={columns}
          paginationMode="server"
          loading={isLoading}
          rowCount={rowCount}
          onPaginationModelChange={(model) => {
            setPage(model.page);
          }}
          sx={{
            [`.${gridClasses.panelWrapper}`]: {
              position: 'static',
            },
          }}
          slots={{
            noRowsOverlay: () => (
              <TableHasNoRowsPlaceholder
                title={'Nothing here yet'}
                subtitle={'Credits earned will be displayed here.'}
              />
            ),
            ...noCreditsProps,
          }}
          hideFooter={rowCount === 0}
        />
      </Box>
    </Stack>
  );
};
