import { Form } from '@/components/styled-components/FormStyles';
import { useUpdateUserProfile } from '@/hooks/useUpdateUserProfile';
import { UserAuthProfile } from '@/schemas';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { GeneralFormikCheckBox } from './GeneralFormikCheckBox';

interface Values {
  identity_verified?: boolean | null;
}
export const EditUserDetails = ({ details }: { details: UserAuthProfile }) => {
  const [updateUserProfile, { loading }] = useUpdateUserProfile();

  const initialValues: Values = {
    identity_verified: details.identity_verified,
  };

  const VALIDATION_SCHEMA = yup.object().shape({
    identity_verified: yup.boolean().required('Required'),
  });

  const handleSubmit = (values: Values) => {
    updateUserProfile({
      variables: {
        profileId: details.id,
        userData: {
          ...values,
        },
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <Form sx={{ maxWidth: 'max-content' }}>
          <Typography variant="h5">{details.name}</Typography>
          <GeneralFormikCheckBox name="identity_verified">
            Identity verified
          </GeneralFormikCheckBox>

          <LoadingButton
            variant="outlined"
            onClick={() => handleSubmit()}
            loading={loading}>
            Save changes
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
};
