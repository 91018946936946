import {
  deleteSupplierAttachmentMutation,
  getSupplierAttachmentsQuery,
} from '@/schemas';
import { useMutation } from '@apollo/client';

export const useDeleteSupplierAttachment = (supplierId?: number) =>
  useMutation(deleteSupplierAttachmentMutation, {
    refetchQueries: [
      {
        query: getSupplierAttachmentsQuery,
        variables: { supplierId: supplierId },
      },
    ],
  });
