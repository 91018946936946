import { Orders_Companies } from '@/__generated__/graphql';
import { MERCHANT_SEGMENT } from '@/constants';

import { gql, TypedDocumentNode } from '@apollo/client';

interface getMerchantDetailsByOrderIdQueryTypes {
  orders_companies: Pick<
    Orders_Companies,
    'name' | 'id' | 'invoicing_currency'
  >[] &
    {
      segment: MERCHANT_SEGMENT;
    }[];
}

export const getMerchantDetailsByOrderId = gql`
  query getMerchantDetailsByOrderId($orderId: bigint) {
    orders_companies(where: { orders: { id: { _eq: $orderId } } }) {
      name
      id
      invoicing_currency
      segment
    }
  }
` as TypedDocumentNode<
  getMerchantDetailsByOrderIdQueryTypes,
  { orderId: number }
>;
