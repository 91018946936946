import { Invoice } from '@/types';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { isNumber } from '@treyd-io/core/utils/isNumber';
import { formatCurrency, formatPercent } from '@treyd-io/core/utils/number';
import { map } from 'lodash';

export const InvoiceRepaymentBreakdown = ({
  invoice,
}: {
  invoice: Invoice;
}) => {
  const invoicedCurrency = invoice?.invoiced_currency;
  const lineItems = invoice?.items;

  return (
    <Stack direction="column" gap={3} marginTop={7}>
      <Typography variant="h6">Breakdown</Typography>
      <TableContainer
        sx={{
          border: 1,
          borderRadius: 2,
          borderColor: 'divider',
        }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Item
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="text.secondary">
                  VAT
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="text.secondary">
                  Discount
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="text.secondary">
                  Total
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(
              lineItems,
              (row, index) =>
                isNumber(row.total_excl_vat_no_discount) && (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="right">
                      {isNumber(row.vat) && formatPercent(row.vat)}
                    </TableCell>
                    <TableCell align="right">
                      {invoicedCurrency &&
                        parseFloat(row.discount) !== 0 &&
                        formatCurrency(
                          parseFloat(row.discount),
                          invoicedCurrency
                        )}
                    </TableCell>
                    <TableCell align="right">
                      {row.total_inc_vat &&
                        invoicedCurrency &&
                        formatCurrency(
                          parseFloat(row.total_inc_vat) || 0,
                          invoicedCurrency
                        )}
                    </TableCell>
                  </TableRow>
                )
            )}
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': {
                  fontWeight: 550,
                  fontSize: '0.88rem',
                  borderBottom: 0,
                },
              }}>
              <TableCell component="th" scope="row">
                Repayment invoice total
              </TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right">
                {invoicedCurrency &&
                  formatCurrency(invoice?.invoiced_amount, invoicedCurrency)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
