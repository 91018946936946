import { getOrderLoanAgreements } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetOrderLoanAgreements = (orderId: number) =>
  useQuery({
    queryKey: [`getOrderAgreements`, orderId],
    queryFn: () => getOrderLoanAgreements(orderId),
    refetchOnWindowFocus: false,
    select: (data) => data?.data,
  });
