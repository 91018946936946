import { TreydUser } from '@/schemas';
import { reduce } from 'lodash';
import { UserService } from './UserService';

export namespace TreydUsersService {
  interface TreydUserOptions {
    id: number;
    email?: string | null;
    displayedName: string;
  }
  export const getUsersOptions = (users?: TreydUser[]) =>
    reduce(
      users,
      (previous: TreydUserOptions[], user) => {
        const displayedName = UserService.getDisplayedName(user);
        if (displayedName)
          previous.push({
            id: user.id,
            email: user?.email,
            displayedName,
          });
        return previous;
      },
      []
    );
}
