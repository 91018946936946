import {
  useGetSupplierAttachments,
  useGetSupplierKyc,
  useSubmitSupplierKyc,
  useUpdateSupplierKyc,
} from '@/hooks';
import { addSupplierAttachmentMutation } from '@/schemas';
import { SupplierCompliance } from '@/types';
import { useMutation } from '@apollo/client';
import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import AlertIcon from '@treyd-io/treyd-ui/icons/AlertIcon';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import LockUnlockedIcon from '@treyd-io/treyd-ui/icons/LockUnlockedIcon';
import { filter, isEmpty, toString, values } from 'lodash';
import { useMemo } from 'react';
import { useToggle } from 'react-use';
import { ComplianceAttachmentsSection } from './ComplianceAttachmentsSection';
import { ComplianceDetailsDialog } from './ComplianceDetailsDialog';
import { ComplianceDetailsSection } from './ComplianceDetailsSection';

interface ComplianceProps {
  supplierId: number;
  supplierLegalName?: string;
}

export const Compliance = (props: ComplianceProps) => {
  const { supplierId, supplierLegalName } = props;
  const [isDetailsDialogOpen, toggleDetailsDialog] = useToggle(false);
  const [updateSupplierKyc] = useUpdateSupplierKyc();
  const { mutate: submitSupplierKyc } = useSubmitSupplierKyc();
  const {
    data: supplierAttachmentsData,
    refetch: refetchSupplierAttachmentsData,
  } = useGetSupplierAttachments(supplierId);
  const { data, refetch: refetchSupplierKyc } = useGetSupplierKyc(supplierId);
  const [uploadAttachment, { loading: isUploading }] = useMutation(
    addSupplierAttachmentMutation
  );

  const supplierKyc = data?.orders_suppliers_by_pk?.kyc;
  const isDraft = supplierKyc?.status === 'Draft';

  const handleKycDetailsSubmit = (
    formValues: SupplierCompliance.DetailsFormValues
  ) => {
    updateSupplierKyc({
      variables: {
        id: supplierId,
        supplier_data: {
          kyc: {
            data: {
              ...formValues,
              isChecked: values(formValues).some(Boolean),
            },
            status: 'Draft',
          },
        },
      },
      onCompleted: async () => {
        await refetchSupplierKyc({
          supplierId,
        });
        toggleDetailsDialog();
      },
    });
  };

  const handleKycRevoke = () => {
    const revokedKyc: SupplierCompliance.SupplierKyc = {
      data: {
        ...supplierKyc?.data,
      },
      status: 'Draft',
    };
    updateSupplierKyc({
      variables: {
        id: supplierId,
        supplier_data: {
          kyc: revokedKyc,
        },
      },
      onCompleted: async () => {
        await refetchSupplierKyc({
          supplierId,
        });
      },
    });
  };

  const handleKycReview = (
    screeningResult: SupplierCompliance.SUPPLIER_KYC_RESULT
  ) => {
    submitSupplierKyc(
      {
        supplierId,
        payload: {
          screeningResult,
        },
      },
      {
        onSuccess: async () => {
          await refetchSupplierKyc({
            supplierId,
          });
        },
      }
    );
  };

  const complianceDetails = {
    url: toString(supplierKyc?.data?.url),
    riskLevel: toString(supplierKyc?.data?.riskLevel),
    comments: toString(supplierKyc?.data?.comments),
  };

  const supplierAttachments = useMemo(
    () =>
      filter(
        supplierAttachmentsData?.django.supplier_attachments,
        (attachment) => {
          return attachment?.tag === 'supplier_screening';
        }
      ),
    [supplierAttachmentsData?.django.supplier_attachments]
  );

  return (
    <Stack gap={3}>
      <Stack gap={2}>
        <Row alignItems="center">
          <Col xs={12} sm>
            <Typography variant="h5">Compliance decision</Typography>
          </Col>
          <Col xs={12} sm>
            <Stack
              direction="row"
              gap={2}
              justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}>
              {isDraft || isEmpty(supplierKyc) ? (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleKycReview('Approved')}>
                    Approve
                  </Button>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleKycReview('Disapproved')}>
                    Reject
                  </Button>
                </>
              ) : (
                <Button
                  color="error"
                  variant="outlined"
                  startIcon={<LockUnlockedIcon />}
                  onClick={handleKycRevoke}>
                  Revoke
                </Button>
              )}
            </Stack>
          </Col>
        </Row>
        <ComplianceDecisionAlert supplierKyc={supplierKyc} />
      </Stack>
      <Stack
        paddingY={4}
        paddingX={3}
        gap={4}
        border={1}
        borderColor="other.outlined_border"
        borderRadius={4}>
        <ComplianceDetailsSection
          onEditClick={toggleDetailsDialog}
          supplierKyc={supplierKyc}
          supplierLegalName={supplierLegalName}
        />
        <ComplianceDetailsDialog
          isOpen={isDetailsDialogOpen}
          onClose={toggleDetailsDialog}
          onSubmit={handleKycDetailsSubmit}
          initialValues={complianceDetails}
        />
        <Divider />
        <ComplianceAttachmentsSection
          supplierKyc={supplierKyc}
          onUploadClick={(file) => {
            const reader = new FileReader();
            reader.onload = () => {
              uploadAttachment({
                variables: {
                  dataUrl: reader?.result,
                  filename: file?.name,
                  supplierId,
                  description: 'attachment',
                  tag: 'supplier_screening',
                },
              }).then(() => refetchSupplierAttachmentsData());
            };
            reader.readAsDataURL?.(file);
          }}
          attachments={supplierAttachments}
          onRemoveAttachmentComplete={() => refetchSupplierAttachmentsData()}
          isUploading={isUploading}
        />
      </Stack>
    </Stack>
  );
};

const ComplianceDecisionAlert = ({
  supplierKyc,
}: {
  supplierKyc?: SupplierCompliance.SupplierKyc;
}) => {
  const screeningResult = supplierKyc?.screeningResult;
  const screeningResultDate = supplierKyc?.screeningResultDate;

  if (!screeningResult || !screeningResultDate) return null;

  return (
    <Alert
      color={screeningResult === 'Approved' ? 'success' : 'error'}
      icon={
        screeningResult === 'Approved' ? <CheckCircleIcon /> : <AlertIcon />
      }>
      <AlertTitle>
        {screeningResult === 'Disapproved' ? 'Rejected' : screeningResult} at:{' '}
        {formatDate(new Date(screeningResultDate), 'datetime')}
      </AlertTitle>
    </Alert>
  );
};
