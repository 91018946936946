import { BeneficiaryRequirementsType } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';

type BeneficiaryRequirementsQueryType = {
  banking: {
    awx_beneficiary_requirements: Array<BeneficiaryRequirementsType>;
  };
};

type BeneficiaryRequirementsQueryVariables = {
  currency?: string;
  beneficiaryCountry: string;
  bankAccountCountry: string;
};

export const getBeneficiaryRequirementsQuery = gql`
  query getBeneficiaryRequirements(
    $currency: String
    $beneficiaryCountry: String!
    $bankAccountCountry: String!
  ) {
    banking {
      awx_beneficiary_requirements(
        currency: $currency
        beneficiary_country: $beneficiaryCountry
        bank_account_country: $bankAccountCountry
      )
    }
  }
` as TypedDocumentNode<
  BeneficiaryRequirementsQueryType,
  BeneficiaryRequirementsQueryVariables
>;
