import { gql, TypedDocumentNode } from '@apollo/client';
import { Orders_Companies } from '__generated__/graphql';

export type GetMerchantCommercialStatusesQueryType = {
  orders_companies_by_pk: Pick<Orders_Companies, 'commercial_statuses'>;
};

export const getMerchantCommercialStatusesQuery = gql`
  query getMerchantCommercialStatuses($companyId: bigint!) {
    orders_companies_by_pk(id: $companyId) {
      commercial_statuses {
        commercial_status
      }
    }
  }
` as TypedDocumentNode<
  GetMerchantCommercialStatusesQueryType,
  { companyId: number }
>;
