import { AbilityContext } from '@/context';
import { useAppNavBarMenuItems } from '@/hooks';
import { User } from '@/types';
import {
  AppBar,
  Button,
  Divider,
  Menu,
  Stack,
  Typography,
} from '@mui/material';
import smallLogo from '@treyd-io/treyd-ui/assets/img/logo.svg';
import ChevronDownIcon from '@treyd-io/treyd-ui/icons/ChevronDownIcon';
import ChevronUpIcon from '@treyd-io/treyd-ui/icons/ChevronUpIcon';
import { filter, map } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MenuItem } from './MenuItem';

interface MobileNavBarProps {
  user?: User;
  selectedRouteSegment?: string;
}

export const MobileNavBar = (props: MobileNavBarProps) => {
  const { user, selectedRouteSegment } = props;
  const ability = useContext(AbilityContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { selectedMenuItem, fixedMenuItems, profileMenuItem, blogMenuItem } =
    useAppNavBarMenuItems({
      user,
      selectedItemId: selectedRouteSegment,
    });

  const availableMenuItems = useMemo(
    () => filter(fixedMenuItems, (item) => ability?.can('read', item.id)),
    [ability, fixedMenuItems]
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleMenuClose();
  }, [selectedRouteSegment]);

  return (
    <AppBar
      sx={{
        padding: 2,
        backgroundColor: 'background.default',
      }}
      elevation={0}>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          justifyContent: 'space-between',
          paddingBlock: 1.5,
          borderColor: 'other.outlined_border',
        }}>
        <Stack gap={1.5} direction="row">
          <img src={smallLogo} width={24} height={24} alt="Treyd" />
          <Divider orientation="vertical" flexItem />
          <Stack direction="row" gap={1.5}>
            {selectedMenuItem?.icon}
            <Typography variant="body1">{selectedMenuItem?.title}</Typography>
          </Stack>
        </Stack>
        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        elevation={8}
        MenuListProps={{
          disablePadding: true,
        }}
        slotProps={{
          paper: {
            elevation: 2,
            sx: {
              marginTop: 0.5,
              padding: 2,
              width: anchorEl?.clientWidth,
              borderRadius: 2,
              bgcolor: 'background.default',
            },
          },
        }}>
        {map([...availableMenuItems, blogMenuItem, profileMenuItem], (item) => (
          <MenuItem
            href={item.to}
            key={item.id}
            icon={item.icon}
            isSelected={selectedRouteSegment === item.id}
            title={item.title}
            marginBottom={item.id === profileMenuItem.id ? 0 : 0.5}
          />
        ))}
      </Menu>
    </AppBar>
  );
};
