import { useUpdateSupplierData } from '@/hooks';
import { Supplier } from '@/schemas';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';

export const RevokeQI = ({
  supplier,
  isOpen,
  onClose,
}: {
  supplier: Supplier;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [updateSupplierData] = useUpdateSupplierData(supplier?.id);
  const showNotificationMessage = useToastNotification();

  const handleClick = () => {
    updateSupplierData({
      variables: {
        id: supplier?.id,
        supplier_data: {
          exempted_from_inspections: false,
        },
      },
    })
      .then(() => {
        showNotificationMessage({
          title: 'Exemption revoked',
          type: 'success',
        });
        onClose();
      })
      .catch(() => {
        showNotificationMessage({
          title: 'Something went wrong',
          type: 'error',
        });
      });
  };
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          borderRadius: 3,
          paddingBlockEnd: 1.5,
        },
      }}
      fullWidth>
      <DialogTitle>Revoke quality inspection exemption</DialogTitle>
      <DialogContent
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}>
        <Typography variant="body1">
          Are you sure you want to revoke the exemption for {supplier?.name}{' '}
          from quality inspection?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          variant="outlined"
          color="secondary"
          size="large">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          onClick={handleClick}>
          Yes, revoke
        </Button>
      </DialogActions>
    </Dialog>
  );
};
