import { RemainingLimitResponse } from '@/types';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { save } from '@treyd-io/core/utils/request';
import {
  REMAINING_LIMIT,
  UPDATE_COMPANY_MAIN_CONTACT,
} from 'constants/api-endpoints';

export const getRemainingLimit = (companyId: number) =>
  save<RemainingLimitResponse>(
    REMAINING_LIMIT(companyId),
    {},
    REQUEST_METHOD.GET
  );

export const updateMainContact = ({
  companyId,
  userId,
}: {
  companyId: number;
  userId: number;
}) => {
  return save(
    UPDATE_COMPANY_MAIN_CONTACT(companyId),
    { contact_id: userId },
    REQUEST_METHOD.PUT
  );
};
