import { INVOICE_OPS_STATUS, invoiceStatusesDisplayedValue } from '@/constants';
import { Chip, ChipProps } from '@mui/material';

interface Props extends ChipProps {
  status: INVOICE_OPS_STATUS;
}

const chipProps: {
  [key in INVOICE_OPS_STATUS]?: ChipProps;
} = {
  [INVOICE_OPS_STATUS.OPEN]: {
    label: invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.OPEN],
    variant: 'standard',
    color: 'info',
  },
  [INVOICE_OPS_STATUS.OVERDUE]: {
    label: invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.OVERDUE],
    variant: 'standard',
    color: 'error',
  },
  [INVOICE_OPS_STATUS.MATURE_FOR_COLLECTION]: {
    label:
      invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.MATURE_FOR_COLLECTION],
    variant: 'outlined',
    color: 'error',
  },
  [INVOICE_OPS_STATUS.COLLECTION]: {
    label: invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.COLLECTION],
    variant: 'standard',
    color: 'error',
  },
  [INVOICE_OPS_STATUS.CLOSED]: {
    label: invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.CLOSED],
    variant: 'outlined',
    color: 'default',
  },
  [INVOICE_OPS_STATUS.ATTESTED]: {
    label: invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.ATTESTED],
    variant: 'outlined',
    color: 'default',
  },
  [INVOICE_OPS_STATUS.DRAFT]: {
    label: invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.DRAFT],
    variant: 'outlined',
    color: 'default',
  },
  [INVOICE_OPS_STATUS.SOLD]: {
    label: invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.SOLD],
    variant: 'outlined',
    color: 'default',
  },
  [INVOICE_OPS_STATUS.SALES_REQUESTED]: {
    label: invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.SALES_REQUESTED],
    variant: 'outlined',
    color: 'default',
  },
  [INVOICE_OPS_STATUS.SALES_REQUEST_CANCELLED]: {
    label:
      invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.SALES_REQUEST_CANCELLED],
    variant: 'outlined',
    color: 'default',
  },
  [INVOICE_OPS_STATUS.SALES_REQUEST_DENIED]: {
    label:
      invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.SALES_REQUEST_DENIED],
    variant: 'outlined',
    color: 'default',
  },
  [INVOICE_OPS_STATUS.WRITTEN_OFF]: {
    label: invoiceStatusesDisplayedValue[INVOICE_OPS_STATUS.WRITTEN_OFF],
    variant: 'standard',
    color: 'error',
  },
};

export const InvoiceOpsStatusChip = (props: Props) => {
  const { status, ...rest } = props;

  if (!chipProps[status]) return null;

  return <Chip {...chipProps[status]} {...rest} />;
};
