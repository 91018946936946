import {
  HUBSPOT_IDS,
  SEARCH_HUBSPOT_COMPANIES,
} from '@/constants/api-endpoints';
import {
  HubspotIdsResponse,
  SearchHubspotCompaniesResponse,
} from '@/types/hubspot';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { get, save } from '@treyd-io/core/utils/request';

export const getHubspotIds = ({
  country,
  orgNo,
}: {
  country: string;
  orgNo: string;
}) =>
  save<HubspotIdsResponse>(
    HUBSPOT_IDS,
    { org_no: orgNo, country },
    REQUEST_METHOD.POST
  );

export const searchHubspotCompanies = (search: string) =>
  get<SearchHubspotCompaniesResponse>(SEARCH_HUBSPOT_COMPANIES(search));
