import { MERCHANT_LIMIT_STATUS } from '@/constants';
import { LimitStatus, Segment } from '@/types';
import { Chip, ChipProps, SvgIconProps } from '@mui/material';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import ClockIcon from '@treyd-io/treyd-ui/icons/ClockIcon';
import CloseCircleIcon from '@treyd-io/treyd-ui/icons/CloseCircleIcon';
import ForbidTwoIcon from '@treyd-io/treyd-ui/icons/ForbidTwoIcon';
import WarningTriangleIcon from '@treyd-io/treyd-ui/icons/WarningTriangleIcon';
import { mapValues } from 'lodash';
import { ReactElement } from 'react';
import {
  ComplianceSegmentMapper,
  CreditSegmentMapper,
  FundingSegmentMapper,
  MonitoringSegmentMapper,
} from './LimitStatusMappers';

interface LimitStatusReturnType {
  statusChip: ReactElement<ChipProps>;
  segments: Partial<Record<Segment.Keys, Segment.Values>>;
}

export namespace LimitStatusService {
  export const ICON_COMPONENTS: Record<
    Segment.Status,
    ReactElement<SvgIconProps>
  > = {
    PUBLISHED: <CheckCircleIcon color="success" />,
    ACTIVE: <CheckCircleIcon color="success" />,
    INACTIVE: <CloseCircleIcon color="error" />,
    INDICATIVE: <CheckCircleIcon color="warning" />,
    WARNING: <WarningTriangleIcon color="warning" />,
    PENDING: <ClockIcon />,
    NOT_STARTED: <ForbidTwoIcon color="disabled" />,
  };

  export const CHIPS_COMPONENTS: Record<
    Segment.Status,
    ReactElement<ChipProps> | null
  > = {
    PUBLISHED: <Chip label="Published" variant="standard" color="success" />,
    INACTIVE: <Chip label="Inactive limit" variant="standard" color="error" />,
    INDICATIVE: (
      <Chip label="Indicative limit" variant="standard" color="warning" />
    ),
    ACTIVE: null,
    WARNING: null,
    PENDING: null,
    NOT_STARTED: null,
  };

  const createSegments = (segments: LimitStatus.Segments) => {
    const segmentMappers: Record<Segment.Keys, Segment.Values> = {
      credit: CreditSegmentMapper(segments.credit),
      compliance: ComplianceSegmentMapper(segments.compliance),
      funding: FundingSegmentMapper(segments.funding),
      monitoring: MonitoringSegmentMapper(segments.monitoring),
    };

    return mapValues(segments, (_, key) => segmentMappers[key as Segment.Keys]);
  };

  export const getLimitStatus = ({
    credit,
    compliance,
    funding,
    monitoring,
    status,
  }: LimitStatus.Response): LimitStatusReturnType => {
    const statusChip: Record<LimitStatus.Status, ReactElement<ChipProps>> = {
      ACTIVE: (
        <Chip
          label={MERCHANT_LIMIT_STATUS.ACTIVE}
          variant="standard"
          color="success"
        />
      ),
      INACTIVE: (
        <Chip
          label={MERCHANT_LIMIT_STATUS.INACTIVE}
          variant="standard"
          color="error"
        />
      ),
    };

    return {
      statusChip: statusChip[status],
      segments: createSegments({ credit, compliance, funding, monitoring }),
    };
  };
}
