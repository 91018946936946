import { RISK_LEVEL_OPTIONS } from '@/constants';
import { SupplierCompliance } from '@/types';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { toString } from 'lodash';

interface ComplianceDetailsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: SupplierCompliance.DetailsFormValues) => void;
  initialValues: SupplierCompliance.DetailsFormValues;
}

export const ComplianceDetailsDialog = (
  props: ComplianceDetailsDialogProps
) => {
  const { isOpen, onClose, onSubmit, initialValues } = props;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Edit details</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(formik) => {
          return (
            <Form>
              <DialogContent>
                <Stack gap={3} paddingY={2}>
                  <TextField
                    name="url"
                    label="URL"
                    onChange={formik.handleChange}
                    value={formik.values?.url}
                    fullWidth
                    error={formik.touched?.url && Boolean(formik.errors?.url)}
                    helperText={formik.touched?.url && formik.errors?.url}
                  />
                  <Autocomplete
                    options={RISK_LEVEL_OPTIONS}
                    value={formik.values?.riskLevel || null}
                    fullWidth
                    onChange={(_, value) =>
                      formik.setFieldValue('riskLevel', toString(value))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Risk level"
                        error={
                          formik.touched.riskLevel &&
                          Boolean(formik.errors.riskLevel)
                        }
                        helperText={
                          formik.touched.riskLevel && formik.errors.riskLevel
                        }
                      />
                    )}
                  />
                  <TextField
                    name="comments"
                    label="Comments"
                    multiline
                    rows={3}
                    onChange={formik.handleChange}
                    value={formik.values?.comments}
                    fullWidth
                    error={
                      formik.touched?.comments &&
                      Boolean(formik.errors?.comments)
                    }
                    helperText={
                      formik.touched?.comments && formik.errors?.comments
                    }
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={!formik.dirty}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
