import { getOrderSignatureLogsQuery } from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetOrderSignatureLogs = (orderId: number) =>
  useQuery(getOrderSignatureLogsQuery, {
    variables: {
      orderId,
    },
    skip: !orderId,
  });
