import { useState } from 'react';

export const useTimer = (initial: number, duration: number) => {
  const [counter, setCounter] = useState(initial);
  const startTimer = () => {
    setCounter(duration);
    const timer = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(timer);
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);
  };
  return { counter, startTimer };
};
