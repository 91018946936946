import { getInvoiceById } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetInvoiceById = (invoiceId: number) =>
  useQuery({
    queryKey: [`getInvoice`, invoiceId],
    queryFn: () => getInvoiceById(invoiceId),
    refetchOnWindowFocus: false,
    enabled: !!invoiceId,
    select: (data) => data.data,
  });
