import { Stack, Typography } from '@mui/material';

export const EmptySection = ({
  title,
  subtitle,
  inline,
}: {
  title: string;
  subtitle: string;
  inline?: boolean;
}) =>
  inline ? (
    <Stack gap={0.5} paddingY={6} paddingX={3} textAlign="center">
      <Typography variant="subtitle1" color="text.primary">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {subtitle}
      </Typography>
    </Stack>
  ) : (
    <Stack
      gap={0.5}
      border={1}
      borderColor={'other.outlined_border'}
      borderRadius={2}
      paddingY={6}
      paddingX={3}
      textAlign="center">
      <Typography variant="subtitle1" color="text.primary">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {subtitle}
      </Typography>
    </Stack>
  );
