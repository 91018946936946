import { styled } from '@mui/material';
import { Form } from 'formik';

export const DetailsForm = styled(Form)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  border: `1px solid ${theme.palette.grey['400']}`,
  borderRadius: theme.spacing(1),
  marginBlock: theme.spacing(3),
  padding: theme.spacing(2),
  width: theme.spacing(40),
}));
