import { aggregateInvoicesByFinanciersQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useAggregateInvoicesByFinanciers = ({
  financiersIds,
  currencies,
  queryOptions,
  skip,
}: {
  financiersIds?: number[];
  currencies?: string[];
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(
    aggregateInvoicesByFinanciersQuery(financiersIds, currencies, queryOptions),
    {
      skip,
    }
  );
