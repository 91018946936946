import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  DefaultOptions,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getJWT } from '@treyd-io/core/utils/jwt';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { HASURA_HOST, protocol } from 'constants/api-endpoints';
import { ReactNode } from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const httpLink = createUploadLink({ uri: `${protocol.http}://${HASURA_HOST}` });

const wsClient: SubscriptionClient = new SubscriptionClient(
  `${protocol.ws}://${HASURA_HOST}`,
  {
    reconnect: true,
    lazy: true,
    timeout: 90000,
    connectionParams: async () => ({
      headers: {
        authorization: getJWT()?.accessToken,
      },
    }),
  }
);

const wsLink: WebSocketLink = new WebSocketLink(wsClient);

const link = split(
  // split based on operation type
  ({ query }) => {
    const main_definittion = getMainDefinition(query);
    return (
      main_definittion.kind === 'OperationDefinition' &&
      main_definittion?.operation === 'subscription'
    );
  },
  wsLink as unknown as ApolloLink, //fix the problem in the types of apollo-link-ws
  httpLink as unknown as ApolloLink //fix the problem in the types of apollo-link-ws
);

const authLink = setContext((op, { headers }) => {
  // Retrieve the authorization token from local storage.
  const { accessToken } = getJWT();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken,
    },
  };
});

const defaultOptions = {
  // connectToDevTools: true,
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  mutate: {
    errorPolicy: 'all',
  },
} as unknown as DefaultOptions;

const getApolloClient = () => {
  return new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
    defaultOptions,
  });
};

export const ApolloClientProvider = ({ children }: { children: ReactNode }) => {
  const client = getApolloClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
