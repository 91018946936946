import { BankAccountCompliance } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';
import { Orders_Bank_Accounts } from '__generated__/graphql';

export type BankAccountFragment = Pick<
  Orders_Bank_Accounts,
  | 'id'
  | 'is_valid'
  | 'sort_code'
  | 'bank_name'
  | 'bank_country'
  | 'aba'
  | 'acct_number'
  | 'bank_account_holder_name'
  | 'bank_address'
  | 'bank_code'
  | 'branch_code'
  | 'bic_swift'
  | 'bsb_code'
  | 'clabe'
  | 'cnaps'
  | 'combination_id'
  | 'created_at'
  | 'currency'
  | 'error_msgs'
  | 'iban'
  | 'ifsc'
  | 'institution_no'
  | 'payment_type'
  | 'deactivated_at'
  | 'bankgiro_number'
  | 'plusgiro_number'
> & {
  compliance: BankAccountCompliance;
};

export const Bank_Account_Fragment = gql`
  fragment BankAccountType on orders_bank_accounts {
    id
    is_valid
    sort_code
    bank_name
    bank_country
    aba
    acct_number
    bank_account_holder_name
    bank_address
    bank_code
    branch_code
    bic_swift
    bsb_code
    clabe
    cnaps
    combination_id
    created_at
    currency
    error_msgs
    iban
    ifsc
    institution_no
    payment_type
    deactivated_at
    bankgiro_number
    plusgiro_number
    compliance
  }
` as TypedDocumentNode<BankAccountFragment, {}>;
