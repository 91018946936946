import { NotificationGroup } from '@/schemas';
import { UserService } from '@/services';
import { Stack } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';

export const NotificationGroupInfoSection = ({
  notificationGroup,
}: {
  notificationGroup?: NotificationGroup;
}) => {
  const notificationGroupId = notificationGroup?.id;
  const notificationCreatedBy = UserService.getDisplayedName(
    notificationGroup?.user
  );
  return (
    <Stack
      borderRadius={4}
      border={1}
      borderColor={'other.outlined_border'}
      padding={{ xs: 3, md: 4 }}>
      <Row>
        <Col xs={12} sm={6} lg>
          <TwoLines
            label="Creation date"
            content={
              notificationGroup?.created
                ? formatDate(new Date(notificationGroup.created), 'date')
                : '-'
            }
            contentProps={{
              noWrap: true,
            }}
          />
        </Col>
        <Col xs={12} sm={6} lg>
          <TwoLines
            label="Created by"
            content={notificationCreatedBy || '-'}
            contentProps={{
              noWrap: true,
            }}
          />
        </Col>
        <Col xs={12} sm={6} lg>
          <TwoLines
            label="Affected merchants"
            content={notificationGroup?.targets_ids?.length}
            contentProps={{
              noWrap: true,
            }}
          />
        </Col>
        <Col xs={12} sm={6} lg>
          <TwoLines
            label="Notification ID"
            content={notificationGroupId}
            contentProps={{
              noWrap: true,
            }}
          />
        </Col>
      </Row>
    </Stack>
  );
};
