import GenericTableView from '@/components/GenericTableView';
import { DEFAULT_GRID_COL_DEF, SUPPLIERS_PATHS } from '@/constants';
import {
  Supplier,
  getMerchantSuppliersQuery,
} from '@/schemas/getMerchantSuppliersQuery';
import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { COUNTRIES_ENUM } from '@treyd-io/core/constants/country';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const columns: GridColDef<Supplier>[] = [
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'name',
    headerName: 'Name',
    width: 250,
    type: 'string',
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'country',
    headerName: 'Country',
    type: 'string',
    width: 200,
    valueGetter: ({ value }) => getCountryName(value),
    renderCell: (params) => (
      <Typography variant="body2" sx={{ fontVariantNumeric: 'tabular-nums' }}>
        {params.value}
      </Typography>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'exempted_from_inspections',
    headerName: 'QI exempt',
    type: 'string',
    width: 100,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={(theme) => ({
          color: params.value
            ? theme.palette.success.main
            : theme.palette.error.main,
        })}>
        {params.value ? 'Yes' : 'No'}
      </Typography>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'allDeals',
    headerName: 'All deals',
    type: 'string',
    sortable: false,
    width: 80,
    valueGetter: ({ value }) => value?.aggregate.count,
    renderCell: (params) => (
      <Typography variant="body2">{params.value}</Typography>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'paidDeals',
    headerName: 'Paid deals',
    type: 'string',
    sortable: false,
    width: 90,
    valueGetter: ({ value }) => value?.aggregate.count,
    renderCell: (params) => (
      <Typography variant="body2">{params.value}</Typography>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'kyc',
    headerName: 'Screening',
    type: 'string',
    width: 100,
    renderCell: (params) => (
      <Typography
        variant="body2"
        color={(theme) =>
          params.value === 'Approved'
            ? theme.palette.success.main
            : theme.palette.error.main
        }>
        {params.value}
      </Typography>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'created_at',
    headerName: 'Created',
    type: 'string',
    width: 150,
  },
];

const formatSupplier = (s: Supplier) => ({
  ...s,
  company__name: s.company?.name,
  kyc: s?.kyc?.screeningResult,
  created_at: s?.created_at
    ? formatDate(new Date(s.created_at), 'datetime')
    : '',
});

export const Suppliers = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const merchantId = id ? Number(id) : undefined;
  const handleRowClick = useCallback(
    (params: Supplier) =>
      navigate(SUPPLIERS_PATHS[':id'].replaceAll(':id', params.id)),
    [navigate]
  );

  const filters = [
    {
      field: 'country',
      options: COUNTRIES_ENUM as string[],
      label: 'All countries',
    },
  ];

  return (
    <Box display="flex" flexDirection={'column'} flexGrow={1} height={'auto'}>
      <GenericTableView
        isDownloadAllowed={true}
        searchPlaceholder="Search for supplier"
        columns={columns}
        getGQLSchema={(offset, limit, searchTerm, sorting, filters) =>
          getMerchantSuppliersQuery(
            offset,
            limit,
            merchantId,
            searchTerm,
            sorting,
            filters
          )
        }
        rowFormatter={formatSupplier}
        resourceName="orders_suppliers"
        onRowClick={handleRowClick}
        filterList={filters}
        filterMapper={(country_code) => getCountryName(country_code)}
      />
    </Box>
  );
};
