import {
  MERCHANT_SEGMENT,
  TRANSACTION_TYPE,
  treydInvoicingSystem,
} from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Invoicing_App_Transaction,
  Orders_Companies,
  Orders_Order_Invoices,
} from '__generated__/graphql';

type TransactionImporter = Pick<Orders_Companies, 'name' | 'id'> & {
  segment: MERCHANT_SEGMENT;
};

type TransactionInvoice = Pick<
  Orders_Order_Invoices,
  'invoice_number' | 'order_id' | 'id' | 'financier'
> & {
  order: { importer: TransactionImporter };
};

export type Transaction = Pick<
  Invoicing_App_Transaction,
  'payment_date' | 'amount' | 'amount_currency' | 'id'
> & {
  type: TRANSACTION_TYPE;
  invoices: TransactionInvoice[];
};

type GetTransactionsQueryType = {
  invoicing_app_transaction: Transaction[];
  invoicing_app_transaction_aggregate: { aggregate: { count: number } };
};

export const getTransactionsQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
    query getTransactions {
      invoicing_app_transaction ${queryParams}  {
        id
        payment_date
        amount
        amount_currency
        type
        invoices (where:{invoicing_system: {_eq: ${treydInvoicingSystem}}}) {
          id
          invoice_number
          order_id
					financier {
						name
					}
          order {
            importer {
              id
              name
							segment
            }
          }
        }
      }
      invoicing_app_transaction_aggregate ${
        whereClause ? `(where: ${whereClause})` : ''
      }{
        aggregate {
          count
        }
      }
    }
    ` as TypedDocumentNode<GetTransactionsQueryType, {}>;
};
