import { PaletteMode } from '@mui/material';
import { createContext } from 'react';

interface ColorModeContextSchema {
  toggleColorMode: () => void;
  colorMode: PaletteMode;
}

export const ColorModeContext = createContext<ColorModeContextSchema>(
  {} as ColorModeContextSchema
);
