import { useEffect, useRef } from 'react';

const useDebouncedEffect = (effect: any, deps: any, delay: any) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      const handler = setTimeout(() => effect(), delay);
      return () => clearTimeout(handler);
    } else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

export default useDebouncedEffect;
