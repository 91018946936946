import { DEFAULT_COUNTRY, DEFAULT_CURRENCY } from '@/constants';
import { getBeneficiaryRequirementsQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

export const usePaymentsRequirements = ({
  beneficiaryCountry,
  bankCountry,
  currency,
}: {
  beneficiaryCountry: string;
  currency?: string;
  bankCountry?: string;
}) => {
  const { data, loading, refetch } = useQuery(getBeneficiaryRequirementsQuery, {
    variables: {
      currency: currency?.toUpperCase() || DEFAULT_CURRENCY,
      beneficiaryCountry:
        beneficiaryCountry?.toUpperCase() ||
        bankCountry?.toUpperCase() ||
        DEFAULT_COUNTRY,
      bankAccountCountry:
        bankCountry?.toUpperCase() ||
        beneficiaryCountry?.toUpperCase() ||
        DEFAULT_COUNTRY,
    },
  });
  useEffect(() => {
    if (currency && beneficiaryCountry && bankCountry) {
      refetch({
        currency: currency?.toUpperCase(),
        beneficiaryCountry:
          beneficiaryCountry?.toUpperCase() || bankCountry?.toUpperCase(),
        bankAccountCountry:
          bankCountry?.toUpperCase() || beneficiaryCountry?.toUpperCase(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, beneficiaryCountry, bankCountry]);

  return {
    getBeneficiaryRequirements: refetch,
    data,
    loading,
  };
};
