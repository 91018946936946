import { publishNotificationGroup, unpublishNotificationGroup } from '@/api';
import { ComponentLoader } from '@/components/ComponentLoader';
import { useBrowsingHistory, useGetNotificationGroup } from '@/hooks';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { AffectedMerchantsSection } from './components/AffectedMerchantsSection';
import { MerchantViewSection } from './components/MerchantViewSection';
import { NotificationGroupInfoSection } from './components/NotificationGroupInfoSection';

export const NotificationDetails = () => {
  const { goBack } = useBrowsingHistory();
  const showNotificationMessage = useToastNotification();
  const params = useParams();
  const notificationGroupId = Number(params.notification_id);
  const {
    data,
    loading,
    refetch: refetchNotificationGroup,
  } = useGetNotificationGroup(notificationGroupId);
  const notificationGroup =
    data?.orders_notification_center_notificationgroup_by_pk;
  const notificationTemplate = notificationGroup?.notification_template;
  const isPublished = Boolean(notificationGroup?.published);
  const affectedMerchantsIds = notificationGroup?.targets_ids;

  const handlePublishNotificationGroup = async () => {
    try {
      await publishNotificationGroup(notificationGroupId);
      refetchNotificationGroup();
      showNotificationMessage({
        title: 'Notification published',
        type: 'success',
      });
    } catch (err) {
      showNotificationMessage({
        title: "Couldn't publish the notification",
        type: 'error',
      });
    }
  };

  const handleUnpublishNotificationGroup = async () => {
    try {
      await unpublishNotificationGroup(notificationGroupId);
      refetchNotificationGroup();
      showNotificationMessage({
        title: 'Notification unpublished',
        type: 'success',
      });
    } catch (err) {
      showNotificationMessage({
        title: "Couldn't unpublish the notification",
        type: 'error',
      });
    }
  };

  if (loading) return <ComponentLoader />;

  return (
    <Stack gap={3}>
      <Stack
        direction="row"
        gap={1}
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap">
        <Stack gap={1}>
          <Button
            variant="text"
            color="info"
            startIcon={<ArrowLeftIcon />}
            sx={{ width: 'max-content' }}
            onClick={goBack}>
            Back
          </Button>
          <Stack gap={1}>
            <Stack
              direction={{ xs: 'column-reverse', sm: 'row' }}
              gap={1}
              alignItems={{ sm: 'center' }}>
              <Typography variant={'h4'}>
                {notificationTemplate?.header}
              </Typography>
              <Chip
                sx={{ alignSelf: { xs: 'flex-start', sm: 'center' } }}
                variant="standard"
                label={isPublished ? 'Published' : 'Unpublished'}
                color={isPublished ? 'success' : 'error'}
              />
            </Stack>
          </Stack>
        </Stack>
        {isPublished ? (
          <Button
            color="error"
            variant="text"
            size="large"
            sx={{ paddingBlock: 1.375, paddingInline: 2 }}
            onClick={handleUnpublishNotificationGroup}>
            Unpublish
          </Button>
        ) : (
          <Button
            color="success"
            variant="contained"
            size="large"
            sx={{ paddingBlock: 1.375, paddingInline: 2 }}
            onClick={handlePublishNotificationGroup}
            disabled={isEmpty(affectedMerchantsIds)}>
            Publish
          </Button>
        )}
      </Stack>

      <Stack gap={3}>
        <NotificationGroupInfoSection notificationGroup={notificationGroup} />
        <MerchantViewSection notificationTemplate={notificationTemplate} />
        <AffectedMerchantsSection
          notificationGroupId={notificationGroupId}
          affectedMerchantsIds={notificationGroup?.targets_ids}
          isNotificationGroupPublished={isPublished}
          onMerchantsUpdate={refetchNotificationGroup}
        />
      </Stack>
    </Stack>
  );
};
