import { gql } from '@apollo/client';
import { GridSortItem } from '@mui/x-data-grid';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import { isEmpty, toNumber } from 'lodash';

export const getTreydUsers = (
  offset: number,
  limit: number,
  searchTerm = '',
  sorting = {},
  filters = {}
) => {
  const usersFilters: Filter[] = [
    {
      type: 'search',
      name: 'email',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'company_id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(searchTerm)
          ? null
          : toNumber(searchTerm),
    },
    {
      type: 'search',
      name: 'name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
  ];

  const { queryParams, whereClause } = getGqlQueryParams({
    limit,
    offset,
    sorting: sorting as GridSortItem,
    filters: usersFilters,
  });

  return gql`
	query getTreydUsers {
		lager_treyd_users ${queryParams}{
			id
			first_name
			last_name
			name
			can_sign_alone
			is_ubo
			company_id
			identity_verified
			is_active
			email
			member_id
		}
		lager_treyd_users_aggregate ${whereClause ? `(where: ${whereClause})` : ''} {
			aggregate {
				count
			}
		}
	}
	`;
};
