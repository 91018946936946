import { ExportedInvoiceTransaction, Invoice } from '@/schemas';
import { CSVBuilder } from '@treyd-io/core/services/CsvService';
import { formatDate } from '@treyd-io/core/utils/date';
import { downloadCsvFile } from '@treyd-io/core/utils/downloadCSV';
import { forEach, isEmpty, map, reduce, startCase, toString } from 'lodash';
import { InvoiceService } from './Invoice';

export class InvoicesTransactionsCsvService {
  private csvBuilder: CSVBuilder;

  constructor() {
    this.csvBuilder = new CSVBuilder();
  }

  private buildHeaders(): void {
    this.csvBuilder.setHeaders([
      ' ',
      'Invoice #',
      'Order #',
      'Payment date',
      'Amount',
      'Currency',
      'Transaction type',
    ]);
  }

  private formatTransactionRow(
    transaction: ExportedInvoiceTransaction,
    index: number
  ): (string | number | Date)[] {
    return map(
      [
        index + 1,
        toString(transaction?.invoice_number),
        toString(transaction?.order_id),
        transaction?.payment_date
          ? formatDate(new Date(transaction?.payment_date), 'date')
          : '',
        toString(transaction?.amount),
        toString(transaction?.amount_currency),
        startCase(transaction?.type || ''),
      ],
      (value) => `"${value}"`
    );
  }

  private buildRows(transactions: ExportedInvoiceTransaction[]): void {
    const formattedRows = map(transactions, (invoice, index) =>
      this.formatTransactionRow(invoice, index)
    );
    this.csvBuilder.addRow(map(formattedRows, (row) => row.join(',')));
  }

  private getInvoicesTransactions(invoices: Invoice[]) {
    return reduce(
      invoices,
      (acc: ExportedInvoiceTransaction[], invoice) => {
        const invoiceTransactions = invoice.transactions;
        if (!InvoiceService.isOldInvoicingSystem(invoice.invoicing_system)) {
          forEach(invoiceTransactions, (transaction) => {
            acc.push({
              ...transaction,
              invoice_number: toString(invoice.invoice_number),
              order_id: invoice.order_id,
            });
          });
        }

        return acc;
      },
      []
    );
  }

  exportToCsv(invoices: Invoice[]) {
    const transactions = this.getInvoicesTransactions(invoices);

    if (isEmpty(transactions)) return false;

    this.buildHeaders();
    this.buildRows(transactions);

    const csvData = this.csvBuilder.build();
    downloadCsvFile({
      data: csvData,
      fileName: 'Transactions.csv',
    });
    return true;
  }
}
