import {
  ORDER_REVIEW_ACTIONS,
  submitButtonColor,
} from './OrderActionModal/types';

export const REASON_OPTIONS = [
  'Uploaded document is not readable',
  'Uploaded document is not a correct invoice',
  'Items on invoice are not covered by Treyd (not finished goods)',
  'Invoice is past due date',
  'Amounts on invoice and platform do not match',
  'Seller on invoice is not Supplier on platform',
  'Buyer on invoice is not Treyd Customer on platform',
  'Incorrect payment beneficiary details',
  'Other',
];

export const REJECT_REASON_OPTIONS = [
  'Uploaded document is not readable',
  'Uploaded document is not a correct invoice',
  'Items on invoice are not covered by Treyd (not finished goods)',
  'Invoice is past due date',
  'Amounts on invoice and platform do not match',
  'Seller on invoice is not Supplier on platform',
  'Buyer on invoice is not Treyd Customer on platform',
  'Incorrect payment beneficiary details',
  'Intra company transaction',
  'Duplicate order',
  'Other',
];

export const FORM_INITIAL_STATE = {
  reason: '',
  comment: '',
};

export const APPROVAL_FORM_INITIAL_STATE = {
  comment: '',
};

export type ModalContent = {
  subtitle: string;
  commentInputLabel: string;
  reasonInputOptions?: string[];
  submitButtonColor: submitButtonColor;
  submitButtonLabel: string;
  initialErrors: any;
  initialValues: any;
};

interface ModalContents {
  [index: string]: ModalContent;
}

export const MODAL_CONTENT: ModalContents = {
  [ORDER_REVIEW_ACTIONS.REJECT]: {
    subtitle:
      'This action is non-reversible. The merchant will have to create a new order.',
    commentInputLabel: 'Add reason for rejection',
    reasonInputOptions: REJECT_REASON_OPTIONS,
    submitButtonColor: 'error' as submitButtonColor,
    submitButtonLabel: ORDER_REVIEW_ACTIONS.REJECT,
    initialErrors: FORM_INITIAL_STATE,
    initialValues: FORM_INITIAL_STATE,
  },
  [ORDER_REVIEW_ACTIONS.SEND_BACK]: {
    subtitle: 'The order will be sent back to the merchant as a draft',
    commentInputLabel: 'Add reason for sendback',
    reasonInputOptions: REASON_OPTIONS,
    submitButtonColor: 'primary' as submitButtonColor,
    submitButtonLabel: ORDER_REVIEW_ACTIONS.SEND_BACK,
    initialErrors: FORM_INITIAL_STATE,
    initialValues: FORM_INITIAL_STATE,
  },
  [ORDER_REVIEW_ACTIONS.APPROVE]: {
    subtitle: '',
    commentInputLabel: 'Add a comment',
    submitButtonColor: 'success' as submitButtonColor,
    submitButtonLabel: ORDER_REVIEW_ACTIONS.APPROVE,
    initialErrors: undefined,
    initialValues: APPROVAL_FORM_INITIAL_STATE,
  },
};
