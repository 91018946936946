import { getMerchantSuppliersErpInvoicesQuery } from '@/schemas/getMerchantSuppliersErpInvoicesQuery';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetMerchantSuppliersErpInvoices = ({
  queryOptions,
  skip,
  companyId,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
  companyId: number;
}) =>
  useQuery(getMerchantSuppliersErpInvoicesQuery(queryOptions), {
    variables: {
      companyId,
    },
    skip,
  });
