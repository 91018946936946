import NavTabsBar from '@/components/NavTabsBar';
import { useGetOrdersReviewCount } from '@/hooks';
import { AwaitingFundingApprovalOrders } from '@/pages/orderReview/OrderReviewListing/AwaitingFundingApprovalOrders';
import { CancelledOrdersTable } from '@/pages/orderReview/OrderReviewListing/CancelledOrdersTable';
import { InProgressOrdersTable } from '@/pages/orderReview/OrderReviewListing/InProgressOrdersTable';
import { Box, Stack, Typography } from '@mui/material';
import { ApprovedOrdersTable } from './ApprovedOrdersTable';
import { AwaitingComplianceApprovalOrders } from './AwaitingComplianceApprovalOrders';

import { AllOrdersTable } from './AllOrdersTable';
import { DraftOrdersTable } from './DraftOrdersTable';
import { NewOrdersTable } from './NewOrdersTable';

const useDealCounts = () => {
  const { data, loading } = useGetOrdersReviewCount();
  const allOrdersCount = data?.all?.aggregate?.count;
  const draft_orders_count = data?.draft?.aggregate?.count;
  const new_orders_count = data?.awaiting_approval?.aggregate?.count;
  const inprogress_orders_count = data?.inprogress?.aggregate?.count;
  const approved_orders_count = data?.approved?.aggregate?.count;
  const cancelledAndRejectedOrdersCount =
    data?.cancelledAndRejected?.aggregate?.count;
  const awaiting_compliance_approval_orders_count =
    data?.awaiting_compliance_approval?.aggregate?.count;
  const awaiting_funding_approval_orders_count =
    data?.awaiting_funding_approval?.aggregate?.count;

  return {
    allOrdersCount,
    draft_orders_count,
    new_orders_count,
    approved_orders_count,
    cancelledAndRejectedOrdersCount,
    inprogress_orders_count,
    awaiting_compliance_approval_orders_count,
    awaiting_funding_approval_orders_count,
    loading,
  };
};

export default function OrderReviewListing() {
  const {
    allOrdersCount,
    draft_orders_count,
    new_orders_count,
    approved_orders_count,
    inprogress_orders_count,
    cancelledAndRejectedOrdersCount,
    awaiting_compliance_approval_orders_count,
    awaiting_funding_approval_orders_count,
  } = useDealCounts();
  const tabs = [
    {
      id: 'all-orders',
      label: 'All',
      chipLabel: allOrdersCount,
      content: <AllOrdersTable />,
    },
    {
      id: 'draft-orders',
      label: 'Draft',
      chipLabel: draft_orders_count,
      content: <DraftOrdersTable />,
    },
    {
      id: 'new-orders',
      label: 'New',
      chipLabel: new_orders_count,
      content: <NewOrdersTable />,
    },
    {
      id: 'inprogress-orders',
      label: 'In progress',
      chipLabel: inprogress_orders_count,
      content: <InProgressOrdersTable />,
    },
    {
      id: 'awaiting-compliance-approval-orders',
      label: 'Compliance',
      chipLabel: awaiting_compliance_approval_orders_count,
      content: <AwaitingComplianceApprovalOrders />,
    },
    {
      id: 'awaiting-funding-approval-orders',
      label: 'Funding',
      chipLabel: awaiting_funding_approval_orders_count,
      content: <AwaitingFundingApprovalOrders />,
    },
    {
      id: 'approved-orders',
      label: 'Approved',
      chipLabel: approved_orders_count,
      content: <ApprovedOrdersTable />,
    },
    {
      id: 'rejected-orders',
      label: 'Cancelled & Rejected',
      chipLabel: cancelledAndRejectedOrdersCount,
      content: <CancelledOrdersTable />,
    },
  ];
  return (
    <Stack flexGrow={1} height="100%">
      <Box sx={{ marginBlockEnd: 1 }}>
        <Typography variant="h4">Orders review</Typography>
      </Box>
      <NavTabsBar tabs={tabs} defaultTabId={'new-orders'} />
    </Stack>
  );
}
