import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Notification_Center_Emaillog,
  Orders_Notification_Center_Notificationtemplate,
} from '__generated__/graphql';

export type EmailLog = Pick<
  Orders_Notification_Center_Emaillog,
  | 'id'
  | 'created'
  | 'recipient'
  | 'open_count'
  | 'first_opened_at'
  | 'last_opend_at'
  | 'delivered_at'
> & {
  template: Pick<Orders_Notification_Center_Notificationtemplate, 'name'>;
};

type GetEmailsLogsQueryTypes = {
  orders_notification_center_emaillog: EmailLog[];
  orders_notification_center_emaillog_aggregate: {
    aggregate: { count: number };
  };
};

export const getEmailsLogsQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getEmails{
    orders_notification_center_emaillog ${queryParams} {
      id
      created
      recipient
      delivered_at
      first_opened_at
      last_opend_at
      open_count
      template {
        name
      }
    }
    orders_notification_center_emaillog_aggregate ${
      whereClause ? `(where: ${whereClause})` : ''
    } {
      aggregate {
        count
      }
    }
    
    }
  ` as TypedDocumentNode<GetEmailsLogsQueryTypes>;
};
