import { BankAccountCompliance } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';
import { Orders_Bank_Accounts } from '__generated__/graphql';

export type SupplierBankAccount = Pick<
  Orders_Bank_Accounts,
  | 'created_at'
  | 'aba'
  | 'acct_number'
  | 'bank_account_holder_name'
  | 'bank_address'
  | 'bank_code'
  | 'bank_country'
  | 'bank_name'
  | 'bankgiro_number'
  | 'bic_swift'
  | 'branch_code'
  | 'bsb_code'
  | 'clabe'
  | 'cnaps'
  | 'combination_id'
  | 'currency'
  | 'deactivated_at'
  | 'error_msgs'
  | 'iban'
  | 'ifsc'
  | 'institution_no'
  | 'payment_type'
  | 'plusgiro_number'
  | 'sort_code'
  | 'id'
  | 'awx_bene_val_errors'
> & {
  compliance: BankAccountCompliance;
};
type GetSupplierBankAccountsQueryType = {
  orders_suppliers_by_pk: {
    bank_accounts: SupplierBankAccount[];
  };
};

type GetSupplierBankAccountsQueryVariablesType = {
  supplierId: number;
};

export const getSupplierBankAccountsQuery = gql`
  query getSupplierBankAccountsQuery($supplierId: bigint!) {
    orders_suppliers_by_pk(id: $supplierId) {
      bank_accounts {
        created_at
        aba
        acct_number
        bank_account_holder_name
        bank_address
        bank_code
        bank_country
        bank_name
        bankgiro_number
        bic_swift
        branch_code
        bsb_code
        clabe
        cnaps
        combination_id
        currency
        deactivated_at
        error_msgs
        iban
        ifsc
        institution_no
        payment_type
        plusgiro_number
        sort_code
        id
        compliance
        awx_bene_val_errors
      }
    }
  }
` as TypedDocumentNode<
  GetSupplierBankAccountsQueryType,
  GetSupplierBankAccountsQueryVariablesType
>;
