import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { useAddSupplierAttachment, useDeleteSupplierAttachment } from '@/hooks';
import { SupplierCompliance } from '@/types';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { UploadButton } from '@treyd-io/treyd-ui/components/Upload/UploadButton';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import CheckIcon from '@treyd-io/treyd-ui/icons/CheckIcon';
import LockUnlockedIcon from '@treyd-io/treyd-ui/icons/LockUnlockedIcon';
import { isEmpty, map } from 'lodash';
import { useToggle } from 'react-use';

import { Supplier } from '@/schemas';
import { ExemptQI } from './ExemptQI';
import { RevokeQI } from './RevokeQI';
import { SupplierAttachment } from './SupplierAttachment';

export const QualityInspectionSection = ({
  supplier,
  attachments,
}: {
  supplier: Supplier;
  attachments: SupplierCompliance.Attachment[];
}) => {
  const theme = useTheme();
  const [uploadSupplierAttachment, { loading: isUploading }] =
    useAddSupplierAttachment(supplier?.id);

  const [deleteSupplierAttachment] = useDeleteSupplierAttachment(supplier?.id);
  const showNotificationMessage = useToastNotification();

  type QIChip = {
    label: string;
    color: 'success' | 'default';
    textColor?: string;
  };

  const qualityInspectionExemption: QIChip = supplier?.exempted_from_inspections
    ? { label: 'Exempt', color: 'success', textColor: 'success.dark' }
    : { label: 'Not exempt', color: 'default', textColor: 'primary.main' };
  const [isExemptQIModalOpen, toggleExemptQIModal] = useToggle(false);
  const [isRevokeQIModalOpen, toggleRevokeQIModal] = useToggle(false);

  return (
    <Stack direction="column" gap={2}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Typography variant="h5">Quality inspection</Typography>
          <Chip
            label={
              <Typography
                variant="body2"
                color={qualityInspectionExemption.textColor}>
                {qualityInspectionExemption?.label}
              </Typography>
            }
            color={qualityInspectionExemption?.color}
            variant="standard"
          />
        </Box>
        <Box display={'flex'} gap={2} alignSelf={'end'}>
          {supplier?.exempted_from_inspections ? (
            <Button
              variant="outlined"
              color="error"
              startIcon={<LockUnlockedIcon />}
              onClick={toggleRevokeQIModal}>
              Revoke
            </Button>
          ) : (
            <>
              <UploadButton
                files={attachments}
                uploadFile={(file) => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    uploadSupplierAttachment({
                      variables: {
                        dataUrl: reader?.result,
                        supplierId: supplier?.id,
                        description: '',
                        filename: file?.name,
                        tag: 'proof_of_payment',
                      },
                    })
                      .then(() => {
                        showNotificationMessage({
                          title: 'Attachment uploaded',
                          type: 'success',
                        });
                      })
                      .catch(() => {
                        showNotificationMessage({
                          title: 'Attachment upload failed',
                          type: 'error',
                        });
                      });
                  };
                  reader.readAsDataURL?.(file);
                }}
                isLoading={isUploading}
                title="Upload previous paid invoices"
                text="Upload previous paid invoices"
                isError={false}
                fullWidth={false}
              />
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<CheckIcon />}
                onClick={toggleExemptQIModal}>
                Exempt
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box
        borderRadius={4}
        border={`${theme.spacing(0.125)} solid`}
        borderColor={theme.palette.divider}
        display={'flex'}
        justifyContent={'start'}
        alignItems={'center'}
        height={'100%'}>
        <Stack direction="column" gap={3} margin={4} width={'100%'}>
          {attachments && (
            <Row spacing={2} display={'flex'} justifyContent={'start'}>
              {map(attachments, (attachment) => (
                <Col key={attachment.id}>
                  <SupplierAttachment
                    attachment={attachment}
                    onDelete={(id) =>
                      deleteSupplierAttachment({
                        variables: {
                          attachmentId: id,
                        },
                      })
                        .then(() => {
                          showNotificationMessage({
                            title: 'Attachment deleted',
                            type: 'success',
                          });
                        })
                        .catch(() => {
                          showNotificationMessage({
                            title: 'Attachment deletion failed',
                            type: 'error',
                          });
                        })
                    }
                  />
                </Col>
              ))}
            </Row>
          )}
          {isEmpty(attachments) && <NoAttachmentsPlaceholder />}
        </Stack>
      </Box>
      <ExemptQI
        isOpen={isExemptQIModalOpen}
        onClose={toggleExemptQIModal}
        supplier={supplier}
      />
      <RevokeQI
        isOpen={isRevokeQIModalOpen}
        onClose={toggleRevokeQIModal}
        supplier={supplier}
      />
    </Stack>
  );
};

const NoAttachmentsPlaceholder = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    paddingY={8}
    bgcolor="grey.50"
    width={'100%'}>
    <Typography variant="body1" color="text.disabled">
      No attachments
    </Typography>
  </Box>
);
