/* eslint-disable @typescript-eslint/no-use-before-define */
import { CREDIT_SUB_STATUS_TITLE } from '@/constants';
import { TimelineItem } from '@/pages/merchants/MerchantsDetails/Tabs/GeneralInfo/TimelineItem';
import { Codes, LimitStatus, LimitStatusSegmentArgs, Segment } from '@/types';
import {
  Alert,
  AlertTitle,
  Link as MuiLink,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toSentenceCase } from '@treyd-io/core/utils/string';
import { intersection, isEmpty, isUndefined, map, reduce } from 'lodash';
import { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LimitStatusService } from '../LimitStatusService';

const PENDING_CREDIT_CODES: Codes.Credit[] = ['NEW_CREDIT_DECISION_IN_STATUS'];
const WARNING_CREDIT_CODES: Codes.Credit[] = ['UP_COMING_LIMIT_RENEWAL'];
const CREDIT_SUB_STATUS = LimitStatusSegmentArgs.CREDIT_SUB_STATUS;

export const CreditSegmentMapper = (
  segment: LimitStatus.Segments['credit']
): Segment.Values => {
  const {
    CREDIT_DECISION_INACTIVE: creditDecisionInactive,
    CREDIT_DECISION_REJECTED: creditDecisionRejected,
    CURRENT_PUBLISHED_CREDIT_DECISION_ACTIVE:
      currentPublishedCreditDecisionActive,
    NEW_CREDIT_DECISION_IN_STATUS: newCreditDecisionInStatus,
    PUBLISHED_INDICATIVE_LIMIT: publishedIndicativeLimit,
    UP_COMING_LIMIT_RENEWAL: upComingLimitRenewal,
    CREDIT_DECISION_EXPIRED: creditDecisionExpired,
  } = reduce(
    segment.uiCodes,
    (
      acc: Partial<Record<Codes.Credit, LimitStatusSegmentArgs.Credit>>,
      uiCode
    ) => {
      acc[uiCode.code] = uiCode.kargs;
      return acc;
    },
    {}
  );

  const alertsMapper: Partial<Record<Codes.Credit, ReactNode>> = {
    UP_COMING_LIMIT_RENEWAL: (
      <Alerts.UpcomingLimitRenewal
        expirationDate={upComingLimitRenewal?.expiration_date}
      />
    ),
  };

  const POINTS_COMPONENTS: Partial<Record<Codes.Credit, ReactNode>> = {
    CREDIT_DECISION_NOT_STARTED: <BulletPoints.CreditDecisionNotStarted />,
    NEW_CREDIT_DECISION_IN_STATUS: (
      <BulletPoints.NewCreditDecisionInStatus
        status={newCreditDecisionInStatus?.status}
      />
    ),
    REQUIREMENTS_NEEDED: <BulletPoints.RequirementsNeeded />,
    PUBLISHED_INDICATIVE_LIMIT: (
      <BulletPoints.PublishedIndicativeLimit
        currency={publishedIndicativeLimit?.currency}
        limit={publishedIndicativeLimit?.limit}
        publishedAt={publishedIndicativeLimit?.published_at}
      />
    ),
    CURRENT_PUBLISHED_CREDIT_DECISION_ACTIVE: (
      <BulletPoints.CurrentPublishedCreditDecisionActive
        currency={currentPublishedCreditDecisionActive?.currency}
        limit={currentPublishedCreditDecisionActive?.limit}
        publishedAt={currentPublishedCreditDecisionActive?.published_at}
      />
    ),
    CREDIT_DECISION_INACTIVE: (
      <BulletPoints.CreditDecisionInactive
        reason={creditDecisionInactive?.reason}
        deactivated_at={creditDecisionInactive?.deactivated_at}
      />
    ),
    CREDIT_DECISION_EXPIRED: (
      <BulletPoints.CreditDecisionExpired
        expirationDate={creditDecisionExpired?.expiration_date}
      />
    ),
    CREDIT_DECISION_REJECTED: (
      <BulletPoints.CreditDecisionRejected
        permanent={creditDecisionRejected?.permanent}
        publishedAt={creditDecisionRejected?.published_at}
      />
    ),
  };

  const points = map(segment.uiCodes, (uiCode) => {
    return POINTS_COMPONENTS[uiCode.code];
  });

  const alerts = map(segment.uiCodes, (uiCode) => {
    return alertsMapper[uiCode.code];
  });

  const icon = ((
    segment: LimitStatus.Segments['credit']
  ): ReactElement<SvgIconProps> => {
    const codes = segment.uiCodes.map((uiCode) => uiCode.code);
    const status = segment.status;

    if (!isEmpty(intersection(codes, PENDING_CREDIT_CODES)))
      return LimitStatusService.ICON_COMPONENTS.PENDING;

    if (!isEmpty(intersection(codes, WARNING_CREDIT_CODES)))
      return LimitStatusService.ICON_COMPONENTS.WARNING;

    return LimitStatusService.ICON_COMPONENTS[status];
  })(segment);

  const chip = LimitStatusService.CHIPS_COMPONENTS[segment.status];

  return {
    chip,
    icon,
    points,
    alerts,
  };
};

namespace BulletPoints {
  export const CreditDecisionNotStarted = () => (
    <TimelineItem>
      <Typography variant="body1">Credit decision not started.</Typography>{' '}
    </TimelineItem>
  );

  export const NewCreditDecisionInStatus = ({
    status,
  }: {
    status: LimitStatusSegmentArgs.CREDIT_SUB_STATUS | undefined;
  }) => {
    return (
      status && (
        <TimelineItem>
          <Typography variant="body1">
            The new credit decision is in{' '}
            <Typography display={'inline'} variant="subtitle1">
              {CREDIT_SUB_STATUS_TITLE[status]}
            </Typography>
            {status === CREDIT_SUB_STATUS.APPROVED && (
              <Typography display={'inline'} variant="body1">
                {' '}
                but not published.
              </Typography>
            )}
          </Typography>
        </TimelineItem>
      )
    );
  };

  export const RequirementsNeeded = () => (
    <TimelineItem>
      <Typography variant="body1">
        Some requirements are required.{' '}
        <MuiLink component={Link} to={'#credit'} color="info.main">
          View requirements ↗︎
        </MuiLink>
      </Typography>
    </TimelineItem>
  );

  export const PublishedIndicativeLimit = ({
    limit,
    currency,
    publishedAt,
  }: {
    limit: number | undefined;
    currency: string | undefined;
    publishedAt: string | undefined;
  }) =>
    limit &&
    currency &&
    publishedAt && (
      <TimelineItem>
        <Typography variant="body1">
          Indicative credit decision of{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatCurrency(limit, currency)}
          </Typography>{' '}
          is published on{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(publishedAt), 'longdate')}
          </Typography>
          .{' '}
        </Typography>
      </TimelineItem>
    );

  export const CurrentPublishedCreditDecisionActive = ({
    limit,
    currency,
    publishedAt,
  }: {
    limit: number | undefined;
    currency: string | undefined;
    publishedAt: string | undefined;
  }) =>
    limit &&
    currency &&
    publishedAt && (
      <TimelineItem>
        <Typography variant="body1">
          The current credit decision of{' '}
          <Typography display={'inline'} variant="subtitle1">
            {limit && currency && formatCurrency(limit, currency)}
          </Typography>{' '}
          is published on{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(publishedAt), 'longdate')}
          </Typography>
          .{' '}
          <MuiLink component={Link} to={'#credit'}>
            View credit decision ↗︎
          </MuiLink>
        </Typography>
      </TimelineItem>
    );

  export const CreditDecisionInactive = ({
    reason,
    deactivated_at,
  }: {
    reason: string | undefined;
    deactivated_at?: string | null;
  }) =>
    reason &&
    (deactivated_at ? (
      <TimelineItem>
        <Typography variant="body1">
          Limit inactive due to{' '}
          <Typography display={'inline'} variant="subtitle1">
            {toSentenceCase(reason).toLowerCase()}{' '}
          </Typography>
          on{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(deactivated_at), 'longdate')}.
          </Typography>
        </Typography>
      </TimelineItem>
    ) : (
      <TimelineItem>
        <Typography variant="body1">
          Limit inactive due to{' '}
          <Typography display={'inline'} variant="subtitle1">
            {toSentenceCase(reason).toLowerCase()}.
          </Typography>
        </Typography>
      </TimelineItem>
    ));

  export const CreditDecisionExpired = ({
    expirationDate,
  }: {
    expirationDate: string | undefined;
  }) =>
    expirationDate && (
      <TimelineItem>
        <Typography variant="body1">
          The current credit decision{' '}
          <Typography display={'inline'} variant="subtitle1">
            expired{' '}
          </Typography>
          on{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(expirationDate), 'longdate')}
          </Typography>
          .
        </Typography>
      </TimelineItem>
    );

  export const CreditDecisionRejected = ({
    permanent,
    publishedAt,
  }: {
    permanent: boolean | undefined;
    publishedAt: string | undefined;
  }) =>
    !isUndefined(permanent) &&
    publishedAt && (
      <TimelineItem>
        <Typography variant="body1">
          Merchant is{' '}
          <Typography display={'inline'} variant="subtitle1">
            {permanent ? 'permanently' : 'temporarily'} declined{' '}
          </Typography>
          on{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatDate(new Date(publishedAt), 'longdate')}
          </Typography>
          .
        </Typography>
      </TimelineItem>
    );
}

export namespace Alerts {
  export const UpcomingLimitRenewal = ({
    expirationDate,
  }: {
    expirationDate: string | undefined;
  }) =>
    expirationDate ? (
      <TimelineItem alert>
        <Alert
          severity="warning"
          variant="standard"
          sx={{ width: '100%', marginTop: 3 }}>
          <AlertTitle>
            Upcoming limit renewal on{' '}
            {formatDate(new Date(expirationDate), 'longdate')}
          </AlertTitle>
        </Alert>
      </TimelineItem>
    ) : (
      <TimelineItem alert>
        <Alert
          severity="warning"
          variant="standard"
          sx={{ width: '100%', marginTop: 3 }}>
          <AlertTitle>Upcoming limit renewal</AlertTitle>
        </Alert>
      </TimelineItem>
    );
}
