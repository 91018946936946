import { Lager_Treyd_Auth_Profile } from '@/__generated__/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type UserAuthProfile = Pick<
  Lager_Treyd_Auth_Profile,
  'name' | 'identity_verified' | 'id'
>;
interface GetUserDetails {
  lager_treyd_auth_profile: UserAuthProfile[];
}
export const getUserAuthProfile = gql`
  query getUserAuthProfile($userId: Int!) {
    lager_treyd_auth_profile(where: { user_id: { _eq: $userId } }) {
      name
      id
      identity_verified
    }
  }
` as TypedDocumentNode<GetUserDetails, { userId: number }>;
