import { FINANCIAL_FILES_TAG } from '@/constants';
import { useGetCompanyAttachments } from '@/hooks';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';
import { chain, startsWith } from 'lodash';
import { useMemo } from 'react';
import { BalanceSheetSection } from './BalanceSheetSection';
import { BankStatementSection } from './BankStatementSection';
import { ProfitAndLossSection } from './ProfitAndLossSection';
import { SupportingFilesSection } from './SupportingFilesSection';
interface FinancialDocumentsDialogProps {
  onClose: () => void;
  open: boolean;
  orgNo: string;
  country: string;
}

export const FinancialDocumentsDialog = (
  props: FinancialDocumentsDialogProps
) => {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle display="flex" justifyContent="flex-end" alignItems="center">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack maxWidth={960} marginX="auto">
          <DialogBody country={props.country} orgNo={props.orgNo} />
        </Stack>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

const DialogBody = ({ country, orgNo }: { country: string; orgNo: string }) => {
  const { data: attachmentsData, refetch: refetchAttachments } =
    useGetCompanyAttachments({
      orgnr: orgNo,
      country,
    });
  const attachments = attachmentsData?.django?.core_company?.attachments;

  const financialAttachments = useMemo(
    () =>
      chain(attachments)
        .filter((attachment) => startsWith(attachment.tag, FINANCIAL_FILES_TAG))
        .groupBy((attachment) => attachment.tag)
        .value(),
    [attachments]
  );

  return (
    <Stack gap={3}>
      <Typography variant="h5">Financial documents</Typography>
      <Stack gap={5}>
        <BalanceSheetSection
          country={country}
          orgNo={orgNo}
          attachments={financialAttachments}
          onFileUploadComplete={() => refetchAttachments()}
        />
        <Divider />
        <ProfitAndLossSection
          country={country}
          orgNo={orgNo}
          attachments={financialAttachments}
          onFileUploadComplete={() => refetchAttachments()}
        />
        <Divider />
        <SupportingFilesSection
          country={country}
          orgNo={orgNo}
          attachments={financialAttachments}
          onFileUploadComplete={() => refetchAttachments()}
        />
        <Divider />
        <BankStatementSection
          country={country}
          orgNo={orgNo}
          attachments={financialAttachments}
          onFileUploadComplete={() => refetchAttachments()}
        />
      </Stack>
    </Stack>
  );
};
