import { DateRangeField } from '@/components/TableFilter/DateRangeField';
import {
  COMMERCIAL_STATUS,
  COMMERCIAL_STATUS_DISPLAYED_VALUE,
  MERCHANT_COMBINED_STATUS_DISPLAYED_VALUE,
  MERCHANT_SEGMENT,
  MERCHANT_SEGMENTS_DISPLAYED_VALUE,
  ONBOARDING_STATUS,
  ONBOARDING_STATUS_DISPLAYED_VALUE,
  companyStatusFilterOptions,
} from '@/constants';
import { Financier } from '@/schemas';
import { MerchantTableFilters, MerchantTableFiltersFields } from '@/types';
import { getFiltersInitialValues } from '@/utils/TableFilters';
import {
  Autocomplete,
  Badge,
  Button,
  Chip,
  Divider,
  Menu,
  Stack,
  TextField,
  autocompleteClasses,
} from '@mui/material';
import {
  COUNTRIES_OPTIONS,
  invoicingCurrencies,
} from '@treyd-io/core/constants/country';
import {
  FilterComparator,
  QueryFilterField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import FiltersIcon from '@treyd-io/treyd-ui/icons/FiltersIcon';
import { Form, Formik } from 'formik';
import { find, isEmpty, map, toLower, toString, toUpper } from 'lodash';
import { useState } from 'react';
interface TableFiltersProps {
  allFinanciers?: Financier[];
  onSubmit: (filters: QueryFilterField<MerchantTableFiltersFields>[]) => void;
  onReset?: () => void;
  filters?: QueryFilterField<MerchantTableFiltersFields>[];
}

interface CommercialStatuses {
  title: string;
  value: COMMERCIAL_STATUS;
}

interface OnboardingStatuses {
  title: string;
  value: ONBOARDING_STATUS;
}

export const TableFilters = (props: TableFiltersProps) => {
  const { onSubmit, allFinanciers, filters, onReset } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClose = () => setAnchorEl(null);
  const appliedFilterFieldsCount = filters?.length || 0;
  const companySegments = [
    {
      title: MERCHANT_SEGMENTS_DISPLAYED_VALUE[MERCHANT_SEGMENT.NORMAL],
      value: MERCHANT_SEGMENT.NORMAL,
    },
    {
      title: MERCHANT_SEGMENTS_DISPLAYED_VALUE[MERCHANT_SEGMENT.WHITE_GLOVE],
      value: MERCHANT_SEGMENT.WHITE_GLOVE,
    },
  ];

  const commercialStatuses: CommercialStatuses[] = [
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.TREYDING_CUSTOMER],
      value: COMMERCIAL_STATUS.TREYDING_CUSTOMER,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.PROACTIVE_OPPORTUNITY
        ],
      value: COMMERCIAL_STATUS.PROACTIVE_OPPORTUNITY,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.CUSTOMER_AT_RISK],
      value: COMMERCIAL_STATUS.CUSTOMER_AT_RISK,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.CHURNED_NO_UTILIZATION
        ],
      value: COMMERCIAL_STATUS.CHURNED_NO_UTILIZATION,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.UP_FOR_KYC_RENEWAL],
      value: COMMERCIAL_STATUS.UP_FOR_KYC_RENEWAL,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.UP_FOR_LIMIT_RENEWAL_NEED_DATA
        ],
      value: COMMERCIAL_STATUS.UP_FOR_LIMIT_RENEWAL_NEED_DATA,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.UP_FOR_RENEWAL_DATA_AVAILABLE
        ],
      value: COMMERCIAL_STATUS.UP_FOR_RENEWAL_DATA_AVAILABLE,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.CHURNED_LIMIT_EXPIRED
        ],

      value: COMMERCIAL_STATUS.CHURNED_LIMIT_EXPIRED,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.DEACTIVATED_RETAIN],

      value: COMMERCIAL_STATUS.DEACTIVATED_RETAIN,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.RESURRECTION_OPPORTUNITY
        ],

      value: COMMERCIAL_STATUS.RESURRECTION_OPPORTUNITY,
    },
    {
      title: COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.OVERDUE],
      value: COMMERCIAL_STATUS.OVERDUE,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.DEACTIVATED_AVOID],
      value: COMMERCIAL_STATUS.DEACTIVATED_AVOID,
    },
    {
      title: COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.DELETED],
      value: COMMERCIAL_STATUS.DELETED,
    },
    {
      title: COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.REJECTED],
      value: COMMERCIAL_STATUS.REJECTED,
    },
    {
      title:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.COMPLIANCE_PENDING],
      value: COMMERCIAL_STATUS.COMPLIANCE_PENDING,
    },
  ];
  const onboardingStatuses: OnboardingStatuses[] = [
    {
      title: ONBOARDING_STATUS_DISPLAYED_VALUE[ONBOARDING_STATUS.DISCOVERY],
      value: ONBOARDING_STATUS.DISCOVERY,
    },
    {
      title: ONBOARDING_STATUS_DISPLAYED_VALUE[ONBOARDING_STATUS.UNDERWRITING],
      value: ONBOARDING_STATUS.UNDERWRITING,
    },
    {
      title:
        ONBOARDING_STATUS_DISPLAYED_VALUE[
          ONBOARDING_STATUS.INDICATIVE_LIMIT_APPROVED
        ],
      value: ONBOARDING_STATUS.INDICATIVE_LIMIT_APPROVED,
    },
    {
      title:
        ONBOARDING_STATUS_DISPLAYED_VALUE[ONBOARDING_STATUS.READY_TO_TREYD],
      value: ONBOARDING_STATUS.READY_TO_TREYD,
    },
    {
      title: ONBOARDING_STATUS_DISPLAYED_VALUE[ONBOARDING_STATUS.CLOSED_WON],
      value: ONBOARDING_STATUS.CLOSED_WON,
    },
    {
      title: ONBOARDING_STATUS_DISPLAYED_VALUE[ONBOARDING_STATUS.CLOSED_LOST],
      value: ONBOARDING_STATUS.CLOSED_LOST,
    },
  ];

  const merchantCombinedStatuses = [
    ...commercialStatuses,
    ...onboardingStatuses,
  ];

  const initialValues = getFiltersInitialValues<MerchantTableFilters>(filters, {
    joiningDateStart: toString,
    joiningDateEnd: toString,
    statuses: (statuses) => statuses || [],
    financiers: (financiers) => financiers || [],
    countries: (countries) => countries || [],
    currencies: (currencies) => currencies || [],
    segments: (segments) => segments || [],
    merchantStatuses: (merchantStatuses) => merchantStatuses || [],
  });

  const handleFormSubmit = (values: MerchantTableFilters) => {
    const {
      joiningDateStart,
      joiningDateEnd,
      statuses,
      financiers,
      currencies,
      countries,
      segments,
      merchantStatuses,
    } = values;
    const filters: QueryFilterField<MerchantTableFiltersFields>[] = [];

    if (joiningDateStart) {
      filters.push({
        key: 'joiningDateStart',
        type: 'filter',
        name: 'created_at',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: joiningDateStart,
      });
    }

    if (joiningDateEnd) {
      filters.push({
        key: 'joiningDateEnd',
        type: 'filter',
        name: 'created_at',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: joiningDateEnd,
      });
    }

    if (!isEmpty(statuses)) {
      filters.push({
        key: 'statuses',
        type: 'filter',
        name: 'is_active',
        comparator: FilterComparator.IN,
        comparisonValue: statuses,
      });
    }
    if (!isEmpty(financiers)) {
      filters.push({
        key: 'financiers',
        type: 'filter',
        name: 'financier_id',
        comparator: FilterComparator.IN,
        comparisonValue: financiers,
      });
    }
    if (!isEmpty(segments)) {
      filters.push({
        key: 'segments',
        type: 'filter',
        name: 'segment',
        comparator: FilterComparator.IN,
        comparisonValue: segments,
      });
    }

    if (!isEmpty(countries)) {
      filters.push({
        key: 'countries',
        type: 'filter',
        name: 'country',
        comparator: FilterComparator.IN,
        comparisonValue: countries,
      });
    }

    if (!isEmpty(currencies)) {
      filters.push({
        key: 'currencies',
        type: 'filter',
        name: 'limit_currency',
        comparator: FilterComparator.IN,
        comparisonValue: map(currencies, toLower),
      });
    }
    if (!isEmpty(merchantStatuses)) {
      filters.push({
        key: 'merchantStatuses',
        type: 'filter',
        name: 'active_combined_status__status',
        comparator: FilterComparator.IN,
        comparisonValue: merchantStatuses,
      });
    }

    onSubmit(filters);
    handleMenuClose();
  };

  return (
    <>
      <Badge
        badgeContent={appliedFilterFieldsCount}
        color="primary"
        sx={{ width: '100%' }}>
        <Button
          startIcon={<FiltersIcon />}
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          Filters
        </Button>
      </Badge>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormSubmit}>
        {(formik) => {
          return (
            <Menu
              keepMounted
              disablePortal
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => formik.handleSubmit()}
              sx={{}}
              slotProps={{
                paper: {
                  elevation: 2,
                  sx: {
                    padding: 2,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: 420,
                    backgroundColor: 'background.default',
                  },
                },
              }}>
              <Form
                noValidate
                tabIndex={-1}
                onKeyDown={(e) => e.stopPropagation()}>
                <Row tabIndex={-1}>
                  <Col xs={12}>
                    <DateRangeField name="joiningDate" label="Joining date" />
                  </Col>
                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(
                        companyStatusFilterOptions,
                        (statusOption) => statusOption.value
                      )}
                      value={formik.values?.statuses}
                      getOptionLabel={(option) =>
                        find(
                          companyStatusFilterOptions,
                          (statusOption) => statusOption.value === option
                        )?.label || ''
                      }
                      onChange={(_, statuses) =>
                        formik.setFieldValue('statuses', statuses)
                      }
                      renderTags={(statuses, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(statuses, (option, index) => (
                            <Chip
                              label={
                                find(
                                  companyStatusFilterOptions,
                                  (statusOption) =>
                                    statusOption.value === option
                                )?.label
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Statuses"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(allFinanciers, (financier) => financier.id)}
                      value={formik.values?.financiers}
                      getOptionLabel={(option) =>
                        find(
                          allFinanciers,
                          (financier) => financier.id === option
                        )?.name || ''
                      }
                      onChange={(_, financiers) =>
                        formik.setFieldValue('financiers', financiers)
                      }
                      renderTags={(financiers, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(financiers, (option, index) => (
                            <Chip
                              label={
                                find(
                                  allFinanciers,
                                  (financier) => financier.id === option
                                )?.name
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Financier"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(
                        COUNTRIES_OPTIONS,
                        (countryOption) => countryOption.value
                      )}
                      value={formik.values?.countries}
                      getOptionLabel={(option) =>
                        find(
                          COUNTRIES_OPTIONS,
                          (countryOption) => countryOption.value === option
                        )?.title || ''
                      }
                      onChange={(_, countries) =>
                        formik.setFieldValue('countries', countries)
                      }
                      renderTags={(countries, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(countries, (option, index) => (
                            <Chip
                              label={
                                find(
                                  COUNTRIES_OPTIONS,
                                  (statusOption) =>
                                    statusOption.value === option
                                )?.title
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Countries"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={invoicingCurrencies}
                      value={map(formik.values?.currencies, toUpper)}
                      onChange={(_, currencies) =>
                        formik.setFieldValue('currencies', currencies)
                      }
                      renderTags={(currencies, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(currencies, (option, index) => (
                            <Chip label={option} {...getTagProps({ index })} />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Limit Currency"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(companySegments, (segment) => segment.value)}
                      value={formik.values?.segments}
                      getOptionLabel={(option) =>
                        find(
                          companySegments,
                          (segment) => segment.value === option
                        )?.title || ''
                      }
                      onChange={(_, segments) =>
                        formik.setFieldValue('segments', segments)
                      }
                      renderTags={(segments, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(segments, (option, index) => (
                            <Chip
                              label={MERCHANT_SEGMENTS_DISPLAYED_VALUE[option]}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Segments"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(
                        merchantCombinedStatuses,
                        (status) => status.value
                      )}
                      value={formik.values?.merchantStatuses}
                      getOptionLabel={(option) =>
                        find(
                          merchantCombinedStatuses,
                          (status) => status.value === option
                        )?.title || ''
                      }
                      onChange={(_, merchantStatuses) =>
                        formik.setFieldValue(
                          'merchantStatuses',
                          merchantStatuses
                        )
                      }
                      renderTags={(merchantStatuses, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(merchantStatuses, (option, index) => (
                            <Chip
                              label={
                                MERCHANT_COMBINED_STATUS_DISPLAYED_VALUE[option]
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Merchant statuses"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} gap={1} display="flex">
                    <Button
                      fullWidth
                      onClick={() => {
                        onReset?.();
                      }}
                      variant="outlined"
                      type="button"
                      color="secondary">
                      Reset
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary">
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Menu>
          );
        }}
      </Formik>
    </>
  );
};
