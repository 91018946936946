import { toString } from 'lodash';

export namespace UserService {
  export const getDisplayedName = (user?: {
    first_name?: string | null;
    last_name?: string | null;
  }) => {
    const firstName = toString(user?.first_name).trim();
    const lastName = toString(user?.last_name).trim();
    return `${firstName} ${lastName}`.trim();
  };
}
