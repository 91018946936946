import { ERP_INVOICE_PDF_TYPES } from '@/constants';
import { useGetErpInvoicePdf } from '@/hooks/erps/useGetErpInvoicePdf';
import { Drawer, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEmpty, toString } from 'lodash';
import { ComponentLoader } from './ComponentLoader';
export const ERPInvoicePDFDrawer = ({
  isOpen,
  toggle,
  uuid,
  type,
}: {
  isOpen: boolean;
  toggle: (nextValue?: any) => void;
  uuid: string;
  type: ERP_INVOICE_PDF_TYPES;
}) => {
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('md'));
  const { data, isPending } = useGetErpInvoicePdf({ type, uuid });

  const invoiceUrl = data?.data.signed_url;

  if (isSmallScreens) return null;

  return (
    <Drawer
      PaperProps={{
        sx: {
          display: !isOpen ? 'none' : 'flex',
          width: isOpen ? '40%' : 0,
        },
      }}
      sx={{
        maxWidth: isOpen ? '40%' : 0,
        width: '100%',
      }}
      anchor="right"
      onClose={toggle}
      open={isOpen}>
      <PdfContainer invoiceUrl={invoiceUrl} loading={isPending} />
    </Drawer>
  );
};

const PdfContainer = ({
  invoiceUrl,
  loading,
}: {
  invoiceUrl?: string;
  loading: boolean;
}) => {
  if (loading) return <ComponentLoader />;
  if (isEmpty(invoiceUrl) && !loading)
    return (
      <Stack height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <Typography variant="h6" align="center">
          No invoice PDF found
        </Typography>
      </Stack>
    );
  return (
    <iframe
      key={invoiceUrl}
      src={toString(invoiceUrl)}
      title="Supplier invoice"
      style={{ width: '100%', height: '100%', border: 'none' }}
    />
  );
};
