import { InvoiceOpsStatusChip, PayoutStatusChip } from '@/components';
import { INVOICE_TYPE } from '@/constants';
import { useGetOrderInvoices } from '@/hooks';
import Order, { BankChargesType } from '@/pages/orderReview/models/Order';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import InformationIcon from '@treyd-io/treyd-ui/icons/InformationIcon';
import {
  find,
  first,
  includes,
  isEmpty,
  toNumber,
  toString,
  toUpper,
} from 'lodash';
import { Link } from 'react-router-dom';

export const Payout = ({ order }: { order: Order }) => {
  const payoutAmount = order?.payouts[0]?.amount;
  const payoutCurrency = order?.payouts[0]?.currency;
  const payoutStatus = order?.payouts[0]?.status;
  const invoicingCurrency = order?.invoicing_currency;

  const payoutDataExists =
    !isEmpty(order?.payouts) && !!payoutAmount && !!payoutCurrency;

  const { data } = useGetOrderInvoices(order.id);
  const invoice = find(data, (invoice) =>
    [INVOICE_TYPE.DEBTOR, INVOICE_TYPE.ACQUIRING].includes(invoice.invoice_type)
  );

  const feesLineItem = find(
    first(order?.payouts)?.line_items,
    (lineItem) => lineItem.tag === 'fees'
  );
  const feesLineItemsDiscount = feesLineItem?.discount;
  const bankChargesLineItem = find(
    first(order?.payouts)?.line_items,
    (lineItem) => lineItem.tag === 'bank_charges'
  );
  const bankChargesAmount = toNumber(
    bankChargesLineItem?.total_repayment_amount_in_invoicing_currency
  );
  const showBankChargeType = includes(
    ['OUR', 'SHA'],
    order?.payouts?.[0]?.bank_charges_type
  );
  const bankChargeRule: Partial<
    Record<BankChargesType, { title: string; description: string }>
  > = {
    SHA: {
      title: 'Shared (SHA)',
      description:
        'The supplier may receive less than the sent amount due to deduction of intermediary bank charges from the payout.',
    },
    OUR: {
      title: 'Us (OUR)',
      description:
        'The customer cover the intermediary bank charges so the supplier should receive the full amount.',
    },
  };

  return (
    <Row spacing={3}>
      <Col xs={12} md={6}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
          gap={1}
          flexWrap="wrap">
          <Stack direction="row" gap={1.5} alignItems="center">
            <Typography variant="h6">Payout</Typography>
            <PayoutStatusChip status={payoutStatus} size="small" />
          </Stack>
          <Typography
            component={Link}
            to="#payment_tracking"
            color="info.main"
            variant="body1">
            Payment tracking ↗
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Row>
            <Col>
              <Typography variant="body1" color={'text.secondary'}>
                Amount
              </Typography>
            </Col>
            <Col xs zeroMinWidth>
              <Typography variant="body1" textAlign={'right'} noWrap>
                {payoutDataExists
                  ? formatCurrency(payoutAmount, payoutCurrency)
                  : '-'}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography variant="body1" color={'text.secondary'}>
                FX rate
              </Typography>
            </Col>
            <Col xs zeroMinWidth>
              <Typography variant="body1" textAlign={'right'} noWrap>
                {`${
                  order?.payouts[0]?.fx_rate_to_invoicing_currency?.toFixed(
                    2
                  ) || '-'
                } (${toUpper(order?.supplier_invoice.currency)} → ${toUpper(
                  order?.invoicing_currency
                )})`}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography variant="body1" color={'text.secondary'}>
                Desired payment date
              </Typography>
            </Col>
            <Col xs zeroMinWidth>
              <Typography variant="body1" textAlign={'right'} noWrap>
                {order?.payouts[0]?.expected_payment_date
                  ? formatDate(
                      new Date(order?.payouts[0]?.expected_payment_date),
                      'longdate'
                    )
                  : '-'}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography variant="body1" color={'text.secondary'}>
                Actual payment date
              </Typography>
            </Col>
            <Col xs zeroMinWidth>
              <Typography variant="body1" textAlign={'right'} noWrap>
                {order?.payouts[0]?.actual_payment_date
                  ? formatDate(
                      new Date(order?.payouts[0]?.actual_payment_date),
                      'longdate'
                    )
                  : '-'}
              </Typography>
            </Col>
          </Row>
        </Stack>
      </Col>

      <Col xs={12} md={6}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
          gap={1}
          flexWrap="wrap">
          <Stack direction="row" gap={1.5} alignItems="center">
            <Typography variant="h6">Repayment</Typography>
            {invoice && (
              <InvoiceOpsStatusChip status={invoice.status} size="small" />
            )}
          </Stack>
          <Typography
            component={Link}
            to="#invoices"
            color="info.main"
            variant="body1">
            Invoices ↗
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Row>
            <Col>
              <Typography variant="body1" color={'text.secondary'}>
                Fee
              </Typography>
            </Col>
            <Col xs zeroMinWidth>
              {feesLineItemsDiscount ? (
                <Stack flexDirection={'column'} textAlign={'right'}>
                  <Typography variant="body1" noWrap>
                    {formatCurrency(
                      toNumber(
                        feesLineItem?.total_repayment_amount_in_invoicing_currency
                      ),
                      toUpper(order?.invoicing_currency)
                    )}
                  </Typography>
                  <Typography
                    component={'del'}
                    variant="caption"
                    color="text.secondary"
                    noWrap>
                    {formatCurrency(
                      toNumber(order?.fees_amount_in_invoicing_currency || 0),
                      toUpper(order?.invoicing_currency)
                    )}{' '}
                    {`(${order?.payouts[0]?.fees_rate?.toFixed(2)}%)`}
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="body1" textAlign={'right'}>
                  {formatCurrency(
                    toNumber(order?.fees_amount_in_invoicing_currency || 0),
                    toUpper(order?.invoicing_currency)
                  )}{' '}
                  {`(${order?.payouts[0]?.fees_rate?.toFixed(2)}%)`}
                </Typography>
              )}
            </Col>
          </Row>
          {Boolean(bankChargesAmount) && (
            <Row>
              <Col>
                <Typography variant="body1" color={'text.secondary'}>
                  Bank charges amount
                </Typography>
              </Col>
              <Col xs zeroMinWidth>
                <Typography variant="body1" textAlign={'right'} noWrap>
                  {formatCurrency(
                    bankChargesAmount,
                    toString(invoicingCurrency)
                  )}
                </Typography>
              </Col>
            </Row>
          )}
          {showBankChargeType && (
            <Row>
              <Col>
                <Typography variant="body1" color={'text.secondary'}>
                  Bank charges type
                </Typography>
              </Col>
              <Col xs zeroMinWidth>
                <Box display={'flex'} justifyContent={'end'}>
                  <Typography variant="body1" textAlign={'right'} noWrap>
                    {
                      bankChargeRule[order?.payouts?.[0]?.bank_charges_type]
                        ?.title
                    }
                  </Typography>
                  <Tooltip
                    title={
                      bankChargeRule[order?.payouts?.[0]?.bank_charges_type]
                        ?.description
                    }
                    placement="top"
                    arrow>
                    <Box display={'flex'} justifyContent={'center'}>
                      <InformationIcon color="primary" />
                    </Box>
                  </Tooltip>
                </Box>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={6}>
              <Typography variant="body1" color={'text.secondary'}>
                Repayment amount
              </Typography>
            </Col>
            <Col xs={6}>
              <Typography variant="body1" textAlign={'right'} noWrap>
                {formatCurrency(
                  order?.payouts[0]
                    ?.total_repayment_amount_in_invoicing_currency || 0,
                  toUpper(order?.invoicing_currency)
                )}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography variant="body1" color={'text.secondary'}>
                Expected repayment date
              </Typography>
            </Col>
            <Col xs zeroMinWidth>
              <Typography variant="body1" textAlign={'right'} noWrap>
                {order?.payouts[0]?.expected_repayment_date
                  ? formatDate(
                      new Date(order?.payouts[0]?.expected_repayment_date),
                      'longdate'
                    )
                  : '-'}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Typography variant="body1" color={'text.secondary'}>
                Tenor
              </Typography>
            </Col>
            <Col xs={6}>
              <Typography variant="body1" textAlign={'right'}>
                {order?.payouts[0]?.payment_term.tenor_in_months * 30} days
              </Typography>
            </Col>
          </Row>
        </Stack>
      </Col>
    </Row>
  );
};
