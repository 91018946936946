import { useQuery } from '@apollo/client';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';

import { getMerchantDetails } from '@/schemas';
import { isEmpty, toNumber, toString } from 'lodash';

import { useGetRemainingLimit, useUpdateMerchantSegment } from '@/hooks';
import { MerchantService } from '@/services';

export const useMerchant = (id: string) => {
  const {
    data,
    loading,
    refetch: refetchMerchantDetails,
  } = useQuery(getMerchantDetails, { variables: { id } });

  const { mutate: updateMerchantSegment } = useUpdateMerchantSegment();
  const { data: remainingLimitResponse, isPending: isRemainingLimitLoading } =
    useGetRemainingLimit(toNumber(id));

  const name = data?.orders_companies_by_pk.name;
  const orgNo = data?.orders_companies_by_pk.org_no;
  const segment = data?.orders_companies_by_pk?.segment;
  const country = data?.orders_companies_by_pk.country;
  const financier = data?.orders_companies_by_pk?.financier?.name;
  const joined = data?.orders_companies_by_pk?.created_at;
  const orders_count =
    data?.orders_companies_by_pk?.orders_count.aggregate?.count;
  const rating = data?.orders_companies_by_pk?.rating;
  const website = data?.orders_companies_by_pk?.website;
  const description = data?.orders_companies_by_pk?.core_company?.description;
  const macroSector =
    data?.orders_companies_by_pk?.core_company?.core_companyindustry
      ?.core_industrymacrosector?.name;
  const microSector =
    data?.orders_companies_by_pk?.core_company?.core_companyindustry
      ?.core_industrymicrosector?.name;
  const address = `${data?.orders_companies_by_pk?.address || ''}, ${
    data?.orders_companies_by_pk?.zip_code || ''
  } ${data?.orders_companies_by_pk?.town || ''}`;
  const status = data?.orders_companies_by_pk?.active_combined_status?.status;
  const statusLabel =
    data?.orders_companies_by_pk?.active_combined_status?.label;

  const incorporated_at = data?.orders_companies_by_pk?.core_company
    ?.registration_date
    ? formatDate(
        new Date(data?.orders_companies_by_pk?.core_company?.registration_date),
        'date'
      )
    : '';
  const company_users = data?.orders_companies_by_pk?.company_users;
  const salesRepresentativeId =
    data?.orders_companies_by_pk?.sales_representative_id;
  const kyc_submit_date =
    data?.orders_companies_by_pk?.core_company?.risk_kycs?.[0]?.created;
  const kyc = data?.orders_companies_by_pk?.kyc
    ? { ...data?.orders_companies_by_pk?.kyc, submitted_at: kyc_submit_date }
    : undefined;
  const erp_system = data?.orders_companies_by_pk?.erp_system;
  const isCompanyActive = data?.orders_companies_by_pk?.is_active;
  const isFundingSecured = data?.orders_companies_by_pk?.is_funding_secured;
  const isCreditLimitDeactivated =
    data?.orders_companies_by_pk?.is_funding_secured === false ||
    !data?.orders_companies_by_pk?.is_active;
  const hasOverdueOrders = Boolean(data?.orders_companies_by_pk?.has_overdue);
  const hasRepaymentPlan = Boolean(
    data?.orders_companies_by_pk?.has_repayment_plan
  );
  const isKYCRevoked =
    (data?.orders_companies_by_pk?.limit === 0 &&
      data?.orders_companies_by_pk?.kyc?.status === 'draft') ||
    data?.orders_companies_by_pk?.kyc?.revoked_at;
  const isLimitBlocked =
    (hasOverdueOrders && !hasRepaymentPlan) || isKYCRevoked;

  const hubspotId = toString(data?.orders_companies_by_pk?.hubspot_vid);
  const isManuallyActive = data?.orders_companies_by_pk?.is_active;
  const discount = data?.orders_companies_by_pk?.monthly_interest_deduction;
  const mainContactId = data?.orders_companies_by_pk?.main_contact_id;

  const desiredLimit = toNumber(data?.orders_companies_by_pk?.desired_limit);
  const remainingLimit = toNumber(remainingLimitResponse?.available_limit);
  const outstandingCredit = toNumber(
    remainingLimitResponse?.outstanding_credit
  );
  const limit = remainingLimitResponse?.company_limit;
  const limitCurrency = toString(remainingLimitResponse?.limit_currency);

  const formattedCompanyLimit = formatCurrency(toNumber(limit), limitCurrency);

  const formattedDesiredLimit = formatCurrency(
    desiredLimit,
    toString(data?.orders_companies_by_pk?.desired_limit_currency)
  );

  const formattedRemainingLimit = formatCurrency(remainingLimit, limitCurrency);
  const formattedOutstandingCredit = formatCurrency(
    outstandingCredit,
    limitCurrency
  );
  const creditDeactivatedReason = toString(
    data?.orders_companies_by_pk?.core_company?.latest_published_credit_decision
      ?.deactivation_reason
  );

  const invoicingCurrency = data?.orders_companies_by_pk?.invoicing_currency;
  const isLoading = loading || isRemainingLimitLoading;
  const isMerchantDeleted = MerchantService.isMerchantDeleted(orgNo);
  const isMerchantFirstOrder = isEmpty(
    data?.orders_companies_by_pk.first_paid_order
  );

  const coreCompany = data?.orders_companies_by_pk?.core_company;
  return {
    states: {
      name,
      orgNo,
      segment,
      country,
      financier,
      joined,
      orders_count,
      rating,
      limit,
      status,
      formattedCompanyLimit,
      loading: isLoading,
      website,
      description,
      address,
      incorporated_at,
      company_users,
      kyc,
      erp_system,
      isCompanyActive,
      isFundingSecured,
      isCreditLimitDeactivated,
      isLimitBlocked,
      hubspotId,
      isManuallyActive,
      desiredLimit,
      formattedDesiredLimit,
      formattedRemainingLimit,
      outstandingCredit,
      formattedOutstandingCredit,
      discount,
      creditDeactivatedReason,
      salesRepresentativeId,
      mainContactId,
      invoicingCurrency,
      isMerchantDeleted,
      isMerchantFirstOrder,
      statusLabel,
      macroSector,
      microSector,
      coreCompany,
    },
    actions: {
      refetchMerchantDetails,
      updateMerchantSegment,
    },
  };
};
