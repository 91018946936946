import { useSubmitBankAccountReview } from '@/hooks';
import { BankAccountCompliance, BankAccountReviewPayload } from '@/types';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ResponsiveButton from '@treyd-io/treyd-ui/components/ResponsiveButton';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';
import UserCheckIcon from '@treyd-io/treyd-ui/icons/UserCheckIcon';
import { Can } from 'context';
import { Formik } from 'formik';
import { find, map, toString } from 'lodash';
import { useToggle } from 'react-use';
import * as yup from 'yup';

const decisionOptions = [
  {
    label: 'Approve',
    value: true,
  },
  {
    label: 'Reject',
    value: false,
  },
];

const VALIDATION_SCHEMA = yup.object().shape({
  approved: yup.string().required('Decision is required.'),
});

export const ReviewBankAccountDialog = ({
  bankAccountId,
  bankAccountCompliance,
  onSubmit,
}: {
  bankAccountId: number;
  onSubmit: () => void;
  bankAccountCompliance: BankAccountCompliance;
}) => {
  const [openReviewDialog, toggleReviewDialog] = useToggle(false);

  const { mutate: submitBankAccountReview, isPending } =
    useSubmitBankAccountReview();

  const handleSubmitBankAccountReview = (payload: BankAccountReviewPayload) => {
    submitBankAccountReview(
      {
        bankAccountId,
        payload,
      },
      {
        onSuccess: () => {
          onSubmit();
          toggleReviewDialog();
        },
      }
    );
  };

  return (
    <Can I="edit" an="bank_account_review">
      <ResponsiveButton
        onClick={toggleReviewDialog}
        color="primary"
        buttonProps={{
          children: 'Review',
          startIcon: <UserCheckIcon />,
        }}
        iconButtonProps={{
          children: <UserCheckIcon />,
        }}
      />
      <Dialog
        open={openReviewDialog}
        onClose={toggleReviewDialog}
        fullWidth
        maxWidth="xs">
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" noWrap>
              Review bank account
            </Typography>
            <IconButton onClick={toggleReviewDialog}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Formik
          initialValues={{
            approved: Boolean(bankAccountCompliance?.approved),
            reason: toString(bankAccountCompliance?.reason),
          }}
          validationSchema={VALIDATION_SCHEMA}
          enableReinitialize
          validateOnMount
          onSubmit={handleSubmitBankAccountReview}>
          {(formik) => {
            return (
              <Stack
                component="form"
                display="contents"
                onSubmit={formik.handleSubmit}>
                <DialogContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    paddingBlockStart: 1,
                  }}>
                  <Autocomplete
                    fullWidth
                    options={map(decisionOptions, (decision) => decision.value)}
                    value={formik.values?.approved}
                    getOptionLabel={(option) =>
                      find(
                        decisionOptions,
                        (decision) => decision.value === option
                      )?.label || ''
                    }
                    onChange={(_, approved) =>
                      formik.setFieldValue('approved', approved)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Decision"
                        error={
                          formik.touched.approved &&
                          Boolean(formik.errors.approved)
                        }
                        helperText={
                          formik.touched.approved && formik.errors.approved
                        }
                      />
                    )}
                  />
                  <TextField
                    name="reason"
                    value={formik.values?.reason}
                    onChange={formik.handleChange}
                    label="Reason (optional)"
                    multiline
                    rows={4}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={toggleReviewDialog}
                    size="large">
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    size="large"
                    loading={isPending}
                    disabled={!formik.isValid}>
                    Save
                  </LoadingButton>
                </DialogActions>
              </Stack>
            );
          }}
        </Formik>
      </Dialog>
    </Can>
  );
};
