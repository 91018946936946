import { OrderAttachment } from '@/types';
import {
  Box,
  Link as MuiLink,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import { Link } from 'react-router-dom';

export const PaymentDetailsSection = ({
  orderAmount,
  payoutProofOfPayment,
}: {
  orderAmount: string;
  payoutProofOfPayment: OrderAttachment | null;
}) => {
  const theme = useTheme();
  return (
    <Box
      borderRadius={4}
      border={`${theme.spacing(0.125)} solid`}
      borderColor={theme.palette.divider}>
      <Stack direction="column" gap={3} margin={4}>
        <Typography variant="h6">{'Payment details'}</Typography>
        <Row>
          <Col xs={12} sm={6} md={3}>
            <TwoLines label={'Amount'} content={orderAmount} />
          </Col>
          {payoutProofOfPayment && (
            <Col xs={12} sm>
              <TwoLines
                label={'Proof of payment'}
                content={
                  <Typography variant="body1" color={'text.disabled'}>
                    <MuiLink
                      to={payoutProofOfPayment.url}
                      component={Link}
                      target="_blank"
                      style={{
                        textDecoration: 'none',
                      }}>
                      <Typography
                        color={'info.main'}
                        borderBottom={1}
                        display="inline">
                        Payout confirmation ↗
                      </Typography>
                    </MuiLink>
                  </Typography>
                }
              />
            </Col>
          )}
        </Row>
      </Stack>
    </Box>
  );
};
