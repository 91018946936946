import { SupplierCompliance } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';

type UpdateSupplierKycMutationType = {
  kyc: SupplierCompliance.SupplierKyc;
};

type UpdateSupplierKycMutationVariablesType = {
  id: number;
  supplier_data: {
    kyc: SupplierCompliance.SupplierKyc;
  };
};

export const updateSupplierKycMutation = gql`
  mutation updateSupplierKycMutation(
    $id: bigint!
    $supplier_data: orders_suppliers_set_input!
  ) {
    update_orders_suppliers_by_pk(
      pk_columns: { id: $id }
      _set: $supplier_data
    ) {
      kyc
    }
  }
` as TypedDocumentNode<
  UpdateSupplierKycMutationType,
  UpdateSupplierKycMutationVariablesType
>;
