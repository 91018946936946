import { Orders_Bank_Accounts } from '@/__generated__/graphql';
import {
  SIMILAR_BANK_ACCOUNTS,
  SUPPLIER_MONITORING,
  SUPPLIER_REVIEW,
} from '@/constants';
import { SubmitSupplierMonitoringPayload, SupplierCompliance } from '@/types';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { get, save } from '@treyd-io/core/utils/request';

export const submitSupplierKyc = async ({
  supplierId,
  payload,
}: {
  supplierId: number;
  payload: SupplierCompliance.SubmitSupplierKycPayload;
}) =>
  save<SupplierCompliance.SupplierKyc>(
    SUPPLIER_REVIEW(supplierId),
    payload,
    REQUEST_METHOD.POST
  );

export const getSimilarBankAccounts = (bankAccountId: number) =>
  get<Orders_Bank_Accounts[]>(SIMILAR_BANK_ACCOUNTS(bankAccountId));

export const submitSupplierMonitoring = async ({
  supplierId,
  payload,
}: {
  supplierId: number;
  payload: SubmitSupplierMonitoringPayload;
}) => save(SUPPLIER_MONITORING(supplierId), payload, REQUEST_METHOD.POST);
