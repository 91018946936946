import { useGetNotes, useGetRemainingLimit } from '@/hooks';
import { CONTRACTOR_TYPE } from '@/pages/orderReview/OrderReviewDetails/OrderContractor/types';
import Order from '@/pages/orderReview/models/Order';
import {
  SUPPLIER_KYC_RESULT,
  SupplierKyc,
} from '@/pages/orderReview/models/Supplier';
import { CircularProgress, Divider, Stack } from '@mui/material';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toNumber, toString } from 'lodash';

import { useGetCompanySupplier, useGetMerchant } from '@/hooks';
import { OrderContractor } from './OrderContractor';

const OrderContractors = ({ deal }: { deal: Order }) => {
  const { data: companySupplier } = useGetCompanySupplier({
    supplierId: deal?.exporter_id,
    companyId: deal?.importer_id,
  });
  const { data: merchant } = useGetMerchant({
    merchantId: deal?.importer_id,
  });

  const { data: remainingLimitResponse, isPending: isRemainingLimitLoading } =
    useGetRemainingLimit(toNumber(deal?.importer_id));
  const { data: supplierNotesData, loading: getSupplierNotesLoading } =
    useGetNotes({
      entity_id: toNumber(deal?.exporter_id),
      entity_type: 'supplier',
    });
  const { data: merchantNotesData, loading: getMerchantNotesLoading } =
    useGetNotes({
      entity_id: toNumber(deal?.importer_id),
      entity_type: 'merchant',
    });

  const merchantNote = merchantNotesData?.orders_orders_app_notes?.[0]?.note;
  const supplierNote = supplierNotesData?.orders_orders_app_notes?.[0]?.note;
  const orderMerchantData = merchant?.orders_companies_by_pk;
  const orderSupplierData = companySupplier?.orders_suppliers_by_pk;

  const supplierKyc: SupplierKyc = orderSupplierData?.kyc;
  const supplierOrdersWithMerchantCount: number | undefined =
    companySupplier?.orders_suppliers_by_pk?.orders_aggregate?.aggregate?.count;

  if (
    isRemainingLimitLoading ||
    getSupplierNotesLoading ||
    getMerchantNotesLoading
  )
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  return (
    <Stack gap={5}>
      <OrderContractor
        supplierId={deal?.exporter_id}
        merchantId={deal?.importer_id}
        name={orderSupplierData?.name as string}
        type={CONTRACTOR_TYPE.SUPPLIER}
        supplierNote={supplierNote || ''}
        isApproved={
          supplierKyc?.screeningResult === SUPPLIER_KYC_RESULT.APPROVED
        }
        riskLevel={supplierKyc?.data?.riskLevel}
        date={
          supplierKyc?.screeningResultDate
            ? new Date(supplierKyc?.screeningResultDate)
            : undefined
        }
        ordersCount={supplierOrdersWithMerchantCount}
      />
      <Divider />
      <OrderContractor
        supplierId={deal?.exporter_id}
        merchantId={deal?.importer_id}
        merchantUid={orderMerchantData?.core_company?.uid}
        name={orderMerchantData?.name as string}
        type={CONTRACTOR_TYPE.MERCHANT}
        merchantNote={merchantNote || ''}
        date={
          orderMerchantData?.created_at &&
          new Date(orderMerchantData?.created_at)
        }
        rating={orderMerchantData?.rating}
        remainingCredit={formatCurrency(
          toNumber(remainingLimitResponse?.available_limit),
          toString(remainingLimitResponse?.limit_currency)
        )}
      />
    </Stack>
  );
};

export default OrderContractors;
