import { gql, TypedDocumentNode } from '@apollo/client';

export type DeactivateMerchantBankAccountMutationType = {
  django: {
    deactivate_bank_account: {
      deactivated_at: string;
    };
  };
};

export type DeactivateMerchantBankAccountMutationVariablesType = {
  companyId: number;
  bankAccountId: number;
};

export const deactivateMerchantBankAccountMutation = gql`
  mutation deactivateMerchantBankAccountMutation(
    $companyId: Int!
    $bankAccountId: Int!
  ) {
    django {
      deactivate_bank_account(
        company_id: $companyId
        bank_account_id: $bankAccountId
      ) {
        deactivated_at
      }
    }
  }
` as TypedDocumentNode<
  DeactivateMerchantBankAccountMutationType,
  DeactivateMerchantBankAccountMutationVariablesType
>;
