import { useGetFeatureFlags } from '@/hooks';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import EditIcon from '@treyd-io/treyd-ui/icons/EditIcon';
import LockUnlockedIcon from '@treyd-io/treyd-ui/icons/LockUnlockedIcon';
import { find } from 'lodash';

export const PreviewKycHead = ({
  submittedAt,
  onRevokeClick,
  onEditClick,
  submittedBy,
  version,
}: {
  onRevokeClick?: () => void;
  onEditClick?: () => void;
  submittedAt?: string;
  submittedBy?: string;
  version?: number;
}) => {
  const { data, loading } = useGetFeatureFlags();
  const featureFlags = data?.orders_orders_app_companyfeatureflag;
  const isKycV3Enabled = find(
    featureFlags,
    ({ name }) => name === 'kyc_version_3'
  )?.enabled;

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="h5" color="text.primary">
            Know Your Customer (KYC)
          </Typography>
          <Chip label={`V.${version}`} size="small" />
        </Stack>
        <Stack direction="row" gap={2}>
          {isKycV3Enabled && onEditClick && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<EditIcon />}
              disabled={loading}
              onClick={onEditClick}>
              Edit KYC
            </Button>
          )}
          {onRevokeClick && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<LockUnlockedIcon />}
              onClick={onRevokeClick}>
              Revoke KYC
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack gap={6} direction="row">
        {submittedAt && (
          <TwoLines
            label={'Submitted at'}
            content={formatDate(new Date(submittedAt), 'date')}
          />
        )}
        {submittedBy && (
          <TwoLines label={'Submitted by'} content={submittedBy} />
        )}
      </Stack>
    </Stack>
  );
};
