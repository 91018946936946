import { gql } from '@apollo/client';

export const PLATFORM_METADATA_SUB = gql`
  subscription getPlatoformMetaData {
    ar_internal_metadata {
      key
      value
      updated_at
    }
  }
`;

export const PLATFORM_METADATA_QUERY = gql`
  query getPlatoformMetaData {
    ar_internal_metadata {
      key
      value
      updated_at
    }
  }
`;
