import FilterField from '@/components/FilterField';
import SearchField from '@/components/SearchField';
import { FilterList } from '@/types';
import { Box, Button } from '@mui/material';
import DownloadIcon from '@treyd-io/treyd-ui/icons/DownloadIcon';
interface Filters {
  [index: string]: string[];
}
interface OrdersReviewToolbarProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  filterList?: FilterList[];
  filters: Filters;
  setFilters: (filters: any) => void;
  searchPlaceholder?: string;
  filterMapper?: (val: string) => string | number;
  onDownloadCsv?: any;
  isDownloadAllowed?: boolean;
}

//Suggestion: move and rename this to be a general component since it's now used by multiple apps
export default function OrdersReviewToolbar(props: OrdersReviewToolbarProps) {
  const {
    searchTerm,
    setSearchTerm,
    filterList,
    setFilters,
    filters,
    filterMapper,
    onDownloadCsv,
    isDownloadAllowed,
  } = props;

  const onFiltersChange = (field: string, value: string[]) => {
    const updatedFilters = { ...filters };
    if (!value) {
      delete updatedFilters[field];
    } else {
      updatedFilters[field] = value;
    }
    setFilters(updatedFilters);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      {setSearchTerm && (
        <Box sx={{ width: '33%', marginInlineEnd: '1.5rem' }}>
          <SearchField
            placeholder={props.searchPlaceholder}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Box>
      )}
      {filterList?.map((filter: FilterList, index: number) => (
        <FilterField
          filterType={filter.filterType}
          filterMapper={filterMapper}
          filter={filter}
          onFilterChange={onFiltersChange}
          key={index}
          chip
        />
      ))}
      {isDownloadAllowed && (
        <Button
          sx={(theme) => ({
            marginLeft: 'auto',
            borderRadius: theme.spacing(1),
          })}
          variant="outlined"
          color="secondary"
          size="medium"
          startIcon={<DownloadIcon />}
          onClick={onDownloadCsv}>
          Download CSV
        </Button>
      )}
    </div>
  );
}
