import UserAvatar from '@/components/UserAvatar';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import TrashTwoIcon from '@treyd-io/treyd-ui/icons/TrashTwoIcon';
import { useState } from 'react';

interface CommentCardProps {
  comment: string;
  userName: string;
  role?: string;
  date: string;
  onDelete?: () => void;
}

export const CommentCard = (props: CommentCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        width={'100%'}>
        <Stack
          alignItems={'flex-start'}
          padding={2}
          gap={2}
          bgcolor={'grey.100'}
          borderRadius={1}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            width={'100%'}>
            <Box display={'flex'} gap={1} alignItems={'flex-start'}>
              <UserAvatar
                firstName={props.userName.split(' ')[0]}
                lastName={props.userName.split(' ')[1]}
              />
              <Stack alignItems={'flex-start'} padding={0} gap={0.25}>
                <Typography variant="subtitle2">{props.userName}</Typography>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  padding={0}
                  gap={0.25}>
                  <Typography color="grey.700" variant="caption">
                    {props?.role}
                  </Typography>
                  {props?.role && (
                    <Box
                      width={'4px'}
                      height={'4px'}
                      marginX={0.25}
                      bgcolor={'grey.700'}
                      borderRadius={'50%'}
                    />
                  )}
                  <Typography color="grey.700" variant="caption">
                    {props.date}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            {props.onDelete && isHovered && (
              <IconButton onClick={props.onDelete}>
                <TrashTwoIcon />
              </IconButton>
            )}
          </Box>
          <Typography
            component="pre"
            color="text.primary"
            sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
            variant="body1">
            {props.comment}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
