import { DateRangeField } from '@/components/TableFilter/DateRangeField';
import { voucherStatusDisplayedValue, voucherStatuses } from '@/constants';
import { VouchersTableFilters, VouchersTableFiltersFields } from '@/types';
import { getFiltersInitialValues } from '@/utils/TableFilters';
import {
  Autocomplete,
  Badge,
  Button,
  Chip,
  Divider,
  Menu,
  Stack,
  TextField,
  autocompleteClasses,
} from '@mui/material';
import {
  FilterComparator,
  QueryFilterField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import FiltersIcon from '@treyd-io/treyd-ui/icons/FiltersIcon';
import { Form, Formik } from 'formik';
import { isEmpty, map, startCase, toString } from 'lodash';
import { useState } from 'react';

interface TableFiltersProps {
  onSubmit: (filters: QueryFilterField<VouchersTableFiltersFields>[]) => void;
  onReset?: () => void;
  filters?: QueryFilterField<VouchersTableFiltersFields>[];
}

export const TableFilters = (props: TableFiltersProps) => {
  const { onSubmit, onReset, filters } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClose = () => setAnchorEl(null);
  const appliedFilterFieldsCount = filters?.length || 0;

  const initialValues = getFiltersInitialValues<VouchersTableFilters>(filters, {
    creationDateStart: toString,
    creationDateEnd: toString,
    statuses: (statuses) => statuses || [],
  });

  const handleformSubmit = (values: VouchersTableFilters) => {
    const { creationDateStart, creationDateEnd, statuses } = values;

    const filters: QueryFilterField<VouchersTableFiltersFields>[] = [];

    if (creationDateStart) {
      filters.push({
        key: 'creationDateStart',
        type: 'filter',
        name: 'created',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: creationDateStart,
      });
    }

    if (creationDateEnd) {
      filters.push({
        key: 'creationDateEnd',
        type: 'filter',
        name: 'created',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: creationDateEnd,
      });
    }

    if (!isEmpty(statuses)) {
      filters.push({
        key: 'statuses',
        type: 'filter',
        name: 'status',
        comparator: FilterComparator.IN,
        comparisonValue: statuses,
      });
    }

    onSubmit(filters);
    handleMenuClose();
  };

  return (
    <>
      <Badge
        badgeContent={appliedFilterFieldsCount}
        color="primary"
        sx={{ width: '100%' }}>
        <Button
          startIcon={<FiltersIcon />}
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          Filters
        </Button>
      </Badge>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleformSubmit}>
        {(formik) => {
          return (
            <Menu
              keepMounted
              disablePortal
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => formik.handleSubmit()}
              sx={{}}
              slotProps={{
                paper: {
                  elevation: 2,
                  sx: {
                    padding: 2,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: 420,
                    backgroundColor: 'background.default',
                  },
                },
              }}>
              <Form
                noValidate
                tabIndex={-1}
                onKeyDown={(e) => e.stopPropagation()}>
                <Row tabIndex={-1}>
                  <Col xs={12}>
                    <DateRangeField label="Creation date" name="creationDate" />
                  </Col>
                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={voucherStatuses}
                      value={formik.values?.statuses}
                      getOptionLabel={(option) =>
                        voucherStatusDisplayedValue[option]
                      }
                      onChange={(_, statuses) => {
                        formik.setFieldValue('statuses', statuses);
                      }}
                      renderTags={(statuses, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(statuses, (option, index) => (
                            <Chip
                              label={startCase(option)}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Status"
                        />
                      )}
                    />
                  </Col>

                  <Col xs={12} gap={1} display="flex">
                    <Button
                      fullWidth
                      onClick={() => {
                        onReset?.();
                      }}
                      variant="outlined"
                      type="button"
                      color="secondary">
                      Reset
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary">
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Menu>
          );
        }}
      </Formik>
    </>
  );
};
