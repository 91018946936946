import {
  Box,
  Collapse,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import IconButton from '@treyd-io/treyd-ui/components/IconButton';
import ChevronDownIcon from '@treyd-io/treyd-ui/icons/ChevronDownIcon';
import ChevronUpIcon from '@treyd-io/treyd-ui/icons/ChevronUpIcon';
import { isEmpty, map } from 'lodash';
import { ReactNode } from 'react';
import { useToggle } from 'react-use';

type Row = { label: string; value: string | number; moreDetails?: ReactNode };

export const SectionTable = ({ rows }: { rows: Row[] }) => {
  const [isExpanded, toggleExpand] = useToggle(false);
  return (
    !isEmpty(rows) && (
      <TableContainer
        sx={{
          border: 1,
          borderColor: 'other.outlined_border',
          borderRadius: 4,
        }}>
        <Table>
          <TableBody>
            {map(rows, (row) => {
              const hasMoreDetails = !!row.moreDetails;
              return (
                <>
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': {
                        borderBottom: 0,
                      },
                    }}>
                    <TableCell width={400}>
                      <Typography variant="subtitle2">{row.label}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        justifyContent="flex-end">
                        {hasMoreDetails && (
                          <IconButton
                            size="small"
                            sx={{ padding: 0 }}
                            onClick={toggleExpand}>
                            {isExpanded ? (
                              <ChevronUpIcon
                                fontSize="large"
                                color="primary"
                                pointerEvents="auto"
                              />
                            ) : (
                              <ChevronDownIcon
                                fontSize="large"
                                color="primary"
                                pointerEvents="auto"
                              />
                            )}
                          </IconButton>
                        )}
                        <Typography variant="body2">{row.value}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  {hasMoreDetails && (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={2}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>{row.moreDetails}</Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};
