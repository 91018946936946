import {
  useGetCompanyAppliedDiscounts,
  useGetCompanyFees,
  useGetCompanyStatuses,
} from '@/hooks';
import { CircularProgress, Divider, Stack } from '@mui/material';
import { FeesSection } from './FeesSection';
import { PricingSection } from './PricingSection';

export const Pricing = ({ companyId }: { companyId: number }) => {
  const {
    data: creditFeesData,
    isPending: isCreditFeesDataLoading,
    refetch: refetchCreditFeesData,
  } = useGetCompanyFees(companyId);

  const {
    data: companyAppliedDiscounts,
    isPending: isCompanyAppliedDiscountsLoading,
    refetch: refetchCompanyAppliedDiscounts,
  } = useGetCompanyAppliedDiscounts(companyId);

  const { data: companyStatuses, isPending: isLoadingCompanyStatuses } =
    useGetCompanyStatuses(companyId);

  const isLimitIndicative = companyStatuses?.limit_status === 'INDICATIVE';

  const loading =
    isCreditFeesDataLoading ||
    isCompanyAppliedDiscountsLoading ||
    isLoadingCompanyStatuses;

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Stack gap={6}>
      <PricingSection
        companyAppliedDiscounts={companyAppliedDiscounts}
        companyId={companyId}
        onUpdate={() => {
          refetchCompanyAppliedDiscounts();
          refetchCreditFeesData();
        }}
      />
      <Divider />
      <FeesSection
        companyId={companyId}
        creditFees={creditFeesData}
        isLimitIndicative={isLimitIndicative}
      />
    </Stack>
  );
};
