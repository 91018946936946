export const BANK_ACCOUNT_TYPE = {
  regular: 'Local',
  priority: 'SWIFT',
  bankgiro: 'Bankgiro',
  plusgiro: 'Plusgiro',
};
export const REGULAR_PAYMENT = { value: 'regular', label: 'Local' };
export const PRIORITY_PAYMENT = { value: 'priority', label: 'SWIFT' };
export const BANKGIRO_PAYMENT = { value: 'bankgiro', label: 'Bankgiro' };
export const PLUSGIRO_PAYMENT = { value: 'plusgiro', label: 'Plusgiro' };

export const PAYMENT_TYPES = [
  REGULAR_PAYMENT,
  PRIORITY_PAYMENT,
  BANKGIRO_PAYMENT,
  PLUSGIRO_PAYMENT,
];
export const DEFAULT_COUNTRY = 'cn';
export const DEFAULT_CURRENCY = 'usd';

export const PAYMENT_TYPE_MAPPER: Record<string, string> = {
  Local: 'regular',
  SEPA: 'regular',
  'SWIFT (IBAN)': 'priority',
  'SWIFT (Account Number)': 'priority',
  'SWIFT (ABA)': 'priority',
  'SWIFT (Sort Code)': 'priority',
  'SWIFT (CLABE)': 'priority',
  'SWIFT (CNAPS)': 'priority',
  Bankgiro: 'bankgiro',
  Plusgiro: 'plusgiro',
};

export const NO_BANK_NAME_METHODS = ['Bankgiro', 'Plusgiro'];

export enum BANK_FORM_FIELDS {
  paymentType = 'payment_type',
  combinationId = 'combination_id',
  bankCountry = 'bank_country',
  bankName = 'bank_name',
  bankAddress = 'bank_address',
  currency = 'currency',
  aba = 'aba',
  localClearingSystem = 'local_clearing_system',
  sortCode = 'sort_code',
  bicSwift = 'bic_swift',
  branchCode = 'branch_code',
  bankCode = 'bank_code',
  iban = 'iban',
  acctNumber = 'acct_number',
  clabe = 'clabe',
  cnaps = 'cnaps',
  bsbCode = 'bsb_code',
  routingNumber = 'routing_number',
  ifsc = 'ifsc',
  bankgiroNumber = 'bankgiro_number',
  plusgiroNumber = 'plusgiro_number',
  bankAccountHolderName = 'bank_account_holder_name',
  beneficiaryEntityType = 'entity_type',
  beneficiaryPostcode = 'postcode',
  beneficiaryStateOrProvince = 'state',
  beneficiaryCompanyName = 'company_name',
  beneficiaryCountryCode = 'country_code',
  beneficiaryAddress = 'full_address',
  beneficiaryCity = 'city',
  beneficiaryPaymentMethod = 'payment_method',
  beneficiaryPersonalEmail = 'personal_email',
  accountName = 'account_name',
  bankCountryCode = 'bank_country_code',
  accountCurrency = 'account_currency',
  paymentMethod = 'payment_method',
  countryCode = 'country_code',
  state = 'state',
  businessRegistrationNumber = 'business_registration_number',
  zipCode = 'zip_code',
}

export const FIELD_TITLE: Record<BANK_FORM_FIELDS | string, string> = {
  [BANK_FORM_FIELDS.paymentType]: 'Payment type',
  [BANK_FORM_FIELDS.combinationId]: 'Combination ID',
  [BANK_FORM_FIELDS.bankCountry]: 'Bank country',
  [BANK_FORM_FIELDS.bankName]: 'Bank name',
  [BANK_FORM_FIELDS.bankAddress]: 'Bank address',
  [BANK_FORM_FIELDS.currency]: 'Currency',
  [BANK_FORM_FIELDS.aba]: 'ABA',
  [BANK_FORM_FIELDS.sortCode]: 'Sort code',
  [BANK_FORM_FIELDS.bicSwift]: 'BIC/SWIFT',
  [BANK_FORM_FIELDS.branchCode]: 'Branch code',
  [BANK_FORM_FIELDS.bankCode]: 'Bank code',
  [BANK_FORM_FIELDS.iban]: 'IBAN',
  [BANK_FORM_FIELDS.acctNumber]: 'Account number',
  [BANK_FORM_FIELDS.clabe]: 'CLABE',
  [BANK_FORM_FIELDS.cnaps]: 'CNAPS',
  [BANK_FORM_FIELDS.bsbCode]: 'BSB code',
  [BANK_FORM_FIELDS.routingNumber]: 'Routing number',
  [BANK_FORM_FIELDS.ifsc]: 'IFSC',
  [BANK_FORM_FIELDS.bankgiroNumber]: 'Bankgiro number',
  [BANK_FORM_FIELDS.plusgiroNumber]: 'Plusgiro number',
  [BANK_FORM_FIELDS.bankAccountHolderName]: 'Account holder name',
  [BANK_FORM_FIELDS.beneficiaryEntityType]: 'Entity type',
  [BANK_FORM_FIELDS.beneficiaryPostcode]: 'Postcode',
  [BANK_FORM_FIELDS.beneficiaryCompanyName]: 'Company name',
  [BANK_FORM_FIELDS.beneficiaryCountryCode]: 'Country code',
  [BANK_FORM_FIELDS.beneficiaryCity]: 'City',
  [BANK_FORM_FIELDS.beneficiaryAddress]: 'Address',
  [BANK_FORM_FIELDS.accountName]: 'Account name',
  [BANK_FORM_FIELDS.bankCountryCode]: 'Bank country code',
  [BANK_FORM_FIELDS.accountCurrency]: 'Account currency',
  [BANK_FORM_FIELDS.paymentMethod]: 'Payment method',
  [BANK_FORM_FIELDS.state]: 'State or province',
  [BANK_FORM_FIELDS.businessRegistrationNumber]: 'Business registration number',
  [BANK_FORM_FIELDS.localClearingSystem]: 'Local clearing system',
  [BANK_FORM_FIELDS.zipCode]: 'Zip code',
};

export const IDENTIFYING_CODES = [
  BANK_FORM_FIELDS.iban,
  BANK_FORM_FIELDS.bicSwift,
  BANK_FORM_FIELDS.sortCode,
  BANK_FORM_FIELDS.aba,
  BANK_FORM_FIELDS.bsbCode,
  BANK_FORM_FIELDS.routingNumber,
  BANK_FORM_FIELDS.cnaps,
  BANK_FORM_FIELDS.ifsc,
];

export const BENEFICIARY_FIELDS = [
  BANK_FORM_FIELDS.beneficiaryPostcode,
  BANK_FORM_FIELDS.beneficiaryCity,
  BANK_FORM_FIELDS.state,
  BANK_FORM_FIELDS.beneficiaryAddress,
];
