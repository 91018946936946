import { ComplianceDecision } from '@/types';
import { TypedDocumentNode, gql } from '@apollo/client';

export const getComplianceDecisionData = gql`
  query getComplianceDecisionData($orgnr: String!, $country: String!) {
    lager_risk_compliancedecision(
      where: {
        core_company: { orgnr: { _eq: $orgnr }, country: { _eq: $country } }
      }
      limit: 1
      offset: 0
      order_by: { created: desc }
    ) {
      status
      final_risk_level
      is_active
    }
  }
` as TypedDocumentNode<
  { lager_risk_compliancedecision: ComplianceDecision[] },
  {}
>;
