import { getAllSuppliersErpInvoicesQuery } from '@/schemas/erp/getAllSuppliersErpInvoicesQuery';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetAllSuppliersErpInvoices = ({
  queryOptions,
  skip,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(getAllSuppliersErpInvoicesQuery(queryOptions), {
    skip,
  });
