import { gql, TypedDocumentNode } from '@apollo/client';
import { Orders_Suppliers } from '__generated__/graphql';
import { Bank_Account_Fragment, BankAccountFragment } from './fragments';

type GetSupplierDetails = {
  orders_suppliers_by_pk: Pick<
    Orders_Suppliers,
    | 'name'
    | 'full_address'
    | 'preferred_bank_account_id'
    | 'exempted_from_inspections'
    | 'kyc'
    | 'country'
  > & {
    bank_accounts: Array<BankAccountFragment> | null;
  };
};

type QueryVariables = {
  exporter_id?: number;
  include_bank_data: boolean;
};

export const getSupplierDetails = gql`
  ${Bank_Account_Fragment}
  query getCompanySupplier(
    $exporter_id: bigint!
    $include_bank_data: Boolean = true
  ) {
    orders_suppliers_by_pk(id: $exporter_id) {
      name
      full_address
      country
      preferred_bank_account_id
      exempted_from_inspections
      kyc
      bank_accounts @include(if: $include_bank_data) {
        ...BankAccountType
      }
    }
  }
` as TypedDocumentNode<GetSupplierDetails, QueryVariables>;
