import { ROUTES } from '@/constants';
import { RouteObject } from 'react-router-dom';
import { NotificationsAndEmails } from './notificationsAndEmailsPage';
import { EmailDetails } from './notificationsAndEmailsPage/Tabs/emails/EmailDetails';
import { NotificationDetails } from './notificationsAndEmailsPage/Tabs/notifications/NotificationDetails';

export const NOTIFICATIONS_AND_EMAILS: RouteObject = {
  path: ROUTES.notificationsAndEmails,

  children: [
    {
      index: true,
      element: <NotificationsAndEmails />,
    },
    {
      path: `${ROUTES.notifications}/:notification_id`,
      element: <NotificationDetails />,
    },
    {
      path: `${ROUTES.emails}/:email_id`,
      element: <EmailDetails />,
    },
  ],
};
