import { Box, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { capitalCase } from 'capital-case';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadCloudIcon from '../../icons/UploadCloudIcon';
import { FileObject } from './Uploader';

interface UploadDropZoneProps {
  onFinish: (files: FileObject[]) => void;
  uploadFile: (file: File) => void;
  disabled?: boolean;
  acceptsImages?: boolean;
}
export const UploadDropZone = ({
  onFinish,
  uploadFile,
  disabled,
  acceptsImages = false,
}: UploadDropZoneProps) => {
  const theme = useTheme();
  const upload = useCallback(
    (file: File) => {
      uploadFile(file);
    },
    [uploadFile]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.length > 0 && upload(acceptedFiles[0]);

      const acceptedFilesObject = acceptedFiles.map(
        (file: File): FileObject => ({
          file,
          status: 'success',
          errors: [],
        })
      );
      onFinish(acceptedFilesObject);
    },
    [onFinish, upload]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: acceptsImages
      ? {
          'application/pdf': [],
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png'],
        }
      : { 'application/pdf': [] },
    maxSize: 10485760, //10mb,
    multiple: false,
    disabled: disabled,
  });
  const isError = isDragReject || !isEmpty(fileRejections);

  const getBgColor = () => {
    if (isDragReject || !isEmpty(fileRejections))
      return alpha(theme.palette?.error.main, 0.04);
    if (isDragActive && !isDragReject)
      return alpha(theme.palette?.primary.main, 0.04);
    return 'transparent';
  };
  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          border: isError ? '1px solid' : '1px dashed',
          borderColor: isError ? 'error.main' : 'divider',
          borderRadius: theme.spacing(1.5),
          marginBlockEnd: theme.spacing(2),
          backgroundColor: getBgColor(),
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: alpha(theme.palette?.primary.main, 0.04),
          },
        })}>
        <input {...getInputProps()} />
        <Stack
          gap={1}
          alignItems="center"
          justifyContent="center"
          textAlign="center">
          <UploadCloudIcon fontSize="large" />
          <Typography variant="subtitle1" color="text.primary">
            Click to upload or drag and drop
          </Typography>
          {!isError && (
            <Typography variant="body2" color="text.secondary">
              All files (max. 10MB)
            </Typography>
          )}
          {isDragReject && (
            <Typography variant="body2" color="error.main">
              Unsupported File
            </Typography>
          )}
          {!isEmpty(fileRejections) && (
            <Typography variant="body2" color="error.main">
              {capitalCase(`${fileRejections[0]?.errors[0]?.code}`)}
            </Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
