import { ComponentLoader } from '@/components/ComponentLoader';
import { useGetPaymentTracking } from '@/hooks';
import { PaymentTrackerService } from '@/services';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CopyButton } from '@treyd-io/treyd-ui/components/CopyButton';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import HelpCircleIcon from '@treyd-io/treyd-ui/icons/HelpCircleIcon';
import { isEmpty, map, toString } from 'lodash';
import { ExportMT103 } from './ExportMT103';
import { TrackingDetails } from './TrackingDetails';
import { TrackingGuideDialog } from './TrackingGuideDialog';

export const PaymentBreakdown = ({ orderId }: { orderId: number }) => {
  const { data, loading } = useGetPaymentTracking(orderId);
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('sm'));

  const payments = data?.banking?.payment_tracking_v2?.payments;
  const events =
    payments && new PaymentTrackerService(payments).getPaymentEvents();

  if (loading) {
    return <ComponentLoader />;
  }

  if (isEmpty(payments)) {
    return null;
  }

  return (
    <Stack direction={'column'} gap={3}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Typography variant={'h6'}>Payment tracking</Typography>
        <TrackingGuideDialog />
      </Stack>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              paddingInline: { xs: 0, md: 2 },
            },
            padding: 0,
            marginTop: 0,
          }}>
          {map(events, (event) => (
            <>
              <TimelineItem sx={{ marginBottom: 1 }}>
                <TimelineSeparator>
                  <TimelineDot
                    variant={event?.details?.timeline.dotVariant}
                    sx={{
                      backgroundColor: event?.details?.timeline.dotColor,
                    }}
                  />
                  {!(event?.details?.isTerminal && event.details.isLast) && (
                    <TimelineConnector
                      sx={{
                        backgroundColor:
                          event?.details?.timeline.connectorColor,
                      }}
                    />
                  )}
                </TimelineSeparator>
                <TimelineContent marginBottom={4}>
                  <Stack gap={1}>
                    <Stack>
                      <Typography variant={'overline'}>
                        ON {event?.details?.date}
                      </Typography>
                      <Typography
                        variant={'subtitle1'}
                        color={event?.details?.titleColor}>
                        {event?.details?.title}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      maxWidth="md">
                      {event?.details?.subtitle}
                      {event?.details?.expected_date && (
                        <Typography variant={'subtitle1'} display={'inline'}>
                          {' '}
                          {event?.details?.expected_date}
                        </Typography>
                      )}
                    </Typography>
                    {event?.details?.paymentRef && (
                      <Stack gap={2}>
                        <Stack gap={0.5}>
                          <TwoLines
                            label={
                              isSmallScreens ? (
                                <Stack>
                                  <Typography
                                    variant={'body2'}
                                    color={'textSecondary'}>
                                    Payment reference
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color={'textSecondary'}>
                                    You can share this reference to the supplier
                                    to track the payment with their bank.
                                  </Typography>
                                </Stack>
                              ) : (
                                <Box display={'flex'} alignItems={'center'}>
                                  <Typography
                                    variant={'body2'}
                                    color={'textSecondary'}>
                                    Payment reference
                                  </Typography>
                                  <Tooltip
                                    title="You can share this reference to the supplier to track the payment with their bank."
                                    arrow>
                                    <Box display="flex" alignItems="center">
                                      <HelpCircleIcon color="action" />
                                    </Box>
                                  </Tooltip>
                                </Box>
                              )
                            }
                            content={
                              <Box display={'flex'}>
                                {event?.details?.paymentRef}
                                <CopyButton
                                  value={toString(event?.details?.paymentRef)}
                                  iconProps={{ sx: { color: 'info.main' } }}
                                />
                              </Box>
                            }
                          />
                          {event?.mt103 && <ExportMT103 mt103={event.mt103} />}
                        </Stack>

                        <TrackingDetails
                          events={event?.tracking_details_events}
                        />
                      </Stack>
                    )}
                  </Stack>
                </TimelineContent>
              </TimelineItem>
              {event.details.isLast && !event.details.isTerminal && (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot variant={'outlined'} />
                  </TimelineSeparator>
                  <TimelineContent marginBottom={4}>
                    <Typography variant="body1" color={'textSecondary'}>
                      Additional details will be provided soon...
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              )}
            </>
          ))}
        </Timeline>
      </Box>
    </Stack>
  );
};
