import {
  useDeleteCompanyUser,
  useFetchCompanyMembers,
  useFetchPotentialCompanyUsers,
  useSendInvitation,
} from '@/hooks';
import { useGetPowerOfAttorneys } from '@/hooks/useGetPowerOfAttorney';
import { useUpdateMainContact } from '@/hooks/useUpdateMainContact';
import { UserInvitationFormValues } from '@/pages/merchants/MerchantsDetails/Tabs/Users/InviteUserModal';
import { SendInvite, TableRowType } from '@/types';
import { toNumber } from 'lodash';
import { useState } from 'react';
import { useToggle } from 'react-use';

const useController = (companyId: string) => {
  const {
    data: companyData,
    loading: loadingCompanyData,
    refetch,
  } = useFetchPotentialCompanyUsers(companyId);
  const { data: powerOfAttorneysData, loading: powerOfAttorneysLoading } =
    useGetPowerOfAttorneys(toNumber(companyId));
  const { data: companyMembersData, loading: loadingCompanyMembers } =
    useFetchCompanyMembers(toNumber(companyId));
  const {
    mutateAsync: updateMainContact,
    isPending: updateMainContactLoading,
  } = useUpdateMainContact();

  const companyMembers =
    companyMembersData?.orders_companies_by_pk?.core_company?.members;

  const powerOfAttorneys =
    powerOfAttorneysData?.documents_power_of_attorneys || [];
  const attorneyIds = powerOfAttorneys?.map((p) => p.user_id);

  const [isOpen, toggleModal] = useToggle(false);
  const [confirmModalIsOpen, toggleConfirmModal] = useToggle(false);
  const [deleteModalIsOpen, toggleDeleteModal] = useToggle(false);
  const [mainContactModalIsOpen, toggleMainContactModal] = useToggle(false);
  const [user, setUser] = useState<TableRowType>({});

  const { mutate: deleteUser, isPending: isDeleteUserLoading } =
    useDeleteCompanyUser();
  const { mutate: invite, isPending: sendInvitationLoading } =
    useSendInvitation();

  const handleSubmit = async (values: UserInvitationFormValues) => {
    invite(
      {
        email: values.email.toLocaleLowerCase(),
        company_id: companyId,
      },
      {
        onSuccess: () => {
          toggleModal();
          refetch();
        },
        onError: console.error,
      }
    );
  };

  const handleConfirmResend = async (data: SendInvite.Payload) => {
    invite(data, {
      onSuccess: () => toggleConfirmModal(),
      onError: console.error,
    });
  };

  const handleConfirmDelete = async (userId: number, companyId: number) => {
    deleteUser(
      {
        userId,
        companyId,
      },
      {
        onSuccess: () => {
          refetch();
          toggleDeleteModal();
        },
        onError: console.error,
      }
    );
  };

  const handleConfirmUpdateMainContact = (userId: number, companyId: number) =>
    updateMainContact(
      {
        companyId,
        userId,
      },
      {
        onSuccess: () => {
          toggleMainContactModal();
        },
      }
    );

  return {
    states: {
      companyData,
      companyMembers,
      attorneyIds,
      loadingCompanyMembers,
      powerOfAttorneysLoading,
      loadingCompanyData,
      isOpen,
      confirmModalIsOpen,
      mainContactModalIsOpen,
      loading:
        sendInvitationLoading ||
        isDeleteUserLoading ||
        updateMainContactLoading,
      deleteModalIsOpen,
      user,
    },
    actions: {
      toggleModal,
      handleSubmit,
      toggleConfirmModal,
      toggleMainContactModal,
      handleConfirmUpdateMainContact,
      handleConfirmResend,
      handleConfirmDelete,
      toggleDeleteModal,
      setUser,
    },
  };
};

export default useController;
