import { useQuery, useSubscription } from '@apollo/client';
import { Button, Snackbar } from '@mui/material';
import { PLATFORM_VERSION_KEY } from 'constants/app-settings';
import { Fragment, useEffect } from 'react';
import { PLATFORM_METADATA_QUERY, PLATFORM_METADATA_SUB } from './schema';

const useCurrentVersion = () => {
  const { data } = useQuery(PLATFORM_METADATA_QUERY);
  const platform_current_version = data?.ar_internal_metadata?.find(
    (x: { key: string; value: string }) => x.key === PLATFORM_VERSION_KEY
  )?.value;
  return platform_current_version;
};

const useIsLatestVersion = () => {
  const currentVersion = useCurrentVersion();
  const { data } = useSubscription(PLATFORM_METADATA_SUB);
  const platform_current_version = data?.ar_internal_metadata?.find(
    (x: { key: string; value: string }) => x.key === PLATFORM_VERSION_KEY
  )?.value;
  const isLatestVersion =
    platform_current_version === currentVersion ||
    platform_current_version === undefined ||
    currentVersion === undefined;
  let type = platform_current_version?.includes('HARD') ? 'HARD' : 'SOFT';
  return { isLatestVersion, type };
};

export default function NewVersionReload() {
  const { isLatestVersion, type } = useIsLatestVersion();

  useEffect(() => {
    if (!isLatestVersion && type === 'HARD') {
      window.setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [isLatestVersion, type]);

  return (
    <>
      {type === 'SOFT' && (
        <Snackbar
          open={!isLatestVersion}
          message={
            'A new version of Treyd Application is available. Please reload the page to update.'
          }
          action={
            <Fragment>
              <Button
                color="secondary"
                size="small"
                onClick={() => window.location.reload()}>
                Reload
              </Button>
            </Fragment>
          }
        />
      )}
      {type === 'HARD' && (
        <Snackbar
          open={!isLatestVersion}
          message={
            'A new version of Treyd Application is available. the application will reload in 5 seconds'
          }
          action={
            <Fragment>
              <Button
                color="secondary"
                size="small"
                onClick={() => window.location.reload()}>
                Reload
              </Button>
            </Fragment>
          }
        />
      )}
    </>
  );
}
