import { getMerchantRiskKycQuery } from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetMerchantRiskKyc = (companyId: number) =>
  useQuery(getMerchantRiskKycQuery, {
    variables: {
      companyId,
    },
    skip: !companyId,
  });
