import { Box, Stack, Typography } from '@mui/material';
import { toSentenceCase } from '@treyd-io/core/utils/string';

export type DocumentCardType = {
  title: string;
  subtitle?: string;
  statusChip?: React.ReactNode;
  actionButtonsSection: React.ReactNode;
};
export const DocumentCard = ({
  subtitle,
  title,
  actionButtonsSection,
  statusChip,
}: DocumentCardType) => {
  return (
    <Stack
      border={1}
      borderRadius={4}
      borderColor="other.outlined_border"
      padding={3}
      minHeight={177}
      justifyContent="space-between"
      width="100%"
      height="100%"
      gap={3}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={'space-between'}
        flexWrap="wrap"
        alignItems={{ xs: 'flex-start', sm: 'cneter' }}
        gap={0.5}
        width="100%">
        <Stack gap={0.5} width="100%">
          <Typography variant="subtitle1" noWrap width="100%">
            {toSentenceCase(title?.toLowerCase())}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            noWrap
            width="100%">
            {subtitle}
          </Typography>
        </Stack>
        {statusChip}
      </Stack>
      <Box>{actionButtonsSection}</Box>
    </Stack>
  );
};
