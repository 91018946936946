export enum CONTRACTOR_TYPE {
  SUPPLIER = 'Supplier',
  MERCHANT = 'Merchant',
}

interface OrderContractorGeneralProps {
  supplierId: number;
  merchantId: number;
  name: string;
  date?: Date;
  ordersCount?: number;
  merchantUid?: string;
}

interface MerchantProps {
  type: CONTRACTOR_TYPE.MERCHANT;
  isApproved?: never;
  riskLevel?: never;
  rating?: number | null;
  remainingCredit?: string;
  merchantNote?: string;
}

interface SupplierProps {
  type: CONTRACTOR_TYPE.SUPPLIER;
  isApproved: boolean;
  riskLevel: string;
  rating?: never;
  remainingCredit?: never;
  supplierNote?: string;
}

export type OrderContractorProps = OrderContractorGeneralProps &
  (MerchantProps | SupplierProps);

export interface HeaderProps {
  supplierId: number;
  merchantId: number;
  name: string;
  type: CONTRACTOR_TYPE;
  isApproved?: boolean;
  date?: Date;
  merchantUid?: string;
}
