import { TypedDocumentNode, gql } from '@apollo/client';

type GetFundingNotSecuredReasonQueryType = {
  funding_financiers_limits_frodacompany: {
    froda_requirement_error?: string;
  }[];
};

export type getFundingNotSecuredReasonQueryVariables = {
  orgNumber: string;
  country: string;
};

export const getFundingNotSecuredReasonQuery = gql`
  query getFundingNotSecuredReason($orgNumber: String!, $country: String!) {
    funding_financiers_limits_frodacompany(
      where: { org_no: { _eq: $orgNumber }, country: { _eq: $country } }
    ) {
      froda_requirement_error
    }
  }
` as TypedDocumentNode<
  GetFundingNotSecuredReasonQueryType,
  getFundingNotSecuredReasonQueryVariables
>;
