import { getOrderInvoices } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetOrderInvoices = (orderId: number) => {
  return useQuery({
    queryKey: ['order-invoices', orderId],
    queryFn: () => getOrderInvoices(orderId),
    enabled: !!orderId,
  });
};
