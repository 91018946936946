import { GridAlignment, GridColDef, GridSortDirection } from '@mui/x-data-grid';

export const DEFAULT_GRID_COL_DEF = {
  filterable: false,
  hideable: false,
  sortable: true,
  align: 'left' as GridAlignment,
  headerAlign: 'left' as GridAlignment,
  sortingOrder: ['desc', 'asc'] as GridSortDirection[],
  flex: 1,
} as Partial<GridColDef>;
