import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import FileSearchIcon from '@treyd-io/treyd-ui/icons/FileSearchIcon';
import TrashTwoIcon from '@treyd-io/treyd-ui/icons/TrashTwoIcon';
import { ReactNode } from 'react';

interface AttachmentCardProps {
  title?: ReactNode;
  description?: ReactNode;
  onPreviewClick?: () => void;
  onRemoveClick?: () => void;
}

export const AttachmentCard = (props: AttachmentCardProps) => {
  const { title, description, onPreviewClick, onRemoveClick } = props;
  return (
    <Stack
      gap={3}
      padding={2}
      border={1}
      borderColor="other.outlined_border"
      borderRadius={2}
      alignItems="flex-start">
      <Box maxWidth={253}>
        <Tooltip title={title} arrow placement="top">
          <Typography variant="body1" color="text.primary" noWrap>
            {title}
          </Typography>
        </Tooltip>
        <Typography variant="body2" color="text.secondary" noWrap>
          {description}
        </Typography>
      </Box>
      <Stack gap={1} direction="row">
        {onPreviewClick && (
          <Button
            size="small"
            variant="outlined"
            onClick={onPreviewClick}
            color="secondary"
            startIcon={<FileSearchIcon />}>
            Preview
          </Button>
        )}
        {onRemoveClick && (
          <Button
            size="small"
            onClick={onRemoveClick}
            color="error"
            startIcon={<TrashTwoIcon />}>
            Remove
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
