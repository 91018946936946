import { getOrderDocuments } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetOrderDocuments = (orderId: number) => {
  return useQuery({
    queryKey: ['order-documents', orderId],
    queryFn: () => getOrderDocuments(orderId),
    enabled: !!orderId,
  });
};
