import { Dialog, DialogContent, Typography } from '@mui/material';

import { AddBankAccount } from '@/components/AddBankForm/AddBankAccount';

interface BankAccountDialogProps {
  open: boolean;
  onClose: () => void;
  entity: 'supplier' | 'company';
  entityId: string;
  entityCountry: string;
  onSuccess: () => void;
}
const BankAccountDialog = (props: BankAccountDialogProps) => {
  const { open, onClose, entity, entityCountry, entityId, onSuccess } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          width: '444px',
        }}>
        <Typography variant="h5">Add bank account</Typography>
        <AddBankAccount
          entityCountry={entityCountry}
          entity={entity}
          entityId={entityId}
          title={
            <Typography variant="body1" color="text.primary">
              Add bank account
            </Typography>
          }
          onSuccess={onSuccess}
          isDialog
        />
      </DialogContent>
    </Dialog>
  );
};

export default BankAccountDialog;
