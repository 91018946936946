import { Orders_Bank_Accounts } from '@/__generated__/graphql';
import { BankAccountCompliance } from '@/types';
import { TypedDocumentNode, gql } from '@apollo/client';

export type CompanyBankAccount = Pick<
  Orders_Bank_Accounts,
  | 'aba'
  | 'acct_number'
  | 'bank_account_holder_name'
  | 'bank_address'
  | 'bank_code'
  | 'bank_country'
  | 'bank_name'
  | 'bankgiro_number'
  | 'bic_swift'
  | 'branch_code'
  | 'bsb_code'
  | 'clabe'
  | 'cnaps'
  | 'combination_id'
  | 'currency'
  | 'deactivated_at'
  | 'error_msgs'
  | 'iban'
  | 'ifsc'
  | 'institution_no'
  | 'payment_type'
  | 'plusgiro_number'
  | 'sort_code'
  | 'id'
  | 'company_id'
  | 'created_at'
  | 'awx_bene_val_errors'
> & {
  compliance: BankAccountCompliance;
};

export type GetCompanyBankAccountsQueryType = {
  orders_companies_by_pk: {
    bank_accounts: CompanyBankAccount[];
  };
};

type GetCompanyBankAccountsQueryVariablesType = {
  companyId: number;
};
export const getCompanyBankAccountsQuery = gql`
  query getCompanyBankAccountsQuery($companyId: bigint!) {
    orders_companies_by_pk(id: $companyId) {
      bank_accounts(where: { deactivated_at: { _is_null: true } }) {
        aba
        acct_number
        bank_account_holder_name
        bank_address
        bank_code
        bank_country
        bank_name
        bankgiro_number
        bic_swift
        branch_code
        bsb_code
        clabe
        cnaps
        combination_id
        currency
        deactivated_at
        error_msgs
        iban
        ifsc
        institution_no
        payment_type
        plusgiro_number
        sort_code
        id
        compliance
        company_id
        created_at
        awx_bene_val_errors
      }
    }
  }
` as TypedDocumentNode<
  GetCompanyBankAccountsQueryType,
  GetCompanyBankAccountsQueryVariablesType
>;
