import { PayoutStatus } from '@/pages/orderReview/models/Order';

interface StatusObject {
  chipLabel: string;
  chipColor:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}
type StatusMapper = Record<PayoutStatus, StatusObject>;

export class PayoutStatusMapper {
  private status: PayoutStatus;
  constructor(status: PayoutStatus) {
    this.status = status;
  }

  public getStatus() {
    const PayoutStatusMapper: StatusMapper = {
      draft: {
        chipLabel: 'Draft',
        chipColor: 'default',
      },
      payment_processing: {
        chipLabel: 'Payment processing',
        chipColor: 'default',
      },
      paid: {
        chipLabel: 'Paid',
        chipColor: 'success',
      },
      ready_for_payment: {
        chipLabel: 'Ready for payment',
        chipColor: 'default',
      },
      pending: {
        chipLabel: 'Pending',
        chipColor: 'default',
      },
      canceled: {
        chipLabel: 'Canceled',
        chipColor: 'error',
      },
    };
    return PayoutStatusMapper[this.status];
  }
}
