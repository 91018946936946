import { NO_TRACKING_STATES } from '@/constants';

import { Order } from '@/types';
import { Stack } from '@mui/material';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { first, toNumber, toString } from 'lodash';
import { PaymentBreakdown } from './PaymentBreakdown';
import { PaymentDetailsSection } from './PaymentDetailsSection';
import { PaymentTrackingEmptyState } from './PaymentTrackingEmptyState';

export const PaymentTrackingTab = ({ order }: { order: Order }) => {
  const orderAmount = formatCurrency(
    toNumber(first(order?.payouts)?.amount),
    toString(first(order?.payouts)?.currency)
  );
  const payoutProofOfPayment = order?.payouts[0]?.proof_of_payment;

  if (NO_TRACKING_STATES.includes(order.mapped_status)) {
    return <PaymentTrackingEmptyState />;
  }

  return (
    <Stack gap={7}>
      <PaymentDetailsSection
        orderAmount={orderAmount}
        payoutProofOfPayment={payoutProofOfPayment}
      />
      <PaymentBreakdown orderId={order?.id} />
    </Stack>
  );
};
