import { createContext } from 'react';
import { Location, Path } from 'react-router-dom';

export const BrowsingHistoryContext = createContext<{
  past: Partial<Location>[];
  present: Partial<Location>;
  future: Partial<Location>[];
  createUrl: (pathOptions: Path) => string;
  goBack: () => void;
}>({
  past: [],
  present: {},
  future: [],
  createUrl: () => '',
  goBack: () => {},
});
