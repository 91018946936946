import { gql } from '@apollo/client';

export const updateInvocationMutation = gql`
  mutation UpdateInvocation(
    $invocation_id: bigint!
    $data: publisher_invocations_set_input!
  ) {
    update_publisher_invocations_by_pk(
      pk_columns: { id: $invocation_id }
      _set: $data
    ) {
      ack
      retries
    }
  }
`;
