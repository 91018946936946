import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { Stack, Typography } from '@mui/material';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import { startCase } from 'lodash';
import { OrdersComplianceReviewDialog } from './OrdersComplianceReviewDialog/OrdersComplianceReviewDialog';

export const ComplianceInfoSection = ({
  decision,
  amlLevel,
}: {
  decision: string;
  amlLevel: string;
}) => {
  return (
    <Stack
      direction="row"
      padding={3}
      borderRadius={2}
      border={1}
      borderColor="grey.300">
      <Row>
        <Col xs={12} md={2}>
          <TwoLines
            label="Compliance decision"
            content={startCase(decision)}
            containerProps={{
              direction: { xs: 'row', sm: 'column' },
              justifyContent: { xs: 'space-between', sm: 'flex-start' },
            }}
          />
        </Col>
        <Col xs={12} md={2}>
          <TwoLines
            label="Risk AML level"
            content={
              amlLevel || (
                <Typography color={'text.disabled'}>Pending</Typography>
              )
            }
            containerProps={{
              direction: { xs: 'row', sm: 'column' },
              justifyContent: { xs: 'space-between', sm: 'flex-start' },
            }}
          />
        </Col>
        <Col xs={12} md="auto" marginLeft="auto">
          <OrdersComplianceReviewDialog />
        </Col>
      </Row>
    </Stack>
  );
};
