import { defineAbility } from '@/auth/permissions/defineAbility';
import { DEV } from '@/pages/dev';
import { INVOICES } from '@/pages/invoices';
import { MERCHANTS } from '@/pages/merchants';
import { ORDER_REVIEW } from '@/pages/orderReview';
import { SUPPLIERS } from '@/pages/suppliers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import yellowFavicon from '@treyd-io/treyd-ui/assets/favicons/yellow_favicon.ico';
import { ToastProvider } from '@treyd-io/treyd-ui/providers/toastProvider';
import { GoogleAuthRedirect, LoginView, PrivateRoutes } from 'auth';

import { CREDITS_AND_VOUCHERS } from '@/pages/creditsAndVouchers';
import { NOTIFICATIONS_AND_EMAILS } from '@/pages/notificationsAndEmails';
import ORDERS from '@/pages/orders';
import Profile from '@/pages/profile';
import { TRANSACTIONS } from '@/pages/transactions';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  AbilityProvider,
  ApolloClientProvider,
  AuthProvider,
  LogglyProvider,
  SentryProvider,
  ThemeProvider,
} from 'providers';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { RootLayout } from './RootLayout';
import { ROUTES } from './constants';
import { ERP_INVOICES } from './pages/erpInvoices';
import ErrorPage from './pages/generalPages/errorPage';

const setEnvIndicators = () => {
  const isStaging = process.env.REACT_APP_ENV === 'staging';
  const isDevelopment = process.env.REACT_APP_ENV === 'development';
  const icon = document.getElementById('favicon') as HTMLLinkElement;
  const rootElement = document.getElementById('root');
  if (isDevelopment)
    rootElement?.setAttribute('style', 'border: 8px solid #078CD7');
  if (isStaging) {
    rootElement?.setAttribute('style', 'border: 8px solid #81CB65');
    icon.setAttribute('href', yellowFavicon);
  }
};

setEnvIndicators();

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage sx={{ width: '100vw', height: '100vh' }} />,
    children: [
      {
        element: <PrivateRoutes />,
        errorElement: <ErrorPage />,
        children: [
          ORDER_REVIEW,
          ORDERS,
          INVOICES,
          ERP_INVOICES,
          TRANSACTIONS,
          MERCHANTS,
          SUPPLIERS,
          CREDITS_AND_VOUCHERS,
          DEV,
          NOTIFICATIONS_AND_EMAILS,
          {
            path: `${ROUTES.profile}`,
            element: <Profile />,
          },
        ],
      },
      {
        path: `${ROUTES.login}`,
        element: <LoginView />,
      },
      {
        path: '/users/google/login/callback',
        element: <GoogleAuthRedirect />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <SentryProvider>
      <ThemeProvider>
        <ToastProvider>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <ApolloClientProvider>
                <AbilityProvider defineAbility={defineAbility}>
                  <LogglyProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <RouterProvider router={router} />
                    </LocalizationProvider>
                  </LogglyProvider>
                </AbilityProvider>
              </ApolloClientProvider>
            </QueryClientProvider>
          </AuthProvider>
        </ToastProvider>
      </ThemeProvider>
    </SentryProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
