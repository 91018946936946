import { useSubmitOrderComplianceReview } from '@/hooks';
import OrderActionModal from '@/pages/orderReview/OrderReviewDetails/OrderActionModal';
import Order from '@/pages/orderReview/models/Order';
import { FormValues } from '@/types';
import { Button, Stack } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { LOGGING_MESSAGES } from 'constants/loggly';
import { Can } from 'context';
import { useCanDoEventOnOrder, useLogglyLoggingService } from 'hooks';

import { ORDER_EVENT, ORDER_REVIEW_VERDICT } from '@/constants';
import { ModalContents, ModalState } from '@/types';
import { useState } from 'react';

enum ORDER_COMPLIANCE_REVIEW_ACTION {
  APPROVE = 'Approve',
  REJECT = 'Reject',
  SEND_BACK = 'Send Back',
}

const FORM_INITIAL_STATE = {
  comment: '',
};

const COMPLIANCE_MODAL_INITIAL_STATE: ModalState = {
  title: '',
  onSubmit: () => {},
  subtitle: '',
  commentInputLabel: '',
  initialErrors: {},
  initialValues: {},
  submitButtonColor: 'primary',
  submitButtonLabel: '',
};

const MODAL_CONTENT: ModalContents = {
  [ORDER_COMPLIANCE_REVIEW_ACTION.REJECT]: {
    subtitle:
      'This action is non-reversible. The merchant will have to create a new order.',
    commentInputLabel: 'Add reason for rejection',
    submitButtonColor: 'error',
    submitButtonLabel: ORDER_COMPLIANCE_REVIEW_ACTION.REJECT,
    initialErrors: undefined,
    initialValues: FORM_INITIAL_STATE,
  },
  [ORDER_COMPLIANCE_REVIEW_ACTION.SEND_BACK]: {
    subtitle: 'The order will be sent back to the merchant as a draft',
    commentInputLabel: 'Add reason for send back',
    submitButtonColor: 'primary',
    submitButtonLabel: ORDER_COMPLIANCE_REVIEW_ACTION.SEND_BACK,
    initialErrors: undefined,
    initialValues: FORM_INITIAL_STATE,
  },
  [ORDER_COMPLIANCE_REVIEW_ACTION.APPROVE]: {
    subtitle: '',
    commentInputLabel: 'Add a comment',
    submitButtonColor: 'success',
    submitButtonLabel: ORDER_COMPLIANCE_REVIEW_ACTION.APPROVE,
    initialErrors: undefined,
    initialValues: FORM_INITIAL_STATE,
  },
};

interface ComplianceProps {
  order: Order;
  goBack: () => void;
  actionsDisabled: boolean;
}

const ComplianceActionButtons = (props: ComplianceProps) => {
  const { order, goBack, actionsDisabled } = props;
  const showNotificationMessage = useToastNotification();
  const [modalState, setModalState] = useState<ModalState>(
    COMPLIANCE_MODAL_INITIAL_STATE
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const log = useLogglyLoggingService();

  const { mutate: submitOrderComplianceReview } =
    useSubmitOrderComplianceReview();

  const { data, isError } = useCanDoEventOnOrder({
    id: order?.id,
    eventName: ORDER_EVENT.COMPLIANCE_APPROVE,
  });

  const canNotApproveOrder =
    actionsDisabled || isError || !Boolean(data?.status);

  const handleOrderApprove = async (values: FormValues) => {
    submitOrderComplianceReview(
      {
        orderId: order?.id,
        data: {
          verdict: ORDER_REVIEW_VERDICT.APPROVE,
          ...values,
        },
      },
      {
        onSuccess: () => {
          goBack();
          showNotificationMessage({
            title: `Order #${order?.id} was approved.`,
            type: 'success',
          });
          log({
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_APPROVE_SUCCESS,
            data: { orderId: order?.id },
          });
          setIsModalOpen(false);
        },
        onError: (err: any) => {
          log({
            type: 'ERROR',
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_COMPLIANCE_REVIEW_FAIL,
            data: { orderId: order?.id, error: err },
          });
          showNotificationMessage({
            title: err?.response?.data?.comment?.[0] || 'Something went wrong.',
            type: 'error',
          });
        },
      }
    );
  };

  const handleOrderReject = async (values: FormValues) => {
    submitOrderComplianceReview(
      {
        orderId: order?.id,
        data: {
          verdict: ORDER_REVIEW_VERDICT.REJECT,
          ...values,
        },
      },
      {
        onSuccess: () => {
          goBack();
          showNotificationMessage({
            title: `Order #${order?.id} was rejected.`,
            type: 'success',
          });
          log({
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_REJECT_SUCCESS,
            data: { orderId: order?.id },
          });
          setIsModalOpen(false);
        },
        onError: (err: any) => {
          log({
            type: 'ERROR',
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_COMPLIANCE_REVIEW_FAIL,
            data: { orderId: order?.id, error: err },
          });
          showNotificationMessage({
            title: err?.response?.data?.comment?.[0] || 'Something went wrong.',
            type: 'error',
          });
        },
      }
    );
  };

  const handleOrderSendBack = async (values: FormValues) => {
    submitOrderComplianceReview(
      {
        orderId: order?.id,
        data: {
          verdict: ORDER_REVIEW_VERDICT.SEND_BACK,
          ...values,
        },
      },
      {
        onSuccess: () => {
          showNotificationMessage({
            title: `Order #${order?.id} was sent back to draft.`,
            type: 'success',
          });
          log({
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_SEND_BACK_SUCCESS,
            data: { orderId: order?.id },
          });
          goBack();
        },
        onError: (err: any) => {
          log({
            type: 'ERROR',
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_COMPLIANCE_REVIEW_FAIL,
            data: { orderId: order?.id, error: err },
          });
          showNotificationMessage({
            title: err?.response?.data?.comment?.[0] || 'Something went wrong.',
            type: 'error',
          });
        },
      }
    );
  };
  const SUBMIT_HANDLERS = {
    [ORDER_COMPLIANCE_REVIEW_ACTION.APPROVE]: handleOrderApprove,
    [ORDER_COMPLIANCE_REVIEW_ACTION.REJECT]: handleOrderReject,
    [ORDER_COMPLIANCE_REVIEW_ACTION.SEND_BACK]: handleOrderSendBack,
  };

  const onActionClick = async (actionName: ORDER_COMPLIANCE_REVIEW_ACTION) => {
    setIsModalOpen(true);
    setModalState({
      title: `${actionName} #${order?.id}`,
      onSubmit: (values: FormValues) => SUBMIT_HANDLERS[actionName](values),
      ...MODAL_CONTENT[actionName],
    });
  };

  return (
    <Stack alignItems="center" direction="row">
      <Can I="edit" an="orders_app_compliance_review">
        <Button
          disabled={actionsDisabled}
          onClick={() =>
            onActionClick(ORDER_COMPLIANCE_REVIEW_ACTION.SEND_BACK)
          }
          size="large"
          color="info">
          Send back
        </Button>
        <Button
          size="large"
          color="error"
          disabled={actionsDisabled}
          onClick={() => onActionClick(ORDER_COMPLIANCE_REVIEW_ACTION.REJECT)}>
          Reject
        </Button>
        <Button
          size="large"
          color="success"
          disabled={canNotApproveOrder}
          onClick={() => onActionClick(ORDER_COMPLIANCE_REVIEW_ACTION.APPROVE)}>
          Approve
        </Button>
      </Can>
      <OrderActionModal
        {...modalState}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </Stack>
  );
};
export default ComplianceActionButtons;
