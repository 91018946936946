import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

export const useDebouncedState = (
  initialState: any = undefined,
  delay: number = 500
) => {
  const [state, setState] = useState(initialState);
  const debouncedSetState = useMemo(
    () => debounce(setState, delay),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return [state, debouncedSetState];
};
