import { COMMERCIAL_STATUS, ONBOARDING_STATUS } from '@/constants';
import { MerchantService } from '@/services';
import { Box, Chip, Tooltip } from '@mui/material';
import { toSentenceCase } from '@treyd-io/core/utils/string';
import InformationIcon from '@treyd-io/treyd-ui/icons/InformationIcon';

interface Props {
  status: COMMERCIAL_STATUS | ONBOARDING_STATUS;
  label: string | undefined;
  size?: 'small' | 'medium';
}

export const MerchantStatusChip = (props: Props) => {
  const { status, size = 'medium', label } = props;
  const chipProps = MerchantService.merchantCombinedStatusMapper[status];
  return (
    <Chip
      size={size}
      onDelete={() => {}}
      deleteIcon={
        <Tooltip title={chipProps?.tooltipText} placement="top" arrow>
          <Box display={'flex'} justifyContent={'center'}>
            <InformationIcon color="primary" />
          </Box>
        </Tooltip>
      }
      label={chipProps?.label + (label ? `, ${toSentenceCase(label)}` : '')}
    />
  );
};
