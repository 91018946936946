import {
  getCompanyAttachmentsQuery,
  GetCompanyAttachmentsQueryVariables,
} from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetCompanyAttachments = (
  variables: GetCompanyAttachmentsQueryVariables
) =>
  useQuery(getCompanyAttachmentsQuery, {
    variables,
    skip: !variables.orgnr || !variables.country,
  });
