import { filter } from 'lodash';
export const sanitizeOrgNumber = (orgNumber: string) =>
  orgNumber ? orgNumber?.replace(/[^0-9^aA-zZ]/g, '') : '';

export const getAddress = (options: {
  firstLineAddress?: string;
  zipCode?: string;
  city?: string;
  country?: string;
}) => {
  const { firstLineAddress, city, country, zipCode } = options;
  if (!firstLineAddress && !city && !country && !zipCode) {
    return '';
  }
  const companyAddress = filter(
    [firstLineAddress, zipCode, city, country],
    Boolean
  ).join(', ');
  return `${companyAddress}.`;
};
