import { ComponentLoader } from '@/components/ComponentLoader';
import { useAggregateInvoicesByFinanciers } from '@/hooks';
import { Financier } from '@/schemas';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { invoicingCurrencies } from '@treyd-io/core/constants/country';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { filter, find, isEmpty, map, reduce, split, toNumber } from 'lodash';

type FinancierCurrencyRow = {
  financier: string;
  currency: string;
  count: number;
  amount: number;
  paidAmount: number;
  remainingAmount: number;
};

type FinancierRow = {
  financierName: string;
  currenciesRows: FinancierCurrencyRow[];
};

const financierIdIndex = 1;
const currencyIndex = 3;

export const FinanciersTable = ({
  filters,
  allFinanciers,
}: {
  filters?: Filter[];
  allFinanciers?: Financier[];
}) => {
  const financierFilter = find(filters, { name: 'financier_id' });
  const currenciesFilter = find(filters, { name: 'invoiced_currency' });

  const financiersIds =
    (financierFilter?.comparisonValue as number[]) || map(allFinanciers, 'id');
  const currencies =
    (currenciesFilter?.comparisonValue as string[]) || invoicingCurrencies;

  const { data, loading: isAggregateInvoicesByFinanciersLoading } =
    useAggregateInvoicesByFinanciers({
      financiersIds,
      currencies,
      queryOptions: {
        filters: filter(
          filters,
          (filter) =>
            filter.name !== 'financier_id' &&
            filter.name !== 'invoiced_currency'
        ),
      },
      skip: isEmpty(financiersIds),
    });

  const rows = reduce(
    data,
    (
      financiers: FinancierRow[],
      financierCurrencyAggregate,
      financierCurrencyKey
    ) => {
      const keyParts = split(financierCurrencyKey, '_');
      const financierId = keyParts[financierIdIndex];
      const currency = keyParts[currencyIndex];

      const financierName =
        find(
          allFinanciers,
          (financier) => financier.id === toNumber(financierId)
        )?.name || financierId;

      if (financierCurrencyAggregate.aggregate?.count) {
        if (!find(financiers, { financierName: financierName }))
          financiers.push({
            financierName,
            currenciesRows: [],
          });

        const financierRow = find(financiers, {
          financierName: financierName,
        }) as FinancierRow;

        financierRow.currenciesRows.push({
          financier: financierName,
          currency,
          count: financierCurrencyAggregate.aggregate?.count,
          amount: financierCurrencyAggregate.aggregate?.sum?.invoiced_amount,
          paidAmount: financierCurrencyAggregate.aggregate?.sum?.paid_amount,
          remainingAmount:
            financierCurrencyAggregate.aggregate?.sum?.remaining_amount,
        });
      }
      return financiers;
    },
    []
  );

  if (isAggregateInvoicesByFinanciersLoading) return <ComponentLoader />;

  if (isEmpty(rows)) return;

  return (
    <Stack gap={2}>
      <Typography variant="h6">Amounts by financiers</Typography>
      <TableContainer
        sx={{
          border: 1,
          borderRadius: 2,
          borderColor: 'divider',
          maxHeight: 400,
        }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Financier
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Currency
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Number of invoices
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Amount
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  Paid amount
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="text.secondary">
                  Remaining amount
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(rows, (financierRow, financierRowIndex) => {
              const financierName = financierRow.financierName;
              const currenciesRows = financierRow.currenciesRows;
              return (
                <>
                  <TableRow
                    key={financierName}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor:
                        financierRowIndex % 2 !== 0 ? 'grey.100' : '',
                    }}>
                    <TableCell rowSpan={currenciesRows.length + 1}>
                      {financierName}
                    </TableCell>
                  </TableRow>
                  {map(currenciesRows, (financierCurrency, index) => {
                    return (
                      <TableRow
                        key={`${financierCurrency.financier} ${financierCurrency.currency} ${index}`}
                        sx={{
                          backgroundColor:
                            financierRowIndex % 2 !== 0 ? 'grey.100' : '',
                        }}>
                        <TableCell>{financierCurrency.currency}</TableCell>
                        <TableCell>{financierCurrency.count}</TableCell>
                        <TableCell>
                          {formatCurrency(
                            financierCurrency.amount,
                            financierCurrency.currency
                          )}
                        </TableCell>
                        <TableCell>
                          {formatCurrency(
                            financierCurrency.paidAmount,
                            financierCurrency.currency
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(
                            financierCurrency.remainingAmount,
                            financierCurrency.currency
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
