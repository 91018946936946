import { useGetInvoices } from '@/hooks';
import { InvoicesCsvService, InvoicesTransactionsCsvService } from '@/services';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Filter } from '@treyd-io/core/types/hasura';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import ExportIcon from '@treyd-io/treyd-ui/icons/ExportIcon';
import FilesIcon from '@treyd-io/treyd-ui/icons/FilesIcon';
import SwapIcon from '@treyd-io/treyd-ui/icons/SwapIcon';
import { MouseEvent, useState } from 'react';

interface ExportInvoicesButtonProps {
  filters?: Filter[];
  buttonProps?: LoadingButtonProps;
  enableExportTransactions?: boolean;
}

export const ExportInvoicesButton = (props: ExportInvoicesButtonProps) => {
  const { buttonProps, filters, enableExportTransactions } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleExportButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [isExporting, setIsExporting] = useState(false);
  const showToastMessage = useToastNotification();
  const invoicesCSVService = new InvoicesCsvService();
  const invoicesTransactionsCSVService = new InvoicesTransactionsCsvService();

  const { refetch: getExportedInvoices } = useGetInvoices({
    queryOptions: {
      filters,
    },
    skip: true,
  });

  const handleExportInvoices = async () => {
    setIsExporting(true);
    const response = await getExportedInvoices();
    const isExported = invoicesCSVService.exportToCsv(
      response.data?.orders_order_invoices
    );

    if (isExported) {
      showToastMessage({
        type: 'success',
        title: 'Invoices exported',
      });
    } else {
      showToastMessage({
        type: 'warning',
        title: 'No invoices found',
      });
    }
    setIsExporting(false);
  };

  const handleExportTransactions = async () => {
    setIsExporting(true);
    const response = await getExportedInvoices();
    const isExported = invoicesTransactionsCSVService.exportToCsv(
      response.data?.orders_order_invoices
    );
    if (isExported) {
      showToastMessage({
        type: 'success',
        title: 'Transactions exported',
      });
    } else {
      showToastMessage({
        type: 'warning',
        title: 'No transactions found',
      });
    }
    setIsExporting(false);
  };

  if (enableExportTransactions) {
    return (
      <>
        <LoadingButton
          size="large"
          variant="outlined"
          color="secondary"
          loading={isExporting}
          fullWidth
          startIcon={<ExportIcon />}
          onClick={(e) => {
            handleExportButtonClick(e);
            buttonProps?.onClick?.(e);
          }}
          {...buttonProps}>
          Export
        </LoadingButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          MenuListProps={{
            disablePadding: true,
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: 2,
                backgroundColor: 'background.default',
              },
            },
          }}>
          <MenuItem
            divider
            onClick={() => {
              handleExportInvoices();
              handleMenuClose();
            }}>
            <ListItemIcon>
              <FilesIcon />
            </ListItemIcon>
            <ListItemText>Invoices</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleExportTransactions();
              handleMenuClose();
            }}>
            <ListItemIcon>
              <SwapIcon />
            </ListItemIcon>
            <ListItemText>Transactions</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  }

  return (
    <LoadingButton
      size="large"
      variant="outlined"
      color="secondary"
      loading={isExporting}
      fullWidth
      startIcon={<ExportIcon />}
      onClick={(e) => {
        handleExportInvoices();
        buttonProps?.onClick?.(e);
      }}
      {...buttonProps}>
      Export
    </LoadingButton>
  );
};
