import { CreditDecision } from '@/types';
import { TypedDocumentNode, gql } from '@apollo/client';

type getCreditDecisionQueryTypes = {
  django: { approved_credit_decision: CreditDecision | null };
};

export const getCreditDecisionData = gql`
  query getCreditDecision($orgnr: String!, $country: String!) {
    django {
      approved_credit_decision(orgnr: $orgnr, country: $country) {
        is_approved
        is_published
        approved_limit
        approved_limit_currency
        created
        expiration_date
        published_at
        status
      }
    }
  }
` as TypedDocumentNode<getCreditDecisionQueryTypes, {}>;
