import { Orders_Bank_Accounts } from '@/__generated__/graphql';
import { ROUTES } from '@/constants';
import { List, ListItem, Typography } from '@mui/material';
import { isEmpty, map } from 'lodash';
import { Link } from 'react-router-dom';

interface SimilarBankAccountsProps {
  similarBankAccounts?: Orders_Bank_Accounts[];
}
export const SimilarBankAccounts = (props: SimilarBankAccountsProps) => {
  const { similarBankAccounts } = props;
  if (isEmpty(similarBankAccounts)) return null;

  return (
    <List sx={{ listStyleType: 'disc', pl: 2 }}>
      {map(similarBankAccounts, (bankAccount) => (
        <ListItem sx={{ display: 'list-item', py: 0, px: 1 }}>
          <Typography
            variant="body2"
            component={Link}
            to={`${ROUTES.suppliers}/${bankAccount.supplier?.id}`}>
            {bankAccount.supplier?.name}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};
