import { TypedDocumentNode, gql } from '@apollo/client';
export type InsertFeatureFlagMutationType = {
  insert_orders_orders_app_companyfeatureflag: { affected_rows: number };
};

export type InsertFeatureFlagVariablesType = {
  featureFlag: {
    name: string;
    enabled: boolean;
  };
};

export const insertFeatureFlagMutation = gql`
  mutation insertFeatureFlag(
    $featureFlag: [orders_orders_app_companyfeatureflag_insert_input!]!
  ) {
    insert_orders_orders_app_companyfeatureflag(objects: $featureFlag) {
      affected_rows
    }
  }
` as TypedDocumentNode<
  InsertFeatureFlagMutationType,
  InsertFeatureFlagVariablesType
>;
