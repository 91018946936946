import { getEmailLogDetailsQuery } from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetEmailLogDetails = (emailLogId: number) =>
  useQuery(getEmailLogDetailsQuery, {
    skip: !Boolean(emailLogId),
    variables: {
      emailLogId,
    },
  });
