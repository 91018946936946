import { SUPPORTING_FILES_DOCUMENTS_TAGS } from '@/constants';
import { useDeleteAttachment, useUpsertAttachment } from '@/hooks';
import { Chip, List, ListItem, Stack, Typography } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { Attachment } from '@treyd-io/treyd-ui/types/file';
import { Dictionary, map } from 'lodash';
import { UploadCard } from './UploadCard';

interface SupportingFilesSectionProps {
  attachments: Dictionary<Attachment[]>;
  orgNo: string;
  country: string;
  onFileUploadComplete: () => void;
}

export const SupportingFilesSection = (props: SupportingFilesSectionProps) => {
  const { attachments, orgNo, country, onFileUploadComplete } = props;
  const [uploadAttachment, { loading: isLoading }] = useUpsertAttachment();
  const [deleteAttachment] = useDeleteAttachment();
  const showToastMessage = useToastNotification();

  const LIST_ITEMS = [
    'HMRC tax summary (This is the summary page of your HMRC business tax account)',
    'Projections',
    'POs (purchase orders)',
    'Term sheet (equity raise)',
    'Commitment letter (for capital injection, shareholder support, etc.)',
  ];

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h6">Supporting files</Typography>
          <Chip
            label={'Optional'}
            variant="filled"
            color="primary"
            size="small"
          />
        </Stack>
        <Stack gap={1}>
          <Typography variant="body1">
            Here you can upload any files that you might find relevant for the
            business analysis. Those listed below can potentially help us have a
            more in-depth analysis of the business.
          </Typography>
          <List sx={{ listStyleType: 'disc', paddingLeft: 2 }} disablePadding>
            {map(LIST_ITEMS, (item, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: 'list-item' }}>
                <Typography variant="body1">{item}</Typography>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Stack>

      <UploadCard
        title={'Supporting files'}
        subtitle={'Any time period is acceptable.'}
        files={
          attachments[SUPPORTING_FILES_DOCUMENTS_TAGS.OTHER_SUPPORTING_FILES]
        }
        isLoading={isLoading}
        onDelete={(id) =>
          id &&
          deleteAttachment({
            variables: {
              id,
            },
            onCompleted: onFileUploadComplete,
            onError: () => {
              showToastMessage({
                message: 'Something went wrong',
                type: 'error',
              });
            },
          })
        }
        uploadFile={(file) => {
          const reader = new FileReader();
          reader.onload = () => {
            uploadAttachment({
              variables: {
                country: country,
                dataurl: reader?.result,
                description: '',
                filename: file?.name,
                orgnr: orgNo,
                tag: SUPPORTING_FILES_DOCUMENTS_TAGS.OTHER_SUPPORTING_FILES,
              },
              onCompleted: onFileUploadComplete,
              onError: () => {
                showToastMessage({
                  message: 'Something went wrong',
                  type: 'error',
                });
              },
            });
          };
          reader.readAsDataURL?.(file);
        }}
      />
    </Stack>
  );
};
