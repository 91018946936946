import {
  getOcrRows,
  getOcrRowsState,
} from '@/pages/orderReview/OrderReviewDetails/OrderInformationTableSection/utils';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';

const OcrStatusIcon = ({ ocrData }: { ocrData: any }) => {
  const { ocrRows } = getOcrRows(ocrData);

  const { isAllRowsReviewed } = getOcrRowsState(ocrRows);

  if (isAllRowsReviewed) return <CheckCircleIcon color="success" />;

  return <></>;
};

export default OcrStatusIcon;
