import { Button, ButtonProps, useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';
import IconButton, { IconButtonProps } from './IconButton';

type SharedPropsKeys = keyof ButtonProps & keyof IconButtonProps;
type SharedProps = {
  [SharedKey in SharedPropsKeys]?:
    | ButtonProps[SharedKey]
    | IconButtonProps[SharedKey];
};

type ResponsiveButtonProps = SharedProps & {
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
} & {
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};

const ResponsiveButton = (props: ResponsiveButtonProps) => {
  const { iconButtonProps, buttonProps, ...sharedProps } = props;
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('sm'));

  if (iconButtonProps && !buttonProps) {
    return <IconButton {...sharedProps} {...iconButtonProps} />;
  }

  if (buttonProps && !iconButtonProps) {
    return <Button {...sharedProps} {...buttonProps} />;
  }

  return isSmallScreens ? (
    <IconButton {...sharedProps} {...iconButtonProps} />
  ) : (
    <Button {...sharedProps} {...buttonProps} />
  );
};

export default ResponsiveButton;
