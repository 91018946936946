import { BrowsingHistoryContext } from 'context';
import { findLastIndex, isEmpty, isEqual, last, remove } from 'lodash';
import { ReactNode, useRef } from 'react';
import {
  Location,
  NavigationType,
  Path,
  createPath,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

export const BrowsingHistoryProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigationActionType = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate();
  const { current: past } = useRef<Partial<Location>[]>([]);
  const present = useRef<Partial<Location>>(location);
  const { current: future } = useRef<Partial<Location>[]>([]);

  useUpdateEffect(() => {
    switch (navigationActionType) {
      case NavigationType.Push:
        remove(future);
        past.push(present.current);
        present.current = location;
        break;

      case NavigationType.Pop: {
        // Handle Browser navigation cases
        // Browser always using POP action
        if (isEqual(last(future), location)) {
          // In case of moving Forward
          past.push(present.current);
          present.current = future.pop()!;
          break;
        }

        // In case of moving Backward
        // If the user entered the app with old browsing history
        if (isEmpty(past)) {
          present.current = location;
          break;
        }
        // If the user entered the app from a Blank tab
        future.push(present.current);
        present.current = past.pop()!;
        break;
      }

      case NavigationType.Replace:
        remove(future);
        present.current = location;
        break;
    }
  }, [location, navigationActionType]);

  const goBack = () => {
    const lastLocationIndex = findLastIndex(
      past,
      (location) => location.pathname !== present.current.pathname
    );

    if (lastLocationIndex === -1 || isEmpty(past)) navigate('/');
    else {
      const to = past.length - lastLocationIndex;
      navigate(-to);
    }
  };

  return (
    <BrowsingHistoryContext.Provider
      value={{
        past,
        present: present.current,
        future,
        createUrl,
        goBack,
      }}>
      {children}
    </BrowsingHistoryContext.Provider>
  );
};

const createUrl = (pathOptions: Path) => createPath(pathOptions);
