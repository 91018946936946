import { Orders_Vouchers_App_Credit_Aggregate } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

export type InvoiceCreditAggregate = Pick<
  Orders_Vouchers_App_Credit_Aggregate,
  'aggregate'
>;
export type GetInvoiceCreditsQueryVariables = {
  invoiceSystemRef: number;
};
export type GetInvoiceCreditsTypes = {
  orders_vouchers_app_credit_aggregate: InvoiceCreditAggregate;
};

export const getCreditAmountByInvoiceQuery = gql`
  query getCreditAmountByInvoice($invoiceSystemRef: Int!) {
    orders_vouchers_app_credit_aggregate(
      where: {
        _and: {
          creditable_type: { _eq: "invoice" }
          creditable_id: { _eq: $invoiceSystemRef }
        }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
` as TypedDocumentNode<GetInvoiceCreditsTypes, GetInvoiceCreditsQueryVariables>;
