import { Lager_Risk_Creditdecisionrequirement } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

export type Requirement = Pick<
  Lager_Risk_Creditdecisionrequirement,
  'is_fulfilled' | 'id' | 'type' | 'display_name'
>;

type GetNewCreditDecisionRequirementsQueryType = {
  lager_risk_creditdecision: {
    creditdecision_requirements: Array<Requirement>;
  }[];
};

export const getNewCreditDecisionRequirements = gql`
  query getNewCreditDecisionRequirements($companyId: bigint!) {
    lager_risk_creditdecision(
      where: {
        status: { _in: ["open", "approved"] }
        core_company: { orders_company: { id: { _eq: $companyId } } }
      }
    ) {
      creditdecision_requirements {
        is_fulfilled
        id
        type
        display_name
      }
    }
  }
` as TypedDocumentNode<
  GetNewCreditDecisionRequirementsQueryType,
  { companyId: number }
>;
