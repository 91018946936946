import { getCompanyStatuses } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetCompanyStatuses = (companyId: number) =>
  useQuery({
    queryKey: [`company-statuses`, companyId],
    queryFn: () => getCompanyStatuses(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
    select: (data) => data.data,
  });
