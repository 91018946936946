import { getWalletEntities } from '@/api/wallet';
import { useQuery } from '@tanstack/react-query';

export const useGetWalletEntities = ({
  country,
  orgNumber,
}: {
  country: string;
  orgNumber: string;
}) =>
  useQuery({
    queryKey: [`wallet-entities`, country, orgNumber],
    queryFn: () => getWalletEntities(country, orgNumber),
    refetchOnWindowFocus: false,
    enabled: !!country || !!orgNumber,
    select: (data) => data.data,
  });
