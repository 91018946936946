import {
  endOfToday,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSaturday,
  isSunday,
  isToday,
  isYesterday,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { includes } from 'lodash';

const formatOptions: Record<
  | 'date'
  | 'time'
  | 'datetime'
  | 'longdate'
  | 'longdatetime'
  | 'longDateWithoutDay',
  Intl.DateTimeFormatOptions
> = {
  date: { dateStyle: 'short' },
  time: { timeStyle: 'medium' },
  datetime: { dateStyle: 'short', timeStyle: 'short' },
  longdatetime: {
    hourCycle: 'h24',
    dateStyle: 'long',
    timeStyle: 'short',
  },
  longdate: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  longDateWithoutDay: {
    month: 'long',
    year: 'numeric',
  },
};

type DateFormat = keyof typeof formatOptions | 'daytime';

export const formatDate = (
  date: Date,
  type: DateFormat,
  locale: string = 'en-SE'
): string => {
  if (!date) return '';

  if (type === 'daytime') {
    if (isToday(date)) {
      return `Today ${format(date, 'kk:mm')}`;
    }

    if (isYesterday(date)) {
      return `Yesterday ${format(date, 'kk:mm')}`;
    }

    return `${format(date, 'yyyy-MM-dd')} at ${format(date, 'kk:mm')}`;
  }
  return new Intl.DateTimeFormat(locale, formatOptions[type]).format(date);
};

export const now = (locale = 'en-SE') =>
  new Intl.DateTimeFormat(locale).format(new Date());

export const isWeekend = (date: Date) => {
  return isSaturday(date) || isSunday(date);
};

export const isExceedTodayTime = (
  date: Date,
  time: {
    hours?: number;
    minutes?: number;
    seconds?: number;
  }
) => {
  const now = new Date();
  const { hours = 0, minutes = 0, seconds = 0 } = time;
  const lastAvailable = setHours(
    setMinutes(setSeconds(now, seconds), minutes),
    hours
  );
  return (
    isToday(date) && isAfter(now, lastAvailable) && isBefore(now, endOfToday())
  );
};

export const newISODate = (dateStr?: string) => {
  if (!dateStr) {
    return new Date();
  }
  const utcTimezoneOffset = '+00:00';
  if (dateStr.includes('+')) {
    return new Date(dateStr);
  } else {
    return new Date(`${dateStr}${utcTimezoneOffset}`);
  }
};

export namespace Payment {
  export const isHoliday = (date: Date, holidays?: string[]) =>
    includes(holidays, formatDate(date, 'date'));

  export const isBeforeNearestWorkingDate = (
    date: Date,
    nearestWorkingDate: Date
  ) => isBefore(date, nearestWorkingDate);

  export const isBetweenDesiredPaymentDateAndNextExecutionDate = (
    date: Date,
    desiredPaymentDate: Date,
    nextExecutionDate: Date
  ) =>
    (isBefore(date, nextExecutionDate) && isAfter(date, desiredPaymentDate)) ||
    (isEqual(date, desiredPaymentDate) && isBefore(date, nextExecutionDate));
}
