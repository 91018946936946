import { Lager_Core_Company, Orders_Companies } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

type GetMerchant = {
  orders_companies_by_pk: Pick<
    Orders_Companies,
    'rating' | 'name' | 'created_at'
  > & { core_company: Pick<Lager_Core_Company, 'uid'> };
};
type GetMerchantVariables = {
  merchantId?: number;
};
export const getMerchantQuery = gql`
  query getMerchant($merchantId: bigint!) {
    orders_companies_by_pk(id: $merchantId) {
      name
      rating
      created_at
      core_company {
        uid
      }
    }
  }
` as TypedDocumentNode<GetMerchant, GetMerchantVariables>;
