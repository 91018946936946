import { Avatar } from '@mui/material';

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const stringAvatar = (
  firstName: string,
  lastName: string,
  sx: externalStyle
) => ({
  sx: {
    ...sx,
    bgcolor: stringToColor(`${firstName} ${lastName}`),
  },
  children: `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`,
});

type externalStyle = Object | undefined;

interface UserAvatarProps {
  firstName: string;
  lastName: string;
  sx?: externalStyle;
}
const UserAvatar = (props: UserAvatarProps) => (
  <>
    {props.firstName && props.lastName ? (
      <Avatar {...stringAvatar(props.firstName, props.lastName, props?.sx)} />
    ) : (
      <Avatar {...stringAvatar('Mock', 'User', props?.sx)} />
    )}
  </>
);

export default UserAvatar;
