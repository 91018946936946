import { getVouchersQuery } from '@/schemas/getVouchersQuery';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetVouchers = ({
  queryOptions,
  skip,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(getVouchersQuery(queryOptions), {
    skip,
  });
