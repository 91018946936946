import { ComplianceDecision } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';

export const getComplianceDecisionQuery = gql`
  query getComplianceDecision($orgnr: String!, $country: String!) {
    lager_risk_compliancedecision(
      where: {
        core_company: { orgnr: { _eq: $orgnr }, country: { _eq: $country } }
      }
      limit: 1
      offset: 0
      order_by: { created: desc }
    ) {
      final_risk_level
    }
  }
` as TypedDocumentNode<
  { lager_risk_compliancedecision: ComplianceDecision[] },
  { orgnr?: string; country?: string }
>;
