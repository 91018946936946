import {
  ERP_INVOICE_PDF_TYPES,
  GET_ERP_INVOICE_ATTACHMENTS,
  INVOICES,
} from '@/constants';
import { ErpInvoices, Invoice } from '@/types';
import { get } from '@treyd-io/core/utils/request';

export const getInvoiceById = (invoiceId: number) =>
  get<Invoice>(INVOICES(invoiceId));

export const getErpInvoicesPdf = ({
  type,
  uuid,
}: {
  type: ERP_INVOICE_PDF_TYPES;
  uuid: string;
}) =>
  get<ErpInvoices.ErpInvoicesPDFResponse>(
    GET_ERP_INVOICE_ATTACHMENTS({ type, uuid })
  );
