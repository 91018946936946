import { TimelineStation } from '@/pages/merchants/MerchantsDetails/Tabs/GeneralInfo/types';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Typography } from '@mui/material';

interface StepTimelineProps {
  timelineStations: TimelineStation[];
}

export const StepTimeline = (props: StepTimelineProps) => {
  const { timelineStations } = props;
  return (
    <Timeline
      sx={{
        margin: 0,
        padding: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
      {timelineStations.map((station, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot variant="outlined" />
            {index !== timelineStations.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            {typeof station.title === 'string' ? (
              <Typography variant="body1" color="text.primary">
                {station.title}
              </Typography>
            ) : (
              station.title
            )}
            {station.timeStamp && (
              <Typography variant="caption" color="text.secondary">
                {station.timeStamp}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
