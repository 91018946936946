import { SearchField } from '@/components';
import { ComponentLoader } from '@/components/ComponentLoader';
import { treydSystemEmail } from '@/constants';
import { useGetTableSettings, useGetTreydUsers } from '@/hooks';
import useSearchTerm from '@/hooks/useSearchTerm';
import { NotificationsTableFiltersFields } from '@/types';
import { Button, Stack } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import PlusIcon from '@treyd-io/treyd-ui/icons/PlusIcon';
import { find, toString } from 'lodash';
import { useToggle } from 'react-use';
import NotificationDialog from '../components/NotificationDialog';
import { NotificationsTable } from './NotificationsTable';
import { TableFilters } from './TableFilters';

export const NotificationsPage = () => {
  const { searchTerm, setSearchTerm } = useSearchTerm(
    'notificationsSearchTerm'
  );
  const [open, toggle] = useToggle(false);
  const {
    notificationsTableSettings: { filterFields, setFilterFields, resetTable },
  } = useGetTableSettings();

  const { data, loading: isAllTreydUsersLoading } = useGetTreydUsers();
  const allTreydUsers = data?.lager_treyd_users;
  const treydSystemId = find(allTreydUsers, { email: treydSystemEmail })?.id;

  const searchFields: QuerySearchField<NotificationsTableFiltersFields>[] = [
    {
      type: 'search',
      name: 'notification_template__header',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
  ];

  const filters: Filter<NotificationsTableFiltersFields>[] = [
    ...searchFields,
    ...(filterFields || []),
    {
      type: 'filter',
      name: 'created_by',
      comparator: FilterComparator.NOT_IN,
      comparisonValue: [`${treydSystemId}`],
    },
  ];

  if (isAllTreydUsersLoading) return <ComponentLoader />;

  return (
    <Stack flexGrow={1} gap={5} height="100%">
      <Stack flexGrow={1} gap={3} height="100%">
        <Row>
          <Col xs={12} sm>
            <SearchField
              placeholder={'Search by notification name'}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Col>
          <Col xs={12} sm="auto">
            <TableFilters
              onSubmit={setFilterFields}
              onReset={resetTable}
              allTreydUsers={allTreydUsers}
              filters={filterFields}
            />
          </Col>
          <Col xs={12} sm="auto">
            <Button
              startIcon={<PlusIcon />}
              variant="contained"
              color="primary"
              fullWidth
              onClick={toggle}
              size="large">
              Add Notification
            </Button>
          </Col>
        </Row>
        <NotificationsTable filters={filters} />
      </Stack>
      {open && <NotificationDialog open={open} toggle={toggle} />}
    </Stack>
  );
};
