import {
  Orders_Companies,
  Unified_Supplier_Invoice,
} from '@/__generated__/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';

export type AllSupplierErpInvoice = Pick<
  Unified_Supplier_Invoice,
  | 'uuid'
  | 'invoice_reference'
  | 'issue_date'
  | 'due_date'
  | 'invoice_status'
  | 'total_amount'
  | 'currency'
  | 'balance'
  | 'supplier_name'
> & {
  erp_connection: {
    integration?: string;
    lager_erp_connection: {
      core_company: {
        company: Pick<Orders_Companies, 'name' | 'id' | 'segment'>;
      };
    };
  };
};

type GetAllSuppliersErpInvoicesQueryType = {
  unified_supplier_invoice: AllSupplierErpInvoice[];
  unified_supplier_invoice_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export const getAllSuppliersErpInvoicesQuery = (
  queryOptions?: QueryOptions
) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);
  return gql`
	query getAllSuppliersErpInvoicesQuery {
		unified_supplier_invoice ${queryParams} {
			uuid
			invoice_reference
			issue_date
			due_date
			invoice_status
			total_amount
			currency
			balance
			supplier_name				
			erp_connection {
				integration
				lager_erp_connection {
					core_company {
						company  {
							name
            	id
							segment
						}
					}
				}
}
}
	unified_supplier_invoice_aggregate ${
    whereClause ? `(where: ${whereClause})` : ''
  } {
    aggregate {
      count
    }
  }
}
  ` as TypedDocumentNode<GetAllSuppliersErpInvoicesQueryType>;
};
