import { MERCHANT_SEGMENT, ROUTES } from '@/constants';
import { Stack, Tooltip, Typography } from '@mui/material';
import { toSentenceCase } from '@treyd-io/core/utils/string';
import HeartIcon from '@treyd-io/treyd-ui/icons/HeartIcon';
import { Link } from 'react-router-dom';

export const MerchantWithLink = ({
  id,
  name,
  segment,
}: {
  id: string;
  name: string;
  segment: MERCHANT_SEGMENT;
}) => {
  if (segment === MERCHANT_SEGMENT.WHITE_GLOVE) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography
          maxWidth={150}
          noWrap
          component={Link}
          to={`${ROUTES.merchants}/${id}`}>
          {name}
        </Typography>
        <Tooltip title={toSentenceCase(segment)} arrow placement="top">
          <Stack>
            <HeartIcon
              style={{
                fill: 'info.500',
              }}
              sx={{
                color: 'info.500',
              }}
            />
          </Stack>
        </Tooltip>
      </Stack>
    );
  }
  return (
    <Typography noWrap component={Link} to={`${ROUTES.merchants}/${id}`}>
      {name}
    </Typography>
  );
};
