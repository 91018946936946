import {
  INVOICE_OPS_STATUS,
  invoiceClosedAndPassedDueDateOpsStatuses,
  invoicePassedDueDateOpsStatuses,
  treydInvoicingSystem,
} from '@/constants';
import { includes } from 'lodash';

export namespace InvoiceService {
  export const doesInvoicePassDueDate = (
    days: number,
    invoiceStatus: INVOICE_OPS_STATUS
  ) => {
    if (includes(invoiceClosedAndPassedDueDateOpsStatuses, invoiceStatus))
      return null;
    if (includes(invoicePassedDueDateOpsStatuses, invoiceStatus) || days > 0)
      return true;
    return false;
  };

  export const isOldInvoicingSystem = (system?: string | null) =>
    system !== treydInvoicingSystem;

  export const getInvoiceClosedDate = (
    closedDate?: string,
    closedDateOverwrite?: string
  ) => {
    return closedDateOverwrite || closedDate;
  };
}
