import { getUpdatesByOrderIdQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ClockIcon } from '@mui/x-date-pickers';
import DialogCloseButton from '@treyd-io/treyd-ui/components/DialogCloseButton';
import ResponsiveButton from '@treyd-io/treyd-ui/components/ResponsiveButton';
import { isEmpty, toString } from 'lodash';

import { useGetOrderLogsVisualization } from '@/hooks/useGetOrderLogsVisualization';
import { Mermaid } from '@/pages/orders/OrderDetails/NonDraftOrderView/DetailsTab/Mermaid';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

export const UpdatesDialog = () => {
  const theme = useTheme();
  const [open, toggleOpen] = useToggle(false);
  const params = useParams();
  const { data } = useQuery(getUpdatesByOrderIdQuery, {
    variables: { orderId: Number(params.id) },
  });
  const { data: diagramData, isPending: isDiagramDataLoading } =
    useGetOrderLogsVisualization(Number(params.id));

  const isSmallScreens = useMediaQuery(theme.breakpoints.down('sm'));

  if (isDiagramDataLoading) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <ResponsiveButton
        disabled={isEmpty(data?.orders_notification_center_notification)}
        onClick={toggleOpen}
        color="info"
        buttonProps={{
          startIcon: <ClockIcon />,
          children: 'View updates diagram',
        }}
        iconButtonProps={{
          children: <ClockIcon />,
        }}
      />
      <Dialog
        open={open}
        onClose={toggleOpen}
        fullWidth
        maxWidth="sm"
        fullScreen={isSmallScreens}>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h5" noWrap color="text.primary">
            All updates for order #{params.id}
          </Typography>
          <DialogCloseButton onClick={toggleOpen} />
        </DialogTitle>

        <DialogContent sx={{ paddingBlockEnd: 3 }}>
          <Stack gap={1}>
            <Typography variant="body1">
              Below is a log of all past events for this order.
            </Typography>
            <Stack alignItems="center" justifyContent="center">
              <Mermaid chart={toString(diagramData?.order_diagram)} />
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
