import NewVersionReload from '@/components/NewVersionReload';
import { ROUTES } from '@/constants';
import { AppNavBarLayout } from '@/layouts';
import MaintenanceMode from '@treyd-io/treyd-ui/components/MaintenanceMode';
import { AppNavBarProvider, BrowsingHistoryProvider } from 'providers';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { TableSettingsProvider } from './providers/TableSettingsProvider';

export const RootLayout = () => {
  const location = useLocation();

  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true')
    return <MaintenanceMode />;

  if (location.pathname === '/') return <Navigate to={`${ROUTES.home}`} />;

  return (
    <BrowsingHistoryProvider>
      <TableSettingsProvider>
        <AppNavBarProvider>
          <AppNavBarLayout>
            <Outlet />
            <NewVersionReload />
          </AppNavBarLayout>
        </AppNavBarProvider>
      </TableSettingsProvider>
    </BrowsingHistoryProvider>
  );
};
