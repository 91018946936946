import {
  GetCompanyDesiredLimitQueryVariables,
  getCompanyDesiredLimitQuery,
} from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetCompanyDesiredLimit = (
  variables: GetCompanyDesiredLimitQueryVariables
) =>
  useQuery(getCompanyDesiredLimitQuery, {
    variables,
    skip: !variables?.companyId,
  });
