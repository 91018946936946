import { sendPOA } from '@/api';
import { Documents } from '@/types';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

export const useSendPOA = () =>
  useMutation<
    AxiosResponse<{ payload: Documents.DocumentType }>,
    AxiosError,
    Documents.Payload
  >({
    mutationKey: ['send-POA'],
    mutationFn: sendPOA,
  });
