import {
  GetCompanyVouchersVariables,
  getCompanyVouchersQuery,
} from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetCompanyVouchers = (variables: GetCompanyVouchersVariables) =>
  useQuery(getCompanyVouchersQuery, {
    variables,
    skip: !Boolean(variables?.companyId),
  });
