import { gql, TypedDocumentNode } from '@apollo/client';
import {
  Orders_Orders as Orders,
  Orders_Active_Order_Review,
} from '__generated__/graphql';

export type GetOrderReviewer = {
  orders_orders_by_pk: Pick<Orders, 'id'> & {
    active_order_review: Pick<
      Orders_Active_Order_Review,
      'reviewer_id' | 'user' | 'data'
    > | null;
  };
};

type QueryVariables = { order_id?: number };
export const getOrderReviewer = gql`
  query getOrderReviewer($order_id: bigint!) {
    orders_orders_by_pk(id: $order_id) {
      active_order_review {
        reviewer_id
        data
        user {
          name
          first_name
          last_name
        }
      }
    }
  }
` as TypedDocumentNode<GetOrderReviewer, QueryVariables>;
