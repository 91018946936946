import { INVOICE_OPS_STATUS, INVOICE_TYPE } from '@/constants';
import { values } from 'lodash';

export interface PaymentInstruction {
  id: number;
  iban: string;
  type: string | null;
  swift: string;
  bankgiro: string | null;
  currency: string;
  bank_name: string;
  created_at: string;
  updated_at: string;
  account_number: string | null;
  remittance_text: string;
}
interface LineItem {
  title: string;
  total_inc_vat: string;
  total_excl_vat: string;
  total_excl_vat_no_discount: string;
  discount: string;
  vat: number;
}

interface Financier {
  name: string;
  id: number;
  full_address: string;
}

export interface Invoice {
  id: number;
  invoice_type: INVOICE_TYPE;
  invoicing_system: string;
  invoicing_system_reference: string;
  invoicing_system_status: string;
  ocr: string;
  status: INVOICE_OPS_STATUS;
  ops_status: INVOICE_OPS_STATUS;
  created_at: string;
  updated_at: string;
  financier_id: number;
  order_id: number;
  parent_invoice_id: number;
  children_invoice_ids: number[];
  invoice_number: string;
  closed_date: string | null;
  delivery_method: string;
  due_date: string;
  financier: Financier;
  invoice_date: string;
  invoiced_amount: number;
  invoiced_amount_excl_vat: number;
  invoiced_currency: string;
  paid_amount: number;
  remaining_amount: number;
  vat_amount: number;
  credited: boolean;
  approaching_due_date: boolean;
  financier_name: string;
  payment_instruction: PaymentInstruction;
  pdf_url: string | null;
  pdf_preview_url: string;
  items: LineItem[];
  links: Record<string, string>;
}

export interface InvoicesTableFilters {
  invoiceDateStart: string;
  invoiceDateEnd: string;
  invoiceDueDateStart: string;
  invoiceDueDateEnd: string;
  statuses: INVOICE_OPS_STATUS[];
  types: INVOICE_TYPE[];
  financiers: string[];
  currencies: string[];
}

export type InvoicesTableFiltersFields = keyof InvoicesTableFilters;

export namespace ErpInvoices {
  export enum INVOICE_STATUS {
    PAID = 'Paid',
    VOID = 'Void',
    OPEN = 'Open',
  }
  export interface InvoicesTableFilters {
    issueDateStart: string;
    issueDateEnd: string;
    dueDateStart: string;
    dueDateEnd: string;
    statuses: INVOICE_STATUS[];
  }
  export type InvoicesTableFiltersFields = keyof InvoicesTableFilters;

  export const INVOICE_STATUSES = values(INVOICE_STATUS);

  export const INVOICE_STATUS_DISPLAYED_VALUE = {
    [INVOICE_STATUS.PAID]: 'Paid',
    [INVOICE_STATUS.VOID]: 'Void',
    [INVOICE_STATUS.OPEN]: 'Open',
  };

  export interface ErpInvoicesPDFResponse {
    id: number;
    invoice_uuid: string;
    file: string;
    created_at: string;
    signed_url: string;
  }
}
