import { getLimitStatus } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetLimitStatus = (companyId: number) =>
  useQuery({
    queryKey: [`limit-status`, companyId],
    queryFn: () => getLimitStatus(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
    select: (data) => data.data,
  });
