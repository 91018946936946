import { FiscalYearSubtitle } from '@/components';
import { BALANCE_SHEET_DOCUMENTS_TAGS } from '@/constants';
import { useDeleteAttachment, useUpsertAttachment } from '@/hooks';
import { Stack, Typography } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { Attachment } from '@treyd-io/treyd-ui/types/file';
import { Dictionary } from 'lodash';
import { UploadCard } from './UploadCard';

interface BalanceSheetSectionProps {
  attachments: Dictionary<Attachment[]>;
  orgNo: string;
  country: string;
  onFileUploadComplete: () => void;
}

export const BalanceSheetSection = (props: BalanceSheetSectionProps) => {
  const { attachments, orgNo, country, onFileUploadComplete } = props;
  const [uploadAttachment, { loading: isLoading }] = useUpsertAttachment();
  const [deleteAttachment] = useDeleteAttachment();
  const showToastMessage = useToastNotification();

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="h6">Balance sheet</Typography>
        <Typography variant="body2">
          Balance sheets will help us understand company's assets, liabilities,
          and shareholder equity.
        </Typography>
      </Stack>
      <UploadCard
        title={
          'Report: current year (latest month available) and last fiscal year'
        }
        subtitle={<FiscalYearSubtitle />}
        files={attachments[BALANCE_SHEET_DOCUMENTS_TAGS.BALANCE_SHEET]}
        isLoading={isLoading}
        onDelete={(id) =>
          id &&
          deleteAttachment({
            variables: {
              id,
            },
            onCompleted: onFileUploadComplete,
            onError: () => {
              showToastMessage({
                message: 'Something went wrong',
                type: 'error',
              });
            },
          })
        }
        uploadFile={(file) => {
          const reader = new FileReader();
          reader.onload = () => {
            uploadAttachment({
              variables: {
                country: country,
                dataurl: reader?.result,
                description: '',
                filename: file?.name,
                orgnr: orgNo,
                tag: BALANCE_SHEET_DOCUMENTS_TAGS.BALANCE_SHEET,
              },
              onCompleted: onFileUploadComplete,
              onError: () => {
                showToastMessage({
                  message: 'Something went wrong',
                  type: 'error',
                });
              },
            });
          };
          reader.readAsDataURL?.(file);
        }}
      />
    </Stack>
  );
};
