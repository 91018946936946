import {
  INVOICE_OPS_STATUS,
  INVOICE_TYPE,
  invoiceStatuses,
  invoiceStatusesDisplayedValue,
  invoiceTypes,
  invoiceTypesDisplayedValue,
} from '@/constants';
import { Financier } from '@/schemas';
import {
  Autocomplete,
  Badge,
  Button,
  Chip,
  Divider,
  Menu,
  Stack,
  TextField,
  Typography,
  autocompleteClasses,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { invoicingCurrencies } from '@treyd-io/core/constants/country';
import {
  FilterComparator,
  QueryFilterField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import FiltersIcon from '@treyd-io/treyd-ui/icons/FiltersIcon';
import { format, isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { find, isEmpty, map, startCase } from 'lodash';
import { useState } from 'react';

interface TableFiltersProps {
  allFinanciers?: Financier[];
  onSubmit: (filters: QueryFilterField[]) => void;
  appliedFilterFieldsCount: number;
}

interface FormData {
  invoiceDateStart: string;
  invoiceDateEnd: string;
  invoiceDueDateStart: string;
  invoiceDueDateEnd: string;
  statuses: INVOICE_OPS_STATUS[];
  types: INVOICE_TYPE[];
  financiers: string[];
  currencies: string[];
}

const INITIAL_VALUES: FormData = {
  invoiceDateStart: '',
  invoiceDateEnd: '',
  invoiceDueDateStart: '',
  invoiceDueDateEnd: '',
  statuses: [],
  types: [INVOICE_TYPE.INTEREST],
  financiers: [],
  currencies: [],
};

export const TableFilters = (props: TableFiltersProps) => {
  const { onSubmit, allFinanciers, appliedFilterFieldsCount } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClose = () => setAnchorEl(null);

  const handleformSubmit = (values: FormData) => {
    const {
      invoiceDateStart,
      invoiceDateEnd,
      invoiceDueDateStart,
      invoiceDueDateEnd,
      statuses,
      types,
      financiers,
      currencies,
    } = values;
    const filters: QueryFilterField[] = [];

    if (invoiceDateStart) {
      filters.push({
        type: 'filter',
        name: 'invoice_date',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: invoiceDateStart,
      });
    }

    if (invoiceDateEnd) {
      filters.push({
        type: 'filter',
        name: 'invoice_date',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: invoiceDateEnd,
      });
    }

    if (invoiceDueDateStart) {
      filters.push({
        type: 'filter',
        name: 'due_date',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: invoiceDueDateStart,
      });
    }

    if (invoiceDueDateEnd) {
      filters.push({
        type: 'filter',
        name: 'due_date',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: invoiceDueDateEnd,
      });
    }

    if (!isEmpty(statuses)) {
      filters.push({
        type: 'filter',
        name: 'ops_status',
        comparator: FilterComparator.IN,
        comparisonValue: statuses,
      });
    }

    if (!isEmpty(types)) {
      filters.push({
        type: 'filter',
        name: 'invoice_type',
        comparator: FilterComparator.IN,
        comparisonValue: types,
      });
    }

    if (!isEmpty(financiers)) {
      filters.push({
        type: 'filter',
        name: 'financier_id',
        comparator: FilterComparator.IN,
        comparisonValue: financiers,
      });
    }

    if (!isEmpty(currencies)) {
      filters.push({
        type: 'filter',
        name: 'invoiced_currency',
        comparator: FilterComparator.IN,
        comparisonValue: currencies,
      });
    }

    onSubmit(filters);
    handleMenuClose();
  };

  return (
    <>
      <Badge
        badgeContent={appliedFilterFieldsCount}
        color="primary"
        sx={{ width: '100%' }}>
        <Button
          startIcon={<FiltersIcon />}
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          Filters
        </Button>
      </Badge>
      <Formik initialValues={INITIAL_VALUES} onSubmit={handleformSubmit}>
        {(formik) => {
          return (
            <Menu
              keepMounted
              disablePortal
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => formik.handleSubmit()}
              sx={{}}
              slotProps={{
                paper: {
                  elevation: 2,
                  sx: {
                    padding: 2,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: 420,
                    backgroundColor: 'background.default',
                  },
                },
              }}>
              <Form
                noValidate
                tabIndex={-1}
                onKeyDown={(e) => e.stopPropagation()}>
                <Row tabIndex={-1}>
                  <Col xs={12}>
                    <Stack gap={1}>
                      <Typography variant="subtitle1">Invoice date</Typography>
                      <Row>
                        <Col xs={12} sm={6}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            label={'Start date'}
                            value={
                              formik.values.invoiceDateStart
                                ? new Date(formik.values.invoiceDateStart)
                                : null
                            }
                            onChange={(date, context) => {
                              formik.setFieldValue(
                                'invoiceDateStart',
                                isValid(date) &&
                                  isEmpty(context.validationError)
                                  ? format(date as Date, 'yyyy-MM-dd')
                                  : '',
                                true
                              );
                            }}
                            slotProps={{
                              field: { clearable: true },
                              textField: {
                                name: 'invoiceDateStart',
                                onBlur: formik.handleBlur,
                                error:
                                  formik.touched.invoiceDateStart &&
                                  Boolean(formik.errors.invoiceDateStart),
                                helperText:
                                  formik.touched.invoiceDateStart &&
                                  formik.errors.invoiceDateStart,
                              },
                            }}
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            label={'End date'}
                            value={
                              formik.values.invoiceDateEnd
                                ? new Date(formik.values.invoiceDateEnd)
                                : null
                            }
                            onChange={(date, context) => {
                              formik.setFieldValue(
                                'invoiceDateEnd',
                                isValid(date) &&
                                  isEmpty(context.validationError)
                                  ? format(date as Date, 'yyyy-MM-dd')
                                  : '',
                                true
                              );
                            }}
                            slotProps={{
                              field: { clearable: true },
                              textField: {
                                name: 'invoiceDateEnd',
                                onBlur: formik.handleBlur,
                                error:
                                  formik.touched.invoiceDateEnd &&
                                  Boolean(formik.errors.invoiceDateEnd),
                                helperText:
                                  formik.touched.invoiceDateEnd &&
                                  formik.errors.invoiceDateEnd,
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </Stack>
                  </Col>
                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <Stack gap={1}>
                      <Typography variant="subtitle1">
                        Invoice due date
                      </Typography>
                      <Row>
                        <Col xs={12} sm={6}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            label={'Start date'}
                            value={
                              formik.values.invoiceDueDateStart
                                ? new Date(formik.values.invoiceDueDateStart)
                                : null
                            }
                            onChange={(date, context) => {
                              formik.setFieldValue(
                                'invoiceDueDateStart',
                                isValid(date) &&
                                  isEmpty(context.validationError)
                                  ? format(date as Date, 'yyyy-MM-dd')
                                  : '',
                                true
                              );
                            }}
                            slotProps={{
                              field: { clearable: true },
                              textField: {
                                name: 'invoiceDueDateStart',
                                onBlur: formik.handleBlur,
                                error:
                                  formik.touched.invoiceDueDateStart &&
                                  Boolean(formik.errors.invoiceDueDateStart),
                                helperText:
                                  formik.touched.invoiceDueDateStart &&
                                  formik.errors.invoiceDueDateStart,
                              },
                            }}
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            label={'End date'}
                            value={
                              formik.values.invoiceDueDateEnd
                                ? new Date(formik.values.invoiceDueDateEnd)
                                : null
                            }
                            onChange={(date, context) => {
                              formik.setFieldValue(
                                'invoiceDueDateEnd',
                                isValid(date) &&
                                  isEmpty(context.validationError)
                                  ? format(date as Date, 'yyyy-MM-dd')
                                  : '',
                                true
                              );
                            }}
                            slotProps={{
                              field: { clearable: true },
                              textField: {
                                name: 'invoiceDueDateEnd',
                                onBlur: formik.handleBlur,
                                error:
                                  formik.touched.invoiceDueDateEnd &&
                                  Boolean(formik.errors.invoiceDueDateEnd),
                                helperText:
                                  formik.touched.invoiceDueDateEnd &&
                                  formik.errors.invoiceDueDateEnd,
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </Stack>
                  </Col>
                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={invoiceStatuses}
                      value={formik.values?.statuses}
                      getOptionLabel={(option) =>
                        invoiceStatusesDisplayedValue[option]
                      }
                      onChange={(_, statuses) => {
                        formik.setFieldValue('statuses', statuses);
                      }}
                      renderTags={(statuses, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(statuses, (option, index) => (
                            <Chip
                              label={startCase(option)}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Status"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={invoiceTypes}
                      value={formik.values?.types}
                      getOptionLabel={(option) =>
                        invoiceTypesDisplayedValue[option]
                      }
                      onChange={(_, types) => {
                        formik.setFieldValue('types', types);
                      }}
                      renderTags={(types, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(types, (option, index) => (
                            <Chip
                              label={startCase(option)}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Type"
                        />
                      )}
                    />
                  </Col>

                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(allFinanciers, (financier) => financier.id)}
                      value={formik.values?.financiers}
                      getOptionLabel={(option) =>
                        find(
                          allFinanciers,
                          (financier) => financier.id === option
                        )?.name || ''
                      }
                      onChange={(_, financiers) =>
                        formik.setFieldValue('financiers', financiers)
                      }
                      renderTags={(financiers, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(financiers, (option, index) => (
                            <Chip
                              label={
                                find(
                                  allFinanciers,
                                  (financier) => financier.id === option
                                )?.name
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Financier"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={invoicingCurrencies}
                      value={formik.values?.currencies}
                      onChange={(_, currencies) =>
                        formik.setFieldValue('currencies', currencies)
                      }
                      renderTags={(currencies, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(currencies, (option, index) => (
                            <Chip label={option} {...getTagProps({ index })} />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Currencies"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} gap={1} display="flex">
                    <Button
                      fullWidth
                      onClick={() => formik.resetForm()}
                      variant="outlined"
                      type="button"
                      color="secondary">
                      Reset
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary">
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Menu>
          );
        }}
      </Formik>
    </>
  );
};
