import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function ArrowUpRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="ArrowUpRightIcon"
      {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.25 15.25V6.75H8.75"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 7L6.75 17.25"
      />
    </SvgIcon>
  );
}
