import { useUpdateCompanyManualDiscount } from '@/hooks';
import { CompanyManualDiscountChargesPayload } from '@/types';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { Formik, FormikProps } from 'formik';
import { toNumber } from 'lodash';
import { KeyboardEvent } from 'react';
import { Form } from 'react-router-dom';
import * as Yup from 'yup';

export const blockInvalidChar = (e: KeyboardEvent) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

const TYPES_MAP = {
  charges: {
    title: 'Edit additional charges',
    helperText: 'You can change from 0 to 1%',
    inputLabel: 'Additional charges',
    validationSchema: Yup.object({
      value: Yup.number().min(0).max(1).required('Required'),
    }),
    isValid: (value: number) => value >= 0 && value <= 1,
    successMessage: 'Additional charges updated.',
    errorMessage: 'Failed to update additional charges.',
  },
  discount: {
    title: 'Edit discount',
    helperText: 'You can change from 0 to 0.5%',
    inputLabel: 'Discount',
    validationSchema: Yup.object({
      value: Yup.number().min(0).max(0.5).required('Required'),
    }),
    isValid: (value: number) => value >= 0 && value <= 0.5,
    successMessage: 'Manual discount updated.',
    errorMessage: 'Failed to update manual discount.',
  },
};

export const DiscountDialog = ({
  open,
  onClose,
  value,
  type,
  companyId,
}: {
  open: boolean;
  value: number;
  onClose: () => void;
  type: 'charges' | 'discount';
  companyId: number;
}) => {
  const { mutate, isPending } = useUpdateCompanyManualDiscount();
  const showNotificationMessage = useToastNotification();

  const handleDiscountFormSubmit = (
    payload: CompanyManualDiscountChargesPayload
  ) => {
    mutate(
      {
        payload,
        companyId,
      },
      {
        onSuccess: () => {
          showNotificationMessage({
            title: TYPES_MAP[type].successMessage,
            type: 'success',
          });
          onClose();
        },
        onError: () => {
          showNotificationMessage({
            title: TYPES_MAP[type].errorMessage,
            type: 'error',
          });
        },
      }
    );
  };

  const handleValueChange = (
    value: string,
    formik: FormikProps<{
      value: number;
    }>
  ) => {
    if (value === '') formik.setFieldValue('value', '');
    else {
      const number = toNumber(toNumber(value).toFixed(3));
      if (TYPES_MAP[type].isValid(number)) {
        formik.setFieldValue('value', number);
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle variant="h5">{TYPES_MAP[type].title}</DialogTitle>
      <Formik
        initialValues={{
          value,
        }}
        validationSchema={TYPES_MAP[type].validationSchema}
        onSubmit={({ value }) =>
          handleDiscountFormSubmit({
            value,
            type,
          })
        }>
        {(formik) => {
          return (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <DialogContent sx={{ paddingBlock: 3 }}>
                <TextField
                  fullWidth
                  type="number"
                  name="value"
                  label={TYPES_MAP[type].inputLabel}
                  onKeyDown={blockInvalidChar}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: {
                      step: 0.001,
                      min: 0,
                      max: 1,
                    },
                  }}
                  onChange={(e) => handleValueChange(e.target.value, formik)}
                  value={formik.values.value}
                  onBlur={formik.handleBlur}
                  error={formik.touched.value && Boolean(formik.errors.value)}
                  helperText={TYPES_MAP[type].helperText}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={onClose}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  size="large"
                  type="submit"
                  loading={isPending}
                  disabled={!formik.isValid}>
                  Save
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
