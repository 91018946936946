import { SupplierNameLink } from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { PAGE_SIZE_OPTIONS, SuppliersScreeningResultLabel } from '@/constants';
import { useGetSuppliers, useGetTableSettings } from '@/hooks';
import { Supplier } from '@/schemas/getSuppliersQuery';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { toString } from 'lodash';
import { useMemo } from 'react';

export const SuppliersTable = ({ filters }: { filters?: Filter[] }) => {
  const {
    suppliersTableSettings: {
      sortModel,
      paginationModel,
      setPaginationModel,
      setSortModel,
    },
  } = useGetTableSettings();

  const columns: GridColDef<Supplier>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 250,
        renderCell: (params) => {
          return (
            <SupplierNameLink
              id={params.row?.id}
              name={params.value}
              isMonitored={params.row?.is_monitored}
            />
          );
        },
      },
      {
        field: 'legal_name',
        headerName: 'Legal name',
        flex: 1,
        minWidth: 250,
      },
      {
        field: 'company__name',
        headerName: 'Merchant',
        type: 'string',
        minWidth: 250,
        renderCell: (params) => toString(params.row?.company?.name),
      },
      {
        field: 'country',
        headerName: 'Country',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => getCountryName(params.value),
      },
      {
        field: 'exempted_from_inspections',
        headerName: 'QI exempt',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => (
          <Typography
            variant="body2"
            color={params.value ? 'success.main' : 'error.main'}>
            {params.value ? 'Yes' : 'No'}
          </Typography>
        ),
      },
      {
        field: 'orders_aggregate.count',
        headerName: 'All orders',
        minWidth: 100,
        flex: 1,
        sortable: true,
        renderCell: (params) => params.row?.all_orders?.aggregate?.count || 0,
      },
      {
        field: 'paid_orders',
        headerName: 'Paid orders',
        sortable: false,
        minWidth: 90,
        flex: 1,
        renderCell: (params) => params.row?.paid_orders?.aggregate?.count || 0,
      },
      {
        field: 'kyc',
        headerName: 'Screening',
        minWidth: 90,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          const screeningResult = params.value?.screeningResult;
          const isApproved = screeningResult === 'Approved';
          if (!screeningResult) return <></>;
          return (
            <Typography
              variant="body2"
              color={isApproved ? 'success.main' : 'error.main'}>
              {isApproved
                ? SuppliersScreeningResultLabel.APPROVED
                : SuppliersScreeningResultLabel.REJECTED}
            </Typography>
          );
        },
      },
      {
        field: 'created_at',
        headerName: 'Creation date',
        minWidth: 150,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params) =>
          params.value && formatDate(new Date(params.value), 'date'),
      },
    ],
    []
  );

  const { data, loading: isSuppliersLoading } = useGetSuppliers({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: paginationModel?.pageSize,
      offset: paginationModel?.page * paginationModel?.pageSize,
    },
  });

  const suppliers = data?.orders_suppliers || [];
  const rowCount = data?.orders_suppliers_aggregate?.aggregate?.count || 0;

  const noSuppliersProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack flexGrow={1} height={400} width="100%">
      <DataGrid
        rows={suppliers}
        columns={columns}
        disableRowSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        sortModel={sortModel}
        loading={isSuppliersLoading}
        onSortModelChange={setSortModel}
        slots={{
          noRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'No suppliers found'}
              subtitle={'Suppliers will be displayed here'}
            />
          ),
          ...noSuppliersProps,
        }}
        {...noSuppliersProps}
      />
    </Stack>
  );
};
