import { DialogProps } from '@mui/material';
import { FormikHelpers } from 'formik';

export enum ORDER_REVIEW_ACTIONS {
  SEND_BACK = 'Send back',
  REJECT = 'Reject',
  APPROVE = 'Approve',
}

export interface FormValues {
  reason?: string;
  comment: string;
}

export type submitButtonColor = 'primary' | 'error' | 'success';
export interface OrderActionModalProps extends Omit<DialogProps, 'onSubmit'> {
  title: string;
  subtitle?: string;
  reasonInputOptions?: string[];
  commentInputLabel: string;
  submitButtonLabel: string;
  submitButtonColor: submitButtonColor;
  initialValues: FormValues;
  initialErrors?: FormValues;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void;
}
