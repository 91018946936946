import NotificationAlert from '@/pages/notificationsAndEmails/notificationsAndEmailsPage/Tabs/notifications/components/NotificationAlert';

import { addNotificationGroup } from '@/api';
import { ROUTES } from '@/constants';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';
import { Formik, FormikErrors } from 'formik';
import { useNavigate } from 'react-router-dom';

interface NotificationDialogProps {
  open: boolean;
  toggle: () => void;
}

type NotificationType = 'info' | 'success' | 'warning' | 'error';

interface FormValues {
  type: NotificationType;
  header: string;
  body: string;
  show_action: boolean;
  action_text?: string;
  action_url?: string;
}

const NotificationDialog = ({ open, toggle }: NotificationDialogProps) => {
  const navigate = useNavigate();
  const showNotificationMessage = useToastNotification();

  const initialValues: FormValues = {
    type: 'success',
    header: '',
    body: '',
    show_action: false,
    action_text: '',
    action_url: '',
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.header) {
      errors.header = 'Required';
    }
    if (!values.body) {
      errors.body = 'Required';
    }
    if (values.show_action && !values.action_text) {
      errors.action_text = 'Required';
    }
    if (values.show_action && !values.action_url) {
      errors.action_url = 'Required';
    }
    return errors;
  };

  return (
    <Dialog open={open} onClose={toggle} scroll="paper" fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" noWrap>
            Create notification
          </Typography>
          <IconButton onClick={toggle}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values) => {
          try {
            const resp = await addNotificationGroup({
              ...values,
              targets_type: 'company',
            });
            showNotificationMessage({
              title: 'Notification created',
              type: 'success',
            });
            navigate(`${ROUTES.notifications}/${resp.data.id}`);
          } catch (error) {
            showNotificationMessage({
              title: 'Notification creation failed',
              type: 'error',
            });
          }
        }}>
        {(formik) => (
          <Stack
            display="contents"
            component="form"
            onSubmit={formik.handleSubmit}>
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                paddingBlockEnd: 2,
              }}>
              <Typography variant="body1">
                Set the attributes you want the notification to show for the
                merchants.
              </Typography>
              <Row>
                <Col xs={12} sm={4}>
                  <TextField
                    name="type"
                    select
                    label="Notification type"
                    fullWidth
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}>
                    <MenuItem value={'success'}>Success</MenuItem>
                    <MenuItem value={'info'}>Info</MenuItem>
                    <MenuItem value={'warning'}>Warning</MenuItem>
                    <MenuItem value={'error'}>Error</MenuItem>
                  </TextField>
                </Col>
                <Col xs={12} sm={8}>
                  <TextField
                    name="header"
                    label="Headline"
                    inputMode="text"
                    fullWidth
                    value={formik.values.header}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.header && Boolean(formik.errors.header)
                    }
                  />
                </Col>
                <Col xs={12}>
                  <TextField
                    name="body"
                    label="Body"
                    multiline
                    inputMode="text"
                    placeholder="Add body"
                    fullWidth
                    value={formik.values.body}
                    onChange={formik.handleChange}
                    error={formik.touched.body && Boolean(formik.errors.body)}
                  />
                </Col>
              </Row>
              <Stack gap={2}>
                <FormControlLabel
                  control={
                    <Switch
                      name="show_action"
                      checked={formik.values.show_action}
                      onChange={formik.handleChange}
                      color="primary"
                    />
                  }
                  label="Show action"
                  labelPlacement="end"
                />
                {formik.values.show_action && (
                  <>
                    <TextField
                      name="action_text"
                      label="Action text"
                      inputMode="text"
                      fullWidth
                      value={formik.values.action_text}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.action_text &&
                        Boolean(formik.errors.action_text)
                      }
                    />
                    <TextField
                      name="action_url"
                      label="URL link"
                      inputMode="text"
                      fullWidth
                      value={formik.values.action_url}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.action_url &&
                        Boolean(formik.errors.action_url)
                      }
                    />
                  </>
                )}
              </Stack>
              <NotificationAlert
                type={formik.values.type}
                headline={formik.values.header}
                body={formik.values.body}
                showAction={formik.values.show_action}
                actionText={formik.values.action_text}
                actionUrl={formik.values.action_url}
              />
            </DialogContent>

            <DialogActions>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </DialogActions>
          </Stack>
        )}
      </Formik>
    </Dialog>
  );
};

export default NotificationDialog;
