import { getAMLReport } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetAMLReport = (orgNo: string, country: string) =>
  useQuery({
    queryFn: () => getAMLReport(orgNo, country),
    queryKey: ['getAMLReport', orgNo, country],
    enabled: !!orgNo && !!country,
    retry: false,
  });
