import { ROUTES } from '@/constants';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { save } from '@treyd-io/core/utils/request';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { useAuth } from 'auth';
import { LAGER_GOOGLE_AUTH } from 'constants/api-endpoints';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function GoogleAuthRedirect() {
  const navigate = useNavigate();
  const { login } = useAuth();
  let [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  const error = searchParams.get('error');
  const showNotificationMessage = useToastNotification();

  useEffect(() => {
    (async () => {
      if (code) {
        try {
          const resp = await save<{
            access_token: string;
            refresh_token: string;
          }>(LAGER_GOOGLE_AUTH, { code }, REQUEST_METHOD.POST);
          login(resp.data.access_token, resp.data.refresh_token);
          navigate(localStorage.getItem('callbackUrl') || ROUTES.home);
        } catch (err: any) {
          showNotificationMessage({
            title: 'Failed to login.',
            type: 'error',
          });
          const url = ROUTES.login;
          navigate(`${url}?error=${err?.response?.data?.error}`);
        }
      }
    })();
  }, [code, error, login, navigate, showNotificationMessage]);

  return <div>{error}</div>;
}
