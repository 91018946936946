import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Lager_Auth_User,
  Orders_Orders_App_Ordercompliancereview,
} from '../__generated__/graphql';

export type OrderComplianceReview = Pick<
  Orders_Orders_App_Ordercompliancereview,
  'id' | 'comment' | 'order_id' | 'created' | 'verdict'
> & {
  user: Pick<Lager_Auth_User, 'first_name' | 'last_name'>;
};

type GetOrdersQueryType = {
  orders_orders_app_ordercompliancereview: OrderComplianceReview[];
  orders_orders_app_ordercompliancereview_aggregate: {
    aggregate: { count: number };
  };
};

export const getOrdersComplianceReviewsQuery = (
  queryOptions?: QueryOptions
) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getOrdersComplianceReviews {
    orders_orders_app_ordercompliancereview ${queryParams} {
    id
    comment
    reason
    verdict
    order_id
    created
    user {
      first_name
      last_name
    }
  }

  orders_orders_app_ordercompliancereview_aggregate ${
    whereClause ? `(where: ${whereClause})` : ''
  } {
			aggregate {
				count
			}
		}
	}
  ` as TypedDocumentNode<GetOrdersQueryType, {}>;
};
