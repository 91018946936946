import { gql, TypedDocumentNode } from '@apollo/client';

export type EditKycMutationType = {
  django: {
    edit_kyc_mutation: {
      editable: boolean;
    };
  };
};

export type EditKycMutationVariables = {
  orgNo: string;
  editable_by: number;
  country: string;
};

export const EditKycMutation = gql`
  mutation EditKycMutation(
    $orgNo: String!
    $editable_by: Int!
    $country: String!
  ) {
    django {
      edit_kyc_mutation(
        org_no: $orgNo
        editable_by: $editable_by
        country: $country
      ) {
        editable
      }
    }
  }
` as TypedDocumentNode<EditKycMutationType, EditKycMutationVariables>;
