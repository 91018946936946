import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { getCommentInputStatus, handleFormValidation } from './utils';
import { OrderActionModalProps } from './types';

const OrderActionModal = (props: OrderActionModalProps) => {
  const {
    title,
    subtitle,
    reasonInputOptions,
    commentInputLabel,
    initialErrors,
    initialValues,
    submitButtonLabel,
    submitButtonColor,
    onSubmit,
    ...rest
  } = props;

  const formik = useFormik({
    initialValues,
    validate: (value) => handleFormValidation(value, props.initialErrors),
    onSubmit,
    initialErrors,
  });

  return (
    <Dialog
      {...rest}
      PaperProps={{
        sx: {
          borderRadius: 3,
        },
      }}
      maxWidth="xs"
      fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle
          variant="h5"
          sx={{ paddingBlockStart: 3.5, paddingBlockEnd: 0.5 }}>
          {title}
        </DialogTitle>
        <DialogContent
          style={{ paddingTop: '0.5rem' }}
          sx={{ paddingInline: 3, paddingBlock: 1 }}>
          {subtitle && (
            <DialogContentText
              color="primary.text"
              variant="body1"
              marginBottom={3}
              paddingTop={1}>
              {subtitle}
            </DialogContentText>
          )}
          <Box sx={{ marginTop: 2.5 }}>
            {Boolean(reasonInputOptions?.length) && (
              <FormControl fullWidth>
                <InputLabel error={Boolean(formik.errors.reason)} id="reason">
                  Select reason (required)
                </InputLabel>
                <Select
                  labelId="reason"
                  name="reason"
                  value={formik.values.reason}
                  label="Select reason (required)"
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.reason)}
                  sx={{ marginBlockEnd: 2 }}>
                  {reasonInputOptions?.map((reason) => (
                    <MenuItem value={reason} key={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              fullWidth
              id="comment"
              name="comment"
              label={`${commentInputLabel} (${getCommentInputStatus(
                !Boolean(initialErrors),
                formik.values.reason
              )})`}
              multiline
              rows={2}
              value={formik.values.comment}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.comment)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ paddingInline: 2.5, paddingBlockEnd: 2.5 }}>
          <Button
            variant="outlined"
            size="large"
            onClick={() => rest.onClose?.({}, 'escapeKeyDown')}
            type="button"
            sx={{
              paddingInline: 2.25,
              paddingBlock: 1.687,
              borderRadius: 1.5,
            }}>
            Close
          </Button>
          <Button
            variant="contained"
            size="large"
            color={submitButtonColor}
            type="submit"
            disabled={!formik.isValid || formik.isSubmitting}
            style={{ boxShadow: 'none' }}
            sx={{
              paddingInline: 2.25,
              paddingBlock: 1.687,
              color: '#ffffff',
              borderRadius: 2,
            }}>
            {submitButtonLabel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default OrderActionModal;
