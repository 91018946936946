export const ENV =
  process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_APP_ENV || 'development';
export const HASURA_HOST = process.env.REACT_APP_HASURA_HOST;
export const LAGER_API_HOST =
  process.env.REACT_APP_LAGER_HOST || process.env.NEXT_PUBLIC_LAGER_HOST;
export const GOOGLE_LOGIN_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
export const GOOGLE_LOGIN_CLIENT_SECRET =
  process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_SECRET;
export const NEXT_PUBLIC_MONTO_TENANT_ID =
  process.env.NEXT_PUBLIC_MONTO_TENANT_ID;

export const isDevelopment = ENV === 'development';
export const isProduction = ENV === 'production';
export const isStaging = ENV === 'staging';
