export enum NotificationIsPublishedLabel {
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
}

export const notificationsStatusFilterOptions = [
  {
    label: NotificationIsPublishedLabel.PUBLISHED,
    value: true,
  },
  {
    label: NotificationIsPublishedLabel.UNPUBLISHED,
    value: false,
  },
];
