import DataGrid from '@/components/DataGrid';
import DownloadToolbar from '@/components/DownloadToolbar';
import TableOperations from '@/components/TableOperations';
import { FilterList, TableSorting } from '@/types';
import { Stack, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { GridColDef, gridClasses } from '@mui/x-data-grid';
import { useRef } from 'react';
import TableToolbar from '../TableToolbar';
import useGQLDataLoader, { GQLSchemaGetter } from './useGQLDataLoader';

interface TableOperationsComponentProps {
  page: number;
  size: number;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  offset: number;
  limit: number;
  setLimit: (size: number) => void;
  sorting: TableSorting;
  setSorting: (sorting: TableSorting) => void;
  filters: any;
  setFilters: (filters: any) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

interface GenericTableViewProps {
  getGQLSchema: GQLSchemaGetter;
  resourceName: string;
  columns: GridColDef[];
  rowFormatter?: (row: any) => any;
  filterList?: FilterList[];
  onRowClick?: (params: any) => void;
  searchPlaceholder?: string;
  filterMapper?: (val: string) => string | number;
  isDownloadAllowed?: boolean;
  disableSelectionOnClick?: boolean;
  getRowClassName?: (params: any) => string;
  sx?: SxProps;
}

type TableViewProps = GenericTableViewProps & TableOperationsComponentProps;

function TableView(props: TableViewProps) {
  const {
    getGQLSchema,
    rowFormatter,
    resourceName,
    columns,
    filterList,
    searchPlaceholder,
    filterMapper,
    offset,
    page,
    setPage,
    limit,
    setLimit,
    sorting,
    setSorting,
    searchTerm,
    setSearchTerm,
    filters,
    setFilters,
    onRowClick,
    isDownloadAllowed,
    disableSelectionOnClick,
    getRowClassName,
    sx,
  } = props;
  const onDownloadRef = useRef(null);

  const { rows, total_count, loading } = useGQLDataLoader(
    getGQLSchema,
    resourceName,
    { offset, limit, searchTerm, sorting, filters },
    rowFormatter
  );

  return (
    <Stack
      spacing={2}
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        flexGrow: 1,
      }}>
      <TableToolbar
        isDownloadAllowed={isDownloadAllowed}
        onDownloadCsv={(onDownloadRef.current as any)?.exportDataAsCsv}
        filterMapper={filterMapper}
        searchPlaceholder={searchPlaceholder}
        filters={filters}
        filterList={filterList}
        setFilters={setFilters}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <Box
        width="100%"
        height={400}
        display="flex"
        flexDirection="column"
        flexGrow={1}>
        <DataGrid
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within, & .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              { outline: 'none' },
            '.MuiDataGrid-row	': {
              '&:hover': {
                cursor: 'pointer',
              },
            },
            ...sx,
          }}
          rows={rows}
          columns={columns}
          loading={loading}
          pageSize={limit}
          paginationModel={{
            pageSize: limit,
            page: page,
          }}
          rowCount={total_count}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setLimit(model.pageSize);
          }}
          onRowClick={onRowClick}
          sorting={sorting}
          onSortingChange={setSorting}
          components={{
            Toolbar: () => <DownloadToolbar ref={onDownloadRef} />,
          }}
          getRowClassName={getRowClassName}
          disableRowSelectionOnClick={disableSelectionOnClick}
        />
      </Box>
    </Stack>
  );
}

export default function GenericTableView(props: GenericTableViewProps) {
  return <TableOperations Component={TableView} {...props} />;
}
