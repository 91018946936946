import { gql, TypedDocumentNode } from '@apollo/client';
import { BankAccountInput } from '__generated__/graphql';
import { Django_Bank_Account } from './fragments';

export type UpsertBankAccountMutationType = {
  django: {
    upsert_bank_account: {
      errors: {
        [key: string]: {
          [key: string]: {
            [key: string]: string;
          };
        };
      };
      bank_account: {};
    };
  };
};

type UpsertBankAccountMutationVariables = {
  bank_account_data: BankAccountInput;
};

export const UpsertBankAccount = gql`
  ${Django_Bank_Account}
  mutation UpsertBankAccount($bank_account_data: BankAccountInput!) {
    django {
      upsert_bank_account(input: $bank_account_data) {
        errors
        bank_account {
          ...DjangoBankAccountType
        }
      }
    }
  }
` as TypedDocumentNode<
  UpsertBankAccountMutationType,
  UpsertBankAccountMutationVariables
>;
