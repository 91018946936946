import {
  useGetAMLReport,
  useGetCompanyRiskAml,
  useGetComplianceDecision,
} from '@/hooks';
import { CircularProgress, Stack } from '@mui/material';
import { fileToUrl } from '@treyd-io/core/utils/file';
import { toString } from 'lodash';
import { getComplianceStatus } from '../../utils';
import { AmlReport } from './AmlReport';
import { ComplianceInfoSection } from './ComplianceInfoSection';

const ComplianceTab = ({
  orgNo,
  country,
}: {
  orgNo: string;
  country: string;
}) => {
  const { data: amlReport, isPending } = useGetAMLReport(orgNo, country);

  const {
    data: complianceDecisionData,
    loading: loadingComplianceDecisionData,
  } = useGetComplianceDecision(orgNo, country);

  const { data: riskAmlData, loading: loadingRiskAmlData } =
    useGetCompanyRiskAml(orgNo, country);

  const complianceStatus = getComplianceStatus(
    complianceDecisionData?.lager_risk_compliancedecision?.[0]
  );

  const riskAMLAssessment = toString(
    riskAmlData?.lager_risk_aml?.[0]?.aml_assesment
  );
  const riskAMLId = toString(riskAmlData?.lager_risk_aml?.[0]?.id);

  const amlReportUrl = amlReport ? fileToUrl(amlReport.data) : undefined;

  const loading =
    loadingComplianceDecisionData || loadingRiskAmlData || isPending;

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Stack gap={4}>
      <ComplianceInfoSection
        amlLevel={riskAMLAssessment}
        decision={complianceStatus}
      />
      <AmlReport reportUrl={amlReportUrl} id={riskAMLId} />
    </Stack>
  );
};

export default ComplianceTab;
