import { inviteUser } from '@/api';
import { SendInvite } from '@/types';
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

export const useSendInvitation = () =>
  useMutation<
    AxiosResponse<SendInvite.Response>,
    AxiosError,
    SendInvite.Payload
  >({
    mutationFn: (payload) =>
      inviteUser({
        ...payload,
        email: payload?.email?.toLocaleLowerCase(),
      }),
  });
