import { AbilityContext, AppNavBarContext } from '@/context';
import { useAppNavBarMenuItems } from '@/hooks';
import { User } from '@/types';
import {
  Divider,
  IconButton,
  List,
  Drawer as MuiDrawer,
  Stack,
  Tooltip,
} from '@mui/material';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import smallLogo from '@treyd-io/treyd-ui/assets/img/logo.svg';
import treydLogo from '@treyd-io/treyd-ui/assets/img/treyd-logo.svg';
import AlignArrowRightIcon from '@treyd-io/treyd-ui/icons/AlignArrowRightIcon';
import { filter } from 'lodash';
import { useContext, useMemo } from 'react';
import { MenuItem } from './MenuItem';

interface DesktopNavBarProps {
  user?: User;
  selectedRouteSegment?: string;
}

const drawerWidth = 272;

const openedMixin = (theme: Theme): CSSObject => ({
  width: '17rem',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '6rem',
  [theme.breakpoints.up('sm')]: {
    width: '6rem',
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const DesktopNavBar = (props: DesktopNavBarProps) => {
  const { user, selectedRouteSegment } = props;
  const ability = useContext(AbilityContext);
  const { isAppNavBarOpen, setIsAppNavBarOpen } = useContext(AppNavBarContext);

  const { selectedMenuItem, fixedMenuItems, profileMenuItem, blogMenuItem } =
    useAppNavBarMenuItems({
      user,
      selectedItemId: selectedRouteSegment,
    });

  const availableMenuItems = useMemo(
    () => filter(fixedMenuItems, (item) => ability?.can('read', item.id)),
    [ability, fixedMenuItems]
  );
  const handleDrawerClose = () => {
    setIsAppNavBarOpen(false);
  };

  const handleDrawerOpen = () => {
    setIsAppNavBarOpen(true);
  };
  return (
    <Drawer
      variant="permanent"
      open={isAppNavBarOpen}
      PaperProps={{
        sx: {
          border: 'none',
          paddingBlock: 2,
          paddingInlineStart: 2,
          background: 'none',
          display: 'flex',
          height: '100vh',
          overflowY: 'hidden',
        },
      }}>
      <Stack
        border={1}
        bgcolor="background.default"
        borderColor="other.outlined_border"
        color="text.primary"
        flexGrow={1}
        padding={2}
        height={'100%'}
        borderRadius={4}
        gap={1}>
        <Stack
          sx={{
            flexDirection: isAppNavBarOpen ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}>
          <Stack alignItems="center">
            {isAppNavBarOpen ? (
              <img
                src={treydLogo}
                width={53}
                height={20}
                style={{ marginInlineStart: 8 }}
                alt="Treyd"
              />
            ) : (
              <img
                src={smallLogo}
                width={40}
                height={40}
                alt="Treyd"
                style={{ padding: 8 }}
              />
            )}
          </Stack>
          <Tooltip
            title={isAppNavBarOpen ? 'Close' : 'Open'}
            placement="right"
            arrow>
            <IconButton
              onClick={isAppNavBarOpen ? handleDrawerClose : handleDrawerOpen}
              color="primary"
              sx={{
                transform: isAppNavBarOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}>
              <AlignArrowRightIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        {!isAppNavBarOpen && <Divider />}
        <Stack
          justifyContent="space-between"
          flexGrow={1}
          gap={1}
          overflow={'hidden'}>
          <List sx={{ padding: 0, flexGrow: 1, overflowY: 'auto' }}>
            {availableMenuItems.map((item) => (
              <MenuItem
                href={item.to}
                key={item.id}
                icon={item.icon}
                isDrawerOpen={isAppNavBarOpen}
                isSelected={selectedMenuItem?.id === item.id}
                title={item.title}
              />
            ))}
          </List>
          <Divider />
          <List sx={{ padding: 0 }}>
            <MenuItem
              href={blogMenuItem.to}
              icon={blogMenuItem.icon}
              isDrawerOpen={isAppNavBarOpen}
              isSelected={selectedMenuItem?.id === blogMenuItem.id}
              title={blogMenuItem.title}
            />
            <MenuItem
              href={profileMenuItem.to}
              icon={profileMenuItem.icon}
              isDrawerOpen={isAppNavBarOpen}
              isSelected={selectedMenuItem?.id === profileMenuItem.id}
              title={profileMenuItem.title}
            />
          </List>
        </Stack>
      </Stack>
    </Drawer>
  );
};
