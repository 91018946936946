import { getNotificationGroupMerchantsQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetNotificationGroupMerchants = ({
  notificationGroupId,
  queryOptions,
  skip,
}: {
  notificationGroupId: number;
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(
    getNotificationGroupMerchantsQuery(notificationGroupId, queryOptions),
    {
      skip,
    }
  );
