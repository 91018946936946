import { OnboardingStatuses } from '@/types';
import { get } from '@treyd-io/core/utils/request';
import { COMPANY_ONBOARDING_STATUSES } from 'constants/api-endpoints';
import { useEffect, useState } from 'react';

export const useOnboardingStatuses = (companyId: string) => {
  const [loading, setLoading] = useState(false);

  const [onboardingStatuses, setOnboardingStatuses] = useState({
    banking_connected: false,
    banking_statement_uploaded: false,
    erp_connected: false,
    is_financial_documents_added: false,
    is_kyc_submitted: false,
    financial_data_completed: false,
    is_business_persons_onboarded: false,
  });

  const fetchOnboardingStatuses = async (companyId: string) => {
    try {
      setLoading(true);
      const response = await get<OnboardingStatuses>(
        COMPANY_ONBOARDING_STATUSES(companyId)
      );
      setOnboardingStatuses(response?.data);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const refetch = fetchOnboardingStatuses;

  useEffect(() => {
    if (companyId) fetchOnboardingStatuses(companyId);
  }, [companyId]);

  return { onboardingStatuses, loading, refetch };
};
