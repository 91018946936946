import { getUsersTermsConsentsQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

export const useGetUsersTermsConsents = (usersIds?: number[]) =>
  useQuery(getUsersTermsConsentsQuery, {
    variables: {
      usersIds: usersIds as number[],
    },
    skip: isEmpty(usersIds),
  });
