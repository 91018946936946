import { MerchantWithLink } from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '@/constants';
import { useGetNotificationGroupMerchants } from '@/hooks';
import { NotificationGroupMerchant } from '@/schemas';
import { Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { FilterComparator } from '@treyd-io/core/types/hasura';
import { getCountryName } from '@treyd-io/core/utils/country';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import ResponsiveButton from '@treyd-io/treyd-ui/components/ResponsiveButton';
import CheckmarkIcon from '@treyd-io/treyd-ui/icons/CheckmarkIcon';
import EditIcon from '@treyd-io/treyd-ui/icons/EditIcon';
import PlusIcon from '@treyd-io/treyd-ui/icons/PlusIcon';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import AddMerchantDialog from './AddMerchantDialog';

interface AffectedMerchantsSectionProps {
  affectedMerchantsIds: number[];
  notificationGroupId: number;
  isNotificationGroupPublished: boolean;
  onMerchantsUpdate: () => void;
}

export const AffectedMerchantsSection = (
  props: AffectedMerchantsSectionProps
) => {
  const {
    affectedMerchantsIds,
    notificationGroupId,
    isNotificationGroupPublished,
    onMerchantsUpdate,
  } = props;
  const [open, toggle] = useToggle(false);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'desc' },
  ]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE,
    page: 0,
  });
  const columns: GridColDef<NotificationGroupMerchant>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 250,
        renderCell: (params) => {
          const id = params.row?.id;
          const name = params.row?.name;
          const segment = params.row?.segment;

          return (
            name && <MerchantWithLink id={id} name={name} segment={segment} />
          );
        },
      },
      {
        field: 'country',
        headerName: 'Country',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => getCountryName(params.value),
      },
      {
        field: 'received_at',
        headerName: 'Seen',
        minWidth: 100,
        sortable: false,
        flex: 1,
        renderCell: (params) =>
          params.row.notifications?.[0]?.received_at && (
            <CheckmarkIcon fontSize="large" color="success" />
          ),
      },
      {
        field: 'dismissed_at',
        headerName: 'Dismissed',
        width: 100,
        sortable: false,
        renderCell: (params) =>
          params.row.notifications?.[0]?.dismissed_at && (
            <CheckmarkIcon fontSize="large" color="success" />
          ),
      },
    ],
    []
  );

  const { data, loading: isNotificationGroupMerchantsLoading } =
    useGetNotificationGroupMerchants({
      queryOptions: {
        filters: [
          {
            type: 'filter',
            name: 'id',
            comparator: FilterComparator.IN,
            comparisonValue: affectedMerchantsIds,
          },
        ],
        sorting: sortModel[0],
        limit: paginationModel?.pageSize,
        offset: paginationModel?.page * paginationModel?.pageSize,
      },
      notificationGroupId: notificationGroupId,
    });

  const merchants = data?.orders_companies || [];
  const rowCount = data?.orders_companies_aggregate?.aggregate?.count || 0;

  const noMerchantsProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack
      border={1}
      borderColor="other.outlined_border"
      borderRadius={4}
      padding={3}
      gap={2}>
      <Stack>
        <Row>
          <Col xs>
            <Stack gap={0.5}>
              <Typography variant="h5">Merchants affected</Typography>
              <Typography variant="body1" color="text.secondary">
                List of merchants who is affected by the notification.
              </Typography>
            </Stack>
          </Col>
          <Col>
            {isEmpty(affectedMerchantsIds) && (
              <ResponsiveButton
                variant="contained"
                size="large"
                color="primary"
                onClick={toggle}
                buttonProps={{
                  children: 'Add',
                  startIcon: <PlusIcon />,
                }}
                iconButtonProps={{
                  children: <PlusIcon />,
                }}
              />
            )}
            {!isEmpty(affectedMerchantsIds) &&
              !isNotificationGroupPublished && (
                <ResponsiveButton
                  variant="outlined"
                  size="large"
                  color="secondary"
                  onClick={toggle}
                  buttonProps={{
                    children: 'Edit',
                    startIcon: <EditIcon />,
                  }}
                  iconButtonProps={{
                    children: <EditIcon />,
                  }}
                />
              )}
            {open && (
              <AddMerchantDialog
                open={open}
                toggle={toggle}
                notificationGroupId={notificationGroupId}
                refetch={onMerchantsUpdate}
                merchantList={affectedMerchantsIds}
              />
            )}
          </Col>
        </Row>
      </Stack>
      <Stack flexGrow={1} height={400} width="100%">
        <DataGrid
          rows={merchants}
          columns={columns}
          disableRowSelectionOnClick
          sortingMode="server"
          paginationMode="server"
          rowCount={rowCount}
          onPaginationModelChange={setPaginationModel}
          paginationModel={paginationModel}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          sortModel={sortModel}
          loading={isNotificationGroupMerchantsLoading}
          onSortModelChange={setSortModel}
          slots={{
            noRowsOverlay: () => (
              <TableHasNoRowsPlaceholder
                title={'No merchants found'}
                subtitle={'Merchants will be displayed here'}
              />
            ),
            ...noMerchantsProps,
          }}
          {...noMerchantsProps}
        />
      </Stack>
    </Stack>
  );
};
