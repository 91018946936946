import { SearchField } from '@/components';
import { ComponentLoader } from '@/components/ComponentLoader';
import { ExportTransactionsButton } from '@/components/Transactions/ExportTransactionsButton';
import {
  useGetAllFinanciers,
  useGetAllMerchants,
  useGetTableSettings,
} from '@/hooks';
import useSearchTerm from '@/hooks/useSearchTerm';
import { Stack, Typography } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { isEmpty, isNaN, toNumber } from 'lodash';
import { TableFilters } from './TableFilters';
import { TransactionsTable } from './TransactionsTable';

export const TransactionsPage = () => {
  const { searchTerm, setSearchTerm } = useSearchTerm('transactionsSearchTerm');
  const { data: merchantsData, loading: isAllMerchantsLoading } =
    useGetAllMerchants();
  const { data: financiersData, loading: isAllFinanciersLoading } =
    useGetAllFinanciers();

  const allMerchants = merchantsData?.orders_companies;

  const allFinanciers = financiersData?.orders_financiers;

  const loading = isAllMerchantsLoading || isAllFinanciersLoading;

  const {
    transactionsTableSettings: { filterFields, setFilterFields, resetTable },
  } = useGetTableSettings();

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'invoice__invoice_number',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'invoice__order_id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(searchTerm)
          ? null
          : toNumber(searchTerm),
    },
  ];

  const filters: Filter[] = [...searchFields, ...filterFields];

  if (loading) return <ComponentLoader />;

  return (
    <Stack flexGrow={1} gap={5} height="100%">
      <Stack flexGrow={1} gap={3} height="100%">
        <Row>
          <Col xs="auto">
            <Typography variant="h4">Transactions</Typography>
          </Col>
          <Col xs={12} sm>
            <SearchField
              placeholder={'Search by invoice #, or order #'}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Col>
          <Col xs={12} sm="auto">
            <TableFilters
              onSubmit={setFilterFields}
              onReset={resetTable}
              filters={filterFields}
              allMerchants={allMerchants}
              allFinanciers={allFinanciers}
            />
          </Col>
          <Col xs={12} sm="auto">
            <ExportTransactionsButton filters={filters} />
          </Col>
        </Row>
        <TransactionsTable filters={filters} />
      </Stack>
    </Stack>
  );
};
