import { OrderAttachment } from '@/types';
import {
  List,
  ListItem,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import LinkIcon from '@treyd-io/treyd-ui/icons/LinkIcon';
import { last, map } from 'lodash';
import { Link } from 'react-router-dom';

export const LinksSection = ({
  orderAgreementLink,
  supplierInvoiceLink,
  supportingFiles,
}: {
  orderAgreementLink?: string;
  supplierInvoiceLink?: string;
  supportingFiles?: OrderAttachment[];
}) => (
  <Stack gap={1}>
    <Stack direction="row" gap={1} textAlign="center">
      <LinkIcon color="primary" />
      <Typography variant="subtitle1" color="text.primary">
        Links
      </Typography>
    </Stack>
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
      }}
      disablePadding>
      {supplierInvoiceLink && (
        <ListItem disablePadding>
          <MuiLink component={Link} to={supplierInvoiceLink} target="_blank">
            Supplier invoice ↗︎
          </MuiLink>
        </ListItem>
      )}
      {orderAgreementLink && (
        <ListItem disablePadding>
          <MuiLink component={Link} to={orderAgreementLink} target="_blank">
            Order agreement ↗︎
          </MuiLink>
        </ListItem>
      )}
      {map(supportingFiles, (file) => (
        <ListItem
          key={file.id}
          disablePadding
          sx={{
            maxWidth: 360,
          }}>
          <Typography noWrap>
            <MuiLink component={Link} to={file.url} target="_blank" noWrap>
              {last(file.filename?.split('/'))} ↗︎
            </MuiLink>
          </Typography>
        </ListItem>
      ))}
    </List>
  </Stack>
);
