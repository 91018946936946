import { gql, TypedDocumentNode, useQuery } from '@apollo/client';
import { Lager_Treyd_Users } from '__generated__/graphql';

type GetUserPermissionsGroups = {
  lager_treyd_users: Array<Pick<Lager_Treyd_Users, 'user_groups'>>;
};

type QueryVariables = {
  user_id: number | undefined;
};

const getUserPermissionsGroups = gql`
  query user_groups($user_id: Int!) {
    lager_treyd_users(where: { user_id: { _eq: $user_id } }) {
      user_groups {
        id
        auth_group {
          name
        }
      }
    }
  }
` as TypedDocumentNode<GetUserPermissionsGroups, QueryVariables>;

const useUserPermissionsGroups = (variables: QueryVariables) =>
  useQuery<GetUserPermissionsGroups, QueryVariables>(getUserPermissionsGroups, {
    variables,
  });

export default useUserPermissionsGroups;
