import { PAGE_SIZE } from '@/constants';
import {
  CreditsTableFiltersFields,
  EmailsTableFiltersFields,
  InvoicesTableFiltersFields,
  MerchantTableFiltersFields,
  NotificationsTableFiltersFields,
  OrdersReview,
  OrdersTableFiltersFields,
  SuppliersTableFiltersFields,
  TableSettings,
  TransactionsTableFiltersFields,
  VouchersTableFiltersFields,
} from '@/types';
import { GridSortDirection } from '@mui/x-data-grid';
import { createContext } from 'react';

export interface TableSettingsContextType {
  merchantsTableSettings: TableSettings<MerchantTableFiltersFields>;
  invoicesTableSettings: TableSettings<InvoicesTableFiltersFields>;
  ordersTableSettings: TableSettings<OrdersTableFiltersFields>;
  suppliersTableSettings: TableSettings<SuppliersTableFiltersFields>;
  transactionsTableSettings: TableSettings<TransactionsTableFiltersFields>;
  creditsTableSettings: TableSettings<CreditsTableFiltersFields>;
  vouchersTableSettings: TableSettings<VouchersTableFiltersFields>;
  notificationsTableSettings: TableSettings<NotificationsTableFiltersFields>;
  emailsTableSettings: TableSettings<EmailsTableFiltersFields>;
  ordersReviewAllOrdersTableSettings: TableSettings<OrdersReview.AllOrdersTableFiltersFields>;
}

export const defaultTableSettings = {
  filterFields: [],
  sortModel: [{ field: 'id', sort: 'desc' as GridSortDirection }],
  paginationModel: { pageSize: PAGE_SIZE, page: 0 },
  setPaginationModel: () => {},
  setSortModel: () => {},
  setFilterFields: () => {},
  resetTable: () => {},
};

export const TableSettingsContext = createContext<TableSettingsContextType>({
  ordersReviewAllOrdersTableSettings: {
    ...defaultTableSettings,
  },
  merchantsTableSettings: {
    ...defaultTableSettings,
  },
  invoicesTableSettings: {
    ...defaultTableSettings,
  },
  ordersTableSettings: {
    ...defaultTableSettings,
  },
  suppliersTableSettings: {
    ...defaultTableSettings,
  },
  transactionsTableSettings: {
    ...defaultTableSettings,
  },
  creditsTableSettings: {
    ...defaultTableSettings,
  },
  vouchersTableSettings: {
    ...defaultTableSettings,
  },
  notificationsTableSettings: {
    ...defaultTableSettings,
  },
  emailsTableSettings: {
    ...defaultTableSettings,
  },
});
