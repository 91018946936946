import { gql, TypedDocumentNode } from '@apollo/client';
import { BASE_SUPPLIER } from './fragments/BaseSupplier';

import {
  Maybe,
  Orders_Companies,
  Orders_Orders_Aggregate,
  Orders_Suppliers,
  Scalars,
} from '@/__generated__/graphql';
import { MERCHANT_SEGMENT } from '@/constants';

export type Supplier = Pick<
  Orders_Suppliers,
  | 'created_at'
  | 'name'
  | 'country'
  | 'exempted_from_inspections'
  | 'website'
  | 'zip_code'
  | 'city'
  | 'contacts'
  | 'full_address'
  | 'org_no'
  | 'id'
  | 'state_or_province'
  | 'is_monitored'
> & {
  company: Pick<Orders_Companies, 'name' | 'id'> & {
    segment: MERCHANT_SEGMENT;
  };
} & {
  allDeals: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  paidDeals: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  legal_name?: Maybe<Scalars['String']>;
};
type SupplierQueryType = {
  orders_suppliers_by_pk: Supplier;
};

export const getSupplierByIdQuery = gql`
  ${BASE_SUPPLIER}
  query ordersSuppliers($supplier_id: bigint!) {
    orders_suppliers_by_pk(id: $supplier_id) {
      ...BaseSupplier
      company {
        name
        id
        segment
      }
      allDeals: orders_aggregate {
        aggregate {
          count
        }
      }
      paidDeals: orders_aggregate(
        where: {
          status: {
            _in: ["paid", "overdue", "scheduled", "closed", "settled", "open"]
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
` as TypedDocumentNode<SupplierQueryType, { supplier_id: string }>;
