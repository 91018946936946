import { MERCHANT_SEGMENT } from '@/constants';
import { gql, TypedDocumentNode } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Invoicing_App_Transaction,
  Orders_Companies,
  Orders_Financiers,
  Orders_Order_Invoices,
  Orders_Order_Invoices_Aggregate,
  Orders_Orders,
} from '__generated__/graphql';

type InvoiceFinancier = Pick<Orders_Financiers, 'name'>;
type InvoiceImporter = Pick<Orders_Companies, 'id' | 'name' | 'country'> & {
  segment: MERCHANT_SEGMENT;
};

type InvoiceOrder = Pick<Orders_Orders, 'id' | 'importer'> & {
  importer: InvoiceImporter;
};

export type InvoiceTransaction = Pick<
  Invoicing_App_Transaction,
  'amount' | 'amount_currency' | 'payment_date' | 'type'
>;

export type ExportedInvoiceTransaction = InvoiceTransaction & {
  invoice_number: string;
  order_id: string;
};

export type Invoice = Pick<
  Orders_Order_Invoices,
  | 'id'
  | 'order_id'
  | 'invoice_number'
  | 'invoice_date'
  | 'invoice_type'
  | 'invoiced_amount'
  | 'invoiced_currency'
  | 'paid_amount'
  | 'remaining_amount'
  | 'ops_status'
  | 'due_date'
  | 'invoicing_system'
  | 'overdue_days'
  | 'closed_date'
  | 'closed_date_overwrite'
> & {
  financier?: InvoiceFinancier;
  order: InvoiceOrder;
  transactions: InvoiceTransaction[];
};

type GetInvoicesQueryType = {
  orders_order_invoices: Invoice[];
  orders_order_invoices_aggregate: Pick<
    Orders_Order_Invoices_Aggregate,
    'aggregate'
  >;
};

export const getInvoicesQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getInvoices{
    orders_order_invoices ${queryParams} {
      id
      order_id
      invoice_number
      invoice_date
      invoice_type
      invoiced_amount
      invoiced_currency
      paid_amount
      remaining_amount
      ops_status
      due_date
      overdue_days
      invoicing_system
      closed_date
      closed_date_overwrite
      transactions{
        amount
        amount_currency
        payment_date
        type
      }
      financier {
        name
      }
      order {
        id
        importer {
          id
          name
          country
					segment
        }
      }
    }
    orders_order_invoices_aggregate ${
      whereClause ? `(where: ${whereClause})` : ''
    } {
      aggregate {
        count
      }
    }
  }
  ` as TypedDocumentNode<GetInvoicesQueryType, {}>;
};
