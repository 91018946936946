import { AlertColor, CircularProgress, Theme } from '@mui/material';
import { getFilenameFromUrl } from '@treyd-io/core/utils/file';
import { capitalCase } from 'capital-case';
import { last } from 'lodash';
import React from 'react';
import { FileObject } from '../components/Upload/Uploader';
import CheckCircleIcon from '../icons/CheckCircleIcon';
import FileErrorIcon from '../icons/FileErrorIcon';
import { Attachment } from '../types/file';
import { isValidAttachment } from '../utils/file';

export class FileAlertBuilder {
  private theme: Theme;
  private file: FileObject | Attachment | string;
  private loading?: boolean;
  private fileName: string;
  private fileSize: string;
  private alertColor: AlertColor;
  private alertIcon: React.JSX.Element;
  private alertText: 'Uploading' | 'Completed' | 'Failed';
  private alertTextColor: string;
  private alertTitleTextColor: string;
  private error: string;

  constructor(
    file: FileObject | Attachment | string,
    theme: Theme,
    loading?: boolean,
    fileName = ''
  ) {
    this.theme = theme;
    this.file = file;
    this.loading = loading;
    this.fileName = fileName;
    this.fileSize = '';
    this.alertColor = 'success';
    this.alertIcon = (
      <CheckCircleIcon
        fontSize="large"
        sx={{ color: this.theme.palette.success.dark }}
      />
    );
    this.alertText = 'Completed';
    this.alertTextColor = this.theme.palette.success.dark;
    this.alertTitleTextColor = this.theme.palette.primary.main;
    this.error = '';
  }

  public get name() {
    return this.fileName;
  }
  public get size() {
    return this.fileSize;
  }
  public get color() {
    return this.alertColor;
  }
  public get icon() {
    return this.alertIcon;
  }

  public get text() {
    return this.alertText;
  }

  public get textColor() {
    return this.alertTextColor;
  }

  public get titleColor() {
    return this.alertTitleTextColor;
  }

  public get errorMessage() {
    return this.error;
  }

  private setFileName(): FileAlertBuilder {
    if (this.fileName) return this;
    if (isValidAttachment(this.file)) {
      this.fileName = last(this.file?.name.split('/')) || '';
    } else if (typeof this.file === 'string') {
      this.fileName = getFilenameFromUrl(this.file) || '';
    } else {
      this.fileName = this.file?.file?.name || '';
    }
    return this;
  }

  private setFileSize(): FileAlertBuilder {
    if (isValidAttachment(this.file) || typeof this.file === 'string')
      return this;
    const size = this.file?.file?.size || 0;
    this.fileSize =
      size / Math.pow(10, 6) > 1
        ? `${Math.round(size / Math.pow(10, 6))} MB`
        : `${Math.round(size / Math.pow(10, 3))} KB`;
    return this;
  }
  private setAlertIcon(): FileAlertBuilder {
    if (this.loading) {
      this.alertIcon = <CircularProgress />;
      return this;
    }
    if (!isValidAttachment(this.file) && typeof this.file !== 'string') {
      if (this.file?.status === 'success')
        this.alertIcon = (
          <CheckCircleIcon
            fontSize="large"
            sx={{ color: this.theme.palette.success.dark }}
          />
        );
      else
        this.alertIcon = (
          <FileErrorIcon
            fontSize="large"
            sx={{ color: this.theme.palette.error.dark }}
          />
        );
    }
    return this;
  }
  private setAlertColor(): FileAlertBuilder {
    if (this.loading) {
      this.alertColor = 'info';
      return this;
    }
    if (!isValidAttachment(this.file) && typeof this.file !== 'string')
      if (this.file?.status === 'success') this.alertColor = 'success';
      else this.alertColor = 'error';
    return this;
  }
  private setAlertText(): FileAlertBuilder {
    if (this.loading) {
      this.alertText = 'Uploading';
      return this;
    }
    if (!isValidAttachment(this.file) && typeof this.file !== 'string')
      if (this.file?.status === 'success') this.alertText = 'Completed';
      else this.alertText = 'Failed';
    return this;
  }
  private setAlertTextColor(): FileAlertBuilder {
    if (this.loading) {
      this.alertTextColor = this.theme.palette.primary.main;
      return this;
    }
    if (!isValidAttachment(this.file) && typeof this.file !== 'string')
      this.alertTextColor =
        this.file?.status === 'error'
          ? this.theme.palette.error.dark
          : this.theme.palette.success.dark;
    return this;
  }
  private setAlertTitleTextColor(): FileAlertBuilder {
    if (this.loading) {
      this.alertTitleTextColor = this.theme.palette.primary.main;
      return this;
    }
    if (!isValidAttachment(this.file) && typeof this.file !== 'string')
      this.alertTitleTextColor =
        this.file?.status === 'error'
          ? this.theme.palette.error.dark
          : this.theme.palette.success.dark;
    return this;
  }
  private setErrors(): FileAlertBuilder {
    if (!isValidAttachment(this.file) && typeof this.file !== 'string')
      this.error = this.file?.errors
        ? `${this.file.errors
            .map((err: string) => capitalCase(err))
            .join(', ')}`
        : '';
    return this;
  }

  public build(): FileAlert {
    return new FileAlert(
      this.setAlertColor()
        .setFileName()
        .setAlertIcon()
        .setAlertText()
        .setAlertTextColor()
        .setAlertTitleTextColor()
        .setFileSize()
        .setErrors()
    );
  }
}

export class FileAlert {
  private builder: FileAlertBuilder;

  constructor(builder: FileAlertBuilder) {
    this.builder = builder;
  }

  get FileAlert(): FileAlertBuilder {
    return this.builder;
  }
}
