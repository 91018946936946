import { BankDialogFormData } from '@/types/bank';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import { Form, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { BankFormBody } from './BankFormBody';

export const BankForm = ({
  isDialog,
  entityCountry,
  loading,
  title,
  setValidationSchema,
}: {
  isDialog?: boolean;
  entityCountry: string;
  loading: boolean;
  title?: ReactNode;
  setValidationSchema: React.Dispatch<
    React.SetStateAction<NonNullable<unknown>>
  >;
}) => {
  const { values, errors } = useFormikContext<BankDialogFormData>();

  return (
    <Form noValidate>
      <Stack component={isDialog ? DialogContent : Stack} paddingY={3} gap={2}>
        {title}
        <BankFormBody
          entityCountry={entityCountry}
          setValidationSchema={setValidationSchema}
        />
      </Stack>
      {isDialog ? (
        <DialogActions>
          <LoadingButton
            type="submit"
            size="large"
            fullWidth
            loading={loading}
            variant="contained"
            disabled={!isEmpty(errors) || !values.payment_type}>
            {'Save'}
          </LoadingButton>
        </DialogActions>
      ) : (
        <LoadingButton
          loading={loading}
          type="submit"
          size="large"
          variant="contained"
          sx={{ alignSelf: { sm: 'flex-start' } }}
          endIcon={<ArrowRightIcon />}
          disabled={!isEmpty(errors) || !values.payment_type}>
          {'Continue'}
        </LoadingButton>
      )}
    </Form>
  );
};
