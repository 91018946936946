import { KycData } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';
import { Lager_Risk_Kyc } from '__generated__/graphql';

type GetMerchantRiskKycQueryVariables = {
  companyId: number;
};

type RiskKyc = Pick<Lager_Risk_Kyc, 'created' | 'version'> & {
  data: KycData;
};

export type GetMerchantRiskKycQueryType = {
  orders_companies_by_pk: {
    core_company: {
      risk_kycs: RiskKyc[];
    };
  };
};

export const getMerchantRiskKycQuery = gql`
  query getMerchantRiskKycQuery($companyId: bigint!) {
    orders_companies_by_pk(id: $companyId) {
      core_company {
        risk_kycs(where: { is_active: { _eq: true } }) {
          created
          data
          version
        }
      }
    }
  }
` as TypedDocumentNode<
  GetMerchantRiskKycQueryType,
  GetMerchantRiskKycQueryVariables
>;
