import { RiskAml } from '@/types';
import { TypedDocumentNode, gql } from '@apollo/client';

//Old companies might have risk aml report without a compliance decision
//we need to show that result
export const getMerchantRiskAmlQuery = gql`
  query getRisk_aml($orgnr: String!, $country: String!) {
    lager_risk_aml(
      where: {
        core_company: { orgnr: { _eq: $orgnr }, country: { _eq: $country } }
        is_active: { _eq: true }
      }
    ) {
      aml_assesment
      id
    }
  }
` as TypedDocumentNode<{ lager_risk_aml: RiskAml[] }, {}>;
