export class CSVBuilder {
  private headers: string[] = [];
  private rows: (string | number)[][] = [];

  setHeaders(headers: string[]): this {
    this.headers = headers;
    return this;
  }

  addRow(row: (string | number)[]): this {
    this.rows.push(row);
    return this;
  }

  build(): string {
    const csvContent = [this.headers.join(',')];
    csvContent.push(...this.rows.map((row) => row.join('\n')));
    return csvContent.join('\n');
  }
}
