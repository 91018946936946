import { OcrData, OcrStatus } from '@/types';
import { TypedDocumentNode, gql } from '@apollo/client';
import { Orders_Invoice } from '__generated__/graphql';

type OrdersInvoice = Pick<
  Orders_Invoice,
  'edited' | 'ocr_status' | 'is_active' | 'id'
> & {
  ocr_data?: OcrData;
  ocr_status?: OcrStatus;
};

type GetOrderInvoiceOcrQueryType = {
  orders_invoice: OrdersInvoice[];
};

export type GetOrderInvoiceOcrQueryVariables = { order_id: number | undefined };

export const getOrderInvoiceOcrQuery = gql`
  query getOrderInvoiceOcrQuery($order_id: bigint!) {
    orders_invoice(
      where: { deal_id: { _eq: $order_id }, is_active: { _eq: true } }
      order_by: { id: desc }
    ) {
      id
      edited
      ocr_status
      ocr_data
      is_active
    }
  }
` as TypedDocumentNode<
  GetOrderInvoiceOcrQueryType,
  GetOrderInvoiceOcrQueryVariables
>;
