import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Notification_Center_Notificationgroup,
  Orders_Notification_Center_Notificationtemplate,
} from '__generated__/graphql';

export type NotificationGroup = Pick<
  Orders_Notification_Center_Notificationgroup,
  'id' | 'targets_ids' | 'created_by' | 'created' | 'published'
> & {
  notification_template: Pick<
    Orders_Notification_Center_Notificationtemplate,
    'header'
  >;
  user: { first_name: string; last_name: string };
};

type GetMerchantsQueryTypes = {
  orders_notification_center_notificationgroup: NotificationGroup[];
  orders_notification_center_notificationgroup_aggregate: {
    aggregate: { count: number };
  };
};

export const getNotificationsGroupsQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getNotificationsGroups{
    orders_notification_center_notificationgroup ${queryParams} {
    targets_ids
    id
    created_by
    created
    published
    notification_template {
      header
    }
    user {
      first_name
      last_name
    }
  }
	orders_notification_center_notificationgroup_aggregate ${
    whereClause ? `(where: ${whereClause})` : ''
  } {
    aggregate {
      count
    }
  }
	
  }
  ` as TypedDocumentNode<GetMerchantsQueryTypes>;
};
