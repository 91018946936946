import NavTab from '@/components/NavTabsBar/NavTab';
import { SxProps } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface TabType {
  label: ReactNode;
  id?: string | number;
  content: ReactNode;
  chipLabel?: ReactNode;
}

interface NavTabsProps {
  tabs: TabType[];
  defaultTabId?: string | number;
  tabsSx?: SxProps;
}

export default function NavTabsBar(props: NavTabsProps) {
  const { hash } = useLocation();
  const defaultTabId = props.defaultTabId || props.tabs[0]?.id || '1';
  const selectedTab = hash || `#${defaultTabId}`;

  return (
    <>
      <Tabs
        value={selectedTab}
        variant="scrollable"
        scrollButtons={false}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginBlockEnd: 3,
          ...props.tabsSx,
        }}>
        {props.tabs.map((tab, index) => (
          <Tab
            key={index + 1}
            label={<NavTab label={tab.label} chipLabel={tab.chipLabel} />}
            to={`#${tab.id || index + 1}`}
            component={Link}
            value={`#${tab.id || index + 1}`}
            color="text.primary"
          />
        ))}
      </Tabs>
      {
        props.tabs.find(
          (tab, index) => `#${tab.id || index + 1}` === selectedTab
        )?.content
      }
    </>
  );
}
