//TODO: use this and create a new hook for it

import { gql, TypedDocumentNode } from '@apollo/client';
import {
  Lager_Core_Member,
  Lager_Core_Signatory,
  Lager_Treyd_Users,
} from '__generated__/graphql';

export type MemberType = Pick<Lager_Core_Member, 'id' | 'name'> & {
  core_signatories: Pick<Lager_Core_Signatory, 'id' | 'can_sign_alone'>[];
};

export type CompanyUserType = Pick<
  Lager_Treyd_Users,
  | 'id'
  | 'name'
  | 'first_name'
  | 'last_name'
  | 'can_sign_alone'
  | 'member_id'
  | 'is_ubo'
  | 'email'
  | 'company_id'
  | 'identity_verified'
  | 'is_active'
  | 'profile_id'
>;

interface GetAllPotentialCompanyUsers {
  orders_companies_by_pk: {
    company_users: CompanyUserType[];
    core_company: {
      members: MemberType[];
    };
  };
}

export const getAllPotentialCompanyUsersById = gql`
  query getAllPotentialCompanyUsersById($company_id: bigint!) {
    orders_companies_by_pk(id: $company_id) {
      company_users(where: { is_active: { _eq: true } }) {
        id
        name
        first_name
        last_name
        can_sign_alone
        member_id
        is_ubo
        email
        company_id
        identity_verified
        is_active
        profile_id
      }
      core_company {
        members(
          where: {
            _and: [{ is_active: { _eq: true } }, { is_person: { _eq: true } }]
          }
        ) {
          id
          name
          core_signatories(where: { is_active: { _eq: true } }) {
            id
            can_sign_alone
          }
        }
      }
    }
  }
` as TypedDocumentNode<GetAllPotentialCompanyUsers, { company_id: number }>;
