import { ROUTES } from '@/constants';
import { RouteObject } from 'react-router-dom';
import { TransactionsPage } from './TransactionsPage';

export const TRANSACTIONS: RouteObject = {
  path: `${ROUTES.transactions}`,
  children: [
    {
      index: true,
      element: <TransactionsPage />,
    },
  ],
};
