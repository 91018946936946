export enum OCR_FIELD_TITLE {
  IMPORTER = 'Importer',
  SUPPLIER_INVOICE_REFERENCE = 'Supplier invoice reference',
  SUPPLIER = 'Supplier',
  COUNTRY = 'Country',
  ADDRESS = 'Address',
  DUE_DATE = 'Due date',
  ACCOUNT_NUMBER = 'Account number',
  ACCOUNT_NAME = 'Account name',
  BANK_NAME = 'Bank name',
  SWIFT_CODE = 'SWIFT code',
  BANK_ADDRESS = 'Bank address',
  SORT_CODE = 'Sort code',
  TOTAL_AMOUNT = 'Total amount (Goods value)',
  CURRENCY = 'Currency',
  TREYD_PAYOUT_AMOUNT = 'Treyd payout amount',
  BANKGIRO_NUMBER = 'Bankgiro number',
}
