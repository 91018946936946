import { gql, TypedDocumentNode } from '@apollo/client';

type GetMerchantCustomerErpInvoicesCountQueryType = {
  unified_customer_invoice_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export const getMerchantCustomerErpInvoicesCountQuery = () => {
  return gql`
    query getMerchantCustomerErpInvoicesQuery {
      unified_customer_invoice_aggregate {
        aggregate {
          count
        }
      }
    }
  ` as TypedDocumentNode<GetMerchantCustomerErpInvoicesCountQueryType, {}>;
};
