import { gql, TypedDocumentNode } from '@apollo/client';
import {
  Mutation_RootInsert_Orders_Orders_App_NotesArgs,
  Mutation_RootUpdate_Orders_Orders_App_NotesArgs,
  Orders_Orders_App_Notes,
} from '__generated__/graphql';

export type GetNotesQueryVariablesType = {
  entity_type: 'supplier' | 'merchant';
  entity_id: number;
};

export type GetNotesNotesQueryType = {
  orders_orders_app_notes: Pick<Orders_Orders_App_Notes, 'note'>[];
};

export const getNotesQuery = gql`
  query getNotesQuery($entity_id: Int!, $entity_type: String!) {
    orders_orders_app_notes(
      where: {
        _and: {
          entity_type: { _eq: $entity_type }
          entity_id: { _eq: $entity_id }
        }
      }
      order_by: { created: desc }
    ) {
      note
    }
  }
` as TypedDocumentNode<GetNotesNotesQueryType, GetNotesQueryVariablesType>;

export type UpdateNotesMutationVariablesType = {
  entity_type: 'supplier' | 'merchant';
  entity_id: number;
  note: string;
};

export type UpdateNotesMutationType = {
  orders_orders_app_notes: Pick<
    Mutation_RootUpdate_Orders_Orders_App_NotesArgs,
    '_set'
  >[];
};

export const updateNotesMutation = gql`
  mutation updateNotesMutation(
    $entity_id: Int!
    $entity_type: String!
    $note: String!
  ) {
    update_orders_orders_app_notes(
      where: {
        _and: {
          entity_id: { _eq: $entity_id }
          entity_type: { _eq: $entity_type }
        }
      }
      _set: { note: $note }
    ) {
      affected_rows
    }
  }
` as TypedDocumentNode<
  UpdateNotesMutationType,
  UpdateNotesMutationVariablesType
>;

export type InsertNotesMutationVariablesType = {
  entity_type: 'supplier' | 'merchant';
  entity_id: number;
  note: string;
  created_at: string;
};

export type InsertNotesMutationType = {
  orders_orders_app_notes: Pick<
    Mutation_RootInsert_Orders_Orders_App_NotesArgs,
    'objects'
  >[];
};

export const insertNotesMutation = gql`
  mutation insertNotesMutation(
    $entity_id: Int!
    $entity_type: String!
    $note: String!
    $created_at: timestamptz!
  ) {
    insert_orders_orders_app_notes(
      objects: {
        entity_id: $entity_id
        entity_type: $entity_type
        note: $note
        type: "order_review_note"
        created: $created_at
        modified: $created_at
      }
    ) {
      affected_rows
    }
  }
` as TypedDocumentNode<
  InsertNotesMutationType,
  InsertNotesMutationVariablesType
>;
