import { Stack, Typography } from '@mui/material';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toNumber, toString } from 'lodash';

export const WithCreditDecisionStatus = ({
  totalCredit,
  remainingCredit,
  limitCurrency,
  isLimitActive,
  isLimitInactive,
  willLimitExpireSoon,
  isLatestPublishedCreditDecisionExpired,
  isLimitIndicative,
}: {
  totalCredit?: number;
  remainingCredit?: number;
  limitCurrency?: string;
  isLimitActive: boolean;
  isLimitInactive: boolean;
  willLimitExpireSoon: boolean;
  isLatestPublishedCreditDecisionExpired: boolean;
  isLimitIndicative: boolean;
}) => {
  if (isLimitIndicative) {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={0.5}>
          <Typography variant="body2" color="warning.main">
            Available credit
          </Typography>
          <Typography variant="subtitle1" color="warning.main">
            {formatCurrency(toNumber(remainingCredit), toString(limitCurrency))}
          </Typography>
        </Stack>
        <Stack gap={0.5} textAlign="right">
          <Typography variant="body2">Total credit</Typography>
          <Typography variant="subtitle1">
            {formatCurrency(toNumber(totalCredit), toString(limitCurrency))}
          </Typography>
        </Stack>
      </Stack>
    );
  }
  if (isLimitActive || willLimitExpireSoon) {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={0.5}>
          <Typography variant="body2" color="info.main">
            Available credit
          </Typography>
          <Typography variant="subtitle1" color="info.main">
            {formatCurrency(toNumber(remainingCredit), toString(limitCurrency))}
          </Typography>
        </Stack>
        <Stack gap={0.5} textAlign="right">
          <Typography variant="body2">Total credit</Typography>
          <Typography variant="subtitle1">
            {formatCurrency(toNumber(totalCredit), toString(limitCurrency))}
          </Typography>
        </Stack>
      </Stack>
    );
  }
  if (isLimitInactive) {
    if (isLatestPublishedCreditDecisionExpired) {
      return (
        <Typography color="error.main">
          Credit decision - Expired decision
        </Typography>
      );
    }
    return (
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={0.5}>
          <Typography variant="body2" color="error.main">
            Available credit
          </Typography>
          <Typography variant="subtitle1" color="error.main">
            {formatCurrency(toNumber(remainingCredit), toString(limitCurrency))}
          </Typography>
        </Stack>
        <Stack gap={0.5} textAlign="right">
          <Typography variant="body2">Total credit</Typography>
          <Typography variant="subtitle1">
            {formatCurrency(toNumber(totalCredit), toString(limitCurrency))}
          </Typography>
        </Stack>
      </Stack>
    );
  }
};
