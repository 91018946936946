import { gql, TypedDocumentNode } from '@apollo/client';
import { OrdersCompanyInputType } from '__generated__/graphql';

type UpdateCompanyMutationType = {
  django: {
    update_company: {
      company: {
        id: number;
      };
    };
  };
};

type UpdateCompanyMutationVariablesType = {
  id: number;
  company: OrdersCompanyInputType;
};

export const UpdateCompanyMutation = gql`
  mutation updateCompanyMutation($id: Int!, $company: OrdersCompanyInputType!) {
    django {
      update_company(id: $id, company_input: $company) {
        company {
          id
        }
      }
    }
  }
` as TypedDocumentNode<
  UpdateCompanyMutationType,
  UpdateCompanyMutationVariablesType
>;
