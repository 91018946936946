import { PLATFORM_HOST } from './platform-links';

export const EU_COMPANY_AGREEMENTS = [
  {
    link: `${PLATFORM_HOST}/legal/Treyd_Terms_and_Conditions_Payment_Service_v1.pdf`,
    title: 'Account agreement',
    version: 1,
  },
  {
    link: `${PLATFORM_HOST}/legal/Treyd_Terms_and_Conditions_Payment_Service_v2.pdf`,
    title: 'Account agreement',
    version: 2,
  },
  {
    link: `${PLATFORM_HOST}/legal/General_Services_Agreement_EU.pdf`,
    title: 'General services agreement',
    version: 3,
  },
];

export const UK_COMPANY_AGREEMENTS = [
  {
    link: `${PLATFORM_HOST}/legal/General_Services_Agreement_UK.pdf`,
    title: 'General service agreement',
    version: 3,
  },
];

export const TOS = {
  link: `${PLATFORM_HOST}/legal/Platform_Terms_And_Conditions.pdf`,
  title: 'Terms of services',
};

export const BLACK_COMPANY_AGREEMENTS = [
  {
    link: `${PLATFORM_HOST}/legal/General_Services_Agreement_Black.pdf`,
    title: 'General service agreement',
    version: 4,
  },
];

export const LATEST_BLACK_COMPANY_AGREEMENT_VERSION = 4;
