import {
  COMPLIANCE_DECISION_STATUS,
  COMPLIANCE_RISK_LEVEL,
  ComplianceDecision,
  ComplianceStatus,
  CreditDecision,
  CreditDecisionStatus,
} from '@/types';

export const getCreditDecisionStatus = (
  isFinancialDataCompleted: boolean,
  creditDecision?: CreditDecision | null
): CreditDecisionStatus => {
  if (creditDecision) {
    const isApproved = creditDecision?.is_approved;
    const isPublished = creditDecision?.is_published;
    if (isApproved && !isPublished) {
      return 'approved_and_not_published';
    }
    if (isApproved && isPublished) {
      if (Number(creditDecision?.approved_limit) > 0) {
        return 'approved_and_published';
      }
      return 'declined';
    }
  }

  if (isFinancialDataCompleted) {
    return 'in_review';
  }

  return 'not_started';
};

export const getComplianceStatus = (
  complianceDecision?: ComplianceDecision
): ComplianceStatus => {
  if (!complianceDecision) return 'not_started';
  if (!complianceDecision.is_active) return 'inactive';
  if (complianceDecision?.status === COMPLIANCE_DECISION_STATUS.PENDING)
    return 'pending';
  if (
    complianceDecision?.final_risk_level === COMPLIANCE_RISK_LEVEL.UNACCEPTABLE
  )
    return 'declined';
  return 'approved';
};
