import { getNewCreditDecisionRequirements } from '@/schemas';
import { useQuery } from '@apollo/client';

export const useGetNewCreditDecisionRequirements = ({
  companyId,
}: {
  companyId: number;
}) => {
  return useQuery(getNewCreditDecisionRequirements, {
    variables: { companyId },

    skip: !companyId,
  });
};
