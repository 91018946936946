import { useLogglyLoggingService, useSubmitOrderFundingReview } from '@/hooks';
import OrderActionModal from '@/pages/orderReview/OrderReviewDetails/OrderActionModal';
import Order from '@/pages/orderReview/models/Order';
import { Button, Stack } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { LOGGING_MESSAGES } from 'constants/loggly';
import { Can } from 'context';

import { ORDER_REVIEW_VERDICT } from '@/constants';
import { useState } from 'react';

enum ORDER_FUNDING_REVIEW_ACTION {
  APPROVE = 'Approve',
  REJECT = 'Reject',
}

const FORM_INITIAL_STATE = {
  comment: '',
};

const FUNDING_MODAL_INITIAL_STATE: ModalState = {
  title: '',
  onSubmit: () => {},
  subtitle: '',
  commentInputLabel: '',
  initialErrors: {},
  initialValues: {},
  submitButtonColor: 'primary',
  submitButtonLabel: '',
};

const MODAL_CONTENT: ModalContents = {
  [ORDER_FUNDING_REVIEW_ACTION.REJECT]: {
    subtitle:
      'This action is non-reversible. The merchant will have to create a new order.',
    commentInputLabel: 'Add reason for rejection',
    submitButtonColor: 'error',
    submitButtonLabel: ORDER_FUNDING_REVIEW_ACTION.REJECT,
    initialErrors: undefined,
    initialValues: FORM_INITIAL_STATE,
  },
  [ORDER_FUNDING_REVIEW_ACTION.APPROVE]: {
    subtitle: '',
    commentInputLabel: 'Add a comment',
    submitButtonColor: 'success',
    submitButtonLabel: ORDER_FUNDING_REVIEW_ACTION.APPROVE,
    initialErrors: undefined,
    initialValues: FORM_INITIAL_STATE,
  },
};

type ModalContent = {
  subtitle: string;
  commentInputLabel: string;
  reasonInputOptions?: string[];
  submitButtonColor: 'primary' | 'error' | 'success';
  submitButtonLabel: string;
  initialErrors: any;
  initialValues: any;
};

type ModalState = ModalContent & {
  title: string;
  onSubmit: (values: FormValues) => void;
};
interface ModalContents {
  [index: string]: ModalContent;
}

interface FormValues {
  comment: string;
}
interface FundingProps {
  order: Order;
  goBack: () => void;
  actionsDisabled: boolean;
}

const FundingActionButtons = (props: FundingProps) => {
  const { order, goBack, actionsDisabled } = props;
  const showNotificationMessage = useToastNotification();
  const [modalState, setModalState] = useState<ModalState>(
    FUNDING_MODAL_INITIAL_STATE
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const log = useLogglyLoggingService();

  const { mutate: submitOrderFundingReview, isPending } =
    useSubmitOrderFundingReview();

  const handleOrderApprove = async (values: FormValues) => {
    submitOrderFundingReview(
      {
        orderId: order?.id,
        fundingReviewData: {
          verdict: ORDER_REVIEW_VERDICT.APPROVE,
          ...values,
          data: {
            financier_id: order?.financier_id,
          },
        },
      },
      {
        onSuccess: () => {
          goBack();
          showNotificationMessage({
            title: `Order #${order?.id} was approved.`,
            type: 'success',
          });
          log({
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_APPROVE_SUCCESS,
            data: { orderId: order?.id },
          });
          setIsModalOpen(false);
        },
        onError: (err: any) => {
          log({
            type: 'ERROR',
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_FUNDING_REVIEW_FAIL,
            data: { orderId: order?.id, error: err },
          });
          showNotificationMessage({
            title: err?.response?.data?.comment?.[0] || 'Something went wrong.',
            type: 'error',
          });
        },
      }
    );
  };

  const handleOrderReject = async (values: FormValues) => {
    submitOrderFundingReview(
      {
        orderId: order?.id,
        fundingReviewData: {
          verdict: ORDER_REVIEW_VERDICT.REJECT,
          ...values,
        },
      },
      {
        onSuccess: () => {
          goBack();
          showNotificationMessage({
            title: `Order #${order?.id} was rejected.`,
            type: 'success',
          });
          log({
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_REJECT_SUCCESS,
            data: { orderId: order?.id },
          });
          setIsModalOpen(false);
        },
        onError: (err: any) => {
          log({
            type: 'ERROR',
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_FUNDING_REVIEW_FAIL,
            data: { orderId: order?.id, error: err },
          });
          showNotificationMessage({
            title: err?.response?.data?.comment?.[0] || 'Something went wrong.',
            type: 'error',
          });
        },
      }
    );
  };

  const SUBMIT_HANDLERS = {
    [ORDER_FUNDING_REVIEW_ACTION.APPROVE]: handleOrderApprove,
    [ORDER_FUNDING_REVIEW_ACTION.REJECT]: handleOrderReject,
  };

  const onActionClick = async (actionName: ORDER_FUNDING_REVIEW_ACTION) => {
    setIsModalOpen(true);
    setModalState({
      title: `${actionName} #${order?.id}`,
      onSubmit: (values: FormValues) => SUBMIT_HANDLERS[actionName](values),
      ...MODAL_CONTENT[actionName],
    });
  };

  return (
    <Stack alignItems="center" direction="row">
      <Can I="edit" an="orders_app_funding_review">
        <Button
          size="large"
          color="error"
          disabled={actionsDisabled || isPending}
          sx={{ marginInlineEnd: 3 }}
          onClick={() => onActionClick(ORDER_FUNDING_REVIEW_ACTION.REJECT)}>
          Reject
        </Button>
        <Button
          size="large"
          color="success"
          disabled={actionsDisabled || isPending}
          sx={{ marginInlineEnd: 3 }}
          onClick={() => onActionClick(ORDER_FUNDING_REVIEW_ACTION.APPROVE)}>
          Approve
        </Button>
      </Can>
      <OrderActionModal
        {...modalState}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </Stack>
  );
};
export default FundingActionButtons;
