export const ROUTES = {
  home: '/orders-review',
  login: '/login',
  merchants: '/merchants',
  suppliers: '/suppliers',
  credit: '/credit',
  orderReview: '/orders-review',
  orders: '/orders',
  invoices: '/invoices',
  transactions: '/transactions',
  payment: '/payment',
  profile: '/profile',
  dev: '/dev',
  blog: 'https://internal.treyd.io/',
  creditsAndVouchers: '/credits-and-vouchers',
  notificationsAndEmails: '/notifications-and-emails',
  notifications: '/notifications-and-emails/notifications',
  emails: '/notifications-and-emails/emails',
  erpInvoices: '/erp-invoices',
};

export const HIDDEN_NAVIGATION_ROUTES: Readonly<RegExp[]> = [
  /\/orders-review\/\d+$/,
  /\/login/,
];
