import { MerchantWithLink } from '@/components';
import NavTabsBar from '@/components/NavTabsBar';
import { Can } from '@/context';
import { useSubmitSupplierMonitoring } from '@/hooks';
import { useBrowsingHistory } from '@/hooks/useBrowsingHistory';
import BankInfoTab from '@/pages/suppliers/SupplierDetails/Tabs/BankInfoTab';
import { getSupplierByIdQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@treyd-io/treyd-ui/components/IconButton';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';
import BellCheckIcon from '@treyd-io/treyd-ui/icons/BellCheckIcon';
import BellCrossIcon from '@treyd-io/treyd-ui/icons/BellCrossIcon';
import { toNumber, toString } from 'lodash';
import { useParams } from 'react-router-dom';
import { Compliance } from './Tabs/Compliance';
import { DetailsTab } from './Tabs/DetailsTab';
import Notes from './Tabs/Notes';
import { Orders } from './Tabs/Orders';

const SupplierDetails = () => {
  const { id } = useParams();
  const { goBack } = useBrowsingHistory();
  const {
    data,
    loading,
    refetch: refetchSupplierData,
  } = useQuery(getSupplierByIdQuery, {
    variables: { supplier_id: toString(id) },
  });
  const supplier = data?.orders_suppliers_by_pk;
  const companyId = supplier?.company?.id;
  const companyName = supplier?.company?.name;
  const companySegment = supplier?.company.segment;
  const isMonitored = supplier?.is_monitored;
  const { mutate: updateSupplierMonitoring } = useSubmitSupplierMonitoring();

  const handleUpdateSupplierMonitoring = () => {
    updateSupplierMonitoring(
      {
        supplierId: toNumber(id),
        payload: {
          is_monitored: !supplier?.is_monitored,
        },
      },
      {
        onSuccess: () => {
          refetchSupplierData();
        },
      }
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }

  const tabs = [
    {
      id: 'details',
      label: 'Details',
      content: <DetailsTab supplier={supplier} />,
    },
    {
      id: 'bank_account',
      label: 'Bank account',
      content: <BankInfoTab supplier={supplier} />,
    },
    {
      id: 'orders',
      label: 'Orders',
      content: id && <Orders supplierId={id} />,
    },
    {
      id: 'compliance',
      label: 'Compliance',
      content: (
        <Compliance
          supplierId={toNumber(id)}
          supplierLegalName={toString(supplier?.legal_name)}
        />
      ),
    },
    {
      id: 'notes',
      label: 'Notes',
      content: <Notes supplierId={toString(id)} />,
    },
  ];

  return (
    <Stack minHeight="100%" gap={3}>
      <Stack gap={1}>
        <Button
          onClick={goBack}
          color="info"
          variant="text"
          sx={{ alignSelf: 'flex-start' }}
          startIcon={<ArrowLeftIcon />}>
          Back
        </Button>
        <Stack direction="row" alignItems="center" gap={1}>
          <Can I="edit" an="supplier_monitoring" passThrough>
            {(allowed) => (
              <Tooltip
                title={
                  isMonitored ? 'Remove from monitoring' : 'Add to monitoring'
                }>
                <IconButton
                  onClick={handleUpdateSupplierMonitoring}
                  disabled={!allowed}
                  color={isMonitored ? 'info' : 'primary'}>
                  {isMonitored ? (
                    <BellCheckIcon color="info" />
                  ) : (
                    <BellCrossIcon />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Can>

          <Typography variant="h4" color="text.primary" marginRight={2} noWrap>
            {supplier?.name}
          </Typography>
        </Stack>
        <Typography
          variant="body1"
          color="text.primary"
          display="flex"
          gap={0.5}>
          Merchant:
          {companyName && companySegment && (
            <MerchantWithLink
              id={companyId}
              name={companyName}
              segment={companySegment}
            />
          )}
        </Typography>
      </Stack>

      <Stack flexGrow={1}>
        <NavTabsBar tabs={tabs} />
      </Stack>
    </Stack>
  );
};
export default SupplierDetails;
