import { Box } from '@mui/material';
import mermaid from 'mermaid';
import { useEffectOnce } from 'react-use';

export const Mermaid = ({ chart }: { chart: string | null }) => {
  mermaid.initialize({
    startOnLoad: true,
    theme: 'default',
    securityLevel: 'loose',
    wrap: true,
  });

  useEffectOnce(() => {
    mermaid.contentLoaded();
  });

  if (!chart) {
    return null;
  }
  return (
    <Box className="mermaid" overflow="scroll" width="100%" height="100%">
      {chart}
    </Box>
  );
};
