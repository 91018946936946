import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import React from 'react';

export interface IconButtonProps extends MuiIconButtonProps {
  variant?: 'text' | 'outlined' | 'contained';
}

const IconButton = (props: IconButtonProps) => {
  return <MuiIconButton {...props} />;
};

export default IconButton;
