import { values } from 'lodash';

export enum CreditableType {
  VOUCHER = 'voucher',
  INVOICE = 'invoice',
}

export const creditableTypes = values(CreditableType);

export const creditableTypeDisplayedValue = {
  [CreditableType.VOUCHER]: 'Voucher',
  [CreditableType.INVOICE]: 'Invoice',
};
