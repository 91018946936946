import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import {
  CHARGE_TEMPLATE_ID,
  GUARANTEE_UNDERTAKING_TEMPLATE_ID,
  PERSONAL_LIABILITY_TEMPLATE_ID,
  STATEMENT_OF_ASSETS_LIABILITIES_TEMPLATE_ID,
} from '@/constants';
import {
  useGetCompanyDocuments,
  useGetUsersTermsConsents,
  useResendDocumentEmails,
} from '@/hooks';
import { useGetActiveCompanyAgreementConsentVersionNumber } from '@/hooks/useGetActiveCompanyAgreementConsentVersionNumber';
import { ConsentService } from '@/services/consent';
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { downloadFile } from '@treyd-io/core/utils/file';
import { openURL } from '@treyd-io/core/utils/openUrl';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { Lager_Treyd_Users } from '__generated__/graphql';
import {
  filter,
  find,
  includes,
  isEmpty,
  last,
  map,
  reduce,
  sortBy,
  toNumber,
  toString,
} from 'lodash';
import { DocumentCard } from './DocumentCard';
import { DocumentStatusChip } from './DocumentStatusChip';
import { FinancialDocumentsSection } from './FinancialDocumentsSection';

export const Documents = ({
  companyId,
  country,
  companyUsers,
  orgNo,
}: {
  companyId: number;
  country: string;
  companyUsers?: Lager_Treyd_Users[];
  orgNo: string;
}) => {
  const {
    data: activeCompanyAgreementData,
    loading: isCompanyAgreementLoading,
  } = useGetActiveCompanyAgreementConsentVersionNumber(companyId);

  const { data: companyDocuments, isPending: isLoadingCompanyDocuments } =
    useGetCompanyDocuments(companyId);

  const companyUsersIds = map(companyUsers, (user) => toNumber(user.id));

  const { data: usersTermsConsentsData, loading: isTermsConsentsDataLoading } =
    useGetUsersTermsConsents(companyUsersIds);

  const { mutate: resendDocument } = useResendDocumentEmails();

  const showToastMessage = useToastNotification();

  const { getTOS, getCompanyAgreementLink } = ConsentService;

  const handleResendDocument = (documentId: number) => {
    resendDocument(
      {
        companyId: toNumber(companyId),
        documentId,
      },
      {
        onSuccess: () => {
          showToastMessage({
            message: 'Document resent successfully',
            type: 'success',
          });
        },
        onError: () => {
          showToastMessage({
            message: 'Error occurred while resending the document',
            type: 'error',
          });
        },
      }
    );
  };

  const companyAgreement = last(
    activeCompanyAgreementData?.orders_orders_app_consent
  );

  const tos = getTOS();
  const companyAgreementInfo = getCompanyAgreementLink(
    toString(country),
    companyAgreement?.version_number
  );

  const usersTermsConsents = reduce(
    usersTermsConsentsData?.orders_orders_app_consent,
    (prev: { userName: string; createdAt: string }[], consent) => {
      const user = find(
        companyUsers,
        (user) => user.id === consent?.consentable_id
      );

      if (user?.is_active)
        prev.push({
          userName: toString(user?.name) || toString(user?.email),
          createdAt: formatDate(new Date(consent?.created), 'datetime'),
        });

      return prev;
    },
    []
  );

  const liabilityDocuments = sortBy(
    filter(
      companyDocuments?.data,
      (document) =>
        includes(
          [
            PERSONAL_LIABILITY_TEMPLATE_ID,
            GUARANTEE_UNDERTAKING_TEMPLATE_ID,
            STATEMENT_OF_ASSETS_LIABILITIES_TEMPLATE_ID,
            CHARGE_TEMPLATE_ID,
          ],
          document?.template_id
        ) && document?.status !== 'draft'
    ),
    ['created_at']
  );

  const attorneyDocuments = filter(
    companyDocuments?.data,
    (document) => document?.tag === 'attorney' && document?.status !== 'draft'
  );

  if (
    isLoadingCompanyDocuments ||
    isTermsConsentsDataLoading ||
    isCompanyAgreementLoading
  ) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Stack gap={5}>
      <FinancialDocumentsSection country={country} orgNo={orgNo} />
      <Divider />
      {isEmpty(usersTermsConsents) && isEmpty(companyAgreement) ? (
        <Typography
          variant="subtitle1"
          color="text.secondary"
          textAlign="center">
          No documents
        </Typography>
      ) : (
        <Stack gap={3}>
          <Typography variant="h5">Legal documents</Typography>
          {!isEmpty(usersTermsConsents) && (
            <Stack gap={1}>
              <Typography variant="h6">Terms of services</Typography>
              <Row spacing={2}>
                {map(usersTermsConsents, (consent) => {
                  return (
                    <Col xs={12} sm={4}>
                      <DocumentCard
                        title={consent.userName}
                        description={`Created on ${consent.createdAt}`}
                        onViewClick={() => openURL(tos?.link, '_blank')}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Stack>
          )}

          {!isEmpty(companyAgreement) && companyAgreement && (
            <Stack gap={1}>
              <Typography variant="h6">Account agreement</Typography>
              <Row>
                <Col xs={12} sm={4}>
                  <DocumentCard
                    title={companyAgreementInfo?.title}
                    description={`Created on ${formatDate(
                      new Date(companyAgreement.created),
                      'datetime'
                    )}`}
                    onViewClick={() =>
                      openURL(companyAgreementInfo?.link, '_blank')
                    }
                  />
                </Col>
              </Row>
            </Stack>
          )}
        </Stack>
      )}

      <Divider />
      <Row spacing={2}>
        <Col xs={12} sm={12} md={12}>
          <Typography variant="h5">Attorney documents</Typography>
        </Col>
        {!isEmpty(attorneyDocuments) ? (
          map(attorneyDocuments, (document) => (
            <Col xs={12} sm={6} md={4}>
              <DocumentCard
                title={toString(document?.title)}
                description={`Created on ${formatDate(
                  new Date(document?.created_at),
                  'datetime'
                )}`}
                chip={
                  <DocumentStatusChip
                    status={document.status}
                    key={document.id}
                  />
                }
                onViewClick={
                  document?.pdf_url
                    ? () => openURL(document?.pdf_url, '_blank')
                    : undefined
                }
                onDownloadClick={
                  document?.pdf_url
                    ? () =>
                        downloadFile(
                          document?.pdf_url,
                          toString(document?.title)
                        )
                    : undefined
                }
                onResendClick={() =>
                  handleResendDocument(toNumber(document?.id))
                }
              />
            </Col>
          ))
        ) : (
          <Col>
            <Typography variant="body1" color="text.secondary">
              No Power of Attorneys has been signed or requested.
            </Typography>
          </Col>
        )}
      </Row>
      <Divider />
      {liabilityDocuments && (
        <Stack gap={2}>
          <Typography variant="h5">Additional Documents</Typography>
          <Row>
            {map(liabilityDocuments, (document) => (
              <Col xs={12} sm={4}>
                <DocumentCard
                  title={toString(document?.title)}
                  description={`Created on ${formatDate(
                    new Date(document?.created_at),
                    'datetime'
                  )}`}
                  chip={
                    <DocumentStatusChip
                      status={document.status}
                      key={document.id}
                    />
                  }
                  onViewClick={
                    document?.pdf_url
                      ? () => openURL(document?.pdf_url, '_blank')
                      : undefined
                  }
                  onDownloadClick={
                    document?.pdf_url
                      ? () =>
                          downloadFile(
                            document?.pdf_url,
                            toString(document?.title)
                          )
                      : undefined
                  }
                  onResendClick={() => handleResendDocument(document?.id)}
                />
              </Col>
            ))}
          </Row>
        </Stack>
      )}
    </Stack>
  );
};
