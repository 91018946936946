import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DialogCloseButton from '@treyd-io/treyd-ui/components/DialogCloseButton';
import ShipmentCheckIcon from '@treyd-io/treyd-ui/icons/ShipmentCheckIcon';
import { map, toNumber } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import { OrdersActivityLogs } from './tabs/OrdersActivityLogs';
import { OrdersComplianceReviews } from './tabs/OrdersComplianceReviews';
import { OrdersOperatorsReviews } from './tabs/OrdersOperatorsReviews';

export const OrdersComplianceReviewDialog = () => {
  const { id } = useParams();

  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('md'));
  const [
    isOrdersComplianceReviewDialogOpen,
    toggleOrdersComplianceReviewDialog,
  ] = useToggle(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const TABS = [
    {
      label: 'Operators reviews',
      content: <OrdersOperatorsReviews merchantId={toNumber(id)} />,
    },
    {
      label: 'Compliance reviews',
      content: <OrdersComplianceReviews merchantId={toNumber(id)} />,
    },
    {
      label: 'Comments',
      content: <OrdersActivityLogs merchantId={toNumber(id)} />,
    },
  ];

  return (
    <>
      <Button
        startIcon={<ShipmentCheckIcon />}
        variant="outlined"
        color="secondary"
        sx={{
          width: {
            xs: '100%',
            sm: 'unset',
          },
        }}
        onClick={toggleOrdersComplianceReviewDialog}>
        Orders activities
      </Button>
      <Dialog
        open={isOrdersComplianceReviewDialogOpen}
        fullWidth
        maxWidth="lg"
        fullScreen={isSmallScreens}
        onClose={toggleOrdersComplianceReviewDialog}>
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h5" color="text.primary" noWrap>
            Orders activities
          </Typography>
          <DialogCloseButton onClick={toggleOrdersComplianceReviewDialog} />
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 3,
          }}>
          <Tabs
            value={selectedTab}
            onChange={(_, newSelectedTab) => setSelectedTab(newSelectedTab)}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginBottom: 3,
            }}>
            {map(TABS, (tab) => (
              <Tab label={tab.label} />
            ))}
          </Tabs>
          {TABS[selectedTab].content}
        </DialogContent>
      </Dialog>
    </>
  );
};
