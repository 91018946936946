import { Alert, ButtonBase, Typography } from '@mui/material';
import { openURL } from '@treyd-io/core/utils/openUrl';

interface NotificationAlertProps {
  type: 'success' | 'error' | 'warning' | 'info';
  headline?: string | null;
  body?: string | null;
  showAction: boolean;
  actionText?: string | null;
  actionUrl?: string | null;
}
const NotificationAlert = (props: NotificationAlertProps) => {
  const { type, headline, body, showAction, actionText, actionUrl } = props;
  return (
    <Alert severity={type} onClose={() => {}}>
      <Typography variant="body1" sx={{ fontWeight: '550' }}>
        {headline || 'Headline'}
      </Typography>
      {body?.split('\n').map((item: any, i: number) => (
        <Typography key={i} variant="body2">
          {item}
        </Typography>
      ))}
      {showAction && (
        <ButtonBase onClick={() => actionUrl && openURL(actionUrl)}>
          <Typography
            variant="body2"
            sx={{ textDecorationLine: 'underline', cursor: 'pointer' }}>
            {actionText}
          </Typography>
        </ButtonBase>
      )}
    </Alert>
  );
};
export default NotificationAlert;
