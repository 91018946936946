import { DateRangeField } from '@/components/TableFilter/DateRangeField';
import {
  ORDER_MAPPED_STATUSES,
  ORDER_MAPPED_STATUS_DISPLAYED_VALUE,
} from '@/constants';
import { Financier } from '@/schemas';
import { OrdersTableFilters, OrdersTableFiltersFields } from '@/types';
import { getFiltersInitialValues } from '@/utils/TableFilters';
import {
  Autocomplete,
  Badge,
  Button,
  Chip,
  Divider,
  Menu,
  Stack,
  TextField,
  autocompleteClasses,
} from '@mui/material';
import {
  FilterComparator,
  QueryFilterField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import FiltersIcon from '@treyd-io/treyd-ui/icons/FiltersIcon';
import { Form, Formik } from 'formik';
import { find, isEmpty, map, startCase, toString } from 'lodash';
import { useState } from 'react';

interface TableFiltersProps {
  allFinanciers?: Financier[];
  onSubmit: (filters: QueryFilterField<OrdersTableFiltersFields>[]) => void;
  onReset?: () => void;
  filters?: QueryFilterField<OrdersTableFiltersFields>[];
}

export const TableFilters = (props: TableFiltersProps) => {
  const { onSubmit, onReset, allFinanciers, filters } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClose = () => setAnchorEl(null);
  const appliedFilterFieldsCount = filters?.length || 0;

  const initialValues = getFiltersInitialValues<OrdersTableFilters>(filters, {
    creationDateStart: toString,
    creationDateEnd: toString,
    expectedPaymentDateStart: toString,
    expectedPaymentDateEnd: toString,
    expectedRepaymentDateStart: toString,
    expectedRepaymentDateEnd: toString,
    actualRepaymentDateStart: toString,
    actualRepaymentDateEnd: toString,
    statuses: (statuses) => statuses || [],
    financiers: (financiers) => financiers || [],
  });

  const handleFormSubmit = (values: OrdersTableFilters) => {
    const {
      creationDateStart,
      creationDateEnd,
      expectedPaymentDateStart,
      expectedPaymentDateEnd,
      expectedRepaymentDateStart,
      expectedRepaymentDateEnd,
      actualRepaymentDateStart,
      actualRepaymentDateEnd,
      statuses,
      financiers,
    } = values;

    const filters: QueryFilterField<OrdersTableFiltersFields>[] = [];

    if (creationDateStart) {
      filters.push({
        key: 'creationDateStart',
        type: 'filter',
        name: 'created',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: creationDateStart,
      });
    }

    if (creationDateEnd) {
      filters.push({
        key: 'creationDateEnd',
        type: 'filter',
        name: 'created',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: creationDateEnd,
      });
    }

    if (expectedPaymentDateStart) {
      filters.push({
        key: 'expectedPaymentDateStart',
        type: 'filter',
        name: 'active_payout__expected_payment_date',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: expectedPaymentDateStart,
      });
    }

    if (expectedPaymentDateEnd) {
      filters.push({
        key: 'expectedPaymentDateEnd',
        type: 'filter',
        name: 'active_payout__expected_payment_date',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: expectedPaymentDateEnd,
      });
    }

    if (expectedRepaymentDateStart) {
      filters.push({
        key: 'expectedRepaymentDateStart',
        type: 'filter',
        name: 'active_payout__expected_settlement_date_calc',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: expectedRepaymentDateStart,
      });
    }

    if (expectedRepaymentDateEnd) {
      filters.push({
        key: 'expectedRepaymentDateEnd',
        type: 'filter',
        name: 'active_payout__expected_settlement_date_calc',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: expectedRepaymentDateEnd,
      });
    }

    if (actualRepaymentDateStart) {
      filters.push({
        key: 'actualRepaymentDateStart',
        type: 'filter',
        name: 'active_payout__actual_payment_date',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: actualRepaymentDateStart,
      });
    }

    if (actualRepaymentDateEnd) {
      filters.push({
        key: 'actualRepaymentDateEnd',
        type: 'filter',
        name: 'active_payout__actual_payment_date',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: actualRepaymentDateEnd,
      });
    }

    if (!isEmpty(statuses)) {
      filters.push({
        key: 'statuses',
        type: 'filter',
        name: 'mapped_status',
        comparator: FilterComparator.IN,
        comparisonValue: statuses,
      });
    }

    if (!isEmpty(financiers)) {
      filters.push({
        key: 'financiers',
        type: 'filter',
        name: 'financier__id',
        comparator: FilterComparator.IN,
        comparisonValue: financiers,
      });
    }

    onSubmit(filters);
    handleMenuClose();
  };

  return (
    <>
      <Badge
        badgeContent={appliedFilterFieldsCount}
        color="primary"
        sx={{ width: '100%' }}>
        <Button
          startIcon={<FiltersIcon />}
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          Filters
        </Button>
      </Badge>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormSubmit}>
        {(formik) => {
          return (
            <Menu
              keepMounted
              disablePortal
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => formik.handleSubmit()}
              sx={{}}
              slotProps={{
                paper: {
                  elevation: 2,
                  sx: {
                    padding: 2,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: 420,
                    backgroundColor: 'background.default',
                  },
                },
              }}>
              <Form
                noValidate
                tabIndex={-1}
                onKeyDown={(e) => e.stopPropagation()}>
                <Row tabIndex={-1}>
                  <Col xs={12}>
                    <DateRangeField name="creationDate" label="Creation Date" />
                  </Col>
                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <DateRangeField
                      name="expectedPaymentDate"
                      label="Expected Payment Date"
                    />
                  </Col>
                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <DateRangeField
                      name="expectedRepaymentDate"
                      label="Expected Repayment Date"
                    />
                  </Col>
                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <DateRangeField
                      name="actualRepaymentDate"
                      label="Actual Repayment Date"
                    />
                  </Col>

                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={ORDER_MAPPED_STATUSES}
                      value={formik.values?.statuses}
                      getOptionLabel={(option) =>
                        ORDER_MAPPED_STATUS_DISPLAYED_VALUE[option]
                      }
                      onChange={(_, statuses) => {
                        formik.setFieldValue('statuses', statuses);
                      }}
                      renderTags={(statuses, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(statuses, (option, index) => (
                            <Chip
                              label={startCase(option)}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Status"
                        />
                      )}
                    />
                  </Col>

                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(allFinanciers, (financier) => financier.id)}
                      value={formik.values?.financiers}
                      getOptionLabel={(option) =>
                        find(
                          allFinanciers,
                          (financier) => financier.id === option
                        )?.name || ''
                      }
                      onChange={(_, financiers) =>
                        formik.setFieldValue('financiers', financiers)
                      }
                      renderTags={(financiers, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(financiers, (option, index) => (
                            <Chip
                              label={
                                find(
                                  allFinanciers,
                                  (financier) => financier.id === option
                                )?.name
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Financier"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} gap={1} display="flex">
                    <Button
                      fullWidth
                      onClick={() => {
                        onReset?.();
                      }}
                      variant="outlined"
                      type="button"
                      color="secondary">
                      Reset
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary">
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Menu>
          );
        }}
      </Formik>
    </>
  );
};
