import { TypedDocumentNode, gql } from '@apollo/client';
import { Orders_Companies } from '__generated__/graphql';

export type Merchant = Pick<Orders_Companies, 'id' | 'name'>;

export type GetAllMerchantsQueryType = {
  orders_companies: Merchant[];
};

export const getAllMerchantsQuery = gql`
  query getAllCompanies {
    orders_companies {
      id
      name
    }
  }
` as TypedDocumentNode<GetAllMerchantsQueryType>;
