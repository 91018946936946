import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

export const DeactivateBankAccountDialog = ({
  isOpen,
  onClose,
  accountType,
  onDeactivateClick,
}: {
  isOpen: boolean;
  onClose: () => void;
  accountType: string;
  onDeactivateClick: () => void;
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Deactivate bank account</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to deactivate “{accountType}” account for this
          supplier?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          onClick={onClose}
          size="large">
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={onDeactivateClick}
          size="large">
          Yes, deactivate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
