import { MAPPED_ORDER_STATUS, OrderType } from '@/constants';
import { gql, TypedDocumentNode } from '@apollo/client';
import { Orders_Orders_Aggregate } from '__generated__/graphql';

type OrderCounts = {
  all: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  draft: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  awaiting_approval: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  inprogress: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  awaiting_compliance_approval: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  awaiting_funding_approval: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  approved: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  cancelledAndRejected: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  paid: Pick<Orders_Orders_Aggregate, 'aggregate'>;
};

export const getInReviewOrdersCount = gql`
  query order_counts {
    all: orders_orders_aggregate(where: { type: { _eq: ${OrderType.PAYABLE} } }) {
      aggregate {
        count
      }
    }
    draft: orders_orders_aggregate(
      where: { _and: { mapped_status: { _in: [${MAPPED_ORDER_STATUS.DRAFT}, ${MAPPED_ORDER_STATUS.PREVIEW}] }, type: {_eq: ${OrderType.PAYABLE} } } }
    ) {
      aggregate {
        count
      }
    }
    awaiting_approval: orders_orders_aggregate(
      where: {
        _and: { mapped_status: { _eq: ${MAPPED_ORDER_STATUS.AWAITING_APPROVAL} } , type: {_eq: ${OrderType.PAYABLE} }  }
        _not: { active_order_review: {} }
      }
    ) {
      aggregate {
        count
      }
    }
    inprogress: orders_orders_aggregate(
      where: {
        _and: { mapped_status: { _eq: ${MAPPED_ORDER_STATUS.AWAITING_APPROVAL} } , type: {_eq: ${OrderType.PAYABLE} }  }
        active_order_review: {}
      }
    ) {
      aggregate {
        count
      }
    }
    awaiting_compliance_approval: orders_orders_aggregate(
      where:{ _and: { mapped_status: { _eq: ${MAPPED_ORDER_STATUS.AWAITING_COMPLIANCE_APPROVAL} }, type: {_eq: ${OrderType.PAYABLE} }  } }
    ) {
      aggregate {
        count
      }
    }
    awaiting_funding_approval: orders_orders_aggregate(
      where: {_and: { mapped_status: { _eq: ${MAPPED_ORDER_STATUS.AWAITING_FUNDING_APPROVAL} } , type: {_eq: ${OrderType.PAYABLE} }  }}
    ) {
      aggregate {
        count
      }
    }
    approved: orders_orders_aggregate(
      where: {
        _and:{
        mapped_status: {
          _in: [
            ${MAPPED_ORDER_STATUS.READY_FOR_PAYMENT},
            ${MAPPED_ORDER_STATUS.PAYMENT_PROCESSING},
            ${MAPPED_ORDER_STATUS.PAID},
            ${MAPPED_ORDER_STATUS.INVOICE_OPEN},
            ${MAPPED_ORDER_STATUS.INVOICE_OVERDUE},
            ${MAPPED_ORDER_STATUS.INVOICE_COLLECTION},
            ${MAPPED_ORDER_STATUS.CLOSED}
          ]
        }
        , type: {_eq: ${OrderType.PAYABLE} }
      }}
    ) {
      aggregate {
        count
      }
    }
    cancelledAndRejected: orders_orders_aggregate(
      where: {_and: { mapped_status: { _in: [${MAPPED_ORDER_STATUS.CANCELED}, ${MAPPED_ORDER_STATUS.REJECTED}] }, type: {_eq: ${OrderType.PAYABLE} }  }}
    ) {
      aggregate {
        count
      }
    }
  }
` as TypedDocumentNode<OrderCounts, {}>;
