import {
  COMPANY_LIMIT_URL,
  DOCUMENTS_URL,
  NEXT_REPAYMENT_INVOICE,
  ORDER,
  ORDER_INVOICES_URL,
  ORDER_LOGS_VISUALIZATION,
} from '@/constants';
import Order, {
  NextRepaymentInvoiceResponse,
} from '@/pages/orderReview/models/Order';
import {
  Invoice,
  LoanAgreementResponse,
  OrderAvialableLimitsResponse,
} from '@/types';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { get, save } from '@treyd-io/core/utils/request';

export const getCompanyLimitByOrderId = async (orderId: number) => {
  const resp = await get<OrderAvialableLimitsResponse>(
    COMPANY_LIMIT_URL(orderId)
  );
  return resp.data;
};

export const getNextRepaymentInvoice = async (order_id: number) => {
  const resp = await get<NextRepaymentInvoiceResponse>(
    NEXT_REPAYMENT_INVOICE(order_id)
  );
  return resp.data;
};

export const getOrderDocuments = async (order_id: number) => {
  const resp = await get<LoanAgreementResponse>(
    DOCUMENTS_URL('orders', order_id)
  );
  return resp.data;
};

export const getOrderInvoices = async (order_id: number) => {
  const resp = await get<Invoice[]>(ORDER_INVOICES_URL(order_id));
  return resp.data;
};

export const updateOrder = async ({
  orderId,
  data,
}: {
  orderId: number;
  data: Partial<Order>;
}) =>
  await save(ORDER(orderId), data, REQUEST_METHOD.PUT, {
    noToast: true,
  });

export const getOrderVisualizationDiagram = async (order_id: number) => {
  const resp = await get<{
    order_diagram: string;
    payout_diagram: string;
  }>(ORDER_LOGS_VISUALIZATION(order_id));
  return resp.data;
};
