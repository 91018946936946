import { PaletteMode } from '@mui/material';
import React, { ReactNode, useMemo, useState } from 'react';
import { ColorModeContext } from '../context/colorModeContext';

interface ColorModeProviderProps {
  children: ReactNode;
}

export const ColorModeProvider = (props: ColorModeProviderProps) => {
  const [mode, setMode] = useState<PaletteMode>('light');
  const colorModeContext = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((previousMode: PaletteMode) =>
          previousMode === 'light' ? 'dark' : 'light'
        );
      },
      colorMode: mode,
    }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorModeContext}>
      {props.children}
    </ColorModeContext.Provider>
  );
};
