import { values } from 'lodash';

export enum TRANSACTION_TYPE {
  PAYMENT = 'PAYMENT',
  WRITE_OFF = 'WRITE_OFF',
  PENNY_ROUNDING = 'PENNY_ROUNDING',
  PAYMENT_TOLERANCE = 'PAYMENT_TOLERANCE',
  CREDIT = 'CREDIT',
}
export const transactionsTypes = values(TRANSACTION_TYPE);

export const transactionsTypesDisplayedValue = {
  [TRANSACTION_TYPE.PAYMENT]: 'Payment',
  [TRANSACTION_TYPE.WRITE_OFF]: 'Write off',
  [TRANSACTION_TYPE.PENNY_ROUNDING]: 'Penny rounding',
  [TRANSACTION_TYPE.PAYMENT_TOLERANCE]: 'Payment tolerance',
  [TRANSACTION_TYPE.CREDIT]: 'Credit',
};
