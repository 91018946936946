import { CompanyStatusesResponse, ComplianceStatus, Merchant } from '@/types';
import { Box, Stack, Typography } from '@mui/material';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import CloseCircleIcon from '@treyd-io/treyd-ui/icons/CloseCircleIcon';
import { toNumber } from 'lodash';
import { LimitStatus } from './LimitStatus';

const MerchantInformationSection = (props: {
  merchant: Merchant;
  complianceStatus: ComplianceStatus;
  riskAmlLevel?: string;
  companyStatuses?: CompanyStatusesResponse;
}) => {
  const hasPrimaryWalletIcon = props.merchant?.hasPrimaryWallet ? (
    <CheckCircleIcon color="success" />
  ) : (
    <CloseCircleIcon color="error" />
  );

  const hasPayoutWalletIcon = props.merchant?.hasPayoutWallet ? (
    <CheckCircleIcon color="success" />
  ) : (
    <CloseCircleIcon color="error" />
  );

  return (
    <>
      <Box
        marginTop={1}
        marginBottom={5}
        padding={3}
        borderRadius={2}
        border={1}
        borderColor="grey.300">
        <Row>
          <Col xs={6} md={2}>
            <TwoLines label="Org #" content={props.merchant?.orgNo} />
          </Col>
          <Col xs={6} md={2}>
            <TwoLines
              label="Incorporated"
              content={props.merchant?.incorporated_at}
            />
          </Col>
          <Col xs={6} md={2}>
            <TwoLines
              label="Joined"
              content={
                props.merchant?.joined
                  ? formatDate(new Date(props.merchant?.joined), 'date')
                  : ''
              }
            />
          </Col>
          <Col xs={6} md={2}>
            <TwoLines
              label="Country"
              content={getCountryName(props.merchant?.country as string)}
            />
          </Col>
          <Col xs={6} md={2}>
            <TwoLines
              label="Website"
              content={props.merchant?.website || 'N/A'}
            />
          </Col>
          <Col xs={6} md={2}>
            <TwoLines
              label="Office address"
              content={props.merchant?.address || 'N/A'}
            />
          </Col>
          <Col xs={6} md={2}>
            <TwoLines
              label="Macro sector"
              content={props.merchant?.macroSector || 'N/A'}
              contentProps={{
                noWrap: false,
              }}
            />
          </Col>
          <Col xs={6} md={2}>
            <TwoLines
              label="Micro sector"
              content={props.merchant?.microSector || 'N/A'}
              contentProps={{
                noWrap: false,
              }}
            />
          </Col>
          <Col xs={12} md={4}>
            <TwoLines
              label="Airwallex Wallet"
              content={
                <>
                  <Stack gap={2} direction={'row'}>
                    <Stack direction={'row'}>
                      {hasPrimaryWalletIcon}
                      <Typography variant="body1">Primary</Typography>
                    </Stack>
                    <Stack direction={'row'}>
                      {hasPayoutWalletIcon}
                      <Typography variant="body1">Payouts</Typography>
                    </Stack>
                  </Stack>
                </>
              }
              contentProps={{
                noWrap: false,
              }}
            />
          </Col>
          <Col xs={12} md={12}>
            <TwoLines
              label="Description"
              content={props.merchant?.description || 'N/A'}
              contentProps={{
                noWrap: false,
              }}
            />
          </Col>
        </Row>
      </Box>

      <LimitStatus companyId={toNumber(props.merchant?.id)} />
    </>
  );
};

export default MerchantInformationSection;
