import { MerchantWithLink } from '@/components';
import { ROUTES } from '@/constants';
import { useGetMerchantDetailsByOrderId } from '@/hooks';
import { InvoiceAlert } from '@/pages/invoices/InvoiceDetailsPage/InvoiceAlert';
import { Invoice } from '@/types';
import { getBankInfo } from '@/utils/bank';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency, formatDecimal } from '@treyd-io/core/utils/number';
import { CopyButton } from '@treyd-io/treyd-ui/components/CopyButton';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import HelpCircleIcon from '@treyd-io/treyd-ui/icons/HelpCircleIcon';
import { map, toString } from 'lodash';
import { Link } from 'react-router-dom';

export const InvoiceMainDetails = ({ invoice }: { invoice: Invoice }) => {
  const {
    invoice_date: invoiceDate,
    status,
    due_date: dueDate,
    order_id: orderId,
    invoiced_amount: toPayAmount,
    invoiced_currency: toPayCurrency,
    paid_amount: paidAmount,
    remaining_amount: remainingAmount,
    ocr,
    invoice_type: invoiceType,
    financier_name: financierName,
    payment_instruction: paymentInstruction,
    financier,
  } = invoice;

  const { data, loading } = useGetMerchantDetailsByOrderId(orderId);

  const merchantName = data?.orders_companies[0].name;
  const merchantId = data?.orders_companies[0].id;
  const merchantSegment = data?.orders_companies[0].segment;
  const invoicingCurrency = data?.orders_companies[0]?.invoicing_currency;
  const isRemainingAmount = remainingAmount !== 0 && paidAmount !== 0;
  const isOverdue =
    status === 'overdue' || status === 'collection' || status === 'written_off';
  const isCreditInvoice = invoiceType === 'credit';
  const showPaymentDetails = !isCreditInvoice && !invoice?.credited;

  const bankInfo = paymentInstruction && getBankInfo(paymentInstruction);

  const showNotificationMessage = useToastNotification();

  return (
    <Stack gap={5}>
      <InvoiceAlert
        invoice={invoice}
        invoicingCurrency={toString(invoicingCurrency)}
      />
      <Row justifyContent={'space-between'} alignItems={'center'}>
        <Col>
          {isRemainingAmount ? (
            <RemainingAmount
              remainingAmount={remainingAmount}
              toPayAmount={toPayAmount}
              toPayCurrency={toPayCurrency}
            />
          ) : (
            <ToPayAmount
              toPayAmount={toPayAmount}
              toPayCurrency={toPayCurrency}
              isCredit={isCreditInvoice}
            />
          )}
        </Col>
        <Col display={'flex'} gap={5} width={{ xs: '100%', md: 'auto' }}>
          <Col xs md="auto" textAlign={{ md: 'right' }}>
            <TwoLines
              label={
                <Typography variant="body1" color={'text.secondary'}>
                  Order
                </Typography>
              }
              content={
                <Link
                  to={`${ROUTES.orders}/${orderId}`}
                  style={{
                    textDecoration: 'none',
                  }}>
                  <Typography
                    color={'info.main'}
                    borderBottom={1}
                    display="inline">
                    #{orderId}
                  </Typography>
                </Link>
              }
            />
          </Col>
          <Col xs md="auto" textAlign={{ md: 'right' }}>
            <TwoLines
              label={
                <Typography variant="body1" color={'text.secondary'}>
                  Invoice date
                </Typography>
              }
              content={formatDate(new Date(invoiceDate), 'longdate')}
            />
          </Col>
          <Col xs md="auto" textAlign={{ md: 'right' }}>
            <TwoLines
              label={
                <Typography
                  variant="body1"
                  color={isOverdue ? 'error.main' : 'text.secondary'}>
                  Due date
                </Typography>
              }
              content={
                <Typography color={isOverdue ? 'error.main' : 'text.primary'}>
                  {formatDate(new Date(dueDate), 'longdate')}
                </Typography>
              }
            />
          </Col>
        </Col>
      </Row>

      {showPaymentDetails && (
        <Box borderRadius={4} border={1} borderColor="divider" padding={4}>
          <Stack gap={3}>
            <Typography variant="h6">Payment details</Typography>
            <Row spacing={2}>
              <Col xs="auto">
                <TwoLines
                  label={
                    <Box display={'flex'} gap={0.25}>
                      <Typography variant="body2" color="text.secondary">
                        Beneficiary
                      </Typography>
                      <Tooltip title="The recipient for the payment" arrow>
                        <Box display="flex" alignItems="center">
                          <HelpCircleIcon color="disabled" />
                        </Box>
                      </Tooltip>
                    </Box>
                  }
                  content={
                    <Box minWidth={'max-content'} marginRight={8}>
                      {financierName || 'N/A'}
                    </Box>
                  }
                />
              </Col>

              <Col xs="auto">
                <TwoLines
                  label={'Address'}
                  content={
                    <Box
                      display={'flex'}
                      alignItems={'start'}
                      minWidth={'max-content'}
                      marginRight={8}
                      title={financier.full_address || 'N/A'}>
                      {financier.full_address || 'N/A'}
                      <CopyButton
                        value={financier.full_address}
                        iconProps={{ sx: { color: 'info.main' } }}
                        buttonProps={{
                          onClick: () =>
                            showNotificationMessage({
                              title: 'Copied to clipboard',
                              type: 'success',
                              duration: 500,
                              position: 'absolute',
                            }),
                        }}
                      />
                    </Box>
                  }
                />
              </Col>

              <Col xs="auto">
                <TwoLines
                  label={
                    <Typography variant="body2" color="text.secondary">
                      Merchant
                    </Typography>
                  }
                  content={
                    <Box
                      display={'flex'}
                      alignItems={'start'}
                      minWidth={'max-content'}
                      marginRight={8}>
                      {!loading && merchantName && merchantSegment ? (
                        <MerchantWithLink
                          id={merchantId}
                          name={merchantName}
                          segment={merchantSegment}
                        />
                      ) : (
                        'N/A'
                      )}
                    </Box>
                  }
                />
              </Col>

              <Col xs="auto">
                <TwoLines
                  label={'OCR/Reference'}
                  content={
                    <Box
                      display={'flex'}
                      alignItems={'start'}
                      minWidth={'max-content'}
                      marginRight={8}>
                      {ocr}
                      <CopyButton
                        value={ocr}
                        iconProps={{ sx: { color: 'info.main' } }}
                        buttonProps={{
                          onClick: () =>
                            showNotificationMessage({
                              title: 'Copied to clipboard',
                              type: 'success',
                              duration: 500,
                              position: 'absolute',
                            }),
                        }}
                      />
                    </Box>
                  }
                />
              </Col>

              {map(
                bankInfo,
                (item) =>
                  item.value && (
                    <Col xs="auto" key={item.label}>
                      <TwoLines
                        label={item.label}
                        content={
                          <Box
                            display={'flex'}
                            alignItems={'start'}
                            minWidth={'max-content'}
                            marginRight={8}>
                            <Typography variant="body1">
                              {item.value}
                            </Typography>
                            <CopyButton
                              value={item.value}
                              iconProps={{ sx: { color: 'info.main' } }}
                              buttonProps={{
                                onClick: () =>
                                  showNotificationMessage({
                                    title: 'Copied to clipboard',
                                    type: 'success',
                                    duration: 500,
                                    position: 'absolute',
                                  }),
                              }}
                            />
                          </Box>
                        }
                      />
                    </Col>
                  )
              )}
            </Row>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

const RemainingAmount = ({
  toPayAmount,
  remainingAmount,
  toPayCurrency,
}: {
  toPayAmount: number;
  remainingAmount: number;
  toPayCurrency: string;
}) => {
  return (
    <TwoLines
      label={
        <Typography variant="body1" color={'text.secondary'}>
          Remaining amount
        </Typography>
      }
      content={
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box display={'flex'} gap={1} alignItems={'baseline'}>
            <Typography variant="h2">
              {formatDecimal(remainingAmount)}
            </Typography>
            <Typography variant="h5">{toPayCurrency}</Typography>
          </Box>
          <Typography variant="body1">
            Original amount:{' '}
            {formatCurrency(toPayAmount, toPayCurrency?.toString() as string)}
          </Typography>
        </Box>
      }
    />
  );
};

const ToPayAmount = ({
  toPayAmount,
  toPayCurrency,
  isCredit,
}: {
  toPayAmount: number;
  toPayCurrency: string;
  isCredit?: boolean;
}) => {
  return (
    <TwoLines
      label={
        <Typography variant="body1" color={'text.secondary'} marginTop={0}>
          {isCredit ? 'Credited' : 'To pay'}
        </Typography>
      }
      content={
        <Box display={'flex'} gap={1} alignItems={'baseline'}>
          <Typography variant="h2">{formatDecimal(toPayAmount)}</Typography>
          <Typography variant="h5">{toPayCurrency}</Typography>
        </Box>
      }
    />
  );
};
