import { Orders_Companies } from '@/__generated__/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

type getMerchantDetailsQueryTypes = {
  orders_companies_by_pk: Pick<
    Orders_Companies,
    'kyc' | 'country' | 'org_no' | 'name'
  >;
};
export const getMerchantDetailsQuery = gql`
  query getMerchantDetails($merchant_id: bigint!) {
    orders_companies_by_pk(id: $merchant_id) {
      name
      country
      org_no
      kyc
    }
  }
` as TypedDocumentNode<getMerchantDetailsQueryTypes, { merchant_id: number }>;
