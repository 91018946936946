import { Invitation, Merchant, OnboardingStatuses } from '@/types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DialogCloseButton from '@treyd-io/treyd-ui/components/DialogCloseButton';
import ClockIcon from '@treyd-io/treyd-ui/icons/ClockIcon';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';
import { OnboardingSteps } from './OnboardingStepsSection';
interface OnboardingProgressDialogProps {
  merchant: Merchant;
  onboardingStatuses: OnboardingStatuses;
  uboInvitations?: Invitation[];
}

export const OnboardingProgressDialog = (
  props: OnboardingProgressDialogProps
) => {
  const { merchant, onboardingStatuses, uboInvitations } = props;
  const { key } = useLocation();
  const [isOnboardingDialogOpen, setIsOnboardingDialogOpen] = useToggle(false);
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('md'));
  const handleOnboardingDialogClose = () => setIsOnboardingDialogOpen(false);
  const handleOnboardingDialogOpen = () => setIsOnboardingDialogOpen(true);
  useEffect(() => {
    handleOnboardingDialogClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);
  return (
    <>
      <Button
        startIcon={<ClockIcon />}
        color="secondary"
        variant="outlined"
        size="large"
        fullWidth
        onClick={handleOnboardingDialogOpen}>
        Onboarding
      </Button>
      <Dialog
        open={isOnboardingDialogOpen}
        onClose={handleOnboardingDialogClose}
        fullScreen={isSmallScreens}
        fullWidth
        maxWidth="md">
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h5" noWrap color="text.primary">
            Onboarding
          </Typography>
          <DialogCloseButton onClick={handleOnboardingDialogClose} />
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBlockEnd: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
          <Typography variant="body1">
            It shows the several steps that merchants go through and what is
            completed and left.
          </Typography>
          <Stack>
            <OnboardingSteps
              merchant={merchant}
              onboardingStatuses={onboardingStatuses}
              uboInvitations={uboInvitations}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
