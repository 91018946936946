import { CompanyIsActiveLabel } from '@/constants';
import { MerchantService } from '@/services';
import { Chip, ChipProps } from '@mui/material';

interface Props extends ChipProps {
  isActive?: boolean | null;
  orgNumber?: string;
}

const chipProps: {
  [key in CompanyIsActiveLabel]?: ChipProps;
} = {
  [CompanyIsActiveLabel.ACTIVE]: {
    label: CompanyIsActiveLabel.ACTIVE,
    variant: 'standard',
    color: 'success',
  },
  [CompanyIsActiveLabel.INACTIVE]: {
    label: CompanyIsActiveLabel.INACTIVE,
    variant: 'standard',
    color: 'error',
  },
  [CompanyIsActiveLabel.DELETED]: {
    label: CompanyIsActiveLabel.DELETED,
    variant: 'standard',
    color: 'error',
  },
};

export const MerchantLimitStatusChip = (props: Props) => {
  const { isActive, orgNumber, ...rest } = props;
  const status = MerchantService.getMerchantLimitStatusLabel(
    isActive,
    orgNumber
  );

  return <Chip {...chipProps[status]} {...rest} />;
};
