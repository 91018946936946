import { updateUserAuthProfile } from '@/schemas';
import { useMutation } from '@apollo/client';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';

export const useUpdateUserProfile = () => {
  const showNotificationMessage = useToastNotification();

  return useMutation(updateUserAuthProfile, {
    onCompleted() {
      showNotificationMessage({
        title: 'Changes saved successfully',
        type: 'success',
      });
    },
    onError() {
      showNotificationMessage({
        title: 'Something went wrong',
        type: 'error',
      });
    },
  });
};
