import { AlertColor, SnackbarOrigin } from '@mui/material';
import { createContext } from 'react';

export type ToastOptions = {
  title?: string;
  message?: string;
  type?: AlertColor;
  duration?: number;
  position?: 'absolute';
  placement?: SnackbarOrigin;
};

export type ShowToastMessage = (options: ToastOptions) => void;

export const ToastContext = createContext<ShowToastMessage>(() => {});
