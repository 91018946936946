import {
  BankingDataKey,
  FormattedOcrField,
  OcrConfidenceStatus,
  OcrData,
  OcrFieldMetaData,
  OcrSectionData,
  OcrSectionDataKey,
  OcrSectionName,
  UserDataKey,
} from '@/types';
import { chain, reduce, toNumber, toString } from 'lodash';

export const getMappedOcrFields = (ocrData: OcrData) =>
  chain<OcrSectionName>(['user_data', 'banking_data'])
    .map((sectionName) => ({
      ocrSectionPath: sectionName,
      data: ocrData?.[sectionName]?.data,
    }))
    .reduce(
      (acc, block) => formatOcrBlockFields(acc, block),
      {} as Record<OcrSectionDataKey, FormattedOcrField>
    )
    .value();

const formatOcrBlockFields = (
  initial: Record<OcrSectionDataKey, FormattedOcrField>,
  section: {
    ocrSectionPath: OcrSectionName;
    data: OcrSectionData<UserDataKey> | OcrSectionData<BankingDataKey>;
  }
) =>
  reduce(
    section?.data,
    (result, ocrFieldMetaData: OcrFieldMetaData, key) => {
      const confidenceLevel = toNumber(ocrFieldMetaData.confidence) || 0;
      let confidenceStatus = getConfidenceStatus(confidenceLevel);
      if (key === 'total_amount') confidenceStatus = 'success'; //because we are not taking it from the user anymore
      result[key as OcrSectionDataKey] = {
        isReviewed: ocrFieldMetaData.field_reviewed,
        confidenceStatus,
        operatorValue: toString(ocrFieldMetaData?.tesseract_output)?.trim(),
        invoiceAiValue: toString(
          ocrFieldMetaData?.final_text_recommendation
        )?.trim(),
        ocrSectionPath: section?.ocrSectionPath,
      };
      return result;
    },
    initial
  );

const getConfidenceStatus = (confidenceLevel: number): OcrConfidenceStatus => {
  if (confidenceLevel === 1) return 'success';
  return 'warning';
};
