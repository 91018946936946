import {
  Orders_Orders_Aggregate,
  Orders_Suppliers,
} from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

type GetCompanySupplier = {
  orders_suppliers_by_pk: Pick<Orders_Suppliers, 'name' | 'kyc'> & {
    orders_aggregate: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  };
};

type QueryVariables = {
  supplierId?: number;
  companyId?: number;
};

export const getCompanySupplierQuery = gql`
  query getCompanySupplier($supplierId: bigint!, $companyId: bigint!) {
    orders_suppliers_by_pk(id: $supplierId) {
      kyc
      name
      orders_aggregate(
        where: {
          _and: {
            importer_id: { _eq: $companyId }
            status: { _in: ["paid", "open", "closed"] }
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
` as TypedDocumentNode<GetCompanySupplier, QueryVariables>;
