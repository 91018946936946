import {
  COMMERCIAL_STATUS,
  MERCHANT_SEGMENT,
  ONBOARDING_STATUS,
} from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Lager_Treyd_Users,
  Orders_Companies,
  Orders_Financiers,
} from '__generated__/graphql';

export type Merchant = Pick<
  Orders_Companies,
  | 'id'
  | 'name'
  | 'country'
  | 'org_no'
  | 'created_at'
  | 'rating'
  | 'limit'
  | 'limit_currency'
  | 'is_active'
  | 'main_contact'
  | 'data'
  | 'sales_representative'
> & {
  active_combined_status: {
    status: ONBOARDING_STATUS | COMMERCIAL_STATUS;
    label: string;
  };
  company_users: Pick<Lager_Treyd_Users, 'email' | 'user_id'>[];
  financier: Pick<Orders_Financiers, 'name'>;
  orders_aggregate: { aggregate: { count: number } };
  segment: MERCHANT_SEGMENT;
};

type GetMerchantsQueryTypes = {
  orders_companies: Merchant[];
  orders_companies_aggregate: { aggregate: { count: number } };
};

export const getMerchantsQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getMerchants{
     orders_companies ${queryParams} {
      id
      name
      country
      segment
			org_no
			created_at
      financier{
				name
			}
      active_combined_status {
        status
        label
      }
			rating
			limit
			limit_currency
      is_active
      sales_representative {
        email
      }
			main_contact {
        email
      }
			data
			company_users {
				email
				user_id
			}
      orders_aggregate {
      aggregate {
        count
      }	
    }
	}
	orders_companies_aggregate ${whereClause ? `(where: ${whereClause})` : ''} {
		aggregate {
			count
		}
	}
	
  }
  ` as TypedDocumentNode<GetMerchantsQueryTypes>;
};
