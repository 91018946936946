import React, { ReactNode, useState } from 'react';
import { Toast } from '../components/Toast';
import {
  ShowToastMessage,
  ToastContext,
  ToastOptions,
} from '../context/toastContext';

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<ToastOptions>();

  const showToastMessage: ShowToastMessage = (
    options = {
      type: 'info',
      duration: 1500,
      title: '',
      message: '',
      position: undefined,
      placement: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    }
  ) => {
    setIsOpen(true);
    setOptions(options);
  };

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <ToastContext.Provider value={showToastMessage}>
        {children}
      </ToastContext.Provider>
      <Toast
        isOpen={isOpen}
        duration={options?.duration || 1500}
        message={options?.message}
        title={options?.title}
        onClose={handleClose}
        type={options?.type || 'info'}
        position={options?.position}
        placement={options?.placement}
      />
    </>
  );
};
