import {
  BANK_FORM_FIELDS,
  BENEFICIARY_FIELDS,
  FIELD_TITLE,
} from '@/constants/bank';
import {
  BankFieldsType as BeneficiaryFieldsType,
  BeneficiaryRequirementsType,
} from '@/types/bank';
import {
  first,
  isArray,
  isNull,
  isString,
  mapKeys,
  mapValues,
  pick,
  reduce,
} from 'lodash';
import * as yup from 'yup';

export class BeneficiaryFormService {
  public beneficiaryRequirements: BeneficiaryFieldsType;

  constructor({
    beneficiaryRequirements,
  }: {
    beneficiaryRequirements: BeneficiaryRequirementsType;
  }) {
    this.beneficiaryRequirements = beneficiaryRequirements?.beneficiary;
  }

  private createValidationSchema = () => {
    return reduce(
      this.beneficiaryRequirements,
      (
        acc: Partial<Record<string, RegExp | string[]>>,
        requirement: string | string[] | undefined,
        key: string
      ) => {
        if (isString(requirement)) {
          acc[key] = new RegExp(requirement);
        }
        if (isArray(requirement)) {
          acc[key] = requirement;
        }

        return acc;
      },
      {}
    );
  };

  private serializeBeneficiaryKeys = (key: string) =>
    ({
      [BANK_FORM_FIELDS.state]: 'state_or_province',
      [BANK_FORM_FIELDS.beneficiaryPostcode]: 'zip_code',
      [BANK_FORM_FIELDS.beneficiaryCity]: 'city',
      [BANK_FORM_FIELDS.beneficiaryAddress]: 'full_address',
    }[key] || key);

  public getValidationSchema = () => {
    const validationSchema = this.createValidationSchema();

    const requiredFields = pick(validationSchema, BENEFICIARY_FIELDS);

    const serializedValidationSchema = mapKeys(requiredFields, (_, key) =>
      this.serializeBeneficiaryKeys(key)
    );

    return mapValues(serializedValidationSchema, (regex, key) => {
      if (isArray(regex)) {
        return isNull(first(regex))
          ? yup.string().optional().oneOf(regex)
          : yup.string().required().oneOf(regex);
      }
      if (regex instanceof RegExp) {
        return yup
          .string()
          .required(`${FIELD_TITLE[key]} is required`)
          .matches(regex, 'Validation error');
      }
      return yup.string().required(`${FIELD_TITLE[key]} is required`);
    });
  };
}
