import { getCompanyAvailableCreditsAmount } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetCompanyCreditsAmount = (companyId: number) => {
  return useQuery({
    queryKey: ['company-credits-amount', companyId],
    queryFn: () => getCompanyAvailableCreditsAmount(companyId),
    select: (data) => data?.data,
    refetchOnWindowFocus: false,
    enabled: Boolean(companyId),
  });
};
