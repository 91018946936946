import { FormControlLabel, Checkbox } from '@mui/material';
import { useField } from 'formik';

export const GeneralFormikCheckBox = ({ children, ...props }: any) => {
  const [field] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <FormControlLabel
        control={<Checkbox {...field} {...props} />}
        label={children}
      />
    </>
  );
};
