import { NotificationStatusChip } from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { PAGE_SIZE_OPTIONS, ROUTES } from '@/constants';
import { useGetNotificationsGroups, useGetTableSettings } from '@/hooks';
import { NotificationGroup } from '@/schemas/getNotificationsGroupsQuery';
import { UserService } from '@/services';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export const NotificationsTable = ({ filters }: { filters?: Filter[] }) => {
  const {
    notificationsTableSettings: {
      sortModel,
      paginationModel,
      setPaginationModel,
      setSortModel,
    },
  } = useGetTableSettings();

  const columns: GridColDef<NotificationGroup>[] = useMemo(
    () => [
      {
        field: 'notification_template__header',
        headerName: 'Name',
        flex: 1,
        minWidth: 250,
        renderCell: (params) => {
          return (
            <Typography
              variant="body1"
              component={Link}
              noWrap
              to={`${ROUTES.notifications}/${params.row?.id}`}>
              {params.row?.notification_template?.header}
            </Typography>
          );
        },
      },
      {
        field: 'published',
        headerName: 'Status',
        minWidth: 140,
        renderCell: (params) => (
          <NotificationStatusChip size="small" isPublished={params.value} />
        ),
      },
      {
        field: 'user__first_name',
        headerName: 'Created By',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => UserService.getDisplayedName(params?.row?.user),
      },
      {
        field: 'targets_ids',
        headerName: 'Affected merchants',
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: ({ value }) => value?.length,
      },
      {
        field: 'created',
        headerName: 'Creation date',
        minWidth: 120,
        flex: 1,
        renderCell: (params) =>
          params.value && formatDate(new Date(params.value), 'date'),
      },
      {
        field: 'id',
        headerName: 'Notification #',
        align: 'right',
        headerAlign: 'right',
        flex: 1,
        minWidth: 100,
      },
    ],
    []
  );

  const { data, loading: isNotificationsGroupsLoading } =
    useGetNotificationsGroups({
      queryOptions: {
        filters,
        sorting: sortModel[0],
        limit: paginationModel?.pageSize,
        offset: paginationModel?.page * paginationModel?.pageSize,
      },
    });

  const notificationsGroups =
    data?.orders_notification_center_notificationgroup || [];
  const rowCount =
    data?.orders_notification_center_notificationgroup_aggregate?.aggregate
      ?.count || 0;

  const noNotificationsGroupsProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack flexGrow={1} height={400} width="100%">
      <DataGrid
        rows={notificationsGroups}
        columns={columns}
        disableRowSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        sortModel={sortModel}
        loading={isNotificationsGroupsLoading}
        onSortModelChange={setSortModel}
        slots={{
          noRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'No notifications found'}
              subtitle={'Notifications will be displayed here'}
            />
          ),
          ...noNotificationsGroupsProps,
        }}
        {...noNotificationsGroupsProps}
      />
    </Stack>
  );
};
