import { ROUTES } from '@/constants';
import MerchantsDetails from '@/pages/merchants/MerchantsDetails';
import { RouteObject } from 'react-router-dom';
import { MerchantsPage } from './MerchantsPage';

export const MERCHANTS: RouteObject = {
  path: `${ROUTES.merchants}`,
  children: [
    {
      index: true,
      element: <MerchantsPage />,
    },
    {
      path: ':id',
      element: <MerchantsDetails />,
    },
  ],
};
