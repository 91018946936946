/* eslint-disable @typescript-eslint/no-use-before-define */
import { TimelineItem } from '@/pages/merchants/MerchantsDetails/Tabs/GeneralInfo/TimelineItem';
import { Codes, LimitStatus, LimitStatusSegmentArgs, Segment } from '@/types';
import { Typography } from '@mui/material';
import { map, reduce, startCase } from 'lodash';
import { ReactNode } from 'react';
import { LimitStatusService } from '../LimitStatusService';

export const FundingSegmentMapper = (
  segment: LimitStatus.Segments['funding']
): Segment.Values => {
  const {
    FUNDING_NOT_SECURED: fundingNotSecured,
    FUNDING_SECURED: fundingSecured,
  } = reduce(
    segment.uiCodes,
    (
      acc: Partial<Record<Codes.Funding, LimitStatusSegmentArgs.Funding>>,
      uiCode
    ) => {
      acc[uiCode.code] = uiCode.kargs;
      return acc;
    },
    {}
  );

  const POINTS_COMPONENTS: Record<Codes.Funding, ReactNode> = {
    FUNDING_NOT_SECURED: (
      <BulletPoints.FundingNotSecured reason={fundingNotSecured?.reason} />
    ),
    FUNDING_NOT_STARTED: <BulletPoints.FundingNotStarted />,
    FUNDING_SECURED: (
      <BulletPoints.FundingSecured financier={fundingSecured?.financier} />
    ),
  };

  const points = map(
    segment.uiCodes,
    (uiCode) => POINTS_COMPONENTS[uiCode.code]
  );
  const icon = LimitStatusService.ICON_COMPONENTS[segment.status];

  return {
    icon,
    points,
  };
};

namespace BulletPoints {
  export const FundingNotStarted = () => (
    <TimelineItem>
      <Typography variant="body1">Funding not started.</Typography>
    </TimelineItem>
  );
  export const FundingNotSecured = ({
    reason,
  }: {
    reason: string | undefined;
  }) =>
    reason ? (
      <TimelineItem>
        <Typography variant="body1">
          Funding is not secured due to:{' '}
          <Typography display={'inline'} variant="subtitle1">
            {startCase(reason).toLowerCase()}
          </Typography>
          .
        </Typography>
      </TimelineItem>
    ) : (
      <TimelineItem>
        <Typography variant="body1">Funding is not secured.</Typography>
      </TimelineItem>
    );

  export const FundingSecured = ({
    financier,
  }: {
    financier: string | undefined;
  }) =>
    financier ? (
      <TimelineItem>
        <Typography variant="body1">
          Funding is secured by{' '}
          <Typography display={'inline'} variant="subtitle1">
            {financier}
          </Typography>
          .
        </Typography>
      </TimelineItem>
    ) : (
      <TimelineItem>
        <Typography variant="body1">Funding is secured.</Typography>
      </TimelineItem>
    );
}
