import Box from '@mui/material/Box';
import { isDevelopment, isStaging } from '@treyd-io/core/constants/api';
import { ReactNode } from 'react';
import { AppNavBar } from './AppNavBar';

export const AppNavBarLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Box sx={{ margin: isStaging || isDevelopment ? -1 : 0 }} height="100%">
      <AppNavBar>{children}</AppNavBar>
    </Box>
  );
};
