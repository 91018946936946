import { useResendDocumentEmails } from '@/hooks';
import { Documents } from '@/types';
import { Box, Button, Chip } from '@mui/material';
import { downloadFile } from '@treyd-io/core/utils/file';
import DownloadIcon from '@treyd-io/treyd-ui/icons/DownloadIcon';
import FileSearchIcon from '@treyd-io/treyd-ui/icons/FileSearchIcon';
import MailIcon from '@treyd-io/treyd-ui/icons/MailIcon';

export const DocumentActionButtons = ({
  document,
  companyId,
}: {
  document: Documents.DocumentType;
  companyId: number;
}) => {
  const { mutate: resendDocuments } = useResendDocumentEmails();
  const handleResendDocument = () => {
    resendDocuments({ companyId, documentId: document.id });
  };
  if (document?.status === 'signed' && document?.pdf_url) {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        gap={2}
        alignItems={'center'}>
        <Button
          color="info"
          onClick={() => downloadFile(document?.pdf_url, document?.title)}
          startIcon={<DownloadIcon />}>
          Download
        </Button>
        <Button
          startIcon={<FileSearchIcon />}
          variant="outlined"
          color="secondary"
          onClick={() => window.open(document.pdf_url)}>
          View
        </Button>
      </Box>
    );
  }
  if (document?.status === 'pending_signature') {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        gap={2}
        alignItems={'center'}>
        <Chip label="Pending" color="default" />
        <Button
          color="info"
          onClick={handleResendDocument}
          startIcon={<MailIcon />}>
          Resend
        </Button>
        <Button
          startIcon={<FileSearchIcon />}
          variant="outlined"
          color="secondary"
          disabled={!document?.pdf_url}
          onClick={() => window.open(document.pdf_url)}>
          View
        </Button>
      </Box>
    );
  }
};
