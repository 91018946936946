import {
  AMOUNTS_FIELDS,
  EXPORTER_BANK_ACCOUNT_FIELDS,
  ORDER_FIELDS,
} from '@/constants';
import { MappedOcrFields, OcrField, OcrRow } from '@/types';
import { map } from 'lodash';

export const mapOcrFieldsIntoRows = (
  fields: OcrField[],
  ocrData: MappedOcrFields
): OcrRow[] =>
  map(fields, (field) => ({
    title: field.title,
    ocrKeyPath: field.ocrKeyPath,
    dataKeyPath: field.dataKeyPath,
    confidenceStatus: ocrData?.[field.ocrKeyPath]?.confidenceStatus || 'error',
    isReviewed: ocrData?.[field.ocrKeyPath]?.isReviewed || false,
    ocrSectionPath: ocrData?.[field.ocrKeyPath]?.ocrSectionPath || '',
    operatorValue: ocrData?.[field.ocrKeyPath]?.operatorValue || '',
    invoiceAiValue: ocrData?.[field.ocrKeyPath]?.invoiceAiValue || '',
  }));

export const getOcrRows = (ocrData: any) => {
  const orderRows = mapOcrFieldsIntoRows(ORDER_FIELDS, ocrData);
  const exporterBankAccountRows = mapOcrFieldsIntoRows(
    EXPORTER_BANK_ACCOUNT_FIELDS,
    ocrData
  );
  const amountsRows = mapOcrFieldsIntoRows(AMOUNTS_FIELDS, ocrData);
  const invoiceRows = [...orderRows, ...exporterBankAccountRows];
  const ocrRows = [...invoiceRows, ...amountsRows];

  return {
    orderRows,
    exporterBankAccountRows,
    invoiceRows,
    amountsRows,
    ocrRows,
  };
};

export const getOcrRowsState = (ocrRows: OcrRow[]) => {
  const reviewedRowsCount = ocrRows.filter((row) => row.isReviewed).length;
  const unreviewedMismathcedRowsCount = ocrRows.filter(
    (row) => !row.isReviewed && row.confidenceStatus === 'warning'
  ).length;
  const unreviewedRowsCount =
    ocrRows.length - (reviewedRowsCount + unreviewedMismathcedRowsCount);
  const isAllRowsReviewed = reviewedRowsCount === ocrRows.length;
  return {
    reviewedRowsCount,
    unreviewedRowsCount,
    unreviewedMismathcedRowsCount,
    isAllRowsReviewed,
  };
};

export const getAccountNumber = (bank_account: any) => {
  let account_type = 'Account number/IBAN';
  let account_number_value = bank_account?.acct_number || bank_account?.iban;
  if (bank_account?.bankgiro_number) {
    account_type = 'Bankgiro number';
    account_number_value = bank_account?.bankgiro_number;
  } else if (bank_account?.plusgiro_number) {
    account_type = 'Plusgiro number';
    account_number_value = bank_account?.plusgiro_number;
  }
  return { account_type, account_number_value };
};
