import { DEFAULT_GRID_COL_DEF, PAGE_SIZE } from '@/constants';
import { RolesType } from '@/types';
import { Box, Chip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { MemberType } from '@/schemas';
import { TableRowType, TreydUser } from '@/types';
import { chain } from 'lodash';

const CompanyMembersTable = ({
  companyMembers,
  companyUsers,
}: {
  companyMembers?: MemberType[];
  companyUsers?: TreydUser[];
}) => {
  const isJoined = (member: MemberType, companyUsers?: TreydUser[]) =>
    companyUsers?.find((user) => user?.member?.id === member?.id);

  const getMemberStatus = (
    member: MemberType,
    companyUsers?: TreydUser[]
  ): TableRowType['status'] => {
    if (isJoined(member, companyUsers)) {
      return { label: 'Joined', color: 'success' };
    }
    return { label: '-', color: 'primary' };
  };

  const getMemberRoles = (member: MemberType) => {
    const roles: RolesType[] = [{ label: 'Board', color: 'info' }];
    if (member?.core_signatories?.length === 0) {
      return roles;
    }
    if (member?.core_signatories?.[0]?.can_sign_alone) {
      roles.push({ label: 'Can sign alone', color: 'success' });
    }
    if (!member?.core_signatories?.[0]?.can_sign_alone) {
      roles.push({ label: 'Can sign', color: 'success' });
    }
    return roles;
  };

  const membersRows: TableRowType[] = chain(companyMembers)
    .map((member) => ({
      id: member?.id,
      name: member?.name,
      role: getMemberRoles(member),
      status: getMemberStatus(member, companyUsers),
    }))
    .orderBy((event) => event.role.length, ['desc'])
    .value();
  const columns: GridColDef<TableRowType>[] = [
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'name',
      headerName: 'Full name',
      width: 250,
      type: 'string',
      renderCell: (params) => (
        <Typography variant="body2">{params.value}</Typography>
      ),
    },
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'role',
      headerName: 'Role',
      type: 'string',
      minWidth: 250,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            height: theme.spacing(6),
            gap: theme.spacing(1),
          })}>
          {params.value.map((role: RolesType) => (
            <Chip
              variant="standard"
              key={role.label}
              label={role.label}
              color={role.color}
            />
          ))}
        </Box>
      ),
    },
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 150,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={(theme) => ({
            color:
              params.row?.status?.color &&
              theme.palette[params.row?.status?.color].main,
          })}>
          {params.value?.label}
        </Typography>
      ),
    },
  ];

  return (
    <Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1.5),
          marginBlockEnd: theme.spacing(1.5),
        })}>
        <Typography variant="h5">Public company directors</Typography>
        <Typography variant="body2">
          This is the list of all directors associated with this company
          available within public records.
        </Typography>
      </Box>
      <DataGrid
        rows={membersRows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: PAGE_SIZE,
            },
          },
        }}
        autoHeight={true}
        getRowHeight={() => 'auto'}
      />
    </Box>
  );
};

export default CompanyMembersTable;
