import { Documents_Power_Of_Attorneys } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

export type PowerOfAttorneys = {
  documents_power_of_attorneys: Array<
    Pick<Documents_Power_Of_Attorneys, 'user_id' | 'company_id'>
  >;
};
type QueryVariables = {
  company_id: number;
};

export const getPowerOfAttorneys = gql`
  query getPowerOfAttorneys($company_id: Int!) {
    documents_power_of_attorneys(where: { company_id: { _eq: $company_id } }) {
      user_id
      company_id
    }
  }
` as TypedDocumentNode<PowerOfAttorneys, QueryVariables>;
