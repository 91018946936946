import { getOrderOpsAutoReview } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetOrderOpsAutoReview = (orderId: number) => {
  return useQuery({
    queryKey: ['orderOpsAutoReview', orderId],
    queryFn: () => getOrderOpsAutoReview(orderId),
    enabled: !!orderId,
  });
};
