import { MAPPED_ORDER_STATUS, ORDER_STATUS, ROUTES } from '@/constants';
import {
  useActivityLog,
  useGetCompanyStatuses,
  useGetOrderComplianceAiReview,
  useGetOrderDuplicates,
  useGetOrderOpsAutoReview,
  useOrderReview,
} from '@/hooks';
import OcrStatusIcon from '@/pages/orderReview/OcrStatusIcon';
import Compliance from '@/pages/orderReview/OrderReviewDetails/Compliance';
import { Funding } from '@/pages/orderReview/OrderReviewDetails/Funding';
import { LinksSection } from '@/pages/orderReview/OrderReviewDetails/LinksSection';
import { OrderInformationTablesSection } from '@/pages/orderReview/OrderReviewDetails/OrderInformationTableSection/OrderInformationTablesSection';
import {
  getOcrRows,
  getOcrRowsState,
} from '@/pages/orderReview/OrderReviewDetails/OrderInformationTableSection/utils';
import { OrderReviewInformationTab } from '@/pages/orderReview/OrderReviewDetails/OrderReviewInformationTab';
import { QualityInspection } from '@/pages/orderReview/OrderReviewDetails/QualityInspection';
import ReviewActionsHeader from '@/pages/orderReview/OrderReviewDetails/ReviewActionsHeader';
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import AlertIcon from '@treyd-io/treyd-ui/icons/AlertIcon';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import CloseCircleIcon from '@treyd-io/treyd-ui/icons/CloseCircleIcon';
import InformationIcon from '@treyd-io/treyd-ui/icons/InformationIcon';
import { useAuth } from 'auth';
import { LOGGING_MESSAGES } from 'constants/loggly';
import { useLogglyLoggingService } from 'hooks';
import { isEmpty, map } from 'lodash';

import { assignSelf } from '@/api';
import { IndicativeLimitAlert } from '@/components';
import NavTabsBar from '@/components/NavTabsBar';
import { useBrowsingHistory } from '@/hooks/useBrowsingHistory';
import { SupplierInvoiceProvider } from '@/providers';
import AppBar from '@mui/material/AppBar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActivityLogSection } from './ActivityLogSection';
import AssigneeSection from './AssigneeSection';
import StartReview from './StartReview';
import { SupplierInvoice } from './SupplierInvoice';
import { SupplierInvoiceDrawer } from './SupplierInvoiceDrawer';

const StatusHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '56px',
  gap: theme.spacing(1.25),
}));

export default function OrderReviewDetails(props: any) {
  const {
    id,
    deal,
    loading,
    activeOrderReview,
    reviewerId,
    reviewerName,
    refetchReviewer,
    ocrData,
    fetchOrder,
    handleOcrFieldUpdate,
    orderLoanAgreementsData,
    publishedCreditDecisionData,
    remainingLimitData,
    isMerchantFirstOrder,
  } = props;
  const { goBack } = useBrowsingHistory();
  const { user } = useAuth();
  const { data: companyStatuses, isPending: isLoadingCompanyStatuses } =
    useGetCompanyStatuses(Number(deal?.importer_id));

  const isLimitIndicative = companyStatuses?.limit_status === 'INDICATIVE';

  const showNotificationMessage = useToastNotification();
  const actionsDisabled =
    user.id !== reviewerId ||
    ![
      ORDER_STATUS.AWAITING_APPROVAL,
      ORDER_STATUS.AWAITING_COMPLIANCE_APPROVAL,
      ORDER_STATUS.AWAITING_FUNDING_APPROVAL,
    ].includes(deal?.status);
  const { orderReviewData } = useOrderReview(deal?.id, activeOrderReview);
  const log = useLogglyLoggingService();
  const { data: dealDuplicates, isPending: isLoadingOrderDuplicates } =
    useGetOrderDuplicates(deal?.id);
  const { ocrRows } = getOcrRows(ocrData);
  const { isAllRowsReviewed, unreviewedRowsCount } = getOcrRowsState(ocrRows);
  const [isReviewFinished, setIsReviewFinished] = useState(isAllRowsReviewed);
  const [unReviewedRows, setUnReviewedRows] = useState(unreviewedRowsCount);
  const agreementPDF = orderLoanAgreementsData?.[0]?.pdf_url;

  const {
    data: orderOpsAutoReviewData,
    isPending: isOrderOpsAutoReviewDataLoading,
  } = useGetOrderOpsAutoReview(deal?.id);

  const {
    data: orderComplianceAutoReviewData,
    isFetching: isOrderComplianceAutoReviewLoading,
    error: orderComplianceAutoReviewError,
  } = useGetOrderComplianceAiReview(deal?.id);

  const handleOcrFieldReviewChecked = (
    ocrSectionPath: string,
    ocrKeyPath: string,
    isChecked: boolean
  ) => {
    if (isChecked) {
      setUnReviewedRows((prev) => prev - 1);
    } else if (!isChecked) {
      setUnReviewedRows((prev) => prev + 1);
    }
    handleOcrFieldUpdate(
      ocrSectionPath,
      ocrKeyPath,
      JSON.stringify({ field_reviewed: isChecked })
    );
  };

  useEffect(() => {
    if (unReviewedRows === 0) setIsReviewFinished(true);
    else setIsReviewFinished(false);
  }, [unReviewedRows, isReviewFinished]);

  const tabs = [
    {
      id: 'information',
      label: 'Information',
      content: <OrderReviewInformationTab deal={deal} />,
    },
    {
      id: 'checklist',
      label: 'Checklist',
      content: (
        <OrderInformationTablesSection
          order={deal}
          fetchOrder={fetchOrder}
          activeOrderReview={activeOrderReview}
          actionsDisabled={actionsDisabled}
          ocrData={ocrData}
          orderReviewData={orderReviewData}
          onOcrFieldReviewChecked={handleOcrFieldReviewChecked}
        />
      ),
      chipLabel: <OcrStatusIcon ocrData={ocrData} />,
    },
    {
      id: 'supplierInvoice',
      label: 'Supplier invoice',
      content: <SupplierInvoice autoReviewData={orderOpsAutoReviewData} />,
      chipLabel: orderOpsAutoReviewData?.is_auto_approval ? (
        <CheckCircleIcon color="success" />
      ) : (
        <AlertIcon color="error" />
      ),
    },
    {
      id: 'compliance',
      label: 'Compliance',
      content: (
        <Compliance
          orderComplianceAutoReviewData={orderComplianceAutoReviewData}
          orderComplianceAutoReviewError={orderComplianceAutoReviewError}
          order={deal}
        />
      ),
      chipLabel: orderComplianceAutoReviewData?.is_auto_approval ? (
        <CheckCircleIcon color="success" />
      ) : (
        <AlertIcon color="error" />
      ),
    },
    {
      id: 'funding',
      label: 'Funding',
      content: (
        <Funding
          order={deal}
          remainingLimitData={remainingLimitData}
          creditDecision={publishedCreditDecisionData}
          refetchOrder={fetchOrder}
        />
      ),
      chipLabel: deal?.mapped_status ===
        MAPPED_ORDER_STATUS.AWAITING_FUNDING_APPROVAL && (
        <AlertIcon color="error" />
      ),
    },
  ];

  const logs = useActivityLog(id);

  const onClickAssign = async () => {
    try {
      await assignSelf(id);
      showNotificationMessage({
        title: 'Order assigned to you.',
      });
      log({
        key: 'Orders',
        message: LOGGING_MESSAGES.ORDER_ASSIGN_SUCCESS,
        data: { orderId: deal?.id },
      });
      refetchReviewer();
      logs.refetch();
    } catch (err: any) {
      log({
        type: 'ERROR',
        key: 'Orders',
        message: LOGGING_MESSAGES.ORDER_ASSIGN_FAIL,
        data: { orderId: deal?.id, error: err },
      });
      showNotificationMessage({
        title: err?.response?.data?.detail || 'Something went wrong.',
        type: 'error',
      });
    }
  };

  if (
    loading ||
    isLoadingOrderDuplicates ||
    isOrderOpsAutoReviewDataLoading ||
    isOrderComplianceAutoReviewLoading ||
    isLoadingCompanyStatuses
  ) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }

  const needReviewer =
    !reviewerId &&
    [
      ORDER_STATUS.AWAITING_APPROVAL,
      ORDER_STATUS.AWAITING_COMPLIANCE_APPROVAL,
      ORDER_STATUS.AWAITING_FUNDING_APPROVAL,
    ].includes(deal?.status);
  interface StatusType {
    [key: string]: {
      color: string;
      msg: string;
      icon: JSX.Element;
    };
  }
  const Status: StatusType = {
    ready_for_payment: {
      color: 'success.main',
      msg: 'Order approved',
      icon: <CheckCircleIcon fontSize="large" sx={{ color: 'white' }} />,
    },
    canceled: {
      color: 'error.main',
      msg: 'Order rejected',
      icon: <CloseCircleIcon fontSize="large" sx={{ color: 'white' }} />,
    },
    rejected: {
      color: 'error.main',
      msg: 'Order rejected',
      icon: <CloseCircleIcon fontSize="large" sx={{ color: 'white' }} />,
    },
    open: {
      color: 'success.main',
      msg: 'Order approved',
      icon: <CheckCircleIcon fontSize="large" sx={{ color: 'white' }} />,
    },
    paid: {
      color: 'success.main',
      msg: 'Order approved',
      icon: <CheckCircleIcon fontSize="large" sx={{ color: 'white' }} />,
    },
    duplicate: {
      color: 'warning.main',
      msg: 'Possible duplicate',
      icon: <AlertIcon fontSize="large" sx={{ color: 'white' }} />,
    },
    firstOrder: {
      color: 'info.main',
      msg: 'First order',
      icon: <InformationIcon fontSize="large" sx={{ color: 'white' }} />,
    },
  };

  return (
    <SupplierInvoiceProvider invoiceUrl={deal?.supplier_invoice.invoice?.url}>
      <Stack display="flex" direction="row">
        <Stack flexGrow={1} width="100%">
          <AppBar
            elevation={0}
            position="sticky"
            sx={{
              width: '100%',
              backgroundColor: 'transparent',
              color: 'inherit',
            }}>
            <Toolbar
              disableGutters
              sx={{
                flexDirection: 'column',
                alignItems: 'stretch',
              }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingX={5}
                paddingY={2}
                bgcolor={'background.default'}
                borderBottom={1}
                borderColor="other.outlined_border">
                <Typography variant="h5">
                  Order #{id} {deal?.status === 'draft' && '(Draft)'}
                </Typography>
                <ReviewActionsHeader
                  deal={deal}
                  dealDuplicates={dealDuplicates}
                  goBack={goBack}
                  actionsDisabled={actionsDisabled}
                  areAllSectionsApproved={isReviewFinished}
                />
              </Stack>
              {deal?.status !== 'awaiting_approval' && Status[deal?.status] && (
                <StatusHeader
                  sx={{ backgroundColor: Status[deal?.status]?.color }}>
                  {Status[deal?.status].icon}
                  <Typography color={'white'}>
                    {Status[deal?.status]?.msg}
                  </Typography>
                </StatusHeader>
              )}
              {deal?.status === 'awaiting_approval' &&
                !isEmpty(dealDuplicates) && (
                  <StatusHeader
                    sx={{ backgroundColor: Status['duplicate'].color }}>
                    {Status['duplicate'].icon}
                    <Typography color={'white'}>
                      {Status['duplicate'].msg}
                    </Typography>
                  </StatusHeader>
                )}
              {isMerchantFirstOrder && (
                <StatusHeader
                  sx={{ backgroundColor: Status['firstOrder'].color }}>
                  {Status['firstOrder'].icon}
                  <Typography color={'white'}>
                    {Status['firstOrder'].msg}
                  </Typography>
                </StatusHeader>
              )}
            </Toolbar>
          </AppBar>
          <Stack
            flexGrow={1}
            paddingX={{ xs: 3, lg: 2 }}
            paddingY={10}
            maxWidth={1184}
            marginX="auto"
            direction="row"
            flexWrap="wrap"
            width="100%"
            gap={6}>
            <Stack flexGrow={1} flexBasis={720} width="100%">
              {needReviewer && <StartReview onClickReview={onClickAssign} />}
              <Stack position="relative" gap={2}>
                {needReviewer && (
                  <Box
                    sx={{
                      backgroundColor: 'background.default',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      zIndex: 2,
                      opacity: 0.77,
                    }}
                  />
                )}
                {isLimitIndicative && <IndicativeLimitAlert />}
                <Typography variant="h4">Order review</Typography>
                <NavTabsBar tabs={tabs} tabsSx={{ marginBlockEnd: 2 }} />
              </Stack>
            </Stack>
            <Stack gap={6} flexGrow={1} flexBasis={336} width="100%">
              {!isEmpty(dealDuplicates) && (
                <Alert severity="warning">
                  <AlertTitle>Possible duplicate</AlertTitle>
                  <Stack gap={0.5} alignItems="flex-start">
                    Possible duplicate of this order has been found. Here are
                    the associated orders:
                    {map(dealDuplicates, (duplicate) => (
                      <MuiLink
                        display="block"
                        color="warning.160p"
                        key={duplicate?.id}
                        variant="subtitle2"
                        component={Link}
                        to={`${ROUTES.orderReview}/${duplicate?.id}`}>
                        #{duplicate?.id} ↗
                      </MuiLink>
                    ))}
                  </Stack>
                </Alert>
              )}
              {deal?.status === 'draft' ? (
                <Alert severity="info">
                  <AlertTitle>Draft order</AlertTitle>
                  This order may have some empty fields as merchants haven’t
                  sent them yet.
                </Alert>
              ) : (
                <AssigneeSection
                  reviewerId={reviewerId}
                  reviewerName={reviewerName}
                  onClickAssign={onClickAssign}
                />
              )}
              <LinksSection
                orderAgreementLink={agreementPDF}
                supplierInvoiceLink={deal?.supplier_invoice.invoice?.url}
                supportingFiles={deal?.supporting_files}
              />
              <QualityInspection order={deal} />
              <ActivityLogSection orderId={id} logs={logs} />
            </Stack>
          </Stack>
        </Stack>
        <SupplierInvoiceDrawer />
      </Stack>
    </SupplierInvoiceProvider>
  );
}
