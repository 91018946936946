import { useGetOrderActivityLog, useGetOrderSignatureLogs } from '@/hooks';
import { map, toString } from 'lodash';

interface ActivityLog {
  id: number;
  created: string;
  modified: string;
  type: string | null;
  message: string;
  user: any;
  data?: any;
}

const formatActivityLog = (log: any): any => {
  if (log.type === 'comment')
    return {
      id: log.id,
      created: log.created,
      modified: log.modified,
      user: log.user,
      type: 'comment',
      data: log.data,
      message: log.data.comment,
    };
  if (log.type === 'log') {
    if (log.data.action_type === 'order_assigned') {
      return {
        id: log.id,
        created: log.created,
        modified: log.modified,
        user: log.user,
        type: 'review',
        data: log.data,
      };
    } else if (
      log.data.action_type === 'review_decision' ||
      log.data.action_type === 'compliance_decision' ||
      log.data.action_type === 'funding_decision'
    ) {
      return {
        id: log.id,
        created: log.created,
        modified: log.modified,
        user: log.user,
        type: 'review',
        message: log.data.verdict,
        reason: log.data.reason,
        comment: log.data.comment,
      };
    }
  }
};

export const useActivityLog = (
  orderId: number
): { activityLogs: ActivityLog[]; loading: boolean; refetch: () => void } => {
  const { data, loading, refetch } = useGetOrderActivityLog(orderId);
  const { data: orderSignatureLogsData, loading: isOrderSignatureLogsLoading } =
    useGetOrderSignatureLogs(orderId);

  if (loading || isOrderSignatureLogsLoading) {
    return { activityLogs: [], loading, refetch: () => {} };
  }

  const orderReviewActivityLogs: ActivityLog[] = map(
    data?.orders_orders_app_activitylog,
    formatActivityLog
  );

  const orderSignatureLogs = map(
    orderSignatureLogsData?.orders_orders_app_orderlog,
    (log) => ({
      id: 0,
      created: toString(log.timestamp),
      modified: toString(log.timestamp),
      type: 'log',
      message: 'Agreement signed',
      user: null,
    })
  );

  const activityLogs: ActivityLog[] = map([
    ...orderReviewActivityLogs,
    ...orderSignatureLogs,
  ])?.sort(
    (b, a) => new Date(a?.created).valueOf() - new Date(b?.created).valueOf()
  );

  activityLogs.push({
    id: 0,
    created: toString(data?.orders_orders_by_pk?.created),
    modified: toString(data?.orders_orders_by_pk?.created),
    type: 'log',
    message: 'Order created',
    user: null,
  });

  return { activityLogs, loading, refetch };
};
