import { Box, Chip, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface NavTabProps {
  label: ReactNode;
  chipLabel?: ReactNode;
}

const NavTab = (props: NavTabProps) => (
  <TabLabelContainer>
    <Typography variant="body2">{props.label}</Typography>
    {Boolean(props.chipLabel) ? (
      typeof props.chipLabel === 'string' ||
      typeof props.chipLabel === 'number' ? (
        <Chip
          sx={{ marginInlineStart: 1 }}
          label={props.chipLabel}
          size="small"
        />
      ) : (
        <Box
          sx={{
            marginInlineStart: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {props.chipLabel}
        </Box>
      )
    ) : null}
  </TabLabelContainer>
);
export default NavTab;

const TabLabelContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
