import { DEFAULT_COUNTRY } from '@/constants';
import { getBeneficiaryRequirementsQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import { find } from 'lodash';

export const useGetSupplierRequirements = ({
  beneficiaryCountry,
  bankCountry,
}: {
  beneficiaryCountry: string;
  bankCountry?: string;
}) => {
  const { data, loading } = useQuery(getBeneficiaryRequirementsQuery, {
    variables: {
      beneficiaryCountry:
        beneficiaryCountry?.toUpperCase() ||
        bankCountry?.toUpperCase() ||
        DEFAULT_COUNTRY,
      bankAccountCountry:
        bankCountry?.toUpperCase() ||
        beneficiaryCountry?.toUpperCase() ||
        DEFAULT_COUNTRY,
    },
    skip: !beneficiaryCountry || !bankCountry,
  });

  return {
    data: find(
      data?.banking?.awx_beneficiary_requirements,
      (req) => req.bank_account.payment_type === 'priority'
    ),
    loading,
  };
};
