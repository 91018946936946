import { gql, TypedDocumentNode } from '@apollo/client';
import { Orders_Orders_App_Consent } from '__generated__/graphql';

type CompanyAgreementConsent = Pick<Orders_Orders_App_Consent, 'created'>;

export type GetCompanyAgreementConsentsType = {
  orders_orders_app_consent: CompanyAgreementConsent[];
};

export const getCompanyAgreementConsentsQuery = gql`
  query getCompanyAgreementConsents($companyId: Int!) {
    orders_orders_app_consent(
      where: {
        _and: {
          consentable_id: { _eq: $companyId }
          type: { _eq: "company_agreement" }
          is_active: { _eq: true }
          consentable_type: { _eq: "company" }
        }
      }
    ) {
      created
    }
  }
` as TypedDocumentNode<GetCompanyAgreementConsentsType, { companyId: number }>;
