import { gql } from '@apollo/client';

export const BASE_SUPPLIER = gql`
  fragment BaseSupplier on orders_suppliers {
    id
    name
    legal_name
    company_id
    country
    city
    full_address
    zip_code
    state_or_province
    org_no
    preferred_bank_account_id
    contacts
    email
    factory
    phone_no
    sofeast_data
    website
    exempted_from_inspections
    updated_at
    created_at
    approval_status_calc
    is_monitored
  }
`;
