import {
  MERCHANT_COMBINED_STATUS_DISPLAYED_VALUE,
  MERCHANT_SEGMENTS_DISPLAYED_VALUE,
} from '@/constants';
import { Merchant } from '@/schemas/getMerchantsQuery';
import { CSVBuilder } from '@treyd-io/core/services/CsvService';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { downloadCsvFile } from '@treyd-io/core/utils/downloadCSV';
import { isEmpty, map, toString, toUpper } from 'lodash';
import { MerchantService } from './MerchantService';
export class MerchantsCsvService {
  private csvBuilder: CSVBuilder;

  constructor() {
    this.csvBuilder = new CSVBuilder();
  }

  private buildHeaders(): void {
    this.csvBuilder.setHeaders([
      ' ',
      'Name',
      'Org #',
      'Segment',
      'Status',
      'Label',
      'Limit status',
      'Country',
      'Financier',
      'Joining date',
      'Number of orders',
      'Treyd rating',
      'Credit Limit',
      'Currency',
      'Main contact email',
      'Invoicing email',
      'Sales representative',
    ]);
  }

  private formatMerchantRow(
    merchant: Merchant,
    index: number
  ): (string | number | Date)[] {
    const status = merchant.active_combined_status?.status
      ? MERCHANT_COMBINED_STATUS_DISPLAYED_VALUE[
          merchant.active_combined_status?.status
        ]
      : '';
    const label = merchant.active_combined_status?.label || '';
    const companyLimit = merchant?.limit;
    const orgNumber = toString(merchant?.org_no);
    const limitStatus = MerchantService.getMerchantLimitStatusLabel(
      merchant?.is_active,
      orgNumber
    );
    const invoicingContact = merchant.data.invoicing_contact || '';
    const mainContactEmail = merchant.main_contact?.email || '';
    const salesRepresentative = merchant.sales_representative?.email || '';

    return map(
      [
        index + 1,
        toString(merchant?.name),
        orgNumber,
        MERCHANT_SEGMENTS_DISPLAYED_VALUE[merchant.segment],
        status,
        label,
        limitStatus,
        merchant?.country ? getCountryName(merchant?.country) : '',
        toString(merchant?.financier?.name),
        merchant?.created_at
          ? formatDate(new Date(merchant?.created_at), 'date')
          : '',
        merchant?.orders_aggregate?.aggregate?.count || 0,
        merchant?.rating || '',
        companyLimit === null ? '' : companyLimit,
        companyLimit === null
          ? ''
          : toUpper(toString(merchant?.limit_currency)),
        mainContactEmail,
        invoicingContact,
        salesRepresentative,
      ],
      (value) => `"${value}"`
    );
  }

  private buildRows(merchants: Merchant[]): void {
    const formattedRows = map(merchants, (merchant, index) =>
      this.formatMerchantRow(merchant, index)
    );
    this.csvBuilder.addRow(map(formattedRows, (row) => row.join(',')));
  }

  exportToCsv(merchants: Merchant[]) {
    if (isEmpty(merchants)) return false;

    this.buildHeaders();
    this.buildRows(merchants);

    const csvData = this.csvBuilder.build();
    downloadCsvFile({
      data: csvData,
      fileName: 'Merchants.csv',
    });
    return true;
  }
}
