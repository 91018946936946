export enum SUPPLIER_KYC_STATUS {
  DRAFT = 'Draft',
  PREVIEW = 'Preview',
}

export enum SUPPLIER_KYC_RESULT {
  APPROVED = 'Approved',
  DISAPPROVED = 'Disapproved',
}

export interface SupplierKycData {
  url: string;
  riskLevel: string;
  comments: string;
  isChecked: boolean;
}

export interface SupplierKyc {
  data: SupplierKycData;
  status: SUPPLIER_KYC_STATUS;
  screeningResult: SUPPLIER_KYC_RESULT;
  screeningSubmitterId: string;
  screeningResultDate: string;
}
