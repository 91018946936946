import { gql, TypedDocumentNode } from '@apollo/client';

type GetMerchantSuppliersErpInvoicesCountQueryType = {
  unified_supplier_invoice_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export const getMerchantSuppliersErpInvoicesCountQuery = () => {
  return gql`
    query getMerchantSuppliersErpInvoicesQuery {
      unified_supplier_invoice_aggregate {
        aggregate {
          count
        }
      }
    }
  ` as TypedDocumentNode<GetMerchantSuppliersErpInvoicesCountQueryType, {}>;
};
