import { MerchantWithLink, OrderStatusChip } from '@/components';
import { ROUTES } from '@/constants';
import { useGetMerchantDetailsByOrderId } from '@/hooks';
import { useBrowsingHistory } from '@/hooks/useBrowsingHistory';
import Order from '@/pages/orderReview/models/Order';
import { Button, Stack, Typography } from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import ResponsiveButton from '@treyd-io/treyd-ui/components/ResponsiveButton';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';
import ShipmentCheckIcon from '@treyd-io/treyd-ui/icons/ShipmentCheckIcon';
import { toString } from 'lodash';
import { Link } from 'react-router-dom';

export const OrderHeader = ({ id, order }: { id: string; order: Order }) => {
  const { goBack } = useBrowsingHistory();
  const { data, loading } = useGetMerchantDetailsByOrderId(order?.id);

  const merchantName = data?.orders_companies[0]?.name;
  const merchantSegment = data?.orders_companies[0]?.segment;
  const merchantId = order?.importer_id;

  if (loading) return null;

  return (
    <Row>
      <Col xs>
        <Stack gap={1}>
          <Button
            variant="text"
            color="info"
            startIcon={<ArrowLeftIcon />}
            sx={{ alignSelf: 'flex-start' }}
            onClick={goBack}>
            Back
          </Button>
          <Stack gap={1}>
            <Stack
              direction={{ xs: 'column-reverse', sm: 'row' }}
              gap={1}
              alignItems={{ sm: 'center' }}>
              <Typography variant={'h4'}>Order #{id}</Typography>
              <OrderStatusChip
                status={order.mapped_status}
                sx={{
                  alignSelf: { xs: 'flex-start', sm: 'center' },
                }}
              />
            </Stack>
            <Stack direction="row" gap={0.5}>
              <Typography variant="body1">Merchant: </Typography>
              {merchantName && merchantSegment && (
                <MerchantWithLink
                  id={toString(merchantId)}
                  name={merchantName}
                  segment={merchantSegment}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Col>
      <Col xs="auto">
        <ResponsiveButton
          size="large"
          variant="outlined"
          color="secondary"
          buttonProps={{
            startIcon: <ShipmentCheckIcon />,
            children: 'Order review',
            component: (props) => (
              <Link {...props} to={`${ROUTES.orderReview}/${order?.id}`} />
            ),
          }}
          iconButtonProps={{
            children: <ShipmentCheckIcon />,
            component: (props) => (
              <Link {...props} to={`${ROUTES.orderReview}/${order?.id}`} />
            ),
          }}
        />
      </Col>
    </Row>
  );
};
