import { Box, Typography, styled } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import TwoCheckmarksIcon from '@treyd-io/treyd-ui/icons/TwoCheckmarksIcon';

import { ReactElement } from 'react';

const UpdateContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  gap: theme.spacing(1.5),
  background: theme.palette.grey[100],
  borderRadius: theme.spacing(1),
}));

const UpdateHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
interface GeneralUpdatesCardProps {
  title: string;
  icon?: ReactElement;
  date: Date;
}

const GeneralUpdatesCard = (props: GeneralUpdatesCardProps) => (
  <>
    <UpdateContainer>
      <UpdateHeader>
        {props.icon ? props.icon : <TwoCheckmarksIcon />}
        <Typography sx={{ wordBreak: 'break-all' }} variant="subtitle2">
          {props.title}
        </Typography>
      </UpdateHeader>
      <Typography color="grey.700" variant="caption">
        {formatDate(props.date, 'daytime')}
      </Typography>
    </UpdateContainer>
  </>
);

export default GeneralUpdatesCard;
