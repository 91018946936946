import { ORDER_ROUTES, ROUTES } from '@/constants';
import { RouteObject } from 'react-router-dom';
import NonDraftOrderView from './OrderDetails/NonDraftOrderView';
import { OrdersPage } from './OrdersPage';

const ORDERS: RouteObject = {
  path: `${ROUTES.orders}`,
  children: [
    {
      index: true,
      element: <OrdersPage />,
    },
    {
      path: `${ORDER_ROUTES[':id']}`,
      element: <NonDraftOrderView />,
    },
  ],
};

export default ORDERS;
