// External Imports
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { includes, toNumber, toString, toUpper } from 'lodash';
import { useParams } from 'react-router-dom';

import { KYC_STATUS, MERCHANT_SEGMENT } from '@/constants';
import { WalletAccountAccountType } from '@/types';

import { MerchantStatusChip } from '@/components';
import NavTabsBar from '@/components/NavTabsBar';
import { GeneralInfo } from '@/pages/merchants/MerchantsDetails/Tabs/GeneralInfo';
import { Orders } from '@/pages/merchants/MerchantsDetails/Tabs/Orders';
import { Suppliers } from '@/pages/merchants/MerchantsDetails/Tabs/Suppliers';
import Users from '@/pages/merchants/MerchantsDetails/Tabs/Users';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';
import HeartIcon from '@treyd-io/treyd-ui/icons/HeartIcon';
import { ActionsMenu } from './ActionsMenu';
import { OnboardingProgressDialog } from './OnboardingProgressDialog';
import { BankAccountsTab } from './Tabs/BankAccounts';
import ComplianceTab from './Tabs/Compliance';
import { Credit } from './Tabs/Credit';
import { CreditsAndVouchers } from './Tabs/CreditsAndVouchers';
import { Documents } from './Tabs/Documents';
import { Emails } from './Tabs/Emails';
import { CustomerErpInvoices } from './Tabs/ErpInvoices/CustomerErpInvoices';
import { SupplierErpInvoices } from './Tabs/ErpInvoices/SupplierErpInvoices';
import { Invoices } from './Tabs/Invoices';
import Kyc from './Tabs/Kyc';
import Poa from './Tabs/Poa';
import { Pricing } from './Tabs/Pricing';
import { Transactions } from './Tabs/Transactions';

import {
  useGetCompanyStatuses,
  useGetComplianceDecision,
  useMerchant,
  useOnboardingStatuses,
} from '@/hooks';
import { useBrowsingHistory } from '@/hooks/useBrowsingHistory';
import { useGetWalletEntities } from '@/hooks/useGetWalletEntities';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';

import { getComplianceStatus } from '@/pages/merchants/MerchantsDetails/utils';
import { getMerchantRiskAmlQuery, getUboInvitations } from '@/schemas';

const MerchantsDetails = () => {
  const { id } = useParams();
  const { goBack } = useBrowsingHistory();
  const showNotificationMessage = useToastNotification();
  const {
    states: {
      name,
      loading,
      orgNo,
      segment,
      country,
      financier,
      joined,
      description,
      address,
      website,
      incorporated_at,
      company_users,
      kyc,
      erp_system,
      hubspotId,
      creditDeactivatedReason,
      salesRepresentativeId,
      limit,
      mainContactId,
      invoicingCurrency,
      isMerchantDeleted,
      status,
      statusLabel,
      macroSector,
      microSector,
    },
    actions: { refetchMerchantDetails, updateMerchantSegment },
  } = useMerchant(id as any);

  const { data: walletEntities, isLoading: loadingWalletEntities } =
    useGetWalletEntities({
      country: toString(toUpper(country)),
      orgNumber: toString(orgNo),
    });
  const companyWalletAccounts = walletEntities?.accounts;
  const hasPayoutWallet = companyWalletAccounts?.some(
    (account) => account.account_type === WalletAccountAccountType.CREDIT
  );
  const hasPrimaryWallet = companyWalletAccounts?.some(
    (account) => account.account_type === WalletAccountAccountType.CASH
  );

  const handleSegmentSelect = (segment: MERCHANT_SEGMENT) => {
    updateMerchantSegment(
      {
        companyId: Number(id),
        segment,
      },
      {
        onSuccess: () => {
          refetchMerchantDetails();
        },
        onError: (error: any) => {
          showNotificationMessage({
            title: error?.response?.data?.detail,
            type: 'error',
          });
        },
      }
    );
  };

  const { onboardingStatuses } = useOnboardingStatuses(id as string);
  const {
    data: complianceDecisionData,
    loading: loadingComplianceDecisionData,
  } = useGetComplianceDecision(toString(orgNo), country);

  const { data: riskAmlData, loading: loadingRiskAmlData } = useQuery(
    getMerchantRiskAmlQuery,
    {
      variables: { orgnr: orgNo, country },
    }
  );
  const { data: companyStatuses, isPending: isLoadingCompanyStatuses } =
    useGetCompanyStatuses(Number(id));

  const { data: uboInvitationsData, loading: loadingUboInvitationsData } =
    useQuery(getUboInvitations, { variables: { company_id: id } });
  const uboInvitations = uboInvitationsData?.lager_invitations;

  const complianceStatus = getComplianceStatus(
    complianceDecisionData?.lager_risk_compliancedecision?.[0]
  );

  const isKycSubmitted = includes(
    [KYC_STATUS.PREVIEW, KYC_STATUS.EDITABLE],
    kyc?.status
  );

  const segmentData: Record<
    MERCHANT_SEGMENT,
    { title: string; fillColor: string; color: string }
  > = {
    [MERCHANT_SEGMENT.NORMAL]: {
      title: 'Mark White Glove',
      fillColor: 'transparent',
      color: 'text.primary',
    },
    [MERCHANT_SEGMENT.WHITE_GLOVE]: {
      title: 'Unmark White Glove',
      fillColor: 'info.500',
      color: 'info.500',
    },
  };

  if (
    loading ||
    loadingComplianceDecisionData ||
    loadingUboInvitationsData ||
    isLoadingCompanyStatuses ||
    loadingRiskAmlData ||
    loadingWalletEntities
  ) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <CircularProgress size={60} />
      </Box>
    );
  }

  const tabs = [
    {
      id: 'general_info',
      label: 'General info',
      content: (
        <GeneralInfo
          merchant={{
            id,
            orgNo,
            incorporated_at,
            website: website || '',
            country,
            joined,
            financier: financier || '',
            description: description || '',
            address,
            company_users,
            kyc,
            erp_system,
            creditDeactivatedReason,
            macroSector,
            microSector,
            hasPayoutWallet,
            hasPrimaryWallet,
          }}
          complianceStatus={complianceStatus}
          riskAmlLevel={toString(
            riskAmlData?.lager_risk_aml?.[0]?.aml_assesment
          )}
          companyStatuses={companyStatuses}
        />
      ),
    },
    {
      id: 'kyc',
      label: 'KYC',
      content: (
        <Kyc
          companyId={toNumber(id)}
          orgNo={toString(orgNo)}
          country={toString(country)}
          companyLimit={limit}
          companyUsers={company_users}
        />
      ),
    },
    {
      id: 'users',
      label: 'Users',
      content: (
        <Users
          company_id={id}
          isKycSubmitted={isKycSubmitted}
          mainContactId={mainContactId}
          refetchMerchantDetails={refetchMerchantDetails}
        />
      ),
    },
    {
      id: 'emails',
      label: 'Emails',
      content: <Emails />,
    },
    {
      id: 'credit',
      label: 'Credit',
      content: (
        <Credit
          companyId={Number(id)}
          orgNumber={orgNo}
          country={country}
          companyStatuses={companyStatuses}
        />
      ),
    },
    {
      id: 'pricing',
      label: 'Pricing',
      content: <Pricing companyId={Number(id)} />,
    },
    {
      id: 'compliance',
      label: 'Compliance',
      content: (
        <ComplianceTab orgNo={toString(orgNo)} country={toString(country)} />
      ),
    },
    {
      id: 'poa',
      label: 'POA',
      content: <Poa companyId={Number(id)} />,
    },
    {
      id: 'documents',
      label: 'Documents',
      content: (
        <Documents
          companyId={toNumber(id)}
          country={toString(country)}
          companyUsers={company_users}
          orgNo={toString(orgNo)}
        />
      ),
    },
    {
      id: 'orders',
      label: 'Orders',
      content: <Orders />,
    },
    {
      id: 'supplier_erp_invoices',
      label: 'Suppliers ERP Invoices',
      content: <SupplierErpInvoices />,
    },
    {
      id: 'customer_erp_invoices',
      label: 'Customer ERP Invoices',
      content: <CustomerErpInvoices />,
    },
    {
      id: 'invoices',
      label: 'Invoices',
      content: <Invoices />,
    },
    {
      id: 'transactions',
      label: 'Transactions',
      content: <Transactions />,
    },
    {
      id: 'suppliers',
      label: 'Suppliers',
      content: <Suppliers />,
    },
    {
      id: 'credits-and-vouchers',
      label: 'Credits and vouchers',
      content: (
        <CreditsAndVouchers
          companyId={toNumber(id)}
          invoicingCurrency={toString(invoicingCurrency)}
        />
      ),
    },
    {
      id: 'bank-accounts',
      label: 'Bank accounts',
      content: <BankAccountsTab companyId={toNumber(id)} />,
    },
  ];

  return (
    <Stack minHeight="100%" gap={3} flexGrow={1}>
      <Row>
        <Col xs>
          <Stack gap={1}>
            <Button
              onClick={goBack}
              color="info"
              variant="text"
              sx={{ alignSelf: 'flex-start' }}
              startIcon={<ArrowLeftIcon />}>
              Back
            </Button>
            <Stack direction="row" alignItems="center">
              {segment && (
                <Tooltip title={segmentData[segment]?.title}>
                  <IconButton
                    onClick={() =>
                      handleSegmentSelect(
                        segment.toLowerCase() === 'normal'
                          ? MERCHANT_SEGMENT.WHITE_GLOVE
                          : MERCHANT_SEGMENT.NORMAL
                      )
                    }>
                    <HeartIcon
                      style={{
                        fill: segmentData[segment]?.fillColor,
                      }}
                      sx={{
                        color: segmentData[segment]?.color,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Typography
                variant="h4"
                color="text.primary"
                marginRight={2}
                noWrap>
                {name}
              </Typography>
              {status && (
                <MerchantStatusChip status={status} label={statusLabel} />
              )}
            </Stack>
          </Stack>
        </Col>
        <Col xs={12} sm="auto">
          <Stack direction="row" gap={1} width="100%">
            <OnboardingProgressDialog
              merchant={{
                company_users,
                joined,
                kyc,
                erp_system,
              }}
              onboardingStatuses={onboardingStatuses}
              uboInvitations={uboInvitations}
            />
            <ActionsMenu
              hubspotId={hubspotId}
              salesRepresentativeId={salesRepresentativeId}
              onRefetchMerchantDetails={refetchMerchantDetails}
            />
          </Stack>
        </Col>
      </Row>
      <Stack position="relative" height="100%" flexGrow={1}>
        {isMerchantDeleted && (
          <Box
            sx={{
              backgroundColor: 'background.default',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 2,
              opacity: 0.77,
            }}
          />
        )}
        <NavTabsBar tabs={tabs} />
      </Stack>
    </Stack>
  );
};

export default MerchantsDetails;
