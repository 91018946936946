import { ThemeOptions } from '@mui/material';
import mainThemeOptions from './mainOptions.theme';

declare module '@mui/material/styles' {
  interface PaletteColor {
    '160p'?: string;
    '190p'?: string;
    '30p'?: string;
  }

  interface SimplePaletteColorOptions {
    '160p'?: string;
    '190p'?: string;
    '30p'?: string;
  }
}

const lightThemeOptions: ThemeOptions = {
  ...mainThemeOptions,
  palette: {
    mode: 'light',
    primary: {
      main: 'hsla(240, 3%, 12%, 1)',
      dark: 'hsla(210, 20%, 2%, 1)',
      light: 'hsla(240, 5%, 24%, 1)',
      contrastText: 'hsla(0, 0%, 100%, 1)',
    },
    background: {
      paper: 'hsla(0, 0%, 100%, 1)',
      default: 'hsla(234, 12%, 98%, 1)',
    },
    action: {
      active: 'hsla(0, 0%, 0%, 0.54)',
      hover: 'hsla(0, 0%, 0%, 0.04)',
      selected: 'hsla(0, 0%, 0%, 0.08)',
      disabled: 'hsla(0, 0%, 0%, 0.26)',
      disabledBackground: 'hsla(0, 0%, 0%, 0.12)',
      focus: 'hsla(0, 0%, 0%, 0.12)',
    },
    info: {
      100: 'hsla(257, 64%, 88%, 1)',
      200: 'hsla(257, 72%, 82%, 1)',
      300: 'hsla(257, 76%, 76%, 1)',
      400: 'hsla(257, 78%, 71%, 1)',
      50: 'hsla(257, 58%, 94%, 1)',
      500: 'hsla(257, 80%, 64%, 1)',
      600: 'hsla(257, 81%, 61%, 1)',
      700: 'hsla(257, 59%, 54%, 1)',
      800: 'hsla(257, 51%, 42%, 1)',
      900: 'hsla(257, 52%, 30%, 1)',
      main: 'hsla(257, 81%, 61%, 1)',
      light: 'hsla(257, 76%, 76%, 1)',
      dark: 'hsla(257, 51%, 42%, 1)',
      '160p': 'hsla(0, 0%, 12%, 1)',
      '190p': 'hsla(259, 80%, 96%, 1)',
      '30p': 'hsla(257, 81%, 61%, 0.3)',
      contrastText: 'hsla(0, 0%, 100%, 1)',
    },
    error: {
      100: 'hsla(360, 78%, 91%, 1)',
      200: 'hsla(360, 78%, 81%, 1)',
      300: 'hsla(360, 78%, 71%, 1)',
      400: 'hsla(360, 78%, 61%, 1)',
      50: 'hsla(0, 78%, 96%, 1)',
      500: 'hsla(0, 78%, 52%, 1)',
      600: 'hsla(360, 78%, 44%, 1)',
      700: 'hsla(360, 78%, 37%, 1)',
      800: 'hsla(360, 78%, 32%, 1)',
      900: 'hsla(360, 78%, 24%, 1)',
      main: 'hsla(360, 78%, 44%, 1)',
      light: 'hsla(360, 78%, 71%, 1)',
      dark: 'hsla(360, 78%, 32%, 1)',
      '160p': 'hsla(0, 78%, 18%, 1)',
      '190p': 'hsla(0, 59%, 94%, 1)',
      '30p': 'hsla(0, 78%, 44%, 0.3)',
      contrastText: 'hsla(0, 0%, 100%, 1)',
    },
    warning: {
      100: 'hsla(29, 100%, 85%, 1)',
      200: 'hsla(29, 100%, 75%, 1)',
      300: 'hsla(29, 100%, 65%, 1)',
      400: 'hsla(28, 100%, 57%, 1)',
      50: 'hsla(29, 99%, 95%, 1)',
      500: 'hsla(28, 100%, 50%, 1)',
      600: 'hsla(27, 100%, 47%, 1)',
      700: 'hsla(26, 100%, 45%, 1)',
      800: 'hsla(24, 100%, 45%, 1)',
      900: 'hsla(22, 100%, 36%, 1)',
      main: 'hsla(27, 100%, 47%, 1)',
      light: 'hsla(29, 100%, 65%, 1)',
      dark: 'hsla(24, 100%, 45%, 1)',
      '160p': 'hsla(27, 100%, 19%, 1)',
      '190p': 'hsla(28, 86%, 95%, 1)',
      contrastText: 'hsla(0, 0%, 100%, 1)',
    },
    success: {
      100: 'hsla(145, 87%, 80%, 1)',
      200: 'hsla(145, 82%, 69%, 1)',
      300: 'hsla(145, 74%, 50%, 1)',
      400: 'hsla(145, 100%, 39%, 1)',
      50: 'hsla(145, 93%, 90%, 1)',
      500: 'hsla(145, 100%, 34%, 1)',
      600: 'hsla(145, 100%, 32%, 1)',
      700: 'hsla(145, 100%, 26%, 1)',
      800: 'hsla(145, 100%, 18%, 1)',
      900: 'hsla(145, 100%, 13%, 1)',
      contrastText: 'hsla(0, 0%, 100%, 1)',
      main: 'hsla(145, 100%, 32%, 1)',
      light: 'hsla(145, 74%, 50%, 1)',
      '160p': 'hsla(145, 100%, 13%, 1)',
      '190p': 'hsla(145, 49%, 93%, 1)',
      dark: 'hsla(145, 100%, 18%, 1)',
    },
    secondary: {
      main: 'hsla(270, 3%, 88%, 1)',
      dark: 'hsla(260, 2%, 74%, 1)',
      light: 'hsla(0, 0%, 93%, 1)',
      contrastText: 'hsla(210, 20%, 2%, 0.87)',
    },
    grey: {
      100: 'hsla(234, 9%, 95%, 1)',
      200: 'hsla(240, 7%, 93%, 1)',
      300: 'hsla(240, 6%, 87%, 1)',
      400: 'hsla(240, 5%, 79%, 1)',
      50: 'hsla(234, 12%, 98%, 1)',
      500: 'hsla(240, 4%, 70%, 1)',
      600: 'hsla(240, 4%, 54%, 1)',
      700: 'hsla(240, 4%, 41%, 1)',
      800: 'hsla(240, 5%, 24%, 1)',
      900: 'hsla(234, 3%, 12%, 1)',
    },
    text: {
      primary: 'hsla(210, 20%, 4%, 0.87)',
      secondary: 'hsla(210, 20%, 4%, 0.6)',
      disabled: 'hsla(210, 20%, 4%, 0.38)',
    },
    divider: 'hsla(210, 20%, 2%, 0.12)',
    common: {
      black: 'hsla(0, 0%, 0%, 1)',
      white: 'hsla(0, 0%, 100%, 1)',
    },
    other: {
      divider: 'hsla(210, 20%, 2%, 0.12)',
      outlined_border: 'hsla(210, 20%, 2%, 0.12)',
      standard_input_line: 'hsla(210, 20%, 2%, 0.42)',
      backdrop_overlay: 'hsla(0, 0%, 0%, 0.3)',
      rating_active: 'hsla(42, 100%, 50%, 1)',
      snackbar: 'hsla(0, 0%, 20%, 1)',
    },
  },
};

export default lightThemeOptions;
