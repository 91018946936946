import { TimelineItem as MuiTimelineItem, TimelineDot } from '@mui/lab';
import { ReactNode } from 'react';

export const TimelineItem = ({
  children,
  alert,
}: {
  children: ReactNode;
  alert?: boolean;
}) => (
  <MuiTimelineItem
    sx={{
      flexDirection: 'row',
      alignItems: 'baseline',
      justifyContent: 'start',
      gap: 2,
      minHeight: 'fit-content',
      marginInlineEnd: 3,
    }}>
    {!alert && <TimelineDot variant="outlined" color="secondary" />}
    {children}
  </MuiTimelineItem>
);
