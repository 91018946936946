import { InvoiceTransaction } from '@/schemas';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';
import { isEmpty, map, startCase, toString } from 'lodash';
import { MissingTransactionsAlert } from './MissingTransactionsAlert';

interface TransactionsDialogProps {
  open: boolean;
  onClose: () => void;
  transactions?: InvoiceTransaction[];
  invoiceNumber: string;
  isOldInvoice?: boolean;
}
export const TransactionsDialog = (props: TransactionsDialogProps) => {
  const { onClose, open, transactions, invoiceNumber, isOldInvoice } = props;

  return (
    open && (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" color="text.primary" noWrap>
              Transactions for invoice #{invoiceNumber}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingBlockEnd: 3,
          }}>
          <Stack gap={2}>
            {isOldInvoice ? (
              <MissingTransactionsAlert />
            ) : (
              <TableContainer
                sx={{
                  border: 1,
                  borderRadius: 2,
                  borderColor: 'divider',
                }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2" color="text.secondary">
                          Payment date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="text.secondary">
                          Amount
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="text.secondary">
                          Type
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isEmpty(transactions) ? (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography textAlign="center" color="text.secondary">
                            No transactions found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      map(transactions, (row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <TableCell component="th" scope="row">
                            {row.payment_date &&
                              formatDate(new Date(row.payment_date), 'date')}
                          </TableCell>
                          <TableCell
                            sx={{ fontVariantNumeric: 'tabular-nums' }}>
                            {formatCurrency(
                              row.amount,
                              toString(row.amount_currency)
                            )}
                          </TableCell>
                          <TableCell>{startCase(row.type)}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    )
  );
};
