import { MAPPED_ORDER_STATUS, MERCHANT_SEGMENT } from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Companies,
  Orders_Financiers,
  Orders_Order_Totals_In_Invoicing_Currency,
  Orders_Orders,
  Orders_Orders_Aggregate,
} from '__generated__/graphql';

type OrderFinancier = Pick<Orders_Financiers, 'name'>;
type OrderImporter = Pick<
  Orders_Companies,
  'id' | 'name' | 'country' | 'is_active' | 'org_no'
> & {
  segment: MERCHANT_SEGMENT;
};

export type Order = Pick<
  Orders_Orders,
  'id' | 'created' | 'active_payout' | 'financier' | 'invoicing_currency'
> & {
  financier?: OrderFinancier;
  importer?: OrderImporter;
  mapped_status: MAPPED_ORDER_STATUS;
  orders_totals_in_invoicing_currency: Pick<
    Orders_Order_Totals_In_Invoicing_Currency,
    'total_amount_inc_vat_in_invoicing_currency'
  >;
};

type GetOrdersQueryType = {
  orders_orders: Order[];
  orders_orders_aggregate: Pick<Orders_Orders_Aggregate, 'aggregate'>;
};

export const getOrdersQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query OrdersQuery {
		orders_orders ${queryParams} {
			id
			created
			mapped_status
			active_payout {
				amount
				currency
				expected_payment_date
				expected_settlement_date_calc
				actual_payment_date
			}
			orders_totals_in_invoicing_currency {
				total_amount_inc_vat_in_invoicing_currency
			}
			invoicing_currency
			importer {
				id
				name
				country
				is_active
				org_no
				segment
			}
			financier {
				id
				name
			}
		}

		orders_orders_aggregate ${whereClause ? `(where: ${whereClause})` : ''} {
			aggregate {
				count
			}
		}
	}
  ` as TypedDocumentNode<GetOrdersQueryType, {}>;
};
