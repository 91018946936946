import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { Header } from '@/components';
import { FormButton } from '@/components/styled-components/FormStyles';

import {
  DetailsForm,
  GeneralFormikCheckBox,
  GeneralFormikInput,
} from '@/pages/dev/components';
import { getUserDetails, updateUserProfileMutation } from '@/schemas';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';

export interface FormValues {
  can_sign_alone?: boolean | null;
  is_ubo?: boolean | null;
  member_id?: number;
}

export const UserDetails = () => {
  const navigate = useNavigate();
  const { company_id, user_id } = useParams();
  const { data: UserData, loading } = useQuery(getUserDetails, {
    variables: { user_id: Number(user_id), company_id: Number(company_id) },
  });
  const user = UserData?.lager_treyd_users?.[0];

  const [updateUserProfile] = useMutation(updateUserProfileMutation);
  const showNotificationMessage = useToastNotification();

  if (loading) {
    return (
      <div className="d-flex justify-content-center mb-3">
        <CircularProgress size={20} />
      </div>
    );
  }

  const initialValues: FormValues = {
    can_sign_alone: user?.can_sign_alone,
    is_ubo: user?.is_ubo,
    member_id: user?.member_id,
  };

  const goBack = () => {
    navigate('/dev#linking', { state: { company_id } });
  };

  return (
    <>
      <Header>
        <Button onClick={goBack} startIcon={<ArrowLeftIcon />}>
          Go back
        </Button>
      </Header>
      <Typography variant="h4">{user?.name || 'User Details'}</Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          updateUserProfile({
            variables: {
              company_user_id: user?.company_user_id,
              userData: { ...values },
            },
          }).then((res) => {
            if (res.errors) {
              showNotificationMessage({
                title: 'Something went wrong',
                type: 'error',
              });
            } else {
              showNotificationMessage({
                title: 'Changes saved successfully',
                type: 'success',
              });
            }
          });
          setSubmitting(false);
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DetailsForm>
            <GeneralFormikCheckBox name="can_sign_alone">
              Can sign alone
            </GeneralFormikCheckBox>
            <GeneralFormikCheckBox name="is_ubo">Is UBO</GeneralFormikCheckBox>
            <GeneralFormikInput
              label="Member ID"
              name="member_id"
              type="number"
              placeholder="Member ID"
            />
            <FormButton variant="outlined" type="submit">
              Save Changes
            </FormButton>
          </DetailsForm>
        </Box>
      </Formik>
    </>
  );
};
