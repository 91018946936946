import { SearchField } from '@/components';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { TableFilters } from '@/features/ErpInvoices/TableFilters';
import useSearchTerm from '@/hooks/useSearchTerm';
import { ErpInvoices } from '@/types';
import { Stack } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QueryFilterField,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { useState } from 'react';
import { SuppliersInvoicesTable } from './SuppliersInvoicesTable';

export const SupplierErpInvoices = () => {
  const { searchTerm, setSearchTerm } = useSearchTerm('erpInvoicesSearchTerm');
  const [filterFields, setFilterFields] = useState<
    QueryFilterField<ErpInvoices.InvoicesTableFiltersFields>[]
  >([]);

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'invoice_reference',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'supplier_name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
  ];

  const filters: Filter[] = [...searchFields, ...filterFields];

  return (
    <Stack gap={3} flexGrow={1}>
      <Stack gap={3} height="100%" flexGrow={1}>
        <Row>
          <Col xs={12} sm>
            <SearchField
              placeholder={'Search by invoice reference, or supplier name'}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Col>
          <Col xs={12} sm="auto">
            <TableFilters
              onSubmit={setFilterFields}
              onReset={() => setFilterFields([])}
              filters={filterFields}
            />
          </Col>
        </Row>
        <SuppliersInvoicesTable filters={filters} />
      </Stack>
    </Stack>
  );
};
