import {
  COMMERCIAL_STATUS,
  COMMERCIAL_STATUS_DISPLAYED_VALUE,
  CompanyIsActiveLabel,
  ONBOARDING_STATUS,
} from '@/constants';
import { includes, toString } from 'lodash';

export namespace MerchantService {
  export const isMerchantDeleted = (orgNumber?: string) =>
    includes(toString(orgNumber).toLocaleLowerCase(), 'deleted');

  export const getMerchantLimitStatusLabel = (
    isActive?: boolean | null,
    orgNumber?: string
  ) => {
    if (isMerchantDeleted(orgNumber)) return CompanyIsActiveLabel.DELETED;
    if (Boolean(isActive)) return CompanyIsActiveLabel.ACTIVE;
    return CompanyIsActiveLabel.INACTIVE;
  };

  export const commercialStatusMapper: Record<
    COMMERCIAL_STATUS,
    { label: string; tooltipText: string }
  > = {
    [COMMERCIAL_STATUS.TREYDING_CUSTOMER]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.TREYDING_CUSTOMER],
      tooltipText:
        'Merchant has active limit and outstanding credit, limit or KYC renewals are not expiring within this month.',
    },
    [COMMERCIAL_STATUS.PROACTIVE_OPPORTUNITY]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.PROACTIVE_OPPORTUNITY
        ],
      tooltipText:
        'Merchant has opportunities for proactive engagement, such as high-limit utilization or positive limits outlooks.',
    },
    [COMMERCIAL_STATUS.CUSTOMER_AT_RISK]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.CUSTOMER_AT_RISK],
      tooltipText:
        'Merchant has negative utilization trends over a rolling 3-month period.',
    },
    [COMMERCIAL_STATUS.CHURNED_NO_UTILIZATION]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.CHURNED_NO_UTILIZATION
        ],
      tooltipText: 'Merchant has active limit but zero outstanding balances.',
    },
    [COMMERCIAL_STATUS.UP_FOR_KYC_RENEWAL]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.UP_FOR_KYC_RENEWAL],
      tooltipText: 'Merchant has KYC renewal due within one month.',
    },
    [COMMERCIAL_STATUS.UP_FOR_LIMIT_RENEWAL_NEED_DATA]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.UP_FOR_LIMIT_RENEWAL_NEED_DATA
        ],
      tooltipText:
        'Merchant has an upcoming limit renewal requiring additional financial data.',
    },
    [COMMERCIAL_STATUS.UP_FOR_RENEWAL_DATA_AVAILABLE]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.UP_FOR_RENEWAL_DATA_AVAILABLE
        ],
      tooltipText:
        'Merchant has upcoming limit renewal where updated financial data is already available.',
    },
    [COMMERCIAL_STATUS.CHURNED_LIMIT_EXPIRED]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.CHURNED_LIMIT_EXPIRED
        ],
      tooltipText: 'Merchant has expired limit',
    },
    [COMMERCIAL_STATUS.DEACTIVATED_RETAIN]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.DEACTIVATED_RETAIN],
      tooltipText:
        'Merchant has temporarily manual deactivation due to various credit reasons.',
    },
    [COMMERCIAL_STATUS.RESURRECTION_OPPORTUNITY]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[
          COMMERCIAL_STATUS.RESURRECTION_OPPORTUNITY
        ],
      tooltipText:
        'Merchant has opportunities to resurrect those temporarily deactivated for more than three months, including those with outdated financials.',
    },
    [COMMERCIAL_STATUS.DELETED]: {
      label: COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.DELETED],
      tooltipText: 'Merchant has requested to be deleted from the system.',
    },
    [COMMERCIAL_STATUS.REJECTED]: {
      label: COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.REJECTED],
      tooltipText:
        'Merchant is currently in a soft rejection status, allowing for follow-up or corrective action.',
    },
    [COMMERCIAL_STATUS.OVERDUE]: {
      label: COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.OVERDUE],
      tooltipText: 'Merchant has overdue invoices more than 30 days.',
    },
    [COMMERCIAL_STATUS.DEACTIVATED_AVOID]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.DEACTIVATED_AVOID],
      tooltipText:
        'Merchant has bankruptcy, liquidation, or compliance issues, requiring avoidance in future interactions.',
    },
    [COMMERCIAL_STATUS.COMPLIANCE_PENDING]: {
      label:
        COMMERCIAL_STATUS_DISPLAYED_VALUE[COMMERCIAL_STATUS.COMPLIANCE_PENDING],
      tooltipText:
        'Merchant does not have a compliance decision, orders will be blocked during order review.',
    },
  };
  export const onboardingStatusMapper = {
    [ONBOARDING_STATUS.UNDERWRITING]: {
      label: 'Underwriting',
      tooltipText:
        'Assessing Treyd-to-Prospect-fit, including sharing an indicative limit or credit decision.',
    },
    [ONBOARDING_STATUS.INDICATIVE_LIMIT_APPROVED]: {
      label: 'Indicative Limit Approved',
      tooltipText:
        'Completing the formal onboarding process and price negotiation to activate the limit and set up the account.',
    },
    [ONBOARDING_STATUS.DISCOVERY]: {
      label: 'Discovery',
      tooltipText:
        'Confirmed mutual interest, aiming to conduct a discovery meeting and gather financials.',
    },
    [ONBOARDING_STATUS.READY_TO_TREYD]: {
      label: 'Ready to Treyd',
      tooltipText:
        'Preparing to convert to a customer with an active limit and a signed first order.',
    },
    [ONBOARDING_STATUS.CLOSED_WON]: {
      label: 'Closed Won',
      tooltipText: '',
    },
    [ONBOARDING_STATUS.CLOSED_LOST]: {
      label: 'Closed Lost',
      tooltipText:
        'Deals that did not progress, with specific reasons recorded.',
    },
  };

  export const merchantCombinedStatusMapper = {
    ...commercialStatusMapper,
    ...onboardingStatusMapper,
  };
}
