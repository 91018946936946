import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Lager_Auth_User,
  Orders_Orders_App_Orderreview,
} from '../__generated__/graphql';

export type OrderOperatorReview = Pick<
  Orders_Orders_App_Orderreview,
  'id' | 'comment' | 'order_id' | 'created' | 'verdict'
> & {
  user: Pick<Lager_Auth_User, 'first_name' | 'last_name'>;
};

type GetOrdersQueryType = {
  orders_orders_app_orderreview: OrderOperatorReview[];
  orders_orders_app_orderreview_aggregate: {
    aggregate: { count: number };
  };
};

export const getOrdersOperatorsReviewsQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getOrdersOperatorsReviews {
    orders_orders_app_orderreview ${queryParams} {
    id
    comment
    reason
    verdict
    order_id
    created
    user {
      first_name
      last_name
    }
  }

  orders_orders_app_orderreview_aggregate ${
    whereClause ? `(where: ${whereClause})` : ''
  } {
			aggregate {
				count
			}
		}
	}
  ` as TypedDocumentNode<GetOrdersQueryType, {}>;
};
