import { ROUTES } from '@/constants';
import { RouteObject } from 'react-router-dom';
import { ErpInvoices } from './ErpInvoices';
export const ERP_INVOICES: RouteObject = {
  path: `${ROUTES.erpInvoices}`,
  children: [
    {
      index: true,
      element: <ErpInvoices />,
    },
  ],
};
