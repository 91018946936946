import { FilterList } from '@/types';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { format, isValid } from 'date-fns';
import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';

interface FilterFieldProps {
  filterType?: 'select' | 'date';
  filter: FilterList;
  onFilterChange: (field_name: string, filter_value: string[]) => void;
  filterMapper?: (val: string) => string | number;
  boxStyles?: any;
  chip?: boolean;
}

export default function FilterField({
  filterType,
  filter,
  onFilterChange,
  filterMapper,
  boxStyles,
  chip,
}: FilterFieldProps) {
  const [options, setOptions] = useState([]);
  const datePickerInputRef = useRef(null);
  const handleChange = (value: any, field: any) => {
    setOptions(value);
    onFilterChange(field, value?.length ? value : undefined);
  };
  const handleDelete = (e: React.MouseEvent, field: string, value: string) => {
    e.preventDefault();
    const newOptions: string[] = options.filter(
      (option: string) => option !== value
    );
    handleChange(newOptions, field);
  };

  if (filterType === 'date') {
    return (
      <Box sx={{ width: '20%', marginInlineEnd: '1rem', ...boxStyles }}>
        <FormControl sx={{ width: '100%' }}>
          <DesktopDatePicker
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            inputRef={datePickerInputRef.current}
            sx={{ width: '100%' }}
            label={filter.label || 'Date'}
            onChange={(date, context) => {
              handleChange(
                isValid(date) && isEmpty(context.validationError)
                  ? format(date as Date, 'yyyy-MM-dd')
                  : '',
                filter.field
              );
            }}
            slots={{
              textField: (props) => <TextField {...props} />,
            }}
          />
        </FormControl>
      </Box>
    );
  }
  return (
    <Box sx={{ width: '20%', marginInlineEnd: '1rem', ...boxStyles }}>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="test-select-label">
          {filter.label ?? filter.label}
        </InputLabel>
        <Select
          label={filter.label ?? filter.label}
          multiple
          renderValue={
            chip
              ? (selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      maxWidth: '220px',
                      overflowX: 'auto',
                      paddingInlineStart: '10px',
                    }}>
                    {selected?.map((value) => (
                      <Chip
                        size="small"
                        clickable
                        key={value}
                        label={filterMapper?.(value) || value}
                        onMouseDown={(e: any) => e.stopPropagation()}
                        onDelete={(e) => handleDelete(e, filter.field, value)}
                      />
                    ))}
                  </Box>
                )
              : undefined
          }
          value={options}
          onChange={(e) => handleChange(e.target.value, filter.field)}>
          {filter.options?.map((option: any) => (
            <MenuItem value={option} key={option}>
              {filterMapper?.(option) || option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
