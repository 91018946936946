import { gql, TypedDocumentNode } from '@apollo/client';

type DeleteSupplierAttachmentMutationType = {
  django: {
    delete_supplier_attachment: {
      deleted: boolean;
    };
  };
};

type DeleteSupplierAttachmentMutationVariablesType = {
  attachmentId: number;
};

export const deleteSupplierAttachmentMutation = gql`
  mutation deleteSupplierAttachmentMutation($attachmentId: Int!) {
    django {
      delete_supplier_attachment(id: $attachmentId) {
        deleted
      }
    }
  }
` as TypedDocumentNode<
  DeleteSupplierAttachmentMutationType,
  DeleteSupplierAttachmentMutationVariablesType
>;
