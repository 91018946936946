'use client';

import {
  PAYMENT_STATUS,
  PaymentEventType,
  PaymentStatus,
  PaymentType,
  TrackingEvents,
} from '@/schemas';
import { formatDate } from '@treyd-io/core/utils/date';
import { includes, last, map } from 'lodash';
import {
  flatten,
  flow,
  map as fpMap,
  keys,
  mapValues,
  sortBy,
  values,
} from 'lodash/fp';

interface TimelineStyles {
  dotColor: string;
  dotVariant: 'outlined' | 'filled';
  connectorColor: string;
}

interface PaymentDetailsObject {
  date: string;
  title: string;
  titleColor: string;
  subtitle: string;
  timeline: TimelineStyles;
  isLast: boolean;
  isTerminal: boolean;
  expected_date?: string;
  paymentRef?: string;
}

type EventDetailsMapper = Record<PaymentStatus, PaymentDetailsObject>;

interface PaymentEvent {
  details: PaymentDetailsObject;
  mt103?: string;
  tracking_details_events?: TrackingEvents[];
}

export class PaymentTrackerService {
  private payments: PaymentType[];
  private paymentEvents: PaymentEventType[];
  private terminalEvents: PaymentStatus[] = [
    PAYMENT_STATUS.FAILED_SWIFT,
    PAYMENT_STATUS.FAILED_TREYD,
    PAYMENT_STATUS.FUNDS_DELIVERED_ASSUMED,
    PAYMENT_STATUS.FUNDS_DELIVERED_GUARANTEED,
  ];
  constructor(payments: PaymentType[]) {
    this.payments = payments;
    this.paymentEvents = this.getOrderedEventsList();
  }

  private getOrderedEventsList() {
    return flow(
      fpMap((payment: PaymentType) => payment.internal_payment_events),
      flatten,
      sortBy([(event: PaymentEventType) => event.event_order])
    )(this.payments);
  }

  private getTrackingDetailsEvents(events: Record<string, TrackingEvents>) {
    return flow(
      keys,
      sortBy([(event: string) => new Date(event).getTime()]),
      mapValues((key: string) => events[key]),
      values
    )(events);
  }

  private isTerminal(event: PaymentEventType) {
    return includes(this.terminalEvents, event.event_type);
  }

  private isLast(event: PaymentEventType) {
    return last(this.paymentEvents)?.event_order === event.event_order;
  }

  private getTimelineStyles(event: PaymentEventType): TimelineStyles {
    return this.isLast(event) && !this.isTerminal(event)
      ? {
          dotColor: 'transparent',
          dotVariant: 'outlined',
          connectorColor: 'grey.400',
        }
      : {
          dotColor: 'info.main',
          dotVariant: 'filled',
          connectorColor: 'info.30p',
        };
  }

  private getPaymentEventDetailsMapper(event: PaymentEventType) {
    const detailsMapper: EventDetailsMapper = {
      not_started: {
        date: formatDate(new Date(event.event_date), 'longdate'), //TODO: change back when api is ready
        title: 'Payment scheduling',
        titleColor: 'primary.main',
        subtitle: 'The payment process is expected to initiate on',
        expected_date:
          event.event_additional_data?.expected_payment_date &&
          formatDate(
            new Date(event.event_additional_data?.expected_payment_date),
            'longdate'
          ),
        timeline: {
          dotColor: this.getTimelineStyles(event).dotColor,
          dotVariant: this.getTimelineStyles(event).dotVariant,
          connectorColor: this.getTimelineStyles(event).connectorColor,
        },
        isLast: this.isLast(event),
        isTerminal: this.isTerminal(event),
      },
      payment_instructed: {
        date: formatDate(new Date(event.event_date), 'longdate'),
        title: 'Payment instruction',
        titleColor: 'primary.main',
        subtitle: 'Treyd instructed the bank to start the transfer process.',
        timeline: {
          dotColor: this.getTimelineStyles(event).dotColor,
          dotVariant: this.getTimelineStyles(event).dotVariant,
          connectorColor: this.getTimelineStyles(event).connectorColor,
        },
        isLast: this.isLast(event),
        isTerminal: this.isTerminal(event),
      },
      compliance_approved: {
        date: formatDate(new Date(event.event_date), 'longdate'),
        title: 'Payment verification',
        titleColor: 'primary.main',
        subtitle: 'The bank verified payment compliance.',
        timeline: {
          dotColor: this.getTimelineStyles(event).dotColor,
          dotVariant: this.getTimelineStyles(event).dotVariant,
          connectorColor: this.getTimelineStyles(event).connectorColor,
        },
        isLast: this.isLast(event),
        isTerminal: this.isTerminal(event),
      },
      pending_compliance: {
        date: formatDate(new Date(event.event_date), 'longdate'),
        title: 'Payment verification',
        titleColor: 'primary.main',
        subtitle: 'The bank is verifying payment compliance.',
        timeline: {
          dotColor: this.getTimelineStyles(event).dotColor,
          dotVariant: this.getTimelineStyles(event).dotVariant,
          connectorColor: this.getTimelineStyles(event).connectorColor,
        },
        isLast: this.isLast(event),
        isTerminal: this.isTerminal(event),
      },
      funds_released: {
        date: formatDate(new Date(event.event_date), 'longdate'),
        title: 'Payment initiation',
        titleColor: 'primary.main',
        subtitle:
          'Money left the bank account, and it is expected to be delivered on',
        expected_date:
          event.event_additional_data?.expected_arrival_date &&
          formatDate(
            new Date(event.event_additional_data?.expected_arrival_date),
            'longdate'
          ),
        paymentRef: event.event_additional_data?.payment_ref,
        timeline: {
          dotColor: this.getTimelineStyles(event).dotColor,
          dotVariant: this.getTimelineStyles(event).dotVariant,
          connectorColor: this.getTimelineStyles(event).connectorColor,
        },
        isLast: this.isLast(event),
        isTerminal: this.isTerminal(event),
      },
      failed_swift: {
        date: formatDate(new Date(event.event_date), 'longdate'),
        title: 'Swift failure',
        titleColor: 'error.main',
        subtitle:
          'Payment failures can occur for various reasons. Our operators have been informed and are actively addressing the issue, aiming to retry the process promptly. We will provide you with updates as they work on resolving it.',
        timeline: {
          dotColor: 'error.main',
          dotVariant: 'filled',
          connectorColor: 'error.30p',
        },
        isLast: this.isLast(event),
        isTerminal: this.isTerminal(event),
      },
      failed_treyd: {
        date: formatDate(new Date(event.event_date), 'longdate'),
        title: 'Payment failure',
        titleColor: 'error.main',
        subtitle:
          'Payment failures can occur for various reasons. Our operators have been informed and are actively addressing the issue, aiming to retry the process promptly. We will provide you with updates as they work on resolving it.',
        timeline: {
          dotColor: 'error.main',
          dotVariant: 'filled',
          connectorColor: 'error.30p',
        },
        isLast: this.isLast(event),
        isTerminal: this.isTerminal(event),
      },
      funds_delivered_guaranteed: {
        date: formatDate(new Date(event.event_date), 'longdate'),
        title: 'Payment arrival',
        titleColor: 'primary.main',
        subtitle: 'The payment arrived to the beneficiary account.',
        timeline: {
          dotColor: this.getTimelineStyles(event).dotColor,
          dotVariant: this.getTimelineStyles(event).dotVariant,
          connectorColor: this.getTimelineStyles(event).connectorColor,
        },
        isLast: this.isLast(event),
        isTerminal: this.isTerminal(event),
      },
      funds_delivered_assumed: {
        date: formatDate(new Date(event.event_date), 'longdate'),
        title: 'Payment arrival',
        titleColor: 'primary.main',
        subtitle:
          'The payment should have arrived to the beneficiary account, unless we get any future failure information.',
        timeline: {
          dotColor: this.getTimelineStyles(event).dotColor,
          dotVariant: this.getTimelineStyles(event).dotVariant,
          connectorColor: this.getTimelineStyles(event).connectorColor,
        },
        isLast: this.isLast(event),
        isTerminal: this.isTerminal(event),
      },
    };
    return detailsMapper[event.event_type];
  }

  public getPaymentEvents(): PaymentEvent[] {
    return map(
      this.paymentEvents,
      (event): PaymentEvent => ({
        details: this.getPaymentEventDetailsMapper(event),
        mt103: event.event_additional_data?.mt103,
        tracking_details_events:
          event.event_additional_data?.tracking_info?.payment_events &&
          this.getTrackingDetailsEvents(
            event.event_additional_data?.tracking_info?.payment_events
          ),
      })
    );
  }
}
