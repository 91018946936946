import { SupplierCompliance } from '@/types';
import { formatDate } from '@treyd-io/core/utils/date';
import { openURL } from '@treyd-io/core/utils/openUrl';
import { last, split, toNumber } from 'lodash';
import { AttachmentCard } from '../AttachmentCard';

export const SupplierAttachment = ({
  attachment,
  onDelete,
}: {
  attachment: SupplierCompliance.Attachment;
  onDelete: (id: number) => void;
}) => {
  const name = last(split(attachment.name, '/'));
  const created = formatDate(new Date(attachment.created || ''), 'datetime');
  const handlePreviewClick = () => openURL(attachment.url, '_blank');

  return (
    <AttachmentCard
      title={name}
      description={created}
      onPreviewClick={handlePreviewClick}
      onRemoveClick={() => onDelete(toNumber(attachment.id))}
    />
  );
};
