import { ROUTES } from '@/constants';
import { Apps, User } from '@/types';
import AvatarSquareIcon from '@treyd-io/treyd-ui/icons/AvatarSquareIcon';
import BellRingingIcon from '@treyd-io/treyd-ui/icons/BellRingingIcon';
import BuildingsIcon from '@treyd-io/treyd-ui/icons/BuildingsIcon';
import FilesIcon from '@treyd-io/treyd-ui/icons/FilesIcon';
import GiftIcon from '@treyd-io/treyd-ui/icons/GiftIcon';
import PackageIcon from '@treyd-io/treyd-ui/icons/PackageIcon';
import ScrollIcon from '@treyd-io/treyd-ui/icons/ScrollIcon';
import SettingsIcon from '@treyd-io/treyd-ui/icons/SettingsIcon';
import ShipmentCheckIcon from '@treyd-io/treyd-ui/icons/ShipmentCheckIcon';
import SwapIcon from '@treyd-io/treyd-ui/icons/SwapIcon';
import TruckIcon from '@treyd-io/treyd-ui/icons/TruckIcon';
import WriteNoteIcon from '@treyd-io/treyd-ui/icons/WriteNoteIcon';
import { find, toString } from 'lodash';

interface AppNavBarMenuItem {
  id: Apps;
  title: string;
  icon: JSX.Element;
  to?: string;
}

interface Options {
  user?: User;
  selectedItemId?: string | null;
}

export const useAppNavBarMenuItems = (options?: Options) => {
  const appNavBarMenuItems = getAppNavBarMenuItems(options?.user);
  const selectedMenuItem = find(
    appNavBarMenuItems.appNavMenuItems,
    (item) => item.id === options?.selectedItemId
  );

  return {
    ...appNavBarMenuItems,
    selectedMenuItem,
  };
};

const getAppNavBarMenuItems = (user?: User) => {
  const firstName = toString(user?.first_name).trim();
  const lastName = toString(user?.last_name).trim();
  const userName = `${firstName} ${lastName}`.trim();

  const profileMenuItem: AppNavBarMenuItem = {
    id: 'profile',
    title: userName || 'Profile',
    icon: <AvatarSquareIcon color="primary" />,
    to: ROUTES.profile,
  };

  const blogMenuItem: AppNavBarMenuItem = {
    id: 'blog',
    title: 'Blog',
    icon: <WriteNoteIcon color="primary" />,
    to: ROUTES.blog,
  };

  const fixedMenuItems: AppNavBarMenuItem[] = [
    {
      id: 'orders-review',
      title: 'Orders review',
      icon: <ShipmentCheckIcon color="primary" />,
      to: ROUTES.orderReview,
    },
    {
      id: 'orders',
      title: 'Orders',
      icon: <PackageIcon color="primary" />,
      to: ROUTES.orders,
    },
    {
      id: 'invoices',
      title: 'Invoices',
      icon: <FilesIcon color="primary" />,
      to: ROUTES.invoices,
    },
    {
      id: 'erp-invoices',
      title: 'ERP Invoices',
      icon: <ScrollIcon color="primary" />,
      to: ROUTES.erpInvoices,
    },
    {
      id: 'transactions',
      title: 'Transactions',
      icon: <SwapIcon color="primary" />,
      to: ROUTES.transactions,
    },
    {
      id: 'merchants',
      title: 'Merchants',
      icon: <BuildingsIcon color="primary" />,
      to: ROUTES.merchants,
    },
    {
      id: 'suppliers',
      title: 'Suppliers',
      icon: <TruckIcon color="primary" />,
      to: ROUTES.suppliers,
    },
    {
      id: 'credits-and-vouchers',
      title: 'Credits & Vouchers',
      icon: <GiftIcon color="primary" />,
      to: ROUTES.creditsAndVouchers,
    },
    {
      id: 'dev',
      title: 'Dev',
      icon: <SettingsIcon color="primary" />,
      to: ROUTES.dev,
    },
    {
      id: 'notifications-and-emails',
      title: 'Notifications & Emails',
      icon: <BellRingingIcon color="primary" />,
      to: ROUTES.notificationsAndEmails,
    },
  ];

  const appNavMenuItems: AppNavBarMenuItem[] = [
    ...fixedMenuItems,
    blogMenuItem,
    profileMenuItem,
  ];

  return {
    profileMenuItem,
    blogMenuItem,
    fixedMenuItems,
    appNavMenuItems,
  };
};
