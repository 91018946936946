import { SuppliersScreeningResultLabel } from '@/constants';
import { Supplier } from '@/schemas/getSuppliersQuery';
import { CSVBuilder } from '@treyd-io/core/services/CsvService';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { downloadCsvFile } from '@treyd-io/core/utils/downloadCSV';
import { isEmpty, map, toString } from 'lodash';
export class SuppliersCsvService {
  private csvBuilder: CSVBuilder;

  constructor() {
    this.csvBuilder = new CSVBuilder();
  }

  private buildHeaders(): void {
    this.csvBuilder.setHeaders([
      ' ',
      'Name',
      'Legal name',
      'Merchant',
      'Country',
      'QI exempt',
      'All orders',
      'Paid orders',
      'Screening',
      'Creation date',
    ]);
  }

  private formatSupplierRow(
    supplier: Supplier,
    index: number
  ): (string | number | Date)[] {
    const screeningResult = supplier?.kyc?.screeningResult;
    const isApproved = screeningResult === 'Approved';

    return map(
      [
        index + 1,
        toString(supplier?.name),
        toString(supplier?.legal_name),
        toString(supplier?.company?.name),
        supplier?.country ? getCountryName(supplier?.country) : '',
        supplier?.exempted_from_inspections ? 'Yes' : 'No',
        supplier?.all_orders?.aggregate?.count || 0,
        supplier?.paid_orders?.aggregate?.count || 0,
        !screeningResult
          ? ''
          : isApproved
          ? SuppliersScreeningResultLabel.APPROVED
          : SuppliersScreeningResultLabel.REJECTED,
        supplier?.created_at
          ? formatDate(new Date(supplier?.created_at), 'date')
          : '',
      ],
      (value) => `"${value}"`
    );
  }

  private buildRows(suppliers: Supplier[]): void {
    const formattedRows = map(suppliers, (supplier, index) =>
      this.formatSupplierRow(supplier, index)
    );
    this.csvBuilder.addRow(map(formattedRows, (row) => row.join(',')));
  }

  exportToCsv(suppliers: Supplier[]) {
    if (isEmpty(suppliers)) return false;

    this.buildHeaders();
    this.buildRows(suppliers);

    const csvData = this.csvBuilder.build();
    downloadCsvFile({
      data: csvData,
      fileName: 'Suppliers.csv',
    });
    return true;
  }
}
