import { IndicativeLimitAlert } from '@/components';
import { CompanyStatusesResponse } from '@/types';
import { Stack } from '@mui/material';
import { toString } from 'lodash';
import { CreditDecisionHistorySection } from './CreditDecisionHistorySection';
import { CreditInfoSection } from './CreditInfoSection';
import { FinanciersSection } from './FinanciersSection';

interface CreditProps {
  companyId: number;
  orgNumber?: string;
  country?: string;
  companyStatuses?: CompanyStatusesResponse;
}

export const Credit = ({
  companyId,
  orgNumber,
  country,
  companyStatuses,
}: CreditProps) => {
  const isLimitIndicative = companyStatuses?.limit_status === 'INDICATIVE';
  return (
    <Stack gap={6.5}>
      {isLimitIndicative && <IndicativeLimitAlert />}

      <CreditInfoSection
        companyId={companyId}
        orgNumber={toString(orgNumber)}
        country={toString(country)}
      />
      <FinanciersSection companyId={companyId} />
      <CreditDecisionHistorySection
        orgNumber={toString(orgNumber)}
        country={toString(country)}
      />
    </Stack>
  );
};
