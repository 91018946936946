import {
  useGetCompanyCreditDecisions,
  useGetCompanyDesiredLimit,
  useGetCompanyStatuses,
  useGetRemainingLimit,
} from '@/hooks';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toNumber, toString } from 'lodash';
import { CreditDecisionSection } from './CreditDecisionSection';
import { WithCreditDecisionStatus } from './WithCreditDecisionStatus';
import { WithoutCrediDecisionStatus } from './WithoutCrediDecisionStatus';

interface CreditInfoSectionProps {
  companyId: number;
  orgNumber: string;
  country: string;
}

export const CreditInfoSection = ({
  companyId,
  country,
  orgNumber,
}: CreditInfoSectionProps) => {
  const {
    data: companyCreditDecisionsData,
    loading: isCompanyCreditDecisionsDataLoading,
  } = useGetCompanyCreditDecisions({
    orgNumber: toString(orgNumber),
    country: toString(country),
  });

  const { data: remainingLimitData, isPending: isRemainingLimitLoading } =
    useGetRemainingLimit(companyId);

  const { data: desiredLimitData, loading: isDesiredLimitDataLoading } =
    useGetCompanyDesiredLimit({
      companyId: companyId,
    });

  const { data: companyStatusesData, isPending: isCompanyStatusesDataLoading } =
    useGetCompanyStatuses(companyId);

  const latestPublishedCreditDecision =
    companyCreditDecisionsData?.lager_core_company?.[0]
      ?.latest_published_credit_decision;

  const companyLimitStatus = companyStatusesData?.limit_status;
  const isLimitActive = companyLimitStatus === 'ACTIVE';
  const isLimitInactive = companyLimitStatus === 'INACTIVE';
  const willLimitExpireSoon = companyLimitStatus === 'WILL_EXPIRE';
  const hasCreditDecision = companyLimitStatus !== 'NO_CREDIT_DECISION';
  const isLimitIndicative = companyLimitStatus === 'INDICATIVE';
  const isLatestPublishedCreditDecisionExpired =
    latestPublishedCreditDecision?.status === 'expired';

  const coreCompanyStatus =
    companyCreditDecisionsData?.lager_core_company?.[0]?.status;

  const coreCompanyAssignee =
    companyCreditDecisionsData?.lager_core_company?.[0]?.assignee;

  const totalCredit = remainingLimitData?.company_limit;
  const remainingCredit = remainingLimitData?.available_limit;
  const outstandingCredit = remainingLimitData?.outstanding_credit;
  const limitCurrency = remainingLimitData?.limit_currency;

  const desiredCredit = desiredLimitData?.orders_companies_by_pk?.desired_limit;
  const desiredCreditCurrency =
    desiredLimitData?.orders_companies_by_pk?.desired_limit_currency;

  const remainingLimitPercentage =
    totalCredit && totalCredit > 0 && remainingCredit && remainingCredit >= 0
      ? (remainingCredit / totalCredit) * 100
      : 0;

  const linearProgressColor = (() => {
    if (isLimitIndicative) return 'warning';
    if (isLimitInactive) return 'error';
    return 'info';
  })();

  const isLoading =
    isCompanyCreditDecisionsDataLoading ||
    isRemainingLimitLoading ||
    isDesiredLimitDataLoading ||
    isCompanyStatusesDataLoading;

  if (isLoading)
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );

  return (
    <Stack gap={5}>
      <Stack gap={2}>
        <Typography variant="h5" color="text.primary">
          Credit info
        </Typography>
        <LinearProgress
          variant={isLimitInactive ? 'buffer' : 'determinate'}
          value={remainingLimitPercentage}
          valueBuffer={100}
          color={linearProgressColor}
        />
        {hasCreditDecision || isLimitIndicative ? (
          <WithCreditDecisionStatus
            totalCredit={totalCredit}
            remainingCredit={remainingCredit}
            limitCurrency={limitCurrency}
            isLimitActive={isLimitActive}
            isLimitInactive={isLimitInactive}
            willLimitExpireSoon={willLimitExpireSoon}
            isLatestPublishedCreditDecisionExpired={
              isLatestPublishedCreditDecisionExpired
            }
            isLimitIndicative={isLimitIndicative}
          />
        ) : (
          <WithoutCrediDecisionStatus
            coreCompanyStatus={coreCompanyStatus}
            assignee={coreCompanyAssignee}
          />
        )}
      </Stack>
      {willLimitExpireSoon && (
        <Alert severity="warning" variant="standard">
          <AlertTitle>Upcoming limit renewal.</AlertTitle>
        </Alert>
      )}
      <CreditDecisionSection
        isPublishedCreditDecisionVisible={
          hasCreditDecision && !isLatestPublishedCreditDecisionExpired
        }
        companyCreditDecision={latestPublishedCreditDecision}
        desiredCredit={formatCurrency(
          toNumber(desiredCredit),
          toString(desiredCreditCurrency)
        )}
        remainingCredit={formatCurrency(
          toNumber(remainingCredit),
          toString(limitCurrency)
        )}
        outstandingCredit={formatCurrency(
          toNumber(outstandingCredit),
          toString(limitCurrency)
        )}
        companyId={companyId}
      />
    </Stack>
  );
};
