import { ROUTES } from '@/constants';
import { useExporter } from '@/hooks';
import Order from '@/pages/orderReview/models/Order';

import { getBankInfo } from '@/utils/bank';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import { getCountryName } from '@treyd-io/core/utils/country';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import ChevronDownIcon from '@treyd-io/treyd-ui/icons/ChevronDownIcon';
import { map, toNumber } from 'lodash';
import { Link } from 'react-router-dom';

export const SupplierAccordion = ({ order }: { order: Order }) => {
  const { data: supplier } = useExporter(toNumber(order?.exporter_id), {
    include_bank_data: true,
  });

  const bankAccount = supplier?.bank_accounts?.find(
    (bankAccount) => bankAccount.id === order?.bank_account_id
  );

  const bankInfo = bankAccount && getBankInfo(bankAccount);

  return (
    <Accordion
      defaultExpanded
      sx={{
        border: 'none',
        backgroundColor: 'transparent',
      }}>
      <AccordionSummary
        sx={{ padding: 0 }}
        expandIcon={<ChevronDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography variant="h6">Supplier information</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingInline: '0 !important' }}>
        <Row spacing={3}>
          <Col xs={12} md={6}>
            <Typography variant="subtitle1" marginBottom={1}>
              Company details
            </Typography>
            <Stack gap={1}>
              <Row>
                <Col xs="auto">
                  <Typography variant="body1" color="text.secondary">
                    Name
                  </Typography>
                </Col>
                <Col xs zeroMinWidth>
                  <Typography
                    variant="body1"
                    textAlign="right"
                    noWrap
                    color="info.main"
                    title={supplier?.name || ''}>
                    {supplier?.name ? (
                      <Link to={`${ROUTES.suppliers}/${order.exporter_id}`}>
                        {supplier?.name}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col xs="auto">
                  <Typography variant="body1" color="text.secondary">
                    Country
                  </Typography>
                </Col>
                <Col xs zeroMinWidth>
                  <Typography
                    variant="body1"
                    textAlign="right"
                    noWrap
                    title={getCountryName(supplier?.country || '')}>
                    {supplier?.country
                      ? getCountryName(supplier?.country)
                      : '-'}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography variant="body1" color="text.secondary">
                    Address
                  </Typography>
                </Col>
                <Col xs zeroMinWidth title={supplier?.full_address || ''}>
                  <Typography variant="body1" textAlign="right" noWrap>
                    {supplier?.full_address || '-'}
                  </Typography>
                </Col>
              </Row>
            </Stack>
          </Col>
          <Col xs={12} md={6}>
            <Typography variant="subtitle1" marginBottom={1}>
              Beneficiary details
            </Typography>
            <Stack gap={1}>
              {map(
                bankInfo,
                (item) =>
                  item.value && (
                    <Row key={item.label}>
                      <Col>
                        <Typography variant="body1" color="text.secondary">
                          {item.label}
                        </Typography>
                      </Col>
                      <Col xs title={item.value} zeroMinWidth>
                        <Typography variant="body1" textAlign="right" noWrap>
                          {item.value}
                        </Typography>
                      </Col>
                    </Row>
                  )
              )}
            </Stack>
          </Col>
        </Row>
      </AccordionDetails>
    </Accordion>
  );
};
