import { NotificationTemplate } from '@/schemas';
import { Stack, Typography } from '@mui/material';
import NotificationAlert from './NotificationAlert';

export const MerchantViewSection = ({
  notificationTemplate,
}: {
  notificationTemplate?: NotificationTemplate;
}) => {
  if (!notificationTemplate) return null;
  return (
    <Stack
      border={1}
      borderColor="other.outlined_border"
      borderRadius={4}
      padding={3}
      gap={1}>
      <Stack gap={0.5}>
        <Typography variant="h5">Merchants view</Typography>
        <Typography variant="body1">
          The notification that is visible for the merchant.
        </Typography>
      </Stack>
      <NotificationAlert
        type={notificationTemplate?.type}
        headline={notificationTemplate?.header}
        body={notificationTemplate?.body}
        showAction={
          Boolean(notificationTemplate?.action_text) &&
          Boolean(notificationTemplate?.action_url)
        }
        actionText={notificationTemplate?.action_text}
        actionUrl={notificationTemplate?.action_url}
      />
    </Stack>
  );
};
