import { SupplierCompliance } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';

type AddSupplierAttachmentMutationType = {
  django: {
    attachment: SupplierCompliance.Attachment;
  };
};

type AddSupplierAttachmentMutationVariablesType = {
  dataUrl: string | ArrayBuffer | null;
  filename: string;
  tag: string;
  description: string;
  supplierId: number;
};

export const addSupplierAttachmentMutation = gql`
  mutation addSupplierAttachmentMutation(
    $dataUrl: String!
    $filename: String!
    $id: Int = null
    $tag: String = ""
    $description: String = ""
    $supplierId: Int!
  ) {
    django {
      upsert_supplier_attachment(
        dataurl: $dataUrl
        filename: $filename
        id: $id
        tag: $tag
        description: $description
        supplier_id: $supplierId
      ) {
        attachment {
          description
          id
          tag
          url
        }
      }
    }
  }
` as TypedDocumentNode<
  AddSupplierAttachmentMutationType,
  AddSupplierAttachmentMutationVariablesType
>;
