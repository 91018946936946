import { getActiveOrderReview } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetActiveOrderReview = (orderId: number) => {
  return useQuery({
    queryKey: ['active-order-review', orderId],
    queryFn: () => getActiveOrderReview(orderId),
    select: (data) => data?.data,
    enabled: !!orderId,
  });
};
