import {
  transactionsTypes,
  transactionsTypesDisplayedValue,
} from '@/constants';
import { Financier } from '@/schemas/getAllFinanciersQuery';
import { Merchant } from '@/schemas/getAllMerchantsQuery';
import {
  TransactionsTableFilters,
  TransactionsTableFiltersFields,
} from '@/types';
import { getFiltersInitialValues } from '@/utils/TableFilters';
import {
  Autocomplete,
  Badge,
  Button,
  Chip,
  Divider,
  Menu,
  Stack,
  TextField,
  Typography,
  autocompleteClasses,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { invoicingCurrencies } from '@treyd-io/core/constants/country';
import {
  FilterComparator,
  QueryFilterField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import FiltersIcon from '@treyd-io/treyd-ui/icons/FiltersIcon';
import { format, isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { find, isEmpty, map, toString } from 'lodash';
import { useState } from 'react';

interface TableFiltersProps {
  allMerchants?: Merchant[];
  allFinanciers?: Financier[];
  onSubmit: (
    filters: QueryFilterField<TransactionsTableFiltersFields>[]
  ) => void;
  onReset?: () => void;
  filters?: QueryFilterField<TransactionsTableFiltersFields>[];
}

export const TableFilters = (props: TableFiltersProps) => {
  const { allMerchants, allFinanciers, filters, onSubmit, onReset } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClose = () => setAnchorEl(null);
  const appliedFilterFieldsCount = filters?.length || 0;

  const initialValues = getFiltersInitialValues<TransactionsTableFilters>(
    filters,
    {
      paymentDateStart: toString,
      paymentDateEnd: toString,
      types: (types) => types || [],
      merchants: (merchants) => merchants || [],
      currencies: (currencies) => currencies || [],
      financiers: (financiers) => financiers || [],
    }
  );

  const handleformSubmit = (values: TransactionsTableFilters) => {
    const {
      paymentDateStart,
      paymentDateEnd,
      types,
      merchants,
      currencies,
      financiers,
    } = values;
    const filters: QueryFilterField<TransactionsTableFiltersFields>[] = [];

    if (paymentDateStart) {
      filters.push({
        key: 'paymentDateStart',
        type: 'filter',
        name: 'payment_date',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: paymentDateStart,
      });
    }

    if (paymentDateEnd) {
      filters.push({
        key: 'paymentDateEnd',
        type: 'filter',
        name: 'payment_date',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: paymentDateEnd,
      });
    }

    if (!isEmpty(types)) {
      filters.push({
        key: 'types',
        type: 'filter',
        name: 'type',
        comparator: FilterComparator.IN,
        comparisonValue: types,
      });
    }

    if (!isEmpty(merchants)) {
      filters.push({
        key: 'merchants',
        type: 'filter',
        name: 'invoice__order__importer_id',
        comparator: FilterComparator.IN,
        comparisonValue: merchants,
      });
    }
    if (!isEmpty(financiers)) {
      filters.push({
        key: 'financiers',
        type: 'filter',
        name: 'invoice__financier_id',
        comparator: FilterComparator.IN,
        comparisonValue: financiers,
      });
    }

    if (!isEmpty(currencies)) {
      filters.push({
        key: 'currencies',
        type: 'filter',
        name: 'amount_currency',
        comparator: FilterComparator.IN,
        comparisonValue: currencies,
      });
    }

    onSubmit(filters);
    handleMenuClose();
  };

  return (
    <>
      <Badge
        badgeContent={appliedFilterFieldsCount}
        color="primary"
        sx={{ width: '100%' }}>
        <Button
          startIcon={<FiltersIcon />}
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          Filters
        </Button>
      </Badge>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleformSubmit}>
        {(formik) => {
          return (
            <Menu
              keepMounted
              disablePortal
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => formik.handleSubmit()}
              sx={{}}
              slotProps={{
                paper: {
                  elevation: 2,
                  sx: {
                    padding: 2,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: 420,
                    backgroundColor: 'background.default',
                  },
                },
              }}>
              <Form
                noValidate
                tabIndex={-1}
                onKeyDown={(e) => e.stopPropagation()}>
                <Row tabIndex={-1}>
                  <Col xs={12}>
                    <Stack gap={1}>
                      <Typography variant="subtitle1">Payment date</Typography>
                      <Row>
                        <Col xs={12} sm={6}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            label={'Start date'}
                            value={
                              formik.values.paymentDateStart
                                ? new Date(formik.values.paymentDateStart)
                                : null
                            }
                            onChange={(date, context) => {
                              formik.setFieldValue(
                                'paymentDateStart',
                                isValid(date) &&
                                  isEmpty(context.validationError)
                                  ? format(date as Date, 'yyyy-MM-dd')
                                  : '',
                                true
                              );
                            }}
                            slotProps={{
                              field: { clearable: true },
                              textField: {
                                name: 'paymentDateStart',
                                onBlur: formik.handleBlur,
                                error:
                                  formik.touched.paymentDateStart &&
                                  Boolean(formik.errors.paymentDateStart),
                                helperText:
                                  formik.touched.paymentDateStart &&
                                  formik.errors.paymentDateStart,
                              },
                            }}
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            label={'End date'}
                            value={
                              formik.values.paymentDateEnd
                                ? new Date(formik.values.paymentDateEnd)
                                : null
                            }
                            onChange={(date, context) => {
                              formik.setFieldValue(
                                'paymentDateEnd',
                                isValid(date) &&
                                  isEmpty(context.validationError)
                                  ? format(date as Date, 'yyyy-MM-dd')
                                  : '',
                                true
                              );
                            }}
                            slotProps={{
                              field: { clearable: true },
                              textField: {
                                name: 'paymentDateEnd',
                                onBlur: formik.handleBlur,
                                error:
                                  formik.touched.paymentDateEnd &&
                                  Boolean(formik.errors.paymentDateEnd),
                                helperText:
                                  formik.touched.paymentDateEnd &&
                                  formik.errors.paymentDateEnd,
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </Stack>
                  </Col>
                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={transactionsTypes}
                      value={formik.values?.types}
                      getOptionLabel={(option) =>
                        transactionsTypesDisplayedValue[option]
                      }
                      onChange={(_, types) => {
                        formik.setFieldValue('types', types);
                      }}
                      renderTags={(types, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(types, (option, index) => (
                            <Chip
                              label={transactionsTypesDisplayedValue[option]}
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Type"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(allMerchants, (merchant) => merchant.id)}
                      value={formik.values?.merchants}
                      getOptionLabel={(option) =>
                        find(allMerchants, (merchant) => merchant.id === option)
                          ?.name || ''
                      }
                      onChange={(_, merchants) =>
                        formik.setFieldValue('merchants', merchants)
                      }
                      renderTags={(merchants, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(merchants, (option, index) => (
                            <Chip
                              label={
                                find(
                                  allMerchants,
                                  (merchant) => merchant.id === option
                                )?.name
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Merchants"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(allFinanciers, (financier) => financier.id)}
                      value={formik.values?.financiers}
                      getOptionLabel={(option) =>
                        find(
                          allFinanciers,
                          (financier) => financier.id === option
                        )?.name || ''
                      }
                      onChange={(_, financiers) =>
                        formik.setFieldValue('financiers', financiers)
                      }
                      renderTags={(financiers, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(financiers, (option, index) => (
                            <Chip
                              label={
                                find(
                                  allFinanciers,
                                  (financier) => financier.id === option
                                )?.name
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Financiers"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={invoicingCurrencies}
                      value={formik.values?.currencies}
                      onChange={(_, currencies) =>
                        formik.setFieldValue('currencies', currencies)
                      }
                      renderTags={(currencies, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(currencies, (option, index) => (
                            <Chip label={option} {...getTagProps({ index })} />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Currencies"
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} gap={1} display="flex">
                    <Button
                      fullWidth
                      onClick={() => {
                        onReset?.();
                      }}
                      variant="outlined"
                      type="button"
                      color="secondary">
                      Reset
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary">
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Menu>
          );
        }}
      </Formik>
    </>
  );
};
