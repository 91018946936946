import { useSendInvitation, useSendPOA } from '@/hooks';
import { MemberType } from '@/schemas';
import { TreydUser } from '@/types';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { Form, Formik, FormikProps } from 'formik';
import { filter, find, isEmpty, map, toNumber, toString } from 'lodash';
import * as Yup from 'yup';

interface CreateNewPoaFormValues {
  signerMemberId: string;
  requesterId: string;
  email?: string;
}

export const NewPoaModal = ({
  isOpen,
  toggle,
  signatories,
  users,
  companyId,
}: {
  isOpen: boolean;
  toggle: () => void;
  signatories: MemberType[];
  users?: TreydUser[];
  companyId: number;
}) => {
  const { mutate: sendRequest, isPending } = useSendPOA();
  const { mutate: inviteUser, isPending: sendInvitationLoading } =
    useSendInvitation();
  const queryClient = useQueryClient();
  const showNotificationMessage = useToastNotification();

  const loading = sendInvitationLoading || isPending;

  const nonSignatoryUsers = filter(
    users,
    (user) => user?.can_sign_alone === false && user?.identity_verified === true
  );

  const initialValues: CreateNewPoaFormValues = {
    signerMemberId: '',
    requesterId: '',
    email: '',
  };

  const validationSchema = Yup.object({
    signerMemberId: Yup.string().required('Required'),
    requesterId: Yup.string().required('Required'),
    email: Yup.string()
      .email()
      .when('signerMemberId', {
        is: (signerMemberId: string) =>
          !getSelectedSignerUser(toNumber(signerMemberId)),
        then: (schema) => schema.required('Required'),
      }),
  });

  const getSelectedSignerUser = (signerId: number) =>
    users?.find((user) => user?.member_id === signerId);

  const showEmailField = (formik: FormikProps<CreateNewPoaFormValues>) =>
    !getSelectedSignerUser(toNumber(formik.values.signerMemberId)) &&
    formik.dirty &&
    formik.values?.signerMemberId;

  const sendPoaRequest = ({
    requesterId,
    signerId,
    isTreydUser,
  }: {
    requesterId: number;
    signerId: number;
    isTreydUser?: boolean;
  }) => {
    sendRequest(
      {
        companyId,
        user_id: toNumber(requesterId),
        signer_id: toNumber(signerId),
      },
      {
        onSuccess: () => {
          isTreydUser
            ? showNotificationMessage({
                message: 'Poa request sent',
                type: 'success',
              })
            : showNotificationMessage({
                title: 'Poa request sent',
                message: 'The authorized signatory will get an email.',
                type: 'success',
              });
          queryClient.invalidateQueries({
            queryKey: ['company-documents'],
          });
          toggle();
        },
        onError: () => {
          showNotificationMessage({
            message: 'Something went wrong',
            type: 'error',
          });
        },
      }
    );
  };

  const sendUserInvitation = (values: CreateNewPoaFormValues) => {
    const signerName = find(signatories, {
      id: toNumber(values?.signerMemberId),
    })?.name;

    inviteUser(
      {
        company_id: toString(companyId),
        email: toString(values.email).toLocaleLowerCase(),
        name: toString(signerName),
      },
      {
        onSuccess: (resp) => {
          sendPoaRequest({
            requesterId: toNumber(values?.requesterId),
            signerId: resp?.data?.invited_user,
            isTreydUser: false,
          });
        },
        onError: () => {
          showNotificationMessage({
            message: 'Something went wrong',
            type: 'error',
          });
        },
      }
    );
  };

  const sendRequestService = async (values: CreateNewPoaFormValues) => {
    const signer = getSelectedSignerUser(toNumber(values?.signerMemberId));

    if (signer && signer?.can_sign_alone) {
      return sendPoaRequest({
        requesterId: toNumber(values?.requesterId),
        signerId: toNumber(signer?.id),
        isTreydUser: true,
      });
    }
    sendUserInvitation(values);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Create new Power of Attorney</DialogTitle>

      <DialogContent
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1.5),
        })}>
        <DialogContentText>
          The invited user will be authorized by the selected signatory to have
          full signature rights.
        </DialogContentText>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            sendRequestService(values);
          }}>
          {(formik) => (
            <Form noValidate>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={3}
                marginBottom={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="signerMemberId">
                    Select authorized signatory
                  </InputLabel>
                  <Select
                    fullWidth
                    disabled={isEmpty(signatories)}
                    labelId="signerMemberId"
                    id="signerMemberId"
                    name="signerMemberId"
                    value={formik.values.signerMemberId}
                    label="Select authorized signatory"
                    onChange={formik.handleChange}>
                    {map(
                      signatories,
                      (signatory) =>
                        signatory.id && (
                          <MenuItem key={signatory.id} value={signatory.id}>
                            <Box display={'flex'} flexDirection={'column'}>
                              {signatory.name}
                              {!formik.values?.signerMemberId &&
                                !getSelectedSignerUser(
                                  toNumber(signatory.id)
                                ) && (
                                  <Typography
                                    variant={'caption'}
                                    color={'text.secondary'}>
                                    Not an active user
                                  </Typography>
                                )}
                            </Box>
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
                {showEmailField(formik) && (
                  <TextField
                    name="email"
                    label="Email address"
                    type="email"
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                )}
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="requesterId">Recipient</InputLabel>
                  <Select
                    fullWidth
                    disabled={isEmpty(nonSignatoryUsers)}
                    labelId="requesterId"
                    id="requesterId"
                    name="requesterId"
                    value={formik.values.requesterId}
                    label="Recipient"
                    onChange={formik.handleChange}>
                    {map(
                      nonSignatoryUsers,
                      (user) =>
                        user.id && (
                          <MenuItem key={user.id} value={user.id}>
                            {user.name || user.email}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Box>
              {formik.values?.signerMemberId &&
                !getSelectedSignerUser(
                  toNumber(formik.values?.signerMemberId)
                ) && (
                  <Box marginTop={1} marginBottom={2}>
                    <Alert severity="info" variant="standard">
                      <AlertTitle>Not an active user</AlertTitle>
                      The authorized signatory will get an email.
                    </Alert>
                  </Box>
                )}
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                gap={2}
                marginBottom={3}>
                <Button
                  sx={{ height: 'max-content' }}
                  size="large"
                  variant="outlined"
                  color="secondary"
                  onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  sx={{ height: 'max-content' }}
                  size="large"
                  variant="contained"
                  disabled={!formik.isValid || !formik.dirty || loading}
                  onClick={() => {
                    formik.handleSubmit();
                  }}>
                  Send request
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
