import { Orders_Orders_App_Companyfeatureflag } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

export type FeatureFlagsType =
  | Array<Pick<Orders_Orders_App_Companyfeatureflag, 'enabled' | 'id' | 'name'>>
  | undefined;
export type GetAllFeatureFlagsQueryType = {
  orders_orders_app_companyfeatureflag: FeatureFlagsType;
};

export const getAllFeatureFlagsQuery = gql`
  query getFeatureFlags {
    orders_orders_app_companyfeatureflag {
      id
      name
      enabled
    }
  }
` as TypedDocumentNode<GetAllFeatureFlagsQueryType, undefined>;
