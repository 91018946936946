import { getCompanyDocuments } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetCompanyDocuments = (companyId?: number) =>
  useQuery({
    queryKey: [`company-documents`, companyId],
    queryFn: () => getCompanyDocuments(companyId as number),
    enabled: !!companyId,
    refetchOnWindowFocus: false,
  });
