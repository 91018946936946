import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { ROUTES } from '@/constants';
import { useGetCompanyVouchers } from '@/hooks';
import { Voucher } from '@/schemas';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { startCase } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface VouchersSectionProps {
  companyId: number;
}

export const VouchersSection = (props: VouchersSectionProps) => {
  const { companyId } = props;
  const { data, loading: isCompanyVouchersLoading } = useGetCompanyVouchers({
    companyId,
  });
  const vouchers = data?.orders_vouchers_app_voucher;
  const rowCount =
    data?.orders_vouchers_app_voucher_aggregate?.aggregate?.count || 0;

  const columns = useMemo(
    (): GridColDef<Voucher>[] => [
      {
        field: 'slug',
        headerName: 'Voucher #',
        flex: 1,
      },
      {
        field: 'order_id',
        headerName: 'Order #',
        flex: 1,
        renderCell: ({ value }) => (
          <Link to={`${ROUTES.orders}/${value}`}>{value}</Link>
        ),
      },
      {
        field: 'created',
        headerName: 'Redemption date',
        flex: 1,
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
      {
        field: 'status',
        headerName: 'status',
        flex: 1,
        renderCell: ({ value }) => (
          <Chip
            label={startCase(value)}
            color="info"
            variant="standard"
            size="small"
          />
        ),
      },
      {
        field: 'value',
        headerName: 'Amount',
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        renderCell: (row) => formatCurrency(row?.value, row?.row?.currency),
      },
    ],
    []
  );

  const noVouchersProps = !rowCount && {
    columnHeaders: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={1}>
          <Typography variant="h6">Vouchers</Typography>
          <Typography variant="body2" color="textSecondary">
            Claimed vouchers with applied discounts on prior orders
          </Typography>
        </Stack>
      </Stack>
      <Box height={400} width="100%">
        <DataGrid
          rows={vouchers || []}
          columns={columns}
          sortingMode="server"
          paginationMode="server"
          initialState={{
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 10,
              },
            },
          }}
          loading={isCompanyVouchersLoading}
          sx={{
            [`.${gridClasses.panelWrapper}`]: {
              position: 'static',
            },
          }}
          slots={{
            noRowsOverlay: () => (
              <TableHasNoRowsPlaceholder
                title={'Nothing here yet'}
                subtitle={'Vouchers earned will be displayed here.'}
              />
            ),
            ...noVouchersProps,
          }}
          hideFooter={rowCount === 0}
        />
      </Box>
    </Stack>
  );
};
