import { MAPPED_ORDER_STATUS } from '@/constants';
import { useGetCompanyFinanciers, useUpdateOrder } from '@/hooks';
import { getPublishedCreditDecisionQueryTypes } from '@/schemas';
import { RemainingLimitResponse } from '@/types';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import CheckmarkIcon from '@treyd-io/treyd-ui/icons/CheckmarkIcon';
import { AbilityContext } from 'context';
import { first, isEmpty, some, startCase, toString } from 'lodash';

import Order from '@/pages/orderReview/models/Order';
import { useContext } from 'react';

interface Props {
  creditDecision: getPublishedCreditDecisionQueryTypes;
  remainingLimitData: RemainingLimitResponse;
  order: Order;
  refetchOrder: () => void;
}
export const Funding = (props: Props) => {
  const { creditDecision, remainingLimitData, order, refetchOrder } = props;
  const publishedCreditDecision = first(
    creditDecision?.lager_core_company
  )?.latest_published_credit_decision;
  const { rules } = useContext(AbilityContext);
  const canChangeFinancier = some(
    rules,
    (rule) =>
      rule.action === 'edit' && rule.subject === 'orders_app_funding_review'
  );

  const showNotificationMessage = useToastNotification();
  const { mutate: updateOrder, isPending: isUpdatingOrder } = useUpdateOrder();

  const updateOrderFinancier = (financierId: number) => {
    updateOrder(
      {
        data: {
          financier_id: financierId,
        },
        orderId: order.id,
      },
      {
        onSuccess: () => {
          refetchOrder();
          showNotificationMessage({
            title: 'Financier selected.',
            type: 'success',
          });
        },
      }
    );
  };

  const isAwaitingFundingApproval =
    order?.mapped_status === MAPPED_ORDER_STATUS.AWAITING_FUNDING_APPROVAL;

  const { data: financiers, isPending: financiersLoading } =
    useGetCompanyFinanciers(order?.importer_id);

  if (financiersLoading) {
    return null;
  }
  return (
    <Box sx={{ paddingBlock: 2 }}>
      {order?.mapped_status ===
        MAPPED_ORDER_STATUS.AWAITING_FUNDING_APPROVAL && (
        <Alert sx={{ marginBlockEnd: 5 }} severity="error">
          <AlertTitle>Order cannot be approved automatically.</AlertTitle>
        </Alert>
      )}
      <Typography variant="h5">Credit decision</Typography>
      <Row
        marginY={{ xs: 0, sm: 3 }}
        padding={{ xs: 2, sm: 0 }}
        justifyContent="space-between">
        <Col xs={4}>
          <TwoLines
            label="Limit"
            content={formatCurrency(
              publishedCreditDecision?.approved_limit,
              toString(publishedCreditDecision?.approved_limit_currency)
            )}
          />
        </Col>
        <Col xs={4}>
          <TwoLines
            label="Outstanding credit"
            content={formatCurrency(
              remainingLimitData?.outstanding_credit,
              remainingLimitData?.limit_currency
            )}
          />
        </Col>
        <Col xs={4}>
          <TwoLines
            label="Remaining credit"
            content={formatCurrency(
              remainingLimitData?.available_limit,
              remainingLimitData?.limit_currency
            )}
          />
        </Col>
        <Col xs={4}>
          <TwoLines
            label="Rating"
            content={publishedCreditDecision?.approved_rating}
          />
        </Col>
        <Col xs={4}>
          <TwoLines
            label="Published at"
            content={
              publishedCreditDecision?.published_at
                ? formatDate(
                    new Date(publishedCreditDecision?.published_at),
                    'date'
                  )
                : ''
            }
          />
        </Col>
        <Col xs={4}>
          <TwoLines
            label="Next planned review date"
            content={publishedCreditDecision?.expiration_date}
          />
        </Col>
      </Row>
      <Divider sx={{ marginBlock: 5 }} />
      <Typography variant="h5">Financiers</Typography>

      {isEmpty(financiers) ? (
        <Stack
          sx={{
            padding: 5,
            marginBlock: 2,
            border: 1,
            borderRadius: 4,
            borderColor: 'divider',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography variant="subtitle1">No financiers</Typography>
          <Typography variant="body2" color="text.secondary">
            Selected financier will be displayed here
          </Typography>
        </Stack>
      ) : (
        financiers?.map((financier) => (
          <Stack
            sx={{
              padding: 3,
              marginBlock: 2,
              border: 1,
              borderRadius: 4,
              borderColor: 'divider',
            }}>
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
              <Typography variant="h6">{`${startCase(
                financier.financier_priority.toLowerCase()
              )}: ${financier?.financier_name}`}</Typography>
              <Button
                size="small"
                variant={
                  order.financier_id === financier.financier_id
                    ? 'contained'
                    : 'outlined'
                }
                startIcon={
                  order.financier_id === financier.financier_id ? (
                    <CheckmarkIcon />
                  ) : (
                    ''
                  )
                }
                color={
                  order.financier_id === financier.financier_id
                    ? 'success'
                    : 'secondary'
                }
                disabled={
                  !canChangeFinancier ||
                  isUpdatingOrder ||
                  !isAwaitingFundingApproval ||
                  financier.financier_priority === 'PRIMARY'
                }
                onClick={() => updateOrderFinancier(financier.financier_id)}>
                {order.financier_id === financier.financier_id
                  ? 'Selected'
                  : 'Select'}
              </Button>
            </Stack>
            <Row marginTop={2} spacing={0}>
              <Col xs={4}>
                <TwoLines
                  label="Limit"
                  content={
                    <Typography>
                      {formatCurrency(
                        Number(financier.limit.limit),
                        financier.limit.limit_currency
                      )}
                    </Typography>
                  }
                />
              </Col>
              <Col xs={4}>
                <TwoLines
                  label="Outstanding credit"
                  content={formatCurrency(
                    Number(financier.limit.outstanding_limit),
                    financier.limit.limit_currency
                  )}
                />
              </Col>
              <Col xs={4}>
                <TwoLines
                  label="Remaining credit"
                  content={formatCurrency(
                    Number(financier.limit.new_remaining),
                    financier.limit.limit_currency
                  )}
                />
              </Col>
            </Row>
          </Stack>
        ))
      )}
    </Box>
  );
};
