import GenericTableView from '@/components/GenericTableView';
import { DEFAULT_GRID_COL_DEF, ORDER_REVIEW_PATHS } from '@/constants';
import { Order, getSupplierOrdersQuery } from '@/schemas';
import { Box, Chip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { newISODate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { ORDER_STATUSES } from 'constants/orders';
import { startCase, toNumber, toString } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';

export const Orders = ({ supplierId }: { supplierId: string }) => {
  const navigate = useNavigate();
  const handleRowClick = (params: { id: string }) =>
    navigate(ORDER_REVIEW_PATHS[':id'].replaceAll(':id', params.id));

  const chipColor = (status: string) => {
    if (status === 'paid') return 'success';
    if (status === 'open') return 'info';
    return undefined;
  };

  const columns: GridColDef[] = [
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'id',
      headerName: 'Order #',
      renderCell: (params: any) => (
        <Link to={ORDER_REVIEW_PATHS[':id'].replaceAll(':id', params.value)}>
          {params.value}
        </Link>
      ),
    },
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'supplier_invoice__order_ref',
      headerName: 'Internal order reference',
      type: 'string',
    },
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'supplier_invoice__invoice_ref',
      headerName: 'Supplier ref',
      type: 'string',
    },
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'status',
      headerName: 'Status',
      type: 'string',
      renderCell: ({ value }) => (
        <Chip
          label={startCase(value)}
          color={chipColor(value)}
          variant="standard"
        />
      ),
    },
    {
      ...DEFAULT_GRID_COL_DEF,
      field: 'active_payout__amount',
      headerName: 'Amount',
      type: 'number',
      sortable: false,
      renderCell: (params) => (
        <Typography variant="body2" sx={{ fontVariantNumeric: 'tabular-nums' }}>
          {params.value}
        </Typography>
      ),
    },
  ];

  const formatRow = (order: Order) => ({
    ...order,
    supplier_invoice__order_ref: order.supplier_invoice?.order_ref,
    supplier_invoice__invoice_ref: order.supplier_invoice?.invoice_ref,
    active_payout__amount: formatCurrency(
      toNumber(order.active_payout?.amount),
      toString(order.active_payout?.currency),
      'en-SE'
    ),
    modified: newISODate(order.modified),
  });

  return (
    <Box>
      <Box display="flex" flexDirection={'column'} flexGrow={1} height="23rem">
        <GenericTableView
          isDownloadAllowed={true}
          searchPlaceholder="Search for order #, supplier ref, internal order reference"
          columns={columns}
          getGQLSchema={(offset, limit, searchTerm, sorting, filters) =>
            getSupplierOrdersQuery(
              offset,
              limit,
              Number(supplierId),
              searchTerm,
              sorting,
              filters
            )
          }
          rowFormatter={formatRow}
          resourceName="orders_orders"
          filterMapper={startCase}
          filterList={[
            {
              field: 'status',
              options: ORDER_STATUSES,
              label: 'Status',
            },
          ]}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};
