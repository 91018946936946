import { autoDealComplianceReview } from '@/api';
import {
  OrderComplianceAutoReviewError,
  OrderComplianceAutoReviewResponse,
} from '@/types';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export const useGetOrderComplianceAiReview = (orderId: number) => {
  return useQuery<
    AxiosResponse<OrderComplianceAutoReviewResponse>,
    OrderComplianceAutoReviewError,
    OrderComplianceAutoReviewResponse
  >({
    queryKey: ['orderComplianceReview', orderId],
    queryFn: () => autoDealComplianceReview(orderId),
    enabled: !!orderId,
    select: (data) => data.data,
    refetchOnWindowFocus: false,
    retry: 1,
  });
};
