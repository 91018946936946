import { HIDDEN_NAVIGATION_ROUTES } from '@/constants';
import { AppNavBarContext } from '@/context';
import { ReactNode, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

export const AppNavBarProvider = ({ children }: { children: ReactNode }) => {
  const [isAppNavBarOpen, setIsAppNavBarOpen] = useLocalStorage<boolean>(
    'isAppNavBarOpen',
    false
  );
  const [isAppNavBarHidden, setIsAppNavBarHidden] = useState(true);
  const pathName = useLocation().pathname;

  useLayoutEffect(() => {
    if (HIDDEN_NAVIGATION_ROUTES.some((route) => route.test(pathName)))
      return setIsAppNavBarHidden(true);

    return setIsAppNavBarHidden(false);
  }, [pathName]);

  return (
    <AppNavBarContext.Provider
      value={{
        isAppNavBarOpen: Boolean(isAppNavBarOpen),
        setIsAppNavBarHidden,
        setIsAppNavBarOpen,
        isAppNavBarHidden,
      }}>
      {children}
    </AppNavBarContext.Provider>
  );
};
