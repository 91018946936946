import { Lager_Treyd_Users } from '@/__generated__/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

export type UserDetails = Pick<
  Lager_Treyd_Users,
  | 'can_sign_alone'
  | 'is_active'
  | 'is_ubo'
  | 'name'
  | 'member_id'
  | 'profile_id'
  | 'identity_verified'
  | 'company_user_id'
>;
interface GetUserDetails {
  lager_treyd_users: UserDetails[];
}
export const getUserDetails = gql`
  query getUserDetails($user_id: Int!, $company_id: bigint!) {
    lager_treyd_users(
      where: { id: { _eq: $user_id }, company_id: { _eq: $company_id } }
    ) {
      can_sign_alone
      is_active
      is_ubo
      name
      member_id
      profile_id
      identity_verified
      company_user_id
    }
  }
` as TypedDocumentNode<GetUserDetails, { user_id: number; company_id: number }>;
