import { useGetFinancialDocumentsData } from '@/hooks';
import { Stack, Typography } from '@mui/material';

export const FiscalYearSubtitle = () => {
  const {
    fiscalYearInfo: {
      currentFiscalYear,
      currentFiscalYearFormula,
      lastFiscalYear,
      lastFiscalYearFormula,
    },
  } = useGetFinancialDocumentsData();
  return (
    <Stack>
      <Typography variant="body1" color={'text.secondary'}>
        {`Current year; from fiscal year start ${currentFiscalYear} to ${currentFiscalYearFormula}`}
      </Typography>
      <Typography variant="body1" color={'text.secondary'}>
        {`Previous year; from fiscal year start ${lastFiscalYear} to ${lastFiscalYearFormula}`}
      </Typography>
    </Stack>
  );
};
