export enum LOGGING_MESSAGES {
  //ORDER
  ORDER_ASSIGN_SUCCESS = 'Order assigned successfully',
  ORDER_ASSIGN_FAIL = 'Order assignment failed',
  ORDER_APPROVE_SUCCESS = 'Order approved successfully',
  ORDER_APPROVE_FAIL = 'Order approval failed',
  ORDER_REJECT_SUCCESS = 'Order rejected successfully',
  ORDER_REJECT_FAIL = 'Order rejection failed',
  ORDER_SEND_BACK_SUCCESS = 'Order sent back successfully',
  ORDER_SEND_BACK_FAIL = 'Order sent back failed',
  ORDER_COMMENT_SUCCESS = 'Order comment added successfully',
  ORDER_COMMENT_FAIL = 'Order comment addition failed',
  ORDER_COMPLIANCE_REVIEW_SUCCESS = 'Order compliance review submitted successfully',
  ORDER_COMPLIANCE_REVIEW_FAIL = 'Order compliance review submission failed',
  ORDER_COMPLIANCE_AUTO_REVIEW_SUCCESS = 'Order compliance auto review submitted successfully',
  ORDER_COMPLIANCE_AUTO_REVIEW_FAIL = 'Order compliance auto review submission failed',
  ORDER_FUNDING_REVIEW_SUCCESS = 'Order funding review submitted successfully',
  ORDER_FUNDING_REVIEW_FAIL = 'Order funding review submission failed',
  ORDER_FUNDING_AUTO_REVIEW_SUCCESS = 'Order funding auto review submitted successfully',
  ORDER_FUNDING_AUTO_REVIEW_FAIL = 'Order funding auto review submission failed',
}
