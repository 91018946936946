import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { Documents } from '@/types';
import { Box } from '@mui/material';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import { DocumentActionButtons } from './DocumentActionButtons';

export const DocumentCard = ({
  document,
  companyId,
}: {
  document: Documents.DocumentType;
  companyId: number;
}) => {
  const getSignerName = document?.signers?.find(
    (signer) => signer?.id === document?.data?.Signer_ID
  )?.name;
  return (
    <Box
      padding={3}
      borderRadius={2}
      border={1}
      borderColor="grey.300"
      display={'flex'}
      justifyContent={'space-between'}>
      <Row>
        <Col xs={6} md={4}>
          <TwoLines
            label={'Authorized signatory'}
            content={getSignerName || 'N/A'}
          />
        </Col>
        <Col xs={6} md={4}>
          <TwoLines label={'Recipient'} content={document?.data?.User_Name} />
        </Col>
      </Row>
      <DocumentActionButtons document={document} companyId={companyId} />
    </Box>
  );
};
