import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import TrashTwoIcon from '../icons/TrashIcon';
import { FileAlertBuilder } from '../services/FileAlertService';
import { Attachment } from '../types/file';
import { isValidAttachment } from '../utils/file';
import { FileObject } from './Upload/Uploader';

export const FileAlert = ({
  file,
  filename,
  loading,
  onDelete,
}: {
  file: FileObject | Attachment | string;
  filename?: string;
  loading?: boolean;
  onDelete?: (id?: number) => void;
}) => {
  const theme = useTheme();
  const fileAlert = new FileAlertBuilder(
    file,
    theme,
    loading,
    filename
  ).build();
  const { color, errorMessage, icon, size, text, name, textColor, titleColor } =
    fileAlert.FileAlert;
  return (
    <Collapse in>
      <Alert
        key={name}
        variant="standard"
        severity={color}
        icon={icon}
        action={
          onDelete && (
            <IconButton
              aria-label="close"
              color="primary"
              size="small"
              disabled={loading}
              onClick={() => {
                onDelete?.(
                  isValidAttachment(file) ? Number(file?.id) : undefined
                );
              }}>
              <TrashTwoIcon />
            </IconButton>
          )
        }
        sx={{ marginBottom: 2 }}>
        <AlertTitle color={titleColor}>{name}</AlertTitle>
        <Box sx={(theme) => ({ display: 'flex', gap: theme.spacing(1) })}>
          {size && (
            <>
              <Typography variant="body2" color={textColor}>
                {errorMessage || size}
              </Typography>
              <Typography variant="body2" color={textColor}>
                •
              </Typography>
            </>
          )}
          <Typography variant="body2" color={textColor}>
            {text}
          </Typography>
        </Box>
      </Alert>
    </Collapse>
  );
};
