import Col from '@/components/Grid/Col';
import { Stack, Typography } from '@mui/material';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import { isEmpty, map } from 'lodash';
import { FeesHistory } from './FeesHistory';

export const FeesSection = ({
  creditFees,
  companyId,
  isLimitIndicative,
}: {
  creditFees?: Record<number, number>;
  companyId: number;
  isLimitIndicative: boolean;
}) => {
  if (isEmpty(creditFees)) {
    return (
      <Stack gap={2}>
        <Typography variant="h5" color="text.primary">
          Fees
        </Typography>
        <Typography variant="body2" color="text.secondary">
          No fees to display
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Stack gap={1}>
          <Typography variant="h5" color="text.primary">
            Fees
          </Typography>
          <Typography variant="body1" color="text.primary">
            {isLimitIndicative
              ? 'Indicative interest rates for the different repayment terms.'
              : 'Interest rates for the different repayment terms.'}
          </Typography>
        </Stack>
        <Stack
          border={1}
          borderColor="other.outlined_border"
          borderRadius={4}
          padding={3}>
          <Row spacing={{ xs: 4, sm: 8 }}>
            {creditFees &&
              map(creditFees, (fee: number, key: number) => (
                <Col xs={6} sm="auto">
                  <TwoLines
                    label={
                      <Typography variant="body1" color="text.secondary">
                        {key * 30} days
                      </Typography>
                    }
                    content={`${fee}%`}
                  />
                </Col>
              ))}
          </Row>
        </Stack>
      </Stack>
      <FeesHistory companyId={companyId} />
    </Stack>
  );
};
