import {
  COMPANY_APPLIED_DISCOUNTS,
  COMPANY_ATTORNEY,
  COMPANY_FINANCIERS,
  COMPANY_MANUAL_DISCOUNT_CHARGES,
  COMPANY_STATUSES,
  CREATE_CORE_COMPANY,
  DELETE_COMPANY_USER,
  DOCUMENTS_RESEND_EMAILS,
  DOCUMENTS_URL,
  DOES_COMPANY_EXIST,
  GET_ALL_CREDITS,
  GET_AVAILABLE_CREDITS_AMOUNT,
  LIMIT_STATUS,
  MERCHANT_SEGMENT,
  REMAINING_LIMIT,
  UPDATE_COMPANY_HUBSPOT_ID,
  UPDATE_COMPANY_SEGMENT,
} from '@/constants';

import {
  AddCompanyForm,
  CompanyAppliedDiscounts,
  CompanyExistsResponse,
  CompanyFinanciersResponse,
  CompanyManualDiscountChargesPayload,
  CompanyStatusesResponse,
  CoreCompanyResponseData,
  Documents,
  GetCompanyCreditsType,
  LimitStatus,
  RemainingLimitResponse,
} from '@/types';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { sanitizeOrgNumber } from '@treyd-io/core/utils/organization';
import { get, save } from '@treyd-io/core/utils/request';

export const getRemainingLimit = (companyId: number) =>
  save<RemainingLimitResponse>(
    REMAINING_LIMIT(companyId),
    {},
    REQUEST_METHOD.GET
  );

export const getCompanyDocuments = (companyId: number) =>
  get<Documents.DocumentResponse>(DOCUMENTS_URL('companies', companyId));
export const getCompanyStatuses = (companyId: number) =>
  get<CompanyStatusesResponse>(COMPANY_STATUSES(companyId));
export const resendDocumentEmails = ({
  companyId,
  documentId,
}: Documents.ResendDocumentEmailsPayload) =>
  save(
    DOCUMENTS_RESEND_EMAILS('companies', companyId, documentId),
    {},
    REQUEST_METHOD.PUT
  );

export const deleteCompanyUser = ({
  userId,
  companyId,
}: {
  userId: number;
  companyId: number;
}) =>
  save(
    DELETE_COMPANY_USER,
    { user_id: userId, company_id: companyId },
    REQUEST_METHOD.POST
  );

export const getCompanyAppliedDiscounts = (companyId: number) =>
  get<CompanyAppliedDiscounts>(COMPANY_APPLIED_DISCOUNTS(companyId));

export const updateCompanyManualDiscount = ({
  companyId,
  payload,
}: {
  companyId: number;
  payload: CompanyManualDiscountChargesPayload;
}) =>
  save<any>(
    COMPANY_MANUAL_DISCOUNT_CHARGES(companyId),
    payload,
    REQUEST_METHOD.POST
  );
export const getCompanyFinanciers = (companyId: number) =>
  get<CompanyFinanciersResponse>(COMPANY_FINANCIERS(companyId));

export const sendPOA = ({ companyId, signer_id, user_id }: Documents.Payload) =>
  save<{ payload: Documents.DocumentType }>(
    COMPANY_ATTORNEY(companyId),
    { signer_id, user_id },
    REQUEST_METHOD.POST
  );

export const getCompanyCredits = async (
  companyId: number,
  limit: number,
  page: number
) =>
  save<GetCompanyCreditsType>(
    GET_ALL_CREDITS(companyId, limit, page),
    {},
    REQUEST_METHOD.GET
  );

export const getCompanyAvailableCreditsAmount = async (companyId: number) =>
  save<{ available_credits: number }>(
    GET_AVAILABLE_CREDITS_AMOUNT(companyId),
    {},
    REQUEST_METHOD.GET
  );

export const createCoreCompany = async (params: AddCompanyForm) =>
  save<CoreCompanyResponseData>(
    CREATE_CORE_COMPANY(
      params.country,
      sanitizeOrgNumber(params.org_no).toUpperCase()
    ),
    {},
    REQUEST_METHOD.POST
  );

export const doesCompanyExists = (params: {
  country: string;
  org_no: string;
}) =>
  save<CompanyExistsResponse>(DOES_COMPANY_EXIST, params, REQUEST_METHOD.POST);

export const updateMerchantSegment = ({
  companyId,
  segment,
}: {
  companyId: number;
  segment: MERCHANT_SEGMENT;
}) => save(UPDATE_COMPANY_SEGMENT(companyId), { segment }, REQUEST_METHOD.PUT);

export const updateCompanyHubspotId = ({
  companyId,
  hubspotVid,
}: {
  companyId: number;
  hubspotVid: string | null;
}) =>
  save(
    UPDATE_COMPANY_HUBSPOT_ID(companyId),
    { hubspot_vid: hubspotVid },
    REQUEST_METHOD.PUT
  );

export const getLimitStatus = (companyId: number) =>
  get<LimitStatus.Response>(LIMIT_STATUS(companyId));
