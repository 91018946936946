import { Orders_Companies } from '@/__generated__/graphql';

import { gql, TypedDocumentNode } from '@apollo/client';

interface getMerchantNameByIdQueryTypes {
  orders_companies_by_pk: Pick<Orders_Companies, 'name'>;
}

export const getMerchantNameById = gql`
  query MerchantDetails($id: bigint!) {
    orders_companies_by_pk(id: $id) {
      name
    }
  }
` as TypedDocumentNode<getMerchantNameByIdQueryTypes, { id: number }>;
