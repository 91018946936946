import MerchantInformationSection from '@/pages/merchants/MerchantsDetails/Tabs/GeneralInfo/MerchantInformationSection';
import { CompanyStatusesResponse, ComplianceStatus, Merchant } from '@/types';
export const GeneralInfo = ({
  complianceStatus,
  merchant,
  riskAmlLevel,
  companyStatuses,
}: {
  merchant: Merchant;
  complianceStatus: ComplianceStatus;
  riskAmlLevel?: string;
  companyStatuses?: CompanyStatusesResponse;
}) => (
  <MerchantInformationSection
    merchant={merchant}
    complianceStatus={complianceStatus}
    riskAmlLevel={riskAmlLevel}
    companyStatuses={companyStatuses}
  />
);
