import UserAvatar from '@/components/UserAvatar';
import { Stack, Tooltip, Typography } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import HelpCircleIcon from '@treyd-io/treyd-ui/icons/HelpCircleIcon';
import { isNumber, map, toNumber, toString } from 'lodash';

import { CompanyCreditDecision } from '@/schemas';
import { EmptySection } from './EmptySection';

interface PublishedCreditDecisionSectionProps {
  companyCreditDecision?: CompanyCreditDecision;
  desiredCredit: string;
  remainingCredit: string;
  outstandingCredit: string;
  isPublishedCreditDecisionVisible: boolean;
}
export const PublishedCreditDecision = ({
  companyCreditDecision,
  desiredCredit,
  remainingCredit,
  outstandingCredit,
  isPublishedCreditDecisionVisible,
}: PublishedCreditDecisionSectionProps) => {
  const approvedLimit = companyCreditDecision?.approved_limit;
  const approvedLimitCurrency = toString(
    companyCreditDecision?.approved_limit_currency
  );
  const approvedRating = companyCreditDecision?.approved_rating;
  const createdAt = companyCreditDecision?.created;
  const publishedAt = companyCreditDecision?.published_at;
  const expirationDate = companyCreditDecision?.expiration_date;
  const approvedLimitComments = companyCreditDecision?.approved_limit_comments;
  const approvedRatingComments =
    companyCreditDecision?.approved_rating_comments;
  const approvals = companyCreditDecision?.approvals;

  if (!isPublishedCreditDecisionVisible)
    return (
      <EmptySection
        title="No current credit decision"
        subtitle="The latest published credit decision will display here"
        inline
      />
    );

  return (
    <Stack gap={4}>
      <Typography variant="h6">Published credit decision</Typography>
      <Row>
        <Col xs={12} sm={6} md={3}>
          <TwoLines label="Desired limit" content={desiredCredit || '-'} />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <TwoLines
            label="Total credit"
            content={
              formatCurrency(
                toNumber(approvedLimit),
                toString(approvedLimitCurrency)
              ) || '-'
            }
          />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <TwoLines label="Remaining credit" content={remainingCredit || '-'} />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <TwoLines
            label="Outstanding credit"
            content={outstandingCredit || '-'}
          />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <TwoLines
            label="Treyd Rating"
            content={
              (isNumber(approvedRating) && `${approvedRating}/10`) || '-'
            }
          />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <TwoLines
            label="Created at"
            content={
              (createdAt && formatDate(new Date(createdAt), 'date')) || '-'
            }
          />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <TwoLines
            label="Published at"
            content={
              (publishedAt && formatDate(new Date(publishedAt), 'date')) || '-'
            }
          />
        </Col>
        <Col xs={12} sm={6} md={3}>
          <TwoLines
            label={
              <Stack direction="row" alignItems="cneter" gap={0.25}>
                <Typography color="text.secondary" variant="body2">
                  Next planned review date
                </Typography>
                <Tooltip title="Expiration date" placement="top" arrow>
                  <Stack justifyContent="center" alignItems="cneter">
                    <HelpCircleIcon
                      fontSize="small"
                      sx={{ color: 'text.secondary' }}
                    />
                  </Stack>
                </Tooltip>
              </Stack>
            }
            content={
              (expirationDate &&
                formatDate(new Date(expirationDate), 'date')) ||
              '-'
            }
          />
        </Col>
      </Row>
      <Stack gap={3}>
        <TwoLines
          label="Approved limit comment"
          content={approvedLimitComments || '-'}
          contentProps={{
            noWrap: false,
          }}
        />
        <TwoLines
          label="Approved rating comment"
          content={approvedRatingComments || '-'}
          contentProps={{
            noWrap: false,
          }}
        />
      </Stack>
      <Stack gap={1}>
        <Typography variant="body2" color="text.secondary">
          Approvers
        </Typography>
        {(approvals && (
          <Row spacing={{ xs: 1, sm: 3 }}>
            {map(approvals, (approval) => {
              const firstName = toString(
                approval?.risk_creditdecisionapprover?.first_name
              ).trim();
              const lastName = toString(
                approval?.risk_creditdecisionapprover?.last_name
              ).trim();
              return (
                <Col xs={12} sm={'auto'}>
                  <Stack direction="row" gap={1}>
                    <UserAvatar
                      sx={{
                        height: '1.5rem',
                        width: '1.5rem',
                        fontSize: 12,
                      }}
                      firstName={firstName}
                      lastName={lastName}
                    />
                    <Typography variant="body1" color="text.primary">
                      {firstName} {lastName}
                    </Typography>
                  </Stack>
                </Col>
              );
            })}
          </Row>
        )) ||
          '-'}
      </Stack>
    </Stack>
  );
};
