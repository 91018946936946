import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '../../icons/CloseIcon';

import { map } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { FileAlert } from '../../components/FileAlert';
import { Attachment } from '../../types/file';
import { UploadDropZone } from './UploadDropZone';

export interface FileObject {
  file?: File;
  status?: 'success' | 'error';
  errors?: string[];
}
export interface UploaderProps {
  files: Attachment[] | string[];
  open: boolean;
  isLoading?: boolean;
  uploadFile: (file: File) => void;
  onDelete?: (id?: number) => void;
  onCancel?: () => void;
  title: string;
  subtitle?: ReactNode;
  single?: boolean;
  filename?: string;
  acceptsImages?: boolean;
}
export const Uploader = (props: UploaderProps) => {
  const {
    files,
    open,
    title,
    subtitle,
    isLoading,
    filename,
    single = false,
    acceptsImages = false,
    onDelete,
    onCancel,
    uploadFile,
  } = props;

  const [fileObjects, setFileObjects] = useState<
    (Attachment | FileObject | string)[]
  >([]);

  useEffect(() => {
    files ? setFileObjects(files) : setFileObjects([]);
  }, [files]);

  const handleOnFinish = (fileObjects: FileObject[]) => {
    setFileObjects((prev) => (prev ? [...prev, ...fileObjects] : fileObjects));
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth>
      <DialogTitle
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <Typography variant="h5" noWrap>
          {title}
        </Typography>
        <IconButton aria-label="close" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {subtitle && (
          <Typography variant="body2" marginBottom={2}>
            {subtitle}
          </Typography>
        )}
        <Box>
          <UploadDropZone
            uploadFile={uploadFile}
            onFinish={handleOnFinish}
            disabled={single && fileObjects?.length === 1}
            acceptsImages={acceptsImages}
          />
          {map(fileObjects, (file, index) => (
            <FileAlert
              key={
                (file as Attachment).id ||
                (file as FileObject).file?.name ||
                (file as string)
              }
              file={file}
              onDelete={
                typeof file === 'string' ? () => onDelete?.(index) : onDelete
              }
              loading={isLoading && index === fileObjects.length - 1}
              filename={filename}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
