import { mapValues } from 'lodash';
import { ROUTES } from './routes';

const DEV_ROOT = ROUTES.dev;

export const DEV_ROUTES = {
  root: '',
  user_details: `company/:company_id/user/:user_id`,
  event_details: 'event/:event_id',
  invoice_details: 'invoice/:invoice_id',
  feature_flags: 'feature-flags',
  feature_flag_details: 'feature-flags/:feature_flag_id',
};

export const DEV_PATHS = mapValues(
  DEV_ROUTES,
  (route) => `${DEV_ROOT}/${route}`
);
