import { IconButton, InputAdornment } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';
import SearchIcon from '@treyd-io/treyd-ui/icons/SearchIcon';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import { useState } from 'react';

type SearchFieldProps = TextFieldProps & {
  searchTerm?: string;
  setSearchTerm: (searchTerm: string) => void;
  withClearButton?: boolean;
};

export const SearchField = ({
  searchTerm,
  setSearchTerm,
  placeholder,
  withClearButton = true,
  ...restProps
}: SearchFieldProps) => {
  const [value, setValue] = useState(searchTerm);

  useDebouncedEffect(
    () => {
      setSearchTerm(value || '');
    },
    [value],
    100
  );

  return (
    <TextField
      {...restProps}
      value={value}
      onChange={(e) => setValue(e.target.value ? String(e.target.value) : '')}
      fullWidth
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: withClearButton && value && (
          <InputAdornment position="end">
            <IconButton onClick={() => setValue('')} size="small">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default SearchField;
