import { ORDER_EVENT } from '@/constants';
import { useSubmitOrderEvent } from '@/hooks';
import {
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';

const ApprovedOrderActionButtons = ({
  orderId,
  goBack,
}: {
  orderId: number;
  goBack: () => void;
}) => {
  const { mutate: submitOrderEvent, isPending } = useSubmitOrderEvent();
  const [openSendBackDialog, setOpenSendBackDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const handleSendBack = () => {
    submitOrderEvent(
      {
        id: orderId,
        eventName: ORDER_EVENT.SEND_BACK,
      },
      {
        onSuccess: goBack,
      }
    );
  };

  const handleCancel = () => {
    submitOrderEvent(
      {
        id: orderId,
        eventName: ORDER_EVENT.CANCEL,
      },
      {
        onSuccess: goBack,
      }
    );
  };

  return (
    <Stack alignItems="center" direction="row">
      <Button
        disabled={isPending}
        color="info"
        onClick={() => setOpenSendBackDialog(true)}
        size="large">
        Send back
      </Button>
      <Button
        disabled={isPending}
        color="error"
        onClick={() => setOpenCancelDialog(true)}
        size="large">
        Cancel
      </Button>

      {/* Send Back Confirmation Dialog */}
      <Dialog
        open={openSendBackDialog}
        onClose={() => setOpenSendBackDialog(false)}>
        <DialogTitle>Confirm send back</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to send this order back?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => setOpenSendBackDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            onClick={() => {
              setOpenSendBackDialog(false);
              handleSendBack();
            }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Cancel Confirmation Dialog */}
      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}>
        <DialogTitle>Confirm cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => setOpenCancelDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            onClick={() => {
              setOpenCancelDialog(false);
              handleCancel();
            }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ApprovedOrderActionButtons;
