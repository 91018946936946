import { Orders_Orders_App_Consent } from '@/__generated__/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';

type CompanyAgreementConsent = Pick<
  Orders_Orders_App_Consent,
  'version_number' | 'created'
>;

export type GetCompanyAgreementConsentVersionNumberType = {
  orders_orders_app_consent: CompanyAgreementConsent[];
};

export const getActiveCompanyAgreementConsentVersionNumberQuery = gql`
  query getCompanyAgreementConsents($companyId: Int!) {
    orders_orders_app_consent(
      where: {
        _and: {
          consentable_id: { _eq: $companyId }
          type: { _eq: "company_agreement" }
          is_active: { _eq: true }
        }
      }
    ) {
      version_number
      created
    }
  }
` as TypedDocumentNode<
  GetCompanyAgreementConsentVersionNumberType,
  { companyId: number }
>;
