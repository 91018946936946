import { ROUTES } from '@/constants';
import { useGetOrderVouchers } from '@/hooks';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { first, toString } from 'lodash';
import { Link } from 'react-router-dom';

export const OrderVoucherAlert = ({
  orderId,
  importerId,
}: {
  orderId: number;
  importerId: number;
}) => {
  const { data: orderVouchers, loading: isLoadingOrderVouchers } =
    useGetOrderVouchers(orderId);
  const appliedVoucher = first(orderVouchers?.orders_vouchers_app_voucher);
  if (isLoadingOrderVouchers) return null;
  return (
    appliedVoucher && (
      <Alert severity="info">
        <AlertTitle>{`Voucher ${appliedVoucher.slug} applied`}</AlertTitle>
        <Stack gap={0.5}>
          <Typography variant="body2">
            {`Merchant received a discount for ${formatCurrency(
              appliedVoucher.value,
              toString(appliedVoucher.currency)
            )} from their earned credits.`}
          </Typography>
          <Typography
            component={Link}
            to={`${ROUTES.merchants}/${importerId}/#credits-and-vouchers`}>
            Check credits and vouchers
          </Typography>
        </Stack>
      </Alert>
    )
  );
};
