import { getErpInvoicesPdf } from '@/api';
import { ERP_INVOICE_PDF_TYPES } from '@/constants';
import { useQuery } from '@tanstack/react-query';

export const useGetErpInvoicePdf = ({
  type,
  uuid,
}: {
  type: ERP_INVOICE_PDF_TYPES;
  uuid: string;
}) => {
  return useQuery({
    queryKey: ['erp-invoice-pdf', type, uuid],
    queryFn: () => getErpInvoicesPdf({ type, uuid }),
    enabled: !!uuid,
  });
};
