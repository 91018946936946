import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getOrdersComplianceReviewsQuery } from '../schemas/getOrdersComplianceReviewsQuery';

export const useGetOrdersComplianceReviews = ({
  queryOptions,
  skip,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(getOrdersComplianceReviewsQuery(queryOptions), {
    skip,
  });
