import { SUPPLIER_KYC_RESULT } from '@/pages/orderReview/models/Supplier';
import { Routes } from '@/types';
import { mapValues } from 'lodash';
import { ROUTES } from './routes';

const SUPPLIERS_ROOT = ROUTES.suppliers;

export const SUPPLIERS_ROUTES: Routes = Object.freeze({
  root: '',
  ':id': `:id`,
});

export const SUPPLIERS_PATHS = mapValues(
  SUPPLIERS_ROUTES,
  (route) => `${SUPPLIERS_ROOT}/${route}`
);

export const COUNTRIES_WITH_STATES_OR_PROVINCES = ['us', 'ca', 'mx'];
export const RISK_LEVEL_OPTIONS = ['Low', 'Medium', 'High'];

export enum SuppliersScreeningResultLabel {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export const suppliersScreeningResultFilterOptions = [
  {
    label: SuppliersScreeningResultLabel.APPROVED,
    value: SUPPLIER_KYC_RESULT.APPROVED,
  },
  {
    label: SuppliersScreeningResultLabel.REJECTED,
    value: SUPPLIER_KYC_RESULT.DISAPPROVED,
  },
];
