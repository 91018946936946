import { AutoOrderReviewResponse } from '@/types';
import {
  Alert,
  AlertTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import AlertIcon from '@treyd-io/treyd-ui/icons/AlertIcon';
import CheckTwoIcon from '@treyd-io/treyd-ui/icons/CheckTwoIcon';
import { includes, isEmpty, map } from 'lodash';

const ORDER_CHECKS_MAP = [
  {
    key: 'verified_supplier',
    label: 'Verified supplier bank account',
  },
  {
    key: 'invoice_due_date_match',
    label: 'Invoice due date',
  },
  {
    key: 'total_amount_match',
    label: 'Total amount',
  },
  {
    key: 'total_and_due_amount_match',
    label: 'Total and due amount match',
  },
  {
    key: 'currency_match',
    label: 'Currency',
  },
  {
    key: 'has_weapons',
    label: 'No weapons',
  },
  {
    key: 'has_restricted_product',
    label: 'No restricted product',
  },
  {
    key: 'banking_data_match',
    label: 'Banking data',
  },
];

export const SupplierInvoice = ({
  autoReviewData,
}: {
  autoReviewData?: AutoOrderReviewResponse;
}) => {
  if (isEmpty(autoReviewData)) {
    return (
      <Alert color="error" icon={<AlertIcon />}>
        <AlertTitle>Could not run AI checks on this order.</AlertTitle>
      </Alert>
    );
  }

  return (
    <Stack gap={3}>
      <Typography variant="h5">Supplier invoice</Typography>
      {autoReviewData?.is_auto_approval ? (
        <Alert color="success">
          <AlertTitle>Order can be auto approved.</AlertTitle>
        </Alert>
      ) : (
        <Alert color="error" icon={<AlertIcon />}>
          <AlertTitle>Order cannot be approved automatically.</AlertTitle>
        </Alert>
      )}
      {
        <TableContainer
          sx={{
            border: 1,
            borderColor: 'other.outlined_border',
            borderRadius: 4,
          }}>
          <Table>
            <TableBody>
              {map(ORDER_CHECKS_MAP, (row) => {
                const hasFailed = includes(
                  autoReviewData?.failed_checks_keys,
                  row.key
                );
                return (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': {
                        borderBottom: 0,
                      },
                    }}>
                    <TableCell width={250}>
                      <Typography variant="subtitle2">{row.label}</Typography>
                    </TableCell>
                    <TableCell color="error.main"></TableCell>
                    <TableCell padding="checkbox">
                      {hasFailed ? (
                        <AlertIcon sx={{ color: 'error.main', margin: 1 }} />
                      ) : (
                        <CheckTwoIcon
                          sx={{ color: 'success.main', margin: 1 }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Stack>
  );
};
