export enum PERMISSION_GROUPS_NAMES {
  PAYMENTS = 'Payments',
  COMPANY_ADMIN = 'Company Admin',
  EMPLOYEE = 'Employee',
  CREDIT_ANALYST = 'Credit Analyst',
  CREDIT_OFFICER = 'Credit Officer',
  TREYD_OPERATOR = 'Treyd Operator',
  COMPLIANCE_OFFICER = 'Compliance Officer',
  FUNDING_OFFICER = 'Finance',
  TREYD_CUSTOMER = 'Treyd Customer',
  TREYD_FINANCIER = 'Treyd Financier',
  SALES = 'Sales',
  TECH_SUPPORT = 'Tech Support',
  PRICING_ADMIN = 'Pricing Admin',
}
