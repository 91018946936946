import { VoucherStatus, voucherStatusDisplayedValue } from '@/constants';
import { Chip, ChipProps } from '@mui/material';

interface Props extends ChipProps {
  status: VoucherStatus;
}

const chipProps: {
  [key in VoucherStatus]?: ChipProps;
} = {
  [VoucherStatus.APPLIED]: {
    label: voucherStatusDisplayedValue[VoucherStatus.APPLIED],
    variant: 'standard',
    color: 'success',
  },
  [VoucherStatus.CANCELLED]: {
    label: voucherStatusDisplayedValue[VoucherStatus.CANCELLED],
    variant: 'standard',
    color: 'error',
  },
};

export const VoucherStatusChip = (props: Props) => {
  const { status, ...rest } = props;

  return <Chip {...chipProps[status]} {...rest} />;
};
