import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { map } from 'lodash';

export const InvoicingEmailsSection = ({
  invoicingEmails,
}: {
  invoicingEmails: string[];
}) => {
  const theme = useTheme();
  return (
    <Stack direction="column" gap={3}>
      <Typography variant="h6">Invoicing emails</Typography>
      <TableContainer
        sx={{
          border: `${theme.spacing(0.125)} solid`,
          borderRadius: theme.spacing(1),
          borderColor: theme.palette.divider,
        }}>
        <Table aria-label="simple table">
          <TableBody>
            {map(invoicingEmails, (email, index) => (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: 0,
                  },
                }}>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" color="text.secondary">
                    Recipient {index + 1}
                  </Typography>
                </TableCell>
                <TableCell align="left">{email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
