import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import EditIcon from '@treyd-io/treyd-ui/icons/EditIcon';
import { useToggle } from 'react-use';

import { Supplier } from '@/schemas';
import { EditSupplierInfo } from './EditSupplierInfo';

export const SupplierInformationSection = ({
  supplier,
}: {
  supplier: Supplier;
}) => {
  const theme = useTheme();
  const [isOpen, toggle] = useToggle(false);
  return (
    <Stack direction="column" gap={2}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Typography variant="h5">Supplier information</Typography>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<EditIcon />}
          onClick={toggle}>
          Edit
        </Button>
      </Box>
      <Box
        borderRadius={4}
        border={`${theme.spacing(0.125)} solid`}
        borderColor={theme.palette.divider}
        padding={3}>
        <Row columnSpacing={{ xs: 2, md: 5 }} rowSpacing={3}>
          <Col md={3} xs={12}>
            <TwoLines
              label={'Legal name'}
              content={supplier?.legal_name || 'N/A'}
            />
          </Col>
          <Col md={3} xs={12}>
            <TwoLines
              label={'Added'}
              content={
                supplier?.created_at
                  ? formatDate(new Date(supplier?.created_at), 'date')
                  : 'N/A'
              }
            />
          </Col>
          <Col md={3} xs={12}>
            <TwoLines label={'Org #'} content={supplier?.org_no || 'N/A'} />
          </Col>
          <Col md={3} xs={12} sx={{ wordBreak: 'break-all' }}>
            <TwoLines label={'Website'} content={supplier?.website || 'N/A'} />
          </Col>
          <Col
            md={3}
            xs={12}
            title={supplier?.full_address || ''}
            sx={{ wordBreak: 'break-all' }}>
            <TwoLines
              label={'Address'}
              content={
                <Typography
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}>
                  {supplier?.full_address || 'N/A'}
                </Typography>
              }
            />
          </Col>
          <Col md={3} xs={12}>
            <TwoLines label={'City'} content={supplier?.city || 'N/A'} />
          </Col>
          <Col md={3} xs={12}>
            <TwoLines
              label={'Zip code'}
              content={supplier?.zip_code || 'N/A'}
            />
          </Col>
          <Col md={3} xs={12}>
            <TwoLines
              label={'Country'}
              content={getCountryName(supplier?.country as string) || 'N/A'}
            />
          </Col>
          <Col md={3} xs={12}>
            <TwoLines
              label={'State or province'}
              content={supplier?.state_or_province || 'N/A'}
            />
          </Col>
        </Row>
      </Box>
      <EditSupplierInfo isOpen={isOpen} onClose={toggle} supplier={supplier} />
    </Stack>
  );
};
