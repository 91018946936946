import { gql, TypedDocumentNode } from '@apollo/client';
import {
  Lager_Treyd_Users,
  Orders_Orders as Orders,
  Orders_Orders_App_Activitylog,
  Signatures,
} from '__generated__/graphql';

type GetOrderActivityLog = {
  orders_orders_by_pk: Pick<Orders, 'created'> & {
    agreement: {
      signatures: Array<
        Pick<Signatures, 'signed_at'> & {
          user: Pick<Lager_Treyd_Users, 'name'>;
        }
      >;
    };
  };
  orders_orders_app_activitylog: Array<
    Pick<
      Orders_Orders_App_Activitylog,
      | 'id'
      | 'created'
      | 'modified'
      | 'type'
      | 'loggable_id'
      | 'loggable_type'
      | 'data'
    > & {
      user: Pick<Lager_Treyd_Users, 'user_id' | 'first_name' | 'last_name'>;
    }
  >;
};

type QueryVariables = { order_id: number | undefined };

export const getOrderActivityLog = gql`
  query getAllActivityLogs($order_id: bigint!) {
    orders_orders_by_pk(id: $order_id) {
      created
      agreement {
        signatures {
          signed_at
          user {
            name
          }
        }
      }
    }
    orders_orders_app_activitylog(
      where: {
        loggable_type: { _eq: "order" }
        loggable_id: { _eq: $order_id }
      }
    ) {
      id
      created
      modified
      type
      loggable_id
      loggable_type
      data
      user {
        user_id
        first_name
        last_name
      }
    }
  }
` as TypedDocumentNode<GetOrderActivityLog, QueryVariables>;
