import { Button } from '@mui/material';
import { downloadFile } from '@treyd-io/core/utils/file';
import ArrowRightIcon from '@treyd-io/treyd-ui/icons/ArrowRightIcon';

export const ExportMT103 = ({ mt103 }: { mt103: string }) => {
  const handleExportMT103 = () => {
    const blob = new Blob([mt103], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    downloadFile(url, 'MT103.txt');
  };

  return (
    <Button
      sx={{ alignSelf: 'flex-start' }}
      startIcon={<ArrowRightIcon />}
      color="info"
      onClick={handleExportMT103}>
      Export MT103 details
    </Button>
  );
};
