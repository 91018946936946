import { getTransactionsQuery } from '@/schemas';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetTransactions = ({
  queryOptions,
  skip,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(getTransactionsQuery(queryOptions), {
    skip,
  });
