import { getOrderVisualizationDiagram } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetOrderLogsVisualization = (orderId: number) => {
  return useQuery({
    queryKey: ['orderVisualization', orderId],
    queryFn: () => getOrderVisualizationDiagram(orderId),
    enabled: !!orderId,
  });
};
