import {
  Alert,
  AlertTitle,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

export const IndicativeLimitAlert = ({ sx }: { sx?: SxProps<Theme> }) => {
  return (
    <Alert severity={'info'} sx={sx}>
      <AlertTitle>Indicative limit and pricing</AlertTitle>
      <Stack gap={0.5}>
        <Typography variant="body2">
          The indicative limit and pricing are preliminary. The final credit
          limit and pricing depends on completing the full onboarding process.
        </Typography>
      </Stack>
    </Alert>
  );
};
