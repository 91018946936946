import { Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { format, isValid } from 'date-fns';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
interface DateRangeFieldProps {
  name: string;
  label: string;
}

export const DateRangeField = (props: DateRangeFieldProps) => {
  const { name, label } = props;
  const formik = useFormikContext();
  const startDateFieldName = `${name}Start`;
  const startDate = get(formik.values, startDateFieldName);
  const isStartDateTouched = get(formik.touched, startDateFieldName);
  const startDateError = get(formik.errors, startDateFieldName);

  const endDateFieldName = `${name}End`;
  const endDate = get(formik.values, endDateFieldName);
  const isEndDateTouched = get(formik.touched, endDateFieldName);
  const endDateError = get(formik.errors, endDateFieldName);

  return (
    <Stack gap={1}>
      <Typography variant="subtitle1">{label}</Typography>
      <Row>
        <Col xs={12} sm={6}>
          <DatePicker
            views={['year', 'month', 'day']}
            sx={{ width: '100%' }}
            label={'Start date'}
            value={startDate ? new Date(startDate) : null}
            onChange={(date, context) => {
              formik.setFieldValue(
                startDateFieldName,
                isValid(date) && isEmpty(context.validationError)
                  ? format(date as Date, 'yyyy-MM-dd')
                  : '',
                true
              );
            }}
            slotProps={{
              field: { clearable: true },
              textField: {
                name: startDateFieldName,
                onBlur: formik.handleBlur,
                error: isStartDateTouched && Boolean(startDateError),
                helperText: isStartDateTouched && startDateError,
              },
            }}
          />
        </Col>
        <Col xs={12} sm={6}>
          <DatePicker
            views={['year', 'month', 'day']}
            sx={{ width: '100%' }}
            label={'End date'}
            value={endDate ? new Date(endDate) : null}
            onChange={(date, context) => {
              formik.setFieldValue(
                endDateFieldName,
                isValid(date) && isEmpty(context.validationError)
                  ? format(date as Date, 'yyyy-MM-dd')
                  : '',
                true
              );
            }}
            slotProps={{
              field: { clearable: true },
              textField: {
                name: endDateFieldName,
                onBlur: formik.handleBlur,
                error: isEndDateTouched && Boolean(endDateError),
                helperText: isEndDateTouched && endDateError,
              },
            }}
          />
        </Col>
      </Row>
    </Stack>
  );
};
