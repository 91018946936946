import codes from 'country-calling-code';
import countries from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';
import { CountryCode } from 'libphonenumber-js';
import { find, lowerCase, toString } from 'lodash';

import { SIGN_METHOD } from '../constants/auth';
import {
  COUNTRIES_OPTIONS,
  COUNTRY_SIGN_METHODS,
  NORDIC_COUNTRIES,
} from '../constants/country';
import { validatePersonalNumber } from './personalNumber';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
countries.registerLocale(enCountries);

export const getCountryAuthMethods = (
  country: string,
  user?: { identity_verified: boolean; identity_reference: string }
): string[] => {
  if (
    country === 'se' &&
    user?.identity_verified &&
    !validatePersonalNumber(user?.identity_reference, 'se').valid
  ) {
    !COUNTRY_SIGN_METHODS['se'].includes(SIGN_METHOD.OTP) &&
      COUNTRY_SIGN_METHODS['se'].push(SIGN_METHOD.OTP);
  }
  return COUNTRY_SIGN_METHODS[country] || COUNTRY_SIGN_METHODS.default;
};

export const getCountryName = (countryCode: string) =>
  find(COUNTRIES_OPTIONS, (country) => country.value === lowerCase(countryCode))
    ?.title || '';

export const isRegulatedCountry = (country: string) =>
  NORDIC_COUNTRIES.includes(country);

export const getCountryCodeFromName = (countryName: string | null = '') =>
  toString(
    countries.getAlpha2Code(toString(countryName), 'en')
  ).toLocaleLowerCase();

export const getCountryNameFromCode = (countryCode: string | null = '') =>
  toString(countries.getName(toString(countryCode), 'en'));

export const getCountryCodeFromPhoneCode = (phoneCode: string | null = '') =>
  find(codes, (code) => code.countryCodes[0] === phoneCode)?.isoCode2 as
    | CountryCode
    | undefined;

export const doesCountryHasBankId = (country: string) =>
  NORDIC_COUNTRIES.includes(country);

export const isEuMember = (country: string) => {
  const sepaCountries = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'LV',
    'LI',
    'LT',
    'LU',
    'MT',
    'MC',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'SM',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'GB',
    'VA',
  ];
  return sepaCountries.includes(country.toUpperCase());
};
