import { PayoutStatus } from '@/pages/orderReview/models/Order';
import { PayoutStatusMapper } from '@/services';
import { Chip, ChipProps } from '@mui/material';
interface Props extends ChipProps {
  status: PayoutStatus;
}
export const PayoutStatusChip = ({ status, ...rest }: Props) => {
  const payoutChip = new PayoutStatusMapper(status).getStatus();

  return (
    <Chip
      label={payoutChip?.chipLabel}
      color={payoutChip?.chipColor}
      {...rest}
    />
  );
};
