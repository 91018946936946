import { OCR_FIELD_TITLE } from '@/constants';
import { OcrField, Routes } from '@/types';
import { mapValues } from 'lodash';
import { ROUTES } from './routes';

export const ORDER_FIELDS: OcrField[] = [
  {
    title: OCR_FIELD_TITLE.IMPORTER,
    ocrKeyPath: 'buyer',
    dataKeyPath: 'importer.name',
  },
  {
    title: OCR_FIELD_TITLE.SUPPLIER_INVOICE_REFERENCE,
    ocrKeyPath: 'invoice_id',
    dataKeyPath: 'invoice_ref',
  },
  {
    title: OCR_FIELD_TITLE.SUPPLIER,
    ocrKeyPath: 'supplier_name',
    dataKeyPath: 'exporter.name',
  },
  {
    title: OCR_FIELD_TITLE.COUNTRY,
    ocrKeyPath: 'supplier_country',
    dataKeyPath: 'exporter.country',
  },
  {
    title: OCR_FIELD_TITLE.ADDRESS,
    ocrKeyPath: 'supplier_address',
    dataKeyPath: 'exporter.full_address',
  },
  {
    title: OCR_FIELD_TITLE.DUE_DATE,
    ocrKeyPath: 'invoice_due_date',
    dataKeyPath: 'due_date',
  },
];

export const EXPORTER_BANK_ACCOUNT_FIELDS: OcrField[] = [
  {
    title: OCR_FIELD_TITLE.ACCOUNT_NUMBER,
    ocrKeyPath: 'account_number',
    dataKeyPath: '',
  },
  {
    title: OCR_FIELD_TITLE.BANKGIRO_NUMBER,
    ocrKeyPath: 'bankgiro',
    dataKeyPath: '',
  },
  {
    title: OCR_FIELD_TITLE.ACCOUNT_NAME,
    ocrKeyPath: 'account_name',
    dataKeyPath: 'bank_account_holder_name',
  },
  {
    title: OCR_FIELD_TITLE.BANK_NAME,
    ocrKeyPath: 'bank_name',
    dataKeyPath: 'bank_name',
  },
  {
    title: OCR_FIELD_TITLE.SWIFT_CODE,
    ocrKeyPath: 'swift_code',
    dataKeyPath: 'bic_swift',
  },
  {
    title: OCR_FIELD_TITLE.BANK_ADDRESS,
    ocrKeyPath: 'bank_address',
    dataKeyPath: 'bank_address',
  },
  {
    title: OCR_FIELD_TITLE.SORT_CODE,
    ocrKeyPath: 'sort_code',
    dataKeyPath: 'sort_code',
  },
];

export const AMOUNTS_FIELDS: OcrField[] = [
  {
    title: OCR_FIELD_TITLE.TOTAL_AMOUNT,
    ocrKeyPath: 'total_amount',
    dataKeyPath: 'value',
  },
  {
    title: OCR_FIELD_TITLE.CURRENCY,
    ocrKeyPath: 'currency',
    dataKeyPath: 'currency',
  },
  {
    title: OCR_FIELD_TITLE.TREYD_PAYOUT_AMOUNT,
    ocrKeyPath: 'due_amount',
    dataKeyPath: 'treyd_payment_to_supplier',
  },
];

const ORDER_REVIEW_ROOT = ROUTES.orderReview;

export const ORDER_REVIEW_ROUTES: Routes = Object.freeze({
  ':id': `:id`,
});

export const ORDER_REVIEW_PATHS = mapValues(
  ORDER_REVIEW_ROUTES,
  (route) => `${ORDER_REVIEW_ROOT}/${route}`
);

export enum ORDER_REVIEW_VERDICT {
  SEND_BACK = 'send_back',
  REJECT = 'reject',
  APPROVE = 'approve',
}

export enum ORDER_EVENT {
  CANCEL = 'cancel',
  SEND_BACK = 'send_back',
  COMPLIANCE_APPROVE = 'compliance_approve',
}
