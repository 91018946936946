import { BANK_STATEMENT_DOCUMENTS_TAGS } from '@/constants';
import { useDeleteAttachment, useUpsertAttachment } from '@/hooks';
import { Stack, Typography } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { Attachment } from '@treyd-io/treyd-ui/types/file';
import { Dictionary } from 'lodash';
import { UploadCard } from './UploadCard';

interface BankStatementSectionProps {
  attachments: Dictionary<Attachment[]>;
  orgNo: string;
  country: string;
  onFileUploadComplete: () => void;
}

export const BankStatementSection = (props: BankStatementSectionProps) => {
  const { attachments, orgNo, country, onFileUploadComplete } = props;
  const [uploadAttachment, { loading: isLoading }] = useUpsertAttachment();
  const [deleteAttachment] = useDeleteAttachment();
  const showToastMessage = useToastNotification();

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="h6">Bank statement</Typography>
        <Typography variant="body2">
          To optimize the business analysis, we need the last 3 months bank
          statements. We also highly recommend to connect the bank account to
          Treyd instead.
        </Typography>
      </Stack>
      <UploadCard
        title={'Bank statement files'}
        subtitle={'The latest three months'}
        files={attachments[BANK_STATEMENT_DOCUMENTS_TAGS.LAST_THREE_MONTHS]}
        isLoading={isLoading}
        onDelete={(id) =>
          id &&
          deleteAttachment({
            variables: {
              id,
            },
            onCompleted: onFileUploadComplete,
            onError: () => {
              showToastMessage({
                message: 'Something went wrong',
                type: 'error',
              });
            },
          })
        }
        uploadFile={(file) => {
          const reader = new FileReader();
          reader.onload = () => {
            uploadAttachment({
              variables: {
                country: country,
                dataurl: reader?.result,
                description: '',
                filename: file?.name,
                orgnr: orgNo,
                tag: BANK_STATEMENT_DOCUMENTS_TAGS.LAST_THREE_MONTHS,
              },
              onCompleted: onFileUploadComplete,
              onError: () => {
                showToastMessage({
                  message: 'Something went wrong',
                  type: 'error',
                });
              },
            });
          };
          reader.readAsDataURL?.(file);
        }}
      />
    </Stack>
  );
};
