import { SupplierCompliance } from '@/types';
import { Box, Stack, Typography } from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { UploadButton } from '@treyd-io/treyd-ui/components/Upload/UploadButton';
import { isEmpty, map } from 'lodash';
import { ComplianceAttachment } from './ComplianceAttachment';

interface ComplianceAttachmentsSectionProps {
  supplierKyc?: SupplierCompliance.SupplierKyc;
  onUploadClick: (file: File) => void;
  attachments: SupplierCompliance.Attachment[];
  onRemoveAttachmentComplete: () => void;
  isUploading: boolean;
}

export const ComplianceAttachmentsSection = (
  props: ComplianceAttachmentsSectionProps
) => {
  const {
    onUploadClick,
    supplierKyc,
    attachments,
    isUploading,
    onRemoveAttachmentComplete,
  } = props;
  const isDraft = supplierKyc?.status === 'Draft' || isEmpty(supplierKyc);

  return (
    <Stack gap={2}>
      <Row alignItems="center">
        <Col xs={6}>
          <Typography variant="h6">Supplier screening attachments</Typography>
        </Col>
        <Col xs={6} justifyContent="flex-end" display="flex">
          {(isDraft || isEmpty(supplierKyc)) && (
            <UploadButton
              files={attachments}
              uploadFile={onUploadClick}
              isLoading={isUploading}
              title={'Supplier screening attachments'}
              text="Upload"
              isError={false}
              fullWidth={false}
              acceptsImages={true}
            />
          )}
        </Col>
      </Row>
      {isEmpty(attachments) ? (
        <NoAttachmentsPlaceholder />
      ) : (
        <Row spacing={{ xs: 2, md: 3 }}>
          {map(attachments, (attachment) => (
            <Col xs={12} md={6} lg={4} key={attachment.id}>
              <ComplianceAttachment
                attachment={attachment}
                onRemoveComplete={onRemoveAttachmentComplete}
                isDraftScreening={isDraft}
              />
            </Col>
          ))}
        </Row>
      )}
    </Stack>
  );
};

const NoAttachmentsPlaceholder = () => (
  <Box
    border={1}
    borderColor="grey.200"
    borderRadius={2}
    display="flex"
    justifyContent="center"
    alignItems="center"
    paddingY={8}
    bgcolor="grey.50">
    <Typography variant="body1" color="text.disabled">
      No attachments
    </Typography>
  </Box>
);
