import {
  AUTH_LAST_REFRESHED_AT,
  AUTH_TOKEN_KEY,
  MAX_TIME_OF_INACTIVITY,
  REFRESH_KEY,
} from '../constants/settings';

export const getAuthLastRefreshedInSeconds = (): number => {
  const lastRefreshAt = localStorage.getItem(AUTH_LAST_REFRESHED_AT);
  if (!lastRefreshAt) return 0;
  const lastRefreshAtDate = new Date(lastRefreshAt);
  const now = new Date();
  const diff = now.getTime() - lastRefreshAtDate.getTime();
  const diffInSeconds = diff / 1000;
  return diffInSeconds;
};

export const getAuthExpired = (): boolean => {
  //if last refresh at is more than 5 min ago then return true
  const lastRefreshAt = localStorage.getItem(AUTH_LAST_REFRESHED_AT);
  if (!lastRefreshAt) return !localStorage.getItem(REFRESH_KEY);
  const diffInSeconds = getAuthLastRefreshedInSeconds();
  return diffInSeconds > MAX_TIME_OF_INACTIVITY;
};

export const getJWT = () => {
  return {
    accessToken: localStorage.getItem(AUTH_TOKEN_KEY) || '',
    refreshToken: localStorage.getItem(REFRESH_KEY) || '',
  };
};

export const clearJWT = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_KEY);
  localStorage.removeItem(AUTH_LAST_REFRESHED_AT);
};

export const setJWT = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(AUTH_TOKEN_KEY, `Bearer ${accessToken}`);
  localStorage.setItem(REFRESH_KEY, refreshToken);
  localStorage.setItem(AUTH_LAST_REFRESHED_AT, new Date().toISOString());
};

export const getExpiredTimeFromToken = (token: string) => {
  const claims = atob(token.split('.')[1]);
  return parseInt(JSON.parse(claims).exp) * 1000;
};
