import { Button } from '@mui/material';
import { useState } from 'react';

export default function ExpandableCell({ value }: { value: string }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      {expanded ? value : value?.slice(0, 200)}&nbsp;
      {value?.length > 200 && (
        <Button
          size="small"
          color="info"
          type="button"
          sx={{ fontSize: 'inherit' }}
          onClick={() => setExpanded(!expanded)}>
          {expanded ? 'view less' : 'view more'}
        </Button>
      )}
    </div>
  );
}
