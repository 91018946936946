import {
  BLACK_COMPANY_AGREEMENTS,
  EU_COMPANY_AGREEMENTS,
  LATEST_BLACK_COMPANY_AGREEMENT_VERSION,
  TOS,
  UK_COMPANY_AGREEMENTS,
} from '@/constants';
import { NORDIC_COUNTRIES } from '@treyd-io/core/constants/country';
import { find } from 'lodash';

export namespace ConsentService {
  export const getTOS = () => {
    return TOS;
  };

  export const getCompanyAgreementLink = (
    country: string,
    version_number?: number
  ) => {
    if (!version_number) return null;
    if (version_number === LATEST_BLACK_COMPANY_AGREEMENT_VERSION) {
      return find(BLACK_COMPANY_AGREEMENTS, { version: version_number });
    }
    if (NORDIC_COUNTRIES.includes(country)) {
      return find(EU_COMPANY_AGREEMENTS, { version: version_number });
    }
    return find(UK_COMPANY_AGREEMENTS, { version: version_number });
  };
}
