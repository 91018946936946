import { useGetSupplierDetails } from '@/hooks';

export const useExporter = (
  exporter_id: number,
  { include_bank_data }: any
) => {
  const { data, refetch, loading } = useGetSupplierDetails({
    exporter_id,
    include_bank_data: !!include_bank_data,
  });
  return { data: data?.orders_suppliers_by_pk, refetch, loading };
};
