import { MAPPED_ORDER_STATUS } from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Companies,
  Orders_Orders_Aggregate,
  Orders_Suppliers,
  Orders_Suppliers_Aggregate,
} from '__generated__/graphql';

export type Supplier = Pick<
  Orders_Suppliers,
  | 'id'
  | 'created_at'
  | 'name'
  | 'country'
  | 'exempted_from_inspections'
  | 'kyc'
  | 'legal_name'
  | 'is_monitored'
> & {
  company: Pick<Orders_Companies, 'name'>;
  all_orders: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  paid_orders: Pick<Orders_Orders_Aggregate, 'aggregate'>;
};

type GetSuppliersQueryTypes = {
  orders_suppliers: Supplier[];
  orders_suppliers_aggregate: Pick<Orders_Suppliers_Aggregate, 'aggregate'>;
};

export const getSuppliersQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
    query getSuppliers {
        orders_suppliers ${queryParams} {
          id
          name
          legal_name
          country
          created_at
          exempted_from_inspections
          kyc
          is_monitored
          company {
            name
          }
          all_orders: orders_aggregate {
          aggregate {
            count
          }
        }
          paid_orders: orders_aggregate(where: {mapped_status: {_in: [
            ${MAPPED_ORDER_STATUS.PAID}, 
            ${MAPPED_ORDER_STATUS.INVOICE_OPEN}, 
            ${MAPPED_ORDER_STATUS.INVOICE_OVERDUE}, 
            ${MAPPED_ORDER_STATUS.INVOICE_COLLECTION}, 
            ${MAPPED_ORDER_STATUS.CLOSED}]}}) {
            aggregate {
              count
            }
          }
      }
        orders_suppliers_aggregate ${
          whereClause ? `(where: ${whereClause})` : ''
        } {
          aggregate {
            count
          }
        }
      }
  ` as TypedDocumentNode<GetSuppliersQueryTypes>;
};
