import {
  Invitation,
  Merchant,
  OnboardingStatuses,
  OnboardingStep,
} from '@/types';
import { Link, Typography } from '@mui/material';
import { map } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StepAccordion } from './StepAccordion';
import { StepTimeline } from './StepTimeline';
import {
  getAccountCreationStepStatus,
  getAccountCreationStepTimeline,
  getCompanyFinancialsStepTimeline,
  getKycStepStatus,
  getKycStepTimeline,
  getUboInvitationStatus,
  getUboInvitationTimeline,
} from './utils';

export const OnboardingSteps = (props: {
  merchant: Merchant;
  onboardingStatuses: OnboardingStatuses;
  uboInvitations?: Invitation[];
}) => {
  const { merchant } = props;
  const [expandedStep, setExpandedStep] = useState<number>(0);
  const STEPS: OnboardingStep[] = [
    {
      title: 'Account creation',
      status: getAccountCreationStepStatus(merchant),
      details: (
        <StepTimeline
          timelineStations={getAccountCreationStepTimeline(merchant)}
        />
      ),
      description:
        'This step involves the creation of an account for both the first user in a company and information about the merchant.',
    },
    {
      title: 'Company financials',
      status:
        props.onboardingStatuses?.financial_data_completed ||
        props.onboardingStatuses?.is_financial_documents_added ||
        props.onboardingStatuses?.erp_connected
          ? 'completed'
          : 'not_started',
      details: (
        <StepTimeline
          timelineStations={getCompanyFinancialsStepTimeline(
            merchant,
            props.onboardingStatuses
          )}
        />
      ),
      description:
        'This step involves the connection of the merchant’s ERP system and with an option to upload financial documents as an alternative. The merchant’s bank account can also be optionally connected.',
    },
    {
      title: 'KYC form',
      status: getKycStepStatus(merchant),
      details: merchant?.kyc?.status && (
        <StepTimeline timelineStations={getKycStepTimeline(merchant)} />
      ),
      description:
        'The KYC form consists of 4 sections divided into: company information, signers, owners, operations. The user is yet to start this step.',
    },
    {
      title: 'UBO invitation',
      status: getUboInvitationStatus(
        props.uboInvitations,
        props.onboardingStatuses.is_business_persons_onboarded
      ),
      details: (
        <StepTimeline
          timelineStations={getUboInvitationTimeline({
            merchant,
            uboInvitations: props.uboInvitations,
            isUboOnboarded:
              props.onboardingStatuses.is_business_persons_onboarded,
          })}
        />
      ),
      description: (
        <Typography>
          This step involves the invitation of other UBO to the platform. While
          this step remains undone, you can always view the list of all possible
          UBOs in the list of
          <Link
            component={RouterLink}
            to={'#users'}
            variant="body1"
            marginBottom={0.5}
            marginLeft={0.5}>
            users ↗
          </Link>
        </Typography>
      ),
    },
  ];
  return map(STEPS, (step, index) => {
    const stepNumber = index + 1;
    return (
      <StepAccordion
        key={step.title}
        details={
          step.status === 'not_started' ? step.description : step.details
        }
        title={step.title}
        status={step.status}
        index={stepNumber}
        onChange={() => setExpandedStep(stepNumber)}
        expanded={expandedStep === stepNumber}
      />
    );
  });
};
