import {
  Orders_Companies,
  Unified_Customer_Invoice,
} from '@/__generated__/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';

export type MerchantCustomerErpInvoice = Pick<
  Unified_Customer_Invoice,
  | 'uuid'
  | 'invoice_reference'
  | 'issue_date'
  | 'due_date'
  | 'invoice_status'
  | 'total_amount'
  | 'currency'
  | 'balance'
  | 'customer_name'
>;

export type GetMerchantCustomerErpInvoicesQueryType = {
  orders_companies_by_pk: Pick<Orders_Companies, 'name'> & {
    core_company: {
      erp_connections: Array<{
        integration?: string;
        erp_invoices?: {
          unified_customer_invoices?: MerchantCustomerErpInvoice[];
          unified_customer_invoices_aggregate?: {
            aggregate?: {
              count: number;
            };
          };
        };
      }>;
    };
  };
};

export const getMerchantCustomerErpInvoicesQuery = (
  queryOptions?: QueryOptions
) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);
  return gql`
  query getMerchantCustomerErpInvoicesQuery($companyId: bigint!) {
		orders_companies_by_pk(id: $companyId) {
			name
			core_company {
				erp_connections(where: {is_active: {_eq: true}}) {
					integration
					erp_invoices {
						unified_customer_invoices ${queryParams} {
							uuid
							invoice_reference
							issue_date
							due_date
							invoice_status
							total_amount
							currency
							balance
							customer_name
						}
						unified_customer_invoices_aggregate ${
              whereClause ? `(where: ${whereClause})` : ''
            } {
							aggregate {
								count
							}
						}
					}
				}
			}
		}
}
` as TypedDocumentNode<
    GetMerchantCustomerErpInvoicesQueryType,
    { companyId: number }
  >;
};
