import {
  BANK_FORM_FIELDS,
  FIELD_TITLE,
  NO_BANK_NAME_METHODS,
} from '@/constants/bank';
import {
  BankFieldsType,
  BeneficiaryRequirementsType,
  PaymentTypeValue,
} from '@/types/bank';
import { isEuMember } from '@treyd-io/core/utils/country';
import {
  filter,
  first,
  isArray,
  isNull,
  map,
  mapValues,
  merge,
  omit,
  pick,
  pickBy,
  reduce,
  toString,
} from 'lodash';
import * as yup from 'yup';

export class BankFormService {
  private beneficiaryRequirements: BankFieldsType[];
  private currency: string;
  private country: string;
  private isSepaPayment: boolean;
  private requirementCombinations: Partial<BankFieldsType>[];

  constructor({
    beneficiaryRequirements,
    currency,
    country,
  }: {
    beneficiaryRequirements: BeneficiaryRequirementsType[];
    currency: string;
    country: string;
  }) {
    this.beneficiaryRequirements = map(
      beneficiaryRequirements,
      (requirement) => {
        return {
          ...requirement.bank_account,
          combination_id: requirement.combination_id,
        };
      }
    );
    this.currency = currency;
    this.country = country;
    this.isSepaPayment =
      this.currency?.toLowerCase() === 'eur' &&
      !!this.country &&
      isEuMember(this.country.toUpperCase());
    this.requirementCombinations = this.getPaymentRequirements(
      this.isSepaPayment
    );
  }

  public getPaymentRequirements = (
    isSepaPayment: boolean
  ): Partial<Record<BANK_FORM_FIELDS, string | string[]>>[] => {
    return isSepaPayment
      ? filter(
          this.beneficiaryRequirements,
          (requirement) => requirement.payment_type === 'regular'
        ) || this.beneficiaryRequirements
      : this.beneficiaryRequirements;
  };

  private getUniqueRequirement = (
    requirementCombination: Partial<Record<BANK_FORM_FIELDS, string | string[]>>
  ): string => {
    const requirementMapper: Partial<Record<BANK_FORM_FIELDS, string>> = {
      [BANK_FORM_FIELDS.sortCode]: 'Sort Code',
      [BANK_FORM_FIELDS.clabe]: 'CLABE',
      [BANK_FORM_FIELDS.cnaps]: 'CNAPS',
      [BANK_FORM_FIELDS.aba]: 'ABA',
      [BANK_FORM_FIELDS.iban]: 'IBAN',
      [BANK_FORM_FIELDS.acctNumber]: 'Account Number',
    };
    const requirementOrder: BANK_FORM_FIELDS[] = [
      BANK_FORM_FIELDS.sortCode,
      BANK_FORM_FIELDS.clabe,
      BANK_FORM_FIELDS.cnaps,
      BANK_FORM_FIELDS.aba,
      BANK_FORM_FIELDS.iban,
      BANK_FORM_FIELDS.acctNumber,
    ];

    for (const key of requirementOrder) {
      if (requirementCombination[key]) {
        return requirementMapper[key] || '';
      }
    }
    return '';
  };

  private getUniquePaymentType = (
    requirementCombination: Partial<Record<BANK_FORM_FIELDS, string | string[]>>
  ) => {
    const hasUniqueRequirement = this.getUniqueRequirement(
      requirementCombination
    );

    const labelMapper: Record<PaymentTypeValue | string, string> = {
      regular: this.isSepaPayment ? 'SEPA' : 'Local',
      priority: `SWIFT ${
        hasUniqueRequirement ? `(${hasUniqueRequirement})` : ''
      }`,
      bankgiro: 'Bankgiro',
      plusgiro: 'Plusgiro',
      fallback: '',
    };

    return labelMapper[
      toString(requirementCombination.payment_type) || 'fallback'
    ];
  };

  public getPaymentTypes = () => {
    return map(this.requirementCombinations, (requirementCombination) => {
      return {
        value: requirementCombination.payment_type,
        label: this.getUniquePaymentType(requirementCombination),
        combinationId: requirementCombination.combination_id,
      };
    });
  };

  public getBankFields = (paymentType: string): Partial<BankFieldsType> => {
    const isNoBankNameMethod = NO_BANK_NAME_METHODS.includes(paymentType);

    const fieldsToOmit: BANK_FORM_FIELDS[] = isNoBankNameMethod
      ? [
          BANK_FORM_FIELDS.bankCountry,
          BANK_FORM_FIELDS.bankName,
          BANK_FORM_FIELDS.bankAddress,
          BANK_FORM_FIELDS.accountCurrency,
          BANK_FORM_FIELDS.bankCountryCode,
          BANK_FORM_FIELDS.paymentType,
          BANK_FORM_FIELDS.combinationId,
        ]
      : [
          BANK_FORM_FIELDS.bankCountry,
          BANK_FORM_FIELDS.accountCurrency,
          BANK_FORM_FIELDS.bankCountryCode,
          BANK_FORM_FIELDS.paymentType,
          BANK_FORM_FIELDS.combinationId,
        ];

    return reduce(
      this.requirementCombinations,
      (
        acc: Record<string, Partial<BankFieldsType>>,
        requirement: Partial<BankFieldsType>
      ) => {
        const newRequirement = pickBy(omit(requirement, fieldsToOmit));

        acc[this.getUniquePaymentType(requirement)] = newRequirement;
        return acc;
      },
      {}
    )[paymentType];
  };

  private createValidationSchema = () =>
    reduce(
      this.requirementCombinations,
      (
        acc: Record<string, RegExp>,
        requirement: Partial<Record<BANK_FORM_FIELDS, string | string[]>>
      ) => {
        omit(requirement, [
          BANK_FORM_FIELDS.paymentType,
          BANK_FORM_FIELDS.combinationId,
        ]);
        merge(acc, requirement);
        return acc;
      },
      {}
    );

  public getValidationSchema = (fields?: BANK_FORM_FIELDS[]) => {
    const validationSchema = this.createValidationSchema();

    const requiredFields = fields && pick(validationSchema, fields);

    return mapValues(requiredFields, (regex, key) => {
      if (isArray(regex)) {
        return isNull(first(regex))
          ? yup.string().optional().oneOf(regex)
          : yup.string().required().oneOf(regex);
      }
      return yup
        .string()
        .required(`${FIELD_TITLE[key]} is required`)
        .matches(regex, 'Validation error');
    });
  };
}
