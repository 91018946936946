import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';

export interface UserInvitationFormValues {
  email: string;
}
interface InviteUserModalProps {
  isOpen: boolean;
  onCancel: () => void;
  loading: boolean;
  onConfirm: (values: UserInvitationFormValues) => void;
}

const InviteUserModal = (props: InviteUserModalProps) => {
  const { isOpen, onCancel, onConfirm, loading } = props;
  const initialValues: UserInvitationFormValues = {
    email: '',
  };

  const validate = (values: UserInvitationFormValues) => {
    const errors: FormikErrors<UserInvitationFormValues> = {};

    if (!values.email) {
      errors.email = 'Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => onConfirm(values),
  });

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Invite new user</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1.5),
          })}>
          <DialogContentText>
            An email will be sent with an invite to create and verify a new
            account.
          </DialogContentText>
          <TextField
            fullWidth
            placeholder="Email address"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && !!formik.errors.email}
            helperText={
              !formik.touched.email &&
              'Invites can only be sent to company emails.'
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel()} variant="outlined" size="large">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={loading}>
            {loading ? <CircularProgress size={20} /> : 'Send invite'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InviteUserModal;
