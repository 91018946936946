import { canDoEventOnOrder } from '@/api';
import { OrderEventPayload } from '@/types';
import { useQuery } from '@tanstack/react-query';

export const useCanDoEventOnOrder = (
  options: OrderEventPayload,
  enabled = true
) =>
  useQuery({
    queryKey: ['canDoEventOnOrder', options?.id],
    queryFn: () => canDoEventOnOrder(options),
    enabled: enabled && !!options?.id,
    select: (data) => data?.data,
    refetchOnWindowFocus: false,
    retry: 2,
  });
