import { DateRangeField } from '@/components/TableFilter/DateRangeField';
import { EmailsTableFilters, EmailsTableFiltersFields } from '@/types';
import { getFiltersInitialValues } from '@/utils/TableFilters';
import { Badge, Button, Menu } from '@mui/material';
import {
  FilterComparator,
  QueryFilterField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import FiltersIcon from '@treyd-io/treyd-ui/icons/FiltersIcon';
import { Form, Formik } from 'formik';
import { toString } from 'lodash';
import { useState } from 'react';

interface TableFiltersProps {
  onSubmit: (filters: QueryFilterField<EmailsTableFiltersFields>[]) => void;
  onReset?: () => void;
  filters?: QueryFilterField<EmailsTableFiltersFields>[];
}

export const TableFilters = (props: TableFiltersProps) => {
  const { onSubmit, filters, onReset } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClose = () => setAnchorEl(null);
  const appliedFilterFieldsCount = filters?.length || 0;

  const initialValues = getFiltersInitialValues<EmailsTableFilters>(filters, {
    creationDateEnd: toString,
    creationDateStart: toString,
  });

  const handleFormSubmit = (values: EmailsTableFilters) => {
    const { creationDateStart, creationDateEnd } = values;
    const filters: QueryFilterField<EmailsTableFiltersFields>[] = [];

    if (creationDateStart) {
      filters.push({
        key: 'creationDateStart',
        type: 'filter',
        name: 'created',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: creationDateStart,
      });
    }

    if (creationDateEnd) {
      filters.push({
        key: 'creationDateEnd',
        type: 'filter',
        name: 'created',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: creationDateEnd,
      });
    }

    onSubmit(filters);
    handleMenuClose();
  };

  return (
    <>
      <Badge
        badgeContent={appliedFilterFieldsCount}
        color="primary"
        sx={{ width: '100%' }}>
        <Button
          startIcon={<FiltersIcon />}
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          Filters
        </Button>
      </Badge>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormSubmit}>
        {(formik) => {
          return (
            <Menu
              keepMounted
              disablePortal
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => formik.handleSubmit()}
              sx={{}}
              slotProps={{
                paper: {
                  elevation: 2,
                  sx: {
                    padding: 2,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: 420,
                    backgroundColor: 'background.default',
                  },
                },
              }}>
              <Form
                noValidate
                tabIndex={-1}
                onKeyDown={(e) => e.stopPropagation()}>
                <Row tabIndex={-1}>
                  <Col xs={12}>
                    <DateRangeField label="Creation date" name="creationDate" />
                  </Col>
                  <Col xs={12} gap={1} display="flex">
                    <Button
                      fullWidth
                      onClick={() => {
                        onReset?.();
                      }}
                      variant="outlined"
                      type="button"
                      color="secondary">
                      Reset
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary">
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Menu>
          );
        }}
      </Formik>
    </>
  );
};
