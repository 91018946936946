import { IconButton, SvgIconProps } from '@mui/material';
import { toString } from 'lodash';
import React, { ComponentProps } from 'react';
import { useCopyToClipboard } from 'react-use';
import { ToastOptions } from '../context/toastContext';
import { useToastNotification } from '../hooks/useToastNotification';
import CopyIcon from '../icons/CopyIcon';
export const CopyButton = ({
  value,
  buttonProps,
  iconProps,
  toastOptions = {
    type: 'info',
    title: 'Copied to clipboard',
  },
}: {
  value: string | number;
  toastOptions?: ToastOptions;
  iconProps?: SvgIconProps;
  buttonProps?: ComponentProps<typeof IconButton>;
}) => {
  const [, copyToClipboard] = useCopyToClipboard();
  const notification = useToastNotification();

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    copyToClipboard(toString(value));
    notification(toastOptions);
    buttonProps?.onClick?.(e);
  };

  return (
    <IconButton
      sx={{ padding: 0 }}
      {...buttonProps}
      onClick={handleOnClick}
      disableRipple>
      <CopyIcon {...iconProps} />
    </IconButton>
  );
};
