import { updateOcrFieldMutation } from '@/schemas';
import { MappedOcrFields } from '@/types';
import { getMappedOcrFields } from '@/utils';
import { useMutation } from '@apollo/client';
import { find, isEmpty } from 'lodash';
import { useGetOrderInvoiceOcr } from './useGetOrderInvoiceOcr';

export const useOrderInvoiceOcr = (
  id?: number
): {
  loading: boolean;
  data: MappedOcrFields | null;
  handleOcrFieldUpdate: (
    ocrSectionPath: string,
    ocrKeyPath: string,
    updatedData: any
  ) => Promise<void>;
} => {
  const { data: orderOcrData, loading } = useGetOrderInvoiceOcr({
    order_id: id,
  });
  const latestActiveOcr = find(
    orderOcrData?.orders_invoice,
    (orderInvoice) => orderInvoice.is_active
  );
  const ocrData = latestActiveOcr?.ocr_data;
  const [updateOcrField] = useMutation(updateOcrFieldMutation);

  const handleOcrFieldUpdate = async (
    ocrSectionPath: string,
    ocrKeyPath: string,
    updatedData: any
  ) => {
    await updateOcrField({
      variables: {
        ocr_id: latestActiveOcr?.id,
        section_name: ocrSectionPath,
        field: ocrKeyPath,
        updated_data: updatedData,
      },
    });
  };

  return {
    data: loading || isEmpty(ocrData) ? null : getMappedOcrFields(ocrData),
    loading,
    handleOcrFieldUpdate,
  };
};
