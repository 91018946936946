import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { useGetCompanyFinanciers } from '@/hooks';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import { isEmpty, map, startCase, toNumber } from 'lodash';
import { EmptySection } from './EmptySection';

export const FinanciersSection = ({ companyId }: { companyId: number }) => {
  const { data: financiers, isPending } = useGetCompanyFinanciers(companyId);

  if (isPending)
    return (
      <Stack justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );

  return (
    <Stack gap={2}>
      <Typography variant="h5" color="text.primary">
        Financiers
      </Typography>
      {isEmpty(financiers) ? (
        <EmptySection
          title="No selected financier"
          subtitle="The selected financier will display here"
        />
      ) : (
        <Stack
          border={1}
          borderColor="other.outlined_border"
          borderRadius={4}
          padding={3}
          gap={3}>
          {map(financiers, (financier) => {
            return (
              <Stack gap={1}>
                <Typography variant="subtitle1" color="text.primary">
                  {`${startCase(
                    financier?.financier_priority?.toLowerCase()
                  )}: ${financier?.financier_name}`}
                </Typography>
                <Row>
                  <Col xs={12} sm={6} md={3}>
                    <TwoLines
                      label="Limit"
                      content={
                        formatCurrency(
                          toNumber(financier?.limit?.limit),
                          financier?.limit?.limit_currency
                        ) || '-'
                      }
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <TwoLines
                      label="Outstanding credit"
                      content={
                        formatCurrency(
                          toNumber(financier?.limit?.outstanding_limit),
                          financier?.limit?.limit_currency
                        ) || '-'
                      }
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <TwoLines
                      label="Remaining credit"
                      content={
                        formatCurrency(
                          toNumber(financier?.limit?.new_remaining),
                          financier?.limit?.limit_currency
                        ) || '-'
                      }
                    />
                  </Col>
                </Row>
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
