import { ComponentLoader } from '@/components/ComponentLoader';
import Row from '@/components/Grid/Row';
import { useBrowsingHistory } from '@/hooks';
import { useGetEmailLogDetails } from '@/hooks/useGetEmailDetails';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import ArrowLeftIcon from '@treyd-io/treyd-ui/icons/ArrowLeftIcon';
import {
  isNumber,
  lowerCase,
  map,
  startCase,
  toNumber,
  toString,
} from 'lodash';
import { useParams } from 'react-router-dom';

export const EmailDetails = () => {
  const { goBack } = useBrowsingHistory();
  const params = useParams();
  const emailLogId = toNumber(params.email_id);
  const { data, loading } = useGetEmailLogDetails(emailLogId);
  const emailLog = data?.orders_notification_center_emaillog_by_pk;
  if (loading) return <ComponentLoader />;

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Button
          variant="text"
          color="info"
          startIcon={<ArrowLeftIcon />}
          sx={{ width: 'max-content' }}
          onClick={goBack}>
          Back
        </Button>

        <Typography variant={'h4'}>Email #{emailLogId}</Typography>
      </Stack>

      <Stack
        borderRadius={4}
        border={1}
        borderColor={'other.outlined_border'}
        padding={{ xs: 3, md: 4 }}
        gap={4}>
        <Stack gap={3}>
          <Typography variant="h6">Email details</Typography>
          <Row>
            <Col xs={12} sm={3}>
              <TwoLines
                label="Creation date"
                content={
                  emailLog?.created
                    ? formatDate(new Date(emailLog?.created), 'date')
                    : '-'
                }
                contentProps={{
                  noWrap: true,
                }}
              />
            </Col>
            <Col xs={12} sm>
              <TwoLines
                label="Template"
                content={
                  startCase(lowerCase(toString(emailLog?.template?.name))) ||
                  '-'
                }
                contentProps={{
                  noWrap: true,
                }}
              />
            </Col>
            <Col xs={12} sm={3}>
              <TwoLines
                label="First opened at"
                content={
                  emailLog?.first_opened_at
                    ? formatDate(
                        new Date(emailLog?.first_opened_at),
                        'datetime'
                      )
                    : '-'
                }
                contentProps={{
                  noWrap: true,
                }}
              />
            </Col>
            <Col xs={12} sm={3}>
              <TwoLines
                label="Last opened at"
                content={
                  emailLog?.last_opend_at
                    ? formatDate(new Date(emailLog?.last_opend_at), 'datetime')
                    : '-'
                }
                contentProps={{
                  noWrap: true,
                }}
              />
            </Col>
            <Col xs={12} sm={3}>
              <TwoLines
                label="Open count"
                content={
                  isNumber(emailLog?.open_count) ? emailLog?.open_count : '-'
                }
                contentProps={{
                  noWrap: true,
                }}
              />
            </Col>
          </Row>
        </Stack>
        <Divider />
        <Stack gap={3}>
          <Typography variant="h6">Variables</Typography>
          <Stack gap={2}>
            {map(emailLog?.payload, (value, key) => (
              <TwoLines
                key={key}
                label={startCase(key)}
                content={value}
                contentProps={{
                  maxWidth: 1000,
                }}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
