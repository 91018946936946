import {
  BANK_FORM_FIELDS,
  DEFAULT_COUNTRY,
  DEFAULT_CURRENCY,
  PAYMENT_TYPE_MAPPER,
} from '@/constants/bank';

import { useUpsertBankAccount } from '@/hooks';
import { UpsertBankAccountMutationType } from '@/schemas';
import { BankDialogFormData } from '@/types/bank';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { Formik, FormikHelpers } from 'formik';
import { isEmpty, toString, toUpper } from 'lodash';
import { ReactNode, useState } from 'react';
import * as yup from 'yup';
import { BankForm } from './BankForm';

export const AddBankAccount = ({
  entityCountry,
  entity,
  entityId,
  title,
  isDialog,
  onSuccess,
}: {
  entityCountry: string;
  entity: 'supplier' | 'company';
  entityId?: string;
  title?: ReactNode;
  isDialog?: boolean;
  onSuccess: () => void;
}) => {
  const toast = useToastNotification();
  const [validationSchema, setValidationSchema] = useState<{
    [x: string]: yup.StringSchema<string, yup.AnyObject, undefined, ''>;
  }>({});
  const [upsertBankAccount, { loading }] = useUpsertBankAccount();

  const initialValues = {
    [BANK_FORM_FIELDS.currency]: toUpper(DEFAULT_CURRENCY),
    [BANK_FORM_FIELDS.bankCountry]: entityCountry || DEFAULT_COUNTRY,
    [BANK_FORM_FIELDS.bankName]: '',
    [BANK_FORM_FIELDS.bankAddress]: '',
    [BANK_FORM_FIELDS.paymentType]: '',
  };

  const VALIDATION_SCHEMA = yup.object({
    payment_type: yup.string().required('Payment type is required'),
    ...validationSchema,
  });

  const handleSubmit = (
    values: BankDialogFormData,
    formik: FormikHelpers<BankDialogFormData>
  ) => {
    upsertBankAccount({
      variables: {
        bank_account_data: {
          ...values,
          payment_type: PAYMENT_TYPE_MAPPER[values?.payment_type],
          [entity]: toString(entityId),
        },
      },
      onCompleted: (data: UpsertBankAccountMutationType) => {
        const bankAccountCreated =
          data?.django?.upsert_bank_account?.bank_account;

        const allBankAccountErrors =
          data.django?.upsert_bank_account?.errors?.all?.bank_account;
        const currentCombinationBankAccountErrors =
          data.django?.upsert_bank_account?.errors?.[values.combination_id]
            ?.bank_account;

        const bankAccountErrors = {
          ...allBankAccountErrors,
          ...currentCombinationBankAccountErrors,
        };

        const entityErrors =
          data?.django?.upsert_bank_account?.errors?.[values.combination_id]
            ?.beneficiary;

        if (!isEmpty(bankAccountCreated)) {
          toast({ message: 'Bank added successfully', type: 'success' });
          onSuccess();
          return;
        }

        if (!isEmpty(bankAccountErrors)) {
          formik.setErrors({
            ...bankAccountErrors,
          });
        }
        !isEmpty(entityErrors) &&
          toast({
            title: 'Invalid supplier details',
            type: 'error',
          });
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formik) => handleSubmit(values, formik)}
      validationSchema={VALIDATION_SCHEMA}>
      <BankForm
        isDialog={isDialog}
        entityCountry={entityCountry}
        loading={loading}
        title={title}
        setValidationSchema={setValidationSchema}
      />
    </Formik>
  );
};
