import { getRemainingLimit } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { toNumber } from 'lodash';

export const useGetRemainingLimit = (companyId: number) => {
  return useQuery({
    queryKey: ['remaining-limit', companyId],
    queryFn: () => getRemainingLimit(toNumber(companyId)),
    enabled: !!companyId,
    refetchOnWindowFocus: false,
    select: (data) => data.data,
  });
};
