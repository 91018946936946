import { getSimilarBankAccounts } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { toNumber } from 'lodash';

export const useGetSimilarBankAccounts = (supplierId: number) => {
  return useQuery({
    queryKey: ['similarBankAccounts', supplierId],
    queryFn: () => getSimilarBankAccounts(toNumber(supplierId)),
    enabled: !!supplierId,
    refetchOnWindowFocus: false,
    select: (data) => data.data,
  });
};
