import {
  Box,
  Stack,
  StackProps,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { IllustrationStars } from './IllustrationStars';
interface TableHasNoRowsPlaceholderProps {
  title?: string;
  subtitle?: string;
  iconProps?: SvgIconProps;
  containerProps?: StackProps;
  contentBoxProps?: StackProps;
}

export const TableHasNoRowsPlaceholder = (
  props: TableHasNoRowsPlaceholderProps
) => {
  const { title, subtitle, iconProps, containerProps, contentBoxProps } = props;
  return (
    <Stack
      alignItems="center"
      textAlign="center"
      height="100%"
      py={12}
      justifyContent="center"
      {...containerProps}>
      <Stack
        justifyContent="center"
        alignItems="center"
        height="100%"
        gap={6}
        {...contentBoxProps}>
        <Box>
          <IllustrationStars
            style={{ fill: 'none' }}
            sx={{
              color: 'info.main',
              width: 95,
              height: 108,
            }}
            viewBox="0 0 95 108"
            {...iconProps}
          />
        </Box>
        <Stack gap={0.5}>
          <Typography variant="h6" color="text.primary">
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
