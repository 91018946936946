import { Lager_Latest_Published_Credit_Decision } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

export type getPublishedCreditDecisionQueryTypes = {
  lager_core_company: {
    latest_published_credit_decision: Pick<
      Lager_Latest_Published_Credit_Decision,
      | 'approved_limit'
      | 'published_at'
      | 'approved_rating'
      | 'expiration_date'
      | 'approved_limit_currency'
    >;
  }[];
};
type getCreditDecisionQueryVariables = {
  orgnr?: string;
  country?: string;
};

export const getPublishedCreditDecisionData = gql`
  query getPublishedCreditDecision($orgnr: String!, $country: String!) {
    lager_core_company(
      where: { orgnr: { _eq: $orgnr }, country: { _eq: $country } }
    ) {
      latest_published_credit_decision {
        approved_limit
        published_at
        approved_rating
        expiration_date
        approved_limit_currency
      }
    }
  }
` as TypedDocumentNode<
  getPublishedCreditDecisionQueryTypes,
  getCreditDecisionQueryVariables
>;
