'use client';
import { Box, CircularProgress } from '@mui/material';

interface Props {
  progressSize?: number;
}
export const ComponentLoader = (props: Props) => {
  return (
    <Box
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%">
      <CircularProgress
        size={props.progressSize || 60}
        sx={{ color: 'info.main' }}
      />
    </Box>
  );
};
