import { SearchField } from '@/components';
import { ComponentLoader } from '@/components/ComponentLoader';
import { ExportOrdersButton } from '@/components/Orders/ExportOrdersButton';
import { OrderType } from '@/constants';
import { useGetAllFinanciers } from '@/hooks';
import useSearchTerm from '@/hooks/useSearchTerm';
import { OrdersTableFiltersFields } from '@/types';
import { Stack } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QueryFilterField,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { isEmpty, isNaN, toNumber, toString } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { OrdersTable } from './OrdersTable';
import { TableFilters } from './TableFilters';

export const Orders = () => {
  const { id } = useParams();
  const companyId =
    isNaN(toNumber(id)) || isEmpty(id) ? undefined : toNumber(id);

  const { searchTerm, setSearchTerm } = useSearchTerm('ordersSearchTerm');
  const [filterFields, setFilterFields] = useState<
    QueryFilterField<OrdersTableFiltersFields>[]
  >([]);

  const { data, loading: isAllFinanciersLoading } = useGetAllFinanciers();
  const allFinanciers = data?.orders_financiers;

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(toString(searchTerm).trim())
          ? null
          : toNumber(searchTerm),
    },
  ];
  const filters: Filter[] = [
    ...searchFields,
    ...filterFields,
    {
      type: 'filter',
      name: 'importer__id',
      comparator: FilterComparator.EQUAL,
      comparisonValue: companyId,
    },
    {
      type: 'filter',
      name: 'type',
      comparator: FilterComparator.EQUAL,
      comparisonValue: OrderType.PAYABLE,
    },
  ];

  if (isAllFinanciersLoading) return <ComponentLoader />;

  return (
    <Stack gap={3} height="100%" flexGrow={1}>
      <Row>
        <Col xs={12} sm>
          <SearchField
            placeholder={'Search by order #'}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Col>
        <Col xs={12} sm="auto">
          <TableFilters
            onSubmit={setFilterFields}
            onReset={() => setFilterFields([])}
            filters={filterFields}
            allFinanciers={allFinanciers}
          />
        </Col>
        <Col xs={12} sm="auto">
          <ExportOrdersButton filters={filters} />
        </Col>
      </Row>
      <OrdersTable filters={filters} />
    </Stack>
  );
};
