import { FiscalYearSubtitle } from '@/components';
import { PROFIT_AND_LOSS_DOCUMENTS_TAGS } from '@/constants';
import { useDeleteAttachment, useUpsertAttachment } from '@/hooks';
import { Stack, Typography } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { Attachment } from '@treyd-io/treyd-ui/types/file';
import { Dictionary, map } from 'lodash';
import { UploadCard } from './UploadCard';

interface ProfitAndLossSectionProps {
  attachments: Dictionary<Attachment[]>;
  orgNo: string;
  country: string;
  onFileUploadComplete: () => void;
}

export const ProfitAndLossSection = (props: ProfitAndLossSectionProps) => {
  const { attachments, orgNo, country, onFileUploadComplete } = props;
  const [uploadAttachment, { loading: isLoading }] = useUpsertAttachment();
  const [deleteAttachment] = useDeleteAttachment();
  const showToastMessage = useToastNotification();

  const PL_SECTIONS = [
    {
      title: 'Interim reports: current year and same period last year',
      subtitle: <FiscalYearSubtitle />,
      tag: PROFIT_AND_LOSS_DOCUMENTS_TAGS.PROFIT_INTERIM_REPORT,
    },
    {
      title: 'Last fiscal year annual report',
      subtitle: 'Your most recent fiscal year end report.',
      tag: PROFIT_AND_LOSS_DOCUMENTS_TAGS.LAST_FISCAL_YEAR,
    },
    {
      title: 'Net sales',
      subtitle: 'Full fiscal year and interim report, current and last year',
      tag: PROFIT_AND_LOSS_DOCUMENTS_TAGS.NET_SALES,
      hidden: country !== 'dk',
    },
  ];
  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="h6">Profit and loss statements</Typography>
        <Typography variant="body2">
          profit and loss statements will help us understand the revenues,
          costs, and expenses incurred by the business.
        </Typography>
      </Stack>
      {map(
        PL_SECTIONS,
        (section, index) =>
          !section.hidden && (
            <UploadCard
              key={index}
              title={section.title}
              subtitle={section.subtitle}
              files={attachments[section.tag]}
              isLoading={isLoading}
              onDelete={(id) =>
                id &&
                deleteAttachment({
                  variables: {
                    id,
                  },
                  onCompleted: onFileUploadComplete,
                  onError: () => {
                    showToastMessage({
                      message: 'Something went wrong',
                      type: 'error',
                    });
                  },
                })
              }
              uploadFile={(file) => {
                const reader = new FileReader();
                reader.onload = () => {
                  uploadAttachment({
                    variables: {
                      country: country,
                      dataurl: reader?.result,
                      description: '',
                      filename: file?.name,
                      orgnr: orgNo,
                      tag: section.tag,
                    },
                    onCompleted: onFileUploadComplete,
                    onError: () => {
                      showToastMessage({
                        message: 'Something went wrong',
                        type: 'error',
                      });
                    },
                  });
                };
                reader.readAsDataURL?.(file);
              }}
            />
          )
      )}
    </Stack>
  );
};
