import { BankAccountCompliance } from '@/types';
import {
  Alert,
  AlertTitle,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import ResponsiveButton from '@treyd-io/treyd-ui/components/ResponsiveButton';
import MinusCircleIcon from '@treyd-io/treyd-ui/icons/MinusCircleIcon';
import { isEmpty, map } from 'lodash';
import { ReviewBankAccountDialog } from './ReviewBankAccountDialog';

export interface AccountInfoRow {
  label: string;
  value: string;
}

interface BankAccountInfoCardProps {
  title?: string;
  accountInfo?: AccountInfoRow[];
  isActive?: boolean;
  bankAccountId: number;
  bankAccountCompliance: BankAccountCompliance;
  onBankAccountReviewSubmit: () => void;
  onDeactivateClick?: () => void;
  errors?: string[];
  awx_errors?: any;
}

export const BankAccountInfoCard = (props: BankAccountInfoCardProps) => {
  const {
    onDeactivateClick,
    onBankAccountReviewSubmit,
    title,
    accountInfo,
    isActive,
    bankAccountId,
    bankAccountCompliance,
    errors,
    awx_errors,
  } = props;

  return (
    <Stack
      gap={2}
      border={1}
      borderRadius={2}
      borderColor="other.outlined_border"
      padding={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{title}</Typography>
        <Stack direction="row" gap={1}>
          <ReviewBankAccountDialog
            bankAccountId={bankAccountId}
            bankAccountCompliance={bankAccountCompliance}
            onSubmit={onBankAccountReviewSubmit}
          />
          <ResponsiveButton
            color="error"
            onClick={onDeactivateClick}
            disabled={!isActive}
            buttonProps={{
              children: 'Deactivate',
              startIcon: <MinusCircleIcon />,
            }}
            iconButtonProps={{
              children: <MinusCircleIcon />,
            }}
          />
        </Stack>
      </Stack>
      <Stack gap={1}>
        {map(
          accountInfo,
          (row) =>
            row.value && (
              <Stack gap={1} direction="row">
                <Typography variant="body2" color="text.secondary">
                  {row.label}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {row.value}
                </Typography>
              </Stack>
            )
        )}
        {awx_errors && (
          <Stack gap={1} width={420}>
            <Typography variant="body2" color="text.secondary">
              {'AWX Beneficiary Errors: '}
            </Typography>
            <Typography variant="body2" color="text.primary">
              <pre
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                {JSON.stringify(awx_errors, null, 2)}
              </pre>
            </Typography>
          </Stack>
        )}
      </Stack>
      {!isEmpty(errors) && (
        <Alert severity={'error'}>
          <AlertTitle>Bank account information needs attention</AlertTitle>
          <List
            sx={{
              listStyleType: 'disc',
              pl: 2,
            }}>
            {errors?.map((error) => (
              <ListItem
                sx={{
                  display: 'list-item',
                  py: 0,
                  px: 1,
                }}
                key={error}>
                {error}
              </ListItem>
            ))}
          </List>
        </Alert>
      )}
      {bankAccountCompliance && (
        <Alert severity={bankAccountCompliance?.approved ? 'success' : 'error'}>
          <AlertTitle>
            {bankAccountCompliance?.approved ? 'Approved' : 'Rejected'}
          </AlertTitle>
          {bankAccountCompliance?.reason}
        </Alert>
      )}
    </Stack>
  );
};
