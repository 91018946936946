import { useAddOrderComment, useLogglyLoggingService } from '@/hooks';
import { useNotifyNordeaTag } from '@/hooks/useNotifyNordeaTag';
import ActivityLogCard from '@/pages/orderReview/OrderReviewDetails/ActivityLogCard';
import CommentForm from '@/pages/orderReview/OrderReviewDetails/CommentForm';
import { ADDITIONAL_TAGS } from '@/types';
import { Button, Stack, Typography } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import InboxIcon from '@treyd-io/treyd-ui/icons/InboxIcon';
import PlusIcon from '@treyd-io/treyd-ui/icons/PlusIcon';
import { useAuth } from 'auth';
import { LOGGING_MESSAGES } from 'constants/loggly';
import { AbilityContext } from 'context';
import { includes, some } from 'lodash';
import { useContext } from 'react';
import { useToggle } from 'react-use';
import { AdditionalTagsDialog } from './AdditionalTagsDialog';

export const ActivityLogSection = ({
  orderId,
  logs,
  disableAdditionalTags,
}: {
  orderId: number;
  logs: any;
  disableAdditionalTags?: boolean;
}) => {
  const { activityLogs, refetch } = logs;
  const [isCommentBoxOpen, toggleCommentBox] = useToggle(false);
  const [isAdditionalTagsDialogOpen, toggleAdditionalTagsDialog] =
    useToggle(false);
  const { user } = useAuth();
  const { rules } = useContext(AbilityContext);
  const canDeleteComment = some(
    rules,
    (rule) =>
      rule.action === 'delete' && rule.subject === 'order_review_comment'
  );
  const showNotificationMessage = useToastNotification();
  const log = useLogglyLoggingService();
  const { mutate: addOrderComment } = useAddOrderComment();
  const { mutate: notifyNordeaTag } = useNotifyNordeaTag();

  const sendComment = async (comment: string, onSuccess?: () => void) => {
    addOrderComment(
      {
        orderId,
        comment,
      },
      {
        onSuccess: () => {
          log({
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_COMMENT_SUCCESS,
            data: { orderId },
          });
          onSuccess && onSuccess();
          refetch();
        },
        onError: (err) => {
          log({
            type: 'ERROR',
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_COMMENT_FAIL,
            data: { orderId, error: err },
          });
          showNotificationMessage({
            title: 'Something went wrong.',
            type: 'error',
          });
        },
      }
    );
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" gap={1} textAlign="center">
          <InboxIcon color="primary" />
          <Typography variant="subtitle1" color="text.primary">
            Activity
          </Typography>
        </Stack>

        {!Boolean(disableAdditionalTags) && (
          <>
            <Button
              color="info"
              size="small"
              onClick={toggleAdditionalTagsDialog}>
              Additional tag
            </Button>
            <AdditionalTagsDialog
              open={isAdditionalTagsDialogOpen}
              onClose={toggleAdditionalTagsDialog}
              onSubmit={(comment) =>
                sendComment(comment, () => {
                  if (includes(comment, ADDITIONAL_TAGS.NORDEA_PAYMENT)) {
                    notifyNordeaTag(orderId);
                  }
                  toggleAdditionalTagsDialog();
                })
              }
            />
          </>
        )}
      </Stack>
      {!isCommentBoxOpen && (
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          fullWidth
          startIcon={<PlusIcon />}
          onClick={toggleCommentBox}>
          New comment
        </Button>
      )}
      {isCommentBoxOpen && (
        <CommentForm
          onSubmit={({ comment }) => {
            sendComment(comment, toggleCommentBox);
          }}
          onCancel={toggleCommentBox}
        />
      )}
      <Stack gap={1.5}>
        {activityLogs?.map(
          (log: any, idx: number) =>
            log.created && (
              <ActivityLogCard
                key={idx}
                log={log}
                refetch={refetch}
                can_delete={
                  log.type === 'comment' &&
                  (user.id === log?.user?.user_id || canDeleteComment)
                }
              />
            )
        )}
      </Stack>
    </Stack>
  );
};
