import { Orders_Vouchers_App_Voucher } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

export type Voucher = Pick<
  Orders_Vouchers_App_Voucher,
  | 'id'
  | 'order_id'
  | 'status'
  | 'value'
  | 'currency'
  | 'created'
  | 'modified'
  | 'slug'
>;
export type GetCompanyVouchersType = {
  orders_vouchers_app_voucher: Array<Voucher>;

  orders_vouchers_app_voucher_aggregate: {
    aggregate: {
      count: number;
    };
  };
};

export type GetCompanyVouchersVariables = {
  companyId: number;
};

export const getCompanyVouchersQuery = gql`
  query getCompanyVouchers($companyId: bigint!) {
    orders_vouchers_app_voucher(
      where: {
        _and: {
          order: { importer_id: { _eq: $companyId } }
          status: { _neq: "cancelled" }
        }
      }
    ) {
      id
      order_id
      value
      currency
      status
      created
      modified
      slug
    }
    orders_vouchers_app_voucher_aggregate(
      where: { status: { _neq: "cancelled" } }
    ) {
      aggregate {
        count
      }
    }
  }
` as TypedDocumentNode<GetCompanyVouchersType, GetCompanyVouchersVariables>;
