import { Box, Stack, Typography } from '@mui/material';

export const AmlReport = ({
  reportUrl,
  id,
}: {
  reportUrl?: string;
  id: string;
}) => {
  if (!reportUrl) {
    return (
      <Stack direction={'column'} gap={2}>
        <Typography variant={'h5'}>AML assessment</Typography>
        <Box
          paddingY={10}
          paddingX={40}
          borderRadius={2}
          border={1}
          borderColor="grey.300"
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}>
          <Typography variant={'h6'}>No AML Submitted</Typography>
          <Typography
            variant={'body2'}
            color={'text.secondary'}
            whiteSpace={'nowrap'}>
            The AML will be displayed once submitted.
          </Typography>
        </Box>
      </Stack>
    );
  }
  return (
    <Stack direction={'column'} gap={2}>
      <Stack gap={1}>
        <Typography variant={'h5'}>AML assessment</Typography>
        <Typography variant={'body1'}>
          You can review the report from the admin
        </Typography>
      </Stack>
      <Box sx={{ height: '100vh' }}>
        <iframe
          src={reportUrl}
          style={{ width: '100%', height: '100%' }}
          title="AML report"
        />
      </Box>
    </Stack>
  );
};
