import { gql, TypedDocumentNode } from '@apollo/client';

interface EventInvocation {
  ack: boolean;
  handler: {
    id: number;
    name: string;
  };
  id: number;
  payload: any;
  retries: number;
}
export interface Event {
  ack: boolean;
  created: string;
  data: any;
  invocations: EventInvocation[];
  type: string;
  uid: string;
}

interface GetEventsQueryType {
  publisher_events: Event[];
}

export const getEvents = gql`
  query getEvents($data: jsonb!) {
    publisher_events(where: { data: { _contains: $data } }) {
      uid
      data
      type
      ack
      created
      invocations {
        id
        retries
        ack
        payload
        handler {
          id
          name
        }
      }
    }
  }
` as TypedDocumentNode<GetEventsQueryType, { data: any }>;
