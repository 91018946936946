import { useGetSupplierAttachments } from '@/hooks';
import { Stack } from '@mui/material';
import { filter } from 'lodash';

import { Supplier } from '@/schemas';
import { QualityInspectionSection } from './QualityInspectionSection';
import { SupplierInformationSection } from './SupplierInformationSection';

export const DetailsTab = ({ supplier }: { supplier?: Supplier }) => {
  const { data } = useGetSupplierAttachments(supplier?.id);
  const attachments = filter(
    data?.django?.supplier_attachments,
    (attachment) => {
      return attachment?.tag === 'proof_of_payment';
    }
  );
  return (
    supplier && (
      <Stack direction="column" gap={6}>
        <SupplierInformationSection supplier={supplier} />
        <QualityInspectionSection
          supplier={supplier}
          attachments={attachments}
        />
      </Stack>
    )
  );
};
