import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function CheckmarkIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="CheckmarkIcon"
      {...props}>
      <path
        d="M7.75 12.75L10 15.25L16.25 8.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
