import { SendInvite, TableRowType } from '@/types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { toString } from 'lodash';

interface ConfirmResendModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (data: SendInvite.Payload) => void;
  companyId?: string;
  user: TableRowType;
  loading: boolean;
}
const ConfirmResendModal = (props: ConfirmResendModalProps) => {
  const { isOpen, onCancel, onConfirm, user, companyId, loading } = props;

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Resend invitation</DialogTitle>
      <DialogContent
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1.5),
        })}>
        <DialogContentText>
          Are you sure you want to resend invitation to {user?.email}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()} variant="outlined" size="large">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={loading}
          onClick={() =>
            onConfirm({
              email: toString(user?.email),
              company_id: companyId,
            })
          }>
          {loading ? <CircularProgress size={20} /> : 'Resend'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmResendModal;
