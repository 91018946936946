import { PAYMENT_TYPES } from '@/constants';
import { find } from 'lodash';

export const getBankTypeLabel = (paymentType: string) => {
  const paymentTypeObj = find(
    PAYMENT_TYPES,
    (type) => type.value === paymentType
  );
  return paymentTypeObj?.label || '';
};
