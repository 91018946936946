import { SupplierCompliance } from '@/types';
import { Button, Stack, Typography } from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import EditIcon from '@treyd-io/treyd-ui/icons/EditIcon';
import { isEmpty, toString } from 'lodash';
interface ComplianceDetailsSectionProps {
  supplierKyc?: SupplierCompliance.SupplierKyc;
  onEditClick: () => void;
  supplierLegalName?: string;
}
export const ComplianceDetailsSection = (
  props: ComplianceDetailsSectionProps
) => {
  const { onEditClick, supplierKyc, supplierLegalName } = props;
  const isDraft = supplierKyc?.status === 'Draft';

  const complianceDetails = {
    url: toString(supplierKyc?.data?.url),
    riskLevel: toString(supplierKyc?.data?.riskLevel),
    comments: toString(supplierKyc?.data?.comments),
  };

  return (
    <Stack gap={2}>
      <Row alignItems="center">
        <Col xs={6}>
          <Typography variant="h6">Details</Typography>
        </Col>
        <Col xs={6} justifyContent="flex-end" display="flex">
          {(isDraft || isEmpty(supplierKyc)) && (
            <Button
              startIcon={<EditIcon />}
              variant="outlined"
              color="secondary"
              onClick={onEditClick}>
              Edit
            </Button>
          )}
        </Col>
      </Row>
      <Row spacing={{ xs: 2, md: 5 }}>
        <Col xs={12} md>
          <TwoLines label="Legal name" content={supplierLegalName || 'N/A'} />
        </Col>
        <Col xs={12} md>
          <TwoLines label="URL" content={complianceDetails.url || 'N/A'} />
        </Col>
        <Col xs={12} md>
          <TwoLines
            label="Risk level"
            content={complianceDetails.riskLevel || 'N/A'}
          />
        </Col>
        <Col xs={12} md>
          <TwoLines
            label="Comments"
            content={complianceDetails.comments || 'N/A'}
          />
        </Col>
      </Row>
    </Stack>
  );
};
