import { useGetMerchants } from '@/hooks';
import { MerchantsCsvService } from '@/services';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Filter } from '@treyd-io/core/types/hasura';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import ExportIcon from '@treyd-io/treyd-ui/icons/ExportIcon';
import { useState } from 'react';

interface ExportMerchantsButtonProps {
  filters?: Filter[];
  buttonProps?: LoadingButtonProps;
}

export const ExportMerchantsButton = (props: ExportMerchantsButtonProps) => {
  const { buttonProps, filters } = props;
  const [isExporting, setIsExporting] = useState(false);
  const showToastMessage = useToastNotification();
  const merchantsCSVService = new MerchantsCsvService();

  const { refetch: getExportedMerchants } = useGetMerchants({
    queryOptions: {
      filters,
    },
    skip: true,
  });

  const handleExportMerchants = async () => {
    setIsExporting(true);
    const response = await getExportedMerchants();
    const isExported = merchantsCSVService.exportToCsv(
      response.data?.orders_companies
    );
    if (isExported) {
      showToastMessage({
        type: 'success',
        title: 'Merchants exported',
      });
    } else {
      showToastMessage({
        type: 'warning',
        title: 'No merchants found',
      });
    }
    setIsExporting(false);
  };

  return (
    <LoadingButton
      size="large"
      variant="outlined"
      color="secondary"
      loading={isExporting}
      fullWidth
      startIcon={<ExportIcon />}
      onClick={(e) => {
        handleExportMerchants();
        buttonProps?.onClick?.(e);
      }}
      {...buttonProps}>
      Export
    </LoadingButton>
  );
};
