import { getOrdersOperatorsReviewsQuery } from '@/schemas/getOrdersOperatorsReviewsQuery';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetOrdersOperatorsReviews = ({
  queryOptions,
  skip,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(getOrdersOperatorsReviewsQuery(queryOptions), {
    skip,
  });
