import { MERCHANT_SEGMENT } from '@/constants';
import { gql, TypedDocumentNode } from '@apollo/client';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Companies,
  Orders_Financiers,
  Orders_Order_Invoices,
  Orders_Order_Payouts,
  Orders_Orders,
} from '__generated__/graphql';
import { isNaN, toNumber, toString } from 'lodash';

export type OrdersCompany = Pick<
  Orders_Companies,
  | 'id'
  | 'name'
  | 'org_no'
  | 'country'
  | 'financier'
  | 'is_active'
  | 'is_funding_secured'
  | 'rating'
  | 'limit'
  | 'limit_currency'
> & {
  financier: {
    name: string;
  };
  segment: MERCHANT_SEGMENT;
};

export type Order = Pick<
  Orders_Orders,
  'id' | 'created' | 'modified' | 'status' | 'mapped_status'
> & {
  importer: Pick<Orders_Companies, 'id' | 'name' | 'country'> & {
    segment: MERCHANT_SEGMENT;
  };
  financier: Pick<Orders_Financiers, 'id' | 'name'>;
  active_payout: Pick<
    Orders_Order_Payouts,
    | 'expected_payment_date'
    | 'amount'
    | 'currency'
    | 'expected_settlement_date_calc'
    | 'actual_payment_date'
  >;
};

export type Invoice = Pick<
  Orders_Order_Invoices,
  | 'id'
  | 'order_id'
  | 'invoice_number'
  | 'invoice_date'
  | 'invoice_type'
  | 'invoiced_amount'
  | 'invoiced_currency'
  | 'ops_status'
  | 'due_date'
  | 'invoicing_system'
  | 'financier'
> & {
  order: Pick<Orders_Orders, 'financier'> & {
    importer: Orders_Companies & {
      segment: MERCHANT_SEGMENT;
    };
  };
};

type GetGlobalSearchResultsQueryType = {
  orders_companies: OrdersCompany[];
  orders_orders: Order[];
  orders_order_invoices: Invoice[];
};

export const getGlobalSearchResultsQuery = (searchTerm = '') => {
  const comparisonValue = toString(searchTerm).trim();
  const stringComparator = FilterComparator.CASE_INSENSITIVE_LIKE;
  const numberComparator = FilterComparator.EQUAL;

  const companiesFilters: Filter[] = [
    {
      type: 'search',
      name: 'name',
      comparator: stringComparator,
      comparisonValue,
    },
    {
      type: 'search',
      name: 'org_no',
      comparator: stringComparator,
      comparisonValue,
    },
    {
      type: 'search',
      name: 'country',
      comparator: stringComparator,
      comparisonValue,
    },
    {
      type: 'search',
      name: 'financier__name',
      comparator: stringComparator,
      comparisonValue,
    },
    {
      type: 'search',
      name: 'company_users__email',
      comparator: stringComparator,
      comparisonValue,
    },
  ];

  const ordersFilters: Filter[] = [
    {
      type: 'search',
      name: 'id',
      comparator: numberComparator,
      comparisonValue: isNaN(toNumber(comparisonValue))
        ? null
        : toNumber(comparisonValue),
    },
    {
      type: 'search',
      name: 'importer__name',
      comparator: stringComparator,
      comparisonValue,
    },
    {
      type: 'search',
      name: 'financier__name',
      comparator: stringComparator,
      comparisonValue,
    },
  ];

  const invoicesFilter: Filter[] = [
    {
      type: 'search',
      name: 'invoice_number',
      comparator: stringComparator,
      comparisonValue,
    },
    {
      type: 'search',
      name: 'order_id',
      comparator: numberComparator,
      comparisonValue: isNaN(toNumber(comparisonValue))
        ? null
        : toNumber(comparisonValue),
    },
    {
      type: 'search',
      name: 'order__importer__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(comparisonValue).trim(),
    },
  ];

  const { queryParams: companiesQueryParams } = getGqlQueryParams({
    limit: 50,
    filters: companiesFilters,
  });

  const { queryParams: ordersQueryParams } = getGqlQueryParams({
    limit: 50,
    filters: ordersFilters,
  });

  const { queryParams: invoicesQueryParams } = getGqlQueryParams({
    limit: 50,
    filters: invoicesFilter,
  });

  return gql` 
  query getGlobalSearchResults {
      orders_companies ${companiesQueryParams}  {
          id
          name
          org_no
          country
          is_active
          is_funding_secured
          rating
          limit
          limit_currency
          financier {
            name
          }
					segment
        },

      orders_orders ${ordersQueryParams} {
        id
        mapped_status
        active_payout {
          amount
          currency
          expected_payment_date
          expected_settlement_date_calc
          actual_payment_date
        }
        created
        modified
        importer {
          id
          name
          country
					segment
        }
        financier {
          id
          name
        }
      }
      
      orders_order_invoices ${invoicesQueryParams} {
        id
        order_id
        invoice_number
        invoice_date
        invoice_type
        invoiced_amount
        invoiced_currency
        ops_status
        due_date
        invoicing_system
        financier {
          name
        }
        order {
          id
          importer {
            id
            name
            country
						segment
          }
        }
      }
    } ` as TypedDocumentNode<GetGlobalSearchResultsQueryType, {}>;
};
