import SearchField from '@/components/SearchField';
import { TableSorting } from '@/types';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import {
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
} from '@mui/x-data-grid';

//DataGrid should extend MuiDataGrid
interface DataGridProps extends MuiDataGridProps {
  sorting: TableSorting;
  onSortingChange: (newSorting: TableSorting) => void;
  searchTerm?: string;
  setSearchTerm?: (searchTerm: string) => void;
}

export default function DataGrid({
  sorting,
  onSortingChange,
  searchTerm,
  setSearchTerm,
  ...props
}: DataGridProps) {
  return (
    <Stack
      spacing={2}
      sx={{
        width: '100%',
        height: '100%',
        flexGrow: 1,
        flexDirection: 'column',
      }}>
      {setSearchTerm && (
        <Box sx={{ width: '50%' }}>
          <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </Box>
      )}
      <MuiDataGrid
        {...props}
        initialState={{
          sorting: {
            sortModel: [sorting],
          },
        }}
        rowBuffer={props.rowCount}
        onSortModelChange={(sortModel) => {
          if (sortModel.length) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [{ field, sort }, ..._restSortModel] = sortModel;
            if (field && sort) {
              onSortingChange({ field, sort: sort || 'desc' });
            }
          }
        }}
      />
    </Stack>
  );
}

DataGrid.defaultProps = {
  pageSize: 10,
  rowsPerPageOptions: [5, 10, 20, 30],
  paginationMode: 'server',
  sortingMode: 'server',
  checkboxSelection: false,
  disableColumnMenu: true,
  density: 'compact',
};
