import { BANK_FORM_FIELDS, FIELD_TITLE } from '@/constants/bank';
import { useGetBankDetails } from '@/hooks';

import { BankDialogFormData, BankFieldsType } from '@/types/bank';
import { formatErrorMessage, getBankIdentifyingCode } from '@/utils/bank';
import { Autocomplete, SelectChangeEvent, TextField } from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { useFormikContext } from 'formik';
import {
  filter,
  find,
  includes,
  intersection,
  isArray,
  isEmpty,
  isNull,
  keys,
  map,
  reduce,
  startCase,
} from 'lodash';
import { ChangeEvent, FocusEvent } from 'react';

export const BankFields = ({
  bankFields,
}: {
  bankFields: Partial<BankFieldsType>;
}) => {
  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormikContext<BankDialogFormData>();

  const { getBankData, data } = useGetBankDetails();

  const bankData = data?.banking?.bank_details;

  const autoCompleteFields: BANK_FORM_FIELDS[] = [
    BANK_FORM_FIELDS.bankName,
    BANK_FORM_FIELDS.bankAddress,
  ];
  const shouldDisableField = (field: BANK_FORM_FIELDS) =>
    includes(autoCompleteFields, field) && !!bankData?.[field];

  const handleFieldChange = async (
    field: string,
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    await setFieldValue(field, e.target.value);
    setFieldTouched(field, true);
  };

  const handleBankIdentifyingCodeBlur = async (
    field: string,
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    const bankIdentifyingCode = getBankIdentifyingCode({
      ...values,
      [field]: e.target.value,
    });

    if (
      !isEmpty(bankIdentifyingCode) &&
      field === bankIdentifyingCode.identifierType
    ) {
      getBankData({
        variables: {
          ...getBankIdentifyingCode({
            ...values,
            [field]: e.target.value,
          }),
        },
        onCompleted: async (data) => {
          const keysIntersection = intersection(
            keys(bankFields) as BANK_FORM_FIELDS[],
            keys(data?.banking?.bank_details) as BANK_FORM_FIELDS[]
          );

          const newValues = reduce(
            keysIntersection,
            (
              acc: Partial<Record<BANK_FORM_FIELDS, string>>,
              key: BANK_FORM_FIELDS
            ) => {
              if (data?.banking?.bank_details[key]) {
                acc[key] = data?.banking?.bank_details[key];
              }
              return acc;
            },
            {}
          );
          // we have to await here cuz setValues is asynchronous, and overwrites any other state setters in the same scope.
          await setValues((prevValues) => ({
            ...prevValues,
            ...newValues,
          }));
        },
      });
    }
  };

  return (
    <>
      {map(bankFields, (field: string | string[], key: BANK_FORM_FIELDS) => {
        if (isArray(field)) {
          return (
            <Col key={key} xs={12}>
              <Autocomplete
                options={
                  isArray(field) ? filter(field, (item) => !isNull(item)) : []
                }
                value={find(field, (item) => item === values[key]) || null}
                fullWidth
                getOptionLabel={(option) => startCase(option) || ' '}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(e, item) => {
                  setFieldValue(key, item && item ? item : '');
                }}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={FIELD_TITLE[key]}
                    error={touched[key] && Boolean(errors[key])}
                    helperText={touched[key] && errors[key]}
                  />
                )}
              />
            </Col>
          );
        }
        return (
          <Col key={key} xs={12}>
            <TextField
              fullWidth
              name={key}
              label={FIELD_TITLE[key] || key}
              value={values[key] || ''}
              disabled={shouldDisableField(key)}
              required
              error={touched[key] && Boolean(errors[key])}
              helperText={
                touched[key] &&
                errors?.[key] &&
                formatErrorMessage(key, errors?.[key])
              }
              onBlur={(e) => {
                handleBankIdentifyingCodeBlur(key, e);
                handleBlur(e);
              }}
              onChange={(e) => handleFieldChange(key, e)}
            />
          </Col>
        );
      })}
    </>
  );
};
