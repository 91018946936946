import { Box, Button, Stack } from '@mui/material';
import { OrderHeader } from './OrderHeader';

import { ComponentLoader } from '@/components/ComponentLoader';
import { DocumentCardType } from '@/components/DocumentCard';
import NavTabsBar, { TabType } from '@/components/NavTabsBar';
import { useGetOrderDocuments, useOrderById } from '@/hooks';
import { OrderAttachment } from '@/pages/orderReview/models/Order';
import { downloadFile } from '@treyd-io/core/utils/file';
import DownloadIcon from '@treyd-io/treyd-ui/icons/DownloadIcon';
import FileSearchIcon from '@treyd-io/treyd-ui/icons/FileSearchIcon';
import { last, reduce, toString } from 'lodash';
import { useParams } from 'react-router-dom';
import { DetailsTab } from './DetailsTab/DetailsTab';
import { DocumentsTab } from './DocumentsTab';
import { InvoicesTab } from './InvoicesTab';
import { PaymentTrackingTab } from './PaymentTrackingTab';
import { SupplierInvoiceTab } from './SupplierInvoiceTab';

export default function NonDraftOrderView() {
  const { id } = useParams();
  const { data: order, isLoading } = useOrderById(parseInt(id as string));

  const {
    data: orderLoanAgreementsData,
    isPending: isLoadingOrderLoanAgreements,
  } = useGetOrderDocuments(parseInt(id as string));
  const invoiceUrl = order?.supplier_invoice?.invoice?.url;
  const getAttachmentDetails = (
    attachment: OrderAttachment | null
  ): DocumentCardType => {
    return {
      title: toString(attachment?.filename),
      actionButtonsSection: (
        <Box display="flex" justifyContent="space-between">
          <Button
            startIcon={<FileSearchIcon />}
            variant="outlined"
            color="secondary"
            onClick={() => window.open(attachment?.url)}>
            Preview
          </Button>
          <Button
            onClick={() =>
              downloadFile(toString(attachment?.url), attachment?.filename)
            }
            color="info"
            startIcon={<DownloadIcon />}>
            Download
          </Button>
        </Box>
      ),
    };
  };

  const payoutProofOfPayments = reduce(
    order?.payouts,
    (documents: DocumentCardType[], payout) => {
      if (payout.proof_of_payment)
        return [
          ...documents,
          {
            ...getAttachmentDetails(payout?.proof_of_payment),
            title: 'Payout proof of payment',
          },
        ];
      return documents;
    },
    []
  );

  const loanAgreementDocuments = reduce(
    orderLoanAgreementsData,
    (documents: DocumentCardType[], loanAgreement) => {
      return [
        ...documents,
        {
          ...getAttachmentDetails({
            filename: toString(loanAgreement?.title),
            url: toString(loanAgreement?.pdf_url),
          }),
          title: 'Order agreement',
        },
      ];
    },
    []
  );

  const orderSupportingFiles = reduce(
    order?.supporting_files,
    (documents: DocumentCardType[], file) => {
      return [
        ...documents,
        {
          ...getAttachmentDetails(file),
          title: 'Supporting file',
          subtitle: `${last(file.filename.split('/'))}`,
        },
      ];
    },
    []
  );

  const documents = [
    ...payoutProofOfPayments,
    ...loanAgreementDocuments,
    ...orderSupportingFiles,
  ];

  if (isLoading || !order) return <ComponentLoader />;

  const tabs: TabType[] = [
    {
      id: 'details',
      label: 'Details',
      content: <DetailsTab order={order} />,
    },
    {
      id: 'invoices',
      label: 'Invoices',
      content: <InvoicesTab />,
    },
    {
      id: 'supplier_invoice',
      label: 'Supplier invoice',
      content: <SupplierInvoiceTab invoiceUrl={invoiceUrl} />,
    },
    {
      id: 'documents',
      label: 'All documents',
      content: isLoadingOrderLoanAgreements ? (
        <ComponentLoader />
      ) : (
        <DocumentsTab documents={documents} />
      ),
      chipLabel: documents?.length,
    },
    {
      id: 'payment_tracking',
      label: 'Payment tracking',
      content: <PaymentTrackingTab order={order} />,
    },
  ];
  return (
    <Stack flexGrow={1} direction="column" minHeight="100%" gap={1}>
      {id && <OrderHeader id={id} order={order} />}
      <Stack flexGrow={1}>
        <NavTabsBar tabs={tabs} />
      </Stack>
    </Stack>
  );
}
