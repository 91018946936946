import { treydCompanyId } from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { Lager_Treyd_Users } from '__generated__/graphql';

export type TreydUser = Pick<
  Lager_Treyd_Users,
  'id' | 'name' | 'first_name' | 'last_name' | 'email' | 'user_groups'
> & {
  id: number;
  name: string;
};

export type GetTreydUsersQueryType = {
  lager_treyd_users: TreydUser[];
};

export const getTreydUsersQuery = gql`
  query getTreydUsers {
    lager_treyd_users(where: { company_id: { _eq: ${treydCompanyId} } }) {
      id
      name
      first_name
      last_name
      email
      user_groups {
        auth_group {
          name
        }
      }
    }
  }
` as TypedDocumentNode<GetTreydUsersQueryType>;
