import { BANK_ACCOUNT_REVIEW } from '@/constants';
import { BankAccountCompliance, BankAccountReviewPayload } from '@/types';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { save } from '@treyd-io/core/utils/request';

export const submitBankAccountReview = async ({
  bankAccountId,
  payload,
}: {
  bankAccountId: number;
  payload: BankAccountReviewPayload;
}) =>
  save<BankAccountCompliance>(
    BANK_ACCOUNT_REVIEW(bankAccountId),
    payload,
    REQUEST_METHOD.POST
  );
