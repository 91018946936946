import { gql, TypedDocumentNode } from '@apollo/client';

type BankDetailsQueryType = {
  banking: {
    bank_details: { [key: string]: string };
  };
};

type BankDetailsQueryVariables = {
  identifierType: string;
  identifierValue: string;
  bankCountry: string;
  accountNumber: string;
};

export const getBankDetailsQuery = gql`
  query get_bank_details(
    $identifierType: String!
    $identifierValue: String!
    $bankCountry: String!
    $accountNumber: String!
  ) {
    banking {
      bank_details(
        identifier_type: $identifierType
        identifier_value: $identifierValue
        bank_country: $bankCountry
        account_number: $accountNumber
      )
    }
  }
` as TypedDocumentNode<BankDetailsQueryType, BankDetailsQueryVariables>;
