import { gql, TypedDocumentNode } from '@apollo/client';

type DeactivateSupplierBankAccountMutationType = {
  django: {
    deactivate_bank_account: {
      deactivated_at: string;
    };
  };
};

type DeactivateSupplierBankAccountMutationVariablesType = {
  supplierId: number;
  bankAccountId: number;
};

export const deactivateSupplierBankAccountMutation = gql`
  mutation deactivateSupplierBankAccountMutation(
    $supplierId: Int!
    $bankAccountId: Int!
  ) {
    django {
      deactivate_bank_account(
        supplier_id: $supplierId
        bank_account_id: $bankAccountId
      ) {
        deactivated_at
      }
    }
  }
` as TypedDocumentNode<
  DeactivateSupplierBankAccountMutationType,
  DeactivateSupplierBankAccountMutationVariablesType
>;
