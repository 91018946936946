import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';

export const PaymentTrackingEmptyState = () => {
  return (
    <TableHasNoRowsPlaceholder
      title={'Nothing here yet'}
      subtitle={'The payment information will be displayed here.'}
      iconProps={{
        style: { fill: 'none' },
        sx: {
          color: 'info.main',
          width: 54,
          height: 60,
        },
        viewBox: '0 0 95 108',
      }}
      containerProps={{
        border: 1,
        borderColor: 'other.outlined_border',
        borderRadius: 4,
      }}
      contentBoxProps={{
        gap: 4,
      }}
    />
  );
};
