import { ORDER_MAPPED_STATUS_DISPLAYED_VALUE } from '@/constants';
import { Order } from '@/schemas/getOrdersQuery';
import { CSVBuilder } from '@treyd-io/core/services/CsvService';
import { formatDate } from '@treyd-io/core/utils/date';
import { downloadCsvFile } from '@treyd-io/core/utils/downloadCSV';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { isEmpty, map, toString } from 'lodash';
import { MerchantService } from './MerchantService';
export class OrdersCsvService {
  private csvBuilder: CSVBuilder;

  constructor() {
    this.csvBuilder = new CSVBuilder();
  }

  private buildHeaders(): void {
    this.csvBuilder.setHeaders([
      ' ',
      'Order #',
      'Status',
      'Merchant',
      'Merchant status',
      'Financier',
      'Creation date',
      'Expected payment date',
      'Expected repayment date',
      'Actual payment date',
      'Payout amount',
      'Repayment amount',
    ]);
  }

  private formatOrderRow(
    order: Order,
    index: number
  ): (string | number | Date)[] {
    const creationDate = order?.created;
    const expectedPaymentDate = order?.active_payout?.expected_payment_date;
    const expectedRepaymentDate =
      order?.active_payout?.expected_settlement_date_calc;
    const actualPaymentDate = order?.active_payout?.actual_payment_date;
    const payoutAmount = order?.active_payout?.amount;
    const repaymentAmount =
      order?.orders_totals_in_invoicing_currency
        ?.total_amount_inc_vat_in_invoicing_currency;
    const payoutCurrency = toString(order?.active_payout?.currency);
    const invoicingCurrency = toString(order?.invoicing_currency);
    const merchantStatus = MerchantService.getMerchantLimitStatusLabel(
      order?.importer?.is_active,
      order?.importer?.org_no
    );

    return map(
      [
        index + 1,
        toString(order?.id),
        order?.mapped_status
          ? ORDER_MAPPED_STATUS_DISPLAYED_VALUE[order?.mapped_status]
          : '',
        toString(order?.importer?.name),
        merchantStatus,
        toString(order?.financier?.name),
        creationDate ? formatDate(new Date(creationDate), 'date') : '',
        expectedPaymentDate
          ? formatDate(new Date(expectedPaymentDate), 'date')
          : '',
        expectedRepaymentDate
          ? formatDate(new Date(expectedRepaymentDate), 'date')
          : '',
        actualPaymentDate
          ? formatDate(new Date(actualPaymentDate), 'date')
          : '',
        payoutAmount ? formatCurrency(payoutAmount, payoutCurrency) : '',
        repaymentAmount
          ? formatCurrency(repaymentAmount, invoicingCurrency)
          : '',
      ],
      (value) => `"${value}"`
    );
  }

  private buildRows(orders: Order[]): void {
    const formattedRows = map(orders, (order, index) =>
      this.formatOrderRow(order, index)
    );
    this.csvBuilder.addRow(map(formattedRows, (row) => row.join(',')));
  }

  exportToCsv(orders: Order[]) {
    if (isEmpty(orders)) return false;

    this.buildHeaders();
    this.buildRows(orders);

    const csvData = this.csvBuilder.build();
    downloadCsvFile({
      data: csvData,
      fileName: 'Orders.csv',
    });
    return true;
  }
}
