import Col from '@/components/Grid/Col';
import { Form } from '@/components/styled-components/FormStyles';
import { useGetUserProfile } from '@/hooks';
import { LoadingButton } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { Formik } from 'formik';
import { first, isEmpty, toNumber } from 'lodash';
import * as yup from 'yup';
import { EditUserDetails } from './EditUserDetails';

interface Values {
  userId: number | null;
}

export const UserOperations = () => {
  const [getUserProfile, { data, loading: dataLoading }] = useGetUserProfile();

  const userDetails = first(data?.lager_treyd_auth_profile);

  const initialValues: Values = {
    userId: null,
  };

  const VALIDATION_SCHEMA = yup.object().shape({
    userId: yup.number().required('Required'),
  });

  const handleSubmit = (values: Values) => {
    getUserProfile({
      variables: {
        userId: toNumber(values.userId),
      },
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form sx={{ maxWidth: 'max-content' }}>
              <TextField
                id="userId"
                name="userId"
                label="User ID"
                variant="outlined"
                fullWidth
                type="number"
                helperText={touched.userId && Boolean(errors?.userId)}
                error={touched.userId && Boolean(errors?.userId)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userId}
              />

              <LoadingButton
                variant="outlined"
                onClick={() => handleSubmit()}
                loading={dataLoading}>
                Get user details
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Col>
      {userDetails && (
        <Col xs={12}>
          <EditUserDetails details={userDetails} />
        </Col>
      )}
      {isEmpty(userDetails) && (
        <Col xs={12}>
          <Typography variant="h6">No user is found by this ID</Typography>
        </Col>
      )}
    </Row>
  );
};
