import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function RefreshIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="RefreshIcon"
      {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.25 4.75L8.75 7L11.25 9.25"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.75 19.25L15.25 17L12.75 14.75"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.75 7H13.25C16.5637 7 19.25 9.68629 19.25 13V13.25"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.25 17H10.75C7.43629 17 4.75 14.3137 4.75 11V10.75"
      />
    </SvgIcon>
  );
}
