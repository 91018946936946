import { useAuth } from '@/auth';
import { ROUTES } from '@/constants';
import {
  useAddCompany,
  useCreateCoreCompany,
  useDoesCompanyExist,
  useGetHubspotCompanyIds,
  useGetTreydUsers,
  useUpdateCompany,
} from '@/hooks';
import useDebouncedEffect from '@/hooks/useDebouncedEffect';
import { useSearchHubspotCompanies } from '@/hooks/useSearchHubspotCompanies';
import { HubspotIdsResponse } from '@/types';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { COUNTRIES } from '@treyd-io/core/constants/country';
import { sanitizeOrgNumber } from '@treyd-io/core/utils/organization';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import SearchIcon from '@treyd-io/treyd-ui/icons/SearchIcon';
import { useFormik } from 'formik';
import { isEmpty, map, omit, toNumber } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

export interface AddCompanyDialogProps {
  open: boolean;
  onClose: () => void;
}

interface AddCompanyFormValues {
  country: string;
  orgNo: string;
  hubspotId?: string;
  salesRepresentativeId: number;
}

export const AddCompanyDialog = (props: AddCompanyDialogProps) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [hubspotCompanies, setHubspotCompanies] =
    useState<HubspotIdsResponse | null>(null);

  useDebouncedEffect(
    () => {
      searchHubspotCompany(searchTerm || '');
    },
    [searchTerm],
    500
  );

  const initialValues = {
    country: 'se',
    orgNo: '',
    hubspotId: '',
    salesRepresentativeId: user.id,
  };

  const {
    mutateAsync: getHubspotCompany,
    isPending: isGetHubspotCompanyLoading,
  } = useGetHubspotCompanyIds();

  const {
    mutate: searchHubspotCompany,
    data: searchHubspotCompaniesData,
    isPending: isSearchHubspotCompanyLoading,
  } = useSearchHubspotCompanies();
  const [
    updateCompanySalesRepresentativeId,
    { loading: isUpdatingCompanySalesRepresentativeId },
  ] = useUpdateCompany();

  const { data: treydUsers, loading: isTreydUsersLoading } = useGetTreydUsers();
  const users = treydUsers?.lager_treyd_users || [];

  const {
    mutateAsync: createCoreCompany,
    isPending: isCreateCoreCompanyLoading,
  } = useCreateCoreCompany();
  const { mutateAsync: doesCompanyExist, isPending: isCompanyExistLoading } =
    useDoesCompanyExist();
  const toast = useToastNotification();

  const [createCompanyAccount, { loading: isCreateCompanyAccountLoading }] =
    useAddCompany();

  const hubspotCompaniesOptions = map(
    searchHubspotCompaniesData?.data,
    (searchResult) => ({
      id: searchResult.id,
      name: `${searchResult.name} (${searchResult.id})`,
    })
  );

  const handleCreateCompanyAccount = (
    values: Omit<AddCompanyFormValues, 'salesRepresentativeId'>
  ) => {
    return createCompanyAccount({
      variables: {
        ...values,
        hubspotId: values.hubspotId || undefined,
      },
    });
  };

  const handleCompanyExists = async (
    values: Omit<AddCompanyFormValues, 'salesRepresentativeId'>
  ) => {
    await doesCompanyExist(
      {
        org_no: sanitizeOrgNumber(values.orgNo),
        country: values.country,
      },
      {
        onSuccess: ({ data }) => {
          if (data.exists) throw new Error('Company already exists');
        },
      }
    );
  };

  const handleCreateCompanyCore = async (
    values: Omit<AddCompanyFormValues, 'salesRepresentativeId'>
  ) => {
    await createCoreCompany(
      {
        country: values.country,
        org_no: values.orgNo,
      },
      {
        onSuccess: ({ data }) => {
          if (data.error_code === 1) throw new Error('Company does not exist');

          if (data.legal_form !== 'Limited_company')
            throw new Error('Not a limited company');
        },
      }
    );
  };

  const handleHubspotCompany = async (
    values: Omit<AddCompanyFormValues, 'salesRepresentativeId'>
  ) => {
    await getHubspotCompany(
      { country: values.country, orgNo: values.orgNo },
      {
        onSuccess: ({ data }) => {
          setHubspotCompanies(data);
          if (isEmpty(data) && !values.hubspotId)
            throw new Error('Merchant is not found.');
          if (data.length > 1) throw new Error();
        },
      }
    );
  };

  const handleSubmit = async (values: AddCompanyFormValues) => {
    const filteredValues = omit(values, 'salesRepresentativeId');
    try {
      await handleHubspotCompany(filteredValues);
      await handleCreateCompanyCore(filteredValues);
      await handleCompanyExists(filteredValues);
      const data = await handleCreateCompanyAccount(filteredValues);
      await updateCompanySalesRepresentativeId({
        variables: {
          id: toNumber(data?.data?.django?.insert_company?.company?.id),
          company: {
            sales_representative_id: values.salesRepresentativeId,
          },
        },
      });
      navigate(
        `${ROUTES.merchants}/${data?.data?.django?.insert_company?.company?.id}`
      );
    } catch (e: unknown) {
      const error = e as Error;
      error.message &&
        toast({
          type: 'error',
          message: error.message,
          duration: 8000,
        });
    }
  };

  const showHubspotCompanyDropdown =
    hubspotCompanies && isEmpty(hubspotCompanies);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      country: Yup.string().required('Required'),
      orgNo: Yup.string().required('Required'),
      hubspotId: Yup.string(),
    }),
    onSubmit: handleSubmit,
    onReset: () => {
      formik.resetForm();
    },
  });

  const isLoading =
    isGetHubspotCompanyLoading ||
    isCreateCoreCompanyLoading ||
    isCreateCompanyAccountLoading ||
    isSearchHubspotCompanyLoading ||
    isUpdatingCompanySalesRepresentativeId ||
    isCompanyExistLoading;

  return (
    <Dialog
      open={open}
      onClose={isLoading ? () => {} : onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>New merchant</DialogTitle>
      <Stack
        style={{
          display: 'contents',
        }}
        component={'form'}
        noValidate
        onSubmit={formik.handleSubmit}>
        <Stack
          component={DialogContent}
          flexDirection={'row'}
          paddingY={1}
          sx={{ marginBlock: 1 }}
          gap={1}>
          <TextField
            name="country"
            select
            label="Country"
            fullWidth
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}>
            {COUNTRIES.map((country) => (
              <MenuItem key={country.value} value={country.value}>
                {country.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="orgNo"
            label="Organization number"
            type="text"
            fullWidth
            value={formik.values.orgNo}
            onChange={formik.handleChange}
            error={formik.touched.orgNo && Boolean(formik.errors.orgNo)}
            helperText={formik.touched.orgNo && formik.errors.orgNo}
          />
        </Stack>
        <Stack component={DialogContent} paddingY={1}>
          <Autocomplete
            options={users}
            value={
              users.find(
                (user) => user.id === formik.values.salesRepresentativeId
              ) || null
            }
            onChange={(_, value) => {
              formik.setFieldValue(
                'salesRepresentativeId',
                value && value.id ? value.id : ''
              );
            }}
            fullWidth
            loading={isTreydUsersLoading}
            getOptionLabel={(option) => option.name || option.email || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                name="salesRepresentativeId"
                fullWidth
                label={'Sales representative'}
                error={
                  formik.touched.salesRepresentativeId &&
                  Boolean(formik.errors.salesRepresentativeId)
                }
              />
            )}
          />
        </Stack>
        {showHubspotCompanyDropdown && (
          <Stack
            component={DialogContent}
            flexDirection={'column'}
            paddingY={1}
            gap={1}>
            <Typography variant="body1">
              Merchant with this Org. number is not found on Hubspot, try
              linking by merchant name.
            </Typography>
            <Autocomplete
              options={hubspotCompaniesOptions}
              noOptionsText="No Hubspot companies found"
              value={
                hubspotCompaniesOptions.find(
                  (item) => item.id === formik.values.hubspotId
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue(
                  'hubspotId',
                  value && value.id ? value.id : ''
                );
              }}
              fullWidth
              getOptionLabel={(option) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="hubspotId"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  fullWidth
                  placeholder="Search for a Hubspot company.."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: null,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.hubspotId && Boolean(formik.errors.hubspotId)
                  }
                  helperText={
                    formik.touched.hubspotId && formik.errors.hubspotId
                  }
                />
              )}
            />
          </Stack>
        )}
        {hubspotCompanies && hubspotCompanies?.length > 1 && (
          <Alert severity="warning" sx={{ marginInline: 3, marginBlock: 1 }}>
            <Stack>
              <Typography>
                Multiple companies with this Org. number found in Hubspot,
                please merge them first.
              </Typography>
              {hubspotCompanies?.map((company) => (
                <Link
                  color={'warning.160p'}
                  href={company?.link}
                  target="_blank"
                  sx={{ width: 'fit-content' }}>
                  {company.name} - {company.id} ↗
                </Link>
              ))}
            </Stack>
          </Alert>
        )}
        <DialogActions sx={{ marginBlockStart: 1 }}>
          <Button
            variant={'outlined'}
            onClick={props.onClose}
            disabled={isLoading}
            color="secondary">
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant={'contained'}
            color="primary">
            Add
          </LoadingButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
