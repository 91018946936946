import { Form, FormButton } from '@/components/styled-components/FormStyles';
import { EventsTable } from '@/pages/dev/components';
import { getEvents } from '@/schemas';

import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress, TextField } from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface EventsFormValues {
  user_id: number | null;
  org_no: string | null;
}

export const EventsLogs = () => {
  const { state } = useLocation();
  const [getEventsQuery, { data, loading }] = useLazyQuery(getEvents);
  const initialValues: EventsFormValues = {
    user_id: parseInt(state?.user_id) || parseInt(''),
    org_no: state?.org_no || '',
  };
  const events = data?.publisher_events;

  const validate = (values: EventsFormValues) => {
    const errors: FormikErrors<EventsFormValues> = {};
    if (!values.user_id && !values.org_no) {
      errors.user_id = 'Either user_id or org_no is required';
      errors.org_no = 'Either user_id or org_no is required';
    } else if (values.user_id && values.org_no) {
      errors.user_id = 'Only one of user_id or org_no is required';
      errors.org_no = 'Only one of user_id or org_no is required';
    }
    return errors;
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      if (values.org_no) {
        getEventsQuery({ variables: { data: { org_no: values.org_no } } });
      } else {
        getEventsQuery({ variables: { data: { user_id: values.user_id } } });
      }
    },
  });

  useEffect(() => {
    if (state?.user_id) {
      getEventsQuery({
        variables: { data: { user_id: parseInt(state.user_id) } },
      });
    }
    if (state?.org_no) {
      getEventsQuery({ variables: { data: { org_no: state.org_no } } });
    }
  }, [getEventsQuery, state?.org_no, state?.user_id]);

  return (
    <>
      <Box>
        <Form sx={{ maxWidth: 'max-content' }} onSubmit={formik.handleSubmit}>
          <label htmlFor="user_id">User id</label>
          <TextField
            type="number"
            variant="outlined"
            placeholder="enter a user id..."
            name="user_id"
            value={formik.values.user_id}
            onChange={formik.handleChange}
            error={formik.touched.user_id && Boolean(formik.errors.user_id)}
            helperText={formik.touched.user_id && formik.errors.user_id}
          />
          <TextField
            type="string"
            variant="outlined"
            placeholder="enter an org_no..."
            name="org_no"
            value={formik.values.org_no}
            onChange={formik.handleChange}
            error={formik.touched.org_no && Boolean(formik.errors.org_no)}
            helperText={formik.touched.org_no && formik.errors.org_no}
          />
          <FormButton variant="outlined" type="submit">
            Get Events
          </FormButton>
        </Form>
      </Box>
      {loading && (
        <div>
          <CircularProgress size={20} />
        </div>
      )}
      {events && (
        <EventsTable
          events={events}
          user_id={formik.values.user_id as number}
          org_no={formik.values.org_no as string}
        />
      )}
    </>
  );
};
