import {
  COMMERCIAL_STATUS,
  MERCHANT_SEGMENT,
  ONBOARDING_STATUS,
} from '@/constants';
import { RolesType } from '@/types';
import {
  Lager_Risk_Aml,
  Lager_Treyd_Users,
  Maybe,
} from '__generated__/graphql';
import { ReactNode } from 'react';

export type OnboardingStepStatus = 'not_started' | 'on_going' | 'completed';

export type CreditDecisionStatus =
  | 'not_started'
  | 'in_review'
  | 'approved_and_not_published'
  | 'approved_and_published'
  | 'declined';

export type ComplianceStatus =
  | 'not_started'
  | 'pending'
  | 'approved'
  | 'declined'
  | 'inactive';

export interface Merchant {
  id?: string;
  orgNo?: string;
  incorporated_at?: string;
  website?: string;
  country?: string;
  joined?: string;
  financier?: string;
  description?: string;
  address?: string;
  company_users?: Lager_Treyd_Users[];
  kyc?: any;
  erp_system?: string | null;
  creditDeactivatedReason?: string;
  macroSector?: string;
  microSector?: string;
  hasPayoutWallet?: boolean;
  hasPrimaryWallet?: boolean;
}

export interface OnboardingStep {
  title: string;
  status: OnboardingStepStatus;
  details: ReactNode;
  description: ReactNode;
}

export interface CreditDecision {
  is_approved: boolean;
  is_published: boolean;
  approved_limit: string;
  approved_limit_currency: string;
  created: string;
  expiration_date: string;
  published_at: string;
}

export interface ComplianceDecision {
  status: string;
  final_risk_level: string;
  is_active: boolean;
}

export type RiskAml = Pick<Lager_Risk_Aml, 'aml_assesment' | 'id'>;

export interface OnboardingStatuses {
  banking_connected: boolean;
  banking_statement_uploaded: boolean;
  erp_connected: boolean;
  is_financial_documents_added: boolean;
  is_kyc_submitted: boolean;
  financial_data_completed: boolean;
  is_business_persons_onboarded: boolean;
}

export enum COMPLIANCE_RISK_LEVEL {
  UNACCEPTABLE = 'UNACCEPTABLE',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export interface Invitation {
  invitation_type: 'ubo' | 'user';
  created_at: string;
  last_send_at: string;
  invited_user_id: number;
  is_accepted: boolean;
  accepted_at: string;
}

export enum COMPLIANCE_DECISION_STATUS {
  PENDING = 'PENDING',
  DONE = 'DONE',
}

export interface TableRowType {
  id?: Maybe<number>;
  name?: Maybe<string>;
  email?: Maybe<string>;
  phone?: Maybe<string>;
  role?: RolesType[];
  status?: { label: string; color: 'success' | 'warning' | 'primary' };
  last_logged_in?: string;
  actions?: {
    resend: boolean;
  };
  phone_confirmed_at?: string | null;
}

export interface RemainingLimitResponse {
  company_limit: number;
  available_limit: number;
  limit_currency: string;
  outstanding_credit: number;
}

export interface MerchantTableFilters {
  joiningDateStart: string;
  joiningDateEnd: string;
  statuses: boolean[];
  financiers: string[];
  countries: string[];
  currencies: string[];
  segments: MERCHANT_SEGMENT[];
  merchantStatuses: (COMMERCIAL_STATUS | ONBOARDING_STATUS)[];
}

export type MerchantTableFiltersFields = keyof MerchantTableFilters;
