import { createContext, Dispatch, SetStateAction } from 'react';

export const AppNavBarContext = createContext({
  isAppNavBarOpen: false,
  isAppNavBarHidden: false,
  setIsAppNavBarHidden: (() => null) as Dispatch<SetStateAction<boolean>>,
  setIsAppNavBarOpen: (() => null) as Dispatch<
    SetStateAction<boolean | undefined>
  >,
});
