import { useFetchCompanyMembers, useGetCompanyDocuments } from '@/hooks';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import PlusIcon from '@treyd-io/treyd-ui/icons/PlusIcon';
import { filter, isEmpty, map } from 'lodash';
import { useToggle } from 'react-use';
import { DocumentCard } from './DocumentCard';
import { NewPoaModal } from './NewPoaModal';
import { NoDocumentsPlaceholder } from './NoDocumentsPlaceholder';

const Poa = ({ companyId }: { companyId: number }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  const { data: companyDocuments, isPending: isLoadingCompanyDocuments } =
    useGetCompanyDocuments(companyId);
  const { data: companyData, loading: loadingCompanyData } =
    useFetchCompanyMembers(companyId);

  const loading = loadingCompanyData || isLoadingCompanyDocuments;

  const signatories = filter(
    companyData?.orders_companies_by_pk?.core_company?.members,
    (member) => member?.core_signatories[0]?.can_sign_alone === true
  );

  const attorneyDocuments = filter(
    companyDocuments?.data,
    (document) => document?.tag === 'attorney'
  );
  if (loading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <CircularProgress size={60} />
      </Box>
    );
  }
  return (
    <Stack gap={3}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Typography variant="h5">Power of Attorney</Typography>
        <Button
          startIcon={<PlusIcon />}
          variant={'outlined'}
          color={'primary'}
          onClick={toggleIsOpen}>
          Add new
        </Button>
      </Stack>

      {!isEmpty(attorneyDocuments) ? (
        map(attorneyDocuments, (document) => (
          <DocumentCard document={document} companyId={companyId} />
        ))
      ) : (
        <NoDocumentsPlaceholder />
      )}
      <NewPoaModal
        isOpen={isOpen}
        toggle={toggleIsOpen}
        signatories={signatories}
        users={companyData?.orders_companies_by_pk?.company_users}
        companyId={companyId}
      />
    </Stack>
  );
};

export default Poa;
