import { ERPInvoicePDFDrawer } from '@/components/ERPInvoicePDFDrawer';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import {
  ERP_INVOICE_PDF_TYPES,
  ERP_INVOICES_SUPPLIER_INTEGRATIONS_PDF,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from '@/constants';
import { useGetMerchantSuppliersErpInvoices } from '@/hooks/useGetMerchantSuppliersErpInvoices';
import { MerchantSupplierErpInvoice } from '@/schemas/getMerchantSuppliersErpInvoicesQuery';
import { IconButton, Stack } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import FileIcon from '@treyd-io/treyd-ui/icons/FileIcon';
import { first, includes, toNumber, toString, toUpper } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

export const SuppliersInvoicesTable = ({ filters }: { filters?: Filter[] }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'issue_date', sort: 'desc' },
  ]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const { id } = useParams();
  const [isPDFDrawerOpen, setIsPDFDrawerOpen] = useToggle(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>();

  const { data, loading: isLoading } = useGetMerchantSuppliersErpInvoices({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: paginationModel?.pageSize,
      offset: paginationModel?.page * paginationModel?.pageSize,
    },
    companyId: toNumber(id),
  });

  const invoices =
    first(data?.orders_companies_by_pk?.core_company?.erp_connections)
      ?.erp_invoices?.unified_supplier_invoices || [];

  const rowCount =
    first(data?.orders_companies_by_pk?.core_company?.erp_connections)
      ?.erp_invoices?.unified_supplier_invoices_aggregate?.aggregate?.count ||
    0;

  const noInvoicesProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  const handlePreviewButtonClick = useCallback(
    (id: string) => {
      setIsPDFDrawerOpen();
      setSelectedInvoiceId(id);
    },
    [setIsPDFDrawerOpen, setSelectedInvoiceId]
  );

  const integration = first(
    data?.orders_companies_by_pk?.core_company?.erp_connections
  )?.integration;

  const isPDFEnabled = includes(
    ERP_INVOICES_SUPPLIER_INTEGRATIONS_PDF,
    toUpper(integration)
  );

  const columns: GridColDef<MerchantSupplierErpInvoice>[] = useMemo(
    () => [
      {
        field: 'invoice_reference',
        headerName: 'Invoice reference',
        minWidth: 90,
        flex: 1,
      },
      {
        field: 'supplier_name',
        headerName: 'Supplier name',
        minWidth: 90,
        flex: 2,
      },
      {
        field: 'invoice_status',
        headerName: 'Status',
        maxWidth: 90,
      },
      {
        field: 'total_amount',
        headerName: 'Total amount',
        minWidth: 150,
        renderCell: ({ row }) =>
          formatCurrency(row.total_amount, toString(row.currency)),
      },
      {
        field: 'balance',
        headerName: 'Balance',
        maxWidth: 90,
        renderCell: ({ row }) =>
          formatCurrency(row.balance, toString(row.currency)),
      },
      {
        field: 'issue_date',
        headerName: 'Issue date',
        maxWidth: 100,
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
      {
        field: 'due_date',
        headerName: 'Due date',
        maxWidth: 100,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
      {
        field: 'preview_button',
        headerName: 'PDF',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 75,
        renderCell: ({ row }) => (
          <IconButton
            onClick={() => handlePreviewButtonClick(toString(row.uuid))}
            disabled={!isPDFEnabled}>
            <FileIcon />
          </IconButton>
        ),
      },
    ],
    [handlePreviewButtonClick, isPDFEnabled]
  );

  return (
    <Stack flexGrow={1} height={400} width="100%">
      <DataGrid
        rows={invoices}
        columns={columns}
        disableRowSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        rowCount={rowCount}
        getRowId={(row) => row.uuid}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        sortModel={sortModel}
        loading={isLoading}
        onSortModelChange={setSortModel}
        slots={{
          noRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'Nothing here yet'}
              subtitle={'Suppliers invoices will be displayed here'}
            />
          ),
          ...noInvoicesProps,
        }}
        {...noInvoicesProps}
      />
      <ERPInvoicePDFDrawer
        isOpen={isPDFDrawerOpen}
        toggle={setIsPDFDrawerOpen}
        uuid={toString(selectedInvoiceId)}
        type={ERP_INVOICE_PDF_TYPES.BILL}
      />
    </Stack>
  );
};
