import { values } from 'lodash';

export enum VoucherStatus {
  APPLIED = 'applied',
  CANCELLED = 'cancelled',
}

export const voucherStatuses = values(VoucherStatus);

export const voucherStatusDisplayedValue = {
  [VoucherStatus.APPLIED]: 'Applied',
  [VoucherStatus.CANCELLED]: 'Cancelled',
};
