import { MenuItem as MuiMenuItem, Stack, Typography } from '@mui/material';
import { toString } from 'lodash';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
interface MenuItemProps {
  isSelected: boolean;
  title: string;
  icon: ReactNode;
  href?: string;
  color?: string;
  opacity?: number;
  marginBottom?: number;
  onClick?: () => void;
}
export const MenuItem = (props: MenuItemProps) => {
  const opacity = props?.opacity || props.isSelected ? 1 : 0.7;
  return (
    <MuiMenuItem
      component={Link}
      selected={props.isSelected}
      autoFocus={props.isSelected}
      to={toString(props.href)}
      onClick={props?.onClick}
      sx={{
        opacity,
        flexDirection: 'column',
        p: 0,
        paddingInline: 1,
        borderRadius: 2,
        overflow: 'hidden',
        marginBottom: props.marginBottom,
      }}>
      <Stack
        direction="row"
        flexGrow={1}
        width="100%"
        alignItems="center"
        paddingY={1.5}>
        <Stack gap={1.5} direction="row" alignItems="center">
          {props.icon}
          <Typography variant="body1" color={props?.color}>
            {props.title}
          </Typography>
        </Stack>
      </Stack>
    </MuiMenuItem>
  );
};
