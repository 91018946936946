import { AUTH_TOKEN_KEY, REFRESH_KEY } from '@treyd-io/core/constants/settings';
import { createContext } from 'react';

interface AuthContextType {
  user: any | null;
  isAuthenticated: boolean;
  setUser: (user: any) => void;
  login: (access_token: string, refresh_token: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  setUser: () => {},
  login: () => {},
  logout: () => {
    localStorage.setItem(AUTH_TOKEN_KEY, '');
    localStorage.setItem(REFRESH_KEY, '');
  },
});
