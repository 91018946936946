export const downloadCsvFile = ({
  data,
  fileName,
}: {
  data: BlobPart;
  fileName: string;
}) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click');
  a.dispatchEvent(clickEvt);
  a.remove();
};
