import { GridSortItem } from '@mui/x-data-grid';

export type QuerySearchField<K = string> = {
  key?: K;
  type: 'search';
  name: string;
  comparator: FilterComparator;
  comparisonValue?: string | number | null;
};

export type QueryFilterFieldComparisonValue =
  | boolean
  | string
  | number
  | string[]
  | number[]
  | boolean[]
  | Record<string, unknown>;

export type QueryFilterField<K = string> = {
  key?: K;
  type: 'filter';
  name: string;
  comparator: FilterComparator;
  comparisonValue?: QueryFilterFieldComparisonValue;
};

export type Filter<K = string> = QuerySearchField<K> | QueryFilterField<K>;

export enum FilterComparator {
  EQUAL = '_eq',
  GREATER_THAN = '_gt',
  GREATER_THAN_OR_EQUAL = '_gte',
  CASE_INSENSITIVE_LIKE = '_ilike',
  IN = '_in',
  CASE_INSENSITIVE_REGEX = '_iregex',
  IS_NULL = '_is_null',
  LIKE = '_like',
  LESS_THAN = '_lt',
  LESS_THAN_OR_EQUAL = '_lte',
  NOT_EQUAL = '_neq',
  NOT_CASE_INSENSITIVE_LIKE = '_nilike',
  NOT_IN = '_nin',
  NOT_CASE_INSENSITIVE_REGEX = '_niregex',
  NOT_LIKE = '_nlike',
  NOT_REGEX = '_nregex',
  NOT_SIMILAR = '_nsimilar',
  REGEX = '_regex',
  SIMILAR = '_similar',
  CONTAINS = '_contains',
  EXIST = '_exist', //custom comparator
  NOT_EXIST = '_nexist', //custom comparator
}

export interface QueryParams {
  offset?: number;
  limit?: number;
  orderBy?: string | null;
  where?: string | null;
}

export interface QueryOptions {
  offset?: number;
  limit?: number;
  filters?: Filter[];
  sorting?: GridSortItem;
}
