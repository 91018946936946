import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { ADDITIONAL_TAGS } from '@/types';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

interface FormValues {
  ignoreAutomation: boolean;
  isNordeaPayment: boolean;
  isAirwallexPayment: boolean;
  hasOcr: boolean;
  ocrNumber: string;
  preProductionMaterials: boolean;
  marketing: boolean;
  freight: boolean;
}

const INITIAL_VALUES: FormValues = {
  ignoreAutomation: false,
  isNordeaPayment: false,
  isAirwallexPayment: false,
  hasOcr: false,
  preProductionMaterials: false,
  marketing: false,
  freight: false,
  ocrNumber: '',
};

const VALIDATION_SCHEMA = Yup.object({
  ignoreAutomation: Yup.boolean(),
  isNordeaPayment: Yup.boolean(),
  isAirwallexPayment: Yup.boolean(),
  hasOcr: Yup.boolean(),
  preProductionMaterials: Yup.boolean(),
  marketing: Yup.boolean(),
  freight: Yup.boolean(),
  ocrNumber: Yup.string().when('hasOcr', ([hasOcr], schema) => {
    return hasOcr
      ? schema.required('Ocr number is required.')
      : schema.notRequired();
  }),
});

export const AdditionalTagsDialog = ({
  onClose,
  open,
  onSubmit,
}: {
  onClose: () => void;
  open: boolean;
  onSubmit: (comment: string) => {};
}) => {
  const handleFormSubmit = (values: FormValues) => {
    const {
      ignoreAutomation,
      isNordeaPayment,
      isAirwallexPayment,
      hasOcr,
      ocrNumber,
      freight,
      marketing,
      preProductionMaterials,
    } = values;
    const tags = [];
    if (ignoreAutomation) {
      tags.push(ADDITIONAL_TAGS.IGNORE_AUTOMATION);
    }
    if (isNordeaPayment) {
      tags.push(ADDITIONAL_TAGS.NORDEA_PAYMENT);
    }
    if (isAirwallexPayment) {
      tags.push('#$AirwallexPayment#$');
    }
    if (isAirwallexPayment) {
      tags.push('#$AirwallexPayment#$');
    }
    if (hasOcr && ocrNumber) {
      tags.push(ADDITIONAL_TAGS.OCR.replace('ocrNumber', ocrNumber));
    }
    if (freight) {
      tags.push(ADDITIONAL_TAGS.FREIGHT);
    }
    if (marketing) {
      tags.push(ADDITIONAL_TAGS.MARKETING);
    }
    if (preProductionMaterials) {
      tags.push(ADDITIONAL_TAGS.PRE_PRODUCTION_MATERIALS);
    }
    onSubmit(tags.join('\n'));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Additional Tag</DialogTitle>
      <Formik
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={handleFormSubmit}
        validateOnMount>
        {({
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          setFieldValue,
          dirty,
          handleSubmit,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}>
                <Typography variant="body1">
                  Extra order-related tags will be displayed in activity.
                </Typography>
                <Row>
                  <Col xs>
                    <Stack>
                      <FormControl
                        component="fieldset"
                        variant="standard"
                        sx={{ alignSelf: 'flex-start' }}>
                        <FormLabel component="legend">
                          <Typography variant="body1">Payment</Typography>
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.ignoreAutomation}
                                onChange={handleChange}
                                name="ignoreAutomation"
                              />
                            }
                            label="Ignore automation"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isNordeaPayment}
                                onChange={handleChange}
                                name="isNordeaPayment"
                              />
                            }
                            label="Nordea payment"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isAirwallexPayment}
                                onChange={handleChange}
                                name="isAirwallexPayment"
                              />
                            }
                            label="Airwallex payment"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.hasOcr}
                                onChange={(e) => {
                                  setFieldValue('ocrNumber', '');
                                  handleChange(e);
                                }}
                                name="hasOcr"
                              />
                            }
                            label="Enter OCR number"
                          />
                        </FormGroup>
                      </FormControl>
                      {values.hasOcr && (
                        <TextField
                          fullWidth
                          label="OCR number"
                          name="ocrNumber"
                          value={values.ocrNumber}
                          onChange={(e) =>
                            setFieldValue('ocrNumber', e.target.value?.trim())
                          }
                          onBlur={handleBlur}
                          error={
                            Boolean(touched.ocrNumber) &&
                            Boolean(errors.ocrNumber)
                          }
                          helperText={
                            Boolean(touched.ocrNumber) && errors.ocrNumber
                          }
                        />
                      )}
                    </Stack>
                  </Col>

                  <Col xs>
                    <FormControl
                      component="fieldset"
                      variant="standard"
                      sx={{ alignSelf: 'flex-start' }}>
                      <FormLabel component="legend">
                        <Typography variant="body1">
                          Supplier invoice
                        </Typography>
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.preProductionMaterials}
                              onChange={handleChange}
                              name="preProductionMaterials"
                            />
                          }
                          label="Pre-production materials"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.marketing}
                              onChange={handleChange}
                              name="marketing"
                            />
                          }
                          label="Marketing"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.freight}
                              onChange={handleChange}
                              name="freight"
                            />
                          }
                          label="Freight"
                        />
                      </FormGroup>
                    </FormControl>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={!dirty}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
