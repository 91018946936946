import { Invitation } from '@/types';
import { TypedDocumentNode, gql } from '@apollo/client';

export const getUboInvitations = gql`
  query getUboInvitations($company_id: bigint) {
    lager_invitations(
      where: {
        _and: {
          company_id: { _eq: $company_id }
          invitation_type: { _eq: "ubo" }
        }
      }
    ) {
      invitation_type
      created_at
      last_send_at
      invited_user_id
      is_accepted
      accepted_at
    }
  }
` as TypedDocumentNode<{ lager_invitations: Invitation[] }, {}>;
