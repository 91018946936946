import { SupplierCompliance } from '@/types';
import { gql, TypedDocumentNode } from '@apollo/client';

type GetSupplierKycQueryType = {
  orders_suppliers_by_pk: {
    kyc: SupplierCompliance.SupplierKyc;
  };
};

type GetSupplierKycQueryVariablesType = {
  supplierId: number;
};

export const getSupplierKycQuery = gql`
  query getSupplierKycQuery($supplierId: bigint!) {
    orders_suppliers_by_pk(id: $supplierId) {
      kyc
    }
  }
` as TypedDocumentNode<
  GetSupplierKycQueryType,
  GetSupplierKycQueryVariablesType
>;
