import { getCompanyAppliedDiscounts } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetCompanyAppliedDiscounts = (companyId: number) =>
  useQuery({
    queryKey: [`companyDiscounts`, companyId],
    queryFn: () => getCompanyAppliedDiscounts(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
    select: (data) => data.data,
  });
