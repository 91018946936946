import CompanyUsersTable from '@/pages/merchants/MerchantsDetails/Tabs/Users/CompanyUsersTable';
import ConfirmDeleteModal from '@/pages/merchants/MerchantsDetails/Tabs/Users/ConfirmDeleteModal';
import ConfirmResendModal from '@/pages/merchants/MerchantsDetails/Tabs/Users/ConfirmResendModal';
import InviteUserModal from '@/pages/merchants/MerchantsDetails/Tabs/Users/InviteUserModal';
import CompanyMembersTable from '@/pages/merchants/MerchantsDetails/Tabs/Users/MembersTable';
import UpdateMainContactModal from '@/pages/merchants/MerchantsDetails/Tabs/Users/UpdateMainContactModal';
import useController from '@/pages/merchants/MerchantsDetails/Tabs/Users/useController';
import { Box, CircularProgress } from '@mui/material';

const Users = ({
  company_id,
  isKycSubmitted,
  mainContactId,
  refetchMerchantDetails,
}: {
  company_id?: string;
  isKycSubmitted: boolean;
  mainContactId?: number;
  refetchMerchantDetails: () => void;
}) => {
  const {
    states: {
      companyData,
      companyMembers,
      attorneyIds,
      loadingCompanyData,
      loadingCompanyMembers,
      powerOfAttorneysLoading,
      isOpen,
      mainContactModalIsOpen,
      confirmModalIsOpen,
      deleteModalIsOpen,
      loading,
      user,
    },
    actions: {
      toggleModal,
      handleSubmit,
      toggleConfirmModal,
      toggleDeleteModal,
      toggleMainContactModal,
      handleConfirmUpdateMainContact,
      handleConfirmDelete,
      handleConfirmResend,
      setUser,
    },
  } = useController(company_id || '');

  if (loadingCompanyData || loadingCompanyMembers || powerOfAttorneysLoading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <CircularProgress size={60} />
      </Box>
    );
  }

  const updateMainContact = (userId: number, companyId: number) => {
    handleConfirmUpdateMainContact(userId, companyId).then(() => {
      refetchMerchantDetails();
    });
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
      })}>
      <CompanyUsersTable
        companyId={company_id}
        companyUsers={companyData?.lager_treyd_users}
        attorneyIds={attorneyIds}
        toggleInviteModal={toggleModal}
        toggleConfirmModal={toggleConfirmModal}
        toggleDeleteModal={toggleDeleteModal}
        toggleMainContactModal={toggleMainContactModal}
        setUser={setUser}
        isKycSubmitted={isKycSubmitted}
        mainContactId={mainContactId}
      />
      <CompanyMembersTable
        companyMembers={companyMembers}
        companyUsers={companyData?.lager_treyd_users}
      />

      <InviteUserModal
        onConfirm={handleSubmit}
        isOpen={isOpen}
        loading={loading}
        onCancel={toggleModal}
      />

      <ConfirmResendModal
        isOpen={confirmModalIsOpen}
        onCancel={toggleConfirmModal}
        companyId={company_id}
        loading={loading}
        onConfirm={handleConfirmResend}
        user={user}
      />

      <ConfirmDeleteModal
        isOpen={deleteModalIsOpen}
        onCancel={toggleDeleteModal}
        loading={loading}
        onConfirm={() => {
          handleConfirmDelete(Number(user?.id), Number(company_id));
        }}
        user={user}
      />
      <UpdateMainContactModal
        isOpen={mainContactModalIsOpen}
        onCancel={toggleMainContactModal}
        loading={loading}
        onConfirm={() =>
          updateMainContact(Number(user?.id), Number(company_id))
        }
        user={user}
      />
    </Box>
  );
};

export default Users;
