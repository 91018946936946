import NavTabsBar from '@/components/NavTabsBar';
import {
  CompanyUsersAndMembers,
  EventsLogs,
  FeatureFlags,
  TreydUsersTable,
} from '@/pages/dev/components';
import { Stack } from '@mui/material';
import { UserOperations } from './components/UserOperations';
export default function DevAppIndex() {
  const tabs = [
    {
      id: 'users',
      label: 'Treyd Users',
      content: <TreydUsersTable />,
    },
    {
      id: 'linking',
      label: 'Linking Users and Members',
      content: <CompanyUsersAndMembers />,
    },
    {
      id: 'events',
      label: 'Events',
      content: <EventsLogs />,
    },
    {
      id: 'featureFlags',
      label: 'Feature flags',
      content: <FeatureFlags />,
    },
    {
      id: 'userOperations',
      label: 'User Operations',
      content: <UserOperations />,
    },
  ];
  return (
    <Stack flexGrow={1} height="100%">
      <NavTabsBar tabs={tabs} />
    </Stack>
  );
}
