import { MerchantWithLink, OrderStatusChip } from '@/components';
import GenericTableView from '@/components/GenericTableView';
import {
  DEFAULT_GRID_COL_DEF,
  MAPPED_ORDER_STATUS,
  ORDER_MAPPED_STATUS_DISPLAYED_VALUE,
  ORDER_REVIEW_PATHS,
} from '@/constants';
import {
  Order,
  getDraftOrdersInOrderReviewQuery,
} from '@/schemas/getDraftOrdersInOrderReviewQuery';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toNumber, toString } from 'lodash';
import { Link } from 'react-router-dom';

const columns: GridColDef<Order>[] = [
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'id',
    headerName: 'Order #',
    width: 150,
    renderCell: (params) => (
      <Link to={ORDER_REVIEW_PATHS[':id'].replaceAll(':id', params.value)}>
        {params.value}
      </Link>
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'created',
    headerName: 'Created',
    width: 150,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'mapped_status',
    headerName: 'Status',
    type: 'string',
    renderCell: (params) => (
      <OrderStatusChip size="small" status={params.value} />
    ),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'importer__name',
    headerName: 'Merchant',
    type: 'string',
    width: 250,
    renderCell: (params) => {
      const id = params.row?.importer?.id;
      const name = params.row?.importer?.name;
      const segment = params.row?.importer?.segment;
      return name && <MerchantWithLink id={id} name={name} segment={segment} />;
    },
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'importer__country',
    headerName: 'Country',
    type: 'string',
    width: 150,
    renderCell: (params) => getCountryName(params.value),
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'active_payout__expected_payment_date',
    headerName: 'Expected payment date',
    width: 250,
  },
  {
    ...DEFAULT_GRID_COL_DEF,
    field: 'active_payout__amount',
    headerName: 'Payout amount',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    sortable: false,
    width: 250,
    renderCell: (params) => (
      <Typography variant="body2" sx={{ fontVariantNumeric: 'tabular-nums' }}>
        {params.value || '-'}
      </Typography>
    ),
  },
];

const formatDeal = (order: Order) => ({
  ...order,
  importer__name: order.importer?.name,
  importer__country: order.importer?.country,
  created: order.created && formatDate(new Date(order.created), 'date'),
  active_payout__expected_payment_date:
    order.active_payout?.expected_payment_date &&
    formatDate(new Date(order.active_payout?.expected_payment_date), 'date'),
  active_payout__amount: formatCurrency(
    toNumber(order.active_payout?.amount),
    toString(order.active_payout?.currency)
  ),
});

export const DraftOrdersTable = () => {
  return (
    <GenericTableView
      searchPlaceholder="Search for order #, merchants, financiers"
      columns={columns}
      getGQLSchema={getDraftOrdersInOrderReviewQuery}
      rowFormatter={formatDeal}
      resourceName="orders_orders"
      disableSelectionOnClick
      filterList={[
        {
          field: 'mapped_status',
          options: [MAPPED_ORDER_STATUS.DRAFT, MAPPED_ORDER_STATUS.PREVIEW],
          label: 'Status',
        },
      ]}
      filterMapper={(status) =>
        ORDER_MAPPED_STATUS_DISPLAYED_VALUE[status as MAPPED_ORDER_STATUS]
      }
    />
  );
};
