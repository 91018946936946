import Order from '@/pages/orderReview/models/Order';
import { get } from '@treyd-io/core/utils/request';
import { ORDERS_URL } from 'constants/api-endpoints';
import useSWR from 'swr';

interface iSwrResponse<T> {
  data?: T;
  error: any;
  isLoading: boolean;
  refetch: () => void;
}

const getOrderByUrl = async (url: string): Promise<Order> => {
  const resp = await get<{ order: Order }>(url);

  const order = resp.data.order;
  return order;
};

export const useOrderById = (order_id: number): iSwrResponse<Order> => {
  // we are using swr to refetching the order every time we lose the focus from the browser tab
  // this is to make sure that we are always showing the latest data if two users are working on the same order
  const key = `${ORDERS_URL}/${order_id}/`;
  const { data, error, isLoading, mutate } = useSWR(key, getOrderByUrl);
  return { data, error, isLoading, refetch: () => mutate() };
};
