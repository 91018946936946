import { getCompanyFinanciers } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetCompanyFinanciers = (companyId: number) =>
  useQuery({
    queryKey: [`company-financiers`, companyId],
    queryFn: () => getCompanyFinanciers(companyId),
    refetchOnWindowFocus: false,
    enabled: !!companyId,
    select: (data) => data.data,
  });
