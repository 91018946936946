import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import UserAvatar from '@/components/UserAvatar';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, ROUTES } from '@/constants';
import { useGetOrdersComplianceReviews } from '@/hooks/useGetOrdersComplianceReviews';
import { OrderComplianceReview } from '@/schemas/getOrdersComplianceReviewsQuery';
import { Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { FilterComparator } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { startCase, toNumber, toString } from 'lodash';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ExpandableCell from './ExpandableCell';

interface OrdersComplianceReviewsProps {
  merchantId: number;
}

export const OrdersComplianceReviews = (
  props: OrdersComplianceReviewsProps
) => {
  const { merchantId } = props;

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'desc' },
  ]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const { data, loading: isLoadingOrders } = useGetOrdersComplianceReviews({
    queryOptions: {
      filters: [
        {
          type: 'filter',
          name: 'order__importer_id',
          comparator: FilterComparator.EQUAL,
          comparisonValue: toNumber(merchantId),
        },
        {
          type: 'filter',
          name: 'comment',
          comparator: FilterComparator.NOT_EQUAL,
          comparisonValue: '',
        },
      ],
      sorting: sortModel[0],
      limit: paginationModel?.pageSize,
      offset: paginationModel?.page * paginationModel?.pageSize,
    },
  });
  const orders = data?.orders_orders_app_ordercompliancereview || [];
  const rowCount =
    data?.orders_orders_app_ordercompliancereview_aggregate?.aggregate?.count ||
    0;

  const noOrdersProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  const columns: GridColDef<OrderComplianceReview>[] = useMemo(
    () => [
      {
        field: 'order_id',
        headerName: 'Order #',
        minWidth: 190,
        renderCell: ({ value }) => (
          <Typography
            component={Link}
            to={`${ROUTES.orders}/${value}`}
            noWrap
            color="info"
            variant="body2">
            {value}
          </Typography>
        ),
      },
      {
        field: 'verdict',
        headerName: 'Action',
        minWidth: 200,
        flex: 1,
        renderCell: ({ value }) => startCase(value),
      },
      {
        field: 'comment',
        headerName: 'Comment',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => <ExpandableCell value={params.value} />,
      },
      {
        field: 'user',
        headerName: 'Reviewer',
        minWidth: 200,
        sortable: false,
        renderCell: (params) => {
          const firstName = toString(params.row?.user?.first_name).trim();
          const lastName = toString(params.row?.user?.last_name).trim();
          const name = `${firstName} ${lastName}`.trim();
          return (
            name && (
              <>
                <UserAvatar
                  sx={{
                    height: '2rem',
                    width: '2rem',
                    marginInlineEnd: '0.5rem',
                    fontSize: 12,
                  }}
                  firstName={firstName}
                  lastName={lastName}
                />
                <Typography>{name}</Typography>
              </>
            )
          );
        },
      },
      {
        field: 'created',
        headerName: 'Date',
        minWidth: 150,
        align: 'right',
        headerAlign: 'right',
        renderCell: ({ value }) => {
          return value && formatDate(new Date(value), 'datetime');
        },
      },
    ],
    []
  );

  return (
    <Stack gap={2}>
      <Typography variant="body1">
        All comments attached with compliance actions on orders.
      </Typography>
      <Stack flexGrow={1} height={400} width="100%">
        <DataGrid
          rows={orders}
          columns={columns}
          disableRowSelectionOnClick
          sortingMode="server"
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowCount={rowCount}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          sortModel={sortModel}
          loading={isLoadingOrders}
          onSortModelChange={setSortModel}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}
          slots={{
            noRowsOverlay: () => (
              <TableHasNoRowsPlaceholder
                title={'Nothing here yet'}
                subtitle={'Orders will be displayed here'}
              />
            ),
            ...noOrdersProps,
          }}
          {...noOrdersProps}
        />
      </Stack>
    </Stack>
  );
};
