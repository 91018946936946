import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export default function MailIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="MailIcon"
      {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.75 7.75C4.75 6.64543 5.64543 5.75 6.75 5.75H17.25C18.3546 5.75 19.25 6.64543 19.25 7.75V16.25C19.25 17.3546 18.3546 18.25 17.25 18.25H6.75C5.64543 18.25 4.75 17.3546 4.75 16.25V7.75Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.5 6.5L12 12.25L18.5 6.5"
      />
    </SvgIcon>
  );
}
