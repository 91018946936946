import { AddNoteDialog } from '@/components';
import BorderContainer from '@/components/styled-components/BorderContainer';
import { PRODUCT_SLACK_CHANNEL } from '@/constants';
import { useGetNotes, useInsertNotes, useUpdateNotes } from '@/hooks';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import EditIcon from '@treyd-io/treyd-ui/icons/EditIcon';
import PinTackIcon from '@treyd-io/treyd-ui/icons/PinTackIcon';
import { toNumber } from 'lodash';
import { useToggle } from 'react-use';

interface NotesProps {
  supplierId: string;
}

const Notes = (props: NotesProps) => {
  const { supplierId } = props;
  const [open, toggle] = useToggle(false);
  const showNotificationMessage = useToastNotification();

  const { data: supplierNotesData, refetch: refetchSupplierNotes } =
    useGetNotes({
      entity_id: toNumber(supplierId),
      entity_type: 'supplier',
    });

  const [updateNotes] = useUpdateNotes();
  const [insertNotes] = useInsertNotes();

  const note = supplierNotesData?.orders_orders_app_notes?.[0]?.note;

  const handleCreateNewSupplierNote = async (note: string) => {
    //TODO: replace hasura write with lager api write
    const date = new Date().toJSON();
    return insertNotes({
      variables: {
        entity_id: toNumber(supplierId),
        entity_type: 'supplier',
        note,
        created_at: date,
      },
    });
  };
  const handleUpdateSupplierNote = (newNote: string) =>
    updateNotes({
      variables: {
        entity_id: toNumber(supplierId),
        entity_type: 'supplier',
        note: newNote,
      },
    });

  const onClickSave = async (newNote: string) => {
    try {
      note
        ? await handleUpdateSupplierNote(newNote)
        : await handleCreateNewSupplierNote(newNote);
      refetchSupplierNotes();
      toggle();
      showNotificationMessage({
        title: 'Supplier note saved',
        type: 'success',
      });
    } catch (error) {
      showNotificationMessage({
        title: 'Supplier note failed to save',
        type: 'error',
      });
    }
  };

  return (
    <Box>
      <Row>
        <Col xs={12} md={8}>
          <BorderContainer sx={{ p: 3, height: '100%', borderRadius: 3 }}>
            <Box sx={{ marginBlockEnd: 2 }}>
              <Typography variant="h6">Supplier notes</Typography>
              <Typography variant="body2" color="text.secondary">
                Shown in order review. Good for adding special conditions or
                agreements.
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ marginBlockStart: 2 }}>
              <Typography
                variant="body1"
                color="text.disabled"
                sx={{ marginBlockEnd: 3.25 }}>
                {note || 'No notes added yet'}
              </Typography>
              <Button
                onClick={toggle}
                variant="outlined"
                color="secondary"
                size="medium"
                startIcon={<EditIcon />}>
                Edit
              </Button>
            </Box>
          </BorderContainer>
        </Col>
        <Col xs={12} md={4}>
          <Alert
            severity="info"
            icon={<PinTackIcon sx={{ width: '100%', color: 'info.dark' }} />}
            sx={{
              flexDirection: 'column',
              textAlign: 'center',
              height: '100%',
              justifyContent: 'center',
              borderRadius: 3,
            }}>
            <AlertTitle sx={{ mt: 1 }}>
              We got more things coming for this page soon
            </AlertTitle>
            What would you like to see? Event log, orders? Let us know in{' '}
            <Link
              href={PRODUCT_SLACK_CHANNEL}
              target="_blank"
              rel="noreferrer"
              color="info.dark">
              #product.
            </Link>
          </Alert>
        </Col>
      </Row>
      <AddNoteDialog
        open={open}
        toggle={toggle}
        onClick={(value: string) => onClickSave(value)}
        note={note || ''}
        title="Edit Supplier note"
      />
    </Box>
  );
};
export default Notes;
