import { OCR_FIELD_TITLE, ROUTES } from '@/constants';
import { useExporter } from '@/hooks';
import { OrderReviewData } from '@/hooks/useOrderReview';
import { OrderInformationTable } from '@/pages/orderReview/OrderReviewDetails/OrderInformationTable';
import { AiStatus } from '@/pages/orderReview/OrderReviewDetails/OrderInformationTableSection/AiStatus';
import {
  getOcrRows,
  getOcrRowsState,
} from '@/pages/orderReview/OrderReviewDetails/OrderInformationTableSection/utils';
import Order from '@/pages/orderReview/models/Order';
import { MappedOcrFields, OcrSectionDataKey } from '@/types';
import { Box, Button, Link as MuiLink, Stack, Typography } from '@mui/material';
import { getCountryName } from '@treyd-io/core/utils/country';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import RefreshIcon from '@treyd-io/treyd-ui/icons/RefreshIcon';
import { get, toString } from 'lodash';

import { updateOrderInOrderReview } from '@/api';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import { ChangeBeneficiaryModal } from '../ChangeBeneficiaryModal';

interface OrderInformationTablesProps {
  order: Order;
  activeOrderReview: any;
  actionsDisabled: boolean;
  ocrData: MappedOcrFields | null;
  fetchOrder: () => void;
  orderReviewData: OrderReviewData;
  onOcrFieldReviewChecked: (
    ocrSectionPath: string,
    ocrKeyPath: string,
    isChecked: boolean
  ) => void;
}

export const OrderInformationTablesSection = (
  props: OrderInformationTablesProps
) => {
  const {
    order,
    actionsDisabled,
    ocrData,
    fetchOrder,
    orderReviewData,
    onOcrFieldReviewChecked,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: exporter_data } = useExporter(order.exporter_id, {
    include_bank_data: true,
  });

  const bank_account = exporter_data?.bank_accounts?.find(
    (bank_account) => bank_account?.id === order.bank_account_id
  );
  const [open, toggle] = useToggle(false);
  const showNotificationMessage = useToastNotification();

  const { orderRows, exporterBankAccountRows, amountsRows, ocrRows } =
    getOcrRows(ocrData);

  const {
    isAllRowsReviewed,
    reviewedRowsCount,
    unreviewedRowsCount,
    unreviewedMismathcedRowsCount,
  } = getOcrRowsState(ocrRows);

  const orderTableRows = orderRows.map((field) => {
    if (field.title === OCR_FIELD_TITLE.IMPORTER)
      return getOcrTableRow(
        field.title,
        field.ocrKeyPath,
        ocrData,
        get(order, field.dataKeyPath),
        `${ROUTES.merchants}/${order?.importer_id}`
      );
    if (field.title === OCR_FIELD_TITLE.SUPPLIER)
      return getOcrTableRow(
        field.title,
        field.ocrKeyPath,
        ocrData,
        get(order, field.dataKeyPath),
        order?.exporter_id
          ? `${ROUTES.suppliers}/${order?.exporter_id}`
          : undefined
      );
    if (field.title === OCR_FIELD_TITLE.COUNTRY)
      return getOcrTableRow(
        field.title,
        field.ocrKeyPath,
        ocrData,
        getCountryName(get(order, field.dataKeyPath)) ||
          get(order, field.dataKeyPath)
      );
    if (field.title === OCR_FIELD_TITLE.DUE_DATE)
      return getOcrTableRow(
        field.title,
        field.ocrKeyPath,
        ocrData,
        get(order, field.dataKeyPath) &&
          formatDate(new Date(get(order, field.dataKeyPath)), 'date')
      );
    return getOcrTableRow(
      field.title,
      field.ocrKeyPath,
      ocrData,
      get(order, field.dataKeyPath)
    );
  });

  const exporterBankAccountTableRows = exporterBankAccountRows.map((field) => {
    if (field.title === OCR_FIELD_TITLE.ACCOUNT_NUMBER) {
      return getOcrTableRow(
        field.title,
        field.ocrKeyPath,
        ocrData,
        toString(bank_account?.acct_number || bank_account?.iban)
      );
    }
    if (field.title === OCR_FIELD_TITLE.BANKGIRO_NUMBER) {
      return getOcrTableRow(
        field.title,
        field.ocrKeyPath,
        ocrData,
        toString(bank_account?.bankgiro_number)
      );
    }
    return getOcrTableRow(
      field.title,
      field.ocrKeyPath,
      ocrData,
      get(bank_account, field.dataKeyPath)
    );
  });

  const amountsTableRows = [
    ...amountsRows.map((field) => {
      if (field.title === OCR_FIELD_TITLE.TOTAL_AMOUNT)
        return getOcrTableRow(
          field.title,
          field.ocrKeyPath,
          ocrData,
          formatCurrency(
            get(order, field.dataKeyPath),
            order.supplier_invoice.currency
          )
        );
      if (field.title === OCR_FIELD_TITLE.TREYD_PAYOUT_AMOUNT)
        return getOcrTableRow(
          field.title,
          field.ocrKeyPath,
          ocrData,
          formatCurrency(
            get(order, field.dataKeyPath),
            toString(order.payouts?.[0]?.currency).toLocaleLowerCase()
          )
        );
      return getOcrTableRow(
        field.title,
        field.ocrKeyPath,
        ocrData,
        get(order, field.dataKeyPath)
      );
    }),
  ];

  const updateBeneficiary = useCallback(
    async (bank_account_id: number) => {
      setIsSubmitting(true);
      try {
        await updateOrderInOrderReview(order.id, {
          bank_account_id,
        });
        fetchOrder();
        showNotificationMessage({
          title: 'Beneficiary details updated',
          type: 'success',
        });
      } catch {
        showNotificationMessage({
          title: 'Beneficiary details update failed',
          type: 'error',
        });
      }
      setIsSubmitting(false);
      toggle();
    },
    [order.id, fetchOrder, showNotificationMessage, toggle]
  );

  return (
    <Stack gap={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Checklist</Typography>
        <AiStatus
          hasOcrData={Boolean(ocrData)}
          reviewedRowsCount={reviewedRowsCount}
          unreviewedRowsCount={unreviewedRowsCount}
          unreviewedMismathcedRowsCount={unreviewedMismathcedRowsCount}
          isAllRowsReviewed={isAllRowsReviewed}
        />
      </Box>
      <Stack gap={7}>
        <OrderInformationTable
          isOpen={!orderReviewData?.['invoice']?.checked}
          title="Supplier invoice"
          rows={orderTableRows}
          onOcrFieldReviewChecked={onOcrFieldReviewChecked}
          editable={!actionsDisabled}
        />
        <OrderInformationTable
          isOpen={!orderReviewData?.['invoice']?.checked}
          title="Beneficiary details"
          rows={exporterBankAccountTableRows}
          headerActions={
            <>
              <Stack direction="row" alignItems="center" gap={1}>
                {bank_account?.deactivated_at && (
                  <Typography color="error" variant="subtitle2">
                    Deactivated
                  </Typography>
                )}
                {!actionsDisabled && (
                  <Button
                    onClick={toggle}
                    size="small"
                    color="info"
                    startIcon={<RefreshIcon />}>
                    Change beneficiary
                  </Button>
                )}
              </Stack>
            </>
          }
          onOcrFieldReviewChecked={onOcrFieldReviewChecked}
          editable={!actionsDisabled}
        />
        <OrderInformationTable
          isOpen={!orderReviewData?.['amounts']?.checked}
          title="Amounts"
          rows={amountsTableRows}
          onOcrFieldReviewChecked={onOcrFieldReviewChecked}
          editable={!actionsDisabled}
        />
        <ChangeBeneficiaryModal
          isSubmitting={isSubmitting}
          updateBeneficiary={updateBeneficiary}
          selectedBankAccountId={order.bank_account_id}
          bankAccounts={exporter_data?.bank_accounts}
          open={open}
          toggle={toggle}
          supplierId={order.exporter_id}
        />
      </Stack>
    </Stack>
  );
};

interface OrderInformationTableProps {
  label?: string | number;
}

const OrderReviewKeyTableCell = (props: OrderInformationTableProps) => {
  const { label } = props;
  return (
    <Stack gap={1}>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="subtitle2">From invoice AI</Typography>
    </Stack>
  );
};

interface OrderReviewValueTableCellProps {
  orderValue?: string | number;
  invoiceAiValue?: string | number;
  operatorInputValue?: string | number;
  link?: string;
}

const OrderReviewValueTableCell = (props: OrderReviewValueTableCellProps) => {
  const { orderValue, invoiceAiValue, link } = props;
  return (
    <Stack gap={1}>
      {link ? (
        <MuiLink to={link} component={Link}>
          {orderValue} ↗
        </MuiLink>
      ) : (
        <Typography variant="body2">{orderValue || '-'}</Typography>
      )}
      <Typography variant="body2">{invoiceAiValue || '-'}</Typography>
    </Stack>
  );
};

const getOcrTableRow = (
  key: string,
  ocrKeyPath: OcrSectionDataKey,
  ocrData?: MappedOcrFields | null,
  dealValue?: string | number,
  valueLinkUrl?: string
) => {
  return {
    keyCell: <OrderReviewKeyTableCell label={key} />,
    valueCell: (
      <OrderReviewValueTableCell
        orderValue={dealValue}
        operatorInputValue={ocrData?.[ocrKeyPath]?.operatorValue}
        invoiceAiValue={ocrData?.[ocrKeyPath]?.invoiceAiValue}
        link={valueLinkUrl}
      />
    ),
    confidenceStatus: ocrData?.[ocrKeyPath]?.confidenceStatus,
    isReviewed: ocrData?.[ocrKeyPath]?.isReviewed,
    ocrKeyPath,
    ocrSectionPath: ocrData?.[ocrKeyPath]?.ocrSectionPath as string,
  };
};
