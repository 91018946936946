import UserAvatar from '@/components/UserAvatar';
import { useProfileController } from '@/hooks';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import LogOutIcon from '@treyd-io/treyd-ui/icons/LogOutIcon';

export default function Profile() {
  const {
    states: { isValid, isSubmitting, errors, isFormTouched, user, formValues },
    actions: { logout, handleSubmit, handleChange },
  } = useProfileController();

  return (
    <Stack gap={4}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}>
        <Typography color="text.primary" variant="h4">
          Profile
        </Typography>
        <Button
          onClick={() => logout()}
          color="error"
          type="button"
          sx={{ alignSelf: 'flex-start' }}
          startIcon={<LogOutIcon />}>
          Log out
        </Button>
      </Stack>

      <Stack component={'form'} onSubmit={handleSubmit} maxWidth={640} gap={2}>
        <Stack gap={3} direction={{ sm: 'row' }} alignItems="center">
          <UserAvatar
            sx={{ width: 100, height: 100, fontSize: 40 }}
            firstName={user?.first_name}
            lastName={user?.last_name}
          />
          <Row>
            <Col xs={12}>
              <TextField
                fullWidth
                label="First name"
                name="firstName"
                variant="outlined"
                value={formValues.firstName}
                onChange={handleChange}
                error={isFormTouched.firstName && Boolean(errors.firstName)}
                helperText={isFormTouched.firstName && errors.firstName}
              />
            </Col>
            <Col xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                variant="outlined"
                value={formValues.lastName}
                onChange={handleChange}
                error={isFormTouched.lastName && Boolean(errors.lastName)}
                helperText={isFormTouched.lastName && errors.lastName}
              />
            </Col>
          </Row>
        </Stack>

        <Typography color="text.primary" variant="body1">
          Email: {user?.email}
        </Typography>
        <Typography variant="body2">
          You can’t change email or password since you’re logged in with SSO.
        </Typography>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          size="large"
          sx={{ alignSelf: { sm: 'flex-start' } }}
          disabled={!isValid}
          loading={isSubmitting}>
          Save changes
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
