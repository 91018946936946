import { gql, TypedDocumentNode } from '@apollo/client';

export type PaymentStatus =
  | 'funds_released'
  | 'failed_swift'
  | 'not_started'
  | 'payment_instructed'
  | 'pending_compliance'
  | 'compliance_approved'
  | 'funds_delivered_guaranteed'
  | 'funds_delivered_assumed'
  | 'failed_treyd';

export enum PAYMENT_STATUS {
  FUNDS_RELEASED = 'funds_released',
  FAILED_SWIFT = 'failed_swift',
  NOT_STARTED = 'not_started',
  PAYMENT_INSTRUCTED = 'payment_instructed',
  PENDING_COMPLIANCE = 'pending_compliance',
  COMPLIANCE_APPROVED = 'compliance_approved',
  FUNDS_DELIVERED_GUARANTEED = 'funds_delivered_guaranteed',
  FUNDS_DELIVERED_ASSUMED = 'funds_delivered_assumed',
  FAILED_TREYD = 'failed_treyd',
}

export interface TrackingEvents {
  charge_amount:
    | {
        amount: string;
        currency: string;
      }[]
    | [];
  charge_type: string | null;
  confirmed_amount: {
    amount: string;
    currency: string;
  } | null;
  foreign_exchange_details: {
    exchange_rate: string;
    source_currency: string | null;
    target_currency: string | null;
  };
  forwarded_to_agent: string | null;
  from: string | null;
  from_bank_address: string | null;
  from_bank_name: string | null;
  funds_available: string | null;
  instructed_amount: {
    amount: string;
    currency: string;
  } | null;
  interbank_settlement_amount: {
    amount: string;
    currency: string;
  } | null;
  interbank_settlement_date: string | null;
  last_update_time: string;
  originator: string | null;
  sender_acknowledgement_receipt: string;
  serial_parties: {
    creditor: string | null;
    creditor_agent: string | null;
    debtor: string | null;
    debtor_agent: string | null;
    instructing_reimbursement_agent: string | null;
    intermediary_agent1: string | null;
  };
  to: string | null;
  to_bank_name: string | null;
  to_bank_address: string | null;
  tracker_event_type: string | null;
  transaction_status: {
    reason: string | null;
    status: string;
  };
  valid: boolean;
}

export type GetPaymentTrackingQueryVariablesType = {
  orderId: number;
};

export interface TrackingInfo {
  completion_time: string | null;
  initiation_time: string | null;
  last_update_time: string;
  payment_events: Record<string, TrackingEvents>;
  transaction_status: {
    reason: string | null;
    status: string;
  };
  uetr: string | null;
}

interface EventAdditionalData {
  expected_payment_date?: string;
  payment_ref?: string;
  expected_arrival_date?: string;
  mt103?: string;
  tracking_info?: TrackingInfo;
}
export interface PaymentEventType {
  event_additional_data: EventAdditionalData;
  event_date: string;
  event_type: PaymentStatus;
  event_order: number;
}

export interface PaymentType {
  internal_payment_events: PaymentEventType[];
  payment_id: number;
}

export type GetPaymentTrackingQueryType = {
  banking: {
    payment_tracking_v2: {
      number_of_payments: number;
      payments: PaymentType[];
    };
  };
};

export const getPaymentTrackingQuery = gql`
  query getPaymentTrackingQuery($orderId: Int!) {
    banking {
      payment_tracking_v2(deal_id: $orderId)
    }
  }
` as TypedDocumentNode<
  GetPaymentTrackingQueryType,
  GetPaymentTrackingQueryVariablesType
>;
