import { createContext } from 'react';

interface SupplierInvoiceContextType {
  isSupplierInvoiceOpen: boolean;
  toggleSupplierInvoice: () => void;
  invoiceUrl?: string;
}
export const SupplierInvoiceContext = createContext<SupplierInvoiceContextType>(
  {
    isSupplierInvoiceOpen: false,
    toggleSupplierInvoice: () => null,
    invoiceUrl: '',
  }
);
