import { CompanyCreditDecision } from '@/schemas';
import { Divider, Stack } from '@mui/material';
import { NewCreditDecision } from './NewCreditDecision';
import { PublishedCreditDecision } from './PublishedCreditDecision';

export interface CreditDecisionSectionProps {
  companyCreditDecision?: CompanyCreditDecision;
  desiredCredit: string;
  remainingCredit: string;
  outstandingCredit: string;
  isPublishedCreditDecisionVisible: boolean;
  companyId: number;
}
export const CreditDecisionSection = ({
  companyCreditDecision,
  desiredCredit,
  remainingCredit,
  outstandingCredit,
  isPublishedCreditDecisionVisible,
  companyId,
}: CreditDecisionSectionProps) => {
  return (
    <Stack
      border={1}
      borderColor="other.outlined_border"
      borderRadius={4}
      padding={3}
      gap={4}>
      <PublishedCreditDecision
        desiredCredit={desiredCredit}
        remainingCredit={remainingCredit}
        outstandingCredit={outstandingCredit}
        isPublishedCreditDecisionVisible={isPublishedCreditDecisionVisible}
        companyCreditDecision={companyCreditDecision}
      />
      <Divider />
      <NewCreditDecision companyId={companyId} />
    </Stack>
  );
};
