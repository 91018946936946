import { gql, TypedDocumentNode } from '@apollo/client';
import { Orders_Orders_App_Consent } from '__generated__/graphql';

export type TermsConsent = Pick<
  Orders_Orders_App_Consent,
  'created' | 'consentable_id'
>;

export const getUsersTermsConsentsQuery = gql`
  query getUsersTermsConsentsQuery($usersIds: [Int!]) {
    orders_orders_app_consent(
      where: {
        _and: {
          consentable_id: { _in: $usersIds }
          type: { _eq: "tos" }
          is_active: { _eq: true }
          consentable_type: { _eq: "user" }
        }
      }
    ) {
      is_active
      consentable_id
      created
    }
  }
` as TypedDocumentNode<
  {
    orders_orders_app_consent: TermsConsent[];
  },
  { usersIds: number[] }
>;
