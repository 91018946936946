import { KYC_STATUS } from '@/constants';
import {
  useEditMerchantKyc,
  useGetMerchantKyc,
  useGetMerchantRiskKyc,
  useRevokeMerchantKyc,
} from '@/hooks';
import { PreviewKycThirdVersion } from '@/pages/merchants/MerchantsDetails/Tabs/Kyc/PreviewKycThirdVersion';
import { Alert, AlertTitle, Box, CircularProgress, Stack } from '@mui/material';
import { Lager_Treyd_Users } from '__generated__/graphql';
import { useAuth } from 'auth';
import { find, isEmpty, toString } from 'lodash';
import { NoKycPlaceholder } from './NoKycPlaceholder';
import { PreviewKycSecondVersion } from './PreviewKycSecondVersion';

const Kyc = ({
  companyId,
  companyLimit,
  country,
  orgNo,
  companyUsers,
}: {
  companyId: number;
  companyLimit: number | undefined;
  country: string;
  orgNo: string;
  companyUsers?: Lager_Treyd_Users[];
}) => {
  const {
    data: companyKycData,
    loading: isLoadingCompanyKycData,
    refetch,
  } = useGetMerchantKyc(companyId);
  const {
    data: companyRiskKycData,
    loading: isLoadingCompanyRiskKycData,
    refetch: refetchCompanyRiskKyc,
  } = useGetMerchantRiskKyc(companyId);
  const { user } = useAuth();

  const [revokeKyc, { loading: isRevokeKycLoading }] = useRevokeMerchantKyc({
    country,
    orgNo,
    revoked_by: user.id,
  });

  const [editKyc, { loading: isEditKycLoading }] = useEditMerchantKyc({
    country,
    orgNo,
    editable_by: user.id,
  });
  const submittedAt =
    companyRiskKycData?.orders_companies_by_pk.core_company?.risk_kycs?.[0]
      ?.created;
  const submittedKycData =
    companyRiskKycData?.orders_companies_by_pk.core_company?.risk_kycs?.[0]
      ?.data;
  const companyKyc = companyKycData?.orders_companies_by_pk?.kyc;
  const kycStatus = companyKyc?.status;
  const isEditable = kycStatus === KYC_STATUS.EDITABLE;
  const isPreview = kycStatus === KYC_STATUS.PREVIEW;
  const isKYCRevoked = Boolean(companyKyc?.revoked_at);
  const submittedKycVersion =
    companyRiskKycData?.orders_companies_by_pk.core_company.risk_kycs[0]
      ?.version;

  const kycSubmitterId = companyKyc?.submited_by;
  const kycSubmitter = toString(
    find(companyUsers, (user) => user.id === kycSubmitterId)?.name
  );

  const handleRevokeKycClick = async () => {
    await revokeKyc();
    await refetch();
    await refetchCompanyRiskKyc();
  };

  const handleEditKycClick = async () => {
    await editKyc();
    await refetch();
    await refetchCompanyRiskKyc();
  };

  if (
    isLoadingCompanyKycData ||
    isLoadingCompanyRiskKycData ||
    isRevokeKycLoading ||
    isEditKycLoading
  )
    return (
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%">
        <CircularProgress size={60} />
      </Box>
    );

  return (
    <Box>
      {isEmpty(submittedKycData) || isKYCRevoked ? (
        <NoKycPlaceholder isKYCRevoked={isKYCRevoked} />
      ) : (
        <Stack gap={2}>
          {isEditable && (
            <Alert severity="warning" variant="outlined">
              <AlertTitle>KYC is about to expire</AlertTitle>
              It is permitted for this merchant to edit and resubmit it.
            </Alert>
          )}
          {submittedKycVersion === 3 ? (
            <PreviewKycThirdVersion
              submittedKycData={submittedKycData}
              submittedAt={submittedAt}
              onRevokeKycClick={handleRevokeKycClick}
              submittedBy={kycSubmitter}
              isEditable={isEditable}
              onEditKycClick={handleEditKycClick}
              isPreview={isPreview}
            />
          ) : (
            <PreviewKycSecondVersion
              submittedKycData={submittedKycData}
              submittedAt={submittedAt}
              onRevokeKycClick={handleRevokeKycClick}
              onEditKycClick={handleEditKycClick}
              submittedBy={kycSubmitter}
              isEditable={isEditable}
              isPreview={isPreview}
            />
          )}
        </Stack>
      )}
    </Box>
  );
};
export default Kyc;
