import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { PAGE_SIZE } from '@/constants';
import { useGetFees } from '@/hooks';
import { Fee } from '@/schemas';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { FilterComparator } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { map, toNumber, toString } from 'lodash';
import { useMemo, useState } from 'react';

export const FeesHistory = ({ companyId }: { companyId: number }) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const columns = useMemo(
    (): GridColDef<Fee>[] => [
      {
        field: 'created_at',
        headerName: 'Period',
        valueGetter: ({ row }) => {
          const creationDate =
            row?.created_at &&
            formatDate(new Date(row?.created_at), 'longdate');
          const archivedDate =
            row?.archived_at &&
            formatDate(new Date(row?.archived_at), 'longdate');

          return `${toString(creationDate)} - ${
            toString(archivedDate) || 'Now'
          }`.trim();
        },
        flex: 1,
        minWidth: 300,
        sortable: false,
      },
      ...map({ length: 5 }, (_, i): GridColDef<Fee> => {
        const tenorNumber = i + 1;
        const days = tenorNumber * 30;
        return {
          field: `${days}_days_fees`,
          headerName: `${days} days`,
          renderCell: (params) => {
            if (
              tenorNumber <=
              toNumber(params?.row?.companies_fee?.max_tenor_in_months)
            )
              return `${toNumber((i + 1) * params?.row?.final_pricing).toFixed(
                2
              )}%`;

            return '-';
          },
          flex: 0,
          minWidth: 75,
          sortable: false,
          align: 'right',
          headerAlign: 'right',
        };
      }),
    ],
    []
  );

  const { data, loading } = useGetFees({
    filters: [
      {
        type: 'filter',
        name: 'company_id',
        comparator: FilterComparator.EQUAL,
        comparisonValue: companyId,
      },
      {
        type: 'filter',
        name: 'archived_at',
        comparator: FilterComparator.IS_NULL,
        comparisonValue: false,
      },
    ],
    sorting: { field: 'id', sort: 'desc' },
    limit: paginationModel?.pageSize,
    offset: paginationModel?.page * paginationModel?.pageSize,
  });

  const fees = data?.orders_companies_final_pricing_log || [];
  const rowCount = data?.orders_companies_fees_aggregate?.aggregate?.count || 0;

  const noFeesProps = !rowCount && {
    columnHeaders: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="h6" color="text.primary">
          Fees History
        </Typography>
        <Typography variant="body1" color="text.primary">
          A list of fees history changes over time.
        </Typography>
      </Stack>
      <Stack height={300} width="100%">
        <DataGrid
          rows={fees}
          columns={columns}
          pageSizeOptions={[]}
          disableRowSelectionOnClick
          sortingMode="server"
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          paginationModel={paginationModel}
          rowCount={rowCount}
          loading={loading}
          slots={{
            noRowsOverlay: () => (
              <TableHasNoRowsPlaceholder
                title={'Nothing here yet'}
                subtitle={'Fees history be displayed here.'}
              />
            ),
            ...noFeesProps,
          }}
          {...noFeesProps}
        />
      </Stack>
    </Stack>
  );
};
