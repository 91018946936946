import { PERMISSION_GROUPS_NAMES } from '@/constants';
import {
  AbilitySubject,
  AllAbilityAction,
  AppAbility,
  PermissionGroup,
  permissionsGenerators,
} from '@/types';
import { Ability, AbilityBuilder } from '@casl/ability';

export const defineAbility = (
  userPermissionGroups?: PermissionGroup[]
): AppAbility => {
  const {
    can: allow,
    cannot: forbid,
    build,
  } = new AbilityBuilder<AppAbility>(Ability);
  if (userPermissionGroups) {
    const permissionsGenerators: permissionsGenerators = {
      [PERMISSION_GROUPS_NAMES.CREDIT_OFFICER]: createCreditOfficerPermissions,
      [PERMISSION_GROUPS_NAMES.TREYD_OPERATOR]: createTreydOperatorPermissions,
      [PERMISSION_GROUPS_NAMES.COMPLIANCE_OFFICER]:
        createComplianceOfficerPermissions,
      [PERMISSION_GROUPS_NAMES.FUNDING_OFFICER]:
        createFundingOfficerPermissions,
      [PERMISSION_GROUPS_NAMES.COMPANY_ADMIN]: createCompanyAdminPermissions,
      [PERMISSION_GROUPS_NAMES.CREDIT_ANALYST]: createCreditAnalystPermissions,
      [PERMISSION_GROUPS_NAMES.EMPLOYEE]: createEmployeePermissions,
      [PERMISSION_GROUPS_NAMES.TECH_SUPPORT]: createTechSupportPermissions,
      [PERMISSION_GROUPS_NAMES.PRICING_ADMIN]: createPricingAdminPermissions,
    };
    userPermissionGroups.forEach((group) =>
      permissionsGenerators[group.auth_group.name as PERMISSION_GROUPS_NAMES]?.(
        allow,
        forbid
      )
    );
  }
  return build();
};

type CanBuilder = (
  action: AllAbilityAction | AllAbilityAction[],
  subject: AbilitySubject | AbilitySubject[]
) => void;

const createCreditOfficerPermissions = (
  _allow: CanBuilder,
  _forbid: CanBuilder
) => {};

const createTreydOperatorPermissions = (
  allow: CanBuilder,
  _forbid: CanBuilder
) => {
  allow('edit', 'supplier_kyc');
  allow(['edit', 'send_back'], 'order');
  allow(['add', 'edit', 'submit'], 'order_review');
};

const createTechSupportPermissions = (
  allow: CanBuilder,
  _forbid: CanBuilder
) => {
  allow('read', 'dev');
  allow('delete', 'order_review_comment');
};

const createCompanyAdminPermissions = (
  _allow: CanBuilder,
  _forbid: CanBuilder
) => {};

const createCreditAnalystPermissions = (
  _allow: CanBuilder,
  _forbid: CanBuilder
) => {};

const createComplianceOfficerPermissions = (
  allow: CanBuilder,
  _forbid: CanBuilder
) => {
  allow('edit', 'orders_app_compliance_review');
  allow('edit', 'bank_account_review');
  allow('edit', 'supplier_kyc');
  allow('edit', 'supplier_monitoring');
};

const createFundingOfficerPermissions = (
  allow: CanBuilder,
  _forbid: CanBuilder
) => {
  allow('edit', 'orders_app_funding_review');
};

const createPricingAdminPermissions = (
  allow: CanBuilder,
  _forbid: CanBuilder
) => {
  allow('read', 'merchant_fees');
  allow('edit', 'merchant_fees');
};

const createEmployeePermissions = (allow: CanBuilder, _forbid: CanBuilder) => {
  allow('read', 'orders-review');
  allow('read', 'orders');
  allow('read', 'invoices');
  allow('read', 'erp-invoices');
  allow('read', 'transactions');
  allow('read', 'blog');
  allow('read', 'credits-and-vouchers');
  allow('read', 'merchants');
  allow('read', 'suppliers');
  allow('read', 'notifications-and-emails');
};
