import { DEV_ROUTES, ROUTES } from '@/constants';
import DevAppIndex from '@/pages/dev/DevAppIndex';
import {
  EventDetails,
  FeatureFlagsDetails,
  UserDetails,
} from '@/pages/dev/components';

import { Can } from 'auth';
import { RouteObject } from 'react-router-dom';

export const DEV: RouteObject = {
  path: `${ROUTES.dev}`,
  children: [
    {
      index: true,
      element: (
        <Can I="read" a="dev">
          <DevAppIndex />
        </Can>
      ),
    },
    {
      path: `${DEV_ROUTES.user_details}`,
      element: <UserDetails />,
    },
    {
      path: `${DEV_ROUTES.event_details}`,
      element: <EventDetails />,
    },
    {
      path: `${DEV_ROUTES.feature_flag_details}`,
      element: <FeatureFlagsDetails />,
    },
  ],
};
