import { useGetNotes, useInsertNotes, useUpdateNotes } from '@/hooks';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Formik } from 'formik';
import { toString } from 'lodash';
interface MerchantNoteDialogProps {
  open: boolean;
  onClose: () => void;
  merchantId: number;
}

export const MerchantNoteDialog = (props: MerchantNoteDialogProps) => {
  const { onClose, open, merchantId } = props;
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('md'));
  const [insertNotes, { loading: isCreatingNotes }] = useInsertNotes();
  const [updateNotes, { loading: isUpdatingNotes }] = useUpdateNotes();
  const { data: noteData, refetch: refetchMerchantNotes } = useGetNotes({
    entity_id: merchantId,
    entity_type: 'merchant',
  });
  const note = toString(noteData?.orders_orders_app_notes?.[0]?.note);

  const handleNoteUpdate = (newNote: string) =>
    updateNotes({
      variables: {
        entity_id: merchantId,
        entity_type: 'merchant',
        note: newNote,
      },
      onCompleted: () => {
        refetchMerchantNotes();
        onClose();
      },
    });

  const handleNoteCreate = async (note: string) => {
    const date = new Date().toJSON();
    return insertNotes({
      variables: {
        entity_id: merchantId,
        entity_type: 'merchant',
        note,
        created_at: date,
      },
      onCompleted: () => {
        refetchMerchantNotes();
        onClose();
      },
    });
  };
  const isLoading = isUpdatingNotes || isCreatingNotes;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isSmallScreens}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="h5" noWrap color="text.primary">
          Merchant note
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{ note }}
        enableReinitialize
        validateOnMount
        onSubmit={(values) => {
          note ? handleNoteUpdate(values.note) : handleNoteCreate(values.note);
        }}>
        {(formik) => (
          <Stack
            component="form"
            display="contents"
            onSubmit={formik.handleSubmit}>
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                paddingBlockEnd: 3,
              }}>
              <Typography variant="body1">
                Shown in order review. Good for adding special conditions or
                agreements.
              </Typography>
              <Divider />
              <TextField
                name="note"
                value={formik.values?.note}
                onChange={formik.handleChange}
                label="Add a note"
                multiline
                rows={4}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                type="button"
                onClick={onClose}
                variant="outlined"
                color="secondary">
                Cancel
              </Button>
              <LoadingButton
                loading={isLoading}
                size="large"
                type="submit"
                disabled={!formik.dirty}
                variant="contained">
                Save
              </LoadingButton>
            </DialogActions>
          </Stack>
        )}
      </Formik>
    </Dialog>
  );
};
