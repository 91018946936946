import { Supplier, SupplierBankAccount } from '@/schemas';

import { BankAccountInfoCard } from '@/components/Bank/BankAccountInfoCard';
import { DeactivateBankAccountDialog } from '@/components/Bank/DeactivateBankAccountDialog';
import {
  useDeactivateSupplierBankAccount,
  useGetSupplierBankAccounts,
} from '@/hooks';
import BankAccountDialog from '@/pages/suppliers/SupplierDetails/Tabs/BankInfoTab/BankAccountDialog';
import { getBankTypeLabel } from '@/pages/suppliers/SupplierDetails/Tabs/BankInfoTab/PaymentTypes';
import { getBankInfo } from '@/utils/bank';
import { Box, Button, Stack, Typography } from '@mui/material';
import PlusIcon from '@treyd-io/treyd-ui/icons/PlusIcon';
import { find, map, toString } from 'lodash';
import { useState } from 'react';
import { useToggle } from 'react-use';

const BankInfoTab = ({ supplier }: { supplier?: Supplier }) => {
  const [open, toggle] = useToggle(false);
  const { data, refetch: refetchSupplierBankAccounts } =
    useGetSupplierBankAccounts(supplier?.id);
  const [deactivateSupplierBankAccount] = useDeactivateSupplierBankAccount();
  const bankAccounts = data?.orders_suppliers_by_pk.bank_accounts;
  const [selectedBankAccountId, setSelectedBankAccountId] = useState();
  const [isDeactivationDialogOpen, toggleDeactivationDialogOpen] =
    useToggle(false);

  const accountType = getBankTypeLabel(
    find(
      bankAccounts,
      (bankAccount) => bankAccount?.id === selectedBankAccountId
    )?.payment_type || ''
  );

  const getBankAccountErrors = (
    bankAccount?: SupplierBankAccount,
    supplier?: Supplier
  ) => {
    const errors: string[] = [];
    const supplierCountry = toString(supplier?.country).toLocaleLowerCase();
    const bankAccountCountry = toString(
      bankAccount?.bank_country
    ).toLocaleLowerCase();
    if (supplierCountry !== bankAccountCountry)
      errors.push(`Bank account country is not matching the supplier country`);
    return errors;
  };

  const onSuccess = () => {
    refetchSupplierBankAccounts();
    toggle();
  };
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBlock: 2,
        }}>
        <Typography variant="h5">Bank accounts</Typography>
        <Button
          startIcon={<PlusIcon />}
          variant="outlined"
          color="secondary"
          onClick={toggle}>
          Add bank account
        </Button>
      </Box>
      <Stack gap={2}>
        {map(bankAccounts, (bankAccount) => (
          <BankAccountInfoCard
            key={bankAccount.id}
            bankAccountId={bankAccount.id}
            bankAccountCompliance={bankAccount.compliance}
            onBankAccountReviewSubmit={refetchSupplierBankAccounts}
            title={getBankTypeLabel(bankAccount.payment_type || '')}
            accountInfo={getBankInfo(bankAccount)}
            onDeactivateClick={() => {
              setSelectedBankAccountId(bankAccount.id);
              toggleDeactivationDialogOpen();
            }}
            isActive={!Boolean(bankAccount.deactivated_at)}
            errors={getBankAccountErrors(bankAccount, supplier)}
            awx_errors={bankAccount.awx_bene_val_errors}
          />
        ))}
      </Stack>
      <BankAccountDialog
        onSuccess={onSuccess}
        onClose={toggle}
        open={open}
        entity="supplier"
        entityId={supplier?.id}
        entityCountry={toString(supplier?.country)}
      />
      {selectedBankAccountId && (
        <DeactivateBankAccountDialog
          isOpen={isDeactivationDialogOpen}
          accountType={accountType}
          onClose={toggleDeactivationDialogOpen}
          onDeactivateClick={() => {
            toggleDeactivationDialogOpen();
            deactivateSupplierBankAccount({
              variables: {
                supplierId: supplier?.id,
                bankAccountId: selectedBankAccountId,
              },
              onCompleted: () => {
                refetchSupplierBankAccounts();
              },
            });
          }}
        />
      )}
    </Box>
  );
};

export default BankInfoTab;
