import { MerchantWithLink } from '@/components';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import {
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  TRANSACTION_TYPE,
  transactionsTypesDisplayedValue,
} from '@/constants';
import { useGetTransactions } from '@/hooks';
import { Transaction } from '@/schemas';
import { Stack } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { Filter } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { toString } from 'lodash';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

export const TransactionsTable = ({ filters }: { filters?: Filter[] }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'payment_date', sort: 'desc' },
  ]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE,
    page: 0,
  });
  const columns: GridColDef<Transaction>[] = useMemo(
    () => [
      {
        field: 'invoice__order_id',
        headerName: 'Order #',
        flex: 0,
        minWidth: 90,
        renderCell: (params) => {
          const orderId = params.row?.invoices?.[0]?.order_id;
          return <Link to={`${ROUTES.orders}/${orderId}`}>{orderId}</Link>;
        },
      },
      {
        field: 'invoice__order__importer__name',
        headerName: 'Merchant',
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: (params) => {
          const id = params.row?.invoices?.[0]?.order?.importer?.id;
          const name = params.row?.invoices?.[0]?.order?.importer?.name;
          const segment = params.row?.invoices?.[0]?.order?.importer?.segment;
          return (
            name && <MerchantWithLink id={id} name={name} segment={segment} />
          );
        },
      },
      {
        field: 'invoice__financier__name',
        headerName: 'Financier',
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: (params) => params.row.invoices[0]?.financier?.name,
      },
      {
        field: 'payment_date',
        headerName: 'Payment date',
        flex: 1,
        minWidth: 100,
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
      {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 150,
        flex: 1,
        cellClassName: 'tabular-numbers',
        renderCell: (params) =>
          formatCurrency(params.value, toString(params.row?.amount_currency)),
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 0,
        align: 'right',
        headerAlign: 'right',
        minWidth: 150,
        renderCell: (params) =>
          transactionsTypesDisplayedValue[params.value as TRANSACTION_TYPE],
      },
    ],
    []
  );

  const { data, loading: isLoadingTransactions } = useGetTransactions({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: paginationModel?.pageSize,
      offset: paginationModel?.page * paginationModel?.pageSize,
    },
  });

  const transactions = data?.invoicing_app_transaction || [];
  const rowCount =
    data?.invoicing_app_transaction_aggregate?.aggregate?.count || 0;

  const noTransactionsProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  return (
    <Stack flexGrow={1} height={400} width="100%">
      <DataGrid
        rows={transactions}
        columns={columns}
        disableRowSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        loading={isLoadingTransactions}
        onSortModelChange={setSortModel}
        slots={{
          noRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'Nothing here yet'}
              subtitle={'Transactions will be displayed here'}
            />
          ),
          ...noTransactionsProps,
        }}
        {...noTransactionsProps}
      />
    </Stack>
  );
};
