import {
  Orders_Companies,
  Orders_Orders_Aggregate,
  Orders_Suppliers,
} from '@/__generated__/graphql';
import {
  COMMERCIAL_STATUS,
  MERCHANT_SEGMENT,
  ONBOARDING_STATUS,
} from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';

type Merchant = Pick<
  Orders_Companies,
  | 'id'
  | 'name'
  | 'org_no'
  | 'country'
  | 'financier'
  | 'created_at'
  | 'rating'
  | 'limit'
  | 'limit_currency'
  | 'website'
  | 'core_company'
  | 'address'
  | 'zip_code'
  | 'town'
  | 'company_users'
  | 'kyc'
  | 'is_funding_secured'
  | 'is_active'
  | 'has_overdue'
  | 'has_repayment_plan'
  | 'hubspot_vid'
  | 'desired_limit'
  | 'desired_limit_currency'
  | 'monthly_interest_deduction'
  | 'sales_representative_id'
  | 'main_contact_id'
  | 'invoicing_currency'
> & {
  active_combined_status: {
    status: ONBOARDING_STATUS | COMMERCIAL_STATUS;
    label: string;
  };
  erp_system: string | null;
  supplier: Pick<Orders_Suppliers, 'name'>;
  orders_count: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  segment: MERCHANT_SEGMENT;
  first_paid_order: { id: number } | null;
};

type getMerchantDetailsQueryTypes = {
  orders_companies_by_pk: Merchant;
};

export const getMerchantDetails = gql`
  query MerchantDetails($id: bigint!) {
    orders_companies_by_pk(id: $id) {
      id
      name
      country
      org_no
      segment
      created_at
      financier {
        name
      }
      rating
      limit
      limit_currency
      town
      address
      zip_code
      website
      is_funding_secured
      desired_limit
      desired_limit_currency
      monthly_interest_deduction
      hubspot_vid
      invoicing_currency
      main_contact_id
      active_combined_status {
        status
        label
      }
      company_users {
        id
        name
        can_sign_alone
        is_ubo
        is_active
        email
      }
      kyc
      is_active
      erp_system
      sales_representative_id
      core_company {
        description
        registration_date
        risk_kycs(where: { is_active: { _eq: true } }) {
          created
        }
        latest_published_credit_decision {
          deactivation_reason
        }
        core_companyindustry {
          core_industrymacrosector {
            name
          }
          core_industrymicrosector {
            name
          }
        }
        active_erp_connection {
          integration_id
        }
      }
      orders_count: orders_aggregate {
        aggregate {
          count
        }
      }
      first_paid_order: orders(
        where: { _and: { payouts: { status: { _eq: "paid" } } } }
        limit: 1
        order_by: { active_payout: { actual_payment_date: asc } }
      ) {
        id
      }
    }
  }
` as TypedDocumentNode<getMerchantDetailsQueryTypes, {}>;
