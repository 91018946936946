import { ComponentLoader } from '@/components/ComponentLoader';
import { useGetLimitStatus } from '@/hooks';
import { LimitStatusService } from '@/services/LimitStatusService';
import { Segment } from '@/types';
import { Timeline, timelineItemClasses } from '@mui/lab';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { capitalCase } from 'capital-case';
import { findLastKey, map } from 'lodash';
import { EmptySection } from '../Credit/EmptySection';

export const LimitStatus = ({ companyId }: { companyId: number }) => {
  const { data: limitStatus, isPending } = useGetLimitStatus(companyId);
  if (isPending) return <ComponentLoader />;

  if (!limitStatus)
    return (
      <EmptySection
        title="No limit status available"
        subtitle="There might be an issue, or there's no limit status yet."
      />
    );

  const { statusChip, segments } =
    LimitStatusService.getLimitStatus(limitStatus);

  return (
    <>
      <Stack gap={2} marginBottom={3}>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <Typography variant="h5">Limit status</Typography>
          {statusChip}
        </Stack>
        <Typography variant="body1">
          This section provides insights into the merchant's status, including
          credit team actions, finance team updates, and operational operations.
          Notably, it incorporates measures to prevent the company from adding
          new orders.
        </Typography>
      </Stack>
      <Box marginBottom={3} borderRadius={2} border={1} borderColor="grey.300">
        {map(segments, (segment, key) => (
          <Row key={key}>
            <Col xs={12} md={12}>
              {segment && (
                <LimitStatusSegmentHeader title={key} segment={segment} />
              )}
            </Col>
            <Col xs={12} md={12}>
              {segment && <LimitStatusSegmentBody segment={segment} />}
            </Col>
            <Col xs={12} md={12}>
              {findLastKey(segments) !== key && <Divider />}
            </Col>
          </Row>
        ))}
      </Box>
    </>
  );
};

const LimitStatusSegmentHeader = ({
  title,
  segment,
}: {
  title: string;
  segment: Segment.Values;
}) => (
  <Stack
    direction={'row'}
    alignItems={'center'}
    gap={2}
    paddingX={3}
    paddingTop={3}>
    {segment.icon}

    <Typography variant="subtitle1">{capitalCase(title)}</Typography>

    {segment?.chip}
  </Stack>
);

const LimitStatusSegmentBody = ({ segment }: { segment: Segment.Values }) => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          paddingInline: { xs: 2, md: 2 },
        },
        padding: 0,
        marginTop: 0,
      }}>
      {map(segment?.points, (value) => value)}
      {map(segment?.alerts, (alert) => alert)}
    </Timeline>
  );
};
