import { useGetGlobalSearchResults } from '@/hooks';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useDebouncedState } from '@treyd-io/core/hooks/useDebouncedState';
import CloseIcon from '@treyd-io/treyd-ui/icons/CloseIcon';
import UnorderedListIcon from '@treyd-io/treyd-ui/icons/UnorderedListIcon';
import SearchField from '../SearchField';
import { InvoicesTable } from './InvoicesTable';
import { MerchantsTable } from './MerchantsTable';
import { OrdersTable } from './OrdersTable';

interface GlobalSearchDialogProps {
  open: boolean;
  onClose: () => void;
}

export const GlobalSearchDialog = (props: GlobalSearchDialogProps) => {
  const { open, onClose } = props;
  const [searchTerm, setSearchTerm] = useDebouncedState('', 500);
  const { data: searchResults, loading: isSearchLoading } =
    useGetGlobalSearchResults(searchTerm);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullScreen
      PaperProps={{
        sx: {
          borderRadius: 0,
        },
      }}
      keepMounted={false}>
      <DialogTitle display="flex" flexDirection="column" gap={1}>
        <Stack direction="row" alignItems="center" gap={2}>
          <SearchField
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            autoFocus
            placeholder="Search..."
            type="text"
            withClearButton={false}
          />
          <IconButton size="large" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        {searchTerm && (
          <Stack color="text.secondary" alignItems="center" flexDirection="row">
            <UnorderedListIcon />
            <Typography variant="body1" marginLeft={1}>
              search results for “{searchTerm}”
            </Typography>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent>
        {isSearchLoading ? (
          <Stack height="100%" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack gap={2}>
            <MerchantsTable
              merchants={searchResults?.orders_companies}
              searchTerm={searchTerm}
            />
            <OrdersTable
              orders={searchResults?.orders_orders}
              searchTerm={searchTerm}
            />
            <InvoicesTable
              invoices={searchResults?.orders_order_invoices}
              searchTerm={searchTerm}
            />
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};
