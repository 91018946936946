import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import IconButton from '@treyd-io/treyd-ui/components/IconButton';
import ClickPulseIcon from '@treyd-io/treyd-ui/icons/ClickPulseIcon';
import DotsVerticalIcon from '@treyd-io/treyd-ui/icons/DotsVerticalIcon';
import WriteNoteIcon from '@treyd-io/treyd-ui/icons/WriteNoteIcon';
import { toNumber } from 'lodash';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import { HubspotActionsDialog } from './HubspotActionsDialog';
import { MerchantNoteDialog } from './MerchantNoteDialog';
interface ActionsMenuProps {
  hubspotId: string;
  salesRepresentativeId: number | null;
  onRefetchMerchantDetails: () => void;
}
export const ActionsMenu = (props: ActionsMenuProps) => {
  const { hubspotId, salesRepresentativeId, onRefetchMerchantDetails } = props;
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const merchantId = toNumber(id);
  const [isNoteDialogOpen, toggleNoteDialog] = useToggle(
    searchParams.get('actions') === 'notes'
  );
  const [isHubspotActionsDialogOpen, toggleHubspotActionsDialog] =
    useToggle(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isActionsMenuOpen = Boolean(anchorEl);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="secondary"
        size="large"
        variant="outlined">
        <DotsVerticalIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isActionsMenuOpen}
        onClose={handleMenuClose}
        elevation={1}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: 'background.default',
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuItem
          onClick={() => {
            toggleHubspotActionsDialog();
            handleMenuClose();
          }}>
          <ListItemIcon>
            <ClickPulseIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Hubspot actions</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggleNoteDialog();
            handleMenuClose();
          }}>
          <ListItemIcon>
            <WriteNoteIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Merchant note</ListItemText>
        </MenuItem>
      </Menu>
      <MerchantNoteDialog
        open={isNoteDialogOpen}
        onClose={toggleNoteDialog}
        merchantId={merchantId}
      />
      <HubspotActionsDialog
        open={isHubspotActionsDialogOpen}
        onClose={toggleHubspotActionsDialog}
        onSaveSuccess={onRefetchMerchantDetails}
        merchantId={merchantId}
        hubspotId={hubspotId}
        salesRepresentativeId={salesRepresentativeId}
      />
    </>
  );
};
