import { VoucherStatus } from '@/constants';
import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import { Orders_Vouchers_App_Voucher } from '__generated__/graphql';

export type Voucher = Pick<
  Orders_Vouchers_App_Voucher,
  'id' | 'created' | 'type' | 'order_id' | 'value' | 'currency' | 'slug'
> & {
  status: VoucherStatus;
};

type GetVouchersQueryTypes = {
  orders_vouchers_app_voucher: Voucher[];
  orders_vouchers_app_voucher_aggregate: { aggregate: { count: number } };
};

export const getVouchersQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getVouchers{
    orders_vouchers_app_voucher ${queryParams} {
      id
      created
      type
      order_id
      value
      currency
      slug
      status
	}
	orders_vouchers_app_voucher_aggregate ${
    whereClause ? `(where: ${whereClause})` : ''
  } {
		aggregate {
			count
		}
	}
	
  }
  ` as TypedDocumentNode<GetVouchersQueryTypes>;
};
