import { getCreditsQuery } from '@/schemas/getCreditsQuery';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetCredits = ({
  queryOptions,
  skip,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(getCreditsQuery(queryOptions), {
    skip,
  });
