import { TypedDocumentNode, gql } from '@apollo/client';
import { Lager_Core_Companycomment } from '__generated__/graphql';

type GetCompanyCreditReviewBlockReasonQueryType = {
  orders_companies_by_pk: {
    core_company: {
      comments: Pick<Lager_Core_Companycomment, 'text'>[];
    };
  };
};

export type GetCompanyCreditReviewBlockReasonQueryVariables = {
  companyId: number;
};

export const getCompanyCreditReviewBlockReasonQuery = gql`
  query getCompanyCreditBlockReason($companyId: bigint!) {
    orders_companies_by_pk(id: $companyId) {
      core_company {
        comments(
          where: {
            category: { _in: ["review_blocked", "underwriting_blocked"] }
          }
          order_by: { created: desc }
          limit: 1
        ) {
          text
        }
      }
    }
  }
` as TypedDocumentNode<
  GetCompanyCreditReviewBlockReasonQueryType,
  GetCompanyCreditReviewBlockReasonQueryVariables
>;
