import Col from '@/components/Grid/Col';
import { Onfido } from '@/types/onfido';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { CopyButton } from '@treyd-io/treyd-ui/components/CopyButton';
import DialogCloseButton from '@treyd-io/treyd-ui/components/DialogCloseButton';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import { forEach, isArray, isEmpty, map, reduce, startCase } from 'lodash';

interface OnfidoCheckDialogProps {
  onClose: () => void;
  report: Onfido.Report | null;
}

export const OnfidoCheckDialog = (props: OnfidoCheckDialogProps) => {
  const { onClose, report } = props;
  const open = !!report;
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('sm'));

  const properties = reduce(
    report?.properties,
    (acc: Record<string, string>, value, key) => {
      if (typeof value === 'string') {
        acc[key] = value;
      }
      if (isArray(value)) {
        forEach(value, (item) => {
          acc[item.type] = item.value;
        });
      }
      return acc;
    },
    {}
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isSmallScreens}
      fullWidth
      maxWidth="md">
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="h5" noWrap color="text.primary">
          Onfido report
        </Typography>
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={{ paddingBlockEnd: 3 }}>
        {!isEmpty(properties) ? (
          <Row>
            {map(properties, (value, key) => (
              <Col xs={6}>
                <TwoLines
                  label={startCase(key)}
                  content={
                    <Stack direction="row" gap={1}>
                      <Typography noWrap title={value || '-'}>
                        {value || '-'}
                      </Typography>
                      {value && (
                        <CopyButton
                          value={value}
                          iconProps={{ sx: { color: 'info.main' } }}
                        />
                      )}
                    </Stack>
                  }
                  contentProps={{
                    noWrap: true,
                  }}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Typography variant="body1" textAlign="center">
            No data
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};
