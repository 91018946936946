import {
  Lager_Auth_User,
  Orders_Notification_Center_Notificationgroup,
  Orders_Notification_Center_Notificationtemplate,
} from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';
import { AlertColor } from '@mui/material';

export type NotificationTemplate = Pick<
  Orders_Notification_Center_Notificationtemplate,
  'header' | 'body' | 'action_text' | 'action_url'
> & {
  type: AlertColor;
};

export type NotificationGroup = Pick<
  Orders_Notification_Center_Notificationgroup,
  'id' | 'created' | 'published_at' | 'created_by' | 'targets_ids' | 'published'
> & {
  user: Pick<Lager_Auth_User, 'first_name' | 'last_name'>;
  notification_template: NotificationTemplate;
};
interface NotificationGroupQuery {
  orders_notification_center_notificationgroup_by_pk: NotificationGroup;
}
export const getNotificationGroupQuery = gql`
  query getNotificationGroup($group_id: bigint!) {
    orders_notification_center_notificationgroup_by_pk(id: $group_id) {
      id
      created
      published_at
      created_by
      targets_ids
      published
      user {
        first_name
        last_name
      }
      notification_template {
        header
        body
        type
        action_text
        action_url
      }
    }
  }
` as TypedDocumentNode<NotificationGroupQuery, { group_id: number }>;
