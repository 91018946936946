export const openURL = (url?: string, target = '_blank') => {
  if (!url) return;
  var a = window.document.createElement('a');
  a.target = target;
  a.href = url;

  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(event);
};
