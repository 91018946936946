import { MAPPED_ORDER_STATUS } from '@/constants';
import ApprovedOrderActionButtons from '@/pages/orderReview/OrderReviewDetails/ApprovedOrderActionButtons';
import ComplianceActionButtons from '@/pages/orderReview/OrderReviewDetails/Compliance/ComplianceActionButtons';
import FundingActionButtons from '@/pages/orderReview/OrderReviewDetails/FundingActionButtons';
import OpsActionsButtons from '@/pages/orderReview/OrderReviewDetails/ReviewActionsHeader/OpsActionButtons';
import Order from '@/pages/orderReview/models/Order';

type ReviewActionButtonsProps = {
  order: Order;
  actionsDisabled: boolean;
  goBack: () => void;
  areAllSectionsApproved: boolean;
  dealDuplicates?: Order[];
};

const ReviewActionButtons = (props: ReviewActionButtonsProps) => {
  const {
    order,
    actionsDisabled,
    goBack,
    areAllSectionsApproved,
    dealDuplicates,
  } = props;

  return (
    <>
      {order?.mapped_status === MAPPED_ORDER_STATUS.AWAITING_APPROVAL && (
        <OpsActionsButtons
          orderId={order?.id}
          actionsDisabled={actionsDisabled}
          goBack={goBack}
          areAllSectionsApproved={areAllSectionsApproved}
          dealDuplicates={dealDuplicates}
        />
      )}
      {order?.mapped_status ===
        MAPPED_ORDER_STATUS.AWAITING_COMPLIANCE_APPROVAL && (
        <ComplianceActionButtons
          order={order}
          actionsDisabled={actionsDisabled}
          goBack={goBack}
        />
      )}
      {order?.mapped_status ===
        MAPPED_ORDER_STATUS.AWAITING_FUNDING_APPROVAL && (
        <FundingActionButtons
          order={order}
          actionsDisabled={actionsDisabled}
          goBack={goBack}
        />
      )}
      {order?.mapped_status === MAPPED_ORDER_STATUS.READY_FOR_PAYMENT && (
        <ApprovedOrderActionButtons goBack={goBack} orderId={order?.id} />
      )}
    </>
  );
};

export default ReviewActionButtons;
