import { isValid as isValidFinnish } from 'finnish-personal-identity-code-validator';
import { NorwegianId } from 'norwegian-national-id-validator';
import personnummer from 'personnummer';

export const sanitizePersonalNumber = (personalNumber: string) =>
  personalNumber ? personalNumber?.replace(/[^0-9]/g, '') : '';

export const validatePersonalNumber = (
  personalNumber: string,
  country: string
) => {
  if (!personalNumber) return { valid: false };
  if (country === 'se') {
    const looseValid = personnummer.valid(personalNumber);
    const numOnlyValue = sanitizePersonalNumber(personalNumber);

    const has12Digits = numOnlyValue.length === 12;
    const strictValid = looseValid && has12Digits;

    return { valid: strictValid, country };
  } else if (country === 'no') {
    const valid = NorwegianId(personalNumber).isValid();
    return { valid, country };
  } else if (country === 'fi') {
    const valid = isValidFinnish(personalNumber);
    return { valid, country };
  } else if (country === 'dk') {
    const valid = personalNumber.match(
      /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/
    );
    return { valid: Boolean(valid), country };
  }
  return { valid: false, country };
};

export const isValidPersonalNumber = (personalNumber: string) => {
  const countries = ['se', 'no', 'fi', 'dk'];
  const validPN = countries
    .map((country) => validatePersonalNumber(personalNumber, country))
    ?.find((personalNumber) => personalNumber.valid);
  return validPN || { valid: false };
};

export const formatPersonalNumber = (personalNumber: string) => {
  if (!personalNumber) {
    return '';
  }

  const dashLimit = 4;
  let formattedPersonalNumber = personalNumber.toString();
  formattedPersonalNumber = formattedPersonalNumber.replace(/-/g, '');
  return `${formattedPersonalNumber.slice(
    0,
    formattedPersonalNumber.length - dashLimit
  )}-${formattedPersonalNumber.slice(-dashLimit)}`;
};
