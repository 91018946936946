import { MembersTable, UsersTable } from '@/pages/dev/components';
import { CompanyUserType, MemberType } from '@/schemas';

interface CompanyTablesProps {
  companyUsers?: CompanyUserType[];
  companyMembers?: MemberType[];
  companyID: number | string;
}

export const CompanyTables = ({
  companyUsers,
  companyMembers,
  companyID,
}: CompanyTablesProps) => {
  return (
    <>
      {companyUsers && (
        <UsersTable users={companyUsers} companyID={companyID} />
      )}
      {companyMembers && <MembersTable members={companyMembers} />}
    </>
  );
};
