import { NotificationIsPublishedLabel } from '@/constants';
import { NotificationService } from '@/services';
import { Chip, ChipProps } from '@mui/material';

interface Props extends ChipProps {
  isPublished?: boolean;
}

const chipProps: {
  [key in NotificationIsPublishedLabel]?: ChipProps;
} = {
  [NotificationIsPublishedLabel.PUBLISHED]: {
    label: NotificationIsPublishedLabel.PUBLISHED,
    variant: 'standard',
    color: 'success',
  },
  [NotificationIsPublishedLabel.UNPUBLISHED]: {
    label: NotificationIsPublishedLabel.UNPUBLISHED,
    variant: 'standard',
    color: 'error',
  },
};

export const NotificationStatusChip = (props: Props) => {
  const { isPublished, ...rest } = props;
  const status = NotificationService.getNotificationStatusLabel(isPublished);

  return <Chip {...chipProps[status]} {...rest} />;
};
