import { Alert, AlertTitle } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';

export const MissingTransactionsAlert = () => (
  <Alert severity="warning" variant="outlined">
    <AlertTitle>Missing transactions</AlertTitle>
    Transactions before {formatDate(new Date('01-01-2023'), 'longdate')} may not
    have been properly migrated from our previous invoicing systems. We are
    actively working to correcting any missing transactions. Thank you for your
    understanding as we ensure accuracy in our records.
  </Alert>
);
