import Order from '@/pages/orderReview/models/Order';
import {
  Alert,
  AlertTitle,
  Stack,
  Typography,
  alertClasses,
} from '@mui/material';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { TwoLines } from '@treyd-io/treyd-ui/components/TwoLines';
import { toString } from 'lodash';
import { UpdatesDialog } from './UpdatesDialog';

export const OrderInformationSection = ({ order }: { order: Order }) => {
  const payoutAmount = order?.payouts[0]?.amount;
  const payoutCurrency = order?.payouts[0]?.currency;
  const payoutDataExists = payoutAmount && payoutCurrency;
  const orderAdditionalNotes = toString(order?.additional_notes).trim();

  return (
    <Stack
      borderRadius={4}
      border={1}
      borderColor={'other.outlined_border'}
      padding={{ xs: 3, md: 4 }}>
      <Stack direction="column" gap={3}>
        <Row alignItems="center">
          <Col xs>
            <Typography variant="h6">Order information</Typography>
          </Col>
          <Col>
            <UpdatesDialog />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={4}>
            <TwoLines
              label="Goods value"
              content={formatCurrency(
                order?.supplier_invoice?.amount || 0,
                order?.supplier_invoice?.currency
              )}
              contentProps={{
                noWrap: true,
              }}
            />
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <TwoLines
              label="Payout"
              content={
                payoutDataExists
                  ? formatCurrency(payoutAmount, payoutCurrency)
                  : '-'
              }
              contentProps={{
                noWrap: true,
              }}
            />
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <TwoLines
              label="Quality inspection"
              content={order?.can_skip_qa_check ? 'Exempt' : 'Not exempted'}
              contentProps={{
                noWrap: true,
              }}
            />
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <TwoLines
              label="Supplier invoice reference"
              content={order?.supplier_invoice?.invoice_ref || '-'}
              contentProps={{
                noWrap: true,
              }}
            />
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <TwoLines
              label="Internal order reference"
              content={order?.supplier_invoice?.order_ref || '-'}
              contentProps={{
                noWrap: true,
              }}
            />
          </Col>
          {Boolean(orderAdditionalNotes) && (
            <Col xs={12}>
              <Alert
                severity="info"
                sx={{
                  maxWidth: 620,
                  [`& .${alertClasses.message}`]: {
                    paddingBlockEnd: 1.5,
                  },
                }}>
                <AlertTitle>Additional note from merchant</AlertTitle>
                <Typography
                  color="text.primary"
                  variant="body2"
                  component={'pre'}>
                  {orderAdditionalNotes}
                </Typography>
              </Alert>
            </Col>
          )}
        </Row>
      </Stack>
    </Stack>
  );
};
