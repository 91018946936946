import { GlobalSearchDialog } from '@/components/GlobalSearchDialog';
import { ROUTES } from '@/constants';
import { useAuth } from 'auth';
import Mousetrap from 'mousetrap';
import { Navigate, Outlet, createPath, useLocation } from 'react-router-dom';
import { useMount, useToggle, useUpdateEffect } from 'react-use';

export default function PrivateRoutes() {
  const auth = useAuth();
  const { user } = auth;
  const [isGlobalSearchDialogOpen, toggleGlobalSearchDialog] = useToggle(false);
  const { key, pathname, hash, search } = useLocation();
  const requestedPage = createPath({
    pathname,
    hash,
    search,
  });
  useMount(() => {
    Mousetrap.bind(['ctrl+k', 'command+k'], (event) => {
      event.preventDefault();
      toggleGlobalSearchDialog();
    });
  });

  useUpdateEffect(() => {
    if (isGlobalSearchDialogOpen) toggleGlobalSearchDialog();
  }, [key]);

  if (auth.isAuthenticated) {
    if (!user) return;

    localStorage.removeItem('callbackUrl');

    return (
      <>
        <Outlet />
        {isGlobalSearchDialogOpen && (
          <GlobalSearchDialog
            open={isGlobalSearchDialogOpen}
            onClose={toggleGlobalSearchDialog}
          />
        )}
      </>
    );
  }
  if (requestedPage !== ROUTES.profile)
    localStorage.setItem('callbackUrl', requestedPage);
  return <Navigate to={ROUTES.login} />;
}
