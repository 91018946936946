import { SupplierInvoiceContext } from '@/context';
import { ReactNode } from 'react';
import { useToggle } from 'react-use';

export const SupplierInvoiceProvider = ({
  children,
  invoiceUrl,
}: {
  children: ReactNode;
  invoiceUrl?: string;
}) => {
  const [isSupplierInvoiceOpen, toggleSupplierInvoice] = useToggle(false);
  return (
    <SupplierInvoiceContext.Provider
      value={{
        isSupplierInvoiceOpen,
        toggleSupplierInvoice: () => toggleSupplierInvoice(),
        invoiceUrl,
      }}>
      {children}
    </SupplierInvoiceContext.Provider>
  );
};
