import { suppliersScreeningResultFilterOptions } from '@/constants';
import { SuppliersTableFilters, SuppliersTableFiltersFields } from '@/types';
import { getFiltersInitialValues } from '@/utils/TableFilters';
import {
  Autocomplete,
  Badge,
  Button,
  Chip,
  Divider,
  Menu,
  Stack,
  TextField,
  Typography,
  autocompleteClasses,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { COUNTRIES_OPTIONS } from '@treyd-io/core/constants/country';
import {
  FilterComparator,
  QueryFilterField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import FiltersIcon from '@treyd-io/treyd-ui/icons/FiltersIcon';
import { format, isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { find, isEmpty, map, toString } from 'lodash';
import { useState } from 'react';

interface TableFiltersProps {
  onSubmit: (filters: QueryFilterField<SuppliersTableFiltersFields>[]) => void;
  onReset?: () => void;
  filters?: QueryFilterField<SuppliersTableFiltersFields>[];
}

export const TableFilters = (props: TableFiltersProps) => {
  const { onSubmit, onReset, filters } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClose = () => setAnchorEl(null);
  const appliedFilterFieldsCount = filters?.length || 0;

  const initialValues = getFiltersInitialValues<SuppliersTableFilters>(
    filters,
    {
      creationDateStart: toString,
      creationDateEnd: toString,
      screeningResult: (comparisonValue?: { screeningResult: string }) =>
        toString(comparisonValue?.screeningResult),
      monitoring: toString,
      qIExempt: toString,
      countries: (currencies) => currencies || [],
    }
  );

  const handleFormSubmit = (values: SuppliersTableFilters) => {
    const {
      creationDateStart,
      creationDateEnd,
      screeningResult,
      qIExempt,
      monitoring,
      countries,
    } = values;
    const filters: QueryFilterField<SuppliersTableFiltersFields>[] = [];

    if (creationDateStart) {
      filters.push({
        key: 'creationDateStart',
        type: 'filter',
        name: 'created_at',
        comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
        comparisonValue: creationDateStart,
      });
    }

    if (creationDateEnd) {
      filters.push({
        key: 'creationDateEnd',
        type: 'filter',
        name: 'created_at',
        comparator: FilterComparator.LESS_THAN_OR_EQUAL,
        comparisonValue: creationDateEnd,
      });
    }

    if (!isEmpty(screeningResult)) {
      filters.push({
        key: 'screeningResult',
        type: 'filter',
        name: 'kyc',
        comparator: FilterComparator.CONTAINS,
        comparisonValue: {
          screeningResult: screeningResult,
        },
      });
    }

    if (!isEmpty(qIExempt)) {
      filters.push({
        key: 'qIExempt',
        type: 'filter',
        name: 'exempted_from_inspections',
        comparator: FilterComparator.EQUAL,
        comparisonValue: qIExempt,
      });
    }

    if (!isEmpty(monitoring)) {
      filters.push({
        key: 'monitoring',
        type: 'filter',
        name: 'is_monitored',
        comparator: FilterComparator.EQUAL,
        comparisonValue: monitoring,
      });
    }

    if (!isEmpty(countries)) {
      filters.push({
        key: 'countries',
        type: 'filter',
        name: 'country',
        comparator: FilterComparator.IN,
        comparisonValue: countries,
      });
    }

    onSubmit(filters);
    handleMenuClose();
  };

  return (
    <>
      <Badge
        badgeContent={appliedFilterFieldsCount}
        color="primary"
        sx={{ width: '100%' }}>
        <Button
          startIcon={<FiltersIcon />}
          size="large"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          Filters
        </Button>
      </Badge>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormSubmit}>
        {(formik) => {
          return (
            <Menu
              keepMounted
              disablePortal
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => formik.handleSubmit()}
              sx={{}}
              slotProps={{
                paper: {
                  elevation: 2,
                  sx: {
                    padding: 2,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: 420,
                    backgroundColor: 'background.default',
                  },
                },
              }}>
              <Form
                noValidate
                tabIndex={-1}
                onKeyDown={(e) => e.stopPropagation()}>
                <Row tabIndex={-1}>
                  <Col xs={12}>
                    <Stack gap={1}>
                      <Typography variant="subtitle1">Creation date</Typography>
                      <Row>
                        <Col xs={12} sm={6}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            label={'Start date'}
                            value={
                              formik.values.creationDateStart
                                ? new Date(formik.values.creationDateStart)
                                : null
                            }
                            onChange={(date, context) => {
                              formik.setFieldValue(
                                'creationDateStart',
                                isValid(date) &&
                                  isEmpty(context.validationError)
                                  ? format(date as Date, 'yyyy-MM-dd')
                                  : '',
                                true
                              );
                            }}
                            slotProps={{
                              field: { clearable: true },
                              textField: {
                                name: 'creationDateStart',
                                onBlur: formik.handleBlur,
                                error:
                                  formik.touched.creationDateStart &&
                                  Boolean(formik.errors.creationDateStart),
                                helperText:
                                  formik.touched.creationDateStart &&
                                  formik.errors.creationDateStart,
                              },
                            }}
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <DatePicker
                            views={['year', 'month', 'day']}
                            sx={{ width: '100%' }}
                            label={'End date'}
                            value={
                              formik.values.creationDateEnd
                                ? new Date(formik.values.creationDateEnd)
                                : null
                            }
                            onChange={(date, context) => {
                              formik.setFieldValue(
                                'creationDateEnd',
                                isValid(date) &&
                                  isEmpty(context.validationError)
                                  ? format(date as Date, 'yyyy-MM-dd')
                                  : '',
                                true
                              );
                            }}
                            slotProps={{
                              field: { clearable: true },
                              textField: {
                                name: 'creationDateEnd',
                                onBlur: formik.handleBlur,
                                error:
                                  formik.touched.creationDateEnd &&
                                  Boolean(formik.errors.creationDateEnd),
                                helperText:
                                  formik.touched.creationDateEnd &&
                                  formik.errors.creationDateEnd,
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </Stack>
                  </Col>
                  <Col xs={12}>
                    <Divider />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      options={map(
                        suppliersScreeningResultFilterOptions,
                        (screeningResultOption) => screeningResultOption.value
                      )}
                      value={formik.values?.screeningResult}
                      getOptionLabel={(option) =>
                        find(
                          suppliersScreeningResultFilterOptions,
                          (screeningResultOption) =>
                            screeningResultOption.value === option
                        )?.label || ''
                      }
                      onChange={(_, screeningResult) =>
                        formik.setFieldValue('screeningResult', screeningResult)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Screening result" />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      options={['Yes', 'No']}
                      value={formik.values?.monitoring}
                      onChange={(_, monitoring) =>
                        formik.setFieldValue('monitoring', monitoring)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Monitoring" />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      options={['Yes', 'No']}
                      value={formik.values?.qIExempt}
                      onChange={(_, qIExempt) =>
                        formik.setFieldValue('qIExempt', qIExempt)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="QI exempt" />
                      )}
                    />
                  </Col>
                  <Col xs={12}>
                    <Autocomplete
                      multiple
                      options={map(
                        COUNTRIES_OPTIONS,
                        (countryOption) => countryOption.value
                      )}
                      value={formik.values?.countries}
                      getOptionLabel={(option) =>
                        find(
                          COUNTRIES_OPTIONS,
                          (countryOption) => countryOption.value === option
                        )?.title || ''
                      }
                      onChange={(_, countries) =>
                        formik.setFieldValue('countries', countries)
                      }
                      renderTags={(countries, getTagProps) => (
                        <Stack direction="row" overflow="auto">
                          {map(countries, (option, index) => (
                            <Chip
                              label={
                                find(
                                  COUNTRIES_OPTIONS,
                                  (statusOption) =>
                                    statusOption.value === option
                                )?.title
                              }
                              {...getTagProps({ index })}
                            />
                          ))}
                        </Stack>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              [`&.${autocompleteClasses.inputRoot}`]: {
                                flexWrap: 'nowrap',
                              },
                            },
                          }}
                          label="Countries"
                        />
                      )}
                    />
                  </Col>

                  <Col xs={12} gap={1} display="flex">
                    <Button
                      fullWidth
                      onClick={() => {
                        onReset?.();
                      }}
                      variant="outlined"
                      type="button"
                      color="secondary">
                      Reset
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary">
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Menu>
          );
        }}
      </Formik>
    </>
  );
};
