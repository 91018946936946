import { isNumber } from 'lodash';

export const formatCurrency = (
  value: number,
  currency: string,
  locale = 'fr-FR' // Locale that uses space for thousand separator and comma for decimal separator
) => {
  if (!isNumber(value) || !currency) return '';

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const formatDecimal = (value: number, locale = 'en-SE') => {
  if (!isNumber(value)) return '';

  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
};

export const formatPercent = (value: number, locale = 'en-SE') => {
  if (!isNumber(value)) return '';

  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(value / 100);
};

export const negativeToZero = (num: number): number => {
  return num < 0 ? 0 : num;
};

export const isZero = (number: number) => number === 0;
