import { TableRowType } from '@/types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
interface MainContactModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
  user: TableRowType;
}
const UpdateMainContactModal = (props: MainContactModalProps) => {
  const { isOpen, onCancel, onConfirm, loading, user } = props;

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Main contact</DialogTitle>
      <DialogContent
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1.5),
        })}>
        <DialogContentText>
          {user?.name || user?.email} will be set as the main contact for this
          company. Any future communications or inquiries will be directed to
          this contact.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" size="large">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={loading}
          onClick={onConfirm}>
          {' '}
          {loading ? <CircularProgress size={20} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateMainContactModal;
