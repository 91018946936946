import ReviewActionButtons from '@/pages/orderReview/OrderReviewDetails/ReviewActionsHeader/ReviewActionButtons';
import Order from '@/pages/orderReview/models/Order';
import { Separator } from '@treyd-io/treyd-ui/components/Separator';
import { SupplierInvoiceButton } from './SupplierInvoiceButton';

interface ReviewActionsHeaderMenuProps {
  order: Order;
  actionsDisabled: boolean;
  goBack: () => void;
  areAllSectionsApproved: boolean;
  dealDuplicates?: Order[];
}

export const ReviewActionsHeaderList = (
  props: ReviewActionsHeaderMenuProps
) => {
  const {
    order,
    actionsDisabled,
    goBack,
    areAllSectionsApproved,
    dealDuplicates,
  } = props;

  return (
    <>
      <ReviewActionButtons
        actionsDisabled={actionsDisabled}
        order={order}
        goBack={goBack}
        areAllSectionsApproved={areAllSectionsApproved}
        dealDuplicates={dealDuplicates}
      />
      <Separator orientation="vertical" variant="middle" flexItem />
      <SupplierInvoiceButton />
    </>
  );
};
