import { getSuppliersQuery } from '@/schemas/getSuppliersQuery';
import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';

export const useGetSuppliers = ({
  queryOptions,
  skip,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(getSuppliersQuery(queryOptions), {
    skip,
  });
