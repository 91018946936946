import { useQuery } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getOrdersActivityLogsQuery } from '../schemas/getOrdersActivityLogsQuery';

export const useGetOrdersActivityLogs = ({
  queryOptions,
  skip,
}: {
  queryOptions?: QueryOptions;
  skip?: boolean;
}) =>
  useQuery(getOrdersActivityLogsQuery(queryOptions), {
    skip,
  });
