import Order from '@/pages/orderReview/models/Order';
import {
  List,
  ListItem,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import FileTextIcon from '@treyd-io/treyd-ui/icons/FileTextIcon';
import { isEmpty, map } from 'lodash';
import { Link } from 'react-router-dom';

export const QualityInspection = ({ order }: { order: Order }) =>
  !isEmpty(order.quality_reports) && (
    <Stack gap={1}>
      <Stack direction="row" gap={1} textAlign="center">
        <FileTextIcon color="primary" />
        <Typography variant="subtitle1" color="text.primary">
          Quality inspection
        </Typography>
      </Stack>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}
        disablePadding>
        {map(
          order.quality_reports,
          (report) =>
            report?.url && (
              <ListItem disablePadding key={report?.url}>
                <MuiLink component={Link} to={report?.url} target="_blank">
                  QI report ↗︎
                </MuiLink>
              </ListItem>
            )
        )}
      </List>
    </Stack>
  );
