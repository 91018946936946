import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  ThemeOptions,
  createTheme,
} from '@mui/material';
import { ColorModeProvider } from '@treyd-io/treyd-ui/providers/colorModeProvider';
import darkThemeOptions from '@treyd-io/treyd-ui/themes/darkOptions.theme';
import lightThemeOptions from '@treyd-io/treyd-ui/themes/lightOptions.theme';
import { ReactNode, useContext, useMemo } from 'react';

import { ColorModeContext } from '@treyd-io/treyd-ui/context/colorModeContext';
import lausanne from '@treyd-io/treyd-ui/fonts/Lausanne/TWKLausanne-300.ttf';
import lausanneMedium from '@treyd-io/treyd-ui/fonts/Lausanne/TWKLausanne-400.ttf';
import lausanneBold from '@treyd-io/treyd-ui/fonts/Lausanne/TWKLausanne-550.ttf';

interface ThemeProviderProps {
  children: ReactNode;
}

const extendTheme = (themeOptions: ThemeOptions) =>
  createTheme({
    ...themeOptions,
    typography: {
      ...themeOptions.typography,
      fontFamily: ['TWK Lausanne', 'sans-serif'].join(','),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'TWK Lausanne';
            font-style: normal;
            font-display: swap;
            font-weight: 300;
            src: local('lausanne'), local('lausanne'), url(${lausanne}) format('truetype');
          }
          @font-face {
            font-family: 'TWK Lausanne';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('lausanne'), local('lausanne'), url(${lausanneMedium}) format('truetype');
          }
          @font-face {
            font-family: 'TWK Lausanne';
            font-style: bold;
            font-display: swap;
            font-weight: 550;
            src: local('lausanne'), local('lausanne'), url(${lausanneBold}) format('truetype');
          }
        `,
      },
      ...themeOptions.components,
      MuiDataGrid: {
        ...themeOptions.components?.MuiDataGrid,
        defaultProps: {
          ...themeOptions.components?.MuiDataGrid?.defaultProps,
          density: 'compact',
        },
      },
    },
  });

const THEMES = {
  light: extendTheme(lightThemeOptions),
  dark: extendTheme(darkThemeOptions),
};

const ThemeProviderWrapper = (props: ThemeProviderProps) => {
  const { colorMode } = useContext(ColorModeContext);
  const theme = useMemo(() => THEMES[colorMode], [colorMode]);
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {props.children}
    </MuiThemeProvider>
  );
};

export const ThemeProvider = (props: ThemeProviderProps) => {
  return (
    <ColorModeProvider>
      <ThemeProviderWrapper {...props} />
    </ColorModeProvider>
  );
};
