import { FileObject } from '../components/Upload/Uploader';
import { Attachment } from '../types/file';

export const isValidAttachment = (
  file: FileObject | Attachment | string
): file is Attachment => {
  return (
    (file as Attachment)?.id !== undefined &&
    (file as Attachment).name !== undefined &&
    (file as Attachment).url !== undefined &&
    (file as Attachment).tag !== undefined
  );
};
