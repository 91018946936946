import { TypedDocumentNode, gql } from '@apollo/client';
import { Orders_Companies } from '__generated__/graphql';

type GetCompanyDesiredLimitQueryType = {
  orders_companies_by_pk: Pick<
    Orders_Companies,
    'desired_limit' | 'desired_limit_currency'
  > & {};
};

export type GetCompanyDesiredLimitQueryVariables = {
  companyId: number;
};

export const getCompanyDesiredLimitQuery = gql`
  query getCompanyDesiredLimit($companyId: bigint!) {
    orders_companies_by_pk(id: $companyId) {
      desired_limit
      desired_limit_currency
    }
  }
` as TypedDocumentNode<
  GetCompanyDesiredLimitQueryType,
  GetCompanyDesiredLimitQueryVariables
>;
