import { Stack, Typography } from '@mui/material';
import React from 'react';
import { DocumentActions } from './DocumentActions';

interface DocumentCardProps {
  title?: string;
  description: string;
  chip?: React.ReactNode;
  onDownloadClick?: () => void;
  onViewClick?: () => void;
  onResendClick?: () => void;
}
export const DocumentCard = (props: DocumentCardProps) => {
  const {
    title,
    description,
    chip,
    onDownloadClick,
    onViewClick,
    onResendClick,
  } = props;

  return (
    <Stack
      border={1}
      borderColor="other.outlined_border"
      padding={2}
      borderRadius={2}
      justifyContent={'space-between'}
      gap={3}
      height={'100%'}>
      <Stack gap={0.5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Typography variant="subtitle1" color="text.primary">
            {title}
          </Typography>
          {chip}
        </Stack>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Stack>

      <Stack gap={1} direction="row" alignItems="center">
        <DocumentActions {...{ onResendClick, onViewClick, onDownloadClick }} />
      </Stack>
    </Stack>
  );
};
