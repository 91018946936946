import { usePagination } from '@/hooks';
import { TableSorting, UserTablePreferences } from '@/types';
import { useDebouncedState } from '@treyd-io/core/hooks/useDebouncedState';

import { toString } from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface TableOperationsProps {
  Component: React.FC<any>;
  delay?: number;
  [x: string]: any;
}

const defaultUserTablePreferences: UserTablePreferences = {
  size: 20,
  hiddenColumns: [],
  sorting: { field: 'id', sort: 'desc' },
};

export default function TableOperations({
  Component,
  ...restProps
}: TableOperationsProps) {
  const [userTablePreferences, setUserTablePreferences] = useState(
    defaultUserTablePreferences
  );

  const { page, size, setPage, setSize } = usePagination(
    0,
    userTablePreferences?.size || 20
  );
  const [filters, setFilters] = useState({});
  const [searchParams] = useSearchParams();
  const initialSearchTerm = searchParams.get('searchTerm');
  const [searchTerm, setSearchTerm] = useDebouncedState(
    toString(initialSearchTerm),
    restProps.delay
  );
  // const [hiddenColumns, setHiddenColumns] = useState(userTablePreferences?.hiddenColumns || []);
  const [sorting, setSorting] = useState(
    userTablePreferences?.sorting ||
      ({ field: 'id', sort: 'desc' } as TableSorting)
  );

  useEffect(() => {
    setUserTablePreferences({ size, sorting });
  }, [size, sorting]);

  useEffect(() => {
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <>
      <Component
        {...restProps}
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
        offset={page * size}
        limit={size}
        setLimit={setSize}
        sorting={sorting}
        setSorting={setSorting}
        filters={filters}
        setFilters={setFilters}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </>
  );
}
