import { gql, TypedDocumentNode } from '@apollo/client';

type GetOrderSignatureLogsQueryType = {
  orders_orders_app_orderlog: {
    timestamp: string;
  }[];
};

type GetOrderSignatureLogsQueryVariables = {
  orderId: number;
};

export const getOrderSignatureLogsQuery = gql`
  query getOrderSignatureLogsQuery($orderId: bigint!) {
    orders_orders_app_orderlog(
      where: { order_id: { _eq: $orderId }, event: { _eq: "request_approval" } }
    ) {
      timestamp
    }
  }
` as TypedDocumentNode<
  GetOrderSignatureLogsQueryType,
  GetOrderSignatureLogsQueryVariables
>;
