import { SearchField } from '@/components';
import { ComponentLoader } from '@/components/ComponentLoader';
import { CurrenciesTable } from '@/components/Invoices/CurrenciesTable';
import { ExportInvoicesButton } from '@/components/Invoices/ExportInvoicesButton';
import { FinanciersTable } from '@/components/Invoices/FinanciersTable';
import { useGetAllFinanciers, useGetTableSettings } from '@/hooks';
import useSearchTerm from '@/hooks/useSearchTerm';
import { Stack, Typography } from '@mui/material';
import {
  Filter,
  FilterComparator,
  QuerySearchField,
} from '@treyd-io/core/types/hasura';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import { isEmpty, isNaN, toNumber, toString } from 'lodash';
import { InvoicesTable } from './InvoicesTable';
import { TableFilters } from './TableFilters';

export const InvoicesPage = () => {
  const { searchTerm, setSearchTerm } = useSearchTerm('invoicesSearchTerm');

  const {
    invoicesTableSettings: { filterFields, setFilterFields, resetTable },
  } = useGetTableSettings();

  const { data, loading: isAllFinanciersLoading } = useGetAllFinanciers();
  const allFinanciers = data?.orders_financiers;

  const searchFields: QuerySearchField[] = [
    {
      type: 'search',
      name: 'invoice_number',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
    {
      type: 'search',
      name: 'order_id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(toString(searchTerm).trim())
          ? null
          : toNumber(searchTerm),
    },
    {
      type: 'search',
      name: 'order__importer__name',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: toString(searchTerm).trim(),
    },
  ];
  const filters: Filter[] = [...searchFields, ...filterFields];

  if (isAllFinanciersLoading) return <ComponentLoader />;

  return (
    <Stack flexGrow={1} gap={5} height="100%">
      <Stack gap={3}>
        <Row>
          <Col xs="auto">
            <Typography variant="h4">Invoices</Typography>
          </Col>
          <Col xs={12} sm>
            <SearchField
              placeholder={'Search by invoice #, order #, or merchant name'}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Col>
          <Col xs={12} sm="auto">
            <TableFilters
              onSubmit={setFilterFields}
              onReset={resetTable}
              filters={filterFields}
              allFinanciers={allFinanciers}
            />
          </Col>
          <Col xs={12} sm="auto">
            <ExportInvoicesButton filters={filters} enableExportTransactions />
          </Col>
        </Row>
        <InvoicesTable filters={filters} />
      </Stack>
      <Row>
        <Col xs={12} md={5}>
          <CurrenciesTable filters={filters} />
        </Col>
        <Col xs={12} md={7}>
          <FinanciersTable filters={filters} allFinanciers={allFinanciers} />
        </Col>
      </Row>
    </Stack>
  );
};
