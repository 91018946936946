import { CreditableType, INVOICE_TYPE, TRANSACTION_TYPE } from '@/constants';
import { TableSettingsContext } from '@/context';
import { useTableSettings } from '@/hooks';
import {
  CreditsTableFiltersFields,
  EmailsTableFiltersFields,
  InvoicesTableFiltersFields,
  MerchantTableFiltersFields,
  NotificationsTableFiltersFields,
  OrdersReview,
  OrdersTableFiltersFields,
  SuppliersTableFiltersFields,
  TransactionsTableFiltersFields,
  VouchersTableFiltersFields,
} from '@/types';
import { FilterComparator } from '@treyd-io/core/types/hasura';
import { format, subMonths } from 'date-fns';
import { ReactNode } from 'react';

export const TableSettingsProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const merchantsTableSettings =
    useTableSettings<MerchantTableFiltersFields>('merchants');
  const ordersTableSettings =
    useTableSettings<OrdersTableFiltersFields>('orders');
  const ordersReviewAllOrdersTableSettings =
    useTableSettings<OrdersReview.AllOrdersTableFiltersFields>(
      'OrdersReviewAllOrders'
    );
  const suppliersTableSettings =
    useTableSettings<SuppliersTableFiltersFields>('suppliers');
  const vouchersTableSettings = useTableSettings<VouchersTableFiltersFields>(
    'vouchers',
    {
      sortModel: [{ field: 'created', sort: 'desc' }],
    }
  );
  const creditsTableSettings = useTableSettings<CreditsTableFiltersFields>(
    'credits',
    {
      sortModel: [{ field: 'created', sort: 'desc' }],
      filterFields: [
        {
          key: 'creditableType',
          type: 'filter',
          name: 'creditable_type',
          comparator: FilterComparator.IN,
          comparisonValue: [CreditableType.INVOICE],
        },
      ],
    }
  );
  const invoicesTableSettings = useTableSettings<InvoicesTableFiltersFields>(
    'invoices',
    {
      filterFields: [
        {
          key: 'types',
          type: 'filter',
          name: 'invoice_type',
          comparator: FilterComparator.IN,
          comparisonValue: [INVOICE_TYPE.DEBTOR],
        },
      ],
    }
  );
  const transactionsTableSettings =
    useTableSettings<TransactionsTableFiltersFields>('transactions', {
      sortModel: [{ field: 'payment_date', sort: 'desc' }],
      filterFields: [
        {
          type: 'filter',
          name: 'type',
          key: 'types',
          comparator: FilterComparator.IN,
          comparisonValue: [TRANSACTION_TYPE.PAYMENT],
        },
        {
          type: 'filter',
          name: 'payment_date',
          key: 'paymentDateStart',
          comparator: FilterComparator.GREATER_THAN_OR_EQUAL,
          comparisonValue: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
        },
        {
          type: 'filter',
          name: 'payment_date',
          key: 'paymentDateEnd',
          comparator: FilterComparator.LESS_THAN_OR_EQUAL,
          comparisonValue: format(new Date(), 'yyyy-MM-dd'),
        },
      ],
    });

  const notificationsTableSettings =
    useTableSettings<NotificationsTableFiltersFields>('notifications', {
      sortModel: [{ field: 'created', sort: 'desc' }],
    });

  const emailsTableSettings = useTableSettings<EmailsTableFiltersFields>(
    'emails',
    {
      sortModel: [{ field: 'id', sort: 'desc' }],
    }
  );

  return (
    <TableSettingsContext.Provider
      value={{
        ordersReviewAllOrdersTableSettings,
        merchantsTableSettings,
        invoicesTableSettings,
        ordersTableSettings,
        suppliersTableSettings,
        transactionsTableSettings,
        creditsTableSettings,
        vouchersTableSettings,
        notificationsTableSettings,
        emailsTableSettings,
      }}>
      {children}
    </TableSettingsContext.Provider>
  );
};
