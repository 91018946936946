import FilterField from '@/components/FilterField';
import SearchField from '@/components/SearchField';
import { FilterList } from '@/types';
import { Box } from '@mui/material';

interface Props {
  filters: any;
  setFilters: (filters: any) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  filterList: FilterList[];
  searchPlaceholder?: string;
}

const CustomToolbar = (props: Props) => {
  const { searchTerm, setSearchTerm, filterList, setFilters, filters } = props;

  const onFiltersChange = (field: string, value: string[]) => {
    const updatedFilters = { ...filters };
    if (!value) {
      delete updatedFilters[field];
    } else {
      updatedFilters[field] = value;
    }
    setFilters(updatedFilters);
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {filterList?.map((filter: any, index: number) => (
          <FilterField
            boxStyles={{ width: '100%', marginInlineEnd: 0 }}
            filter={filter}
            onFilterChange={onFiltersChange}
            key={index}
            chip
          />
        ))}
      </Box>
      {setSearchTerm && (
        <Box sx={(theme) => ({ width: '100%', marginBlock: theme.spacing(2) })}>
          <SearchField
            placeholder={props.searchPlaceholder}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomToolbar;
