import { User } from '@/types';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import {
  clearJWT,
  getAuthExpired,
  getAuthLastRefreshedInSeconds,
  getJWT,
  setJWT,
} from '@treyd-io/core/utils/jwt';
import { save } from '@treyd-io/core/utils/request';
import {
  LAGER_CURRENT_USER,
  USER_REFRESH_TOKEN,
} from 'constants/api-endpoints';
import { REFRESH_ACCESS_TOKEN_FREQ } from 'constants/app-settings';
import { AuthContext, SentryContext } from 'context';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const Sentry = useContext(SentryContext);
  const [user, setUser] = useState<User | null>(null);

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const { refreshToken } = getJWT();
    return !!refreshToken;
  });

  const login = (new_access_token: string, new_refresh_token: string) => {
    const { accessToken } = getJWT();
    if (accessToken !== new_access_token) {
      setJWT(new_access_token, new_refresh_token);
    }
    getCurrentUser();
  };

  const logout = () => {
    clearJWT();
    setIsAuthenticated(false);
    setUser(null);
  };

  const getCurrentUser = useCallback(async () => {
    try {
      const resp = await save<User>(LAGER_CURRENT_USER, {}, REQUEST_METHOD.PUT);
      setUser(resp.data);
      setIsAuthenticated(true);
      Sentry.setUser({
        id: resp.data.id,
        email: resp.data.email,
        username: resp.data.name,
      });
    } catch (err: any) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update_access_token = useCallback(() => {
    const refresh = getJWT()?.refreshToken;
    return save<{ access: string }>(
      USER_REFRESH_TOKEN,
      { refresh },
      REQUEST_METHOD.POST,
      {
        noToast: true,
      }
    )
      .then((resp) => {
        const { access } = resp?.data;
        setJWT(access, refresh);
        return true;
      })
      .catch(() => {
        logout();
        return false;
      });
  }, []);

  useEffect(() => {
    if (!isAuthenticated || getAuthExpired()) {
      logout();
      return;
    }
    const intervalId = setInterval(() => {
      if (isAuthenticated && !getAuthExpired()) {
        if (getAuthLastRefreshedInSeconds() < 60) {
          return;
        }
        update_access_token();
      } else {
        clearInterval(intervalId);
        logout();
      }
    }, REFRESH_ACCESS_TOKEN_FREQ);

    update_access_token().then(() => {
      getCurrentUser();
    });
    return () => clearInterval(intervalId);
  }, [getCurrentUser, isAuthenticated, update_access_token]);

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
