import { gql, TypedDocumentNode } from '@apollo/client';
import { Orders_Financiers } from '__generated__/graphql';

export type Financier = Pick<Orders_Financiers, 'name' | 'id'>;

type GetAllFinanciersQueryType = {
  orders_financiers: Financier[];
};

export const getAllFinanciersQuery = gql`
  query getAllFinanciers {
    orders_financiers {
      id
      name
    }
  }
` as TypedDocumentNode<GetAllFinanciersQueryType, {}>;
