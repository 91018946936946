import { TypedDocumentNode, gql } from '@apollo/client';
import {
  Orders_Notification_Center_Emaillog,
  Orders_Notification_Center_Notificationtemplate,
} from '__generated__/graphql';

export type Email = Pick<
  Orders_Notification_Center_Emaillog,
  | 'id'
  | 'created'
  | 'recipient'
  | 'payload'
  | 'first_opened_at'
  | 'last_opend_at'
  | 'open_count'
> & {
  template: {
    name: Pick<Orders_Notification_Center_Notificationtemplate, 'name'>;
  };
};

type GetEmailLogDetailsQueryTypes = {
  orders_notification_center_emaillog_by_pk: Email;
};

type GetEmailLogDetailsQueryVariables = {
  emailLogId: number;
};

export const getEmailLogDetailsQuery = gql`
  query getEmailLogDetails($emailLogId: bigint!) {
    orders_notification_center_emaillog_by_pk(id: $emailLogId) {
      id
      created
      recipient
      payload
      first_opened_at
      last_opend_at
      open_count
      template {
        name
      }
    }
  }
` as TypedDocumentNode<
  GetEmailLogDetailsQueryTypes,
  GetEmailLogDetailsQueryVariables
>;
