/* eslint-disable react/no-unescaped-entities */
import { KYC_STATUS } from '@/constants';
import { TimelineStation } from '@/pages/merchants/MerchantsDetails/Tabs/GeneralInfo/types';
import {
  Invitation,
  Merchant,
  OnboardingStatuses,
  OnboardingStepStatus,
} from '@/types';
import { Link as MuiLink, Typography } from '@mui/material';
import { formatDate } from '@treyd-io/core/utils/date';
import { Lager_Treyd_Users } from '__generated__/graphql';
import { includes, map } from 'lodash';
import { Link } from 'react-router-dom';

const doesCompanyHaveSigners = (
  companyUsers: Pick<Lager_Treyd_Users, 'id' | 'name' | 'can_sign_alone'>[]
) =>
  companyUsers.length > 0 && companyUsers.some((user) => user?.can_sign_alone);

export const getAccountCreationStepStatus = (merchant: Merchant) =>
  merchant.company_users && doesCompanyHaveSigners(merchant.company_users)
    ? 'completed'
    : 'on_going';

export const getAccountCreationStepTimeline = (merchant: Merchant) => {
  const timeline: TimelineStation[] = [
    {
      title: (
        <Typography>
          Company has been created and the information can be seen above. View
          the detected list of Signatories in the
          <MuiLink
            component={Link}
            to={'#users'}
            variant="body1"
            marginBottom={0.5}
            marginLeft={0.5}>
            users section ↗
          </MuiLink>
        </Typography>
      ),
      timeStamp: merchant?.joined
        ? formatDate(new Date(merchant?.joined), 'datetime')
        : '',
    },
  ];
  if (!merchant?.company_users?.length) {
    timeline.unshift({
      title: (
        <Typography variant="body1" color="warning.main">
          Company doesn't have any users yet.
        </Typography>
      ),
    });
  } else if (
    !(
      merchant.company_users?.length &&
      doesCompanyHaveSigners(merchant.company_users)
    )
  ) {
    timeline.unshift({
      title: (
        <Typography variant="body1" color="warning.main">
          None of the users have sign alone rights.
        </Typography>
      ),
    });
  }
  return timeline;
};

const isKycSubmitted = (merchant: Merchant) => {
  return includes(
    [KYC_STATUS.PREVIEW, KYC_STATUS.EDITABLE],
    merchant?.kyc?.status
  );
};

export const getKycStepStatus = (merchant: Merchant): OnboardingStepStatus => {
  if (merchant?.kyc?.status) {
    if (!isKycSubmitted(merchant)) {
      return 'on_going';
    }
    if (isKycSubmitted(merchant)) {
      return 'completed';
    }
  }
  return 'not_started';
};

export const getKycStepTimeline = (merchant: Merchant) => {
  const timeline: TimelineStation[] = [];
  if (isKycSubmitted(merchant)) {
    const kycSubmitterId = merchant?.kyc?.submited_by;
    const kycSubmitter = merchant?.company_users?.find(
      (user) => user?.id === kycSubmitterId
    );
    timeline.push({
      title: (
        <Typography>
          {kycSubmitter?.name} completed the KYC.
          <MuiLink
            component={Link}
            to={'#kyc'}
            variant="body1"
            marginBottom={0.5}
            marginLeft={0.5}>
            View it here↗
          </MuiLink>
        </Typography>
      ),
      timeStamp: merchant?.kyc?.submitted_at
        ? formatDate(new Date(merchant?.kyc?.submitted_at), 'datetime')
        : '',
    });
  } else {
    timeline.push({
      title: (
        <Typography>
          Kyc has not been submitted yet.
          <MuiLink
            component={Link}
            to={'#kyc'}
            variant="body1"
            marginBottom={0.5}
            marginLeft={0.5}>
            View it here↗
          </MuiLink>
        </Typography>
      ),
    });
  }
  return timeline;
};

export const getCompanyFinancialsStepTimeline = (
  merchant: Merchant,
  onboardingStatuses: OnboardingStatuses
) => {
  const timeline: TimelineStation[] = [];
  if (
    onboardingStatuses.financial_data_completed &&
    !onboardingStatuses.is_financial_documents_added &&
    !onboardingStatuses.erp_connected
  ) {
    timeline.push({
      title:
        'The company’s financial documents have been provided by other means.',
    });
  }
  if (onboardingStatuses.is_financial_documents_added) {
    timeline.push({
      title: 'The company’s financial documents have been uploaded.',
    });
  }
  if (onboardingStatuses.erp_connected && merchant.erp_system) {
    timeline.push({
      title: `Connection to ${merchant.erp_system} has been established.`,
    });
  }
  return timeline;
};

export const getUboInvitationStatus = (
  uboInvitations?: Invitation[],
  isUboOnboarded?: boolean
): OnboardingStepStatus => {
  if (isUboOnboarded) {
    return 'completed';
  }
  if (uboInvitations && uboInvitations.length) {
    const isAllUboInvitationsAccepted = uboInvitations.every(
      (invitation) => invitation.is_accepted
    );
    if (isAllUboInvitationsAccepted) {
      return 'completed';
    }
    return 'on_going';
  }
  return 'not_started';
};

export const getUboInvitationTimeline = ({
  merchant,
  uboInvitations,
  isUboOnboarded,
}: {
  merchant: Merchant;
  uboInvitations?: Invitation[];
  isUboOnboarded?: boolean;
}) => {
  const timeline: TimelineStation[] = [];
  const sortedUboInvitations = [...(uboInvitations || [])]?.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  const invitedUbos = sortedUboInvitations?.map((uboInvitation) => ({
    name:
      merchant.company_users &&
      merchant?.company_users.find(
        (user) => user?.id === uboInvitation.invited_user_id
      )?.name,
    created_at: uboInvitation.created_at,
    accepted_at: uboInvitation.accepted_at,
    is_accepted: uboInvitation.is_accepted,
    invitedUboId: uboInvitation.invited_user_id,
  }));

  const linkedUbos = merchant.company_users?.filter(
    (user) =>
      user?.is_ubo &&
      !invitedUbos?.find((invitedUbo) => invitedUbo.invitedUboId === user.id)
  );

  invitedUbos?.forEach((ubos) => {
    if (ubos.is_accepted) {
      timeline.push({
        title: `A UBO invite has been accepted by ${ubos.name}`,
        timeStamp: ubos.accepted_at
          ? formatDate(new Date(ubos.accepted_at), 'datetime')
          : '',
      });
    } else {
      timeline.push({
        title: `A UBO invite has been sent to ${ubos.name}`,
        timeStamp: ubos.created_at
          ? formatDate(new Date(ubos.created_at), 'datetime')
          : '',
      });
    }
  });
  if (isUboOnboarded) {
    map(linkedUbos, (ubo) => {
      timeline.push({
        title: `${ubo?.name} has been onboarded as a UBO.`,
      });
    });
  }
  return timeline;
};
