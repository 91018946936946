import {
  Maybe,
  Orders_Companies,
  Orders_Orders_Aggregate,
  Orders_Suppliers,
  Scalars,
} from '@/__generated__/graphql';
import { gql, TypedDocumentNode } from '@apollo/client';
import { BASE_SUPPLIER } from './fragments/BaseSupplier';

type Supplier = Pick<
  Orders_Suppliers,
  | 'created_at'
  | 'name'
  | 'country'
  | 'exempted_from_inspections'
  | 'website'
  | 'zip_code'
  | 'city'
  | 'contacts'
  | 'full_address'
  | 'org_no'
  | 'id'
  | 'state_or_province'
> & {
  company: Pick<Orders_Companies, 'name' | 'id'>;
} & {
  allDeals: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  paidDeals: Pick<Orders_Orders_Aggregate, 'aggregate'>;
  legal_name?: Maybe<Scalars['String']>;
};

export const updateSupplierDetailsMutation = gql`
  ${BASE_SUPPLIER}
  mutation updateSupplierFactory(
    $id: bigint!
    $supplier_data: orders_suppliers_set_input!
  ) {
    supplier: update_orders_suppliers_by_pk(
      pk_columns: { id: $id }
      _set: $supplier_data
    ) {
      ...BaseSupplier
    }
  }
` as TypedDocumentNode<
  { supplier: Supplier },
  { id: string; supplier_data: Partial<Orders_Suppliers> }
>;
