import { TypedDocumentNode, gql } from '@apollo/client';
import { QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Lager_Auth_User,
  Orders_Order_Activity_Logs,
} from '../__generated__/graphql';

export type OrderActivityLog = Pick<
  Orders_Order_Activity_Logs,
  'id' | 'data' | 'created' | 'loggable_id'
> & {
  user: Pick<Lager_Auth_User, 'first_name' | 'last_name'>;
};

type GetOrdersQueryType = {
  orders_order_activity_logs: OrderActivityLog[];
  orders_order_activity_logs_aggregate: {
    aggregate: { count: number };
  };
};

export const getOrdersActivityLogsQuery = (queryOptions?: QueryOptions) => {
  const { queryParams, whereClause } = getGqlQueryParams(queryOptions);

  return gql`
  query getOrdersActivityLogs {
    orders_order_activity_logs ${queryParams} {
    id
    loggable_id
    data
    created
    user {
      first_name
      last_name
    }
  }

  orders_order_activity_logs_aggregate ${
    whereClause ? `(where: ${whereClause})` : ''
  } {
			aggregate {
				count
			}
		}
	}
  ` as TypedDocumentNode<GetOrdersQueryType, {}>;
};
