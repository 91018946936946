import { getCompanyCredits } from '@/api';
import { useQuery } from '@tanstack/react-query';

export const useGetCompanyCredits = ({
  companyId,
  limit,
  page,
}: {
  companyId: number;
  limit: number;
  page: number;
}) => {
  return useQuery({
    queryKey: ['company-credits', companyId, limit, page],
    queryFn: () => getCompanyCredits(companyId, limit, page),
    select: (data) => data?.data,
    refetchOnWindowFocus: false,
  });
};
