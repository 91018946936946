import { gql, TypedDocumentNode } from '@apollo/client';
import { GridSortItem } from '@mui/x-data-grid';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import {
  Orders_Orders as Orders,
  Orders_Order_Payouts,
  Orders_Order_Supplier_Invoices,
  Orders_Orders_Aggregate,
} from '__generated__/graphql';
import { isEmpty, toNumber } from 'lodash';

export type Order = Pick<Orders, 'id' | 'status' | 'modified'> & {
  supplier_invoice: Pick<
    Orders_Order_Supplier_Invoices,
    'order_ref' | 'invoice_ref'
  >;
  active_payout: Pick<
    Orders_Order_Payouts,
    | 'amount'
    | 'currency'
    | 'actual_payment_date'
    | 'expected_settlement_date_calc'
  >;
};

type GetMerchantOrdersQuery = {
  orders_orders: Array<Order>;
  orders_orders_aggregate: Pick<Orders_Orders_Aggregate, 'aggregate'>;
};

export const getSupplierOrdersQuery = (
  offset: number,
  limit: number,
  exporter_id: number,
  searchTerm = '',
  sorting = {},
  filters = {
    status: '',
  }
) => {
  let supplierOrdersFilters: Filter[] = [
    {
      type: 'search',
      name: 'id',
      comparator: FilterComparator.EQUAL,
      comparisonValue:
        isNaN(toNumber(searchTerm)) || isEmpty(searchTerm)
          ? null
          : toNumber(searchTerm),
    },
    {
      type: 'search',
      name: 'supplier_invoice__order_ref',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'search',
      name: 'supplier_invoice__invoice_ref',
      comparator: FilterComparator.CASE_INSENSITIVE_LIKE,
      comparisonValue: searchTerm,
    },
    {
      type: 'filter',
      name: 'exporter_id',
      comparator: FilterComparator.IN,
      comparisonValue: exporter_id,
    },
  ];

  if (filters?.status) {
    supplierOrdersFilters.push({
      type: 'filter',
      name: 'status',
      comparator: FilterComparator.IN,
      comparisonValue: filters?.status,
    });
  }

  const { queryParams, whereClause } = getGqlQueryParams({
    limit,
    offset,
    sorting: sorting as GridSortItem,
    filters: supplierOrdersFilters,
  });

  return gql`
	query getSupplierOrdersQuery {
		orders_orders ${queryParams} {
			id
			status
      supplier_invoice {
  			invoice_ref
	  		order_ref
      }
			active_payout {
        amount
        currency
        actual_payment_date
        expected_settlement_date_calc
      }

		}
		orders_orders_aggregate (where: ${whereClause}) {
			aggregate {
			  count
			}
		}
		}
  ` as TypedDocumentNode<GetMerchantOrdersQuery, {}>;
};
