/* eslint-disable @typescript-eslint/no-use-before-define */
import { TimelineItem } from '@/pages/merchants/MerchantsDetails/Tabs/GeneralInfo/TimelineItem';
import { Codes, LimitStatus, LimitStatusSegmentArgs, Segment } from '@/types';
import { Typography } from '@mui/material';
import { formatCurrency } from '@treyd-io/core/utils/number';
import { map, reduce } from 'lodash';
import { ReactNode } from 'react';
import { LimitStatusService } from '../LimitStatusService';

export const MonitoringSegmentMapper = (
  segment: LimitStatus.Segments['monitoring']
): Segment.Values => {
  const { COMPANY_HAS_PAYMENT_REMARKS: companyHasPaymentRemarks } = reduce(
    segment.uiCodes,
    (
      acc: Partial<Record<Codes.Monitoring, LimitStatusSegmentArgs.Monitoring>>,
      uiCode
    ) => {
      acc[uiCode.code] = uiCode.kargs;
      return acc;
    },
    {}
  );

  const POINTS_COMPONENTS: Record<Codes.Monitoring, ReactNode> = {
    NO_DATA_AVAILABLE: <BulletPoints.NoDataAvailable />,
    COMPANY_HAS_OVERDUE_INVOICE: <BulletPoints.CompanyHasOverdueInvoices />,
    COMPANY_CREDITSAFE_INACTIVE: <BulletPoints.CompanyCreditSafeInactive />,
    COMPANY_HAS_NO_PAYMENT_REMARKS: <BulletPoints.CompanyHasNoPaymentRemarks />,
    COMPANY_CREDITSAFE_ACTIVE: <BulletPoints.CompanyCreditSafeActive />,
    COMPANY_HAS_NO_OVERDUE_INVOICE: (
      <BulletPoints.CompanyHasNoOverdueInvoices />
    ),
    COMPANY_HAS_PAYMENT_REMARKS: (
      <BulletPoints.CompanyHasPaymentRemarks
        balance={companyHasPaymentRemarks?.balance}
        currency={companyHasPaymentRemarks?.currency}
      />
    ),
  };

  const points = map(
    segment.uiCodes,
    (uiCode) => POINTS_COMPONENTS[uiCode.code]
  );

  const icon = LimitStatusService.ICON_COMPONENTS[segment.status];

  return {
    icon,
    points,
  };
};

namespace BulletPoints {
  export const NoDataAvailable = () => (
    <TimelineItem>
      <Typography variant="body1">
        No information has been provided yet.
      </Typography>
    </TimelineItem>
  );
  export const CompanyHasOverdueInvoices = () => (
    <TimelineItem>
      <Typography variant="body1">Company has overdue invoices.</Typography>
    </TimelineItem>
  );
  export const CompanyHasNoPaymentRemarks = () => (
    <TimelineItem>
      <Typography variant="body1">No payment remarks.</Typography>
    </TimelineItem>
  );
  export const CompanyCreditSafeActive = () => (
    <TimelineItem>
      {' '}
      <Typography variant="body1">
        The merchant is not bankrupt, under reconstruction, or in default.
      </Typography>
    </TimelineItem>
  );
  export const CompanyHasNoOverdueInvoices = () => (
    <TimelineItem>
      <Typography variant="body1">No overdue invoices.</Typography>
    </TimelineItem>
  );

  export const CompanyCreditSafeInactive = () => (
    <TimelineItem>
      <Typography variant="body1">
        The merchant is bankrupt, under reconstruction, or in default.
      </Typography>
    </TimelineItem>
  );

  export const CompanyHasPaymentRemarks = ({
    balance,
    currency,
  }: {
    balance: number | undefined;
    currency: string | undefined;
  }) =>
    balance && currency ? (
      <TimelineItem>
        <Typography variant="body1">
          Payment remark: there are historical claims, and the enforcement
          authority balance is{' '}
          <Typography display={'inline'} variant="subtitle1">
            {formatCurrency(balance, currency)}
          </Typography>
          .
        </Typography>
      </TimelineItem>
    ) : (
      <TimelineItem>
        <Typography variant="body1">Merchant has payment remarks.</Typography>
      </TimelineItem>
    );
}
