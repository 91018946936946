import { gql, TypedDocumentNode } from '@apollo/client';
import { FilterComparator, QueryOptions } from '@treyd-io/core/types/hasura';
import { getGqlQueryParams } from '@treyd-io/core/utils/hasura';
import { map } from 'lodash';

type InvoiceByFinancierAggregation = {
  aggregate: {
    count: number;
    sum: {
      invoiced_amount: number;
      paid_amount: number;
      remaining_amount: number;
    };
  };
};

type AggregateInvoicesByFinanciersQueryType = Record<
  string,
  InvoiceByFinancierAggregation
>;

export const aggregateInvoicesByFinanciersQuery = (
  financiersIds?: number[],
  currencies?: string[],
  queryOptions?: QueryOptions
) => {
  const financiersTemplates = map(financiersIds, (financierId) =>
    getFinancierTemplate(financierId, currencies, queryOptions)
  ).join('\n');

  return gql`
    query aggregateInvoicesByFinanciers {
     ${financiersTemplates}
    }
  ` as TypedDocumentNode<AggregateInvoicesByFinanciersQueryType, {}>;
};

const getFinancierTemplate = (
  financierId: number,
  currencies?: string[],
  queryOptions?: QueryOptions
) => {
  return map(currencies, (currency) =>
    getFinancierCurrencyTemplate(financierId, currency, queryOptions)
  ).join('\n');
};

const getFinancierCurrencyTemplate = (
  financierId: number,
  currency: string,
  queryOptions?: QueryOptions
) => {
  const { queryParams } = getGqlQueryParams({
    ...queryOptions,
    filters: [
      ...(queryOptions?.filters || []),
      {
        type: 'filter',
        name: 'financier_id',
        comparator: FilterComparator.EQUAL,
        comparisonValue: financierId,
      },
      {
        type: 'filter',
        name: 'invoiced_currency',
        comparator: FilterComparator.EQUAL,
        comparisonValue: currency,
      },
    ],
  });

  return `
    financier_${financierId}_currency_${currency}:orders_order_invoices_aggregate ${queryParams} {
      aggregate {
        count
        sum {
          invoiced_amount
          paid_amount
          remaining_amount
        }
      }
    }
    `;
};
