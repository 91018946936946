import { ORDER_REVIEW_ROUTES, ROUTES } from '@/constants';
import { RouteObject } from 'react-router-dom';
import OrderReviewDetails from './OrderReviewDetails';
import OrderReviewDetailsWrapper from './OrderReviewDetails/OrderReviewDetailsWrapper';
import OrderReviewListing from './OrderReviewListing';

export const ORDER_REVIEW: RouteObject = {
  path: `${ROUTES.orderReview}`,
  children: [
    {
      index: true,
      element: <OrderReviewListing />,
    },
    {
      path: `${ORDER_REVIEW_ROUTES[':id']}`,
      element: (
        <OrderReviewDetailsWrapper
          render={(props: any) => <OrderReviewDetails {...props} />}
        />
      ),
    },
  ],
};
