import { ComponentLoader } from '@/components/ComponentLoader';
import { CREDIT_SUB_STATUS_TITLE } from '@/constants';
import {
  useGetLimitStatus,
  useGetNewCreditDecisionRequirements,
} from '@/hooks';
import { Requirement as IRequirement } from '@/schemas';
import { Stack, Typography } from '@mui/material';
import { Col } from '@treyd-io/treyd-ui/components/Grid/Col';
import { Row } from '@treyd-io/treyd-ui/components/Grid/Row';
import CheckCircleIcon from '@treyd-io/treyd-ui/icons/CheckCircleIcon';
import MinusCircleIcon from '@treyd-io/treyd-ui/icons/MinusCircleIcon';
import { find, isEmpty, last, map } from 'lodash';
import { EmptySection } from './EmptySection';

export const NewCreditDecision = ({ companyId }: { companyId: number }) => {
  const { data, loading: isRequirementsLoading } =
    useGetNewCreditDecisionRequirements({ companyId });

  const { data: limitStatus, isLoading: isLimitStatusLoading } =
    useGetLimitStatus(companyId);

  const creditData = limitStatus?.credit.uiCodes;

  const creditSubStatus = find(
    creditData,
    (uiCode) => uiCode.code === 'NEW_CREDIT_DECISION_IN_STATUS'
  )?.kargs.status;

  const requirements = last(
    data?.lager_risk_creditdecision
  )?.creditdecision_requirements;

  const isLoading = isLimitStatusLoading || isRequirementsLoading;

  if (isLoading) return <ComponentLoader />;

  if (!requirements && !creditSubStatus)
    return (
      <EmptySection
        title="No new credit decision"
        subtitle="The new published credit decision will display here"
        inline
      />
    );

  return (
    <Stack gap={3}>
      <Stack gap={2}>
        <Typography variant="h6">New credit decision</Typography>
        {creditSubStatus && (
          <Typography variant="body2">
            Credit decision - {CREDIT_SUB_STATUS_TITLE[creditSubStatus]}
          </Typography>
        )}
      </Stack>
      {requirements && !isEmpty(requirements) && (
        <RequirementsSection requirements={requirements} />
      )}
    </Stack>
  );
};

const RequirementsSection = ({
  requirements,
}: {
  requirements: IRequirement[];
}) => (
  <Stack gap={1}>
    <Typography variant="subtitle1">Requirements for limit renewal:</Typography>

    <Row spacing={{ xs: 1, sm: 4 }}>
      {map(requirements, (requirement) => (
        <Col xs={12} sm={'auto'}>
          <Requirement requirement={requirement} key={requirement.id} />
        </Col>
      ))}
    </Row>
  </Stack>
);

const Requirement = ({ requirement }: { requirement: IRequirement }) => (
  <Stack direction="row" gap={1} alignItems={'center'}>
    {requirement.is_fulfilled ? (
      <CheckCircleIcon color="success" />
    ) : (
      <MinusCircleIcon color="disabled" />
    )}
    <Typography variant="body1">{requirement.display_name}</Typography>
  </Stack>
);
