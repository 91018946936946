import { useSupplierInvoice } from '@/hooks';
import { Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEmpty, toString } from 'lodash';
import { memo } from 'react';
export const SupplierInvoiceDrawer = () => {
  const { isSupplierInvoiceOpen, invoiceUrl } = useSupplierInvoice();
  const theme = useTheme();
  const isSmallScreens = useMediaQuery(theme.breakpoints.down('md'));
  if (isSmallScreens) return null;

  return (
    <Drawer
      PaperProps={{
        sx: {
          display: !isSupplierInvoiceOpen ? 'none' : 'flex',
          width: isSupplierInvoiceOpen ? '40%' : 0,
        },
      }}
      sx={{
        maxWidth: isSupplierInvoiceOpen ? '40%' : 0,
        width: '100%',
      }}
      variant="persistent"
      anchor="right"
      open={isSupplierInvoiceOpen}>
      <PdfContainer invoiceUrl={invoiceUrl} />
    </Drawer>
  );
};

const PdfContainer = memo(
  ({ invoiceUrl }: { invoiceUrl?: string }) => {
    return (
      <iframe
        key={invoiceUrl}
        src={toString(invoiceUrl)}
        title="Supplier invoice"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    );
  },
  (prevProps) => !isEmpty(prevProps.invoiceUrl)
);
