import {
  ADD_NOTIFICATION_GROUP,
  EDIT_NOTIFICATION_GROUP,
  PUBLISH_NOTIFICATION_GROUP,
  UNPUBLISH_NOTIFICATION_GROUP,
} from '@/constants';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { save } from '@treyd-io/core/utils/request';

export interface NotificationGroup {
  type: string;
  header: string;
  body: string;
  targets_type: string;
  dismissible?: boolean;
  action_text?: string;
  action_url?: string;
}

export const addNotificationGroup = async (
  data: NotificationGroup
): Promise<any> =>
  await save(ADD_NOTIFICATION_GROUP, data, REQUEST_METHOD.POST, {
    noToast: true,
  });
export const editNotificationGroup = async (
  groupId: number,
  data: { targets_type: string; targets_ids: number[] }
): Promise<any> =>
  await save(EDIT_NOTIFICATION_GROUP(groupId), data, REQUEST_METHOD.PUT, {
    noToast: true,
  });
export const publishNotificationGroup = async (
  group_id: number
): Promise<any> =>
  await save(
    PUBLISH_NOTIFICATION_GROUP(group_id),
    undefined,
    REQUEST_METHOD.PUT,
    { noToast: true }
  );
export const unpublishNotificationGroup = async (
  group_id: number
): Promise<any> =>
  await save(
    UNPUBLISH_NOTIFICATION_GROUP(group_id),
    undefined,
    REQUEST_METHOD.PUT,
    { noToast: true }
  );
