import { InvoiceOpsStatusChip } from '@/components';
import { TransactionsDialog } from '@/components/Invoices/TransactionsDialog';
import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import {
  INVOICE_OPS_STATUS,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  ROUTES,
} from '@/constants';
import { useGetInvoices } from '@/hooks';
import { Invoice } from '@/schemas';
import { InvoiceService } from '@/services';
import { Stack, Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { formatDate } from '@treyd-io/core/utils/date';
import { formatCurrency } from '@treyd-io/core/utils/number';
import SwapIcon from '@treyd-io/treyd-ui/icons/SwapIcon';
import { startCase, toNumber, toString } from 'lodash';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

export const InvoicesTab = () => {
  const { id } = useParams();
  const orderId = toNumber(id);

  const filters: Filter[] = [
    {
      type: 'filter',
      name: 'order_id',
      comparator: FilterComparator.EQUAL,
      comparisonValue: orderId,
    },
  ];
  const [selectedInvoice, setSelectedInvoice] = useState<null | Invoice>();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'id', sort: 'desc' },
  ]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const { data, loading: isLoadingInvoices } = useGetInvoices({
    queryOptions: {
      filters,
      sorting: sortModel[0],
      limit: paginationModel?.pageSize,
      offset: paginationModel?.page * paginationModel?.pageSize,
    },
  });

  const invoices = data?.orders_order_invoices || [];
  const rowCount = data?.orders_order_invoices_aggregate?.aggregate?.count || 0;

  const noInvoicesProps = !rowCount && {
    Header: () => null,
    hideFooter: true,
    columnHeaderHeight: 0,
  };

  const columns: GridColDef<Invoice>[] = useMemo(
    () => [
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Transactions',
        flex: 1,
        minWidth: 105,
        getActions: (params: GridRowParams<Invoice>) => {
          return [
            <GridActionsCellItem
              color="primary"
              icon={<SwapIcon />}
              label="Transaction"
              onClick={() => setSelectedInvoice(params.row)}
            />,
          ];
        },
      },
      {
        field: 'invoice_number',
        headerName: 'Invoice #',
        flex: 1,
        minWidth: 90,
        renderCell: (params) => {
          return (
            <Link to={`${ROUTES.invoices}/${params.id}`}>{params.value}</Link>
          );
        },
      },
      {
        field: 'ops_status',
        headerName: 'Status',
        minWidth: 170,
        flex: 1,
        renderCell: ({ value }) => (
          <InvoiceOpsStatusChip size="small" status={value} />
        ),
      },

      {
        field: 'invoiced_amount',
        headerName: 'Amount',
        minWidth: 150,
        flex: 1,
        cellClassName: 'tabular-numbers',
        renderCell: (params) =>
          formatCurrency(params.value, toString(params.row?.invoiced_currency)),
      },
      {
        field: 'paid_amount',
        headerName: 'Paid amount',
        minWidth: 150,
        flex: 1,
        cellClassName: 'tabular-numbers',
        renderCell: (params) =>
          formatCurrency(params.value, toString(params.row?.invoiced_currency)),
      },
      {
        field: 'remaining_amount',
        headerName: 'Remaining amount',
        minWidth: 150,
        flex: 1,
        cellClassName: 'tabular-numbers',
        renderCell: (params) =>
          formatCurrency(params.value, toString(params.row?.invoiced_currency)),
      },
      {
        field: 'invoice_date',
        headerName: 'Invoice date',
        flex: 1,
        minWidth: 100,
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
      {
        field: 'due_date',
        headerName: 'Due date',
        flex: 1,
        minWidth: 100,
        renderCell: ({ value }) => value && formatDate(new Date(value), 'date'),
      },
      {
        field: 'closed_date',
        headerName: 'Closed date',
        flex: 1,
        minWidth: 100,
        renderCell: ({ value, row }) => {
          const closedDate = InvoiceService.getInvoiceClosedDate(
            value,
            row.closed_date_overwrite
          );
          return closedDate && formatDate(new Date(closedDate), 'date');
        },
      },
      {
        field: 'overdue_days',
        headerName: 'Days till due date',
        flex: 1,
        sortable: false,
        minWidth: 140,
        renderCell: (params) => {
          const isOverDue = InvoiceService.doesInvoicePassDueDate(
            params.value,
            params.row?.ops_status as INVOICE_OPS_STATUS
          );
          const numberOfDays = Math.abs(params.value);
          const daysPostfix = numberOfDays === 1 ? 'day' : 'days';
          if (isOverDue)
            return (
              <Typography color="error.main">
                {numberOfDays} {daysPostfix} overdue
              </Typography>
            );
          if (isOverDue === false)
            return (
              <Typography color="success.main">
                {numberOfDays} {daysPostfix} remaining
              </Typography>
            );
          return <></>;
        },
      },
      {
        field: 'financier__name',
        headerName: 'Financier',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => params.row?.financier?.name,
      },
      {
        field: 'invoice_type',
        headerName: 'Type',
        minWidth: 100,
        flex: 1,
        renderCell: (params) => startCase(toString(params.value)),
      },
      {
        field: 'invoicing_system',
        headerName: 'System',
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        minWidth: 70,
        renderCell: ({ value }) => (
          <Tooltip title={value}>
            <Typography>
              {InvoiceService.isOldInvoicingSystem(value) ? 'Old' : value}
            </Typography>
          </Tooltip>
        ),
      },
    ],
    []
  );

  const handleCloseTransactionsDialog = () => {
    setSelectedInvoice(null);
  };

  return (
    <Stack flexGrow={1} height={400} width="100%">
      <DataGrid
        rows={invoices}
        columns={columns}
        disableRowSelectionOnClick
        sortingMode="server"
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
        rowCount={rowCount}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        sortModel={sortModel}
        loading={isLoadingInvoices}
        onSortModelChange={setSortModel}
        slots={{
          noRowsOverlay: () => (
            <TableHasNoRowsPlaceholder
              title={'Nothing here yet'}
              subtitle={'Invoices will be displayed here'}
            />
          ),
          ...noInvoicesProps,
        }}
        {...noInvoicesProps}
      />
      <TransactionsDialog
        onClose={handleCloseTransactionsDialog}
        invoiceNumber={toString(selectedInvoice?.invoice_number)}
        isOldInvoice={InvoiceService.isOldInvoicingSystem(
          selectedInvoice?.invoicing_system
        )}
        open={Boolean(selectedInvoice)}
        transactions={selectedInvoice?.transactions}
      />
    </Stack>
  );
};
