import { ORDER_EVENT } from '@/constants';
import { User } from './user';

type AutoOrderReviewChecks =
  | 'verified_supplier'
  | 'invoice_due_date_match'
  | 'total_amount_match'
  | 'currency_match'
  | 'goods_type_match'
  | 'has_weapons'
  | 'has_restricted_product'
  | 'total_and_due_amount_match';

export interface AutoOrderReviewResponse {
  is_auto_approval: boolean;
  failed_checks_keys: AutoOrderReviewChecks[];
}

export interface OrderEventPayload {
  id: number;
  eventName: ORDER_EVENT;
}

export type ActiveOrderReview = {
  comment?: string;
  data?: Record<string, unknown>;
  reason?: string;
  reviewer_id?: string | null;
  reviewer?: User;
  verdict?: string;
} | null;

export enum ADDITIONAL_TAGS {
  IGNORE_AUTOMATION = '#$IGNORE-AUTOMATION#$',
  NORDEA_PAYMENT = '#$NordeaPayment#$',
  OCR = '#$OCR:ocrNumber#$',
  FREIGHT = '#$Freight#$',
  MARKETING = '#$Marketing#$',
  PRE_PRODUCTION_MATERIALS = '#$PreProductionMaterials#$',
}
