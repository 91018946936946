import Order from '@/pages/orderReview/models/Order';
import { RepaymentBreakdownSection } from '@/pages/orders/RepaymentBreakdownSection';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ChevronDownIcon from '@treyd-io/treyd-ui/icons/ChevronDownIcon';

export const RepaymentAccordion = ({ order }: { order: Order }) => {
  return (
    <Box>
      <Accordion
        defaultExpanded
        sx={{
          border: 'none',
          backgroundColor: 'transparent',
        }}>
        <AccordionSummary
          sx={{ padding: 0 }}
          expandIcon={<ChevronDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography variant="h6">Repayment breakdown</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingInline: 0 }}>
          <RepaymentBreakdownSection order={order} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
