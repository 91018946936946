import { updateUserDataMutation } from '@/schemas';
import { User } from '@/types';
import { useMutation } from '@apollo/client';
import { REQUEST_METHOD } from '@treyd-io/core/constants/request';
import { save } from '@treyd-io/core/utils/request';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { useAuth } from 'auth';
import { LAGER_CURRENT_USER } from 'constants/api-endpoints';
import { FormikErrors, useFormik } from 'formik';

interface FormValues {
  firstName: string;
  lastName: string;
  isDataChanged?: string;
}

export const useProfileController = () => {
  const { user, logout, setUser } = useAuth();
  const [updateUserData] = useMutation(updateUserDataMutation);
  const showNotificationMessage = useToastNotification();

  const onSuccess = async () => {
    const resp = await save<User>(LAGER_CURRENT_USER, {}, REQUEST_METHOD.PUT);
    setUser(resp.data);
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }
    if (
      user?.first_name === values.firstName &&
      user?.last_name === values.lastName
    ) {
      errors.isDataChanged =
        'At least one field should be changed.(i.e. First Name / Last Name)';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
    },
    enableReinitialize: true,
    validate,
    validateOnMount: true,
    onSubmit: (values) => {
      updateUserData({
        variables: {
          id: user?.id,
          userData: {
            first_name: values.firstName,
            last_name: values.lastName,
          },
        },
      })
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          showNotificationMessage({
            title: 'Something went wrong.',
            type: 'error',
          });
        });
    },
  });

  return {
    states: {
      user,
      formValues: formik.values,
      errors: formik.errors,
      isFormTouched: formik.touched,
      isValid: formik.isValid,
      isSubmitting: formik.isSubmitting,
    },
    actions: {
      handleSubmit: formik.handleSubmit,
      handleChange: formik.handleChange,
      logout,
    },
  };
};
