import { TypedDocumentNode, gql } from '@apollo/client';
import {
  Lager_Core_Company,
  Lager_Risk_Creditdecision,
} from '__generated__/graphql';

type GetCompanyCreditDecisionHistoryQueryType = {
  lager_core_company: (Pick<Lager_Core_Company, 'creditdecisions'> & {
    creditdecisions: Pick<
      Lager_Risk_Creditdecision,
      | 'created'
      | 'status'
      | 'published_at'
      | 'expiration_date'
      | 'approved_rating'
      | 'approved_limit'
      | 'approved_limit_currency'
    >[];
  })[];
};

export type GetCompanyCreditDecisionHistoryQueryVariables = {
  orgNumber: string;
  country: string;
};

export const getCompanyCreditDecisionHistoryQuery = gql`
  query getCompanyCreditDecisionHistory(
    $orgNumber: String!
    $country: String!
  ) {
    lager_core_company(
      where: { orgnr: { _eq: $orgNumber }, country: { _eq: $country } }
    ) {
      creditdecisions(
        where: { status: { _in: ["outdated", "expired"] } }
        order_by: { created: desc }
      ) {
        created
        status
        published_at
        expiration_date
        approved_rating
        approved_limit
        approved_limit_currency
      }
    }
  }
` as TypedDocumentNode<
  GetCompanyCreditDecisionHistoryQueryType,
  GetCompanyCreditDecisionHistoryQueryVariables
>;
