export const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((word, index) => {
      if (index === 0 || index === 1) {
        return word[0];
      }
      return '';
    })
    .filter((letter) => letter)
    ?.join('');
};

export const toSentenceCase = (str: string) => {
  const s = str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.join(' ');
  return s ? s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase() : str;
};
