import { Lager_Treyd_Users } from '@/__generated__/graphql';
import { TypedDocumentNode, gql } from '@apollo/client';

type CompanyUser = Pick<Lager_Treyd_Users, 'email'>;
type GetCompanyUsersEmailsQueryType = {
  lager_treyd_users: CompanyUser[];
};

export const getCompanyUsersEmails: TypedDocumentNode<
  GetCompanyUsersEmailsQueryType,
  { companyId: number }
> = gql`
  query getCompanyUsersEmails($companyId: bigint!) {
    lager_treyd_users(where: { company_id: { _eq: $companyId } }) {
      email
    }
  }
`;
