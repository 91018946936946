import { Stack } from '@mui/material';
import { Filter, FilterComparator } from '@treyd-io/core/types/hasura';
import { TransactionsTable } from './TransactionsTable';

export const Transactions = ({ invoiceNumber }: { invoiceNumber: string }) => {
  const filters: Filter[] = [
    {
      type: 'filter',
      name: 'invoicing_app_invoices__number',
      comparator: FilterComparator.EQUAL,
      comparisonValue: invoiceNumber,
    },
  ];

  return (
    <Stack>
      <TransactionsTable filters={filters} />
    </Stack>
  );
};
