import { useGetTransactions } from '@/hooks';
import { TransactionsCsvService } from '@/services';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Filter } from '@treyd-io/core/types/hasura';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import ExportIcon from '@treyd-io/treyd-ui/icons/ExportIcon';
import { useState } from 'react';

interface ExportTransactionsButtonProps {
  filters?: Filter[];
  buttonProps?: LoadingButtonProps;
}

export const ExportTransactionsButton = (
  props: ExportTransactionsButtonProps
) => {
  const { buttonProps, filters } = props;
  const [isExporting, setIsExporting] = useState(false);
  const showToastMessage = useToastNotification();
  const transactionsCSVService = new TransactionsCsvService();

  const { refetch: getExportedTransactions } = useGetTransactions({
    queryOptions: {
      filters,
    },
    skip: true,
  });

  const handleExportTransactions = async () => {
    setIsExporting(true);
    const response = await getExportedTransactions();
    const isExported = transactionsCSVService.exportToCsv(
      response.data?.invoicing_app_transaction
    );
    if (isExported) {
      showToastMessage({
        type: 'success',
        title: 'Transactions exported',
      });
    } else {
      showToastMessage({
        type: 'warning',
        title: 'No transactions found',
      });
    }
    setIsExporting(false);
  };

  return (
    <LoadingButton
      size="large"
      variant="outlined"
      color="secondary"
      loading={isExporting}
      fullWidth
      startIcon={<ExportIcon />}
      onClick={(e) => {
        handleExportTransactions();
        buttonProps?.onClick?.(e);
      }}
      {...buttonProps}>
      Export
    </LoadingButton>
  );
};
