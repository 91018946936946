import { SvgIcon, SvgIconProps } from '@mui/material';

export const IllustrationStars = (props: SvgIconProps) => {
  return (
    <SvgIcon
      style={{ fill: 'none', ...props.style }}
      data-testid="IllustrationStars"
      {...props}>
      <path
        d="M2.3125 45.9783C28.8747 42.14 43.7733 27.4075 46.2783 0.731689C48.7614 27.3901 63.6206 42.022 89.6844 46.0308C78.0165 47.6352 67.4983 51.6047 59.1746 60.1294C50.8379 68.6716 47.3624 79.3079 46.3263 91.0982C43.7296 64.4967 28.8441 49.8647 2.31687 45.974L2.3125 45.9783Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M71.9358 72.1301C73.0243 80.8122 78.0386 85.6036 86.8169 87.0244C78.0037 88.4364 72.9937 93.2715 71.9708 102.268C70.3139 92.8474 66.6811 89.0834 57.2383 87.0156C65.9073 85.538 70.8822 80.7554 71.9402 72.1301H71.9358Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};
