import { Chip } from '@mui/material';
import { toSentenceCase } from '@treyd-io/core/utils/string';

export const DocumentStatusChip = ({ status }: { status: string }) => {
  if (status === 'pending_signature') {
    return (
      <Chip label={'Pending'} variant="filled" size="small" color="secondary" />
    );
  }
  if (status === 'signed') {
    return <Chip label={'Signed'} size="small" color={'success'} />;
  }
  return <Chip label={toSentenceCase(status)} size="small" />;
};
