import { gql } from '@apollo/client';

export const Django_Bank_Account = gql`
  fragment DjangoBankAccountType on BankAccountType {
    id
    acct_number
    bank_account_holder_name
    bank_address
    bank_code
    bank_country
    bank_name
    bic_swift
    branch_code
    bsb_code
    clabe
    cnaps
    combination_id
    currency
    error_msgs
    ifsc
    institution_no
    payment_type
    sort_code
    aba
    iban
    created_at
    updated_at
    bankgiro_number
    plusgiro_number
  }
`;
