import { Button, Stack, Typography, styled } from '@mui/material';
import NoAvatarIcon from '@treyd-io/treyd-ui/icons/NoAvatarIcon';
import UserCircleIcon from '@treyd-io/treyd-ui/icons/UserCircleIcon';
import { useAuth } from 'auth';

export const AssigneeRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

interface AssigneeSectionProps {
  reviewerId: number;
  reviewerName: string;
  onClickAssign: () => void;
}
const AssigneeSection = ({
  reviewerId,
  reviewerName,
  onClickAssign,
}: AssigneeSectionProps) => {
  const { user } = useAuth();
  if (!!reviewerId) {
    return (
      <Stack gap={1}>
        <AssigneeRow>
          <Stack direction="row" gap={1}>
            <UserCircleIcon color="primary" />
            <Typography variant="subtitle1">Assignee</Typography>
          </Stack>
          {user.id !== reviewerId && (
            <Button onClick={onClickAssign} color="info" size="small">
              Assign to yourself
            </Button>
          )}
        </AssigneeRow>
        <Typography variant="body1" color="text.primary">
          {reviewerName}
          {user.id === reviewerId ? ` (you)` : ''}
        </Typography>
      </Stack>
    );
  } else {
    return (
      <Stack direction="row" gap={1}>
        <NoAvatarIcon color="primary" />
        <Typography variant="subtitle1" color="text.primary">
          No assignee
        </Typography>
      </Stack>
    );
  }
};

export default AssigneeSection;
