import * as Sentry from '@sentry/react';
import { ENV } from '@treyd-io/core/constants/api';
import { SentryContext } from 'context';
import { ReactNode } from 'react';

export const SentryProvider = ({ children }: { children: ReactNode }) => {
  Sentry.init({
    environment: ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 0.1,
  });

  return (
    <SentryContext.Provider value={Sentry}>{children}</SentryContext.Provider>
  );
};
