import { useActivityLog } from '@/hooks';
import { ActivityLogSection } from '@/pages/orderReview/OrderReviewDetails/ActivityLogSection';
import Order from '@/pages/orderReview/models/Order';
import { InvoicingEmailsSection } from '@/pages/orders/InvoicingEmailsSection';
import { OrderVoucherAlert } from '@/pages/orders/OrderDetails/NonDraftOrderView/DetailsTab/OrderVoucherAlert';
import { Divider, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { OrderInformationSection } from './OrderInformationSection';
import { Payout } from './Payout';
import { RepaymentAccordion } from './RepaymentAccordion';
import { SupplierAccordion } from './SupplierAccordion';

export const DetailsTab = ({ order }: { order: Order }) => {
  const hasInvoicingEmails = !isEmpty(order?.invoice_contact_list);
  const logs = useActivityLog(order.id);

  return (
    <Stack flexGrow={1} direction="row" flexWrap="wrap" width="100%" gap={6}>
      <Stack flexGrow={1} flexBasis={650} width="100%" gap={7}>
        <OrderVoucherAlert
          orderId={order?.id}
          importerId={order?.importer_id}
        />
        <OrderInformationSection order={order} />
        <Payout order={order} />
        <Stack gap={3}>
          <Divider />
          <SupplierAccordion order={order} />
          <Divider />
          <RepaymentAccordion order={order} />
          {order?.invoice_contact_list && hasInvoicingEmails && (
            <>
              <Divider />
              <InvoicingEmailsSection
                invoicingEmails={order?.invoice_contact_list}
              />
            </>
          )}
        </Stack>
      </Stack>
      <Stack
        gap={6}
        flexGrow={{ xs: 1, xl: 'unset' }}
        flexBasis={336}
        width="100%">
        <ActivityLogSection
          orderId={order?.id}
          logs={logs}
          disableAdditionalTags
        />
      </Stack>
    </Stack>
  );
};
