import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress, TextField } from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Form, FormButton } from '@/components/styled-components/FormStyles';
import { CompanyTables } from '@/pages/dev/components';
import { getAllPotentialCompanyUsersById } from '@/schemas';
import { toNumber } from 'lodash';
interface FormValues {
  company_id: number | string;
  dateValue: string;
}

export const CompanyUsersAndMembers = () => {
  const { state } = useLocation();

  const initialValues: FormValues = {
    company_id: state?.company_id || '',
    dateValue: state?.dateValue || Date.now(),
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.company_id) {
      errors.company_id = 'Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      getCompanyData({
        variables: { company_id: toNumber(values.company_id) },
      });
    },
  });

  const [getCompanyData, { data, loading: dataLoading }] = useLazyQuery(
    getAllPotentialCompanyUsersById,
    { variables: { company_id: toNumber(formik.values.company_id) } }
  );
  const companyUsers = data?.orders_companies_by_pk?.company_users;
  const companyMembers = data?.orders_companies_by_pk?.core_company?.members;

  useEffect(() => {
    if (state?.company_id) {
      getCompanyData({ variables: { company_id: state?.company_id } });
    }
  }, [getCompanyData, state?.company_id]);

  return (
    <>
      <Box>
        <Form sx={{ maxWidth: 'max-content' }} onSubmit={formik.handleSubmit}>
          <TextField
            variant="outlined"
            type={'number'}
            placeholder="Enter a company id..."
            label="Company ID"
            name="company_id"
            value={formik.values.company_id}
            onChange={formik.handleChange}
            error={
              formik.touched.company_id && Boolean(formik.errors.company_id)
            }
            helperText={formik.touched.company_id && formik.errors.company_id}
          />
          <FormButton variant="outlined" type="submit">
            Get Data
          </FormButton>
        </Form>
      </Box>
      {dataLoading && (
        <div>
          <CircularProgress size={20} />
        </div>
      )}
      {data && (
        <CompanyTables
          companyUsers={companyUsers}
          companyMembers={companyMembers}
          companyID={formik.values.company_id}
        />
      )}
    </>
  );
};
