import { useGetSuppliers } from '@/hooks';
import { SuppliersCsvService } from '@/services';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Filter } from '@treyd-io/core/types/hasura';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import ExportIcon from '@treyd-io/treyd-ui/icons/ExportIcon';
import { useState } from 'react';

interface ExportSuppliersButtonProps {
  filters?: Filter[];
  buttonProps?: LoadingButtonProps;
}

export const ExportSuppliersButton = (props: ExportSuppliersButtonProps) => {
  const { buttonProps, filters } = props;
  const [isExporting, setIsExporting] = useState(false);
  const showToastMessage = useToastNotification();
  const suppliersCSVService = new SuppliersCsvService();

  const { refetch: getExportedSuppliers } = useGetSuppliers({
    queryOptions: {
      filters,
    },
    skip: true,
  });

  const handleExportSuppliers = async () => {
    setIsExporting(true);
    const response = await getExportedSuppliers();
    const isExported = suppliersCSVService.exportToCsv(
      response.data?.orders_suppliers
    );
    if (isExported) {
      showToastMessage({
        type: 'success',
        title: 'Suppliers exported',
      });
    } else {
      showToastMessage({
        type: 'warning',
        title: 'No suppliers found',
      });
    }
    setIsExporting(false);
  };

  return (
    <LoadingButton
      size="large"
      variant="outlined"
      color="secondary"
      loading={isExporting}
      fullWidth
      startIcon={<ExportIcon />}
      onClick={(e) => {
        handleExportSuppliers();
        buttonProps?.onClick?.(e);
      }}
      {...buttonProps}>
      Export
    </LoadingButton>
  );
};
