import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import RefreshIcon from '../icons/RefreshIcon';
import SettingsIcon from '../icons/SettingsIcon';

const MaintenanceMode = () => {
  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <Stack direction="column" minHeight="100vh" padding="1.5rem">
      <Stack alignItems="center" justifyContent="center" flexGrow={1}>
        <Stack alignItems="center" justifyContent="center" width="100%" gap={6}>
          <SettingsIcon sx={{ fontSize: 120, mb: 2 }} />
          <Stack
            gap={3}
            alignItems="center"
            justifyContent="center"
            width="100%">
            <Stack gap={2} textAlign="center">
              <Typography variant="h3">We'll be back soon.</Typography>
              <Typography variant="body1">
                Sorry for the inconvenience but we're performing some
                maintenance at the moment and we'll be back online shortly.
              </Typography>
            </Stack>
            <Stack
              direction={{ sm: 'row' }}
              gap={2}
              width="100%"
              justifyContent="center">
              <Button
                size="large"
                variant="contained"
                onClick={handleRetry}
                startIcon={<RefreshIcon />}>
                Reload
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                href={'mailto:support@treyd.io'}
                size="large">
                Talk to support
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MaintenanceMode;
