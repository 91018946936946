import { ORDER_REVIEW_VERDICT } from '@/constants';
import { useLogglyLoggingService, useSubmitOrderReview } from '@/hooks';
import OrderActionModal from '@/pages/orderReview/OrderReviewDetails/OrderActionModal';
import {
  FormValues,
  ORDER_REVIEW_ACTIONS,
} from '@/pages/orderReview/OrderReviewDetails/OrderActionModal/types';
import {
  APPROVAL_FORM_INITIAL_STATE,
  MODAL_CONTENT,
  ModalContent,
} from '@/pages/orderReview/OrderReviewDetails/constants';
import Order from '@/pages/orderReview/models/Order';
import { Button, Stack } from '@mui/material';
import { useToastNotification } from '@treyd-io/treyd-ui/hooks/useToastNotification';
import { LOGGING_MESSAGES } from 'constants/loggly';
import { Can } from 'context';
import { isEmpty } from 'lodash';

import { useState } from 'react';

interface OpsActionButtonsProps {
  orderId: number;
  actionsDisabled: boolean;
  goBack: () => void;
  dealDuplicates?: Order[];
  areAllSectionsApproved: boolean;
}

type ModalState = ModalContent & {
  title: string;
  onSubmit: (values: FormValues) => void;
};

const OpsActionsButtons = (props: OpsActionButtonsProps) => {
  const {
    orderId,
    actionsDisabled,
    goBack,
    dealDuplicates,
    areAllSectionsApproved,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalState, setModalState] = useState<ModalState>({
    title: '',
    onSubmit: () => {},
    subtitle: '',
    commentInputLabel: '',
    initialErrors: {},
    initialValues: {},
    submitButtonColor: 'primary',
    submitButtonLabel: '',
  });

  const log = useLogglyLoggingService();
  const showNotificationMessage = useToastNotification();

  const { mutate: submitOrderReview } = useSubmitOrderReview();

  const sendOrderBack = async (values: FormValues) => {
    submitOrderReview(
      {
        orderId,
        data: {
          verdict: ORDER_REVIEW_VERDICT.SEND_BACK,
          ...values,
        },
      },
      {
        onSuccess: () => {
          showNotificationMessage({
            title: `Order #${orderId} was sent back to draft.`,
            type: 'success',
          });
          log({
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_SEND_BACK_SUCCESS,
            data: { orderId: orderId },
          });
          goBack();
        },
        onError: (err: any) => {
          log({
            type: 'ERROR',
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_SEND_BACK_FAIL,
            data: { orderId: orderId, error: err },
          });
          showNotificationMessage({
            title: err?.response?.data?.comment?.[0] || 'Something went wrong.',
            type: 'error',
          });
        },
      }
    );
  };

  const rejectOrder = async (values: FormValues) => {
    submitOrderReview(
      {
        orderId,
        data: {
          verdict: ORDER_REVIEW_VERDICT.REJECT,
          ...values,
        },
      },
      {
        onSuccess: () => {
          showNotificationMessage({
            title: `Order #${orderId} was rejected.`,
            type: 'success',
          });
          log({
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_REJECT_SUCCESS,
            data: { orderId: orderId },
          });
          goBack();
        },
        onError: (err: any) => {
          log({
            type: 'ERROR',
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_REJECT_FAIL,
            data: { orderId: orderId, error: err },
          });
          showNotificationMessage({
            title: err?.response?.data?.comment?.[0] || 'Something went wrong.',
            type: 'error',
          });
        },
      }
    );
  };

  const approveOrder = async (values: FormValues) => {
    submitOrderReview(
      {
        orderId,
        data: {
          verdict: ORDER_REVIEW_VERDICT.APPROVE,
          ...values,
        },
      },
      {
        onSuccess: () => {
          showNotificationMessage({
            title: `Order #${orderId} was approved.`,
            type: 'success',
          });
          log({
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_APPROVE_SUCCESS,
            data: { orderId: orderId },
          });
          goBack();
        },
        onError: (err: any) => {
          log({
            type: 'ERROR',
            key: 'Orders',
            message: LOGGING_MESSAGES.ORDER_APPROVE_FAIL,
            data: { orderId: orderId, error: err },
          });
          showNotificationMessage({
            title:
              err?.response?.data?.errors?.financier?.[0] ||
              err?.response?.data?.comment?.[0] ||
              'Something went wrong.',
            type: 'error',
          });
        },
      }
    );
  };

  const onActionClick = async (action_name: ORDER_REVIEW_ACTIONS) => {
    setIsModalOpen(true);
    let new_state = {
      title: `${action_name} #${orderId}`,
      onSubmit: (values: FormValues) => {
        switch (action_name) {
          case ORDER_REVIEW_ACTIONS.APPROVE:
            return approveOrder(values);
          case ORDER_REVIEW_ACTIONS.REJECT:
            return rejectOrder(values);
          case ORDER_REVIEW_ACTIONS.SEND_BACK:
            return sendOrderBack(values);
        }
      },
      ...MODAL_CONTENT[action_name],
    };
    if (
      action_name === ORDER_REVIEW_ACTIONS.APPROVE &&
      !isEmpty(dealDuplicates)
    ) {
      new_state = {
        ...new_state,
        subtitle: `This order is very similar to another order (order #${dealDuplicates?.[0]?.id}) Do you want to approve it anyway?`,
        submitButtonLabel: 'Approve anyway',
        initialErrors: APPROVAL_FORM_INITIAL_STATE,
      };
    } else if (
      action_name === ORDER_REVIEW_ACTIONS.APPROVE &&
      !areAllSectionsApproved
    ) {
      new_state = {
        ...new_state,
        subtitle:
          'Since all sections weren’t marked as approved, a reason is needed for order approval.',
        commentInputLabel: 'Add reason for approval',
        initialErrors: APPROVAL_FORM_INITIAL_STATE,
      };
    }
    setModalState(new_state);
  };

  return (
    <>
      <Stack alignItems="center" direction="row">
        <Can I="send_back" an="order">
          <Button
            disabled={actionsDisabled}
            onClick={() => onActionClick(ORDER_REVIEW_ACTIONS.SEND_BACK)}
            size="large"
            color="info">
            Send back
          </Button>
        </Can>
        <Can I="submit" an="order_review">
          <Button
            disabled={actionsDisabled}
            onClick={() => onActionClick(ORDER_REVIEW_ACTIONS.REJECT)}
            sx={{ marginInline: '1.5rem' }}
            size="large"
            color="error">
            {' '}
            Reject
          </Button>
          <Button
            disabled={actionsDisabled}
            onClick={() => onActionClick(ORDER_REVIEW_ACTIONS.APPROVE)}
            size="large"
            color="success">
            Approve
          </Button>
        </Can>
      </Stack>
      {isModalOpen && (
        <OrderActionModal
          {...modalState}
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default OpsActionsButtons;
