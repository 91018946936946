import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import { ROUTES } from '@/constants';
import { BankAccountFragment } from '@/schemas/fragments';
import { getBankInfo } from '@/utils/bank';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Link as MuiLink,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { BANK_ACCOUNT_TYPE } from 'constants/bank';
import { useFormik } from 'formik';
import { find, map, toNumber } from 'lodash';
import { Form, Link } from 'react-router-dom';

interface ChangeBeneficiaryModalProps {
  open: boolean;
  toggle: () => void;
  bankAccounts?: BankAccountFragment[] | null;
  selectedBankAccountId: number | null;
  updateBeneficiary: (bankAccountId: number) => void;
  isSubmitting: boolean;
  supplierId: number;
}

export const ChangeBeneficiaryModal = (props: ChangeBeneficiaryModalProps) => {
  const {
    open,
    toggle,
    bankAccounts,
    selectedBankAccountId,
    updateBeneficiary,
    isSubmitting,
    supplierId,
  } = props;

  const formik = useFormik({
    initialValues: {
      bankAccountId: toNumber(selectedBankAccountId),
    },
    onSubmit: (values) => updateBeneficiary(values.bankAccountId),
    enableReinitialize: true,
  });

  const bankAccount = find(
    bankAccounts,
    (bankAccount) => bankAccount?.id === formik.values?.bankAccountId
  );

  const bankInfo = bankAccount && getBankInfo(bankAccount);

  const bankAccountTitle = bankAccount?.payment_type
    ? BANK_ACCOUNT_TYPE[
        bankAccount?.payment_type as keyof typeof BANK_ACCOUNT_TYPE
      ]
    : '';

  return (
    <Dialog fullWidth open={open} onClose={toggle}>
      <DialogTitle>
        <Typography variant="h5">Change beneficiary</Typography>
      </DialogTitle>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingBlockStart: 1,
            gap: 3,
          }}>
          <Typography variant="body1">
            To add a new beneficiary, go to{' '}
            <MuiLink
              component={Link}
              to={`${ROUTES.suppliers}/${supplierId}#bank_account`}>
              supplier ↗.
            </MuiLink>
          </Typography>
          <Select
            name="bankAccountId"
            value={formik.values.bankAccountId}
            onChange={formik.handleChange}>
            {map(bankAccounts, (bankAccount) => {
              const isDeactivated = Boolean(bankAccount?.deactivated_at);
              return (
                <MenuItem
                  key={bankAccount?.id}
                  value={bankAccount?.id}
                  disabled={isDeactivated}>
                  {`${bankAccount?.bank_account_holder_name} (${
                    bankAccount?.acct_number || bankAccount?.iban
                  }) ${isDeactivated ? `(Deactivated)` : ''}`}
                </MenuItem>
              );
            })}
          </Select>
          <Stack
            gap={2}
            border={1}
            borderRadius={4}
            borderColor="other.outlined_border"
            padding={3}>
            <Typography variant="subtitle1" color="text.primary">
              {bankAccountTitle}
            </Typography>
            <Stack gap={1}>
              {map(
                bankInfo,
                (row) =>
                  row.value && (
                    <Row spacing={1}>
                      <Col xs={12} sm="auto">
                        <Typography
                          width={164}
                          textAlign={'start'}
                          variant="body2"
                          color="text.secondary">
                          {row.label}
                        </Typography>
                      </Col>
                      <Col xs={12} sm>
                        <Typography variant="body2" color="text.primary">
                          {row.value}
                        </Typography>
                      </Col>
                    </Row>
                  )
              )}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={toggle}
            size="large">
            Close
          </Button>
          <LoadingButton
            disabled={!formik.dirty}
            loading={isSubmitting}
            variant="contained"
            type="submit"
            size="large">
            Save changes
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
