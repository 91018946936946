import { TableHasNoRowsPlaceholder } from '@/components/TableHasNoRowsPlaceholder';
import { Box } from '@mui/material';

export const SupplierInvoiceTab = ({ invoiceUrl }: { invoiceUrl?: string }) => {
  return invoiceUrl ? (
    <Box sx={{ height: '100vh' }}>
      <iframe
        src={invoiceUrl}
        style={{ width: '100%', height: '100%' }}
        title="invoice"
      />
    </Box>
  ) : (
    <TableHasNoRowsPlaceholder
      title={'Nothing here yet'}
      subtitle={'Supplier invoice will be displayed here.'}
      iconProps={{
        style: { fill: 'none' },
        sx: {
          color: 'info.main',
          width: 54,
          height: 60,
        },
        viewBox: '0 0 95 108',
      }}
      containerProps={{
        border: 1,
        borderColor: 'other.outlined_border',
        borderRadius: 4,
      }}
      contentBoxProps={{
        gap: 4,
      }}
    />
  );
};
