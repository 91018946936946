import { FormValues } from './types';

export const handleFormValidation = (
  values: FormValues,
  initialErrors: any
) => {
  const errors = {} as FormValues;
  if (values.reason === '') errors.reason = 'Required';
  if (values.reason === 'Other' && !values.comment) errors.comment = 'Required';
  if (!values.reason && !values.comment && initialErrors?.comment === '')
    errors.comment = 'Required';
  return errors;
};

export const getCommentInputStatus = (
  isInitialValid: boolean,
  reasonInputValue?: string
) => {
  if (isInitialValid) return 'optional';

  if (reasonInputValue !== undefined) {
    if (reasonInputValue === 'Other') {
      return 'required';
    }
    return 'optional';
  }
  return 'required';
};
